import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountdownModule } from 'ngx-countdown';
import { NgxGaugeModule } from 'ngx-gauge';
import { SharedModule } from '../shared/shared.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CountdownStopwatchComponent } from './components/countdown-stopwatch/countdown-stopwatch.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { ThreeDContainerComponent } from './components/three-d-container/three-d-container.component';
import { OrgChartComponent } from './components/org-chart/org-chart.component';
import { OrgChartDrawChildrenLinksPipe } from './pipes/org-chart-draw-children-links.pipe';

@NgModule({
    declarations: [ConfirmDialogComponent, CountdownStopwatchComponent, FormatNumberPipe, ThreeDContainerComponent, OrgChartComponent, OrgChartDrawChildrenLinksPipe],
    imports: [
        CommonModule,
        SharedModule,
        NgxGaugeModule,
        CountdownModule
    ],
    exports: [
        ConfirmDialogComponent,
        CountdownStopwatchComponent,
        FormatNumberPipe,
        ThreeDContainerComponent,
        OrgChartComponent
    ]
})
export class CoreModule { }
