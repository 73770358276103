import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MarkdownComponent } from '../components/markdown/markdown.component';

@Injectable({
  providedIn: 'root'
})

export class MarkdownService {

  constructor(private matDialog: MatDialog) { }

  openMarkdown(path: any) {
    const dialogRef = this.matDialog.open(MarkdownComponent, {
      width: '90%',
      maxWidth: '1100px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal",
      data: {
        path: path
      }
    });
  }
}
