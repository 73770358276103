import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { onlyIntegerValidator } from '../../../../shared/validators/only-integer.validator';
import { IDistribution1ToNNewForm } from '../../interfaces/i-distribution-1-to-n-new';
import { UploadDistribution1ToNCsvService } from '../api/upload-distribution-1-to-n-csv.service';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNNewFormService implements IFormService<IDistribution1ToNNewForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private dataService: BlDistribution1ToNDataService,
    private uploadCSVService: UploadDistribution1ToNCsvService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNNewForm> = this.init();
  isEdit: boolean = false;
  isImportMethod: boolean = false;

  init(): FormGroupTypeSafe<IDistribution1ToNNewForm> {
    let group: FormGroupTypeSafe<IDistribution1ToNNewForm> = this.formBuilder.group<IDistribution1ToNNewForm>({
      country: this.formBuilder.control(null),
      mapType: this.formBuilder.control("map", Validators.required),
      name: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
      truckNumber: this.formBuilder.control(null),
      inputMethod: this.formBuilder.control(0)
    });
    return group;
  }

  prepareDataToSend(multipart?: any) {
    let formData = new FormData();
    if (multipart) {
      formData.append('files', multipart[0]);
    } else {
      formData.append('files', null);
    }
    return formData as any;

  }

  uploadCSV(multipart?: any) {
    let dataToSend: any = this.prepareDataToSend(multipart);
    return this.uploadCSVService.create(dataToSend, true, true);
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNNewForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }

}
