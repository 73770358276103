import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { NewPasswordActivationService } from '../api/new-password-activation.service';

@Injectable({
  providedIn: 'root'
})
export class BlNewPasswordActivationRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: NewPasswordActivationService
  ) {
    super(alertService);
  }

}