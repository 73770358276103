<div class="reducedForm tableForm" id="tableForm">
    <div class="row">
        <div class="col-sm-12">
            <h3 class="tab-title">
                {{"AddNodeGraphPair" | translate}}
                <button class="addDestinationBtn bg-green" type="button" mat-mini-fab color="accent"
                    (click)="addNewPairOfNodes()">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 table-box dashboard-y-scroll">
            <app-table-form [apiService]="apiService" [dataService]="dataService" [tableService]="tableService" (fieldValueChange)="changeValue($event)" [resetPageIndex]="true">
            </app-table-form>
        </div>
    </div>
</div>