import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../core/services/auth.service';
import { CompanySettingsService } from '../../core/services/company-settings.service';
import { TokenService } from '../../core/services/token.service';
import { AlertMessageDataService } from '../../shared/services/alert-message-data.service';
import { SignalRNotificationService } from '../../shared/services/signal-r-notification.service';
import { SignalRService } from '../../shared/services/signal-r.service';
import { LanguageModalComponent } from '../language-modal/language-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {


  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private tokenService: TokenService,
    private companySettings: CompanySettingsService,
    private cdr: ChangeDetectorRef,
    private signalRNotificationService: SignalRNotificationService,
    private SignalRService: SignalRService,
    private alertService: AlertMessageDataService
  ) { }

  // public todayDate: Date;
  // public propertyName: any = this.tokenService.getTokenData().given_name;
  // public propertyAddress: any = this.tokenService.getTokenData().family_name;
  // public propertyPhone: any = this.tokenService.getTokenData().nameid;
  // public propertyEmail: any = this.tokenService.getTokenData().email;
  // public propertyImage: any = this.tokenService.getTokenData().website;
  // public fullPath: string = config.baseApiUrl.AWS_IMG + this.propertyImage;
  // public notificationObj = null;

  ngOnInit() {
    // this.trackDate();
    // this.trackNotificationsFromSignalR();
  }

  // private trackNotificationsFromSignalR() {
  //   this.SignalRService.startConnection().then(() => {
  //     this.SignalRService.addNotificationListener();
  //     this.signalRNotificationService.getAll().subscribe(notifications => {
  //       // this.notificationObj = notifications;
  //     });
  //   })
  //     .catch(err => {
  //       console.error('Error while starting connection: ' + err)
  //     });


  //   this.signalRNotificationService.notification
  //     .subscribe(notification => {
  //       if (notification && notification.message) {
  //         this.alertService.setMessage("success", notification.message)
  //         if (notification.number == 0) {
  //           // notification.number = this.notificationObj.number + notification.number;
  //           this.notificationObj = null;
  //           return;
  //         }
  //         this.notificationObj = notification;
  //       }
  //     })
  // }

  openModal() {
    const dialogRef = this.matDialog.open(LanguageModalComponent, {
      width: "300px",
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    });
  }

  // openNotificationModal() {
  //   this.matDialog.open(NotificationModalComponent, {
  //     width: '750px',
  //     height: "auto",
  //     minHeight: "100px"
  //   }).afterClosed().subscribe(data => {
  //     // this.notificationObj = null;
  //   });
  // }

  // private trackDate() {
  //   this.todayDate = DateFunctions.getTodayHotelDate();
  //   this.companySettings.getRealTime().subscribe(data => {
  //     if (data) {
  //       if (this.companySettings.isTimeZoneChanged(cloneDeep(this.companySettings.getCurrentValue()))) {
  //         this.companySettings.stopHotelDateTime();
  //         this.companySettings.updateTimezone();
  //         SpinnerFunctions.showSpinner();
  //         this.companySettings.getHotelSettingFromApi().subscribe(data => {
  //           SpinnerFunctions.hideSpinner();
  //         }, error => {
  //           SpinnerFunctions.hideSpinner();
  //         });
  //       } else {
  //         this.todayDate = DateFunctions.getMomentDate(data);
  //         if (this.cdr && !(this.cdr as ViewRef).destroyed) {
  //           this.cdr.detectChanges();
  //         }
  //       }

  //     }
  //   })
  // }

  calculatePositionLeft(){
    const userTextWidth = document.getElementById("userText").offsetWidth;
    const companyTextWidth = document.getElementById("companyText").offsetWidth;
    const companyToolbar = <HTMLElement>document.querySelector(".company-toolbar");
    if( companyToolbar && userTextWidth < 100) {
      let left = (userTextWidth + companyTextWidth + 45 - 255);
      // left = sirina texta usera + sirina texta company + 3 * padding (15px) - sirina panela
      companyToolbar.style.setProperty("left", `${left}px`);
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    // this.signalRNotificationService.notification.next(null);
    // this.notificationObj = null;
  }

}
