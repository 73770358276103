import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlInputDataTableService {

  constructor() { }

  private fixedColumns1: any[] = [];
  headersTable1: any[] = this.fixedColumns1;

  private fixedColumns2a: any[] = [];
  headersTable2a: any[] = this.fixedColumns2a;

  createDynamicHeaderColumnsTable1(numberCol: number) {
    this.fixedColumns1 = [];
    if (numberCol) {
      for (let i = 1; i <= numberCol; i++) {
        this.fixedColumns1.push({ "index": i, title: i });
      }
      this.headersTable1 = this.fixedColumns1;
    }
  }

  createDynamicHeaderColumnsTable2a(numberCol: number) {
    this.fixedColumns2a = [];
    if (numberCol) {
      for (let i = 1; i <= numberCol; i++) {
        this.fixedColumns2a.push({ "index": i, title: i });
      }
      this.headersTable2a = this.fixedColumns2a;
    }
  }
}
