<div [ngClass]="!(dataService.originalObject.getValue() || mainTitle) ? 'fixed-page-header' : 'relative-page-header'">

  <div class="row">
    <div class="col-md-12">
      <h3 class="page-header-title"> <span>{{"Packing" | translate}}</span> / {{"3DPacking" |
    translate}}</h3>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="header-pfsp">
      <div class="header-button-box" [ngClass]="formService.isShowSavesButtons ? 'all-buttons' : null">
        <button type="button" mat-stroked-button color="primary"
          class="custom-stroked-btn"
          (click)="newBackpackProblem()"
          [disabled]="!permissionsService.havePermission('3dp', 'ability-to-create-new-problem')">
          <mat-icon>create</mat-icon>{{"New" | translate}}
        </button>
        <button type="button" mat-stroked-button color="primary"
          class="custom-stroked-btn"
          (click)="viewBackpackProblem()"
          [disabled]="!permissionsService.havePermission('3dp', 'ability-to-open-all-problems')">
          <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
        </button>
        @if (formService.isShowSavesButtons == true) {
          @if (isLiteratureProblem != true) {
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="saveDatas()"
              [disabled]="!permissionsService.havePermission('3dp', 'ability-to-save-problem') || !this.formService.form.valid">
              <mat-icon>album</mat-icon>{{"Save" | translate}}
            </button>
          }
          @if (dataService.idForPatch && dataService.idForPatch != -1) {
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="saveAsNew()"
              [disabled]="(!permissionsService.havePermission('3dp', 'ability-to-save-as') || !this.formService.form.valid)">
              <mat-icon>album</mat-icon>{{"Save As" | translate}}
            </button>
          }
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="runProblem()"
            [disabled]="(!permissionsService.havePermission('3dp', 'ability-to-run-problem') || !this.formService.form.valid || !this.formService.isExportShow)">
            <mat-icon>school</mat-icon>{{"Run" | translate}}
          </button>
          @if (formService.isRunProblemForTitle == true) {
            <span class="problem-title" [innerHTML]="formService.problemTitle | safeHtml">
            </span>
          } @else {
            @if (dataService.originalObject.getValue() || mainTitle) {
              <span class="problem-title"
                      [innerHTML]="dataService.originalObject.getValue() ?
                      (dataService.originalObject.getValue()[0] ?
                      dataService.originalObject.getValue()[0].title :
                      dataService.originalObject.getValue().title) : mainTitle">
              </span>
            }
          }
        }
      </div>
      <div class="help-block">
        @if (formService.isExportShow==true) {
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="export()">
            <mat-icon>save_alt</mat-icon>{{"Export CSV" | translate}}
          </button>
        }
        <button type="button" mat-stroked-button color="primary"
          class="custom-stroked-btn"
          (click)="openMarkdown()">
          <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

  <div class="row">
    @if (!(dataService.originalObject.getValue() || mainTitle)) {
      <div class="col-md-12 iframe-wrapper">
        <div class="iframe-holder">
          <iframe src="assets/markdown/packaging/3d-packing-problem/help.html" width="100%" height="100%"
            frameborder="5">
          </iframe>
        </div>
      </div>
    }
  </div>
</div>
