<div class="row headerWrapper">
        <!-- App Search components -->
        <div class="col-sm-6 col-md-4 col-lg-3 searchBox noLeftPadding">
            <app-search class="reducedForm" [dataService]="dataService"></app-search>
        </div>
        <!-- End App Search components -->
    
        <!-- Icon for Popover ToolTip -->
        <div class="col-sm-6 col-md-8 col-lg-9 products iconBox">

            <!-- <span [ngbPopover]="popContent" popoverTitle="{{'headerInvoicedDelivered' | translate}}" placement="left"
                    popoverClass="dashboardHeader" container="body" triggers="mouseenter:mouseleave">
                        <mat-icon class="tooltipIcon">info</mat-icon>
                        <ng-template #popContent>
                            <ul class="popList">
                                <li><span class="popListSpan">{{invoiceFirstParagraph}}</span></li>
                                <li><span class="popListSpan">{{invoiceSecondParagraph}}</span></li>
                                <li><span class="popListSpan">{{invoiceThirdParagraph}}</span></li>
                                <li><span class="popListSpan">{{invoiceFourthParagraph}}</span></li>
                                <li><span class="popListSpan">{{invoiceFifthParagraph}}</span></li>
                                <li><span class="popListSpan">{{invoiceSixthParagraph}}</span></li>
                            </ul>
                        </ng-template>
                    </span> -->
    
            <app-show-hide-columns class="reducedButton" [tableService]="tableService"></app-show-hide-columns>
            <!-- End Icon for Popover ToolTip -->
        </div>
    </div>