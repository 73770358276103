import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexOfFormElement'
})
export class IndexOfFormElementPipe implements PipeTransform {

  transform(formAray: any, nameOfControl: string, id: any, articleTypeId:any = null): number {
    return formAray.controls.findIndex(item => item.get(id ? nameOfControl : 'articleTypeId').value === (id ? id : articleTypeId));
  }

}
