import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardDistributionComponent } from './components/dashboard-distribution/dashboard-distribution.component';
import { RoleGuard } from '../../core/guards/role.guard';

const routes: Routes = [
  {
    path: "dashboard-distribution",
    component: DashboardDistributionComponent,
    canActivate: [RoleGuard],
    data: {section: "routing", child: "dashboard-distribution" }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Distribution1ToNRoutingModule { }
