<!-- Header Dialog-->
<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>
<!-- End Header Dialog-->
<!-- Form Dialog-->
<form class="reducedForm" [formGroup]="formService.form" (ngSubmit)="save()">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{"Add" | translate}}&nbsp; {{"CFP" | translate}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 input-methods">
                <label class="sub-title">{{"Input Methods" | translate}}:</label>
                <mat-radio-group [formControlName]="form.keys.inputMethod">
                    <mat-radio-button [value]="data.id" *ngFor="let data of dropdownData.Methods">{{data.title |
                        translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row" [ngClass]="!formService.isImportMethod ? 'showDiv' : 'hideDiv'">
                <!-- M Control -->
                <div class="col-sm-4 input-mobile">
                    <mat-form-field appearance="outline"
                        [ngClass]="formService.form.get('m').errors ? 'requiredField' : ''">
                        <mat-label>{{"M" | translate}}</mat-label>
                        <input type="number" min=3 [formControlName]="form.keys.m" matInput autocomplete="off" />
                        <mat-error class="customError">
                            <ng-container *ngIf="formService.form.get('m').hasError('required')">
                                {{"ThisFieldIsRequired" | translate}}
                            </ng-container>
                            <ng-container *ngIf="formService.form.get('m').hasError('min')">
                                {{"MinimalValueValidation" | translate}}&nbsp; 3.
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- End M Control -->
                <!-- P Control -->
                <div class="col-sm-4 input-mobile">
                    <mat-form-field appearance="outline"
                        [ngClass]="formService.form.get('p').errors ? 'requiredField' : ''">
                        <mat-label>{{"P" | translate}}</mat-label>
                        <input type="number" min=3 [formControlName]="form.keys.p" matInput autocomplete="off" />
                        <mat-error class="customError">
                            <ng-container *ngIf="formService.form.get('p').hasError('required')">
                                {{"ThisFieldIsRequired" | translate}}
                            </ng-container>
                            <ng-container *ngIf="formService.form.get('p').hasError('min')">
                                {{"MinimalValueValidation" | translate}}&nbsp; 3.
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- End P Control -->
                <!-- Len Control -->
                <div class="col-sm-4 input-mobile">
                    <mat-form-field appearance="outline"
                        [ngClass]="formService.form.get('len').errors ? 'requiredField' : ''">
                        <mat-label>{{"Length" | translate}}</mat-label>
                        <input type="number" min=2 [formControlName]="form.keys.len" matInput autocomplete="off" />
                        <mat-error class="customError">
                            <ng-container *ngIf="formService.form.get('len').hasError('required')">
                                {{"ThisFieldIsRequired" | translate}}
                            </ng-container>
                            <ng-container *ngIf="formService.form.get('len').hasError('min')">
                                {{"MinimalValueValidation" | translate}}&nbsp; 2.
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- End Len Control -->
                <div class="col-md-12">
                    <!-- Description Control -->
                    <mat-form-field appearance="outline" color="accent"
                        [ngClass]="formService.form.get('description').errors ? 'requiredField' : ''">
                        <mat-label>{{"Name" | translate}}</mat-label>
                        <textarea [formControlName]="form.keys.description" matInput cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="7"></textarea>
                        <mat-error class="customError">
                            <ng-container *ngIf="form.get('description').hasError('required')">
                                {{"ThisFieldIsRequired" | translate}}
                            </ng-container>
                            <ng-container *ngIf="form.get('description').hasError('noWhiteSpace')">
                                {{"NoWhiteSpace" | translate}}
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                    <!-- End Description Control -->
                </div>
        </div>
        <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
            <div class="col-md-12 inputfile-wrapper">
                <div class="inputfile-box">
                    <input type="file" name="file" id="file" class="inputfile"
                        (change)="onFileChanged($event)" #fileNameId />
                    <label for="file">
                        <span id="file-name" class="file-box"></span>
                        <span class="file-button">
                            <mat-icon>publish</mat-icon>
                            <span>{{"Select File" | translate}}</span>
                        </span>
                    </label>
                </div>
            </div>
        </div>

    </div>
    <div mat-dialog-actions>

        <button mat-flat-button disableRipple color="accent" type="submit"
                [disabled]="(!this.form.get('m').valid || !this.form.get('p').valid || !this.form.get('len').valid || !this.form.get('description').valid) && formService.isImportMethod == false">{{'Apply' | translate}}
        </button>
        <button mat-flat-button disableRipple type="button" color="warn" (click)="close()">
          {{'Close' | translate}}
        </button>

    </div>
</form>
<!-- End Form Dialog-->
