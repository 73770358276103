import { IOption } from "../../../shared/interfaces/i-option";

export const LOT_SIZES: IOption[] = [
    {
        id: 1,
        title: "EOQ"
    },
    {
        id: 2,
        title: "LFL"
    },
    {
        id: 3,
        title: "LTC"
    },
    {
        id: 4,
        title: "LUC"
    },
    {
        id: 5,
        title: "PPB"
    },
    {
        id: 6,
        title: "FOQ"
    }
]