import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';

@Component({
  selector: 'app-packing-dialog-backpack-problem',
  templateUrl: './packing-dialog-backpack-problem.component.html',
  styleUrls: ['./packing-dialog-backpack-problem.component.scss']
})
export class PackingDialogBackpackProblemComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    protected matDialog: MatDialog
  ) {}

  items = [];
  itemsTmp = [];
  container = {
    x: 0,
    y: 0,
    z: 0
  }
  currentItemIndex: number = -1;

  ngOnInit(): void {
    this.items = this.data['algorithmPackingResults'][0]['packages'];
    this.container = {
      x: this.data['containerX'],
      y: this.data['containerY'],
      z: this.data['containerZ']
    }

  }

  pack(): void {
    this.currentItemIndex++;
    this.itemsTmp = cloneDeep(this.items).slice(0, this.currentItemIndex + 1);
  };

  unpack(): void {
    this.currentItemIndex--;
    this.itemsTmp = cloneDeep(this.items).slice(0, this.currentItemIndex + 1);
  }

  close(): void {
    this.matDialog.closeAll();
  }

}
