import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlCellFormationProblemFormService } from '../../../../services/form/bl-cell-formation-problem-form.service';
import { BlCellFormationProblemRequestsService } from '../../../../services/requests/bl-cell-formation-problem-requests.service';
import { BlCellFormationProblemDataService } from '../../../../services/shared/bl-cell-formation-problem-data.service';
import { FormDashboardCellFormationProblemComponent } from '../form-dashboard-cell-formation-problem/form-dashboard-cell-formation-problem.component';
import { ViewDialogDashboardCellFormationProblemComponent } from '../view-dialog-dashboard-cell-formation-problem/view-dialog-dashboard-cell-formation-problem.component';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { NotFoundError } from '../../../../../../shared/handlers/not-found-error';
import { CellFormationProblemService } from '../../../../services/api/cell-formation-problem.service';
import { SaveAsNewDialogComponent } from '../save-as-new-dialog/save-as-new-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BlReportsService } from '../../../../../../shared/services/bl-reports.service';
import { cloneDeep } from 'lodash';
import { DynamicAlgorithmFormControlsService } from '../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { BlCfpTestFormService } from '../../../../services/form/bl-cfp-test-form.service';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';
import { FileDownloadService } from '../../../../../../shared/services/file-download.service';
import { FileType } from '../../../../../../shared/enums/file-type';
import { apiPaths } from '../../../../../../config/api';
import { CellFormatingHelperFunctionsService } from '../../../../services/helper/cell-formating-helper-functions.service';

@Component({
  selector: 'app-header-dashboard-cell-formation-problem',
  templateUrl: './header-dashboard-cell-formation-problem.component.html',
  styleUrls: ['./header-dashboard-cell-formation-problem.component.scss']
})
export class HeaderDashboardCellFormationProblemComponent implements OnInit, OnDestroy {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlCellFormationProblemDataService,
    public formService: BlCellFormationProblemFormService,
    private requestsService: BlCellFormationProblemRequestsService,
    private alertService: AlertMessageDataService,
    public permissionsService: PermissionsService,
    public apiService: CellFormationProblemService,
    private sanitizer: DomSanitizer,
    private reportService: BlReportsService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private testFormService: BlCfpTestFormService,
    private markdownService: MarkdownService,
    private fileDownloadService: FileDownloadService,
    private cellFormatingHelperFunctionsService: CellFormatingHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  private dataForSend: any = [];
  private dataFromStorage: any;
  public mainTitle: string = "";
  public dataRows1: any;
  public nameOfCreated: any = "";

  public clickedButton: boolean = false;
  public showTestButton: boolean = false;

  public safeData: any;
  public unsafeHTML = "assets/markdown/cms/cell-formation-problem/help1.html";


   // ngAfterViewInit(): void {
  //   this.dataService.originalObject.subscribe(data => {
  //     this.formService.isExportShow = !!(data && data.id);
  //     if (data) {
  //       this.dataFromStorage = data;
  //     }
  //   });
  // }

  ngOnInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        if (data == true) {
          this.formService.isShowOtherTabs = true;
        } else {
          this.formService.isShowOtherTabs = false;
        }
      }
    })

    this.dataService.originalObject.subscribe({
      next: (data) => {
        this.dataFromStorage = data;
        this.formService.isExportShow = !!(data && data.id);
        this.showTestButton = !!(data && data.id);
      }
    });

    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  saveData() {

    this.clickedButton = true;
    this.dataForSend = [];

    if(this.dataFromStorage) {
      
      let rows: any = this.formService.form.getRawValue();
      rows = this.cellFormatingHelperFunctionsService.mapToSingleArray(rows.rows, rows);

      this.dataForSend.push({
        id: this.dataFromStorage.id,
        code: this.dataFromStorage.code,
        description: this.dataFromStorage.description,
        len: this.dataFromStorage.len,
        m: this.dataFromStorage.m,
        p: this.dataFromStorage.p,
        name: this.dataFromStorage.name,
        rows: rows
      });

      SpinnerFunctions.showSpinner();
      this.subscription.add(
        this.requestsService.insertData(this.dataForSend).subscribe({
          next: success => {
            this.alertService.setMessage("success", this.dataFromStorage.id ? "Successfully updated." : "Successfully created.");
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
            this.cellFormatingHelperFunctionsService.breakApartStavke(success);
            this.dataService.originalObject.next(success);
            if (success['id']) {
              this.dataService.setIdForPatch(success['id']);
            }
          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
          }
        })
      );
    }
  }

  saveAsNew() {
    this.matDialog.open(SaveAsNewDialogComponent, {
      width: '90%',
      maxWidth: '400px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal",
      data: {
        title: this.mainTitle
      }
    }).afterClosed().subscribe({
      next: (val) => {
        if (val) {
          let form;
          if (this.dataService.originalObject.getValue()) {
            form = this.dataService.originalObject.getValue()[0] ? this.dataService.originalObject.getValue()[0] : this.dataService.originalObject.getValue();
          }

          let rows: any = this.formService.form.getRawValue();
          rows = this.cellFormatingHelperFunctionsService.mapToSingleArray(rows.rows, rows);

          let obj: any = cloneDeep(this.dataService.originalObject.getValue());

          let newSend = [];

          newSend.push({
            m: obj.m,
            p: obj.p,
            len: obj.len,
            code: obj.code,
            name: val,
            description: obj.description,
            rows: rows
          });
          this.subscription.add(
            this.requestsService.insertData(newSend).subscribe({
              next: success => {
                this.cellFormatingHelperFunctionsService.breakApartStavke(success);
                this.dataService.originalObject.next(success);
                this.alertService.setMessage("success", "Successfully created.");

                this.mainTitle = success["name"];
                this.formService.problemTitle = success["description"];
                this.dataService.idForPatch = this.dataFromStorage.id = success["id"];
                this.formService.isLiteratureProblem = false;
                SpinnerFunctions.hideSpinner();
              },
              error: error => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
              }
            })
          );
        }
      }
    })
  }

  newCFP() {
    const dialogRef = this.matDialog.open(FormDashboardCellFormationProblemComponent, {
      width: '90%',
      maxWidth: '600px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal"
    }).afterClosed().subscribe({
      next: (data) => {
        if (data) {
          this.mainTitle = data;
          this.formService.resetAlgorithms();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.testFormService.isShowReportTable = false;
          this.testFormService.reset();
        }
      }
    });
  }

  viewCFP() {
    const dialogRef = this.matDialog.open(ViewDialogDashboardCellFormationProblemComponent, {
      width: '90%',
      maxWidth: '650px',
      height: "auto",
      minHeight: "210px",
      panelClass: "customModal"
    }).afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.testFormService.isShowReportTable = false;
          this.testFormService.reset();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.changeAlgorithm(this.formService.form.getSafe(x => x.algorithm).value);
        }
      }
    });
  }

  changeAlgorithm(value): void {
    if (value) {
      let choosenAlgorithm = cloneDeep(this.formService.algorithmData).find(x => x.id === value);
      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls(choosenAlgorithm.parametri, this.formService.form);
      this.formService.parametri = Object.keys(choosenAlgorithm.parametri);
    }
  }

  testCFP() {
    this.dataService.storageIsRunResults.next(true);
    this.dataService.storageIsTabsView.next(true);
  }

  openMarkdown(): void {
    this.markdownService.openMarkdown(this.safeData);
  }

  export() {
    this.fileDownloadService.export(this.dataService.idForPatch, apiPaths.cms.downloadReportCSV, "CFP_Report_", FileType.csv)
  }

  runBrusco() {
    this.clickedButton = true;
    this.dataForSend = [];

    if(this.dataFromStorage) {
      
      let form: any = cloneDeep(this.formService.form).getRawValue();
      let rows: any = this.cellFormatingHelperFunctionsService.mapToSingleArray(form.rows, form)

      this.prepareDataToSendBursco(!!this.dataFromStorage.id, rows, form);

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.runBrusco(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.dataService.storageIsRunBrusco.next(true);
              this.dataService.storageDataResults.next(success);
              const originalObject = this.dataService.originalObject.getValue();
              originalObject.rowsTab7 = success['rowsTab7'];
              this.dataService.originalObject.next(originalObject);
              SpinnerFunctions.hideSpinner();
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].description : success["description"];
              this.clickedButton = false;
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }
  }

  runSimilarity() {
    this.clickedButton = true;
    this.dataForSend = [];

    if(this.dataFromStorage) {
      
      let form: any = cloneDeep(this.formService.form).getRawValue();
      let rows: any = this.cellFormatingHelperFunctionsService.mapToSingleArray(form.rows, form)


      this.dataForSend.push({
        m: this.dataFromStorage.m,
        p: this.dataFromStorage.p,
        rowsTab1: {
          table1: rows
        },
        rowsTab2: {
          groupedMachines: this.dataFromStorage.rowsTab2.groupedMachines,
          groupedParts: this.dataFromStorage.rowsTab2.groupedParts,
          table: {
            tableHeader: this.dataFromStorage.rowsTab2.table.tableHeader,
            tableData: this.dataFromStorage.rowsTab2.table.tableData
          }
        },
        rowsTab5: {
          naslov: this.dataFromStorage.rowsTab5.naslov
        },
        rowsTab6: {
          naslov: this.dataFromStorage.rowsTab6.naslov
        }
      });

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.runSimilarity(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.cellFormatingHelperFunctionsService.breakApartStavke(success);
              if (!success['id']) {
                success['id'] = this.dataService.idForPatch;
              }
              this.dataService.originalObject.next(success);
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].description : success["description"];
              this.dataService.storageIsRunBrusco.next(false);
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }
  }

  prepareDataToSendBursco(isSaved: boolean = false, rows: any, form: any): void {
    this.dataForSend = [];
    let dataToSend = {
      id: this.dataFromStorage.id,
      m: this.dataFromStorage.m,
      p: this.dataFromStorage.p,
      len: this.dataFromStorage.len,
      description: this.dataFromStorage.description,
      code: this.dataFromStorage.code,
      name: this.dataFromStorage.name,
      rowsTab1: {
        table1: rows
      },
      algorithm: rows.algorithm,
      objective: this.formService.form.getSafe(x => x.objective).value,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParams(this.formService.form)
    }

    if(!isSaved) {
      dataToSend.objective = form.objective;
    }

    this.dataForSend.push(dataToSend);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.isShowOtherTabs = false;
    this.dataForSend = [];
    this.formService.isRunProblemForTitle = false;
    this.formService.problemTitle = "";
    this.formService.isExportShow = false;
    this.showTestButton = false;
    this.formService.isLiteratureProblem = false;
  }

}
