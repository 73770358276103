<div class="dashboard-content">
  <div class="row">
    <div class="col-md-5 col-lg-4">
      <app-tab-dashboard-input-data-left-side></app-tab-dashboard-input-data-left-side>
    </div>
    <div class="col-md-7 col-lg-8">
      @if (formService.isShowRightBlock) {
        <app-tab-dashboard-input-data-right-side></app-tab-dashboard-input-data-right-side>
      }
    </div>
  </div>
</div>
