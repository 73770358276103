import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {

  constructor() { }

  @Input() currentPass: string = null;
  currentPassStrength: number = 0;
  @Output() currentPassStrengthEvent = new EventEmitter();
  strengthName: string = "";
  @Input() form: UntypedFormGroup;

  namesForPassStrength = {
    0: "Enter the password",
    20: "Very weak",
    40: "Weak",
    60: "Medium",
    80: "Strong",
    100: "Very strong"
  };
  confirmPasswordValue: string;

  ngOnInit(): void {
    this.currentPassStrengthEvent.emit(this.currentPassStrength);
  }

  // validatePass(): void {
  //   this.currentPassStrengthEvent.emit(this.currentPassStrength);
  // }

  changePasswordStrength(strength): void {
    let strengthTmp = strength != null ? strength : 0;
    this.currentPassStrength = strengthTmp;
    this.strengthName = this.namesForPassStrength[strengthTmp];
    this.currentPassStrengthEvent.emit(this.currentPassStrength);
  }

}
