import { Component, OnInit, OnDestroy } from '@angular/core';
import { QapService } from '../../../../services/api/qap.service';
import { BlQapTableService } from '../../../../services/table/bl-qap-table.service';
import { BlQapDataService } from '../../../../services/shared/bl-qap-data.service';
import { BlQapRequestsService } from '../../../../services/requests/bl-qap-requests.service';
import { BlQapFormService } from '../../../../services/forms/bl-qap-form.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IQapFilterForm, IQapDropdownData } from '../../../../interfaces/i-qap';
import { PROBLEM_TYPES_FILTER } from '../../../../../../config/settings/cms/problem-types-filter';
import { cloneDeep } from 'lodash';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { BlQapFlowsTableService } from '../../../../services/table/bl-qap-flows-table.service';
import { BlQapDistancesTableService } from '../../../../services/table/bl-qap-distances-table.service';
import { QapHelperFunctionsService } from '../../../../services/helper/qap-helper-functions.service';

@Component({
  selector: 'app-view-dialog-dashboard-qap',
  templateUrl: './view-dialog-dashboard-qap.component.html',
  styleUrls: ['./view-dialog-dashboard-qap.component.scss']
})
export class ViewDialogDashboardQapComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: QapService,
    public tableService: BlQapTableService,
    public dataService: BlQapDataService,
    private requestService: BlQapRequestsService,
    public formService: BlQapFormService,
    protected matDialog: MatDialog,
    private ref : MatDialogRef<ViewDialogDashboardQapComponent>,
    private tableFlowService: BlQapFlowsTableService,
    private tableDistanceService: BlQapDistancesTableService,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  public clickedButton: boolean = false;
  public isOptionSelected: boolean = false;
  public isLiteratureProblem: boolean = false;
  public form: FormGroupTypeSafe<IQapFilterForm> = this.formService.getFilterFormReference();

  public dropdownData: IQapDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    Methods: null
  }

  ngOnInit(): void {
    this.dropdownData.ProblemTypes = PROBLEM_TYPES_FILTER;
    this.form.getSafe(x => x.problemTypes).valueChanges.subscribe({
      next: data => {
        if (data != "" || data != null) {
          this.fillTable(data);
          this.isOptionSelected = true;
          if(data == "l") { 
            this.isLiteratureProblem = true;
          } else if(data == "c"){
            this.isLiteratureProblem = false;
          }
          //this.dataService.storageTypeProblem.next(data);
        } else {
          this.isOptionSelected = false;
        }
      }
    });
    // this.refreshPage();
    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        options: [
          {
            title: 'View',
            class: 'mat-flat-button ',
            method: (row) => {
              this.dataService.setIdForPatch(row['id'] ? row['id'] : 777);
              this.formService.isRunProblemForTitle = false;
              this.formService.problemTitle = "";
              // this.resetView();
              this.showDataFromService(row);
            },
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close(false);
          },
        },
      ])
    );
  }

  fillTable(value: any) {
    let type: any = value;
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType(type).subscribe({
        next: success => {
          SpinnerFunctions.hideSpinner();
          this.dataService.setStorage(success);
        }, 
        error: error => {
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }

  refreshPage() {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType("l").subscribe({
        next: (data) => {
          this.dataService.setStorage(data);
          SpinnerFunctions.hideSpinner();
        }
      })
    );
    this.formService.formFilter.getSafe(x => x.problemTypes).setValue("l", { emitEvent: false });
  }

  // resetView() {
  //   setTimeout(() => {
  //     this.dataService.setView(false);
  //   }, 1000);
  // }

  showDataFromService(row: any) {
    this.clickedButton = true;
    this.dataService.storageIsRunResults.next(false);
    this.dataService.storageIsTabsView.next(true);
    this.dataService.storageDataIsShowOtherTabs.next(true);
    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.requestService.getQAP(row.id).subscribe({
          next: (data: any) => {
            if (data) {
              this.flowsAndDistancesArrayFunstionsService.breakApartStavke(data);
              this.clickedButton = false;
              this.dataService.originalObject.next(data);
              
              this.formService.reducedFlowsArray = data[0]? data[0].flows: data["flows"];
              this.formService.reducedDistancesArray = data[0]? data[0].distances: data["distances"];
              this.formService.setFlowsRows(data[0]? data[0]: data);
              this.formService.setDistancesRows(data[0]? data[0]: data);
            }
            SpinnerFunctions.hideSpinner();
            this.close(true);
          }
        })
      );
    }
  }

  close(state : boolean) {
    this.ref.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataService.setStorage([]);
    this.formService.resetFilter();
    this.isLiteratureProblem = false;
    this.isOptionSelected = false;
  }

}
