import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { DeterministicMrpService } from '../api/deterministic-mrp.service';
import { MrpReportService } from '../api/mrp-report.service';

@Injectable({
  providedIn: 'root'
})
export class BlDeterministicMrpRequestsService extends BlRequestService {

  constructor(
    private alertServices: AlertMessageDataService,
    private apiService: DeterministicMrpService,
    private mprReportService: MrpReportService
  ) {
    super(alertServices);
  }

  saveData(dataForSend: any) {
    return this.apiService.updateAll(dataForSend);
  }

  getDeterministicMrps() {
    return this.apiService.getAll();
  }
  getDeterministicMrp(id: number) {
    return this.apiService.get(id);
  }

  insertData(rowId: any) {
    return this.apiService.create(rowId);
  }

  getReportDatas() {
    return this.mprReportService.getAll();
  }

  filterReportData(dataToSend: any) {
    return this.mprReportService.create(dataToSend);
  }
}
