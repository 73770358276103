import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TableFunctions } from '../../functions/table-functions';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessageDataService } from '../../services/alert-message-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription, merge, Observable } from 'rxjs';
import { ITableColumn } from '../../interfaces/i-table-column';
import { ApiService } from '../../services/api.service';
import { ArrayFunctions } from '../../functions/array-functions';
import { share, debounceTime } from 'rxjs/operators';
import { ColumnOption } from '../../interfaces/column-option';
import { ObjectFunctions } from '../../functions/object-functions';
import { ISetOfColumns } from '../../interfaces/i-set-of-columns';
import { cloneDeep } from 'lodash';
import { SpinnerFunctions } from '../../functions/spinner-functions';
import { TABLE_APIS } from '../../enums/table-apis';
import { BlTableLogicService } from '../../bussiness-logic/base/bl-table-logic.service';
import { ITableStorage } from '../../bussiness-logic/interfaces/i-storage';
import { LAST_PAGE_PAGINATION } from '../../consts/table-settings';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { MAT_SELECT_CONFIG } from '@angular/material/select';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-table-select-panel' }
    }
  ]
})

export class TableComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() public tableService: BlTableLogicService<any>;
  @Input() public dataService: ITableStorage<any>;
  @Input() public apiService: ApiService<any>;

  // fill initial storage or not
  @Input() public idAll: number; // fillStorage Or Not

  @Output() public refillStorage: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected tableFunctions: TableFunctions,
    protected translateService: TranslateService,
    protected alertMessageService: AlertMessageDataService,
    private matDialog: MatDialog
  ) {
  }

  public idColumn: string = "id";
  public isLoading = false;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  private datas: any[];

  public selectedRows = new SelectionModel<any>(true, []);
  public columns: ITableColumn[] = [];
  public allColumnProps: string[] = [];
  private originalColumns: ITableColumn[] = [];

  public displayedColumnProps: string[] = [];

  public pageSizeOptions: number[] = [];
  public totalCount = 0;
  public isServerRendering = false;

  // Go to page
  public pageNumbers: number[];
  public pages = [];
  public pageSize = 1;

  protected subscription: Subscription = new Subscription();
  public defaultSort: MatSortable;
  public fixedColumns: string[] = [];
  public buttons: any[] = []; // TODO: set type
  public defaultPage = 1;

  warnButtons: string[] = ["Delete", "Close", "Cancel"];

  ngOnInit() {
    this.idColumn = this.tableService.idColumn;
    this.translateService.onLangChange.subscribe({
      next: data => {
        if(data) {
          this.paginator._intl.itemsPerPageLabel = this.translateService.instant("ItemsPerPage");
          this.dataSource.paginator = this.paginator;
        }
      }
    });

    this.fixedColumns = this.tableService.getFixedColumns();
    this.pageSizeOptions = this.tableService.getPageSizeOptions();
    this.buttons = this.tableService.getButtons();

    this.trackSearchValueFiltering();
    this.trackColumnsSelection();
    this.trackOutsideClearSelectedRows();
    this.trackStorageUpdates();
    this.setupDisplayedColumns();

    this.fillTable();

    // this.tableService.setSortAndPaginatorForDataSource(this.dataSource, this.sort, this.paginator);

    this.trackSortingAndResetPagination();
    this.trackPaginationAndSort();
    this.trackPageSizeChanges();
  }

  private trackPageSizeChanges() {
    if(this.dataService._paginationGoToId){
      this.dataService._paginationGoToId.asObservable().pipe(debounceTime(50)).subscribe({
        next: id => {
          if(id){
            if(id == LAST_PAGE_PAGINATION){
              this.paginator.lastPage();
            } else {
              let index = this.datas.findIndex(x => x[this.idColumn] == id);
              let page = Math.ceil(index / this.paginator.pageSize);
              if(index % this.paginator.pageSize == 0){
                page++;
              }
              this.goToPage(page);
            }
          }
        }
      });
    }
  }

  private trackColumnsSelection() {
    this.tableService.getColumnsStorage().subscribe({
      next: (data: ISetOfColumns) => {
        if (this.columns.length == 0) {
          this.originalColumns = cloneDeep(data.columns);
        }
        this.columns = data.columns;
        this.displayedColumnProps = data.displayedColumns;
      }
    });
  }

  columnMethod(column: ITableColumn, row: any) {
    return column.hasOwnProperty('method') ? column.method(row) : null
  }

  checkboxMethod(event, column, row) {

    if (event) {
      if (column.isActiveOne) {
        this.selectedRows.clear();
        if (event.checked)
          this.selectedRows.toggle(row);
      } else {

        if ((!column.hasOwnProperty('condition')) || (column.hasOwnProperty('condition') && column.condition(row, this.selectedRows))) {
          this.selectedRows.toggle(row);
        }
      }

      if (column.hasOwnProperty('method')) {
        // whole row or value of ID column
        let params = column.hasOwnProperty('get_obj') ? row : row[this.idColumn];
        column.method(event.checked, params);
      }
      this.tableService.setAllSelectedRows(cloneDeep(this.selectedRows).selected);
    }
  }

  optionMethod(option: ColumnOption, row) {
    option.hasOwnProperty('method') && option.method(option.hasOwnProperty('param') ? row[option.param] : row)
  }

  buttonMethod(button) {
    if (typeof(button.method) == 'string') {
      this[button.method](button);
    } else {
      if (button.hasOwnProperty('method_params')) {
        button.method(this[button.method_params]);
      }
      else {
        button.method();
      }
    }
  }

  // Called From Column Methods
  onGroupDelete(button?) {
    const selectedItems: any = cloneDeep(this.selectedRows).selected;
    if (selectedItems.length > 0) {
      this.matDialog.open(ConfirmDialogComponent, {
        width: "370px",
        height: "auto",
        minHeight: '200px',
        panelClass: 'custom-dialog',
        data: {
          type: 'delete'
        }
      }).afterClosed().subscribe({
        next: value => {
          if (value == true) {
            if (this.apiService) {
              this.apiService.deleteAll(selectedItems.map(x => x[this.idColumn ? this.idColumn : "id"])).subscribe({
                next: success => {
                  this.alertMessageService.setMessage('success', 'Successfully deleted!');
                  this.selectedRows.clear();
                  this.fillTable(true);
                  if (button && button.callback) {
                    button.callback();
                  }
                },
                error: error => {
                  this.alertMessageService.setMessage('danger', 'Error on deleting');
                  // this.selectedRows.clear();
                }
              });
            }
          }
        }
      });
    } else {
      this.alertMessageService.setMessage('danger', 'Error on deleting. You must select at least one row.');
    }
  }

  onSelect(event, row) {
    this.selectedRows.toggle(row);
    this.tableService.setAllSelectedRows(cloneDeep(this.selectedRows).selected);
  }

  update(active: any, row: any, column: ITableColumn) {
    if (active) {
      active = 1;
    }
    else {
      active = 0;
    }
    if (this.apiService) {
      this.apiService.update(row[this.idColumn ? this.idColumn : "id"], ObjectFunctions.makeComputedProperty(column.index, active)).subscribe({
        next: data => {
          this.alertMessageService.setMessage('success', `${column.title} is successfully updated`);
          this.fillTable();
        },
        error: error => {
          this.alertMessageService.setMessage('danger', `Error on updating ${column.title}`);
        }
      });
    }

    if (this.columns.find(x => x.index == column.index).isActiveOne) {
      this.fillTable(true);
    }
  }

  private setupDisplayedColumns() {
    this.displayedColumnProps = this.tableService.getDisplayedColumns();
  }

  private trackSearchValueFiltering() {
    if (this.dataService.searchValue) {
      this.dataService.searchValue.subscribe({
        next: searchValue => {
          this.tableService.filterDataSourceBySearchValue(this.dataSource, searchValue);
        }
      });
    }
  }

  private trackOutsideClearSelectedRows() {
    this.tableService.getIsClearSelectedRows().subscribe({
      next: (isClear: boolean) => {
        if (isClear) {
          this.selectedRows = new SelectionModel<any>(true, []);
        }
      }
    });
  }

  private trackSortingAndResetPagination() {
    this.subscription.add(
      this.sort.sortChange.subscribe({
        next: () => {
          this.paginator.pageIndex = 0
        }
      })
    );
  }

  protected trackStorageUpdates() {
    this.subscription.add(this.dataService.getStorage().subscribe({
      next: (data: any) => {
        this.buildTable(data);
      }
    }));
  }

  private preparePathParamsForApi(filters) {
    return filters.filter(x => x.hasOwnProperty('filter_type') ? x.filter_type == 'url' : false).map(x => x.value);
  }
  private prepareQueryStringForApi() {
    let obj = {
      'Pagination.PageIndex': this.paginator.pageIndex,
      'Pagination.PageSize': this.paginator.pageSize,

    };
    if (this.sort) {
      obj['Pagination.Sort.SortBy'] = this.sort.active;
      obj['Pagination.Sort.SortDirection'] = (this.sort.direction === 'asc') ? 0 : 1
    }

    return obj;
  }

  private prepareBodyParamsForApi(filters) {
    let body = {};
    filters.filter(x => ((x.hasOwnProperty('filter_type') && x.filter_type != 'url' || (!x.hasOwnProperty('filter_type'))) && ((!x.hasOwnProperty('isServerFiltering')) || (x.hasOwnProperty('isServerFiltering') && x.isServerFiltering)))).forEach(x => {
      body[x.column] = x.value
    });
    return body;
  }

  protected fillTable(isNotGet = false) {
    if ((!this.apiService) || (!ArrayFunctions.inArray(TABLE_APIS.Get, this.tableService.apis))) {
      if (isNotGet) {
        this.refillStorage.emit(true);
      }
      return;
    }
    this.isLoading = true;
    let request: Observable<any>;
    if (this.idAll) {
      request = this.apiService.get(this.idAll).pipe(share());
    } else {
      if (this.dataService.filtersStorage) {
        let filters = this.dataService.filtersStorage.getValue();
        request = this.apiService.getWithBodyAndUrlParams(this.preparePathParamsForApi(filters), this.prepareQueryStringForApi(), this.prepareBodyParamsForApi(filters)).pipe(share());
      } else {
        request = this.apiService.getWithParams(this.prepareQueryStringForApi()).pipe(share());
      }
    }

    SpinnerFunctions.showSpinner();
    this.subscription.add(request.subscribe({
      next: (data: any) => {
        if (!data) {
          data = [];
        }
        this.buildTable(data);
        this.fillDataServiceStorage();
        this.isLoading = false;
        SpinnerFunctions.hideSpinner();
      },
      error: error => {
        this.isLoading = false;
        this.dataSource.data = [];
      }
    }));
    return request;
  }

  protected buildTable(data, resetPageIndex: boolean = true) {
    let allData = data.hasOwnProperty('data') ? data.data : data;
    if(resetPageIndex)
      this.paginator.pageIndex = 0;

    if (data.hasOwnProperty('total_count')) {
      this.totalCount = data.total_count;
      this.isServerRendering = true;
      // this.paginator.pageSize = this.pageSizeOptions[this.pageSizeOptions.length/2];
    } else {
      this.totalCount = this.datas ? this.datas.length : 0;
      this.isServerRendering = false; // TODO: pass this info in TableService
    }

    this.tableFunctions.customNestedPropsSort(this.dataSource);
    this.dataSource.sort = this.sort;

    this.dataSource.data = allData;
    let originalData = cloneDeep(this.datas);
    this.datas = allData;
    // this.tableService.setSortAndPaginatorForDataSource(this.dataSource, this.sort, this.paginator);
    this.totalCount = data.hasOwnProperty('total_count') ? data.total_count : allData.length;

    this.setColumnHeader();
    this.setPageSizeOptions();

    if ((!originalData) && !data.hasOwnProperty('total_count')) {
      this.paginator.pageSize = this.pageSize;
      this.goToPage(1);
    }

  }

  private setNumPages() {
    let size = Math.ceil(this.totalCount / this.paginator.pageSize);
    if (!size || size == Infinity) {
      size = 1;
    }
    this.pages = ArrayFunctions.fromNumber(size);
  }

  private trackPaginationAndSort() {
    this.subscription.add(merge(this.sort.sortChange, this.paginator.page)
      .subscribe({
        next: data => {
          this.setNumPages();
          this.selectedRows = new SelectionModel<any>(true, []);
          if (this.isServerRendering && data && !this.apiService) {
            this.apiService.getWithParamsAllData(this.prepareQueryStringForApi()).subscribe({
              next: (data: any) => {
                let datas = (data.hasOwnProperty('data')) ? data.data : data;
                this.datas = data;
                this.dataSource.data = datas;
                this.goToPage(1);
                this.totalCount = data.hasOwnProperty('total_count') ? data.total_count : datas.length;
                this.setPageSizeOptions();
              }
            });
          }

        }
      }));

  }

  private fillDataServiceStorage() {
    if (this.dataService && this.dataSource.data && this.dataSource.data.length > 0) {
      let source = this.datas.sort((x, y) => {
        let sort = {
          direction: this.sort.direction,
          id: this.sort.active
        }
        if (x[sort.id] > y[sort.id]) {
          return sort.direction == 'asc' ? 1 : -1;
        } else if (x[sort.id] < y[sort.id]) {
          return sort.direction == 'asc' ? -1 : 1;
        } else {
          return 0;
        }
      })
      this.dataService.setStorage(source);
    }
  }



  private setColumnHeader() {
    if (this.columns.length == 0) {
      this.originalColumns = cloneDeep(this.tableService.getColumns());
    }
    this.columns = this.tableService.getColumns();
    this.allColumnProps = this.tableService.getDisplayedColumns();
    this.displayedColumnProps = this.tableService.getColumns().filter(x => !x.hide).map(x => x.index);
  }

  private setDefaultSort() {
    this.defaultSort = this.tableService.getDefaultSort();
    if (this.displayedColumnProps.indexOf(this.defaultSort.id) === -1) {
      this.defaultSort = {
        id: this.displayedColumnProps[0],
        start: 'desc',
        disableClear: true
      };
    }
    this.sort.sort(this.defaultSort);
  }

  private setPageSizeOptions() {
    this.pageSizeOptions = TableFunctions.getPageSizeOptionsBasedOnTotalCount(this.totalCount);
    let pageSize = this.pageSizeOptions.find(x => x >= 10);

    if (!this.pageSize) {
      this.pageSize = Math.max(...this.pageSizeOptions);
    }
    else {
      pageSize = this.pageSizeOptions.find(x => x >= 10 && x <= 200);
      if (!pageSize) {
        this.pageSizeOptions.unshift(10);
        this.pageSizeOptions.unshift(100);
        this.pageSizeOptions.unshift(200);
        pageSize = 10;
        this.pageSize = pageSize;
      }
    }
    this.setNumPages();
  }

  goToPage(num) {
    this.selectedRows = new SelectionModel<any>(true, []);
    this.paginator.pageIndex = num - 1;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.dataService.storage.next([]);

    if (this.dataService.searchValue) {
      this.dataService.searchValue.next("");
    }
    this.subscription.unsubscribe();
    this.tableService.setAllSelectedRows([]);
    this.tableService.setColumns(this.originalColumns);
    this.tableService.setColumnsStorageWithDisplayed({
      columns: this.originalColumns,
      displayedColumns: this.tableService.getDisplayedColumns()
    })
  }


  // TODO: Solve this example
  // protected reloadService : ReloadService // DONT DELETE - Will be used

  //special case where data in post doesn't result in one row during the next get / currently only for common tables with getting data by id
  // if(this.idAll){
  //   this.reloadService.currentState.subscribe(val=>{
  //     if(val){
  //         this.apiService.get(this.idAll).subscribe(data=>{
  //           this.reloadService.setNewData(data);
  //           this.dataSource = new MatTableDataSource<any>(data);
  //         })
  //     }
  //   })
  // }

}
