import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BaseComponent } from '../../../../../../shared/components/base/base.component';
import { TableFunctions } from '../../../../../../shared/functions/table-functions';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';
import { SourceDestinationFormDashboardDistributionComponent } from '../source-destination-form-dashboard-distribution/source-destination-form-dashboard-distribution.component';

@Component({
  selector: 'app-left-sidebar-dashboard-distribution',
  templateUrl: './left-sidebar-dashboard-distribution.component.html',
  styleUrls: ['./left-sidebar-dashboard-distribution.component.scss']
})
export class LeftSidebarDashboardDistributionComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlLeftSidebarDistribution1ToNFormService,
    protected location: Location,
    protected matDialog: MatDialog,
    protected tableFunctions: TableFunctions,
    public dataService: BlDistribution1ToNDataService
  ) {
    super(tableFunctions, matDialog, location);
  }

  ngOnInit(): void {}

  private dialog = {
    component: SourceDestinationFormDashboardDistributionComponent,
    dimensions: {
      width: "95%",
      height: "auto",
      minHeight: "130px",
      panelClass: "mapModal"
    }
  };

  openModal(event): void {
    this.openDialog(this.dialog.component, { id: event.index }, this.dialog.dimensions);
  }

  ngOnDestroy(): void {
    this.formService.resetFormAndCreateNew();
  }

}