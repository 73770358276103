import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlInventoryFormService } from '../../../../../../services/form/bl-inventory-form.service';
import { BlDeterministicMrpDataService } from '../../../../../../services/shared/bl-deterministic-mrp-data.service';
import { BlInventoryTableService } from '../../../../../../services/table/bl-inventory-table.service';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../../../shared/helpers/reactive-forms-helper';
import { IInventoryForm } from '../../../../../../interfaces/i-inventory';

@Component({
  selector: 'app-tab-dashboard-inventory',
  templateUrl: './tab-dashboard-inventory.component.html',
  styleUrls: ['./tab-dashboard-inventory.component.scss']
})
export class TabDashboardInventoryComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlInventoryFormService,
    private dataService: BlDeterministicMrpDataService,
    public tableService: BlInventoryTableService,
    private alertService: AlertMessageDataService,
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IInventoryForm> = this.formService.form;

  public numbers: any = [];
  public initial = true;
  
  ngOnInit(): void {
      this.subscription.add(
        this.dataService.storageDataisFromViewDialog.subscribe({
          next: (data) => {
            if (data == false) {
              this.dataService.storageDataFromFormDialog.subscribe({
                next: (dataForm) => {
                  if (dataForm) {
                    dataForm = dataForm[0] ? dataForm[0] : dataForm
                    this.formService.numberRows = dataForm.noProductPartItems;
                    this.formService.numberCols = dataForm.noPlanningPerod;
                    this.formService.typePlanning = dataForm.timeUnitPlanningPeriod;
                    this.formService.colSpan = this.formService.numberCols + 4;
      
                    this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                    this.tableService.createDynamicHeaderColumns(this.formService.numberCols, this.formService.typePlanning)
                    if (this.formService.numberRows && this.formService.numberRows > 0) {
                      if(this.dataService.clickedRun)
                      this.formService.createTableWithDynamicRows(dataForm, this.formService.numberRows);
                    }
                  }
                }
              })
            } else {
              this.dataService.storageDataViewDialog.subscribe({
                next: (dataView) => {
                  if (dataView) {
                    dataView = dataView[0] ? dataView[0] : dataView;
                    this.formService.numberCols = dataView.noPlanningPerod;
                    this.formService.typePlanning = dataView.timeUnitPlanningPeriod;
                    if (this.formService.numberCols && this.formService.numberCols > 0) {
                      this.tableService.createDynamicHeaderColumns(this.formService.numberCols, this.formService.typePlanning)
                      this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                      this.formService.setRows(dataView);
                    }
                  }
                }
              })
            }
          }
        })
      );
   
  }

  saveData() {
    this.clickedButton = true;
    this.subscription.add(this.dataService.storageDataisFromViewDialog.subscribe({
      next: (data) => {
        if (data == false && this.clickedButton) {
          this.dataService.storageDataFromFormDialog.subscribe({
            next: (dataForm) => {
              let formValue: any = this.form.getRawValue();
              this.formService.rowData = {
                  rowsTab4: formValue.rowsTab4
              };
            }
          });
          if (this.clickedButton) {

            this.subscription.add(
              this.dataService.storageNewDataTab4.next(this.formService.rowData)
            );

            this.alertService.setMessage("primary", "Successfully temporarily saved.");
            this.clickedButton = false;
          }

        } else {
          this.dataService.storageDataViewDialog.subscribe({
            next: (dataView) => {
            if (dataView) {
              let formValue: any = this.form.getRawValue();
              this.formService.rowData = {
                  rowsTab4: formValue.rowsTab4
                };
              }
            }
          });
          if (this.clickedButton) {

            this.subscription.add(
              this.dataService.storageViewDataTab4.next(this.formService.rowData)
            );

            this.alertService.setMessage("primary", "Successfully temporarily saved.");
            this.clickedButton = false;
          }
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols = 0;
    this.formService.typePlanning = "";
    this.formService.colSpan = 0;
    this.clickedButton = false;
    this.numbers = [];
  }

}
