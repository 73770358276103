import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checked'
})
export class CheckedPipe implements PipeTransform {

  transform(value: any, args?: any): boolean {
    if(typeof(value)==='boolean') {
      return true;
    }
    else if (value===0) {
      return false;
    } 
    return true;
  }

}
