import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlBackpackProblemContainersTableService {

  constructor() { }

  private fixedColumns: any[] = [];
  private fixedColumns1: any[] = [];
  private fixedColumns2: any[] = [];
  headersTable: any[] = this.fixedColumns;
  headersTable1: any[] = this.fixedColumns1;
  headersTable2: any[] = this.fixedColumns2;

  createDynamicHeaderColumnsTable(numberCol: number) {

    this.headersTable1 = [
      {
        "title": "Name"
      },
      {
        "title": "L"
      },
      {
        "title": "W"
      },
      {
        "title": "H"
      },
      {
        "title": "Qt"
      }
    ]
  }

  createDynamicHeaderColumnsTabl2(numberCol: number) {

    this.headersTable2 = [
      {
        "title": "Name"
      },
      {
        "title": "L"
      },
      {
        "title": "W"
      },
      {
        "title": "H"
      },
      {
        "title": "Qt"
      }
    ]
  }

  createDynamicReducedHeaderColumnsTable(dataArr: any) {
    this.fixedColumns = [];
    if (dataArr) {
      for (let i = 0; i < dataArr.length; i++) {
        this.fixedColumns.push({ "index": i, title: dataArr[i] });
      }
      this.headersTable = this.fixedColumns;
    }
  }

}
