import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlCellFormationProblemDataService } from '../../../../../../services/shared/bl-cell-formation-problem-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-dashboard-dissimil-p',
  templateUrl: './tab-dashboard-dissimil-p.component.html',
  styleUrls: ['./tab-dashboard-dissimil-p.component.scss']
})
export class TabDashboardDissimilPComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlCellFormationProblemDataService
  ) { }

  private subscription: Subscription = new Subscription();
  public dataFromStorage: any;
  public title: string = "";

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          this.dataFromStorage = null;
          if(data) {
            this.dataFromStorage = data;
            this.title = (this.dataFromStorage.rowsTab4 != null) ? this.dataFromStorage.rowsTab4.naslov : "";
          }
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.title = "";
  }

}
