<mat-form-field appearance="outline" *ngIf="formControlToFill" [ngClass]="(formControlToFill.hasError('required')||formControlToFill.hasError('notselected')) ? 'requiredField' : false">
    <mat-label>{{ labelText | translate}}</mat-label>
    <input [formControl]='formControlToFill' matInput type="text" aria-label="Number" [matAutocomplete]="auto" autocomplete="off" (keyup)="setAutocompleteError()">

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="checkAutocomplete">
        <mat-option *ngFor="let option of _filteredOptions | async" [value]="option" (click)="selectOption(option)">
            {{option.title}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControlToFill.hasError('required')">
        {{'ThisFieldIsRequired' | translate}}
    </mat-error>
    <mat-error *ngIf="formControlToFill.hasError('notselected')">
      {{'ThisFieldIsRequired' | translate}}.  {{"Select from dropdown" | translate}}.
    </mat-error>
</mat-form-field>