import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Output() photo = new EventEmitter<object>();
  
  ngOnInit(): void {

    
  }

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  constructor(private http: HttpClient, private formBuilder : UntypedFormBuilder, private alertMessageDataService : AlertMessageDataService) { }
  selectedFiles: File[];
  public overSize = false;

  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    
    const uploadData = new FormData(); 

    for (let i = 0; i < this.selectedFiles.length; i++) {
      if(this.selectedFiles[i].size>1050000) {
        this.overSize=true;
        break;
      }
      uploadData.append('photos', this.selectedFiles[i], this.selectedFiles[i].name);
    } 

    if(this.overSize==true){
      this.alertMessageDataService.setMessage("danger","Image must be not over 1MB in size");
    }
    if(this.overSize==false)
    this.photo.emit(uploadData);
    this.overSize=false;
  }
}
