<div class="show-hide-columns-wrapper">
  <button mat-stroked-button color="primary" type="button"
    class="custom-stroked-btn show-hide-columns"
    [disabled]="!(displayedColumns && columns)"
    [matMenuTriggerFor]="listOfColumns"
    (menuOpened)="show = true; isVisibleColumnList = true;"
    (menuClosed)="show = false; isVisibleColumnList = false;">
    <mat-icon>view_carousel</mat-icon>
    {{"Show Columns" | translate}}
  </button>

  <mat-menu #listOfColumns="matMenu" class="columns-panel" xPosition="before">
      <div (click)="$event.stopPropagation();" class="columns-wrapper">
        @if (displayedColumns && columns && show) {
          <mat-selection-list class="custom-mat-select-list" color="primary"
            [@showList]="isVisibleColumnList">
            @for (column of columns; track column) {
              <mat-list-option #chbColumn disableRipple
                (click)="toggleColumn(column.index, chbColumn.selected)"
                [selected]="displayedColumns.includes(column.index)">
                {{column.title | translate}}
              </mat-list-option>
            }
          </mat-selection-list>
        }
      </div>
  </mat-menu>
</div>



