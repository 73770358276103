import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { IDistribution1ToNMapFilteringRequest } from '../../interfaces/i-distribution-1-to-n-map-filtering';
import { Distribution1ToNMapFilteringService } from '../api/distribution-1-to-n-map-filtering.service';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNMapFilteringRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: Distribution1ToNMapFilteringService
  ) {
    super(alertService);
  }

  filtering(data: IDistribution1ToNMapFilteringRequest): Observable<any> {
    return this.apiService.filtering(data);
  }
  
}