
<div class="map-dashboard-wrapper mt-10">

<div class="controls">
    <button class="choose-map" mat-flat-button color="accent" [disabled]="routes.length == allRoutes.length" (click)="addNext()">{{"Next" | translate}}</button>
    <button class="choose-map" mat-flat-button color="warn" [disabled]="routes.length == 0" (click)="removeLast()">{{"Previous" | translate}}</button>
</div>

<div class="map map-run">
    <app-leaflet-map
    #leafletMap
    [routes]="routes"
    [clickEvent]="false"
    [getUsersLocationOnInit]="false"
    [invalidateSize]="this.dataService.triggerInvalidateSizeOnMap"
    [zoomIn]="zoomIn"
    >
</app-leaflet-map>
</div>
</div>

