import { ObjectFunctions } from "./object-functions";
import { ArrayFunctions } from "./array-functions";
import { StringFunctions } from "./string-functions";
import { TranslateService } from "@ngx-translate/core";
import { TemplateRef } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { DateFunctions } from "./date-functions";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { ITableColumn } from "../interfaces/i-table-column";

export class TableFunctions {
    
    customNestedPropsFilter(data : any, filterInput : string){
        let keys = Object.keys(data[0]);
        return data.filter( (x : any) => {
            return this.nestedFilter(keys, x, filterInput);
          });
    }

    customNestedPropsSort(dataSource : MatTableDataSource<any>){
        dataSource.sortingDataAccessor = (obj, property) => {
            return this.getProperty(obj,property);
        }
    }

    static sortBy(property, array, direction = 'asc'){
        array.sort((x,y)=> {
            let sort = {
              direction: direction,
              id: property
            }
            if(x[sort.id] > y[sort.id]){
              return sort.direction == 'asc'? 1 : -1;
            } else if(x[sort.id] < y[sort.id]){
              return sort.direction == 'asc'? -1 : 1;
            } else {
              return 0;
            }
          });
    }

    static getPageSizeOptionsBasedOnTotalCount(totalCount){
      let totalNumItems = totalCount;
      if (totalNumItems <= 500)
      {
          var a = [ 20, 50, 100, 200, 500 ];
      }
      var magnitude = Math.pow(10, Math.floor(Math.log10(totalNumItems)));
      var numItemsCandidates = [ 0.05 * magnitude, 0.1 * magnitude, 0.2 * magnitude, 0.5 * magnitude, 1 * magnitude,2 * magnitude, 5 * magnitude, 10 * magnitude
      ]; 
      var i = 0;
      while (numItemsCandidates[i + 4] < totalNumItems)
      {
          ++i;
      }
      var pageSizeOptions = new Array();
      for (var selectedIndex = i; selectedIndex < i + 5; ++selectedIndex)
      {
          var numItems = Math.floor(numItemsCandidates[selectedIndex]);
          if (numItems > 0)
          {
            pageSizeOptions.push(numItems);
          }
      }
      return pageSizeOptions;
    }

    sortWithNoDataSource(dataToSort: any, sorted, customKeys : any) : any {


        let objKeys = customKeys == null ? Object.keys(dataToSort[0]) : customKeys;
        return Array.from(dataToSort.sort((a, b) => {
            let isAsc = sorted.direction === 'asc';
            if(objKeys.indexOf(sorted.active) != -1) {
                let valueA = this.getProperty(a, sorted.active);
                
                if(Date.parse(valueA)) {
                    valueA = DateFunctions.getMomentDateNoTime(DateFunctions.formatDate(valueA)).getTime();
                }
                let valueB = this.getProperty(b, sorted.active);
                if(Date.parse(valueB)) {
                    valueB = DateFunctions.getMomentDateNoTime(DateFunctions.formatDate(valueB)).getTime();
                }
                return this.compare(valueA, valueB, isAsc);
            }
            return 0;
        }));
    }
    
    getProperty(obj, property){
        return property.split('.').reduce((o, p) => o && o[p], obj)
    }

    static getPropertyValue(obj, property){
        return property.split('.').reduce((o, p) => o && o[p], obj);
    }

    getColumnsFromObj(obj : any, translateService : TranslateService, hasSelectColumn? : boolean, indexColumn?: string){
        let keys = ObjectFunctions.getKeys(obj);
        keys = keys.map(key => {
        if(!Array.isArray(obj[key]) && (typeof(obj[key]) == 'object' && obj[key] != null)) {
            let nestedObj = obj[key];
            return key.concat('.' + this.getNestedKey(nestedObj));
        }
        return key;
        });

        // reordering id and select
        if(hasSelectColumn){
            ArrayFunctions.removeItem(indexColumn, keys);
            keys.unshift('select');
            keys.unshift(indexColumn);
        }

        // translation of columns and definition
        let columns : ITableColumn[] = keys.map(
        (key) => {
            let title = StringFunctions.capitalize(key);
            return {
            index: key,
            title: title.trim()? translateService.get(title)['value'] : ""
            };
        });
        return columns;
    }

    openDialog(matDialog : MatDialog, entryComponent : ComponentType<any> | TemplateRef<any> | any, data? : any, dimensions? : any, afterClose?, panelClass?:string) : MatDialogRef<any>{
    let formDialogRef;
    formDialogRef = matDialog.open(entryComponent, {
        width: (dimensions && dimensions.hasOwnProperty('width')) ? dimensions['width'] : '50%',
        height: (dimensions && dimensions.hasOwnProperty('width')) ? dimensions['height'] : '60%',
        disableClose: false,
        data: data,
        panelClass: (dimensions && dimensions.hasOwnProperty('panelClass')) ? dimensions['panelClass'] : '',
      });
      
      if(afterClose){
        formDialogRef.afterClosed().subscribe({
            next: data => {
                afterClose();
        }
      });
    }
      return formDialogRef;
    }

    

    /* Getting type of Column */
    getIndexOfRow(dataSource: MatTableDataSource<any>, row : any, isServerRendering? : boolean, paginator?: MatPaginator){
        return (dataSource.data.indexOf(row) + 1) + (isServerRendering? (paginator.pageIndex * paginator.pageSize) : 0);
    }

    getNestedKey(obj){
        return obj.hasOwnProperty('title') ? 'title' : '';
      }

    private compare(a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private nestedFilter(keys, obj,  filterInput){
        let found = false;
        for(let key of keys) {
              if(typeof(obj[key]) == 'object' && obj[key] != null){
                let objKeys = Object.keys(obj[key]);
                found = this.nestedFilter(objKeys, obj[key], filterInput);
              }
              else if(typeof(obj[key]) == 'string'){
               found= obj[key].toLowerCase().indexOf(filterInput)!=-1;
              }
              else if(typeof(obj[key]) == 'number'){
               found= obj[key] == filterInput;
              }
              if(found) break;
        }
        return found;
    }
}