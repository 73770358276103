import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/guards/role.guard';
import { AdminConfigModule } from './admin-config.module';
import { DashboardAdminConfigComponent } from './components/dashboard-admin-config/dashboard-admin-config.component';

const routes: Routes = [
  {
    path: "dashboard-admin-config",
    component: DashboardAdminConfigComponent,
    canActivate: [RoleGuard],
    data: {section: "admin-config", child: "dashboard-admin-config" }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminConfigRoutingModule { }
