<div id="alert-wrapper">
  <div id="alert-block">
    @for (message of _messages | async; track message) {
      <div class="alert"  [ngClass]="'alert-'+ message.type" (close)="closeAlert(message)">
        {{ message.content | translate}}
      </div>
    }
    @for (message of _messages1 | async; track message) {
      <div class="alert"  [ngClass]="'alert-'+ message.type" (close)="closeAlert(message)">
        <span [innerHtml]="message.content | safeHtml"></span>
      </div>
    }
  </div>
</div>
