import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackpackProblemRoutingModule } from './backpack-problem-routing.module';
import { DashboardBackpackProblemComponent } from './components/dashboard-backpack-problem/dashboard-backpack-problem.component';
import { ViewDialogBackpackProblemComponent } from './components/dashboard-backpack-problem/components/view-dialog-backpack-problem/view-dialog-backpack-problem.component';
import { FormBackpackProblemComponent } from './components/dashboard-backpack-problem/components/form-backpack-problem/form-backpack-problem.component';
import { TablesBackpackProblemComponent } from './components/dashboard-backpack-problem/components/tables-backpack-problem/tables-backpack-problem.component';
import { EditItemsDialogBackpackProblemComponent } from './components/dashboard-backpack-problem/components/edit-items-dialog-backpack-problem/edit-items-dialog-backpack-problem.component';
import { EditContainersDialogBackpackProblemComponent } from './components/dashboard-backpack-problem/components/edit-containers-dialog-backpack-problem/edit-containers-dialog-backpack-problem.component';
import { HeaderBackpackProblemComponent } from './components/dashboard-backpack-problem/components/header-backpack-problem/header-backpack-problem.component';
import { SaveAsDialogBackpackProblemComponent } from './components/dashboard-backpack-problem/components/save-as-dialog-backpack-problem/save-as-dialog-backpack-problem.component';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { LeftTableBackpackProblemComponent } from './components/dashboard-backpack-problem/components/tables-backpack-problem/components/left-table-backpack-problem/left-table-backpack-problem.component';
import { RightTableBackpackProblemComponent } from './components/dashboard-backpack-problem/components/tables-backpack-problem/components/right-table-backpack-problem/right-table-backpack-problem.component';
import { PackingDialogBackpackProblemComponent } from './components/dashboard-backpack-problem/components/packing-dialog-backpack-problem/packing-dialog-backpack-problem.component';
import { ThreeDTableBackpackProblemComponent } from './components/dashboard-backpack-problem/components/three-d-table-backpack-problem/components/three-d-table-backpack-problem/three-d-table-backpack-problem.component';
import { ThreeDTableReportBackpackProblemComponent } from './components/dashboard-backpack-problem/components/three-d-table-backpack-problem/components/three-d-table-report-backpack-problem/three-d-table-report-backpack-problem.component';



@NgModule({
  declarations: [DashboardBackpackProblemComponent, ViewDialogBackpackProblemComponent, FormBackpackProblemComponent, TablesBackpackProblemComponent, EditItemsDialogBackpackProblemComponent, EditContainersDialogBackpackProblemComponent, HeaderBackpackProblemComponent, SaveAsDialogBackpackProblemComponent, LeftTableBackpackProblemComponent, RightTableBackpackProblemComponent, PackingDialogBackpackProblemComponent, ThreeDTableBackpackProblemComponent, ThreeDTableReportBackpackProblemComponent],
  imports: [
    CommonModule,
    BackpackProblemRoutingModule,
    SharedModule,
    CoreModule
  ]
})
export class BackpackProblemModule { }
