import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplaceCommaInNewLine'
})
export class StringReplaceCommaInNewLinePipe implements PipeTransform {

  constructor(
  ){

  }
  
  transform(dataString: any): any {
    if (dataString) {
      return dataString.replace(/,/g, '<br/>');
    }
    return '';
  }

}