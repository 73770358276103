import { Injectable } from '@angular/core';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IDeterministicMrp } from '../../interfaces/i-deterministic-mrp';
import { DeterministicMrpService } from '../api/deterministic-mrp.service';
import { BlDeterministicMrpRequestsService } from '../requests/bl-deterministic-mrp-requests.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BlDeterministicMrpDataService extends BlDataLogicService implements ITableStorage<IDeterministicMrp> {

  constructor(
    private apiService: DeterministicMrpService,
    private requestService: BlDeterministicMrpRequestsService
  ) {
    super();
  }

  selectedTab : number = 0;
  clickedRun : boolean = false;
  clickedView : boolean = false;
  patchFlag : boolean = false;
  requestedResults : boolean = false;
  idForPatch : number = null;
  inserted : boolean = false;

  currentId: number = null;
  currentTitle: string = null;

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  storage: BehaviorSubject<IDeterministicMrp[]> = new BehaviorSubject<IDeterministicMrp[]>([]);

  originalData: IDeterministicMrp[];

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  //ovo su storidzi za prvi tab //
  public storageIsTabsView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //storage kojim se setuje dal se vide tabovi ili ne
  public storageDataIsShowOtherTabs: BehaviorSubject<any> = new BehaviorSubject<any>(false); // storage kojim se setuje dal se videb tabovi od drugog do petog
  public storageDataFromFormDialog: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage koji sluzi da setuje kolonu sa vrednostima i prvog taba (new  button)
  public storageDataViewDialog: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage koji se setuje vrednostima iz dijaloga za prikaz (open  button)
  public storageDataisFromViewDialog: BehaviorSubject<any> = new BehaviorSubject<any>(false); // storage kojim se pamti stanje true/false da bi znali dal da uzimamo podatke iz ovog ili storage za form dialog
  public storageDataNewForm: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storidz samo za polja iz forme
  public storageDataViewForm: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storidz samo za polja iz view dialoga
  public storageSelectedFirstTab: BehaviorSubject<any> = new BehaviorSubject<any>(0);  // ovaj storage je default 0 i ne setuje se nidje vec na prikaz ostalih tabova se poziva kako bi se postavio inicijalno prvi tab setovan nakon otvaranja drugog problema
  // END ovo su storidzi za prvi tab //

  //storidzi za drugi tab
  public storageIsTreeView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //storage kojim se dal se vidi stablo u drugom tabu ili ne
  public storageNewDataTab2: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za drugi tab koji se setuje vrednostima iz forme za dodavanje (new  button)
  public storageViewDataTab2: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za drugi tab koji se setuje vrednostima iz dijaloga za prikaz (open  button)
  // END storidzi za drugi tab // 

  //storidzi za treci tab
  public storageNewDataTab3: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za treci tab koji se setuje vrednostima iz forme za dodavanje (new  button)
  public storageViewDataTab3: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za treci tab koji se setuje vrednostima iz dijaloga za prikaz (open  button)
  //storidzi za treci tab

  //storidzi za cetvrti tab
  public storageNewDataTab4: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za cetvrti tab koji se setuje vrednostima iz forme za dodavanje (new  button)
  public storageViewDataTab4: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za cetvrti tab koji se setuje vrednostima iz dijaloga za prikaz (open  button)
  //storidzi za cetvrti tab

  //storidzi za peti tab
  public storageNewDataTab5: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za peti tab koji se setuje vrednostima iz forme za dodavanje (new  button)
  public storageViewDataTab5: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage za peti tab koji se setuje vrednostima iz dijaloga za prikaz (open  button)
  //storidzi za peti tab

  //storidzi za report tab
  public storageReportData: BehaviorSubject<any> = new BehaviorSubject<any>(null); //response od beka kojim se popunjava desni blok
  public storageDataForNewReportPage: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storidz kojim se pamti rednost svih tabela
  //storidzi za report tab


  getOriginalData(): IDeterministicMrp[] {
    return this.originalData;
  }

  setOriginalData(items: IDeterministicMrp[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IDeterministicMrp[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IDeterministicMrp[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IDeterministicMrp[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IDeterministicMrp[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

  setRun(state:boolean){
    this.clickedRun = state;
  }

  setView(state:boolean){
    this.clickedView = state;
  }

  setFlag(state:boolean){
    this.patchFlag = state;
  }

  setIdForPatch(id:number){
    this.idForPatch = id;
  }

  insert(state : boolean){
    this.inserted = state;
  }
}
