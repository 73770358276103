
<form [formGroup]="formService.form" class="custom-form item-master">
  <div class="row">
    <div [ngClass]="dataService.problemType.shortcut == 'cvrp'? 'col-md-6' : 'col-md-12'">
      <div class="row">
        <div class="col-md-12 tab-header">
          @if (dataService.problemType.shortcut == 'tsp') {
            <mat-checkbox class="tab-title" [checked]="true" color="primary"
              [formControl]="formService.form.get('isCircular')"
              (change)="changeIsCircular($event)">
              <span>{{"Circular route" | translate }}</span>
            </mat-checkbox>
          }
          <h3 class="tab-title actions-cell">
            {{"SourceAndDestinations" | translate}}
            <button class="add-btn" type="button" mat-icon-button color="primary"
              (click)="addNewControl()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </h3>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="source-destination-table-wrapper">
            @if (dataService.typeOfMap != 'graph') {
              <table class="table table-sm table-striped hide-subscript">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-left">{{"Name" | translate}}</th>
                    @if (dataService.typeOfMap == 'graph') {
                      <th>{{"Node" | translate}}</th>
                    } @else {
                      <th>{{"Longitude" | translate}}</th>
                      <th>{{"Latitude" | translate}}</th>
                    }
                    @if (dataService.problemType.shortcut == 'cvrp') {
                      <th class="quantity-cell">{{"Quantity" | translate}}</th>
                    }
                    @if (dataService.typeOfMap != 'graph') {
                      <th>{{"Edit" | translate}}</th>
                    }
                    <th>{{"Delete" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">
                      <span class="rowNameLabel">{{ "Source" | translate }}</span>
                    </td>
                    @if (dataService.typeOfMap != 'graph') {
                      <td>
                        <mat-form-field appearance="outline" color="primary">
                          <mat-label>{{"Longitude" | translate}}</mat-label>
                          <input type="number" matInput min=0
                            [formControl]="formService.form.get('source').get('longitude')"
                            (change)="changeSourceLongitude($event)" />
                          </mat-form-field>
                        </td>
                      }
                      <td>
                        <mat-form-field appearance="outline" color="primary">
                          <mat-label>
                            @if (dataService.typeOfMap == 'graph') {
                              {{"Node" | translate}}
                            } @else {
                              {{"Latitude" | translate}}
                            }
                          </mat-label>
                          <input type="number" matInput min=0
                            [formControl]="formService.form.get('source').get('latitude')"
                            (change)="changeSourceLatitude($event)" />
                          </mat-form-field>
                        </td>
                        @if (dataService.problemType.shortcut == 'cvrp') {
                          <td>
                          </td>
                        }
                        @if (dataService.typeOfMap != 'graph') {
                          <td>
                            <button class="custom-flat-btn table-flat-btn" mat-flat-button color="primary"
                              (click)="openModal(null)">{{"Choose" | translate}}
                            </button>
                          </td>
                        }
                        <td>
                        </td>
                      </tr>
                      @for (item of formService.form.get('destinations')['controls']; track item; let i = $index) {
                        <tr>
                          @if (dataService.problemType.shortcut != 'ptp') {
                            <td class="text-left">
                              <span class="rowNameLabel">{{ "Destination" | translate }}
                              {{ i + 1 }}</span>
                            </td>
                          } @else {
                            @if (i==0) {
                              <td class="text-left">
                                <span class="rowNameLabel">{{ "Destination" | translate }}</span>
                              </td>
                            } @else {
                              <td class="text-left">
                                <span class="rowNameLabel">{{ "InBetweenRoute" | translate }}
                                {{ i }}</span>
                              </td>
                            }
                          }
                          @if (dataService.typeOfMap != 'graph') {
                            <td>
                              <mat-form-field appearance="outline" color="primary">
                                <mat-label>{{"Longitude" | translate}}</mat-label>
                                <input type="number" matInput min=0
                                  [formControl]="formService.form.get('destinations')['controls'][i].get('longitude')"
                                  (change)="changeDestinationLongitude($event, i)" />
                                </mat-form-field>
                              </td>
                            }
                            <td>
                              <mat-form-field appearance="outline" color="primary">
                                <mat-label>
                                  @if (dataService.typeOfMap == 'graph') {
                                    {{"Node" | translate}}
                                  } @else {
                                    {{"Latitude" | translate}}
                                  }
                                </mat-label>
                                <input type="number" matInput min=0
                                  [formControl]="formService.form.get('destinations')['controls'][i].get('latitude')"
                                  (change)="changeDestinationLatitude($event, i)" />
                                </mat-form-field>
                              </td>
                              @if (dataService.problemType.shortcut == 'cvrp') {
                                <td class="quantity-cell">
                                  <mat-form-field class="quantity" appearance="outline" color="primary">
                                    <mat-label>
                                      {{"Quantity" | translate}}
                                    </mat-label>
                                    <input type="number" matInput min=0
                                      [formControl]="formService.form.get('destinations')['controls'][i].get('quantity')"
                                      (change)="changeDestinationQuantity($event, i)" />
                                    </mat-form-field>
                                  </td>
                                }
                                @if (dataService.typeOfMap != 'graph') {
                                  <td>
                                    <button class="custom-flat-btn table-flat-btn" mat-flat-button color="primary"
                                      (click)="openModal(i)">{{"Choose" | translate}}
                                    </button>
                                  </td>
                                }
                                <td class="actions-cell">
                                  @if (i > 0) {
                                    <button mat-icon-button color="warn"
                                      (click)="removeDestination(i)" type="button">
                                      <mat-icon>remove_circle</mat-icon>
                                    </button>
                                  }
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      } @else {
                        <app-controls-for-graph></app-controls-for-graph>
                      }
                    </div>
                  </div>
                </div>
              </div>

              @if (dataService.problemType.shortcut == 'cvrp') {
                <div class="col-md-6 cvrp-part">
                  <div class="row">
                    <div class="col-md-12 tab-header tab-cvrp">
                      <h3 class="tab-title actions-cell">
                        {{"Trucks" | translate}}
                        <button class="add-btn" type="button" mat-icon-button color="primary"
                          (click)="addTruckControl()">
                          <mat-icon>add_circle</mat-icon>
                        </button>
                      </h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="source-destination-table-wrapper">
                        <table class="table table-sm table-striped hide-subscript">
                          <thead class="thead-dark">
                            <tr>
                              <th>{{"NumberOfTrucks" | translate}}</th>
                              <th>{{"QuantityOfTrucks" | translate}}</th>
                              <th>{{"Delete" | translate}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            @for (item of formService.form.get('trucks')['controls']; track item; let i = $index) {
                              <tr>
                                <td>
                                  <mat-form-field appearance="outline" color="primary" class="quantity">
                                    <mat-label>{{"NumberOfTrucks" | translate}}</mat-label>
                                    <input type="number" matInput min=1
                                      [formControl]="formService.form.get('trucks')['controls'][i].get('truckNumber')"
                                      (change)="changeTruckNumber($event, i)" />
                                    </mat-form-field>
                                  </td>
                                  <td>
                                    <mat-form-field appearance="outline" color="primary" class="quantity">
                                      <mat-label>
                                        {{"QuantityOfTrucks" | translate}}
                                      </mat-label>
                                      <input type="number" matInput min=1
                                        [formControl]="formService.form.get('trucks')['controls'][i].get('truckQuantity')"
                                        (change)="changeTruckQuantity($event, i)" />
                                      </mat-form-field>
                                    </td>
                                    <td class="actions-cell">
                                      @if (i > 0) {
                                        <button mat-icon-button color="warn"
                                          (click)="removeTruckControl(i)" type="button">
                                          <mat-icon>remove_circle</mat-icon>
                                        </button>
                                      }
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </form>
