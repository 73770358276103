<div class="row">
  <div class="col-md-12 section-content">
    <app-header-dashboard-cell-formation-problem>
    </app-header-dashboard-cell-formation-problem>
    @if (formService.isShowMainTabs) {
      <app-tabs-dashboard-cell-formation-problem>
      </app-tabs-dashboard-cell-formation-problem>
    }
  </div>
</div>
