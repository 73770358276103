<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 source-destination-header">
                <h3 class="source-destination-title">{{"Source" | translate}} 
                </h3> 
                <button mat-flat-button color="accent"
                    (click)="openModal()">{{"Choose" | translate}}
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 source-destination-label">
                <table class="table table-sm">
                    <tbody>
                        <tr>
                            <td>{{"Longitude" | translate}}:</td>
                            <td>{{ dataService.source[1] == null ? "-" : dataService.source[1] | number : '1.2-7' }}</td>
                        </tr>
                        <tr>
                            <td>{{"Latitude" | translate}}:</td>
                            <td>{{ dataService.source[0] == null ? "-" : dataService.source[0] | number : '1.2-7' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <hr class="source-destination-divider" />
    </div>
</div>