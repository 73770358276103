<div class="row">
    <div class="col-sm-12 header-title-box">
        <h3 class="page-header-title"> <span>{{"Routing" | translate}}</span> / {{"GeoLocation" | translate}}</h3>
        <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="openMarkdown()">
            <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
        </button>
    </div>
</div>
