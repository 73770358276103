import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'checkboxColumn'
})
export class CheckboxColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.index == 'select';
  }

}
