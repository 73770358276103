import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlQapFlowsTableService {

  constructor() { }

  private fixedColumns: any[] = [];
  private fixedColumns1: any[] = [];
  private fixedColumns2: any[] = [];
  headersTable: any[] = this.fixedColumns;
  headersTable1: any[] = this.fixedColumns1;
  headersTable2: any[] = this.fixedColumns1;


  createDynamicHeaderColumnsTable(numberCol: number) {
    this.fixedColumns1 = [];
    if (numberCol) {
      for (let i = 1; i <= numberCol; i++) {
        this.fixedColumns1.push({ "index": i, title: i });
      }
      this.headersTable1 = this.fixedColumns1;
    }
  }

  createDynamicHeaderColumnsTable2(numberCol: number) {
    this.fixedColumns2 = [];
    if (numberCol) {
      for (let i = 1; i <= numberCol; i++) {
        this.fixedColumns2.push({ "index": i, title: i });
      }
      this.headersTable2 = this.fixedColumns2;
    }
  }

  createDynamicReducedHeaderColumnsTable(dataArr: any) {
    this.fixedColumns = [];
    if (dataArr) {
      for (let i = 0; i < dataArr.length; i++) {
        this.fixedColumns.push({ "index": i, title: dataArr[i] });
      }
      this.headersTable = this.fixedColumns;
    }
  }
}
