import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardDeterministicMrpComponent } from './components/dashboard-deterministic-mrp/dashboard-deterministic-mrp.component';
import { DashboardMrpReportComponent } from './components/dashboard-mrp-report/dashboard-mrp-report.component';
import { RoleGuard } from '../../core/guards/role.guard';
import { UpgradeYourBrowserComponent } from './components/dashboard-mrp-report/upgrade-your-browser/upgrade-your-browser.component';

const routes: Routes = [
  {
    path: "dashboard-deterministic-mrp",
    component: DashboardDeterministicMrpComponent,
    canActivate: [RoleGuard],
    data: {section: "mrp", child: "dashboard-deterministic-mrp" }
  },
  {
    path: "dashboard-mrp-report",
    component: DashboardMrpReportComponent,
    canActivate: [RoleGuard],
    data: {section: "mrp", child: "dashboard-mrp-report" }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeterministicMrpRoutingModule { }
