<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 formHeading">
      <h3 class="modalHeading">
        {{"CFP" | translate}}
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <form [formGroup]="formService.formFilter" class="custom-form type-form">
        <mat-form-field appearance="outline" [ngClass]="formService.formFilter.get('problemTypes').errors ? 'requiredField' : ''">
          <mat-label>{{ "Instance Type" | translate}}</mat-label>
          <mat-select [formControlName]="form.keys.problemTypes" disableOptionCentering
                      panelClass="custom-mat-select-panel-class">
            @for (option of dropdownData.ProblemTypes; track option) {
              <mat-option [value]="option.type">
                {{option.title}}
              </mat-option>
            }
          </mat-select>
          <mat-error class="customError">
            @if (formService.formFilter.get('problemTypes').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  @if (isOptionSelected == true) {
    <div class="row">
      <div class="col-sm-12 table-action-box">
          <app-search class="search-box"
            [dataService]="dataService">
          </app-search>
          <app-show-hide-columns class="action-box"
            [tableService]="tableService">
          </app-show-hide-columns>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 table-box cfp-table">
        <app-table
          [apiService]="apiService"
          [tableService]="tableService"
          [dataService]="dataService"
          (refillStorage)="refreshPage()">
        </app-table>
      </div>
    </div>
  }
</div>
