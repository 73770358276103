import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { INPUT_METHOD } from '../../../../../../config/settings/inputMethod';
import { FileType } from '../../../../../../shared/enums/file-type';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IBackpackProblemConfigForm, IBackpackProblemDropdownData } from '../../../../interfaces/i-backpack-problem';
import { BlBackpackProblemFormService } from '../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { BackpackHelperFunctionsService } from '../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-form-backpack-problem',
  templateUrl: './form-backpack-problem.component.html',
  styleUrls: ['./form-backpack-problem.component.scss']
})
export class FormBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<FormBackpackProblemComponent>,
    public formService: BlBackpackProblemFormService,
    private matDialog: MatDialog,
    private alertService: AlertMessageDataService,
    private dataService: BlBackpackProblemDataService,
    private translateService: TranslateService,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();

  public formConfig: FormGroupTypeSafe<IBackpackProblemConfigForm> = this.formService.getFormConfigReference();

  public dropdownData: IBackpackProblemDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    Methods: null
  }

  public fileData: FormData;
  public fileName: any = "";
  public countFiles: any = 0

  public allowedFileTypes: string[] = [FileType.csv]

  ngOnInit(): void {
    this.dropdownData.Methods = INPUT_METHOD;

    this.formConfig.getSafe(x => x.inputMethod).valueChanges.subscribe({
      next: val => {
        if(val == 1) {
          this.formService.isImportMethod = true;
          this.formService.formConfig.get("title").setValue("");
          this.translateService.get("File not chosen.").subscribe({
            next: data => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          });
        } else if (val == 0) {
          this.formService.isImportMethod = false;
          this.translateService.get("File not chosen.").subscribe({
            next: data => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          });
        }
      }
    });
  }

  onFileChanged(file){
    this.fileData = file;
    this.countFiles = 1;
  }

  close(state?: any) {
    this.ref.close(state);
  }

  save() {
    this.dataService.show3dTable = false;
    this.dataService.selectedIndex = 0;

    this.formService.createContainersTableWithDynamicRows(4, 1);
    this.formService.createItemsTableWithDynamicRows(5, 1);


    if(this.formService.isImportMethod == false) {
      let formValue: any = this.formService.formConfig.value;

      this.subscription.add(
        this.dataService.originalObject.next(formValue)
      );

      this.dataService.setIdForPatch(-1);
      this.alertService.setMessage("success", "Successfully applied.");
      this.close(this.formConfig.getSafe(x => x.title).value);

      this.formService.isRunProblemForTitle = false;
      this.formService.problemTitle = "";

      this.dataService.showDashboard = true;

    } else {
      if(this.fileData) {

        SpinnerFunctions.showSpinner();
        this.formService.uploadCSV(this.fileData).subscribe({
          next: success => {
            this.backpackHelperFunctionsService.breakApartStavke(success);

            this.dataService.originalObject.next(success);

            this.alertService.setMessage("success", "Successfully uploaded CSV.");
            this.countFiles = 0;
            this.close(success["title"]);
            SpinnerFunctions.hideSpinner();


            this.dataService.showDashboard = true;

          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
          }
        });
      } else {
        this.alertService.setMessage("danger", "You must select a CSV file and then upload it.")
      }
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.formConfig.get("title").setValue("");
    this.countFiles = 0;
    this.formService.formConfig.get("inputMethod").setValue(0);
  }

}
