<div class="dashboard-content">
    <div class="row drag-drop-wrapper">
    </div>
    <div class="row">
        <div class="col-md-6">
            <app-left-table-backpack-problem></app-left-table-backpack-problem>
        </div>
        <div class="col-md-6">
            <app-right-table-backpack-problem></app-right-table-backpack-problem>
        </div>
    </div>
</div>
