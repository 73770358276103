<!-- Header Dialog-->
<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<form class="reducedForm" [formGroup]="formService.form" (ngSubmit)="save()">
    <div mat-dialog-content>

        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{"Add" | translate}} {{dataService.problemType.title | translate}}
                </h3>
            </div>
        </div>

        <div class="row" *ngIf="dataService.problemType.shortcut == 'sssp' || dataService.problemType.shortcut == 'ptp'">
            <div class="col-md-12 input-methods">
              <label class="sub-title">{{"Input Methods" | translate}}:</label>
              <mat-radio-group [formControlName]="form.keys.inputMethod">
                <mat-radio-button [value]="data.id" *ngFor="let data of methods">{{data.title | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>
        </div>

        <div class="row" [ngClass]="!formService.isImportMethod ? 'showDiv' : 'hideDiv'">
            <div class="col-sm-12">

                <div class="row">
                    <div class="col-sm-6">
                        <mat-form-field appearance="outline" color="accent"
                            [ngClass]="formService.form.get('mapType').errors ? 'requiredField' : null">
                            <mat-label>{{"TypeOfMap" | translate}}</mat-label>
                            <mat-select [formControlName]="form.keys.mapType" disableOptionCentering>
                                <mat-option *ngFor="let option of mapTypes" [value]="option.value">
                                    {{option.title | translate}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="customError">
                                <ng-container *ngIf="formService.form.get('mapType').hasError('required')">
                                    {{"ThisFieldIsRequired" | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6">
                        <mat-form-field color="primary" appearance='outline' [ngClass]="formService.form.get('name').errors ? 'requiredField' : null">
                            <mat-label>{{'Title' | translate}}</mat-label>
                            <input matInput [formControlName]="formService.form.keys.name" />
                            <mat-error class="customError">
                                <ng-container *ngIf="formService.form.get('name').hasError('required')">
                                    {{"ThisFieldIsRequired" | translate}}
                                </ng-container>
                                <ng-container *ngIf="formService.form.get('name').hasError('noWhiteSpace')">
                                    {{"NoWhiteSpace" | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>
        </div>

        <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
            <div class="col-md-12 inputfile-wrapper">
                <app-common-file-upload (onFileChanged)="onFileChanged($event)" [allowedFileTypes]="allowedFileTypes"></app-common-file-upload>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button disableRipple color="accent" type="submit"
                [disabled]="form.invalid && !formService.isImportMethod">{{'Apply' | translate}}</button>
        <button mat-flat-button disableRipple type="button" color="warn"
                [mat-dialog-close]="true">{{'Close' | translate}}</button>
    </div>

</form>
