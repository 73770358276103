import { Injectable } from '@angular/core';
import { IChangePasswordForm, IChangePasswordRequest } from '../../interfaces/i-change-password';
import { BlChangePasswordRequestsService } from '../requests/bl-change-password-requests.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordService } from '../api/change-password.service';
import { Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';

@Injectable({
  providedIn: 'root'
})
export class BlChangePasswordFormService implements IFormService<IChangePasswordForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private requestsService: BlChangePasswordRequestsService,
    private matDialog: MatDialog,
    private apiService: ChangePasswordService
  ) {}

  form: FormGroupTypeSafe<IChangePasswordForm> = this.init();
  public q = "";
  public strength: number = 0;


  init(): FormGroupTypeSafe<IChangePasswordForm> {
    return this.formBuilder.group<IChangePasswordForm>({
      oldPassword: this.formBuilder.control("", Validators.required),
      password: this.formBuilder.control("", Validators.required),
      passwordConfirm: this.formBuilder.control("", Validators.required),

    });
  }

  getFormReference(): FormGroupTypeSafe<IChangePasswordForm> {
    return this.form;
  }

  prepareDataToSend(): IChangePasswordRequest {
    let formValue: IChangePasswordForm = this.form.value;

    let dataToSend: IChangePasswordRequest = {
      oldPassword: formValue.oldPassword,
      password: formValue.password
    };

    return dataToSend;
  }

  passwordValidation(): void {
    const password = this.form.get('password').value;
    let confirmPasswordValue = this.form.get('passwordConfirm').value;
    
    if (password !== confirmPasswordValue) {
        this.form.get('passwordConfirm').setErrors( {MatchPassword: true} );
    } else {
      this.form.get('passwordConfirm').setErrors(null);
    }
  }

  changeCurrentPass(): void {
    this.passwordValidation();

    if(this.strength < 100)
      this.form.get("password").setErrors({'validatePass': true});
    else
      return this.form.get("password").setErrors(null);
  }

  passStrengthChanged(strength): void {
    this.strength = strength;
  }

  validatePass(): {} {
    if(this.strength < 100) 
     return {'validatePass': true};
    else
      return null;
  }

  submitInsert() {
    let dataToSend: IChangePasswordRequest = this.prepareDataToSend();
    return this.requestsService.insert(dataToSend, "Password", this.apiService, null);
  }

  close(): void {
    this.matDialog.closeAll();
  }

  reset(): void {
    this.form = this.init();
  }

}