import { FilterOperators } from '../consts/filter-operators';
import { FilterType } from '../consts/filter-type';

export interface IFilterColumn {
    column : string,
    value: any,
    operator?: FilterOperators,
    comparer?: IFilterComparer,
    filter_type?: FilterType ,
    default?: any,
    isServerFiltering?: boolean
}

export enum IFilterComparer {
    less = '<',
    more = '>',
    lessOrEqual = "<=",
    moreOrEqual = ">=",
    default = "=="
}