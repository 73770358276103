import { TranslateService } from '@ngx-translate/core';
import { ArrayFunctions } from '../functions/array-functions';

export class PrintDeletedOptionHelper {

    private props: any = {
        id: 'ID',
        title: 'Title'
    };
    private translateService: TranslateService;

    constructor(translateService: TranslateService, props: any = null){
        this.translateService = translateService;
        if(props){
            this.props = props;
        }
    }

    getDeletedOptionHtml(data: any){
        if(!data) return "";
        
        let html = "<ul style='font-size: 10px; list-style-type: none; width: 90%; margin: 0px auto; padding: 0px;'>";

        let keys = Object.keys(this.props);
        Object.keys(data).filter(x=> ArrayFunctions.inArray(x, keys)).forEach(key => {
          html += this.getListItemHtml(key, data);
        });
        html += "</ul>";
        return html;
    }
    
    private getListItemHtml(key: string, data: any){
    return  `<li> <b>${this.translateService.get(this.props[key])['value']}:</b> ${  this.printKeyValue(data[key]) } </li>`;
    }

    private printKeyValue(value){
    if(!value){
        return "";
    }
    if(Array.isArray(value)) {
        let elements = [];
        for(let element of value){
        elements.push(element['title']);
        }
        return elements.join(", ");
    }
    return value;
    }
}