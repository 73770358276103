<!-- Table Cost Analysis Report Table Tab -->
<div class="row">
        <div class="col-md-12 tab-wrapper">
            <ng-container *ngIf="dataFromService.costAnalysis.length > 0; else noAllTableData;">
                <ng-container *ngFor="let data of dataFromService.costAnalysis; let i = index;">
                    <div class="row">
                        <div class="col-sm-12 table-header">
                            <p *ngIf="data.nazivTabele" class="table-title" [innerHTML]="data.nazivTabele"></p>
                            <p class="table-title" [innerHTML]="data.opis"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 table-wrapper">
                            <table class="table table-sm">
                                <thead class="thead-dark">
                                    <tr>
                                        <th *ngFor="let header of data.table.tableHeader">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let tableRow of data.table.tableBody">
                                        <ng-container *ngFor="let columns of tableRow.stavke">
                                                <td [ngStyle]="(columns.style != '')? {'background': columns.style}: {'background': '#fff'}">{{columns.value}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #noAllTableData>
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning" role="alert">
                            {{"NoRecordsFound" | translate}}
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <!-- End Cost Analysis Report Table Tab -->