<div class="graphSourceDestinationBlock">
    <div>
        <mat-form-field appearance="outline" color="accent">
            <mat-label>
                {{ "Source" | translate }}
            </mat-label>
            <input type="number" matInput min="0" [formControl]="formService.form.get('graphSource')" />
        </mat-form-field>
    </div>

    <div *ngIf="dataService.problemType.shortcut === 'ptp'">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>
                {{ "Destination" | translate }}
            </mat-label>
            <input type="number" matInput min="0" [formControl]="formService.form.get('graphDestination')" />
        </mat-form-field>
    </div>

    <div *ngIf="dataService.isEdit" class="nodesFilterBlock">

                <mat-form-field appearance="outline" color="accent">
                    <mat-label>
                        {{"NodesSeparatedByComma" | translate}}
                    </mat-label>
                    <input matInput [formControl]="formService.form.get('filterByNodes')" />
                </mat-form-field>

                <button class="filterNodesBtn" type="button" mat-flat-button color="accent"
                    (click)="filterByNodes()">{{"Filter" | translate}}</button>

    </div>
</div>

<div class="graph-table-wrapper">
    <table class="table table-sm table-striped">
        <thead class="thead-dark">
            <tr>
                <th>{{ "V1" | translate }}</th>
                <th>{{ "V2" | translate }}</th>
                <th>{{ "W" | translate }}</th>
                <th>{{ "Delete" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="
          let item of formService.form.get('graph')['controls'];
          let i = index
        ">
                <tr>
                    <td>
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>
                                {{ "V1" | translate }}
                            </mat-label>
                            <input type="number" matInput min="0" [formControl]="
                  formService.form.get('graph')['controls'][i].get('v1')
                " />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>
                                {{ "V2" | translate }}
                            </mat-label>
                            <input type="number" matInput min="0" [formControl]="
                  formService.form.get('graph')['controls'][i].get('v2')
                " />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>
                                {{ "W" | translate }}
                            </mat-label>
                            <input type="number" matInput min="0" [formControl]="
                  formService.form.get('graph')['controls'][i].get('w')
                " />
                        </mat-form-field>
                    </td>

                    <td>
                        <button class="removeDestinationBtn" *ngIf="i > 0" mat-mini-fab color="warn"
                            (click)="removeGraph(i)" type="button">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
