import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormArrayTypeSafe, FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { onlyIntegerValidator } from '../../../../shared/validators/only-integer.validator';
import { IDistribution1ToNSourceDestinationsTabForm } from '../../interfaces/i-distribution-1-to-n-source-destinations-tab';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNSourceDestinationsTabFormService implements IFormService<IDistribution1ToNSourceDestinationsTabForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private dataService: BlDistribution1ToNDataService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNSourceDestinationsTabForm> = this.init();
  isEdit: boolean = false;

  init(): FormGroupTypeSafe<IDistribution1ToNSourceDestinationsTabForm> {
    const group: FormGroupTypeSafe<IDistribution1ToNSourceDestinationsTabForm> = this.formBuilder.group<IDistribution1ToNSourceDestinationsTabForm>({
      source: new UntypedFormGroup({
        longitude: this.formBuilder.control("", Validators.required),
        latitude: this.formBuilder.control("", Validators.required)
      }),
      destinations: this.formBuilder.array([]),
      trucks: this.formBuilder.array([]),
      graph: this.formBuilder.array([]),
      graphSource: this.formBuilder.control("", Validators.required),
      graphDestination: this.formBuilder.control("", Validators.required),
      filterByNodes: this.formBuilder.control(""),
      isCircular: this.formBuilder.control(1)
    });
    return group;
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNSourceDestinationsTabForm> {
    return this.form;
  }

  fillForm() {
    this.setSource();
    this.createDestinationsControls();
    this.setIsCircular();

    this.form.removeControl("trucks");
    this.form.addControl("trucks", this.formBuilder.array([]));
    if(this.dataService.trucks) {
      for(let truck of this.dataService.trucks)
      this.addTruckControl(truck.truckNumber, truck.truckQuantity);
    }
  }

  setIsCircular(): void {
    this.dataService.isCircular = 1;
    this.form.getSafe(x => x.isCircular).setValue(this.dataService.isCircular);
  }

  setSource() {
    this.form.getSafe(x => x.source).get("longitude").setValue(this.dataService.source[1]);
    this.form.getSafe(x => x.source).get("latitude").setValue(this.dataService.source[0]);
  }

  createDestinationsControls(): void {
    this.form.removeControl("destinations");
    this.form.addControl("destinations", this.formBuilder.array([]));
    for(let i=0; i<this.dataService.allDestinations.length; i++) {
      (this.form.getSafe(x => x.destinations) as UntypedFormArray).push(
        new UntypedFormGroup({
          longitude: this.formBuilder.control(this.dataService.allDestinations[i][1], Validators.required),
          latitude: this.formBuilder.control(this.dataService.allDestinations[i][0], Validators.required),
          quantity: this.formBuilder.control(this.dataService.destinationsQuantity == null ? null : this.dataService.destinationsQuantity[i], [Validators.required, onlyIntegerValidator])
        })
      )
    }
    ValidationFunctions.runValidation(this.form);
  }

  removeDestinationControl(index: number): void {
    (<UntypedFormArray>(this.form.getSafe(x => x.destinations))).removeAt(index);
  }

  addDestinationControl(): void {
    (this.form.getSafe(x => x.destinations) as UntypedFormArray).push(
      new UntypedFormGroup({
        longitude: this.formBuilder.control("", Validators.required),
        latitude: this.formBuilder.control("", Validators.required),
        quantity: this.formBuilder.control("", [Validators.required, onlyIntegerValidator])
      })
    );
    ValidationFunctions.runValidation(this.form);
  }

  createFirstTruckControl(truckNumber: number) {
    this.form.removeControl("trucks");
    this.form.addControl("trucks", this.formBuilder.array([]));
    this.addTruckControl(truckNumber);
    ValidationFunctions.runValidation(this.form);
  }

  addTruckControl(truckNumber: number = null, truckQuantity: number = null): void {
    (this.form.getSafe(x => x.trucks) as UntypedFormArray).push(
      new UntypedFormGroup({
        truckNumber: this.formBuilder.control(truckNumber != null ? truckNumber : "", [Validators.required, onlyIntegerValidator, Validators.min(1)]),
        truckQuantity: this.formBuilder.control(truckQuantity != null ? truckQuantity : "", [Validators.required, onlyIntegerValidator, Validators.min(1)])
      })
    );
    ValidationFunctions.runValidation(this.form);
  }

  removeTruckControl(index: number): void {
    (<UntypedFormArray>(this.form.getSafe(x => x.trucks))).removeAt(index);
  }


  createGraphControls(): void {
    this.form.removeControl("graph");
    this.form.addControl("graph", this.formBuilder.array([]));
    for(let i=0; i<this.dataService.allGraphs.length; i++) {
      (this.form.getSafe(x => x.graph) as UntypedFormArray).push(
        new UntypedFormGroup({
          v1: this.formBuilder.control(this.dataService.allGraphs[i][0], Validators.required),
          v2: this.formBuilder.control(this.dataService.allGraphs[i][1], Validators.required),
          w: this.formBuilder.control(this.dataService.allGraphs[i][2], Validators.required)
        })
      )
    }
    ValidationFunctions.runValidation(this.form);
  }

  removeGraphControl(index: number): void {
    (<UntypedFormArray>(this.form.getSafe(x => x.graph))).removeAt(index);
  }

  addGraphControl(): void {
    (this.form.getSafe(x => x.graph) as UntypedFormArray).push(
      new UntypedFormGroup({
        v1: this.formBuilder.control("", Validators.required),
        v2: this.formBuilder.control("", Validators.required),
        w: this.formBuilder.control("", Validators.required)
      })
    );
    ValidationFunctions.runValidation(this.form);
  }

  reset(): void {
    this.form.reset();
  }

}
