@if (!isSpinnerVissible) {
  <mat-form-field appearance="outline" [ngClass]="(formService.form.get(controlName).invalid)? 'requiredField': false">
    <mat-label>{{labela | translate}}</mat-label>
    <mat-select [multiple]="multiple ? true : false" (openedChange)="openedChange($event)" class="virtual-scroll"
      [formControl]="formService.form.get(controlName)" disableOptionCentering>
      <cdk-virtual-scroll-viewport class="example-viewport-select" minBufferPx="200" maxBufferPx="400" [itemSize]="50">
        @if (multiple) {
          <mat-option [value]="defaultOptions" #allSelect (click)="formService.selectAll(allSelect.selected, controlName, dropdownData)">
            {{"SelectAll" | translate}}
          </mat-option>
          <mat-option *cdkVirtualFor="let option of data" [value]="option.id" [disabled]="disabledOptions">
            {{option.title}}
          </mat-option>
        } @else {
          <mat-option *cdkVirtualFor="let option of data" [value]="option.id">
            {{option.title}}
          </mat-option>
        }
      </cdk-virtual-scroll-viewport>
    </mat-select>
    @if (formService.form.get(controlName).hasError('required')) {
      <mat-error class="customError">
        {{'ThisFieldIsRequired' | translate}}
      </mat-error>
    }
  </mat-form-field>
} @else {
  <app-input-with-spinner inputLabel="{{labela | translate}}"></app-input-with-spinner>
}
