import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'formControl'
})
export class FormControlPipe implements PipeTransform {

  transform(column: ITableColumn, row: any) : boolean {
    return column.isFormControl;
  }

}
