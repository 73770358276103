import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Distribution1ToNModule } from './distribution-1-to-n/distribution-1-to-n.module';
import { GeoLocationModule } from './geo-location/geo-location.module';
import { GraphNodesModule } from './graph-nodes/graph-nodes.module';

const routes: Routes = [
  {
    path: "sssp",
    loadChildren: () => Distribution1ToNModule
  },
  {
    path: "ptp",
    loadChildren: () => Distribution1ToNModule
  },
  {
    path: "tsp",
    loadChildren: () => Distribution1ToNModule
  },
  {
    path: "cvrp",
    loadChildren: () => Distribution1ToNModule
  },
  {
    path: "geo-location",
    loadChildren: () => GeoLocationModule
  },
  {
    path: "graph-nodes",
    loadChildren: () => GraphNodesModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingRoutingModule { }
