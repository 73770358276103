<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 formHeading">
            <h3 class="modalHeading">
                {{dataService.problemType.title | translate}}
            </h3>
        </div>
    </div>

    <div class="row reducedForm">
        <div class="col-sm-6 col-md-4">
            <mat-form-field color="accent" appearance="outline">
                <mat-label>{{"Instance Type" | translate}}</mat-label>
                <mat-select (selectionChange)="changeProblemType($event)">
                    <mat-option *ngFor="let problem of problemTypes" [value]="problem.type">{{problem.title | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6 col-md-4" *ngIf="isProblemSelected">
            <mat-form-field color="accent" appearance="outline">
                <mat-label>{{"TypeOfMap" | translate}}</mat-label>
                <mat-select [value]="'all'" (selectionChange)="changeMapType($event)">
                    <mat-option *ngFor="let type of mapTypes" [value]="type.type">{{type.title | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <ng-container *ngIf="isProblemSelected">
        <div class="row">
            <div class="col-sm-6 col-md-4 searchBox">
                <app-search class="reducedForm" [dataService]="dataService"></app-search>
            </div>
            <div class="col-sm-6 col-md-8 show-hide-btn-box">
                <app-show-hide-columns class="reducedButton" [tableService]="tableService"></app-show-hide-columns>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 table-box distribution-table">
                <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService">
                </app-table>
            </div>
        </div>
    </ng-container>

</div>