import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { IDistribution1ToN } from '../../interfaces/i-distribution-1-to-n';
import { IDistribution1ToNRouteSettings, IDistribution1ToNRouteSettingsForm } from '../../interfaces/i-distribution-1-to-n-route-settings';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNRouteSettingsFormService implements IFormService<IDistribution1ToNRouteSettingsForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private dataService: BlDistribution1ToNDataService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNRouteSettingsForm> = this.init();
  isEdit: boolean = false;
  objToPatch: IDistribution1ToN;

  init(): FormGroupTypeSafe<IDistribution1ToNRouteSettingsForm> {
    let group: FormGroupTypeSafe<IDistribution1ToNRouteSettingsForm> = this.formBuilder.group<IDistribution1ToNRouteSettingsForm>({
      motorway: this.formBuilder.control("", Validators.required),
      trunk: this.formBuilder.control("", Validators.required),
      primary: this.formBuilder.control("", Validators.required),
      secondary: this.formBuilder.control("", Validators.required),
      tertiary: this.formBuilder.control("", Validators.required),
      unclassified: this.formBuilder.control("", Validators.required),
      residential: this.formBuilder.control("", Validators.required)
    });
    return group;
  }

  fillForm(): void {
    this.form.getSafe(x => x.motorway).setValue(this.dataService.settingsObj.motorway);
    this.form.getSafe(x => x.trunk).setValue(this.dataService.settingsObj.trunk);
    this.form.getSafe(x => x.primary).setValue(this.dataService.settingsObj.primary);
    this.form.getSafe(x => x.secondary).setValue(this.dataService.settingsObj.secondary);
    this.form.getSafe(x => x.tertiary).setValue(this.dataService.settingsObj.tertiary);
    this.form.getSafe(x => x.unclassified).setValue(this.dataService.settingsObj.unclassified);
    this.form.getSafe(x => x.residential).setValue(this.dataService.settingsObj.residential);
  }

  createSettingsObj(): IDistribution1ToNRouteSettings {
    let formValue: IDistribution1ToNRouteSettingsForm = this.form.getRawValue();

    let dataToSend: IDistribution1ToNRouteSettings = {
      motorway: formValue.motorway,
      trunk: formValue.trunk,
      primary: formValue.primary,
      secondary: formValue.secondary,
      tertiary: formValue.tertiary,
      unclassified: formValue.unclassified,
      residential: formValue.residential
    };

    return dataToSend;
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNRouteSettingsForm> {
    return this.form;
  }

  reset(): void {
    this.form.reset();
  }
  
}