
<div class="row">

  <div class="col-sm-12 items-box">
    <span class="subtitle">{{"Items" | translate}}</span>
    <button [disabled]="isProblemLiterature" type="button" mat-stroked-button color="primary"
      class="custom-stroked-btn"
      (click)="openEditDialog()">
      <mat-icon>drive_file_rename_outline
      </mat-icon>{{"Edit" | translate}}
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="qap-table-wrapper">

      <cdk-virtual-scroll-viewport
        [ngStyle]="{'height' : dataForTable.length < 15 ? (dataForTable.length + 2)*30+'px' : '480px'}"
        style="width: 100%; overflow-y: auto;"
        minBufferPx="200"
        maxBufferPx="600"
        [itemSize]="30">
        <div>
          @if ((dataForTable && dataForTable.length) > 0) {
            <table class="table table-sm table-dynamic">
              <thead class="thead-dark">
                <tr>
                  <th>&nbsp;</th>
                  @for (header of tableService.headersTable2; track header) {
                    <th>{{header.title}}</th>
                  }
                </tr>
              </thead>
              <tbody>
                <ng-container *cdkVirtualFor="let tableRow of dataForTable; let i=index;">
                  <tr>
                    <td>{{i+1}}</td>
                    @for (columns of tableRow; track j; let j = $index) {
                      <td [ngStyle]="(i == j) ? {'background': '#fff'} : {'background': '#fff'}">
                        {{columns}}
                      </td>
                    }
                  </tr>
                </ng-container>
              </tbody>
            </table>
          } @else {
            <div class="alert alert-warning" role="alert">
              {{"NoRecordsFound" | translate}}
            </div>
          }
        </div>
      </cdk-virtual-scroll-viewport>

    </div>
  </div>
</div>


