import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { remove } from 'lodash';
import { KEY_CODES } from '../../consts/key-codes';
import { MatChipOption } from '../../interfaces/mat-chip-option';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() labelTitle = ""
  @Input() allOptions: MatChipOption[] = [];

  @Output() emitSelectedChips = new EventEmitter();

  public selectedChips: MatChipOption[] = []; //Ono sto selektujemo
  public filteredChips: MatChipOption[] = [];
  public keyCodes: number[] = [KEY_CODES.ENTER, KEY_CODES.COMMA];

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.allOptions){
      this.filteredChips = changes.allOptions.currentValue;
    }
  }

  filterChip(value: string) {
    this.filteredChips = this.allOptions.filter( option => {
      return option.title.toLowerCase().indexOf( value.toLowerCase()) !== -1 && !this.selectedChips.includes(option);
    })
  }

  selectChip(event, htmlElementRef){
    this.selectedChips.push(event.option.value);
    htmlElementRef.value = "";
    this.refreshFilterList();
    this.emitSelectedChips.emit( this.selectedChips);
  }

  removeChip(element){
    remove(this.selectedChips, element) //LODASH REMOVE
    this.refreshFilterList();
    this.emitSelectedChips.emit(this.selectedChips);
  }

  refreshFilterList(){
    this.filteredChips = this.allOptions.filter( option => {
      return !this.selectedChips.includes(option);
    })
  }


}
