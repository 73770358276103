<mat-form-field class="col-md-offset-3" class="filterLeftSideBar" appearance="outline">
  <mat-label>{{labelTitle | translate}}</mat-label>
  <mat-chip-listbox #chipListStatus>
    @for (chip of selectedChips; track chip) {
      <mat-chip-option selectable="true" removable="true"
        (removed)="removeChip(chip)">
        {{chip.title}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }

    <input #chipInput [matAutocomplete]="auto" [matChipInputFor]="chipListStatus"
      [matChipInputSeparatorKeyCodes]="keyCodes" matChipInputAddOnBlur="false"
      (keyup)="filterChip($event.target.value)" >
    </mat-chip-listbox>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectChip($event, chipInput)">
      @for (option of filteredChips; track option) {
        <mat-option [value]="option">
          {{ option.title }}
        </mat-option>
      }
    </mat-autocomplete>


  </mat-form-field>