import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BlAdminConfigRoutingRequestsService } from '../../../../../../admin-config/services/requests/bl-admin-config-routing-requests.service';
import { BlShowAreaOnMapRequestsService } from '../../../../../../admin-config/services/requests/bl-show-area-on-map-requests.service';
import { INPUT_METHOD } from '../../../../../../config/settings/inputMethod';
import { ConfirmDialogComponent } from '../../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { FileType } from '../../../../../../shared/enums/file-type';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IOption } from '../../../../../../shared/interfaces/i-option';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { mapTypes } from '../../../../../routing-common/constants/map-types';
import { IDistribution1ToNNewForm } from '../../../../interfaces/i-distribution-1-to-n-new';
import { Distribution1ToNService } from '../../../../services/api/distribution-1-to-n.service';
import { DownloadMapService } from '../../../../services/api/download-map.service';
import { RoutingCountriesService } from '../../../../services/api/routing-countries.service';
import { BlDistribution1ToNNewFormService } from '../../../../services/forms/bl-distribution-1-to-n-new-form.service';
import { BlDistribution1ToNSourceDestinationsTabFormService } from '../../../../services/forms/bl-distribution-1-to-n-source-destinations-tab-form.service';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-new-dashboard-distribution',
  templateUrl: './new-dashboard-distribution.component.html',
  styleUrls: ['./new-dashboard-distribution.component.scss']
})
export class NewDashboardDistributionComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<NewDashboardDistributionComponent>,
    public formService: BlDistribution1ToNNewFormService,
    public dataService: BlDistribution1ToNDataService,
    public sourceDestinationTabFormService: BlDistribution1ToNSourceDestinationsTabFormService,
    public leftSidebarFormService: BlLeftSidebarDistribution1ToNFormService,
    private adminConfigRoutingRequestsService: BlAdminConfigRoutingRequestsService,
    public matDialog: MatDialog,
    private translateService: TranslateService,
    private downloadMapService: DownloadMapService,
    private routingCountriesService: RoutingCountriesService,
    private alertService: AlertMessageDataService,
    private showAreaOnMapRequestsService: BlShowAreaOnMapRequestsService,
    public apiService: Distribution1ToNService
  ) { }

  form: FormGroupTypeSafe<IDistribution1ToNNewForm>;

  mapTypes = mapTypes;
  countries: IOption[] = [];
  isCountryOk: boolean = false;

  countryLongitude: number = null;
  countryLatitude: number = null;
  countryName: string = null;
  countryId: number = null;

  fileData: FormData;
  fileName: any = "";
  countFiles: any = 0
  methods: any[] = []

  private confirmDialog = {
    component: ConfirmDialogComponent,
    specification: {
      width: "370px",
      height: "auto",
      minHeight: '150px',
      panelClass: 'custom-dialog',
      data: { message: "Error" }
    }
  };

  public allowedFileTypes: string[] = [FileType.csv]

  ngOnInit(): void {
    this.formService.reset();
    this.form = this.formService.getFormReference();
    ValidationFunctions.runValidation(this.form);
    this.getAllCountries();
    this.setMapTypes();

    this.methods = INPUT_METHOD;

    this.form.getSafe(x => x.inputMethod).valueChanges.subscribe({
      next: val => {
        if (val == 1) {
          this.formService.isImportMethod = true;
          this.translateService.get("File not chosen.").subscribe({
            next: data => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          });
        } else if (val == 0) {
          this.formService.isImportMethod = false;
          this.translateService.get("File not chosen.").subscribe({
            next: data => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          });
        }
      }
    });

  }

  setMapTypes(): void {
    if (this.dataService.problemType.shortcut == 'cvrp' || this.dataService.problemType.shortcut == 'tsp') {
      this.mapTypes = cloneDeep(mapTypes).filter(x => x.value != "graph");
      this.form.getSafe(x => x.mapType).disable();
    }
    else {
      this.mapTypes = cloneDeep(mapTypes);
      this.form.getSafe(x => x.mapType).enable();
    }
  }

  showDataAfterUploadCsv(data): void {
    if (data) {
      if(this.dataService.problemType.shortcut == 'ptp')
        data.destinations.unshift(data.destinations.pop());
      this.dataService.isCustom = this.apiService.selectedProblemType == "c";
      this.dataService.isEdit = true;
      this.leftSidebarFormService.objToPatch = data;
      this.leftSidebarFormService.originalObj = cloneDeep(data);
      this.leftSidebarFormService.objToPatch["id"] = data.id;
      this.dataService.showTabs = true;
      this.leftSidebarFormService.fillForm();

      this.dataService.canRunBS.next(true);
      this.dataService.currentIdForRun = data.id;

      this.dataService.isCalculated = false;
      this.dataService.calculateFinished.next(false);
    }
  }

  close(): void {
    this.ref.close();
  }

  save(): void {
    if (this.formService.isImportMethod) {
      if (this.fileData) {
        SpinnerFunctions.showSpinner();
        this.formService.uploadCSV(this.fileData).subscribe({
          next: success => {
            if(success["poruka"] != null && success["poruka"] != "") {
              SpinnerFunctions.hideSpinner();
              this.alertService.setMessage("danger", success["poruka"]);
            }
            else {
              this.alertService.setMessage("success", "Successfully uploaded CSV.");
              this.countFiles = 0;

              this.showDataAfterUploadCsv(success);

              this.close();
              SpinnerFunctions.hideSpinner();
            }
          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
          }
        });
      } else {
        this.alertService.setMessage("danger", "You must select a CSV file and then upload it.")
      }
    }
    else {
      this.dataService.resetData();
      this.dataService.isCalculated = false;
      this.sourceDestinationTabFormService.fillForm();
      this.dataService.typeOfMap = this.formService.form.getSafe(x => x.mapType).value;
      let destinationsNumber = 1;

      if (this.dataService.problemType.shortcut == 'ptp') {
        destinationsNumber++;
      }

      this.dataService.destinationsQuantity = [null];
      for (let i = 1; i < destinationsNumber; i++) {
        this.dataService.allDestinations[i] = [null, null];
        this.dataService.destinationsQuantity.push(null);
      }

      this.leftSidebarFormService.form.getSafe(x => x.name).setValue(this.formService.form.getSafe(x => x.name).value);

      this.sourceDestinationTabFormService.createDestinationsControls();
      this.sourceDestinationTabFormService.createFirstTruckControl(this.form.getSafe(x => x.truckNumber).value);
      this.dataService.trucks[0].truckNumber = this.dataService.truckNumber = this.form.getSafe(x => x.truckNumber).value;

      this.dataService.showTabs = true;
      this.close();

      this.sourceDestinationTabFormService.createGraphControls()
      this.sourceDestinationTabFormService.form.getSafe(x => x.graphSource).setValue("");
      this.sourceDestinationTabFormService.form.getSafe(x => x.graphDestination).setValue("");
      this.sourceDestinationTabFormService.form.getSafe(x => x.filterByNodes).setValue("");
    }

  }

  getAllCountries(): void {
    SpinnerFunctions.showSpinner();
    this.adminConfigRoutingRequestsService.getAll().subscribe({
      next: data => {
        this.countries = data.free.map(x => {
          return {
            id: x.id,
            title: x.description
          }
        });
        SpinnerFunctions.hideSpinner();
      },
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    });
  }

  isCountryIsDownloaded(event): void {
    SpinnerFunctions.showSpinner();
    this.countryId = event.id;
    this.routingCountriesService.checkCountry(event.id).subscribe({
      next: data => {
        SpinnerFunctions.hideSpinner();
        this.checkCountry(data, event.title);
      },
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    });
  }

  checkCountry(data, countryName): void {
    this.isCountryOk = false;
    this.countryName = this.countryLongitude = this.countryLatitude = null;

    if (data.userMessageType == "1") {
      this.confirmDialog.specification.data = {
        message: this.translateService.instant("DoYouWantToDownloadMapFirstPart") + data.contryName + this.translateService.instant("DoYouWantToDownloadMapSecondPart")
      };

      this.matDialog.open(this.confirmDialog.component, this.confirmDialog.specification).afterClosed().subscribe({
        next: value => {
          if (value) {
            this.alertService.setMessage("success", this.translateService.instant("TheMapIsDownloadingPleaseWait"));
          }
        }
      });
    }
    else if (data.userMessageType == "-2") {
      this.alertService.setMessage("danger", this.translateService.instant("TheCountryMapIsCurrentlyUnavailable"));
    }
    else {
      this.isCountryOk = true;
      this.countryName = countryName;
      this.countryLongitude = data.longitude;
      this.countryLatitude = data.latitude;
    }

  }

  setCoordinatesForCountry(): void {
    SpinnerFunctions.showSpinner();
    this.showAreaOnMapRequestsService.getMapData(this.countryId).subscribe({
      next: data => {
        SpinnerFunctions.hideSpinner();
        this.dataService.coordinatesForSelectedCountry = data;
      },
      error: err => {
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage("danger", "Error!");
      }
    });
  }

  onFileChanged(file){
    this.fileData = file;
    this.countFiles = 1;
  }

  ngOnDestroy(): void {
    this.formService.form.getSafe(x => x.inputMethod).setValue(0);
  }

}
