import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IFormService } from '../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormArrayTypeSafe, FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { IAdminConfigRouting, IAdminConfigRoutingForm, IAdminConfigRoutingRequest, IMapDownload  } from '../../interfaces/i-admin-config-routing';
import { BlAdminConfigRoutingRequestsService } from '../requests/bl-admin-config-routing-requests.service';


@Injectable({
  providedIn: 'root'
})
export class BlAdminConfigRoutingFormService implements IFormService<IAdminConfigRoutingForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private matDialog: MatDialog,
    private requestsService: BlAdminConfigRoutingRequestsService
  ) {}

  form: FormGroupTypeSafe<IAdminConfigRoutingForm> = this.init();
  maps: IMapDownload[] = [];
  originalObj: IAdminConfigRouting[] = [];

  public hotelId: string = "";

  init(): FormGroupTypeSafe<IAdminConfigRoutingForm> {
    return this.formBuilder.group<IAdminConfigRoutingForm>({
      maps: this.formBuilder.array([])
    })
  }

  getFormReference(): FormGroupTypeSafe<IAdminConfigRoutingForm> {
    return this.form;
  }

  fillForm(maps: IMapDownload[]): void {
    this.createMapsFormControls(maps);
  }

  prepareDataToSend(): IAdminConfigRoutingRequest {
    let formValue = this.form.getRawValue() as IAdminConfigRoutingForm;
    let selectedMaps = this.maps.filter((value, index) => formValue.maps[index]).map(x => x.id);

    let dataToSend: IAdminConfigRoutingRequest = {
      maps: selectedMaps
    }

    return dataToSend;
  }

  createMapsFormControls(maps: IMapDownload[]) {
    let reportControl: UntypedFormControl = null;
    let formArray = new FormArrayTypeSafe<IMapDownload[]>([]);
    for (let i = 0; i < maps.length; i++) {
    reportControl = new UntypedFormControl(false);
    formArray.push(reportControl);
    this.form.setControlSafe(x => x.maps, formArray);
    }

    this.form.getSafe(form => form.maps).setValidators(atLeastOneCheckbox);
  }

  submitUpdate() {
    let dataToSend: IAdminConfigRoutingRequest = this.prepareDataToSend();
    return this.requestsService.updateDownloadMaps(dataToSend);
  }

  reset(): void {
    this.form = this.init();
  }

  close(): void {
		this.matDialog.closeAll();
  }

}

export function atLeastOneCheckbox(control): ValidationErrors | null {
  let found = false;
  control.value.forEach(ctrl => {
    if(ctrl != false)
    found = true;
  })
  return found ? null : {'notSelected': true};
}
