import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutSharedService } from '../layout-shared.service';
import { PermissionsService } from '../../shared/services/permissions.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit {
  constructor(
    private sharedService: LayoutSharedService,
    public permissionsService: PermissionsService
    ) {}

  public disableHome: boolean;

  private subscription = new Subscription();
  public isLeftSidebarOpen: boolean;

  ngOnInit() {
    this.disableHome = true;
    this.trackIsLeftSidebarOpen();
  }

  trackIsLeftSidebarOpen() {
    this.subscription.add(
      this.sharedService.leftSidebarState.subscribe({
        next: (data: boolean) => (this.isLeftSidebarOpen = data)
      })
    );
  }

  toggleLeftBar() {
    this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
    this.sharedService.toggleLeftBar(this.isLeftSidebarOpen);
  }

  disableFirst() {
    if (this.disableHome) {
      this.disableHome = false;
    }
  }
}
