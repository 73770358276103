import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexOfFormElementForRecursiveId'
})
export class IndexOfFormElementForRecursiveIdPipe implements PipeTransform {

  transform(formAray: any, nameOfControl: string, id: any, idRecursive: any): number {
    return formAray.controls.findIndex(item => item.get(id ? nameOfControl : 'idRecursive').value === (id ? id : idRecursive));
  }

}
