import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { IMpsForm } from '../../interfaces/i-mps';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BlMpsFormService implements IFormService<IMpsForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  form: FormGroupTypeSafe<IMpsForm> = this.init();

  public isFromViewDialog: boolean = false;
  public rowData: any = null;
  public numberRows: number = 0;
  public numberCols: number = 0;
  public typePlanning: string = "";
  public colSpan: number = 0;

  init(): FormGroupTypeSafe<IMpsForm> {
    let group: FormGroupTypeSafe<IMpsForm> = this.formBuilder.group<IMpsForm>({
      rowsTab3: this.formBuilder.array([])
    });

    ValidationFunctions.runValidation(group);
    return group;
  }

  getFormReference(): FormGroupTypeSafe<IMpsForm> {
    return this.form;
  }

  setRows(rowData: any) {
    this.form.removeControl("rowsTab3");
    this.form.addControl("rowsTab3", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.rows;
      let row3: any = rowData.rowsTab3;
      let numberRows: any = rowData.noProductPartItems;
      let numberCols: any = rowData.noPlanningPerod;

      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rowsTab3) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl({ value: row[i].itemId, disabled: true }),
            requirement: new UntypedFormControl(row3[i].requirement),
          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.rowsTab3) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row3[i]["c" + j]));
        }
      }
      (this.form.get("rowsTab3") as UntypedFormArray).controls = [...(this.form.get("rowsTab3") as UntypedFormArray).controls];
    }
  }
  createTableWithDynamicRows(rowData: any, numberRows: number) {
    this.removeTableDYnamicRows();
    if (numberRows) {
      let row: any = rowData.rows;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rowsTab3) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl({ value: row[i].itemId, disabled: true }),
            requirement: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
          })
        );
        for (let j = 0; j < rowData.noPlanningPerod; j++) {
          ((this.form.getSafe(x => x.rowsTab3) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(""));
        }
      }
      (this.form.get("rowsTab3") as UntypedFormArray).controls = [...(this.form.get("rowsTab3") as UntypedFormArray).controls];
    }
  }

  removeTableDYnamicRows() {
    this.form.removeControl("rowsTab3");
    this.form.addControl("rowsTab3", this.formBuilder.array([]));
  }

  reset(): void {
    this.form = this.init();
  }
}
