<div class="row dashboard-content source-destination">
    <div class="col-md-12 section-content">
        <app-header-backpack-problem>
        </app-header-backpack-problem>
            <div class="row"  *ngIf="dataService.showDashboard">
                <div class="col-md-12">
                    <mat-tab-group dynamicHeight="true" class="routingMapTab" [selectedIndex]="dataService.selectedIndex">
                        <mat-tab label="{{'3DPacking' | translate}}">
                            <app-tables-backpack-problem>
                            </app-tables-backpack-problem>
                        </mat-tab>
                        <ng-container *ngIf="dataService.show3dTable">
                            <mat-tab label="{{'Results' | translate}}" >
                                <app-three-d-table-backpack-problem></app-three-d-table-backpack-problem>
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                </div>
            </div>
    </div>
</div>
