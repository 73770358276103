<div class="buttonsWithSpinner">
  @if (!isClickedSave) {
    <button mat-flat-button color="primary" type="submit" [class.insert]="!(this.data)" [disabled]="(!this.form.valid)"
      [class.update]="this.data" [ngClass]="(this.hideButton==true)? 'hide': ''">
      @if (buttonText) {
        {{ buttonText | translate }}
      } @else {
        {{'Save' | translate}}
      }
    </button>
  } @else {
    <button [disabled]="true" mat-flat-button class="disabledSpinner">
      @if (buttonText) {
        <span>{{"Running" | translate}}</span>&nbsp;&nbsp;<mat-spinner class="spinnerStyle" [diameter]="17"></mat-spinner>
      } @else {
        <span>{{"Saving" | translate}}</span>&nbsp;&nbsp;<mat-spinner class="spinnerStyle" [diameter]="17"></mat-spinner>
      }
    </button>
  }
  <button mat-flat-button type="button" color="warn" [mat-dialog-close]="true">{{'Close' | translate}}</button>
</div>
