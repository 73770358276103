import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BlCellFormationProblemDataService } from '../../services/shared/bl-cell-formation-problem-data.service';
import { BlCellFormationProblemFormService } from '../../services/form/bl-cell-formation-problem-form.service';

@Component({
  selector: 'app-dashboard-cell-formation-problem',
  templateUrl: './dashboard-cell-formation-problem.component.html',
  styleUrls: ['./dashboard-cell-formation-problem.component.scss']
})
export class DashboardCellFormationProblemComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private dataService: BlCellFormationProblemDataService,
    public formService: BlCellFormationProblemFormService,
  ) { }

  ngAfterViewInit(): void {
    this.dataService.storageIsTabsView.subscribe({
      next: (data) => {
        this.formService.isShowMainTabs = data;
      }
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.dataService.storageIsTabsView.next(false);
    this.dataService.storageDataIsShowOtherTabs.next(false);
    this.dataService.storageIsRunResults.next(false);
    this.dataService.originalObject.next(null);
  }

}
