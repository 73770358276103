import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToTimestamp'
})
export class DateToTimestampPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return new Date(value).getTime();
  }

}
