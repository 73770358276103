import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ArrayFunctions } from '../../functions/array-functions';
import { TableFunctions } from '../../functions/table-functions';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-v-mat-select',
  templateUrl: './v-mat-select.component.html',
  styleUrls: ['./v-mat-select.component.scss']
})
export class VMatSelectComponent implements OnInit, OnChanges {

  constructor() { }

  @Input("labela") public labela = "";
  @Input("podaci") public data = [];
  @Input("multiple") public multiple: boolean = false;
  @Input("validator") public validator = null;
  @Input("isSpinnerVissible") public isSpinnerVissible: boolean = false;
  @Input("formService") public formService = null;
  @Input("disabledOptions") public disabledOptions: boolean = false;
  @Input("defaultOptions") public defaultOptions: number = null;
  @Input("dropdownData") public dropdownData = null;
  @Input("controlName") public controlName = null;

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;


  ngOnInit() {
    if (this.validator)
      this.formService.form.get(this.controlName).setValidators(Validators[this.validator]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cdkVirtualScrollViewPort && this.data.length > 0 && this.formService.form.get(this.controlName).value != null) {
      if (this.multiple) {
          let trenutniObjekat = this.data.filter(x => ArrayFunctions.inArray(x.id,this.formService.form.get(this.controlName).value));
          TableFunctions.sortBy("title", trenutniObjekat);
          this.data = this.data.filter(x => !ArrayFunctions.inArray(x.id,this.formService.form.get(this.controlName).value));
          this.data.unshift(...trenutniObjekat);
      } else {
        let index = this.data.findIndex(item => item.id == this.formService.form.get(this.controlName).value);
        if (index != -1) {
          let trenutniObjekat = this.data.find(x => x.id == this.formService.form.get(this.controlName).value);
          this.data = this.data.filter(x => x.id != this.formService.form.get(this.controlName).value);
          this.data.unshift(trenutniObjekat);
        }
      }
    }

  }

  openedChange(opened) {
    if (opened) {
      let index = this.data.findIndex(item => item.id == this.formService.form.get(this.controlName).value);
      this.cdkVirtualScrollViewPort.scrollToIndex(index);
      this.cdkVirtualScrollViewPort.checkViewportSize();
      // this.cdkVirtualScrollViewPort.scrolledIndexChange.subscribe(data=>{
      //   this.cdkVirtualScrollViewPort.setTotalContentSize(50*this.data.length);
      // })
    }
  }

}
