import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { SpinnerFunctions } from '../../../../../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../../../../../shared/services/alert-message-data.service';
import { ICellFormationProblemForm, ICellFormationProblemTestDropDownData } from '../../../../../../../../interfaces/i-cell-formation-problem';
import { BlCellFormationProblemFormService } from '../../../../../../../../services/form/bl-cell-formation-problem-form.service';
import { BlCellFormationProblemRequestsService } from '../../../../../../../../services/requests/bl-cell-formation-problem-requests.service';
import { BlCellFormationProblemDataService } from '../../../../../../../../services/shared/bl-cell-formation-problem-data.service';
import { BlInputDataTableService } from '../../../../../../../../services/table/bl-input-data-table.service';
import { CellFormatingHelperFunctionsService } from '../../../../../../../../services/helper/cell-formating-helper-functions.service';

@Component({
  selector: 'app-tab-dashboard-input-data-left-side',
  templateUrl: './tab-dashboard-input-data-left-side.component.html',
  styleUrls: ['./tab-dashboard-input-data-left-side.component.scss']
})
export class TabDashboardInputDataLeftSideComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dataService: BlCellFormationProblemDataService,
    public formService: BlCellFormationProblemFormService,
    public tableService: BlInputDataTableService,
    private alertService: AlertMessageDataService,
    private requestsService: BlCellFormationProblemRequestsService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private cellFormatingHelperFunctionsService: CellFormatingHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;
  public isViewLoad: boolean = false;
  private dataForSend: any = [];
  public dataForSecondBlock: any = null;
  private dataFromStorage: any;

  public dropdownData: ICellFormationProblemTestDropDownData = {
    Instances: null,
    Algorithms: null,
    Objectives: null
  }

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<ICellFormationProblemForm> = this.formService.form;
  public numbers: any = [];

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        this.formService.isShowOtherTabs = data;
      }
    });

    this.getAlgorithms();
    this.getObjectives();
  }

  dataFromForm: any = null;

  ngOnInit(): void {

    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          if(data) {
            this.dataFromStorage = data;
            if(data.id) {
              this.isViewLoad = true;
              if (this.isViewLoad) {
                this.dataService.dataForSecondBlock = data;
                this.isViewLoad = false;
                this.formService.numberRows = this.dataService.dataForSecondBlock.m;
                this.formService.numberCols1 = this.dataService.dataForSecondBlock.len;
                this.formService.configP = this.dataService.dataForSecondBlock.p;

                if (this.formService.numberCols1 && this.formService.numberCols1 > 0) {
                  this.tableService.createDynamicHeaderColumnsTable1(this.formService.numberCols1)
                  this.numbers = Array(this.formService.numberCols1).fill(0).map((x, i) => i);
                  this.formService.setRows(data, this.formService.configP);
                }
              }
            } else {
              this.isViewLoad = true;
              this.dataFromForm = data;
              if (this.isViewLoad) {
                this.isViewLoad = false;
                this.formService.numberRows = data.m;
                this.formService.numberCols1 = data.len;
                this.formService.configP = data.p;

                this.numbers = Array(this.formService.numberCols1).fill(0).map((x, i) => i);
                this.tableService.createDynamicHeaderColumnsTable1(this.formService.numberCols1);

                if (this.formService.numberRows && this.formService.numberRows > 0) {
                  this.formService.createTableWithDynamicRows(this.formService.numberCols1, this.formService.numberRows, this.formService.configP);
                }
              }
            }
          }
        }
      })
    );
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestsService.getAlgorithms().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Algorithms = data;
            this.formService.algorithmData = data;
            this.formService.form.getSafe(x => x.algorithm).setValue(data[0].id);
          }
        }
      })
    )
  }

  getObjectives() {
    this.subscription.add(
      this.requestsService.getObjectives().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Objectives = data;
            this.formService.form.getSafe(x => x.objective).setValue(1);
          }
        }
      })
    )
  }

  saveData() {
    this.clickedButton = true;
    this.dataForSend = [];


    if(this.dataFromStorage) {
      let mainData: any = this.dataFromStorage;

      let rows: any = this.form.getSafe(x => x.rows).value;
      rows = this.cellFormatingHelperFunctionsService.mapToSingleArray(rows, rows)

      this.dataForSend.push({
        id: mainData.id,
        m: mainData.m,
        p: mainData.p,
        len: mainData.len,
        name: mainData.description,
        rows: rows
      });

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.insertData(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", this.dataFromStorage.id ? "Successfully updated." : "Successfully created.");

              this.dataService.originalObject.next(success);
              this.dataService.storageDataIsShowOtherTabs.next(true);
              this.dataService.storageIsRunBrusco.next(false);
              this.dataService.storageIsRunResults.next(false);
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.dataService.dataForSecondBlock = success;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].description : success["description"];
              this.changeAlgorithm(this.formService.form.getSafe(x => x.algorithm).value);
            },
            error: error => {
              this.alertService.setMessage("danger", this.dataFromStorage.id ? "Error while updating." : "Error while creating.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }
  }

  changeAlgorithm(value): void {
    if (value) {
      let choosenAlgorithm = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === value);
      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls(choosenAlgorithm.parametri, this.form);
      this.formService.parametri = Object.keys(choosenAlgorithm.parametri);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols1 = 0;
    this.clickedButton = false;
    this.numbers = [];
  }
}
