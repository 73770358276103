import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { GROUP_PROPERTIES } from '../../../routing-common/constants/group-properties';
import { routingProblems } from '../../../routing-common/constants/routing-problems';
import { IProblemAlgorithm } from '../../../routing-common/interfaces/i-problem-algorithm';
import { IDistribution1ToN, ITruck } from '../../interfaces/i-distribution-1-to-n';
import { IDistribution1ToNAlghoritm } from '../../interfaces/i-distribution-1-to-n-alghoritm';
import { IMapByDistances } from '../../interfaces/i-map-by-distances';
import { Distribution1ToNService } from '../api/distribution-1-to-n.service';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNDataService extends BlDataLogicService implements ITableStorage<IDistribution1ToN> {

  constructor(
    private apiService: Distribution1ToNService
  ) {
    super();
  }

  isCalculated: boolean = false;
  coordinatesForMainMap = null;
  allCoordinatesForMainMap = null;
  mainMapFitBounds = true;
  isCircular = 1;

  source = [null, null];
  allDestinations = [[null, null]];
  settingsObj = {
    motorway: 0,
    trunk: 0,
    primary: 0,
    secondary: 0,
    tertiary: 0,
    unclassified: 0,
    residential: 0,
  };
  algorithm: IDistribution1ToNAlghoritm;

  isCustom: boolean = true;

  reports: any[] = [];
  problemAlgorithms: IProblemAlgorithm[] = [];

  showTabs: boolean = false;
  typeOfMap: string = "map";

  triggerInvalidateSizeOnMap: boolean = false;

  problemType;
  resetComponent: boolean = true;

  destinationsQuantity = [];
  trucks: ITruck[] = [];
  truckNumber: number = 0;
  calculateFinished: BehaviorSubject<boolean> = new BehaviorSubject(false);

  canRunBS: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentIdForRun: number = null;
  isEdit: boolean = false;

  countryLongitude: number = null;
  countryLatitude: number = null;
  countryName: string = null;
  countryId: number = null;
  coordinatesForSelectedCountry = null;

  mapByIterations: IMapByDistances = {
    routes: []
  };

  mapByIterationsHash: any = [];

  changeMapByIterations: BehaviorSubject<boolean> = new BehaviorSubject(false);

  allGraphs = [[null, null, null]];

  resetData(): void {
    this.source = [null, null]
    this.allDestinations = [[null, null]];
    this.settingsObj = {
      motorway: 0,
      trunk: 0,
      primary: 0,
      secondary: 0,
      tertiary: 0,
      unclassified: 0,
      residential: 0,
    };
    this.isCustom = true;
    this.allCoordinatesForMainMap = null;
    this.reports = [];
    this.showTabs = false;
    this.typeOfMap = "map";
    this.destinationsQuantity = [];
    this.trucks = [{
      truckNumber: null,
      truckQuantity: null
    }];
    this.truckNumber = 0;
    this.canRunBS.next(false);
    this.currentIdForRun = null;
    this.isEdit = false;
    this.countryLongitude = null;
    this.countryLatitude = null;
    this.countryName = null;
    this.countryId = null;
    this.coordinatesForSelectedCountry = null;
    this.mapByIterations = {
      routes: []
    }
    this.changeMapByIterations.next(false);
    this.allGraphs = [[null, null, null]];
    this.isCircular = 1;
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<IDistribution1ToN[]> = new BehaviorSubject<IDistribution1ToN[]>([]);

  originalData: IDistribution1ToN[];

  getProblemType(): string {
    const currentPage = window.location.pathname;
    const myParam = currentPage.split("/")[2];
    return routingProblems.find(x => x.shortcut === myParam).shortcut;
  }

  getGroupPropertiesId(): number {
    return GROUP_PROPERTIES.find(x => x.problems.find(z => z == this.getProblemType())).id;
  }

  getOriginalData(): IDistribution1ToN[] {
    return this.originalData;
  }

  setOriginalData(items: IDistribution1ToN[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IDistribution1ToN[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IDistribution1ToN[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IDistribution1ToN[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IDistribution1ToN[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

}
