import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { IDeterministicMrp } from '../../interfaces/i-deterministic-mrp';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../../../../config/api';

@Injectable({
  providedIn: 'root'
})
export class DeterministicMrpService extends ApiService<IDeterministicMrp> {

  constructor(http: HttpClient) {
    super(apiPaths.mrp.deterministicMrp, http);
  }
}
