import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { PfspService } from '../api/pfsp.service';
import { ChangeArrayNumbersService } from '../api/change-array-numbers.service';
import { SortProblemService } from '../api/sort-problem.service';
import { LoadProblemService } from '../api/load-problem.service';
import { AlgorithmListService } from '../api/algorithm-list.service';
import { TestPfspService } from '../api/test-pfsp.service';
import { HttpClient } from '@angular/common/http';
import { PfspAllAlgorithmsService } from '../api/pfsp-all-algorithms.service';
import { ObjectivePfspService } from '../api/objective-pfsp.service';

@Injectable({
  providedIn: 'root'
})
export class BlPfspRequestsService extends BlRequestService{

  constructor(
    private alertServices: AlertMessageDataService,
    private apiService: PfspService,
    private changeArrayNumbersService: ChangeArrayNumbersService,
    private sortProblemService: SortProblemService,
    private loadProblemService: LoadProblemService,
    private algorithmService: AlgorithmListService,
    private testService: TestPfspService,
    private pfspAllService: PfspAllAlgorithmsService,
    private objectiveServices: ObjectivePfspService,
    private http: HttpClient
  ) {
    super(alertServices);
  }

  saveData(dataForSend: any) {
    return this.apiService.updateAll(dataForSend);
  }

  insertData(dataForSend: any) {
    return this.apiService.create(dataForSend);
  }

  getAllByType(type: string) {
    return this.apiService.getWithParams({ problemType: type });
  }

  getPFSP(id: number) {
    return this.apiService.get(id);
  }

  getPFSPs() {
    return this.apiService.getAll();
  }

  runProblem(dataForSend: any) {
    return this.changeArrayNumbersService.create(dataForSend);
  }

  sortProblem(dataForSend: any) {
    return this.sortProblemService.create(dataForSend);
  }

  loadProblem(dataForSend: any) {
    return this.loadProblemService.create(dataForSend);
  }

  testProblem(dataForSend: any) {
    return this.testService.create(dataForSend);
  }

  getAlgorithms() {
    return this.algorithmService.getAll();
  }

  getObjectives() {
    return this.objectiveServices.getAll();
  }

  getAllPfsps() {
    return this.pfspAllService.getAll();
  }
}
