import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlCellFormationProblemDataService } from '../../../../../../../../services/shared/bl-cell-formation-problem-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-dashboard-results-left-side',
  templateUrl: './tab-dashboard-results-left-side.component.html',
  styleUrls: ['./tab-dashboard-results-left-side.component.scss']
})
export class TabDashboardResultsLeftSideComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlCellFormationProblemDataService
  ) { }

  private subscription: Subscription = new Subscription();
  public dataFromStorage: any;

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          this.dataFromStorage = null;
          if(data && data.rowsTab7) {
            this.dataFromStorage = data;
          }
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
