<div *ngIf="dataService.typeOfMap !== 'graph'" class="map-dashboard-wrapper mt-10"
    [ngClass]="(dataService.showTabs && dataService.problemType.shortcut == 'cvrp') ? 'all-header-buttons' : null">
    <div class="controls">
        <app-left-sidebar-dashboard-distribution-additional-fields></app-left-sidebar-dashboard-distribution-additional-fields>
    </div>

    <div class="map map-run">
        <app-leaflet-map
        [markers]="dataService.coordinatesForMainMap.markers"
        [routes]="dataService.coordinatesForMainMap.routes"
        [lineWidth]="formService.form.get('width').value"
        [fitBounds]="dataService.mainMapFitBounds"
        [clickEvent]="false"
        [getUsersLocationOnInit]="false"
        [invalidateSize]="this.dataService.triggerInvalidateSizeOnMap"
        >
    </app-leaflet-map>
    </div>
</div>

