<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 formHeading">
            <h3 class="modalHeading">
                {{ "Packing" | translate }}
            </h3>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 itemData">
            <ng-container *ngIf="itemsTmp.length > 0">
               <p>{{ itemsTmp[currentItemIndex].description }}</p>
            </ng-container>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 mt-10">
            <app-three-d-container #threeDContainerComponent [items]="itemsTmp" [container]="container"></app-three-d-container>
        </div>
    </div>

    <hr />

    <div class="row mb-10">
        <div class="col-md-12">
            <div class="addRemovePackageBlock">
                <button type="button" mat-flat-button color="accent" [disabled]="currentItemIndex == items.length - 1"
                    (click)="pack()">{{"Add" | translate}}</button>
                <button type="button" mat-flat-button color="warn" [disabled]="currentItemIndex == -1"
                    (click)="unpack()">{{"Delete" | translate}}</button>
            </div>
        </div>
    </div>
</div>
