import { IOption } from "../../../shared/interfaces/i-option";

export const SOURCE_CODES: IOption[] = [
    {
        id: 1,
        title: "Buy"
    },
    {
        id: 2,
        title: "Made"
    }
]