<app-leaflet-map
    [markers]="dataService.coordinatesForMainMap.markers"
    [routes]="dataService.coordinatesForMainMap.routes"
    [lineColor]="formService.form.get('color').value"
    [lineWidth]="formService.form.get('width').value"
    [fitBounds]="dataService.mainMapFitBounds"
    [clickEvent]="false"
    [getUsersLocationOnInit]="false"
>
</app-leaflet-map>
