import { BlCapacityFormService } from './../../../../services/form/bl-capacity-form.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BlDeterministicMrpDataService } from '../../../../services/shared/bl-deterministic-mrp-data.service';
import { BlDeterministicMrpFormService } from '../../../../services/form/bl-deterministic-mrp-form.service';
import { FormArray } from '@angular/forms';
import { BlBomFormService } from '../../../../services/form/bl-bom-form.service';
import { BlMpsFormService } from '../../../../services/form/bl-mps-form.service';
import { BlInventoryFormService } from '../../../../services/form/bl-inventory-form.service';

@Component({
  selector: 'app-tabs-dashboard-deterministic-mrp',
  templateUrl: './tabs-dashboard-deterministic-mrp.component.html',
  styleUrls: ['./tabs-dashboard-deterministic-mrp.component.scss']
})
export class TabsDashboardDeterministicMrpComponent implements OnInit, AfterViewInit {

  constructor(
    public dataService: BlDeterministicMrpDataService,
    public formService: BlDeterministicMrpFormService,
    public formServiceTab2: BlBomFormService,
    public formServiceTab3: BlMpsFormService,
    public formServiceTab4: BlInventoryFormService,
    public formServiceTab5: BlCapacityFormService
  ) { }

  public canChangeTab: boolean = true;
  public selectedTab: number = 0;

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        this.formService.isShowOtherTabs = data;
        this.dataService.storageSelectedFirstTab.subscribe({
          next: (data) => {
            this.dataService.selectedTab = data;
          }
        })
      }
    })
  }

  ngOnInit(): void {
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab == 0) {

    }
  }

}
