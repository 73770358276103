
<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form class="change-language-form" [formGroup]="form" (ngSubmit)="save()">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <h3 class="modal-heading">{{"ChooseLanguage" | translate}}</h3>
      </div>
    </div>
    @if (!isSpinnerVissible) {
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>{{"ChooseLanguage" | translate}}</mat-label>
            <mat-select class="selectedImg" [(value)]="selected" [ngClass]="selected"
                        panelClass="custom-mat-select-panel-class"
                        [formControlName]="form.keys.language" disableOptionCentering>
              @for (lng of languages; track lng) {
                <mat-option [value]="lng.language">
                  <img src={{baseUrl+lng.imagePath}}>&nbsp; {{lng.title | translate}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    } @else {
      <div class="row">
        <div class="col-sm-12">
          <app-input-with-spinner inputLabel="{{'ChooseLanguage' | translate}}"></app-input-with-spinner>
        </div>
      </div>
    }
  </div>
  <div mat-dialog-actions>
      <app-button-with-spinner
        [isClickedSave]="isClickedSave"
        [data]="selected"
        [form]="form">
      </app-button-with-spinner>
  </div>
</form>
