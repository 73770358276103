<ng-container *ngIf="dataFromStorage?.rowsTab7">
    <div class="row">
        <div class="col-sm-12">
            <span class="tab-subtitle" [innerHTML]="title | safeHtml"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-7 col-md-8 col-lg-9">
            <app-tab-dashboard-results-left-side></app-tab-dashboard-results-left-side>
        </div>
        <div class="col-sm-5 col-md-4 col-lg-3">
            <app-tab-dashboard-results-right-side></app-tab-dashboard-results-right-side>
        </div>
    </div>
</ng-container>