<form [formGroup]="formService.form" class="reducedForm tab-bom">
    <!-- Table Bom Tab -->
    <div class="row">
        <div class="col-md-12">
            <cdk-virtual-scroll-viewport style="width: 100%; overflow-y: auto;"
            [ngStyle] = "{'height': dynamicHeight ? dynamicHeight : '450px'}" minBufferPx="200"
                maxBufferPx="400" [itemSize]="50">
                <div class="col-md-12">
                    <ng-container *ngIf="formService.form.get('rowsTab2')['controls']?.length > 0; else noAllTableData;">
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th *ngFor="let header of tableService.headersTable">{{header.title | translate}}</th>
                                    <th class="actions-cell">BOM</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *cdkVirtualFor="let item of formService.form.get('rowsTab2')['controls']; let i=index">
                                    <tr>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{"Item ID" | translate}}</mat-label>
                                                <input [formControl]="formService.form.get('rowsTab2')['controls'][i].get('itemId')"
                                                    matInput autocomplete="off" />
                                            </mat-form-field>
                                        </td>
                                        <td *ngFor="let cols of numbers; let j=index;">
                                            <mat-form-field appearance="outline">
                                                <input [formControl]="formService.form.get('rowsTab2')['controls'][i].get('c'+j)" matInput autocomplete="off" />
                                            </mat-form-field>
                                        </td>
                                        <td class="actions-cell">
                                            <button type="button" mat-flat-button color="accent"
                                                (click)="viewTree(formService.form.get('rowsTab2')['controls'][i].get('itemId'))">{{"Show" |
                                                translate}}
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-template #noAllTableData>
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th *ngFor="let header of tableService.headersTable">{{header.title | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td [attr.colspan]="formService.colSpan" class="alert alert-warning">
                                        {{'NoRecordsFound' | translate}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <!-- END Table Bom Tab -->
</form>
