<ng-container>
  <form [formGroup]="formService.form" class="custom-form" style="height: 100%">
    <div class="content-wrapper">
      <div class="upper">
        <div class="row">
          <div class="col-sm-6 input-mobile">
            <mat-form-field
              appearance="outline"
              [ngClass]="
                formService.form.get('targetFunction').errors
                  ? 'requiredField'
                  : ''
              "
              >
              <mat-label>{{ "Objective" | translate }}</mat-label>
              <mat-select panelClass="custom-mat-select-panel-class"
                [formControlName]="formService.form.keys.targetFunction"
                disableOptionCentering>
                @for (option of dropdownData.TargetFunctions; track option) {
                  <mat-option
                    [value]="option.id"
                    >
                    {{ option.title | translate }}
                  </mat-option>
                }
              </mat-select>
              <mat-error class="customError">
                @if (
                  formService.form.get('targetFunction').hasError('required')
                  ) {
                  {{ "ThisFieldIsRequired" | translate }}
                }
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 input-mobile">
            <mat-form-field
              appearance="outline"
              [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''">
              <mat-label>{{ "Algorithm" | translate }}</mat-label>
              <mat-select panelClass="custom-mat-select-panel-class"
                (selectionChange)="changeAlgorithm($event.value)"
                [formControlName]="formService.form.keys.algorithm"
                disableOptionCentering
                >
                @for (option of dropdownData.Algorithms; track option) {
                  <mat-option
                    [value]="option.id"
                    >
                    {{ option.title | translate }}
                  </mat-option>
                }
              </mat-select>
              <mat-error class="customError">
                @if (formService.form.get('algorithm').hasError('required')) {
                  {{ "ThisFieldIsRequired" | translate }}
                }
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            @if (form.get('algorithmParams')['controls']) {
              <ng-container
                formArrayName="algorithmParams"
                >
                @for (item of formService.parametri; track item; let i = $index) {
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ item | translate }}</mat-label>
                        <input
                        [formControl]="
                          form
                            .get('algorithmParams')
                            ['controls'][i].get('value')
                        "
                          matInput
                          autocomplete="off"
                          type="number"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  }
                </ng-container>
              }
            </div>
          </div>

          <!-- Left Table PFSP -->
          <div class="row">
            <div class="col-md-12 custom-form reduced-form">
              <cdk-virtual-scroll-viewport
              [ngStyle]="{
                height:
                  formService.numberRows < 15
                    ? (formService.numberRows + 2) * 30 + 'px'
                    : '465px'
              }"
                style="width: 100%; overflow-y: auto"
                minBufferPx="200"
                maxBufferPx="600"
                [itemSize]="30"
                >
                <div>
                  @if (
                    formService.form.get('rows')['controls']?.length > 0) {
                    <table class="table table-sm table-striped tab-input-data table-pfsp hide-subscript">
                      <thead class="thead-dark">
                        <tr>
                          <th>&nbsp;</th>
                          @for (header of tableService.headersTable1; track header) {
                            <th>
                              {{ header.index }}
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                        *cdkVirtualFor="
                          let item of formService.form.get('rows')['controls'];
                          let i = index
                        "
                          >
                          <tr>
                            <td>{{ i + 1 }}</td>
                            @for (cols of numbers; track cols; let j = $index) {
                              <td>
                                <mat-form-field appearance="outline">
                                  <input
                                [formControl]="
                                  formService.form
                                    .get('rows')
                                    ['controls'][i].get('c' + j)
                                "
                                    matInput
                                    autocomplete="off"
                                    />
                                  </mat-form-field>
                                </td>
                              }
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    } @else {
                      <div class="alert alert-warning" role="alert">
                        {{ "NoRecordsFound" | translate }}
                      </div>
                    }
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
            <!-- End Left Table PFSP -->
          </div>
        </div>
      </form>
    </ng-container>
