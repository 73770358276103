import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'buttonClass'
})
export class ButtonClassPipe implements PipeTransform {

  transform(button: ITableColumn): string { // TODO: set button type
    return button.hasOwnProperty('class')? button.class : '';
  }

}
