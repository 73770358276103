<div id="login-wrapper">

    <div class="login-form">
        <div class="row">
            <div class="col-md-12">
                <h3>{{"COMPUTER INTEGRATED MANUFACTURING" | translate}}</h3>
            </div>
        </div>

        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="row">
                <div class="col-sm-11 fieldBox">
                    <mat-form-field color="primary" appearance='outline'>
                        <mat-label>{{"Username" | translate}} / {{'Email' | translate}}</mat-label>
                        <input matInput type='text' [formControlName]="form.keys.username" id="username" (keydown.enter)="textareaEnterPressed($event)">
                        <mat-error *ngIf="form.get('username').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </mat-error>
                        <mat-error *ngIf="form.get('username').hasError('email')">
                            {{'Email is not in a good format' | translate}}
                        </mat-error>
                        <mat-error *ngIf="form.get('username').hasError('noWhiteSpace')">
                            {{"NoWhiteSpace" | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-1"></div>
            </div>
            <div class="row passwordBox">
                <div class="col-sm-11 fieldBox">
                    <mat-form-field color="primary" appearance='outline'>
                        <mat-label>{{'Password' | translate}}</mat-label>
                        <input matInput type='password' [formControlName]="form.keys.password" id="password" (keyup)="changeCurrentPass()"
                            (keydown.enter)="textareaEnterPressed($event)">
                    </mat-form-field>

                    <app-password-strength [form]="form" (currentPassStrengthEvent)='passStrengthChanged($event)'
                        [currentPass]="this.form.get('password').value"></app-password-strength>
                </div>

                <div class="col-sm-1 iconBox">
                    <span [ngbPopover]="popContent1"
                          [popoverTitle]="'Password Info' | translate" placement="bottom" popoverClass="passwordPopoverBottom"
                          triggers="mouseenter:mouseleave" container="body">
                        <mat-icon class="help-icon">help_outline</mat-icon>
                        <ng-template #popContent1>
                            {{"PasswordInfo" | translate}}
                        </ng-template>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-11 fieldBox">
                    <mat-form-field color="primary" appearance='outline'>
                        <mat-label>{{'Confirm Password' | translate}}</mat-label>
                        <input matInput type='password' [formControlName]="form.keys.passwordConfirm" #passwordConfirm (keyup)='setConfirmPassword()'
                            (keydown.enter)="textareaEnterPressed($event)" class="postal-code-font-size">
                    </mat-form-field>
                </div>
                <div class="col-sm-1 iconBox">
                    <span [ngbPopover]="popContent1"
                          [popoverTitle]="'Password Info' | translate" placement="bottom" popoverClass="passwordPopoverBottom"
                          triggers="mouseenter:mouseleave" container="body">
                        <mat-icon class="help-icon">help_outline</mat-icon>
                        <ng-template #popContent1>
                            {{"PasswordInfo" | translate}}
                        </ng-template>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-11 fieldBox">
                    <button type="submit" mat-flat-button color="accent" [disabled]="form.invalid">{{'Create new user' |
                        translate}}</button>
                </div>
                <div class="col-sm-1"></div>
            </div>
            <div class="row">
                <div class="col-md-11 forget-password">
                    <a routerLink=""><span>{{"Back to Login Page" | translate}}</span></a>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </form>
    </div>

</div>
