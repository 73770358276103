import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlBackpackProblemFormService } from '../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { BlBackpackProblemContainersTableService } from '../../../../services/table/bl-backpack-problem-containers-table.service';
import { BackpackHelperFunctionsService } from '../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-tables-backpack-problem',
  templateUrl: './tables-backpack-problem.component.html',
  styleUrls: ['./tables-backpack-problem.component.scss']
})
export class TablesBackpackProblemComponent implements OnInit {

  constructor(
    private dataService: BlBackpackProblemDataService,
    public formService: BlBackpackProblemFormService,
    public tableService: BlBackpackProblemContainersTableService,
    private alertService: AlertMessageDataService,
    private requestService: BlBackpackProblemRequestsService,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public dataForTable: any = [];
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  // ngAfterViewInit(): void {
  //   this.dataService.originalObject.subscribe(data => {
  //     this.formService.isProblemLoad = !!data;
  //   })
  // }

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: data => {
          if(data) {
            this.dataFromStorage = data;
            this.dataFromStorageForSend = data;
            this.formService.sizes = this.dataFromStorage.size;
            if(data.id) {
              this.formService.reducedNumbersArray = this.dataFromStorage.optimalSequence;
            }
          }
        }
      })
    );

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formService.reducedNumbersArray, event.previousIndex, event.currentIndex);

    this.clickedButton = true;
    this.dataForSend = [];

    if(this.dataFromStorage) {
      if(this.dataFromStorage.id) {
        let form: any = this.formService.form.getRawValue();
        let flowRows: any = (this.dataFromStorageForSend.items.length > 0)?this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.dataFromStorageForSend.items, form): this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.formService.reducedItemsArray, this.formService.reducedItemsArray);
        let distanceRows: any = (this.dataFromStorageForSend.containers.length > 0)? this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.dataFromStorageForSend.containers, form): this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.formService.reducedContainersArray, this.formService.reducedContainersArray);

        this.dataForSend.push({
          size: this.dataFromStorageForSend.size,
          title: (this.formService.problemTitle == "")? this.dataFromStorageForSend.title: this.formService.problemTitle,
          items: flowRows,
          containers: distanceRows,
          algorithm: this.formService.form.getSafe(x => x.algorithm).value,
          ub: this.dataFromStorageForSend.ub
        });
      } else {
        let form: any = this.formService.form.getRawValue();
        let prepakovanNizObjekataItems: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
        let prepakovanNizObjekataContainers: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
        let flowRows: any = (form.items.length > 0)?this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.items, form): this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataItems, prepakovanNizObjekataItems);
        let distanceRows: any = (form.containers.length > 0)? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.containers, form): this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataContainers, prepakovanNizObjekataContainers);
        this.dataForSend.push({
          size: this.dataFromStorageForSend.size,
          title: (this.formService.problemTitle == "")? this.dataFromStorageForSend.title: this.formService.problemTitle,
          items: flowRows,
          containers: distanceRows,
          algorithm: this.formService.form.getSafe(x => x.algorithm).value,
          ub: null
        });
      }
    }

    if (this.clickedButton) {
      SpinnerFunctions.showSpinner();
      this.dataService.storageDataResults.next(null);
      this.formService.isRunProblemForTitle = false;
      this.formService.problemTitle = "";
      if(this.dataForSend.ub == null) {
        this.alertService.setMessage("danger", "Error. UB is null. Before Run problem, please Save.");
        SpinnerFunctions.hideSpinner();
        this.clickedButton = false;
      } else {
        this.subscription.add(
          this.requestService.runProblem(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.dataService.storageDataResults.next(success[0]? success[0]: success);
              this.dataService.originalObject.next(success[0]? success[0]: success);
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].title : success["title"];
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.sizes = 0;
    this.formService.isProblemLoad = false;
    this.clickedButton = false;
    this.dataForSend = [];
    this.dataFromStorage = [];
    this.dataFromStorageForSend = [];
  }

}
