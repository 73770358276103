import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { BomService } from '../api/bom.service';

@Injectable({
  providedIn: 'root'
})
export class BlBomRequestsService extends BlRequestService {

  constructor(
    private alertServicea: AlertMessageDataService,
    private apiService: BomService,
  ) {
    super(alertServicea);
  }

  getTree(dataForSend: any) {
    return this.apiService.create(dataForSend);
  }
}
