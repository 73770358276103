<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-4 formHeading">
            <h3 class="modalHeading">
                {{"Admin" | translate}}
            </h3>
        </div>
        <div *ngIf="adminInfo != ''" class="col-sm-8 formHeading">
            <h3 class="modalHeading fs15">
                {{ adminInfo }}
            </h3>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <div class="row" style="width: 100%; margin: 0px;">
        <div class="col-sm-12 dialog-action-box">
            <button (click)="submit()" mat-flat-button color="accent" type="submit" >{{'Submit' | translate}}</button>
        </div>
    </div>
</div>