
<div class="common-mat-select-wrapper">
  <button disableRipple class="btn-matSelect" (click)="openColumnList()">
    <mat-icon>view_carousel</mat-icon>&nbsp;<span>{{'ShowColumns' | translate}}</span>
  </button>

  <mat-selection-list class="commonMatSelectionList" *ngIf="displayedColumns && columns" [@showList]="isVisibleColumnList">
      <ng-container *ngFor="let column of columns">
        <mat-list-option #chbColumn disableRipple (click)="toggleColumn(column.index, chbColumn.selected)" [selected]="displayedColumns.includes(column.index)">
          {{column.title | translate}}
        </mat-list-option>
      </ng-container>
  </mat-selection-list>
</div>



