// import { ApiService } from 'src/app/shared/services/api.service';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { DATE_COLUMN_KEYS } from '../consts/date-column-keys';
import { ArrayFunctions } from './array-functions';
import { DateFunctions } from './date-functions';

export class ReportsFunctions {
    public static getTaxColumns(allProps: string[]) {
        return ReportsFunctions.getTaxProps(allProps).map(x => {
            return { 
                index: x,
                title: x,
                isRight: true
            }
        })
    }

    public static getTaxProps(allProps: string[]) : string[] {
        let fixedColumns = ['tip', 'chargeType', 'revenueCategory', 'revenueLabel', 'chargeName', 'user', 'count', 'avgPrice', 'folioNo', 'nonTaxableRevenue', 'taxableRevenue', 'totalRevenue', 'taxes', 'totalTaxes', 'total', 'folioNo', 'adjDate', 'adjUser', 'reason', 'comments', 'quantity', 'taxRev', 'nonTaxRev', 'marketName', 'sourceName', 'totalTax', 'totalRoomRevenue', 'period'];
        return Object.keys(allProps).filter(x => !ArrayFunctions.inArray(x, fixedColumns));
    }

    public static setEndDate(endDate){
        let end = cloneDeep(endDate);
        end.setDate(end.getDate() + 1);
        return end;
    }

    public static getFilterData(params, service, nesto) {
        return service.getFilterData(params)
        .pipe(
            map( (data: any[]) => {
                let obj = {}
                if(data.length >0){
                    data.forEach( x => {
                        obj[x.ParamName] = x.ParamValue? x.ParamValue.split(',') : x.ParamValue;
                        if(obj[x.ParamName] == ""){
                            obj[x.ParamName] = [];
                        }
                    })
                }
                return obj;
            }
        ));
    }

    public static prepareForFilterData(form, parValueMaps, arrays = null, hotelName = null) {
        let obj = {
            database: hotelName
        };
        const filter = [], keys = Object.keys(parValueMaps);
        keys.forEach( parValue => {

            let value = form.get(parValue).value != null ?  form.get(parValue).value : '';
            if(Date.parse(value) && ['startDate','endDate', 'startDateCheck', 'endDateCheck', 'startDatePayment', 'endDatePayment', 'currentStartDate','currentEndDate', 'sDate', 'runForDate'].indexOf(parValue)!==-1){
                value = DateFunctions.formatDate(value);
            }

            if (Array.isArray(value) && arrays != null) {
                value = value.join(",")
            }
            // } else {
            //     if (value.toString().trim() === '' || value.toString().trim() === '0') {
            //     notInclude = true;
            //     }
            // }
            // if (!notInclude) {
                filter.push({
                    ParamName: parValueMaps[parValue],
                    ParamValue: (value == "" || value == 0)? null : value
                });
            // }
        });
        obj['parametri'] = filter;
        return obj;
    }

    /**
     * NEW FUNCTION FOR REPORTS
     */
    public static prepareDataForRunReport(paramsToSend: any, hotelName = null) : any{
        let parametri: any[] = [];

        let keys = Object.keys(paramsToSend);
        keys.forEach( key => {
            let value = paramsToSend[key]

            if(Date.parse(value) && DATE_COLUMN_KEYS.indexOf(key)!==-1){
                value = DateFunctions.formatDate(value);
            }
            if (Array.isArray(value)) {
                value = value.join(",")
            }
            parametri.push({
                ParamName: key,
                ParamValue: (value == "" || value == 0)? null : value
            });
        });
        return {
            database: hotelName,
            parametri: parametri
        };
    }

    static printReport(){
        let content = document.getElementById('report').innerHTML;
        document.getElementById('app-content').style.display = "none";

        let modal = document.getElementById('report-modal-content');
        let modalClasses = "";

        let modalWrapper = document.getElementById('report-modal-dialog');
        let modalWrapperClasses = ""

        if(modal && modalWrapper){
            content = modal.innerHTML;
            
            modalClasses = modal.getAttribute('class');
            modal.setAttribute('class', 'no-display');

            modalWrapperClasses = modalWrapper.getAttribute('class');
            modalWrapper.setAttribute('class', 'no-display');
        }

        document.getElementById("report-content-print").innerHTML = content;

                    window.print();
    
        if(modal && modalWrapper){ 
            modal.setAttribute('class', modalClasses);
            modalWrapper.setAttribute('class', modalWrapperClasses);
        }
        document.getElementById('app-content').style.display = "block";
        document.getElementById("report-content-print").innerHTML = '';
    }

    static isCountColumn(value) {
        return (value && isNaN(value) && value.indexOf('xxx') !== -1);
    }

    public static showByTip(data: any[], currentTip: number){
        return data.filter(x => {
            if(currentTip == 1) 
                return x.tip === currentTip;
            if(currentTip == 0)
                return x.tip >= 0;
            return x.tip <= currentTip && x.tip >0
        });
    }

    static printDataReport(html = ""){
        let report = document.getElementById('report');
        let content = null;
        if(report){
            content = report.innerHTML;
        }
        document.getElementById('app-content').setAttribute('class', 'no-display');
 
        let modal = document.getElementById('report-modal-content');
        let modalClasses = "";
 
        let modalWrapper = document.getElementById('report-modal-dialog');
        let modalWrapperClasses = "";
        let htmlOverflow = document.querySelector("html").style.overflow;
 
        if(modal && modalWrapper){
            content = modal.innerHTML;
            if(html){
                content = html;
                document.querySelector("html").setAttribute("class", "");
                document.querySelector("html").style.overflow = "auto";
            }
            modalClasses = modal.getAttribute('class');
            modal.setAttribute('class', 'no-display');
 
            modalWrapperClasses = modalWrapper.getAttribute('class');
            modalWrapper.setAttribute('class', 'no-display');
        }
       
        let buttons = document.getElementById("report-buttons");
        let buttonClasses = "";
        if(buttons) {
            buttonClasses = buttons.getAttribute('class');
            buttons.setAttribute('class', 'no-display');
           
        }
 
        // hide
        let hidden = document.querySelectorAll(".report-print-hidden");
        if(hidden && hidden.length > 0){
            for(let i=0; i< hidden.length; i++){
                hidden[i]['style'].display = "none";
            }
        }
        document.getElementById("report-content-print").innerHTML = content;
 
        window.print();
   
        if(modal && modalWrapper){
            modal.setAttribute('class', modalClasses);
            modalWrapper.setAttribute('class', modalWrapperClasses);
            if(html){
                document.querySelector("html").setAttribute("class", "cdk-global-scrollblock");
                document.querySelector("html").style.overflow = htmlOverflow;
            }
           
        }
        if(hidden && hidden.length > 0){
            for(let i=0; i< hidden.length; i++){
                hidden[i]['style'].display = "block";
            }
        }
 
        if(buttons) {
            buttons.setAttribute('class', buttonClasses);
        }
 
        document.getElementById('app-content').setAttribute('class', '');
        document.getElementById("report-content-print").innerHTML = '';
    }
}
