import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { IQapForm, IQapConfigForm, IQapFilterForm } from '../../interfaces/i-qap';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { UploadQapCSVService } from '../api/upload-qap-csv.service';

@Injectable({
  providedIn: 'root'
})
export class BlQapFormService implements IFormService<IQapForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private uploadCSVService: UploadQapCSVService
  ) { }

  formConfig: FormGroupTypeSafe<IQapConfigForm> = this.initConfig();
  form: FormGroupTypeSafe<IQapForm> = this.init();
  formFilter: FormGroupTypeSafe<IQapFilterForm> = this.initFilter();

  public isShowRunButton: boolean = false;
  public isShowSavesButtons: boolean = false;
  public isShowMainTabs: boolean = false;
  public isShowOtherTabs: boolean = false;
  public isDisabledEditFlowButton: boolean = false;
  public isDisabledEditDistancesButton: boolean = false;
  // public isLiteratureProblem: boolean = false;
  public isFromViewDialog: boolean = false;
  // public isProblemLoad: boolean = false;
  public isExportShow: boolean = false;
  public isImportMethod: boolean = false;
  public isRunProblemForTitle: boolean = false;

  public problemType: string = "";
  public sizes: number = 0;
  public originalNumbersArray: any = [];
  public reducedNumbersArray: any = [];
  public reducedFlowsArray: any = [];
  public reducedDistancesArray: any = [];
  public rowData: any = null;
  public problemTitle: string = "";

  public algorithmData: any = [];

  public parametri: any = [];

  initConfig(): FormGroupTypeSafe<IQapConfigForm> {
    let group: FormGroupTypeSafe<IQapConfigForm> = this.formBuilder.group<IQapConfigForm>({
      size: this.formBuilder.control(1, [Validators.required, Validators.min(1)]),
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
      problemType: this.formBuilder.control("c"),
      inputMethod: this.formBuilder.control(0)
    });
    ValidationFunctions.runValidation(group);
    return group;

  }
  init(): FormGroupTypeSafe<IQapForm> {
    let group: FormGroupTypeSafe<IQapForm> = this.formBuilder.group<IQapForm>({
      algorithm: this.formBuilder.control(""),
      flows: this.formBuilder.array([]),
      distances: this.formBuilder.array([]),
      algorithmParams: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;

  }

  initFilter(): FormGroupTypeSafe<IQapFilterForm> {
    let group: FormGroupTypeSafe<IQapFilterForm> = this.formBuilder.group<IQapFilterForm>({
      problemTypes: this.formBuilder.control(""),
    });
    //ValidationFunctions.runValidation(group);
    return group;

  }

  getFormConfigReference(): FormGroupTypeSafe<IQapConfigForm> {
    return this.formConfig;
  }

  getFormReference(): FormGroupTypeSafe<IQapForm> {
    return this.form;
  }

  getFilterFormReference(): FormGroupTypeSafe<IQapFilterForm> {
    return this.formFilter;
  }

  setFlowsRows(rowData: any) {
    //this.form.getSafe(x => x.algorithm).setValue(rowData.leftBlock.algorithm);
    //this.form.getSafe(x => x.targetFunction).setValue(rowData.leftBlock.targetFunction);
    this.form.removeControl("flows");
    this.form.addControl("flows", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.flows;
      let numberRows: any = rowData.size;
      let numberCols: any = rowData.size;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.flows) as UntypedFormArray).push(
          new UntypedFormGroup({

          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.flows) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row[i]["c" + j], [Validators.required]));
        }
      }
      (this.form.get("flows") as UntypedFormArray).controls = [...(this.form.get("flows") as UntypedFormArray).controls];
    }
    ValidationFunctions.runValidation(this.form);
  }

  createFlowsTableWithDynamicRows(numberColumns: any, numberRows: number) {
    this.removeFlowsTableDYnamicRows();
    if (numberRows) {
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.flows) as UntypedFormArray).push(
          new UntypedFormGroup({
          })
        );
        for (let j = 0; j < numberColumns; j++) {
          ((this.form.getSafe(x => x.flows) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control("0", [Validators.required]));
        }
      }
      (this.form.get("flows") as UntypedFormArray).controls = [...(this.form.get("flows") as UntypedFormArray).controls];
    }
  }

  removeFlowsTableDYnamicRows() {
    this.form.removeControl("flows");
    this.form.addControl("flows", this.formBuilder.array([]));
  }

  setDistancesRows(rowData: any) {
    //this.form.getSafe(x => x.algorithm).setValue(rowData.leftBlock.algorithm);
    //this.form.getSafe(x => x.targetFunction).setValue(rowData.leftBlock.targetFunction);
    this.form.removeControl("distances");
    this.form.addControl("distances", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.distances;
      let numberRows: any = rowData.size;
      let numberCols: any = rowData.size;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.distances) as UntypedFormArray).push(
          new UntypedFormGroup({

          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.distances) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row[i]["c" + j], [Validators.required]));
        }
      }
      (this.form.get("distances") as UntypedFormArray).controls = [...(this.form.get("distances") as UntypedFormArray).controls];
    }
    ValidationFunctions.runValidation(this.form);
  }

  createDistancesTableWithDynamicRows(numberColumns: any, numberRows: number) {
    this.removeDistancesTableDYnamicRows();
    if (numberRows) {
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.distances) as UntypedFormArray).push(
          new UntypedFormGroup({
          })
        );
        for (let j = 0; j < numberColumns; j++) {
          ((this.form.getSafe(x => x.distances) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control("0", [Validators.required]));
        }
      }
      (this.form.get("distances") as UntypedFormArray).controls = [...(this.form.get("distances") as UntypedFormArray).controls];
    }
  }

  removeDistancesTableDYnamicRows() {
    this.form.removeControl("distances");
    this.form.addControl("distances", this.formBuilder.array([]));
  }

  formateArrayNumbers(numberCols: number){ 
    this.originalNumbersArray = [];
    for(let i = 1; i<=numberCols; i++) {
      this.originalNumbersArray.push(i);
    }
  }

  prepareDataToSend(multipart?: any) {
    let formData = new FormData();
    if (multipart) {
      formData.append('files', multipart[0]);
    } else {
      formData.append('files', null);
    }
    return formData as any;

  }

  uploadCSV(multipart?: any) {
    let dataToSend: any = this.prepareDataToSend(multipart);
    return this.uploadCSVService.create(dataToSend, true, true);
  }

  reset(): void {
    this.form = this.init();
  }
  resetConfig(): void {
    this.formConfig = this.initConfig();
  }

  resetFilter(): void {
    this.formFilter = this.initFilter();
  }
}
