<div class="graphSourceDestinationBlock custom-form">
    <mat-form-field appearance="outline" color="primary">
      <mat-label>
        {{ "Source" | translate }}
      </mat-label>
      <input type="number" matInput min="0" [formControl]="formService.form.get('graphSource')" />
    </mat-form-field>

  @if (dataService.problemType.shortcut === 'ptp') {
      <mat-form-field appearance="outline" color="primary">
        <mat-label>
          {{ "Destination" | translate }}
        </mat-label>
        <input type="number" matInput min="0" [formControl]="formService.form.get('graphDestination')" />
      </mat-form-field>
  }

  @if (dataService.isEdit) {
    <div class="nodesFilterBlock">
      <mat-form-field appearance="outline" color="primary">
        <mat-label>
          {{"NodesSeparatedByComma" | translate}}
        </mat-label>
        <input matInput [formControl]="formService.form.get('filterByNodes')" />
      </mat-form-field>
      <button class="custom-flat-btn" type="button" mat-flat-button color="primary"
      (click)="filterByNodes()">{{"Filter" | translate}}</button>
    </div>
  }
</div>

<div class="graph-table-wrapper custom-form">
  <table class="table table-sm table-striped hide-subscript">
    <thead class="thead-dark">
      <tr>
        <th>{{ "V1" | translate }}</th>
        <th>{{ "V2" | translate }}</th>
        <th>{{ "W" | translate }}</th>
        <th>{{ "Delete" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      @for (
        item of formService.form.get('graph')['controls']; track
        item; let i = $index) {
        <tr>
          <td>
            <mat-form-field appearance="outline" color="primary">
              <mat-label>
                {{ "V1" | translate }}
              </mat-label>
                            <input type="number" matInput min="0" [formControl]="
                  formService.form.get('graph')['controls'][i].get('v1')
                " />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" color="primary">
                <mat-label>
                  {{ "V2" | translate }}
                </mat-label>
                            <input type="number" matInput min="0" [formControl]="
                  formService.form.get('graph')['controls'][i].get('v2')
                " />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" color="primary">
                  <mat-label>
                    {{ "W" | translate }}
                  </mat-label>
                            <input type="number" matInput min="0" [formControl]="
                  formService.form.get('graph')['controls'][i].get('w')
                " />
                  </mat-form-field>
                </td>
                <td class="actions-cell">
                  @if (i > 0) {
                    <button mat-icon-button color="warn"
                      (click)="removeGraph(i)" type="button">
                      <mat-icon>remove_circle</mat-icon>
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
