import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { SpinnerFunctions } from '../functions/spinner-functions';
import { AlertMessageDataService } from './alert-message-data.service';
import { BlReportsService } from './bl-reports.service';
import { finalize } from 'rxjs/operators';
import { BlRequestService } from '../bussiness-logic/base/bl-request.service';
import { HttpClient } from '@angular/common/http';
import { config } from '../../config/global';
import { IMimeType } from '../interfaces/i-mime-type';
import { MIME_TYPES } from '../consts/mime-types';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(
    private alertService: AlertMessageDataService,
    private reportService: BlReportsService,
    private http: HttpClient
  ) { }

  mimeTypes: IMimeType[] = MIME_TYPES;

  public export(id: number, path: string, fileName: string, fileType: string): void{
    const type = this.mimeTypes.find(x => x.name === fileType)
    SpinnerFunctions.showSpinner();
    const sub = this.getDownloadPath(id, path)
      .pipe(finalize(() => {
        SpinnerFunctions.hideSpinner();
        sub.unsubscribe();
      }))
      .subscribe({
        next: (data: any) => {
          this.alertService.setMessage("success", "Successfully.");
          this.reportService.save(new Blob([data.body], { type: type.mimeType }), fileName + Date.now() + "." + type.extension);
        }
      });
  }

  private getDownloadPath(id: number, path: string): Observable<any> {
    const options: any = {
      responseType: 'arraybuffer' as 'json',
      observe: 'response'
    };
    return this.http.get(`${config.baseApiUrl.SERVER + path + "/" + id}`, options);
  }


  
}
