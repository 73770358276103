import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { IGeoLocationForward } from '../../interfaces/i-geo-location-forward';
import { GeoLocationForwardService } from '../api/geo-location-forward.service';

@Injectable({
  providedIn: 'root'
})
export class BlGeoLocationForwardDataService extends BlDataLogicService implements ITableStorage<IGeoLocationForward> {

  constructor(
    private apiService: GeoLocationForwardService
  ) {
    super();
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<IGeoLocationForward[]> = new BehaviorSubject<IGeoLocationForward[]>([]);

  originalData: IGeoLocationForward[];

  getOriginalData(): IGeoLocationForward[] {
    return this.originalData;
  }

  setOriginalData(items: IGeoLocationForward[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IGeoLocationForward[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IGeoLocationForward[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IGeoLocationForward[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IGeoLocationForward[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

}