<div class="dashboard-content source-destination"
[ngClass]="(dataService.showTabs && dataService.problemType.shortcut == 'cvrp') ? 'all-header-buttons' : null">
    <div class="row">
        <div class="col-md-12">
            <mat-tab-group #tabsElement (selectedTabChange)="onTabChange($event)"  [selectedIndex]="selectedIndex" dynamicHeight="true" class="routingMapTab">
                <mat-tab label="{{'SourceAndDestinations' | translate}}">
                    <app-source-destination-tab-dashboard-distribution>
                    </app-source-destination-tab-dashboard-distribution>
                </mat-tab>
                <ng-container *ngIf="dataService.isCalculated">
                    <mat-tab [disabled]="dataService.typeOfMap == 'graph'" label="{{'Map' | translate}}" >
                        <app-main-map-tab-dashboard-distribution></app-main-map-tab-dashboard-distribution>
                    </mat-tab>
                    <mat-tab label="{{'Results' | translate}}">
                        <app-reports-tab-dashboard-distribution></app-reports-tab-dashboard-distribution>
                    </mat-tab>
                    <mat-tab *ngIf="dataService.problemType.shortcut == 'sssp' && dataService.mapByIterations.routes.length > 0" label="{{'MapByIterations' | translate}}">
                        <app-map-by-iterations-tab-dashboard-distribution></app-map-by-iterations-tab-dashboard-distribution>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </div>
    </div>
</div>
