<ng-container *ngIf="options && (setup.isAllowedOptionsEmptyArray? true: (isOptionsCame)); else spinner">
    <mat-form-field appearance="outline" [ngClass]="(formControlToFill.invalid)? 'requiredField': false">
        <mat-label>{{ labelText | translate}}</mat-label>
        <mat-select (openedChange)="openedChange($event)" class="virtual-scroll" [formControl]="formControlToFill"
            disableOptionCentering>

            <mat-select-search [formControl]="filterControl"></mat-select-search>

            <cdk-virtual-scroll-viewport class="example-viewport-select" minBufferPx="200" maxBufferPx="400" [itemSize]="36"
                [style.height.px]="options.length > 5 ? 6 * 36 : options.length * 36">
                <mat-option *cdkVirtualFor="let option of options" [value]="option.id" (click)="select(option)">

                    <span *ngIf="!option.isNotFound else deletedOption">
                        {{option.title}}
                    </span>
                    <ng-template #deletedOption>
                        <span>
                            {{option.title | translate}}

                            <a (mouseover)="showDeletedOptionInfo(option.id)" class="deleted-link-cursor option-title" [ngbPopover]="popContent"
                                popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom"
                                 container="body" triggers="mouseenter:mouseleave">
                                #{{ option.id }}
                            </a>
                         
                            <ng-template #popContent>
                                <ng-container *ngIf="deletedOptionInfo && deletedOptionStatus">
                                    <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{
                                        deletedOptionStatus }}</p>
                                    <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
                                </ng-container>
                            </ng-template>
                        </span>
                    </ng-template>
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </mat-select>
        <mat-error *ngIf="formControlToFill.hasError('required')" class="customError">
            {{'ThisFieldIsRequired' | translate}}
        </mat-error>
        <ng-container *ngIf="formControlToFill.disabled &&  this.deletedOption && this.deletedOption.isNotFound">
            <span>
                <a (mouseover)="showDeletedOptionInfo(deletedOption.id)" class="deleted-link-cursor" [ngbPopover]="popContent"
                    popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom"
                    triggers="mouseenter:mouseleave" container="body">
                    #{{ deletedOption.id }}
                </a>
                <ng-template #popContent>
                    <ng-container *ngIf="deletedOptionInfo && deletedOptionStatus">
                        <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{
                            deletedOptionStatus }}</p>
                        <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
                    </ng-container>
                </ng-template>
            </span>
        </ng-container>
    </mat-form-field>
</ng-container>
<ng-template #spinner>
    <app-input-with-spinner inputLabel="{{labelText}}"></app-input-with-spinner>
</ng-template>