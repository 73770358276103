import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../../../../../../../../shared/components/base/base.component';
import { TableFunctions } from '../../../../../../../../shared/functions/table-functions';
import { ValidationFunctions } from '../../../../../../../../shared/functions/validation-functions';
import { BlDistribution1ToNNewFormService } from '../../../../../../services/forms/bl-distribution-1-to-n-new-form.service';
import { BlDistribution1ToNSourceDestinationsTabFormService } from '../../../../../../services/forms/bl-distribution-1-to-n-source-destinations-tab-form.service';
import { BlDistribution1ToNDataService } from '../../../../../../services/shared/bl-distribution-1-to-n-data.service';
import { SourceDestinationFormDashboardDistributionComponent } from '../../../source-destination-form-dashboard-distribution/source-destination-form-dashboard-distribution.component';

@Component({
  selector: 'app-source-destination-tab-dashboard-distribution',
  templateUrl: './source-destination-tab-dashboard-distribution.component.html',
  styleUrls: ['./source-destination-tab-dashboard-distribution.component.scss']
})
export class SourceDestinationTabDashboardDistributionComponent extends BaseComponent implements OnInit {


  constructor(
    public formService: BlDistribution1ToNSourceDestinationsTabFormService,
    protected location: Location,
    protected matDialog: MatDialog,
    protected tableFunctions: TableFunctions,
    public dataService: BlDistribution1ToNDataService,
    public newFormService: BlDistribution1ToNNewFormService
  ) {
    super(tableFunctions, matDialog, location);
  }

  @Output() openDestinationModal = new EventEmitter<any>();


  ngOnInit(): void {
    this.formService.createDestinationsControls();
    ValidationFunctions.runValidation(this.formService.form);
  }

  private dialog = {
    component: SourceDestinationFormDashboardDistributionComponent,
    dimensions: {
      width: "90%",
      height: "auto",
      minHeight: "130px",
      panelClass: "custom-dialog"
    }
  };

  openModal(event): void {
    const eventIndex = event;
    this.openDialog(this.dialog.component, { id: eventIndex }, this.dialog.dimensions);
  }

  changeSourceLatitude(event) {
    const value = event.target.value;
    this.dataService.source[0] = this.parseToFloat(value);
  }

  changeSourceLongitude(event) {
    const value = event.target.value;
    this.dataService.source[1] = this.parseToFloat(value);
  }

  changeDestinationLatitude(event, index) {
    const value = event.target.value;
    this.dataService.allDestinations[index][0] = this.parseToFloat(value);
  }

  changeDestinationLongitude(event, index): void {
    const value = event.target.value;
    this.dataService.allDestinations[index][1] = this.parseToFloat(value);
  }

  changeDestinationQuantity(event, index): void {
    const value = event.target.value;
    this.dataService.destinationsQuantity[index] = value == "" ? null : Number(value);
  }

  parseToFloat(value) {
    if(value == null || value == "")
      return null;
    return parseFloat(value);
  }

  removeDestination(index): void {
    this.dataService.allDestinations.splice(index, 1);
    // -1
    this.formService.removeDestinationControl(index);
    if(this.dataService.getGroupPropertiesId() == 2)
      this.dataService.destinationsQuantity.splice(index, 1);
  }

  addNewControl(): void {
    if(this.dataService.typeOfMap !== 'graph')
      this.addDestination();
    else
      this.addGraph();
  }

  addDestination(): void {
    this.dataService.allDestinations.push([null, null]);
    this.formService.addDestinationControl();
    if(this.dataService.getGroupPropertiesId() == 2)
      this.dataService.destinationsQuantity.push(null);
  }

  addGraph(): void {
    this.dataService.allGraphs.push([null, null, null]);
    this.formService.addGraphControl();
  }

  removeTruckControl(index): void {
    this.dataService.trucks.splice(index, 1);
    this.formService.removeTruckControl(index);
  }

  addTruckControl(): void {
    if(this.dataService.trucks != null) {
      this.dataService.trucks.push({truckNumber: null, truckQuantity: null});
      this.formService.addTruckControl();
    }
  }

  changeTruckNumber(event, index) {
    const value = event.target.value;
    this.dataService.trucks[index].truckNumber = parseInt(value);
  }

  changeTruckQuantity(event, index) {
    const value = event.target.value;
    this.dataService.trucks[index].truckQuantity = parseInt(value);
  }

  changeIsCircular(event) {
    const value = event.checked;
    this.dataService.isCircular = value ? 1 : 0;
  }
}
