import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { apiPaths } from '../../config/api';
import { config } from '../../config/global';
import { getStartModuleUrl } from '../../config/settings/base/modules';
import { claims_to_check } from '../../config/settings/claims/claims_to_check';
import { user_fake_claims } from '../../config/settings/claims/user_fake_claims';
import { ICredentials } from '../../login/interfaces/i-credentials';
import { ArrayFunctions } from '../../shared/functions/array-functions';
import { LocalStorageFunctions } from '../../shared/functions/local-storage-functions';
import { ObjectFunctions } from '../../shared/functions/object-functions';
import { Role } from '../../shared/interfaces/role';
import { TokenResponse } from '../../shared/interfaces/token-response';
import { AlertMessageDataService } from '../../shared/services/alert-message-data.service';
import { IAuthRequest } from '../interfaces/i-auth-request';
import { IAuthResponse } from '../interfaces/i-auth-response';
import { AuthTokenFixedProps, IAuthToken } from '../interfaces/i-auth-token';
import { IProperty } from '../interfaces/i-property';
import { TokenService } from './token.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authKey: string = "auth";

  constructor(
    private http: HttpClient,
    private alertService: AlertMessageDataService,
    private tokenService: TokenService,
    private router: Router
  ) { }

  login(credentials: ICredentials) {
    return this.http.post(config.baseApiUrl.SERVER + apiPaths.auth.login, this.prepareLoginRequest(credentials)).pipe(
      tap((data: any) => {
        this.tokenService.saveToken(data);

        // "DEV"
        let token = this.tokenService.getTokenData();
      }),
      catchError(error => {
        this.alertService.setMessage('danger', 'You are not authorized. Check your email and password.'); //TODO: translate
        return throwError("Not Authorized");
      })
    );
  }

  modify(property: IProperty) {
    let request: any = {
      "userid": parseInt(this.tokenService.getTokenData().sub),
      "accountid": property.id,
      "hotelname": property.accountName
    };
    return this.http.post(config.baseApiUrl.SERVER + apiPaths.auth.modify, request).pipe(tap((data: any) => {
      this.tokenService.saveToken(data);
    }));
  }

  // Retrieves the auth JSON object (or NULL if none)
  getAuthToken(): TokenResponse | null {
    var i = localStorage.getItem(this.authKey);
    if (i) {
      return JSON.parse(i);
    }
    else {
      return null;
    }
  }

  getAllInfoFromToken(): IAuthToken {
    let jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken(this.getAuthToken().token);
  }

  getClaims() {
    let token_infos = (this.isLoggedIn()) ? this.getAllInfoFromToken() : []; // "DEV" env
    let keys = Object.keys(token_infos);
    let claimNames = keys.filter(x => !ArrayFunctions.inArray(x, AuthTokenFixedProps));
    let claims = {};
    claimNames.forEach(
      name => {
        claims[name] = token_infos[name];
      }
    )
    if (ObjectFunctions.isEmptyObject(claims)) {
      claims = user_fake_claims;
    }
    return claims;
  }

  goToStartModule(data = null) {
    if (data) {
      let startModuleUrl = getStartModuleUrl(data);
      this.router.navigateByUrl(startModuleUrl);
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  /**
  * Checking User Role - Does user have access to section / subsection
  * @param claim Name of claim
  * @param child Access key or Title - alias for byte position in claim - defined in config/claims_to_check.ts
  */
  getUserRoles(claim: string, child?: string, claims?: any, useClaims = false): boolean {
    let roleset: Role[] = claims_to_check.filter(x => x.claim == claim || x.title == claim);
    // it is not defined to be checked
    if (roleset == null || roleset.length <= 0) {
      return true;
    }
    if (roleset.length > 1) {
      this.alertService.setMessage('danger', 'Upps. Role with same config name is present multiple times.');
      return false;
    }

    let role = roleset[0];
    let service_claims = this.getClaims();

    // "DEV" env
    if (claims != null && useClaims) {
      service_claims = claims;
    }

    if (service_claims.hasOwnProperty(role.claim)) {
      let current_claim = service_claims[role.claim];

      let role_privilegies: number[] = Array.isArray(current_claim) ? current_claim : (current_claim as string).split('').map(x => parseInt(x));

      // if first byte is 1 or claim contains 1 bit in stream - all privilegies
      if ((role_privilegies.length == 1 && role_privilegies[0] == 1) || (child == null && role_privilegies.includes(1))) { //|| ((role_privilegies.length == 1 && role_privilegies[0] == 0) && child == null)
        return true;
      }
      else if (child == null) {
        return false;
      }

      // finding child in claim - by title or (access key) - guards
      let child_roles: number[] = role.bytes.filter(x => x.access_key == child || x.title == child).map(x => x.position);

      if (child_roles == null || child_roles.length < 1)
        return false;

      let position_child_role = child_roles[0];

      return (role_privilegies[position_child_role] == 1) ? true : false;
    }
    // else if (role.claim == 'settings') {

    //   let settingsClaims = claims_to_check.filter(x => x.module == 'settings').map(x => x.claim);

    //   let hasSettingsClaim = Object.keys(service_claims).filter(z => ArrayFunctions.inArray(z, settingsClaims));

    //   return hasSettingsClaim.length > 0;

    // }
    else {
      return false;
    }
  }

  isLoggedIn() {
    return this.tokenService.hasToken();
  }

  logout() {
    this.tokenService.deleteToken();
    LocalStorageFunctions.deleteAll();
    this.router.navigateByUrl("/");
  }


  private prepareLoginRequest(credentials: ICredentials): IAuthRequest {
    return {
      client_id: "FDA",
      grant_type: "password",
      client_secret: "offline_access profile email",
      username: credentials.username,
      password: credentials.password,
      refresh_token: ""
    };
  }

}