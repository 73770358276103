import { Component, OnInit } from '@angular/core';
import { BlNotificationDataService } from '../../services/shared/bl-notification-data.service';
import { BlNotificationTableService } from '../../services/table/bl-notification-table.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-dashboard-notification',
  templateUrl: './header-dashboard-notification.component.html',
  styleUrls: ['./header-dashboard-notification.component.scss']
})
export class HeaderDashboardNotificationComponent implements OnInit {

  constructor(
    public dataService: BlNotificationDataService,
    public tableService: BlNotificationTableService,
    private translateService: TranslateService
  ) { }

  //localization for popover dialog
  // public invoiceFirstParagraph: string = this.translateService.get("invoiceFirstParagraph")["value"];
  // public invoiceSecondParagraph: string = this.translateService.get("invoiceSecondParagraph")["value"];
  // public invoiceThirdParagraph: string = this.translateService.get("invoiceThirdParagraph")["value"];
  // public invoiceFourthParagraph: string = this.translateService.get("invoiceFourthParagraph")["value"];
  // public invoiceFifthParagraph: string = this.translateService.get("invoiceFifthParagraph")["value"];
  // public invoiceSixthParagraph: string = this.translateService.get("invoiceSixthParagraph")["value"];

  ngOnInit() {
  }

}
