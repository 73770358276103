import { Component, OnInit, Input } from '@angular/core';
import { BlTableLogicService } from '../../bussiness-logic/base/bl-table-logic.service';
import { ITableStorage } from '../../bussiness-logic/interfaces/i-storage';
import { BlReportsService } from '../../services/bl-reports.service';

@Component({
  selector: 'app-export-to-csv',
  templateUrl: './export-to-csv.component.html',
  styleUrls: ['./export-to-csv.component.scss']
})
export class ExportToCsvComponent implements OnInit {


  @Input() public dataService: ITableStorage<any>;
  @Input() public tableService: BlTableLogicService<any>;

  constructor(
    private reportService: BlReportsService
  ) { }

  ngOnInit() {
  }

  exportToCsv() {
    this.reportService.exportFileToCsv(this.tableService.getColumns(), this.dataService.getCurrentValue());
  }
}
