<form [formGroup]="formService.form" class="reducedForm item-master">
    <!-- Heading Item Master Tab -->
    <div class="row tab-header">
        <div class="col-md-8">
            <h3 class="tab-title">
                {{"Item Master" | translate}}
            </h3>
        </div>
        <div class="col-md-4 tab-actions">
            <ng-container *ngIf="formService.form.get('rows')['controls']?.length>0">
                <button class="btn-absolute" *ngIf="!dataService.inserted" type="button" mat-flat-button color="accent" (click)="saveData()" [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-insert-bom-mps-inventory-capacity') || !this.form.get('rows').valid">{{"Insert BOM, MPS, Invetory, Capacity" |
                        translate}}
                </button>
            </ng-container>
        </div>
    </div>
    <!-- End Heading Item Master Tab -->

    <!-- Table Item Master Tab -->
    <div class="row mrp-virtual-scroll">
      <div class="col-md-12">
        <cdk-virtual-scroll-viewport style="width: 100%; height: 450px; overflow-y: auto;" minBufferPx="200" maxBufferPx="400" [itemSize]="50">
                <ng-container *ngIf="formService.form.get('rows')['controls']?.length > 0; else noAllTableData;">
                    <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th *ngFor="let header of tableService.fixedColumns">{{header.title | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *cdkVirtualFor="let item of formService.form.get('rows')['controls']; let i=index">
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{"Item ID" | translate}}</mat-label>
                                            <input [formControl]="formService.form.get('rows')['controls'][i].get('itemId')" (change)="checkingSameValue($event, i)" matInput autocomplete="off" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{"ABC Class" | translate}}</mat-label>
                                            <input [formControl]="formService.form.get('rows')['controls'][i].get('itemClass')" matInput autocomplete="off" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Source Code" | translate}}</mat-label>
                                            <mat-select [formControl]="formService.form.get('rows')['controls'][i].get('sourceCode')" disableOptionCentering>
                                                <mat-option *ngFor="let option of dropdownData.SourceCodes" [value]="option.title">
                                                    {{option.title}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Material Type" | translate}}</mat-label>
                                            <mat-select [formControl]="formService.form.get('rows')['controls'][i].get('materialType')" disableOptionCentering>
                                                <mat-option *ngFor="let option of dropdownData.MaterialTypes" [value]="option.title">
                                                    {{option.title}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{"Unit Measure" | translate}}</mat-label>
                                            <input [formControl]="formService.form.get('rows')['controls'][i].get('unitMeasure')" matInput autocomplete="off" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Lead Time" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('leadTime')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Lot Size" | translate}}</mat-label>
                                            <mat-select [formControl]="formService.form.get('rows')['controls'][i].get('lotSize')" disableOptionCentering>
                                                <mat-option *ngFor="let option of dropdownData.LotSizes" [value]="option.title">
                                                    {{option.title}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Lot Quantity" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('lotQuantity')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Scrap %" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('scrap')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Demand" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('demand')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Unit Cost" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('unitCost')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Setup Cost" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('setupCost')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Holding Cost" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('holdingCost')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>{{"Shortage Cost" | translate}}</mat-label>
                                            <input matInput [formControl]="formService.form.get('rows')['controls'][i].get('shortageCost')" type="number" min=0 />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{"Item Description" | translate}}</mat-label>
                                            <input [formControl]="formService.form.get('rows')['controls'][i].get('itemDescription')" matInput autocomplete="off" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{"Other Note" | translate}}</mat-label>
                                            <input [formControl]="formService.form.get('rows')['controls'][i].get('otherNote')" matInput autocomplete="off" />
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
                <ng-template #noAllTableData>
                    <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th *ngFor="let header of tableService.fixedColumns">{{header.title | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="16" class="alert alert-warning">
                                    {{'NoRecordsFound' | translate}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-template>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <!-- End Table Item Master Tab -->
</form>
