<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-4 formHeading">
      <h3 class="modalHeading">
        {{"Admin" | translate}}
      </h3>
    </div>
    @if (adminInfo != '') {
      <div class="col-sm-8 formHeading">
        <h3 class="modalHeading">
          {{ adminInfo }}
        </h3>
      </div>
    }
  </div>
</div>

<div mat-dialog-actions>
  <button (click)="submit()" mat-flat-button color="primary" type="submit" >{{'Submit' | translate}}</button>
</div>
