<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
    </div>
  </div>
</div>

@if (showForm) {
  <form class="custom-form" [formGroup]="formService.form">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12 formHeading actions-cell">
          <h3 class="modalHeading">
            {{"Containers" | translate}}
          </h3>
          <button class="add-btn" type="button" mat-icon-button color="primary"
            (click)="formService.addContainer()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12 actions-cell">
          <button class="add-btn" type="button" mat-icon-button color="primary"
            (click)="formService.addContainer()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-12 custom-form reduced-form">
          <cdk-virtual-scroll-viewport
            [ngStyle]="{'height' : (formService.form.get('containers')['controls']?.length + 1)*32+'px'}"
            style="width: 100%; overflow-y: auto;"
            minBufferPx="200"
            maxBufferPx="600"
            [itemSize]="32">
            <div>
              @if (formService.form.get('containers')['controls']?.length > 0) {
                <table class="table table-sm table-striped tab-input-data table-pfsp table-packing hide-subscript">
                  <thead class="thead-dark">
                    <tr>
                      <th>&nbsp;</th>
                      @for (header of tableService.headersTable2; track header) {
                        <th>{{header.title}}</th>
                      }
                      <th>delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (item of formService.form.get('containers')['controls']; track item; let i = $index) {
                      <tr>
                        <td>{{i+1}}</td>
                        @for (cols of numbers; track cols; let j = $index) {
                          <td>
                            <mat-form-field appearance="outline">
                              <input [formControl]="formService.form.get('containers')['controls'][i].get('c'+j)"
                                matInput autocomplete="off" />
                              </mat-form-field>
                            </td>
                          }
                          <td class="actions-cell">
                            @if (i > 0) {
                              <button class="remove-btn" mat-icon-button color="warn"
                                (click)="formService.removeContainer(i)" type="button">
                                <mat-icon>remove_circle</mat-icon>
                              </button>
                            }
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                } @else {
                  <div class="alert alert-warning" role="alert">
                    {{"NoRecordsFound" | translate}}
                  </div>
                }
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
            <button mat-flat-button color="primary" [disabled]="!form.get('containers').valid" (click)="save()">{{"Save" | translate}}</button>
            <button mat-flat-button color="warn" (click)="close()">{{"Close" | translate}}</button>
      </div>
    </form>
  }

