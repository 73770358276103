import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'sortableColumn'
})
export class SortableColumnPipe implements PipeTransform {

  transform(column: ITableColumn, fixedColumns: string[]): boolean {
    return (fixedColumns.indexOf(column.index) !== -1);;
  }

}
