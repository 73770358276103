
<!-- <button
  [disabled]="true"
  mat-flat-button
  class="disabledSpinner">
  <span>Saving</span>&nbsp;&nbsp;<mat-spinner class="spinnerStyle" [diameter]="20"></mat-spinner>
</button> -->
<div class="col-md-12 flexStart buttonsWithSpinner">
  <ng-container *ngIf="!isClickedSave; else spinner">

      <button mat-flat-button disableRipple color="accent" type="submit" [class.insert]="!(this.data)" [disabled]="(!this.form.valid)"
      [class.update]="this.data" [ngClass]="(this.hideButton==true)? 'hide': ''">
        <ng-container *ngIf="buttonText else saveButton">
          {{ buttonText | translate }}
        </ng-container>
        <ng-template #saveButton>
          {{'Save' | translate}}
        </ng-template>
      </button>
  </ng-container>
  <ng-template #spinner>
      <button [disabled]="true" mat-flat-button disableRipple class="disabledSpinner">
        <ng-container *ngIf="buttonText else saving">
          <span>{{"Running" | translate}}</span>&nbsp;&nbsp;<mat-spinner class="spinnerStyle" [diameter]="17"></mat-spinner>
        </ng-container>
        <ng-template #saving>
          <span>{{"Saving" | translate}}</span>&nbsp;&nbsp;<mat-spinner class="spinnerStyle" [diameter]="17"></mat-spinner>
        </ng-template>
      </button>
  </ng-template>
  <button mat-flat-button type="button" disableRipple color="warn" [mat-dialog-close]="true">{{'Close' | translate}}</button>
</div>

<!-- (click)="close()" -->
