<div class="row extended-width">
    <div class="col-sm-12">
        <div class="row drag-drop-wrapper">
            <div class="col-md-12">
                <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let data of this.formService.reducedNumbersArray" cdkDrag>{{data}}</div>
                </div>
            </div>
        </div>
        <div class="row first dynamic-wrapper">
            <div class="col-md-12 dynamic-column">
                <ng-container *ngIf="dataFromStorage?.rightBlock?.tables?.length > 0; else noAllTableData;">
                    <ng-container *ngFor="let data of dataFromStorage?.rightBlock.tables; let i = index;">
                        <table class="table table-sm table-dynamic">
                            <thead class="thead-dark">
                                <tr>
                                    <th>&nbsp;</th>
                                    <th *ngFor="let header of tableService.headersTable">{{header.title}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tableRow of data.tableData; let j=index;">
                                    <td>{{j+1}}</td>
                                    <ng-container *ngFor="let columns of tableRow.stavke">
                                        <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                                            {{columns.value}}
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </ng-container>
                <ng-template #noAllTableData>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-warning" role="alert">
                                {{"NoRecordsFound" | translate}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>