import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { IQapSaveAsForm } from '../../interfaces/i-qap';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { Validators } from '@angular/forms';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';

@Injectable({
  providedIn: 'root'
})
export class BlQapSaveAsFormService implements IFormService<IQapSaveAsForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  form: FormGroupTypeSafe<IQapSaveAsForm> = this.init();

  init(): FormGroupTypeSafe<IQapSaveAsForm> {
    let group: FormGroupTypeSafe<IQapSaveAsForm> = this.formBuilder.group<IQapSaveAsForm>({
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
    });
    ValidationFunctions.runValidation(group);
    return group;

  }


  getFormReference(): FormGroupTypeSafe<IQapSaveAsForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }
}
