import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { TokenService } from "../../core/services/token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private tokenService: TokenService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
            request = this.prepareRequest(request);
            return next.handle(request);
    }

    prepareRequest(request){
        let objRequest = {
            setHeaders: {
                Authorization: 'Bearer ' + this.tokenService.getToken()
            }
        };
        return request.clone(objRequest);
    }

}

