import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'nestedPropertyColumn'
})
export class NestedPropertyColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.index.indexOf('.') !== -1 && !column.isFormControl;
  }

}
