import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { Distribution1ToNMapFilteringService } from '../api/distribution-1-to-n-map-filtering.service';
import { BlDistribution1ToNMapFilteringRequestsService } from '../requests/bl-distribution-1-to-n-map-filtering-requests.service';
import { IDistribution1ToNMapFiltering, IDistribution1ToNMapFilteringForm, IDistribution1ToNMapFilteringRequest } from '../../interfaces/i-distribution-1-to-n-map-filtering';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNMapFilteringFormService implements IFormService<IDistribution1ToNMapFilteringForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private matDialog: MatDialog,
    private requestsService: BlDistribution1ToNMapFilteringRequestsService,
    private apiService: Distribution1ToNMapFilteringService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNMapFilteringForm> = this.init();
  originalObj: IDistribution1ToNMapFiltering;

  init(): FormGroupTypeSafe<IDistribution1ToNMapFilteringForm> {
    return this.formBuilder.group<IDistribution1ToNMapFilteringForm>({
      description: this.formBuilder.control(""),
      type: this.formBuilder.control("", Validators.required),
      homeNumber: this.formBuilder.control(""),
      searchedDestinations: this.formBuilder.array([]),
    });
  }

  fillForm(id: number, isDestination: boolean): void {

  }

  prepareDataToSend(centerFromMap): IDistribution1ToNMapFilteringRequest {
    let formValue: IDistribution1ToNMapFilteringForm = this.form.getRawValue();

    let dataToSend: IDistribution1ToNMapFilteringRequest = {
      type: formValue.type,
      description: formValue.description,
      homeNumber:  formValue.homeNumber,
      mapCenter: [centerFromMap.lat, centerFromMap.lng]
    };

    return dataToSend;
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNMapFilteringForm> {
    return this.form;
  }

  refreshValidators(): void {
    const typeValue = this.form.getSafe(x => x.type).value;
    this.form.getSafe(x => x.description).clearValidators();

    if(typeValue == "streets") {
      this.form.getSafe(x => x.description).setValidators([Validators.required, Validators.minLength(3)]);
    }
    else {
      this.form.getSafe(x => x.description).setValidators(Validators.minLength(3));
    }

    this.form.getSafe(x => x.description).updateValueAndValidity();
    ValidationFunctions.runValidation(this.form);
  }

  createSearchedDestinationsControls(data): void {
    this.form.removeControl("searchedDestinations");
    this.form.addControl("searchedDestinations", this.formBuilder.array([]));
    for(let i=0; i<data.length; i++) {
      (this.form.getSafe(x => x.searchedDestinations) as UntypedFormArray).push(
        new UntypedFormControl(false)
      )
    }
    ValidationFunctions.runValidation(this.form);
  }

  close(): void {
    this.matDialog.closeAll();
  }

  reset(): void {
    this.form = this.init();
  }

}
