<div [ngClass]="!(dataService.showTabs) ? 'fixed-page-header' : 'relative-page-header'">

  <div class="row">
      <div class="col-md-12">
          <h3 class="page-header-title"> <span>{{"Routing" | translate}}</span> / {{dataService.problemType.title | translate}}</h3>
      </div>
  </div>

  <div class="row">
      <div class="col-md-12">
          <div class="header-button-box distribution-box"
          [ngClass]="(dataService.showTabs && dataService.problemType.shortcut == 'cvrp') ? 'all-buttons-and-overview' : (dataService.showTabs ? 'all-buttons' : null)">
              <div class="cta-buttons">

                  <button type="button" mat-stroked-button color="accent" (click)="openModalForNew()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-add-new-problem')">
                      <mat-icon>create</mat-icon>{{"New" | translate}}
                  </button>

                  <button type="button" mat-stroked-button color="accent" (click)="viewDistribution1ToN()">
                      <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
                  </button>

                  <ng-container *ngIf="dataService.showTabs">
                      <button type="button" mat-stroked-button color="accent" (click)="save()" *ngIf="dataService.isCustom" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-save-problem')">
                          <mat-icon>create</mat-icon>
                          {{"Save" | translate}}
                      </button>

                      <button type="button" mat-stroked-button color="accent" (click)="saveAsNew()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-save-as')">
                          <mat-icon>create</mat-icon>
                          {{"Save As" | translate}}
                      </button>

                      <button type="button" mat-stroked-button color="accent" (click)="openModalBeforeCalculate()" [disabled]="isRunDisabled()">
                          <mat-icon>school</mat-icon>{{"Run" | translate}}
                      </button>

                      <button type="button" mat-stroked-button color="accent" (click)="openSettingsModal()">
                          <mat-icon>settings</mat-icon>
                          {{"RouteSettings" | translate}}
                      </button>

                      <ng-container *ngIf="dataService.problemType.shortcut == 'cvrp'">
                          <button type="button" mat-stroked-button color="accent" (click)="openOverviewModal()" [disabled]="isRunDisabled()">
                              <mat-icon>settings</mat-icon>
                              {{"Overview" | translate}}
                          </button>
                      </ng-container>

                  </ng-container>

                  <div class="reducedForm header-form">
                      <mat-form-field color="accent" appearance="outline">
                          <mat-label>{{"Problems" | translate}}</mat-label>
                          <mat-select [value]="dataService.problemType.value" (selectionChange)="redirectToAnotherProblem($event)">
                              <mat-option *ngFor="let problem of problems" [value]="problem.value">
                                  {{problem.title | translate}}</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>

              </div>
              <div class="admin-button">
                    <ng-container *ngIf="dataService.isEdit">
                        <button type="button" mat-stroked-button color="accent" (click)="export()">
                            <mat-icon>save_alt</mat-icon>{{"Export CSV" | translate}}
                        </button>
                    </ng-container>
                  <button type="button" mat-stroked-button color="accent" (click)="openAdminModal()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-open-admin')">
                      <mat-icon>admin_panel_settings</mat-icon>
                      {{"Admin" | translate}}
                  </button>
                  <button type="button" mat-stroked-button color="accent" (click)="openMarkdown()">
                    <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
                </button>
              </div>
          </div>
      </div>
  </div>

  <ng-container *ngIf="dataService.countryName">
      <div class="row">
          <div class="col-md-12 countryNameInHeader">
              {{ dataService.countryName }}
          </div>
      </div>
  </ng-container>

  <div class="row">
    <div class="col-md-12 iframe-wrapper" *ngIf="!(dataService.showTabs)">
      <div class="iframe-holder">
          <iframe
          [src]="safeData"
          width="100%" height="100%"  frameborder="5">
          </iframe>
      </div>
    </div>
  </div>

</div>
