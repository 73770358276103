<div class="row">
  <div class="col-md-12">
    <h3 class="page-header-title"> <span>{{"Routing" | translate}}</span> / {{"GraphNodes" | translate}}</h3>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="header-button-box distribution-box">
      <div class="cta-buttons">

        <button type="button" mat-stroked-button color="accent" (click)="openModalForNew()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-add-new-problem')">
          <mat-icon>create</mat-icon>{{"New" | translate}}
        </button>

        <button type="button" mat-stroked-button color="accent" (click)="viewGraphNodes()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-open-problem')">
          <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
        </button>
        @if (dataService.showContent) {
          @if (dataService.isCustom) {
            <button type="button" mat-stroked-button color="accent" (click)="save()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-save-problem')">
              <mat-icon>create</mat-icon>
              {{"Save" | translate}}
            </button>
          }
          <button type="button" mat-stroked-button color="accent" (click)="saveAsNew()" [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-save-as')">
            <mat-icon>create</mat-icon>
            {{"Save As" | translate}}
          </button>
        }

      </div>
      <div class="help-block">
        <button type="button" mat-stroked-button color="accent" (click)="openMarkdown()">
          <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  @if (!(dataService.showContent)) {
    <div class="col-md-12 iframe-wrapper">
      <div class="iframe-holder">
        <iframe
          src="assets/markdown/routing/graph-nodes/help.html"
          width="100%" height="100%"  frameborder="5">
        </iframe>
      </div>
    </div>
  }
</div>
