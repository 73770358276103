import { Role } from "../../../shared/interfaces/role";

export const claims_to_check: Role[] = [
    {
        claim: "home",
        title: "Home",
        bytes: []
    },
    {
        claim: "mrp",
        title: "MRP",
        bytes: [
            {
                position: 1,
                access_key: "dashboard-deterministic-mrp",
                title: "Deterministic MRP"
            },
            {
                position: 2,
                access_key: "dashboard-mrp-report",
                title: "MRP Report"
            }
        ]
    },
    {
        claim: "deterministic-mrp",
        title: "Deterministic MRP",
        bytes: [
            {
                position: 1,
                access_key: "ability-to-create-new-problem",
                title: "Ability To Create New Problem"
            },
            {
                position: 2,
                access_key: "ability-to-open-all-problems",
                title: "Ability To Open All Problems"
            },
            {
                position: 3,
                access_key: "ability-to-save-problem",
                title: "Ability To Save Problem"
            },
            {
                position: 4,
                access_key: "ability-to-run-problem",
                title: "Ability To Run Problem"
            },
            {
                position: 5,
                access_key: "ability-to-insert-bom-mps-inventory-capacity",
                title: "Ability To Insert BOM, MPS Inventory, Capacity"
            },
            {
                position: 6,
                access_key: "ability-to-save-as",
                title: "Ability To Save As"
            },
        ]
    },
    {
        claim: "cms",
        title: "CMS",
        bytes: [
            {
                position: 1,
                access_key: "dashboard-cell-formation-problem",
                title: "Cell Formation Problem"
            }
        ]
    },
    {
        claim: "cell-formation-problem",
        title: "Cell Formation Problem",
        bytes: [
            {
                position: 1,
                access_key: "ability-to-create-new-problem",
                title: "Ability To Create New CFP Problem"
            },
            {
                position: 2,
                access_key: "ability-to-open-all-problems",
                title: "Ability To Open All CFP Problems"
            },
            {
                position: 3,
                access_key: "ability-to-run-brusco",
                title: "Ability To Run Brusco"
            },
            {
                position: 4,
                access_key: "ability-to-run-similarity",
                title: "Ability To Run Similarity"
            },
            {
                position: 5,
                access_key: "ability-to-save-problem",
                title: "Ability To Save Problem"
            },
            {
                position: 6,
                access_key: "ability-to-save-as",
                title: "Ability To Save As"
            },
            {
                position: 7,
                access_key: "ability-to-test-problem",
                title: "Ability To Test CFP Problem"
            }
        ]
    },
    {
        claim: "routing",
        title: "Routing",
        bytes: [
            {
                position: 1,
                access_key: "dashboard-distribution",
                title: "Distribution (1 to n)"
            }
        ]
    },
    {
        claim: "single-source-sp",
        title: "Single Source Sp",
        bytes: [
            {
                position: 1,
                access_key: "ability-to-add-new-problem",
                title: "Ability To Add New Problem"
            },
            {
                position: 2,
                access_key: "ability-to-open-problem",
                title: "Ability To Open Problem"
            },
            {
                position: 3,
                access_key: "ability-to-save-problem",
                title: "Ability To Save Problem"
            },
            {
                position: 4,
                access_key: "ability-to-save-as",
                title: "Ability To Save As"
            },
            {
                position: 5,
                access_key: "ability-to-open-admin",
                title: "Ability To Open Admin"
            },
        ]
    },
    {
        claim: "order",
        title: "Order",
        bytes: [
            {
                position: 1,
                access_key: "dashboard-pfsp",
                title: "PFSP"
            }
        ]
    },
    {
        claim: "pfsp",
        title: "PFSP Problem",
        bytes: [
            {
                position: 1,
                access_key: "ability-to-create-new-problem",
                title: "Ability To Create New PFSP Problem"
            },
            {
                position: 2,
                access_key: "ability-to-open-all-problems",
                title: "Ability To Open All PFSP Problems"
            },
            {
                position: 3,
                access_key: "ability-to-save-problem",
                title: "Ability To Save PFSP Problem"
            },
            {
                position: 4,
                access_key: "ability-to-save-as",
                title: "Ability To Save As PFSP Problem"
            },
            {
                position: 5,
                access_key: "ability-to-run-problem",
                title: "Ability To Run PFSP Problem"
            },
            {
                position: 6,
                access_key: "ability-to-sort-problem",
                title: "Ability To Sort PFSP Problem"
            },
            {
                position: 7,
                access_key: "ability-to-load-problem",
                title: "Ability To Load PFSP Problem"
            },
            {
                position: 8,
                access_key: "ability-to-test-problem",
                title: "Ability To Test PFSP Problem"
            }
        ]
    },
    {
        claim: "assignment",
        title: "Assignment",
        bytes: [
            {
                position: 1,
                access_key: "dashboard-qap",
                title: "QAP"
            }
        ]
    },
    {
        claim: "qap",
        title: "QAP Problem",
        bytes: [
            {
                position: 1,
                access_key: "ability-to-create-new-problem",
                title: "Ability To Create New QAP Problem"
            },
            {
                position: 2,
                access_key: "ability-to-open-all-problems",
                title: "Ability To Open All QAP Problems"
            },
            {
                position: 3,
                access_key: "ability-to-save-problem",
                title: "Ability To Save QAP Problem"
            },
            {
                position: 4,
                access_key: "ability-to-save-as",
                title: "Ability To Save As QAP Problem"
            },
            {
                position: 5,
                access_key: "ability-to-run-problem",
                title: "Ability To Run QAP Problem"
            },
            {
                position: 6,
                access_key: "ability-to-test-problem",
                title: "Ability To Test QAP Problem"
            }
        ]
    },
    {
        claim: "3dp",
        title: "3D Packing Problem",
        bytes: [
            {
                position: 1,
                access_key: "ability-to-create-new-problem",
                title: "Ability To Create New 3D Packing Problem"
            },
            {
                position: 2,
                access_key: "ability-to-open-all-problems",
                title: "Ability To Open All 3D Packing Problems"
            },
            {
                position: 3,
                access_key: "ability-to-save-problem",
                title: "Ability To Save 3D Packing Problem"
            },
            {
                position: 4,
                access_key: "ability-to-save-as",
                title: "Ability To Save As 3D Packing Problem"
            },
            {
                position: 5,
                access_key: "ability-to-run-problem",
                title: "Ability To Run 3D Packing Problem"
            }
        ]
    },
    {
        claim: "admin-config",
        title: "Admin Config",
        bytes: []
    }
]