import { ArrayFunctions } from './../../functions/array-functions';
import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';
import { UPDATABLE_COLUMN_PROPS } from '../../consts/table-settings';

@Pipe({
  name: 'booleanColumn'
})
export class BooleanColumnPipe implements PipeTransform {

  transform(column: ITableColumn, row: any): boolean {
    return UPDATABLE_COLUMN_PROPS.indexOf(column.index) === -1 && typeof(row[column.index]) == 'boolean' && !column.isFormControl;
  }

  isBinaryColumn(value) {
    return value === 0 || value===1;
  }
}
