import { Component, OnInit, Input } from '@angular/core';
import { ITableStorage } from '../../bussiness-logic/interfaces/i-storage';
import { BlTableLogicService } from '../../bussiness-logic/base/bl-table-logic.service';
import { PrintFunctions } from '../../functions/print-functions';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  @Input() public dataService: ITableStorage<any>;
  @Input() public tableService: BlTableLogicService<any>;
  @Input() public title: string;

  constructor(
    private printFunctions: PrintFunctions
  ) { }

  ngOnInit() {
  }

  print() {
    let myWindow = window.open("", "print");
    this.printFunctions.generateHeader(myWindow, this.title);
    this.printFunctions.generateTable(myWindow, this.tableService.getColumns(), this.dataService.getCurrentValue());
    this.printFunctions.generateFooter(myWindow);
    this.printFunctions.runPrint(myWindow);
  }
}
