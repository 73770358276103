import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { IQap } from '../../interfaces/i-qap';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../../../../config/api';

@Injectable({
  providedIn: 'root'
})
export class QapService extends ApiService<IQap>{

  constructor(http: HttpClient) {
    super(apiPaths.assignment.qap, http);
  }
}
