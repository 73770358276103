import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PfspHelperFunctionsService {

  constructor() { }

  breakApartStavke(data: any) {
    let dataFromService: any = data[0]? data[0]: data;
    dataFromService.leftBlock.table.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl ? ctrl : '';
      });
      delete row.stavke;
    });
  }
  

  //primjer pravljenja niza stringova mapiranjem
  mapToSingleArray(arr: any[], form: any) {
    let namesOfDynamicControls = [];
    Object.keys(arr[0]).forEach(el => {
      if (el.startsWith("c")) {
        namesOfDynamicControls.push(el);
      }
    })
    arr.forEach(el => {
      el.stavke = "";
      el.tempArr = [];
      namesOfDynamicControls.forEach(name => {
        el.tempArr.push(el[name]);
        delete el[name];
      })
      el.stavke = el.tempArr.join(',')
      delete el["tempArr"];
    })
    return arr;
  }
}
