
<input matInput class="mat-select-search-input mat-select-search-hidden"/>

<div class="mat-select-search-inner"
  [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
  <input matInput
    class="mat-select-search-input"
    #searchSelectInput
    (keydown)="_handleKeydown($event)"
    (input)="onInputChange($event.target.value)"
    (blur)="onBlur($event.target.value)"
    [placeholder]="placeholderLabel"
    />
    @if (value) {
      <button mat-button
        mat-icon-button
        aria-label="Clear"
        (click)="clear(true)"
        class="mat-select-search-clear">
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>

  @if (noEntriesFoundLabel && value && _options?.length === 0) {
    <div
      class="mat-select-search-no-entries-found">
      {{noEntriesFoundLabel}}
    </div>
  }
