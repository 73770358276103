<style>
    @media print {
        html,
        body {
            height: 99%;
        }

        #reportButton,
        #title {
            display: none;
        }
    }
</style>

    <div id="report"></div>

    <div id="title" mat-dialog-title>
        <div class="row">
            <div class="col-sm-12 closeIconBox">
                <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
            </div>
        </div>
    </div>
    
    <mat-dialog-content id="report-modal-dialog">
    
        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{ "Results" | translate }}
                </h3>
            </div>
        </div>
    
        <div class="row">
            <div class="col-sm-12 header-button-box">
                <button (click)="printReport()" mat-stroked-button color="accent">
                    <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
                </button>
            </div>
        </div>
    
        <div class="row" id="report-modal-content">
            <div class="col-sm-12 tab-wrapper">
                <ng-container *ngIf="dataService.reports.length > 0; else noAllTableData;">
                    <ng-container *ngFor="let data of dataService.reports; let i = index;">
                        <div class="row">
                            <div class="col-sm-12 table-header">
                                <p class="table-title">{{data.nazivTabele}}</p>
                                <p class="table-title">{{data.opis}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 table-wrapper">
                                <table class="table table-sm">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th *ngFor="let header of data.table.tableHeader">{{header}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let tableRow of data.table.tableBody">
                                            <ng-container *ngFor="let columns of tableRow.stavke">
                                                <td
                                                    [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                                                    {{columns.value}}
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #noAllTableData>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="alert alert-warning" role="alert">
                                {{"NoRecordsFound" | translate}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    
    </mat-dialog-content>
