<div class="row">
    <div class="col-md-12 section-content">
        <app-header-dashboard-pfsp>
        </app-header-dashboard-pfsp>
        <ng-container *ngIf="formServices.isShowMainTabs">
            <app-tabs-dashboard-pfsp>
            </app-tabs-dashboard-pfsp>
        </ng-container>
    </div>
</div>
