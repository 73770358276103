<span class="parent-span">
    <a [matMenuTriggerFor]="usersMenu" class="user-data" (click)="calculatePositionLeft()">
        <p id="userText">
            <span >{{userName}}</span>
            <mat-icon class="user-icon">person</mat-icon>
        </p>
    </a>
    <mat-menu #usersMenu="matMenu" overlapTrigger="false" class="user-toolbar">
        <mat-toolbar>
          <mat-icon class="mat-menu-icon">verified</mat-icon> {{"Version" | translate}} 1.0
        </mat-toolbar>
        <button mat-menu-item id="manual" title="{{'Logout' | translate}}" (click)="logout()">
            <mat-icon class="mat-menu-icon">exit_to_app</mat-icon>
            <span>{{'Logout' | translate}}</span>
        </button>
    </mat-menu>
</span>
