<div class="custom-form tableForm">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-md-12">
          <h3 class="tab-title">
            {{"CheckMapsToDownload" | translate}} :
          </h3>
        </div>
      </div>
      <hr />
      @if (showControls) {
        <form class="item-master" [formGroup]="form">
          <div class="row">
            <ng-container formArrayName="maps">
              @for (map of freeMaps; track map; let i = $index) {
                <div class="col-md-6 col-lg-4 download-maps">
                  <mat-checkbox matTooltip={{map.description}} class="checkList" color="primary"
                    [formControlName]="i"
                    [value]="map.id">
                    <span>{{ map.description }}</span>
                  </mat-checkbox>
                </div>
              }
            </ng-container>
          </div>
          @if (form.get('maps').hasError('notSelected')) {
            <div class="row mt-10">
              <div class="col-md-12">
                <mat-error class="customError">
                  <p>{{"AtLeastOneMapIsRequired" | translate}}</p>
                </mat-error>
              </div>
            </div>
          }
          <div class="row">
            <div class="col-md-12" [ngStyle]="{'margin-top': form.valid ? '20px' : null}">
              <button mat-flat-button color="primary"
                class="custom-flat-btn"
                (click)="save()"
                [disabled]="form.invalid">{{"Save" | translate}}
              </button>
            </div>
          </div>
        </form>
      }
    </div>

    <div class="col-lg-3">
      <div class="occupiedMaps">
        <h3>{{"DownloadedMaps" | translate}}</h3>
        @if (occupiedMaps.length > 0) {
          @for (occupiedMap of occupiedMaps; track occupiedMap) {
            <div class="row">
              <div class="col-md-12">
                <p> - {{ occupiedMap.description }}</p>
              </div>
            </div>
          }
        } @else {
          <div class="alert alert-warning mt-10">
            {{'NoRecordsFound' | translate}}
          </div>
        }

      </div>
    </div>
  </div>

</div>
