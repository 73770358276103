<div class="row">
    <div class="col-md-12 tab-content">
        <mat-tab-group dynamicHeight="true" [selectedIndex]="selectedTab"
            class="custom-tab-group"
            (selectedTabChange)="onTabChange($event)">
            <mat-tab label="{{'Input Data' | translate}}" [disabled]="!(dataService.originalObject.getValue())">
                <app-tab-dashboard-input-data></app-tab-dashboard-input-data>
            </mat-tab>
            <mat-tab label="{{'Results' | translate}}" [disabled]="isResultsRun == false">
                <app-tab-dashboard-result></app-tab-dashboard-result>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
