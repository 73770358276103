export const UPDATABLE_COLUMN_PROPS = [
    "status", 
    "statu", 
    "required", 
    "statusForGuest", 
    "statusForReservation", 
    "isRequired", 
    "applicationStatus", 
    "ibeStatus", 
    "roomStatus", 
    "repeat", 
    "primary"
]

export const IMAGE_PROP = "imagepath";

export const LAST_PAGE_PAGINATION: number = -9999999999999;