<div class="row">
  <div class="col-md-12">
    <app-header-graph-nodes></app-header-graph-nodes>
  </div>
</div>

@if (dataService.showContent) {
  <div class="row">
    <div class="col-md-12">
      <app-table-graph-nodes></app-table-graph-nodes>
    </div>
  </div>
}