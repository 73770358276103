import { Component, OnInit, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { TableFunctions } from '../../functions/table-functions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Location } from '@angular/common';
import { IDialogDimension } from '../../interfaces/i-dialog-dimension';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {

  constructor(
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) { }

  ngOnInit() {
  }

  openDialog(entryComponent: ComponentType<any> | TemplateRef<any>, data?: any, dimensions?: object, afterClose?
  ) {
    return this.tableFunctions.openDialog(this.matDialog, entryComponent, data, dimensions, afterClose);
  }

  getDefaultButtons(
    formComponent: ComponentType<any> | TemplateRef<any> | any,
    dialogDimensions: IDialogDimension, afterClose = null
  ) {
    return [
      {
        title: "Add",
        method: (id?) => {
          this.openDialog(formComponent, { id: null }, dialogDimensions, afterClose);
        }
      },
      {
        title: "Delete",
        method: "onGroupDelete"
      },
      {
        title: "Back",
        method: () => {
          this.goBack();
        }
      }
    ];
  }

  getEditColumn(entryComponent: ComponentType<any> | TemplateRef<any>, dimensions: any = null, afterClose = null) {
    return {
      index: 'actions',
      title: 'Actions',
      options: [
        {
          title: 'Edit',
          method: (id?) => { this.openDialog(entryComponent, { id: id }, dimensions, afterClose) },
          param: 'id',
        },
      ],
    };
  }

  goBack() {
    this.location.back();
  }
}
