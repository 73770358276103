<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 formHeading">
            <h3 class="modalHeading">
                {{ "Overview" | translate }}
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 table-box overview-table">
            <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService">
            </app-table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 close-btn">
            <button mat-flat-button type="button" color="warn" [mat-dialog-close]="true">{{"Close" | translate}}</button>
        </div>
    </div>
</div>

