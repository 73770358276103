<style>
  @media print {
  html,
  body {
  height: 99%;
}

#reportButton,
#title {
display: none;
}
}
</style>

<form class="custom-form" [formGroup]="formService.form">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="page-header-title">
        {{"Square Assignment" | translate}} - {{"Test" | translate}}
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-md-3 col-lg-3 input-mobile">
      <mat-form-field appearance="outline" [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''" color="primary">
        <mat-label>{{ "Algorithm" | translate}}</mat-label>
        <mat-select panelClass="custom-mat-multiselect-panel-class"
          [formControlName]="formService.form.keys.algorithm" disableOptionCentering multiple>
          @for (option of dropdownData.Algorithms; track option) {
            <mat-option (onSelectionChange)="changeAlgorithmOptions($event.source)" [value]="option.id">
              {{option.title | translate}}
            </mat-option>
          }
        </mat-select>
        <mat-error class="customError">
          @if (formService.form.get('algorithm').hasError('required')) {
            {{"ThisFieldIsRequired" | translate}}
          }
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-6 col-md-3 col-lg-2 input-mobile">
      <mat-form-field appearance="outline" [ngClass]="formService.form.get('instances').errors ? 'requiredField' : ''" color="primary">
        <mat-label>{{ "Instance" | translate}}</mat-label>
        <mat-select panelClass="custom-mat-multiselect-panel-class"
          [formControlName]="formService.form.keys.instances" disableOptionCentering multiple>
          @for (option of dropdownData.Instances; track option) {
            <mat-option [value]="option.id">
              {{option.title}}
            </mat-option>
          }
        </mat-select>
        <mat-error class="customError">
          @if (formService.form.get('instances').hasError('required')) {
            {{"ThisFieldIsRequired" | translate}}
          }
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-3 col-lg-5 btn-run">
      <button mat-flat-button color="primary" type="submit"
        class="custom-flat-btn"
        [disabled]="!this.formService.form.valid"
        (click)="runTest()">{{'Run' | translate}}</button>
    </div>
  </div>

  @if (formService.form.get('algorithmParams')['controls']) {
    <ng-container formArrayName="algorithmParams">
      @for (item of parametri; track item; let i = $index) {
        <div class="row">
          <div class="col-sm-6 col-md-3 col-lg-3 input-mobile"></div>
          <div class="col-sm-6 col-md-3 col-lg-2 input-mobile">
            @if ((item.parametri | json) != ({} | json)) {
             <span class="input-mobile-subtitle">{{item.title}}</span>
            }
            <ng-container [formArrayName]="i">
              @for (item2 of item.parametri | keyvalue; track item2; let j = $index) {
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{$any(item2.key) | translate}}</mat-label>
                      <input [formControl]="formService.form.get('algorithmParams')['controls'][i].get('values')['controls'][j].get('value')" matInput
                        autocomplete="off" type="number">
                      </mat-form-field>
                    </div>
                  </div>
                }
              </ng-container>
              @if ((item.parametri | json) != ({} | json)) {
                <hr class="input-mobile-divider"/>
              }
            </div>
            <div class="col-sm-6 col-md-6 col-lg-7 btn-run"></div>
          </div>
        }
      </ng-container>
    }

    @if (formService.isShowReportTable) {
      <div class="row">
        <div class="col-md-12 header-button-box">
          <button (click)="printReport()" id="reportButton"
            class="custom-stroked-btn"
            mat-stroked-button color="primary">
            <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
          </button>
        </div>
      </div>
      <div class="row" id="report">
        <div class="col-md-12 dynamic-column">
          @if (dataForTable) {
            @for (data of dataForTable; track data; let i = $index) {
              <div class="row">
                <div class="col-sm-12">
                  <span class="table-title" [innerHTML]="data.nazivTabele | safeHtml"></span>
                  <span class="table-title" [innerHTML]="data.opis | safeHtml"></span>
                </div>
              </div>
              <table class="table table-sm table-dynamic">
                <thead class="thead-dark">
                  <tr>
                    <th>&nbsp;</th>
                    @for (header of data.table.tableHeader; track header) {
                      <th>{{header}}</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (tableRow of data.table.tableBody; track tableRow; let j = $index) {
                    <tr>
                      <td>{{j+1}}</td>
                      @for (columns of tableRow.stavke; track columns) {
                        <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                          {{columns.value}}
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            }
          } @else {
            <div class="row">
              <div class="col-md-12">
                <div class="alert alert-warning" role="alert">
                  {{"NoRecordsFound" | translate}}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </form>
