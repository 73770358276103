<div class="dashboard-content">
    <div class="row">
        <div class="col-md-12 customPadingRight">
            <mat-tab-group (selectedTabChange)="onTabChange($event)" dynamicHeight="true"
                class="custom-tab-group">
                <mat-tab label="{{'ForwardGeoLocation' | translate}}">
                    <app-forward-geo-location>
                    </app-forward-geo-location>
                </mat-tab>
                <mat-tab label="{{'BackwardGeoLocation' | translate}}">
                    <app-backward-geo-location>
                    </app-backward-geo-location>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
