import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IBackpackProblemForm } from '../../../../interfaces/i-backpack-problem';
import { BlBackpackProblemFormService } from '../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { BlBackpackProblemItemsTableService } from '../../../../services/table/bl-backpack-problem-items-table.service';
import { BackpackHelperFunctionsService } from '../../../../services/helper/backpack-helper-functions.service';
import { flow } from 'lodash';

@Component({
  selector: 'app-edit-items-dialog-backpack-problem',
  templateUrl: './edit-items-dialog-backpack-problem.component.html',
  styleUrls: ['./edit-items-dialog-backpack-problem.component.scss']
})
export class EditItemsDialogBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<EditItemsDialogBackpackProblemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: BlBackpackProblemDataService,
    public formService: BlBackpackProblemFormService,
    public tableService: BlBackpackProblemItemsTableService,
    private alertService: AlertMessageDataService,
    private requestService: BlBackpackProblemRequestsService,
    private cdr: ChangeDetectorRef,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;
  public isViewLoad: boolean = false;
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IBackpackProblemForm> = this.formService.form;
  public numbers: any = [];
  public showForm: boolean = false;

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscription.add(
        this.dataService.originalObject.subscribe({
          next: dataView => {
            if (dataView && dataView.id) {
              this.numbers = Array(this.dataService.itemNumberOfColumns).fill(0).map((x, i) => i);
              this.tableService.createDynamicHeaderColumnsTable(this.formService.sizes);
              this.formService.setItemsRows(dataView[0] ? dataView[0] : dataView);
            }
          }
        })
      );
      this.cdr.detectChanges();
    }, 0)

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.subscription.add(
        this.dataService.originalObject.subscribe({
          next: data => {
            if(data) {
              this.dataFromStorage = data;
              this.formService.sizes = this.dataFromStorage.size;
              this.dataFromStorageForSend = data;

              this.numbers = Array(this.dataService.itemNumberOfColumns).fill(0).map((x, i) => i);
              this.tableService.createDynamicHeaderColumnsTable(this.formService.sizes);
              this.showForm = true;
              if(data.id) {
                this.formService.setItemsRows(data);
              } else {
                this.formService.createItemsTableWithDynamicRows(this.dataService.itemNumberOfColumns, this.formService.sizes);
              }

            }
          }
        })
      );
    }, 0)

  }

  save() {
    this.clickedButton = true;
    let distanceRows: any = null;
    let flowRows: any = null;

    if(this.dataFromStorage) {
      let form: any = this.formService.form.getRawValue();
      if(this.dataFromStorage.id) {
        flowRows = this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.items, form);
        distanceRows = (this.dataFromStorage.containers.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.dataFromStorage.containers, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.formService.reducedContainersArray, this.formService.reducedContainersArray);
      } else {
        flowRows = this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.items, form);
        let prepakovanNizObjekataContainers: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
        distanceRows = (form.containers.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.containers, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataContainers, prepakovanNizObjekataContainers);
      }

      this.prepareDataToSend(flowRows, distanceRows);

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.subscription.add(
          this.requestService.saveData(this.dataForSend).subscribe({
            next: success => {
              this.backpackHelperFunctionsService.breakApartStavke(success);
              this.alertService.setMessage("success", this.dataForSend.id ? "Successfully updated." : "Successfully created.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.dataService.originalObject.next(success);
              if (success['id']) {
                this.dataService.setIdForPatch(success['id']);
              }
              this.close(true);
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }
  }

  prepareDataToSend(flowRows: any, distanceRows: any): void {
    this.dataForSend = [];

    this.dataForSend.push({
      id: this.dataFromStorage.id ? this.dataFromStorage.id : null,
      title: this.dataFromStorage.title,
      problemType: this.dataFromStorage.problemType,
      items: flowRows,
      containers: distanceRows,
    });
  }

  close(state?: boolean) {
    this.dialogRef.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clickedButton = false;
  }

}
