import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseComponent } from '../../../../../../shared/components/base/base.component';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlDistribution1ToNRequestsService } from '../../../../services/requests/bl-distribution-1-to-n-requests.service';
import { SaveAsNewDashboardDistributionComponent } from '../save-as-new-dashboard-distribution/save-as-new-dashboard-distribution.component';

@Component({
  selector: 'app-admin-dashboard-distribution',
  templateUrl: './admin-dashboard-distribution.component.html',
  styleUrls: ['./admin-dashboard-distribution.component.scss']
})
export class AdminDashboardDistributionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SaveAsNewDashboardDistributionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private requestsService: BlDistribution1ToNRequestsService,
    private alertService: AlertMessageDataService
  ) {}

  ngOnInit(): void {}

  adminInfo: string = "";

  submit(): void {
    SpinnerFunctions.showSpinner();
    this.requestsService.admin().subscribe({
      next: data => {
        if(data.adminInfo) {
          this.adminInfo = data.adminInfo;
        }
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage("success", "Success!");
      }, 
      error: err => {
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage("danger", "Error!");
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}