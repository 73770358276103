<div id="login-wrapper">

  <div class="login-form">
    <div class="row">
      <div class="col-md-12">
        <h3>{{"COMPUTER INTEGRATED MANUFACTURING" | translate}}</h3>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{'Email' | translate}}</mat-label>
            <input matInput (keydown.enter)="textareaEnterPressed($event)" type="text" formControlName="email" />
            @if (form.get('email').hasError('required')) {
              <mat-error>
                {{"ThisFieldIsRequired" | translate}}
              </mat-error>
            }
            @if (form.get('email').hasError('email')) {
              <mat-error>
                {{'Email is not in a good format' | translate}}
              </mat-error>
            }
            @if (form.get('email').hasError('noWhiteSpace')) {
              <mat-error>
                {{"NoWhiteSpace" | translate}}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="button" mat-flat-button color="primary" (click)="submit()" [disabled]="form.invalid">{{'Reset Password' | translate}}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 forget-password">
          <a routerLink=""><span>{{"Back to Login Page" | translate}}</span></a>
        </div>
      </div>
    </form>
  </div>

</div>
