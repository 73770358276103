import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { share } from 'rxjs/operators';
import { AlertMessageDataService } from '../../services/alert-message-data.service';
import { ApiService } from '../../services/api.service';
import { SpinnerFunctions } from '../../functions/spinner-functions';
import { ITableStorage } from '../interfaces/i-storage';
import { LAST_PAGE_PAGINATION } from '../../consts/table-settings';

export abstract class BlRequestService {

  constructor(
    public alertMessageService: AlertMessageDataService
  ) { }

  insert(form: UntypedFormGroup | any, nameOfCreated: string, service: ApiService<any>, dataService?: any, onSuccess?: any, passData?: boolean): Observable<any> {
    let values = form;
    if (form instanceof UntypedFormGroup) {
      values = form.value;
    }

    if ((form instanceof UntypedFormGroup) && !form.valid) {
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }

    let result: Observable<any> = service.create(values).pipe(share());
    this.resultSubscribe(null, result, nameOfCreated, 'insert', service, dataService, onSuccess, passData);

    return result;
  }

  insertCustomRefill(form: UntypedFormGroup | any, nameOfCreated: string, service: ApiService<any>, dataService?: any, getAllAPi?: Observable<any>): Observable<any> {
    let values = form;
    if (form instanceof UntypedFormGroup) {
      values = form.value;
    }

    if ((form instanceof UntypedFormGroup) && !form.valid) {
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }

    let result: Observable<any> = service.create(values).pipe(share());
    this.resultSubscribeCustomRefill(result, nameOfCreated, 'insert', service, dataService, getAllAPi);

    return result;
  }

  update(id: number, form: UntypedFormGroup | any, originalObj: any, nameOfCreated: string, service: ApiService<any>, dataService?: any, onSuccess?: any): Observable<any> {
    let values = form;
    if (form instanceof UntypedFormGroup) {
      values = form.value;
    }


    if ((form instanceof UntypedFormGroup) && !form.valid) {
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }

    // change for nested props!
    // let diffObj : any = diff(originalObj, values);


    // let keys = Object.keys(diffObj);
    // keys.forEach(
    //   key => {
    //     if(isArray(values[key]) && isNumber(values[key][0])){
    //         diffObj[key] = values[key] as number[];
    //       }
    //     }
    // )
    // if((!diffObj) || diffObj==null || diffObj == {} || (keys.length == 0) || (keys.length==1 && keys[0]=='id')){
    //   this.alertMessageService.setMessage('warning', 'INFO: There is nothing to update.');
    //   return new Observable<any>();
    // } else {

    let result: Observable<any> = service.update(id, values).pipe(share());
    this.resultSubscribe(id, result, nameOfCreated, 'updat', service, dataService, onSuccess);

    return result;
    // }

  }

  public updateCustomRefill(id: number, form: UntypedFormGroup | any, originalObj: any, nameOfCreated: string, service: ApiService<any>, dataService?: any, getAllAPi?: Observable<any>): Observable<any> {
    let values = form;
    if (form instanceof UntypedFormGroup) {
      values = form.value;
    }


    if ((form instanceof UntypedFormGroup) && !form.valid) {
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }

    // change for nested props!
    // let diffObj : any = diff(originalObj, values);


    // let keys = Object.keys(diffObj);
    // keys.forEach(
    //   key => {
    //     if(isArray(values[key]) && isNumber(values[key][0])){
    //         diffObj[key] = values[key] as number[];
    //       }
    //     }
    // )
    // if((!diffObj) || diffObj==null || diffObj == {} || (keys.length == 0) || (keys.length==1 && keys[0]=='id')){
    //   this.alertMessageService.setMessage('warning', 'INFO: There is nothing to update.');
    //   return new Observable<any>();
    // } else {

    let result: Observable<any> = service.update(id, values).pipe(share());
    this.resultSubscribeCustomRefill(result, nameOfCreated, 'updat', service, dataService, getAllAPi);

    return result;
    // }

  }

  /**
   * 
   * @param form FormGroup which must contains "items" FormArray
   * @param originalObj array of elements
   * @param nameOfCreated 
   * @param dataService 
   * @param onSuccess 
   */
  updateAll(form: UntypedFormGroup | any, originalObj: any[], nameOfCreated: string, service: ApiService<any>, dataService?: any, onSuccess?: any) {
    let values = form;
    if (form instanceof UntypedFormGroup) {
      if (!form.contains('items')) {
        this.alertMessageService.setMessage('danger', 'Programming error!!! You need to setup "items" FormArray!');
        return new Observable<any>();
      }
      values = form.get('items').value;
    }


    if ((form instanceof UntypedFormGroup) && !form.valid) {
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }


    // change for nested props!
    // let diffObjs : any = diff(originalObj, values);

    // let ids = originalObj.map(x => x.id);

    // let keys = Object.keys(diffObjs);
    // keys.forEach(
    //   (key) => {
    //     diffObjs[key]['id'] = ids[key];
    //   }
    // );

    let result: Observable<any> = service.updateAll(values).pipe(share());
    this.resultSubscribe(null, result, nameOfCreated, 'updat', service, dataService, onSuccess);

    return result;
  }

  private resultSubscribe(id: number, result: Observable<any>, nameOfCreated: string, operation: string, service: ApiService<any>, dataService?: ITableStorage<any>, onSuccess?: any, passData?: boolean) {
    SpinnerFunctions.showSpinner();
    result.subscribe({
      next: success => {
        this.alertMessageService.setMessage('success', nameOfCreated + " successfully " + operation + "ed.");
        // "DEV" env - move in "success"
        if (dataService != null) {
          service.getWithAllData().subscribe({
            next: (data: any) => {
              let datas = data.data ? data.data : data;
              dataService.setStorage(datas);

              if(dataService._paginationGoToId) {
                if(id){
                  dataService._paginationGoToId.next(id);
                } else {
                  dataService._paginationGoToId.next(LAST_PAGE_PAGINATION);
                }
              }
              
              SpinnerFunctions.hideSpinner();
            }
          })
        }
        if (onSuccess) {
          if (passData) {
            onSuccess(success);
          }
          else {
            onSuccess();
          }
        }
      },
      error: error => {
        this.alertMessageService.setMessage('danger', "Error on " + operation + "ing " + nameOfCreated);

        if (error.hasOwnProperty('statusCode') && error.statusCode == 400) {
          if (dataService != null) {
            service.getWithTotalCount().subscribe({
              next: data => {
                dataService.storage.next(data as any);
              }
            });
          }
        }

        // "DEV" env
        if (onSuccess)
          onSuccess();

        SpinnerFunctions.hideSpinner();
      }
    });
  }


  private resultSubscribeCustomRefill(result: Observable<any>, nameOfCreated: string, operation: string, service: ApiService<any>, dataService?: any, getAllAPi?: Observable<any>) {
    SpinnerFunctions.showSpinner();
    result.subscribe({
      next: success => {
        this.alertMessageService.setMessage('success', nameOfCreated + " successfully " + operation + "ed.");
        // "DEV" env - move in "success"
        if (dataService != null) {
          getAllAPi.subscribe({
            next: (data: any) => {
              dataService.setStorage(data.data ? data.data : data);
              SpinnerFunctions.hideSpinner();
            }
          });
        }

      },
      error: error => {
        this.alertMessageService.setMessage('danger', "Error on " + operation + "ing " + nameOfCreated);

        if (error.hasOwnProperty('statusCode') && error.statusCode == 400) {
          if (dataService != null) {
            getAllAPi.subscribe({
              next: data => {
                dataService.storage.next(data);
              }
            });
          }
        }
        SpinnerFunctions.hideSpinner();
      }
    });
  }
}
