import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';
import { ColumnOption } from '../../interfaces/column-option';

@Pipe({
  name: 'columnValueCondition'
})
export class ColumnValueConditionPipe implements PipeTransform {

  transform(column: ITableColumn | ColumnOption, row: any): boolean {
    return (column.hasOwnProperty('condition') && column.condition(row)) || (!column.hasOwnProperty('condition'));
  }

}
