import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { FormGroupTypeSafe } from '../../../../../../../../../../shared/helpers/reactive-forms-helper';
import { IQapDropdownData, IQapForm } from '../../../../../../../../interfaces/i-qap';
import { BlQapFormService } from '../../../../../../../../services/forms/bl-qap-form.service';
import { BlQapRequestsService } from '../../../../../../../../services/requests/bl-qap-requests.service';
import { BlQapDataService } from '../../../../../../../../services/shared/bl-qap-data.service';
import { BlQapFlowsTableService } from '../../../../../../../../services/table/bl-qap-flows-table.service';
import { EditFlowsDialogDashboardQapComponent } from '../../../../../edit-flows-dialog-dashboard-qap/edit-flows-dialog-dashboard-qap.component';
import { QapHelperFunctionsService } from '../../../../../../../../services/helper/qap-helper-functions.service';

@Component({
  selector: 'app-tab-dashboard-input-data-left-side',
  templateUrl: './tab-dashboard-input-data-left-side.component.html',
  styleUrls: ['./tab-dashboard-input-data-left-side.component.scss']
})
export class TabDashboardInputDataLeftSideComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private dataService: BlQapDataService,
    public formService: BlQapFormService,
    public tableService: BlQapFlowsTableService,
    private requestService: BlQapRequestsService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IQapForm> = this.formService.form;
  public dataForTable: any = [];
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  public isProblemLiterature: boolean = false;

  // public parametri: any = [];

  public dropdownData: IQapDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    Methods: null
  }

  ngAfterViewInit(): void {
    this.dataService.originalObject.subscribe({
      next: (data) => {
        if (data) {
          this.dataFromStorageForSend = data;
          if(this.dataFromStorageForSend.problemType == 'l') {
            this.isProblemLiterature = true;
          } else {
            this.isProblemLiterature = false;
          }

          let podaci: any = data[0] ? data[0].flows : data.flows;
          if(podaci) {
            this.dataForTable = this.flowsAndDistancesArrayFunstionsService.mapiranjeNizaNizova(podaci);
            this.tableService.createDynamicHeaderColumnsTable2(data[0] ? data[0].size : data.size);
          }
        }
      }
    });
    this.getAlgorithms();

    this.cdr.detectChanges();
  }

  ngOnInit(): void {

    // this.getAlgorithms();

    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          if(data) {
            this.dataFromStorage = data;
            if (data.id) {
              let podaci: any = data[0] ? data[0].flows : data.flows;
              this.dataFromStorageForSend = data;
              if(this.dataFromStorageForSend.problemType == 'l') {
                this.isProblemLiterature = true;
              } else {
                this.isProblemLiterature = false;
              }
              this.dataForTable = this.flowsAndDistancesArrayFunstionsService.mapiranjeNizaNizova(podaci);
              this.tableService.createDynamicHeaderColumnsTable2(data[0] ? data[0].size : data.size);
            } else {
              this.formService.sizes = this.dataFromStorage.size;
              this.tableService.createDynamicHeaderColumnsTable2(this.formService.sizes);
              this.dataFromStorageForSend = data;
    
              if(this.dataFromStorageForSend.problemType == 'l') {
                this.isProblemLiterature = true;
              } else {
                this.isProblemLiterature = false;
              }
    
              let prepakovanNizObjekata: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.formService.sizes);
              this.dataForTable = this.flowsAndDistancesArrayFunstionsService.mapiranjeNizaNizova(prepakovanNizObjekata);
            }
          }
        }
      })
    );
  }

  openEditDialog() {
    this.matDialog.open(EditFlowsDialogDashboardQapComponent, {
      width: '90%',
      maxWidth: '1200px',
      height: 'auto',
      minHeight: '150px',
      panelClass: 'customModal'
    })
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestService.getAlgorithms().subscribe({
        next: (data) => {
          if (data) {
            // data.unshift({
            //   id: 0,
            //   value: "",
            //   title: "Select"
            // });
            this.dropdownData.Algorithms = data;
            this.formService.form.getSafe(x => x.algorithm).setValue(data[0].id);
            this.formService.algorithmData = data;
          }
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.sizes = 0;
    this.clickedButton = false;
    this.isProblemLiterature = false;
    this.dataForTable = [];
    this.dataForSend = [];
    this.dataFromStorage = [];
    this.dataFromStorageForSend = [];
  }

  changeAlgorithm(value): void {
    const choosenAlgorithm = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === value);
    if(choosenAlgorithm.parametri) {
      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls(choosenAlgorithm.parametri, this.form);
      this.formService.parametri = Object.keys(choosenAlgorithm.parametri);
    }
  }

}
