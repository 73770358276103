<form (ngSubmit)="showMap()" [formGroup]="formService.form"
      class="custom-form tableForm">
  <div class="row">
    <div class="col-md-2">
      <div class="row">
        <div class="col-sm-6 col-md-12">
          <mat-form-field appearance="outline" color="primary">
            <mat-label>{{"Maps" | translate}}</mat-label>
            <mat-select [formControlName]="formService.form.keys.maps" panelClass="custom-mat-select-panel-class">
              @for (option of freeMaps; track option) {
                <mat-option [value]="option.id">
                  {{option.description}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-6 col-md-12">
          <button id="showMapBtn" mat-flat-button color="primary" type="submit"
            class="custom-flat-btn"
            [disabled]="formService.form.invalid">
            {{'ShowMap' | translate}}
          </button>
        </div>
      </div>
    </div>

    @if (isMapVisible) {
      <div class="col-md-10 show-area-on-map">
        <app-leaflet-map [markers]="dataService.coordinatesForMainMap.markers"
          [routes]="dataService.coordinatesForMainMap.routes" [fitBounds]="true" [clickEvent]="false"
          [getUsersLocationOnInit]="false">
        </app-leaflet-map>
      </div>
    }
  </div>
</form>
