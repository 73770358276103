<div id="alert-wrapper">
  <div id="alert-block">
    <div *ngFor="let message of _messages | async" class="alert"  [ngClass]="'alert-'+ message.type" (close)="closeAlert(message)">
      {{ message.content | translate}}
    </div>
    <div *ngFor="let message of _messages1 | async" class="alert"  [ngClass]="'alert-'+ message.type" (close)="closeAlert(message)">
      <span [innerHtml]="message.content | safeHtml"></span>
    </div>
  </div>
</div>
