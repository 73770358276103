import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';


@Component({
  selector: 'app-packing-test',
  templateUrl: './packing-test.component.html',
  styleUrls: ['./packing-test.component.scss']
})
export class PackingTestComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    protected matDialog: MatDialog
  ) {}

  items = [
    {
      "ID": 1000,
      "IsPacked": true,
      "Dim1": 5,
      "Dim2": 4,
      "Dim3": 2,
      "CoordX": 0,
      "CoordY": 0,
      "CoordZ": 0,
      "PackDimX": 5,
      "PackDimY": 2,
      "PackDimZ": 4,
      "Volume": 40
    },
    {
      "ID": 1001,
      "IsPacked": true,
      "Dim1": 2,
      "Dim2": 1,
      "Dim3": 1,
      "CoordX": 5,
      "CoordY": 0,
      "CoordZ": 0,
      "PackDimX": 1,
      "PackDimY": 2,
      "PackDimZ": 1,
      "Volume": 2
    }
  ];

  itemsTmp = [];
  currentItemIndex: number = -1;

  ngOnInit(): void {}

  pack(): void {
    this.currentItemIndex++;
    this.itemsTmp = cloneDeep(this.items).slice(0, this.currentItemIndex + 1);
  };

  unpack(): void {
    this.currentItemIndex--;
    this.itemsTmp = cloneDeep(this.items).slice(0, this.currentItemIndex + 1);
  }

  close(): void {
    this.matDialog.closeAll();
  }

}
