import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardPfspComponent } from './components/dashboard-pfsp/dashboard-pfsp.component';
import { RoleGuard } from '../../core/guards/role.guard';

const routes: Routes = [
  {
    path: "dashboard-pfsp",
    component: DashboardPfspComponent,
    canActivate: [RoleGuard],
    data: {section: "order", child: "dashboard-pfsp" }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PfspRoutingModule { }
