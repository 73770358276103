import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlCapacityTableService {

  constructor() { }

  private fixedColumns: any[] = [];

  headersTable: any[] = this.fixedColumns;

  createDynamicHeaderColumns(numberCol: number, type: string) {
    this.fixedColumns = [];
    if (numberCol) {
      if (type == "Day") {
        for (let i = 1; i <= numberCol; i++) {
          this.fixedColumns.push({ "index": i, title: "Day" });
        }
      } else if (type == "Week") {
        for (let i = 1; i <= numberCol; i++) {
          this.fixedColumns.push({ "index": i, title: "Week" });
        }
      } else if (type == "Month") {
        for (let i = 1; i <= numberCol; i++) {
          this.fixedColumns.push({ "index": i, title: "Month" });
        }
      } else if (type == "Quater") {
        for (let i = 1; i <= numberCol; i++) {
          this.fixedColumns.push({ "index": i, title: "Quater" });
        }
      }

      this.headersTable = this.fixedColumns;
    }
  }

  removeDynamicHeaderColumns() {
    for (let i = 1; i <= this.fixedColumns.length; i++) {
      this.fixedColumns.slice(i, 1);
    }
  }
}
