import { Component, OnInit } from '@angular/core';
import { BlChangePasswordFormService } from '../services/forms/bl-change-password-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IChangePasswordForm } from '../interfaces/i-change-password';
import { Location } from '@angular/common';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { SpinnerFunctions } from '../../../shared/functions/spinner-functions';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    public formService: BlChangePasswordFormService,
    public activatedRoute: ActivatedRoute,
    private location: Location,
    private alertService: AlertMessageDataService,
    private router: Router
  ) {}

  public form: FormGroupTypeSafe<IChangePasswordForm>;

  ngOnInit() {
    this.form = this.formService.getFormReference();
  }

  setConfirmPassword(): void {
    this.formService.passwordValidation();
  }

  passStrengthChanged(strength): void {
    this.formService.passStrengthChanged(strength);
  }

  changeCurrentPass(): void {
    this.formService.changeCurrentPass();
  }

  save(): void {
    SpinnerFunctions.showSpinner();

    this.formService.submitInsert()
      .subscribe({
        next: success => {
          SpinnerFunctions.hideSpinner();
        }, 
        error: error => {
          SpinnerFunctions.hideSpinner();
        }
      });
  }

}