import { UntypedFormGroup, ValidationErrors, UntypedFormControl, AbstractControl, Validators } from "@angular/forms";
import { DateFunctions } from "./date-functions";
import { AlertMessage } from '../interfaces/alert-message';

export class ValidationFunctions {

    static getFormErrors(form : UntypedFormGroup) : ValidationErrors[]{
        var errors : any[] = [];
        Object.keys(form.controls).forEach(key => {

            const controlErrors: ValidationErrors = form.get(key).errors;
            
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors.push({
                        type: 'danger',
                        field: key,
                        error: keyError
                    })
                });
            }
        });

        return errors;
    }

    static getFormErrorMessages(form : UntypedFormGroup){
        let errors = ValidationFunctions.getFormErrors(form);
        let messages : AlertMessage[] = [];
        errors.forEach(
            error => {
                messages.push({
                    type: error.type,
                    content: 'Field: ' + error.field + ', ' + error.error
                })
            }
        )
        return messages;
    }

    static runValidation(formGroup: UntypedFormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
          control.markAsTouched();
          if (control.controls) {
            this.runValidation(control);
          }

        });
      }

      
    static noStartingSpaceValidator(control: AbstractControl) : ValidationErrors | null {
       
      return control.value? control.value.startsWith(' ') ? {'cannotStartWithSpace': true} : null : null;
      
    }


    static isValueNumber(control: AbstractControl) : ValidationErrors | null {
       
      return control.value ? isNaN(control.value) ? {'onlyNumbers': true} : null : null;
      
    }

    static dateBeforeToday(control: AbstractControl) : ValidationErrors | null {
        let selected = new Date(control.value);
        selected.setHours(0,0,0);

        let current = new Date();
        current.setHours(0,0,0);
        
        return selected.getTime() < current.getTime() ? {'dateError': true} : null;
        
      }

    static noWhiteSpaceValidator(control: AbstractControl) : ValidationErrors | null {
       
      return control.value ? 
      (control.value || '').trim().length === 0 || 
      (control.value as string).startsWith(' ') || 
      (control.value as string).endsWith(' ') ? {'noWhiteSpace': true} : null : null;
      
    }

    static noNegativeValue(control: AbstractControl) : ValidationErrors | null {
       
        return control.value ? (control.value.toString()).length === 0 || (control.value.toString()).startsWith('-') || (control.value.toString()).startsWith('+') ? {'noNegativeValue': true} : null : null;
        
      }

    static dateLessThanTodayValidator = () => {
        return (c: UntypedFormControl) => {
          let controlValue = DateFunctions.convertDate(c.value, "YYYY-MM-DD");
            if (ValidationFunctions.isDateBeforeToday(controlValue)){
                return null;
            }
            else {
                return {
                    arrivalValid: {
                        valid: false
                    }
                }
            }
        }
    }
    static isDateBeforeToday(date){
        return ValidationFunctions.nullifyHours(date).getTime() >= ValidationFunctions.nullifyHours(new Date(DateFunctions.convertDate((new Date() as any), "YYYY-MM-DD"))).getTime();
    }
    static  nullifyHours(date) {
        return new Date(new Date(date).setHours(0, 0, 0, 0))
    }
}
