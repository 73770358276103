<div class="row">
  <div class="col-md-12">
    <div class="help-block">
        <h3 class="page-header-title">{{"Operations Management" | translate}}</h3>
        <button type="button" mat-stroked-button color="primary"
                class="custom-stroked-btn"
                (click)="openMarkdown()">
                <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
        </button>
    </div>
  </div>
</div>

