<ng-container *ngIf="showTable">
    <table class="table selectedItemTable">
        <thead>
            <tr>
                <th>{{"Info" | translate}}</th>
                <th>{{"Value" | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let x of columnsForItem">
                <td [ngClass]="x.type == 'object' ? 'bold' : ''">
                    {{ x.title | translate }}
                </td>
                <td>
                    {{ selectedItem[x.name] }}
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>