<div class="layout-wrapper">
      <div class="layout-header">
          <app-header></app-header>
      </div>
      <div class="fake-block"></div>
      <div class="layout-content">
        <span (click)="toggleLeftBar()">
          {{"MENU" | translate}} <mat-icon [innerHTML]="isLeftSidebarOpen ? 'arrow_left' : 'arrow_right'"></mat-icon>
        </span>
        <div class="layout-left" [@showLeftBar]="isLeftSidebarOpen">
          <div class="sidebar-wrapper">
            <div class="title">
              <h3>Operations management</h3>
            </div>
            <div>
              <app-left-sidebar></app-left-sidebar>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>
      </div>
</div>

