import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { IBackpackProblemSaveAsForm } from '../../interfaces/i-backpack-problem';

@Injectable({
  providedIn: 'root'
})
export class BlBackpackProblemSaveAsFormService implements IFormService<IBackpackProblemSaveAsForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe
  ) { }

  form: FormGroupTypeSafe<IBackpackProblemSaveAsForm> = this.init();

  init(): FormGroupTypeSafe<IBackpackProblemSaveAsForm> {
    let group: FormGroupTypeSafe<IBackpackProblemSaveAsForm> = this.formBuilder.group<IBackpackProblemSaveAsForm>({
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
    });
    ValidationFunctions.runValidation(group);
    return group;
  }

  getFormReference(): FormGroupTypeSafe<IBackpackProblemSaveAsForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }

}