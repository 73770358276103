<!-- Header Dialog-->
<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>
<!-- End Header Dialog-->

<form class="reducedForm languageForm" [formGroup]="form" (ngSubmit)="save()">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">{{"ChooseLanguage" | translate}}</h3>
            </div>
        </div>
        <ng-container *ngIf="!isSpinnerVissible; else inputSpinner">
            <mat-form-field color="accent" appearance="outline">
                <mat-label>{{"ChooseLanguage" | translate}}</mat-label>
                <mat-select class="selectedImg" [(value)]="selected" [ngClass]="selected" [formControlName]="form.keys.language" disableOptionCentering>
                    <mat-option *ngFor="let lng of languages" [value]="lng.language">
                     <img src={{baseUrl+lng.imagePath}}>&nbsp; {{lng.title | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-template #inputSpinner>
            <app-input-with-spinner inputLabel="{{'ChooseLanguage' | translate}}"></app-input-with-spinner>
        </ng-template>
    </div>
    <div mat-dialog-actions>
        <!-- <button mat-flat-button color="accent" (click)="close()">{{"Close" | translate}}</button> -->
        <div class="row full-width mt-10">
            <app-button-with-spinner class="fullWidth" [isClickedSave]="isClickedSave" [data]="selected" [form]="form"></app-button-with-spinner>
        </div>
    </div>
</form>