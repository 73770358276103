import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IQapConfigForm, IQapDropdownData } from '../../../../interfaces/i-qap';
import { BlQapFormService } from '../../../../services/forms/bl-qap-form.service';
import { BlQapDataService } from '../../../../services/shared/bl-qap-data.service';
import { INPUT_METHOD } from '../../../../../../config/settings/inputMethod';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FileType } from '../../../../../../shared/enums/file-type';
import { QapHelperFunctionsService } from '../../../../services/helper/qap-helper-functions.service';

@Component({
  selector: 'app-form-dashboard-qap',
  templateUrl: './form-dashboard-qap.component.html',
  styleUrls: ['./form-dashboard-qap.component.scss']
})
export class FormDashboardQapComponent implements OnInit, OnDestroy {

  // @ViewChild('fileNameId', {static: true}) fileNameId: ElementRef;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<FormDashboardQapComponent>,
    public formService: BlQapFormService,
    private matDialog: MatDialog,
    private alertService: AlertMessageDataService,
    private dataService: BlQapDataService,
    private translateService: TranslateService,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
    // private tableFlowService: BlQapFlowsTableService,
    // private tableDistanceService: BlQapDistancesTableService
  ) { }

  private subscription: Subscription = new Subscription();

  public formConfig: FormGroupTypeSafe<IQapConfigForm> = this.formService.getFormConfigReference();

  public dropdownData: IQapDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    Methods: null
  }

  public fileData: FormData;
  public fileName: any = "";
  public countFiles: any = 0;

  public allowedFileTypes: string[] = [FileType.csv]
  
  ngOnInit(): void {
    this.dropdownData.Methods = INPUT_METHOD;

    this.formConfig.getSafe(x => x.inputMethod).valueChanges.subscribe({
      next: (val) => {
        if(val == 1) {
          this.formService.isImportMethod = true;
          this.formService.formConfig.get("size").setValue(1);
          this.formService.formConfig.get("title").setValue("");
          this.translateService.get("File not chosen.").subscribe({
            next: (data) => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          })
        } else if (val == 0) {
          this.formService.isImportMethod = false;
          this.translateService.get("File not chosen.").subscribe({
            next: (data) => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          })
        }
      }
    })
  }

  onFileChanged(file){
    this.fileData = file;
    this.countFiles = 1;
  }

  close(state?: any) {
    this.ref.close(state);
  }

  save() {

    if(this.formService.isImportMethod == false) {
      let formValue: any = this.formService.formConfig.value;

      this.dataService.originalObject.next(formValue);
     
      this.subscription.add(
        this.dataService.storageIsTabsView.next(true)
      );

      this.subscription.add(
        this.dataService.storageDataIsShowOtherTabs.next(false)
      );

      this.alertService.setMessage("success", "Successfully applied.");
      this.close(this.formConfig.getSafe(x => x.title).value);
  
      this.formService.isRunProblemForTitle = false;
      this.formService.problemTitle = "";

    } else {
      if(this.fileData) {

        SpinnerFunctions.showSpinner();
        this.formService.uploadCSV(this.fileData).subscribe({
          next: success => {
            this.flowsAndDistancesArrayFunstionsService.breakApartStavke(success);
            this.dataService.storageIsTabsView.next(true);
            this.dataService.storageDataIsShowOtherTabs.next(true);
            this.dataService.originalObject.next(success);

            
            this.formService.reducedFlowsArray = success[0]? success[0].flows: success["flows"];
            this.formService.reducedDistancesArray = success[0]? success[0].distances: success["distances"];
            this.formService.setFlowsRows(success[0]? success[0]: success);
            this.formService.setDistancesRows(success[0]? success[0]: success);
  
            this.alertService.setMessage("success", "Successfully uploaded CSV.");
            
            this.translateService.get("File not chosen.").subscribe({
              next: (data) => {
                if (data) {
                  document.getElementById("file-name").innerHTML = data;
                }
              }
            });
            this.countFiles = 0;
            this.close(success["title"]);
            SpinnerFunctions.hideSpinner();
          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
          }
        });
      } else {
        this.alertService.setMessage("danger", "You must select a CSV file and then upload it.")
      }
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // this.formService.reset();
    this.formService.formConfig.get("size").setValue(1);
    this.formService.formConfig.get("title").setValue("");
    this.countFiles = 0;
    this.formService.formConfig.get("inputMethod").setValue(0);
  }

}
