import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { TemplateRef } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { Location } from "@angular/common";
import { TableFunctions } from '../../functions/table-functions';
import { ITableColumn } from '../../interfaces/i-table-column';
import { ISetOfColumns } from '../../interfaces/i-set-of-columns';
import { TABLE_APIS } from '../../enums/table-apis';
import { ArrayFunctions } from '../../functions/array-functions';
import { IDialogDimension } from '../../interfaces/i-dialog-dimension';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

export abstract class BlTableLogicService<T> {
  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {}
  protected abstract columns: ITableColumn[];
  protected abstract columnsStorage: BehaviorSubject<ISetOfColumns>;

  public apis = [TABLE_APIS.Get, TABLE_APIS.Delete, TABLE_APIS.Update];

  protected defaultSort: MatSortable = {
    id: "id",
    start: "asc",
    disableClear: true
  };

  protected abstract displayedColumns: string[];
  protected buttons: any[]; // TODO: set type

  protected pageSizeOptions: number[] = [10, 1, 50, 100];
  protected selectedRows = new BehaviorSubject<T[]>([]);
  protected fixedColumns = ["select", "action", "actions", "pos", "changes", "print"];

  public idColumn: string = "id";

  private _clearSelectedRows = new BehaviorSubject<boolean>(false);

  getColumns(): ITableColumn[] {
    // TODO: delete in extended classes
    return this.columns;
  }

  setColumns(columns) {
    this.columns = columns;
  }

  addColumnWithDialog(column: ITableColumn){
    if(!ArrayFunctions.inArray(column.index, this.columns.map(x => x.index))){
      this.columns.push(column);
    } else {
      let existingColumn = this.columns.findIndex(x => x.index == column.index);
      // existingColumn = column;
      this.columns[existingColumn] = column;
    }
    this.setColumnsStorage(this.columns);
  }

  getColumnsStorageCurrentValue(): ISetOfColumns {
    return this.columnsStorage.getValue();
  }

  getColumnsStorage(): Observable<ISetOfColumns> {
    return this.columnsStorage.asObservable();
  }

  getColumnsStorageForPrint() : BehaviorSubject<ISetOfColumns> {
    return this.columnsStorage;
  }

  setColumnsStorage(columns: ITableColumn[]): void {
    this.columns = columns;
    this.displayedColumns = columns.filter(x => !x.hide).map(x => x.index);
    this.columnsStorage.next({
      columns: this.columns,
      displayedColumns: this.displayedColumns
    });
  }

  setColumnsStorageWithDisplayed(columns: ISetOfColumns) {
    this.columnsStorage.next(columns);
  }

  setColumnsStorageWithSetOfColumns(columns: ISetOfColumns) {
    this.columnsStorage.next(columns);
  }

  getFixedColumns(): string[] {
    return this.fixedColumns;
  }

  getDefaultSort() {
    return this.defaultSort;
  }

  getSelectedRows(): Observable<T[]> {
    return this.selectedRows.asObservable();
  }
  setAllSelectedRows(selectedRows: T[]){
    this.selectedRows.next(selectedRows);
  }

  createNewRow(){
    this.setAllSelectedRows([Object.create({})]);
  }

  isCreateNewRow(){
    return this.getCurrentRows().length > 0 && this.getCurrentRows()[0]['id'];
  }

  setSelectedRows(isSelected: boolean, row: T) {
    let currentRows = this.selectedRows.getValue();
    if (isSelected) {
      currentRows.push(row);
    } else {
      ArrayFunctions.removeItem(row, currentRows);
    }
    this.selectedRows.next(currentRows);
  }

  getCurrentRows(): T[] {
    return this.selectedRows.getValue();
  }

  getIsClearSelectedRows(){
    return this._clearSelectedRows.asObservable();
  }
  
  clearSelectedRows() {
    this._clearSelectedRows.next(true);
  }

  clearSelection() {
    this._clearSelectedRows.next(true);
    this.setAllSelectedRows([]);
  }
  selectOnlyOneRow(selectedRows){
    selectedRows.deselect(selectedRows.selected[0]);
  }

  isFixedColumn(index): boolean {
    return ArrayFunctions.inArray(index, this.getFixedColumns());
  }

  getDisplayedColumns(): string[] {
    return this.columns.filter(item => !item.hide).map(item => item.index);
  }

  setIsDisplayedColumn(index: string, isDisplayed: boolean) {
    let column = this.columns.find(x => x.index == index);
    column.hide = !isDisplayed;
    this.setColumnsStorage(this.columns);
  }

  getToggleableColumns(columns?: ITableColumn[]) {
    let columnsToCheck = columns ? columns : this.getColumns();
    return columnsToCheck.filter(item => !this.isFixedColumn(item.index));
  }

  setSortAndPaginatorForDataSource(
    dataSource: MatTableDataSource<any>,
    sort: MatSort,
    paginator: MatPaginator
  ) {
    dataSource.sort = sort;
    if (paginator) {
      paginator._intl.itemsPerPageLabel = this.translateService.instant(
        "ItemsPerPage"
      );
    }
    dataSource.paginator = paginator;
  }

  filterDataSourceBySearchValue(
    dataSource: MatTableDataSource<any>,
    filterValue: string
  ): void {
    dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  getPageSizeOptions(): number[] {
    return this.pageSizeOptions;
  }

  openDialog(
    entryComponent: ComponentType<any> | TemplateRef<any>,
    data?: any,
    dimensions?: object,
    afterClose?
  ) {
    this.tableFunctions.openDialog(
      this.matDialog,
      entryComponent,
      data,
      dimensions,
      afterClose
    );
  }

  getDefaultButtons(
    formComponent: ComponentType<any>,
    dialogDimensions: IDialogDimension,
  ) {
    return [
      {
        title: "Add",
        method: (id?) => {
          this.openDialog(formComponent, { id: null }, dialogDimensions);
        }
      },
      {
        title: "Delete",
        method: "onGroupDelete"
      },
      {
        title: "Close",
        method: () => {
          this.goBack();
        }
      }
    ];
  }

  setEditColumn(entryComponent : ComponentType<any> | TemplateRef<any>, dimensions: any = null) {
    this.columns.push({ 
      index: 'actions', 
      title: 'Actions', 
      options: [
        {
          title: 'Edit',
          method: (id?) => { this.openDialog(entryComponent, { id : id }, dimensions) },
          param: 'id',
        },
      ],
    });
  }

  goBack() {
    this.location.back();
  }

  getButtons() {
    return this.buttons;
  }

  resetButtons() {
    this.buttons = null;
  }

  addButtonsWithDialog(buttons: any[]){
    if(this.buttons){
      this.buttons  = this.buttons.concat(buttons);
    } else {
      this.buttons = buttons;
    }
  }

  setDefaultButtons(buttons: any[]){ // method, callback
    this.buttons = buttons;
  }
}
