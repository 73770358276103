import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-geo-location-table',
  templateUrl: './geo-location-table.component.html',
  styleUrls: ['./geo-location-table.component.scss']
})
export class GeoLocationTableComponent implements OnInit {

  @Input() selectedItem;
  @Input() columnsForItem;
  
  showTable: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  separateObjectProperties(object): void {

    const columns = Object.keys(object);
    for (let name of columns) {
      if ((typeof object[name]) == 'object' && object[name] != null) {
        this.columnsForItem.push({ type: "object", name: name, title: this.mapColumnName(name) + ':' });
        this.selectedItem[name] = "";
        this.separateObjectProperties(object[name]);
      }
      else {
        this.selectedItem[name] = object != null ? object[name] : "";
        this.columnsForItem.push({ type: "primitive", name: name, title: this.mapColumnName(name) });
      }
    }
  }

  mapColumnName(columnName): string {
    switch (columnName) {
      case 'placeId':
        return "PlaceId";
      case 'placeRank':
        return 'PlaceRank';
      case 'lat':
        return 'Latitude';
      case 'longitude':
        return 'Longitude';
      case 'displayName':
        return 'DisplayName';
      case 'klasa':
        return 'Class';
      case 'importance':
        return 'Importance';
      case 'iconUrl':
        return 'IconUrl';
      case 'category':
        return 'Category';
      case 'classType':
        return 'ClassType';
      case 'addressType':
        return 'AddressType';
      case 'osmId':
        return 'OsmId';
      case 'osmType':
        return 'OsmType';
        
      default:
        var result = columnName.replace( /([A-Z])/g, " $1" );
        var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }
  }

  generateTable(selected): void {
    this.selectedItem = {};
    this.columnsForItem = [];
    this.separateObjectProperties(selected);
    this.showTable = true;
  }

}