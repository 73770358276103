import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { IGraphNode } from '../../interfaces/i-graph-node';
import { GraphNodesService } from '../api/graph-nodes.service';
import { BlGraphNodesItemsDataService } from './bl-graph-nodes-items-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlGraphNodesDataService extends BlDataLogicService implements ITableStorage<IGraphNode> {

  constructor(
    private apiService: GraphNodesService,
    private graphNodesItemsDataService: BlGraphNodesItemsDataService
  ) {
    super();
  }

  showContent: boolean = false;
  isCustom: boolean = true;
  numberOfNodesForNewItem: number = 1;

  currentObjBS: BehaviorSubject<IGraphNode> = new BehaviorSubject<IGraphNode>(null);
  currentObj: IGraphNode = null;

  resetData(): void {
    this.showContent = false;
    this.isCustom = true;
    this.numberOfNodesForNewItem = 1;
    this.currentObj = null;
  }

  checkIfAllNodesHasValue(): boolean {
    return !this.currentObj.nodes.some(function(x) {
      return !x.node1 || !x.node2 || !x.distance
    });
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<IGraphNode[]> = new BehaviorSubject<IGraphNode[]>([]);

  originalData: IGraphNode[];

  getOriginalData(): IGraphNode[] {
    return this.originalData;
  }

  setOriginalData(items: IGraphNode[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IGraphNode[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IGraphNode[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IGraphNode[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IGraphNode[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

}