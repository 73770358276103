import { Pipe, PipeTransform } from '@angular/core';
import { CompanySettingsService } from '../services/company-settings.service';
import { DecimalPipe } from '@angular/common';
import { of } from 'rxjs';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  constructor(
    private companySettings: CompanySettingsService
  ) {

  }

  transform(value: any, async: boolean = true): any {
    let decimal_points = this.companySettings.getCurrentValue().decimal_points;
    if (async) {
      return of(new DecimalPipe("en-gB").transform(value, `1.${decimal_points}-${decimal_points}`))
    } else {
      return new DecimalPipe("en-gB").transform(value, `1.${decimal_points}-${decimal_points}`);
    }
  }

}
