import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControlTypeSafe } from '../../helpers/reactive-forms-helper';
import { IOption } from '../../interfaces/i-option';

@Component({
  selector: 'app-common-autocomplete',
  templateUrl: './common-autocomplete.component.html',
  styleUrls: ['./common-autocomplete.component.css']
})
export class CommonAutocompleteComponent implements OnInit, OnChanges {

  @Input() public formControlToFill: FormControlTypeSafe<any>;
  @Input() public options: IOption[];
  @Input() public labelText: string;
  @Input() public errorCheck: (formControl: AbstractControl) => boolean;

  @Output() public onSelect: EventEmitter<IOption> = new EventEmitter(null);

  public _filteredOptions: Observable<any[]>;

  constructor() { }

  ngOnInit() {
    if(this.formControlToFill){
      this._filteredOptions = this.formControlToFill.valueChanges.pipe(startWith(''),map(value => {
        return this._filter( !value || typeof(value)==='string'? value : value.title);
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.formControlToFill && changes.formControlToFill && changes.formControlToFill.currentValue){
      this.formControlToFill = changes.formControlToFill.currentValue; 
      this._filteredOptions = this.formControlToFill.valueChanges.pipe(startWith(''),map(value => {
        return this._filter( !value || typeof(value)==='string'? value : value.title);
      }));
    }
    if(changes.options && changes.formControlToFill && changes.formControlToFill.currentValue){
      let control = changes.formControlToFill.currentValue;
      this.formControlToFill = control; 
    }
    if(changes.options && changes.options.currentValue && changes.options.currentValue.length > 0){
      let value =  this.formControlToFill.value;
      this.options = changes.options.currentValue;
      this.setValue(value);
    }
  }

  private setValue(value){
    let option = (typeof(value) === 'number')? this.options.find(x => x.id == value) : value;
    if(option){
      this.formControlToFill.setValue(option);
    } else {
      this.formControlToFill.setValue(null);
    }
  }

  private _filter(value: string): any[] {
		const filterValue = value? value.toLowerCase(): '';
		return this.options && this.options.length > 0? this.options.filter(option => option.title.toLowerCase().includes(filterValue)) : [];
  }

  setAutocompleteError(){
    if(this.formControlToFill){
      if(this.errorCheck!= null){
        if(!this.errorCheck(this.formControlToFill)){
          this.formControlToFill.setErrors({'notSelected': true})
        } else {
          this.formControlToFill.setErrors(null);
        }
      } else {
        this.formControlToFill.setErrors({'notSelected': true})
      }
       
    }
  }

  selectOption(option: IOption){
    this.onSelect.emit(option);
    this.formControlToFill.setErrors(null);
  }

  checkAutocomplete(obj?: any): string | undefined {
    if(!obj){
      return '';
    }
    let props = ['title','name','rateTitle', 'rate_name'];
    let value = obj;
    for(let prop of props){
      if(obj[prop]){
        value = obj[prop];
        break;
      }
    }
    return value;
  }

}
