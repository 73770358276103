<div class="row">
    <!-- Header Deterministic MRP -->
    <div class="col-md-12">
        <h3 class="page-header-title"> <span>{{"MRP" | translate}}</span> / {{"Deterministic MRP" | translate}}</h3>
    </div>
    <!-- End Header Deterministic MRP -->
</div>

<div class="row">
    <!-- Buttons for Add,  View -->
    <div class="col-md-12">
        <div class="header-button-box">
            <div class="header-buttons">
                <button type="button" mat-stroked-button color="accent" (click)="newMrp()" [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-create-new-problem')">
                    <mat-icon>create</mat-icon>{{"New" | translate}}
                </button>
                <button type="button" mat-stroked-button color="accent" (click)="viewMrps()" [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-open-all-problems')">
                    <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
                </button>
                <ng-container *ngIf="formService.isShowOtherTabs == true">
                    <button type="button" mat-stroked-button color="accent" (click)="saveDatas()" [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-save-problem') || !this.formService.form.get('rows').valid">
                        <mat-icon>album</mat-icon>{{"Save" | translate}}
                    </button>
                    <button type="button" mat-stroked-button color="accent" *ngIf="(nameOfCreated && dataService.patchFlag) || dataService.idForPatch" (click)="saveAsNew()" [disabled]="(!permissionsService.havePermission('deterministic-mrp', 'ability-to-save-as') || !this.formService.form.get('rows').valid)">
                        <mat-icon>album</mat-icon>{{"Save As" | translate}}
                    </button>
                    <button type="button" mat-stroked-button color="accent" (click)="openWindow()" [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-run-problem') || !this.formService.form.get('rows').valid">
                        <mat-icon>play_circle_outline</mat-icon>{{"Run" | translate}}
                    </button>
                </ng-container>
            </div>
            <div class="header-info-and-help">
              <div *ngIf="dataService.currentTitle" class="header-info">
                {{dataService.currentTitle}}
              </div>

              <button type="button" mat-stroked-button color="accent" (click)="openMarkdown()">
                <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
              </button>
            </div>
        </div>
    </div>
    <!-- End Buttons for Add,  View -->
</div>

<div class="row">
  <div class="col-md-12 iframe-wrapper" *ngIf="!(dataService.storageDataViewDialog.getValue() || nameOfCreated)">
    <div class="iframe-holder">
        <iframe
        src="assets/markdown/mrp/deterministic-mrp/help.html"
        width="100%" height="100%"  frameborder="5">
        </iframe>
    </div>
  </div>
</div>
