<ng-container *ngIf="!isSpinnerVissible; else spinner">
    <mat-form-field appearance="outline" [ngClass]="(formService.form.get(controlName).invalid)? 'requiredField': false">
        <mat-label>{{labela | translate}}</mat-label>
        <mat-select [multiple]="multiple ? true : false" (openedChange)="openedChange($event)" class="virtual-scroll"
            [formControl]="formService.form.get(controlName)" disableOptionCentering>
            <cdk-virtual-scroll-viewport class="example-viewport-select" minBufferPx="200" maxBufferPx="400" [itemSize]="50">
                <ng-container *ngIf="multiple; else singleList">
                    <mat-option [value]="defaultOptions" #allSelect (click)="formService.selectAll(allSelect.selected, controlName, dropdownData)">
                        {{"SelectAll" | translate}}
                    </mat-option>
                    <mat-option *cdkVirtualFor="let option of data" [value]="option.id" [disabled]="disabledOptions">
                            {{option.title}}
                    </mat-option>
                </ng-container>
                <ng-template #singleList>
                <mat-option *cdkVirtualFor="let option of data" [value]="option.id">
                    {{option.title}}
                </mat-option>
            </ng-template>
            </cdk-virtual-scroll-viewport>
        </mat-select>
        <mat-error *ngIf="formService.form.get(controlName).hasError('required')" class="customError">
            {{'ThisFieldIsRequired' | translate}}
        </mat-error>
    </mat-form-field>
</ng-container>
<ng-template #spinner>
    <app-input-with-spinner inputLabel="{{labela | translate}}"></app-input-with-spinner>
</ng-template>