import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../../../shared/helpers/reactive-forms-helper';
import { IBackpackProblemDropdownData, IBackpackProblemForm } from '../../../../../../interfaces/i-backpack-problem';
import { BlBackpackProblemFormService } from '../../../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../../../services/shared/bl-backpack-problem-data.service';
import { BlBackpackProblemItemsTableService } from '../../../../../../services/table/bl-backpack-problem-items-table.service';
import { EditItemsDialogBackpackProblemComponent } from '../../../edit-items-dialog-backpack-problem/edit-items-dialog-backpack-problem.component';
import { BackpackHelperFunctionsService } from '../../../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-right-table-backpack-problem',
  templateUrl: './right-table-backpack-problem.component.html',
  styleUrls: ['./right-table-backpack-problem.component.scss']
})
export class RightTableBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlBackpackProblemDataService,
    public formService: BlBackpackProblemFormService,
    public tableService: BlBackpackProblemItemsTableService,
    private requestService: BlBackpackProblemRequestsService,
    private matDialog: MatDialog,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IBackpackProblemForm> = this.formService.form;
  public dataForTable: any = [];
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  public isProblemLiterature: boolean = false;

  ngAfterViewInit(): void {
    this.dataService.originalObject.subscribe({
      next: data => {
        this.formService.isProblemLoad = !!data;
        if(data) {
          this.dataFromStorageForSend = data[0] ? data[0] : data;
        if(this.dataFromStorageForSend.problemType == 'L')
          this.isProblemLiterature = true;
        else
          this.isProblemLiterature = false;

        let podaci = data[0] ? data[0].items : data.items;
        if(podaci)
          this.dataForTable = this.backpackHelperFunctionsService.mapiranjeNizaNizova(podaci);

        this.tableService.createDynamicHeaderColumnsTable2(data[0] ? data[0].size : data.size);

        }
      }
    });
  }

  ngOnInit(): void {

    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: data => {

          if(data) {
            if(data.id) {
              let podaci: any = data.items;
              this.dataForTable = this.backpackHelperFunctionsService.mapiranjeNizaNizova(podaci);
              this.tableService.createDynamicHeaderColumnsTable2(data.size);
              this.dataFromStorageForSend = data;
            } else {
              this.dataFromStorage = data;
              this.formService.sizes = this.dataFromStorage.size;
              this.tableService.createDynamicHeaderColumnsTable2(this.formService.sizes);
              this.dataFromStorageForSend = data;

              let prepakovanNizObjekata: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(this.formService.sizes, this.dataService.itemNumberOfColumns);
              this.dataForTable = this.backpackHelperFunctionsService.mapiranjeNizaNizova(prepakovanNizObjekata);
            }

            if(this.dataFromStorageForSend && this.dataFromStorageForSend.problemType == 'L') {
              this.isProblemLiterature = true;
            } else {
              this.isProblemLiterature = false;
            }
          }

        }
      })
    );
  }

  openEditDialog() {
    this.matDialog.open(EditItemsDialogBackpackProblemComponent, {
      width: '450px',
      height: 'auto',
      minHeight: '150px',
      panelClass: 'customModal'
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.sizes = 0;
    this.formService.isProblemLoad = false;
    this.clickedButton = false;
    this.dataForTable = [];
    this.dataForSend = [];
    this.dataFromStorage = [];
    this.dataFromStorageForSend = [];
    this.isProblemLiterature = false;
  }

}
