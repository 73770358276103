import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { forkJoin } from "rxjs";
import { ObjectFunctions } from "./object-functions";

export class FormFunctions {

  constructor(){}

  /**
   * Passing over object, his sub objects and sub arrays.
   * Creating whole form form object.
   * @param data Object
   * @param form Setuping by reference
   */
  
  generateForm(data: any, form: UntypedFormGroup) {
    let keys = ObjectFunctions.getKeys(data);
    keys.forEach(x => {
      if (Array.isArray(data[x])) {
        form.addControl(x.toString(), this.generateFromArray(data[x]));
      } else if (typeof(data[x]) == 'object' && data[x] != null) {
        form.addControl(x, this.generateFormObject(data[x]));
      } else {
        form.addControl(x.toString(), new UntypedFormControl(data[x], []));
      }
    });
  }

  generateFormObject(obj) {
    let nestedKeys = ObjectFunctions.getKeys(obj);
    let group: UntypedFormGroup = new UntypedFormGroup({});
    nestedKeys.forEach(key => {
      group.addControl(key.toString(), new UntypedFormControl(obj[key], []));
    });
    return group;
  }

  generateFromArray(data) {
    let array: UntypedFormArray = new UntypedFormArray([]);
    data.forEach(el => {
      if (Array.isArray(el)) array.push(this.generateFromArray(el));
      else if (typeof(el) == 'object' && el != null) {
        array.push(this.generateFormObject(el));
      } else {
        array.push(this.generateFormObject(el));
      }
    });
    return array;
  }

  /** @description Receives array of service references and returns an Observable containing all of those services packed using ForkJoin
   * @param {any} services Array of service references
   * @description (example [this.service1, this.service2, this.service3])
  */
  static retrieveDataFromServices(services: any[]) {
    let forkVariables: any[] = [];
    services.map(service => forkVariables.push(service.getAll()));
    return forkJoin(forkVariables);
  }
}
