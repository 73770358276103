<!-- <form [formGroup]="headerForm" class="headerForm reducedForm" *ngIf="showRouteByRouteOptions">

  <span>{{"RouteByRoute" | translate}}: </span>
  <div class="controlsBlock">
    <mat-radio-group (change)="changeRouteByRouteOption($event)" formControlName="routeByRoute">
      <mat-radio-button [value]="1" >{{"Yes" | translate}}</mat-radio-button>
      <mat-radio-button [value]="0" >{{"No" | translate}}</mat-radio-button>
    </mat-radio-group>

    <ng-container *ngIf="showAddDeleteButtons && this.allRoutes.length > 0">
      <div class="addDeleteRouteBlock">
        <button class="choose-map" mat-flat-button color="accent" [disabled]="currentPolylineIndex == allRoutes[allRoutes.length - 1].coordinates.length - 1" (click)="addRoute()">{{"Add" | translate}}</button>
        <button class="choose-map" mat-flat-button color="warn" [disabled]="currentPolylineIndex == -1" (click)="removeRoute()">{{"Delete" | translate}}</button>
      </div>
    </ng-container>
  </div>

</form> -->

<div *ngIf="isMapReady" style="height: inherit" class="map"
  leaflet
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="clickOnMap($event)"
>
<!-- [leafletLayersControl]="layersControl" -->

  <div *ngFor="let marker of markerObjects">
    <div [leafletLayer]="marker"></div>
  </div>

  <div *ngIf="polylines">
    <div *ngFor="let item of polylines">
      <div [leafletLayer]="item"></div>
    </div>
  </div>

  <button
    mat-raised-button
    color="basic"
    id="showHideMapBtn"
    type="button"
    (click)="showHideMap($event)">
    {{ (isHideMap ? "ShowMap" : "HideMap") | translate }}
  </button>

</div>

<span class="current-location">
  {{ currentLocation }}
</span>
