import { SpinnerFunctions } from './../../../../shared/functions/spinner-functions';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BlDeterministicMrpDataService } from '../../services/shared/bl-deterministic-mrp-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-mrp-report',
  templateUrl: './dashboard-mrp-report.component.html',
  styleUrls: ['./dashboard-mrp-report.component.scss']
})
export class DashboardMrpReportComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlDeterministicMrpDataService
  ) { }

  private dataFromLocaleStorage: any = [];
  private subscription: Subscription = new Subscription();




  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.dataService.storageReportData.next(null);
    this.subscription.unsubscribe();
    localStorage.removeItem("object");
  }
}
