import { Component, OnInit, OnDestroy } from '@angular/core';
import { CellFormationProblemService } from '../../../../services/api/cell-formation-problem.service';
import { BlCellFormationProblemTableService } from '../../../../services/table/bl-cell-formation-problem-table.service';
import { BlCellFormationProblemDataService } from '../../../../services/shared/bl-cell-formation-problem-data.service';
import { BlCellFormationProblemRequestsService } from '../../../../services/requests/bl-cell-formation-problem-requests.service';
import { BlCellFormationProblemFormService } from '../../../../services/form/bl-cell-formation-problem-form.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { ICellFormationProblemDropDownData, ICellFormationProblemForm, ICellFormationProblemFilterForm } from '../../../../interfaces/i-cell-formation-problem';
import { PROBLEM_TYPES_FILTER } from '../../../../../../config/settings/cms/problem-types-filter';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { CellFormatingHelperFunctionsService } from '../../../../services/helper/cell-formating-helper-functions.service';

@Component({
  selector: 'app-view-dialog-dashboard-cell-formation-problem',
  templateUrl: './view-dialog-dashboard-cell-formation-problem.component.html',
  styleUrls: ['./view-dialog-dashboard-cell-formation-problem.component.scss']
})
export class ViewDialogDashboardCellFormationProblemComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: CellFormationProblemService,
    public tableService: BlCellFormationProblemTableService,
    public dataService: BlCellFormationProblemDataService,
    private requestService: BlCellFormationProblemRequestsService,
    public formService: BlCellFormationProblemFormService,
    protected matDialog: MatDialogRef<ViewDialogDashboardCellFormationProblemComponent>,
    private cellFormatingHelperFunctionsService: CellFormatingHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  public clickedButton: boolean = false;
  public isOptionSelected: boolean = false;
  public isLiteratureProblem : boolean = false;
  public form: FormGroupTypeSafe<ICellFormationProblemFilterForm> = this.formService.getFilterFormReference();

  public dropdownData: ICellFormationProblemDropDownData = {
    ProblemTypes: null,
    Methods: null
  }

  ngOnInit(): void {
    this.dropdownData.ProblemTypes = PROBLEM_TYPES_FILTER;
    this.form.getSafe(x => x.problemTypes).valueChanges.subscribe({
      next: (data) => {
        if (data != "" || data != null) {
          this.fillTable(data);
          this.isOptionSelected = true;
          if(data == "l") {
            this.isLiteratureProblem = true;
          } else if(data == "c"){
            this.isLiteratureProblem = false;
          }
        } else {
          this.isOptionSelected = false;
        }
      }
    });

    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        options: [
          {
            title: 'View',
            class: 'mat-flat-button ',
            method: (row) => {
              this.dataService.setIdForPatch(row['Id'] ? row['Id'] : 777);
              this.formService.isRunProblemForTitle = false;
              this.formService.problemTitle = "";
              this.formService.resetAlgorithms();
              // this.resetView();
              this.showDataFromService(row);
            },
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close(false);
          },
        },
      ])
    );
  }

  fillTable(value: any) {
    let type: any = value;
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType(type).subscribe({
        next: success => {
          SpinnerFunctions.hideSpinner();
          this.dataService.setStorage(success);
        }, 
        error: error => {
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }

  refreshPage() {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType("l").subscribe({
        next: (data) => {
          this.dataService.setStorage(data);
          SpinnerFunctions.hideSpinner();
        }
      })
    );
    this.formService.formFilter.getSafe(x => x.problemTypes).setValue("l", { emitEvent: false });
  }

  // resetView() {
  //   setTimeout(() => {
  //     this.dataService.setView(false);
  //   }, 1000);
  // }

  showDataFromService(row: any) {
    this.clickedButton = true;
    this.dataService.storageIsTabsView.next(true);
    this.dataService.storageDataIsShowOtherTabs.next(true);
    this.dataService.storageIsRunResults.next(false);

    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.requestService.getCFP(row.Id).subscribe({
          next: (data) => {
            if (data) {
              this.cellFormatingHelperFunctionsService.breakApartStavke(data);
              this.clickedButton = false;

              this.dataService.originalObject.next(data);
              this.formService.problemTitle = data["name"];
              this.formService.isLiteratureProblem = this.isLiteratureProblem;
            }
            SpinnerFunctions.hideSpinner();
            this.close(true);
          }
        })
      );
    }
  }

  close(state : boolean = false) {
    this.matDialog.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataService.setStorage([]);
    this.formService.resetFilter();
    this.isOptionSelected = false;
    this.isLiteratureProblem = false;
  }
}
