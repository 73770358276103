import { Component, OnInit } from '@angular/core';
import { ReportsFunctions } from '../../../../../../shared/functions/reports-functions';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-reports-tab-dashboard-distribution',
  templateUrl: './reports-tab-dashboard-distribution.component.html',
  styleUrls: ['./reports-tab-dashboard-distribution.component.scss']
})
export class ReportsTabDashboardDistributionComponent implements OnInit {

  constructor(
    public dataService: BlDistribution1ToNDataService
  ) {}

  ngOnInit(): void {}

  public regex =  /^[\-]?[\d]+(([\,]|[\.])[\d]+)*$/ ;

  printReport(): void {
    ReportsFunctions.printReport();
  }

}
