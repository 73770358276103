<div class="row drag-drop-wrapper">
    <ng-container>
      <!-- <div class="col-sm-6 input-mobile"></div> -->
      <div class="col-sm-12 input-mobile">
            <div cdkDropList cdkDropListOrientation="horizontal"      class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let data of this.formService.reducedNumbersArray" cdkDrag>{{data}}</div>
            </div>
      </div>
    </ng-container>
</div>
<div class="row">
    <div class="col-md-6" style="height: inherit;">
        <app-tab-dashboard-input-data-left-side></app-tab-dashboard-input-data-left-side>
    </div>
    <div class="col-md-6">
        <!-- <ng-container *ngIf="formService.isShowOtherTabs"> -->
            <app-tab-dashboard-input-data-right-side></app-tab-dashboard-input-data-right-side>
        <!-- </ng-container> -->
    </div>
</div>
