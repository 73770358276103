import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackpackProblemModule } from './backpack-problem/backpack-problem.module';

const routes: Routes = [
  {
    path: "backpack-problem",
    loadChildren: () => BackpackProblemModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackingRoutingModule { }
