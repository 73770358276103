import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { BlRegisterRequestsService } from '../../services/requests/bl-register-requests.service';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../shared/functions/spinner-functions';

@Component({
  selector: 'app-register-activation',
  templateUrl: './register-activation.component.html',
  styleUrls: ['./register-activation.component.scss']
})
export class RegisterActivationComponent implements OnInit, OnDestroy {

  constructor(
    public activatedRoute: ActivatedRoute,
    private alertService: AlertMessageDataService,
    private router: Router,
    private requestService: BlRegisterRequestsService
  ) { }

  public urlValue: any;
  public isUrlValid: boolean = false;
  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe({
      next: (params) => {
        const q = params['q'];
        this.urlValue = q;
        if (typeof (q) === 'undefined') {
          this.alertService.setMessage("danger", "Invalid url");
          this.isUrlValid = false;
        } else {
          this.isUrlValid = true;
        }
      }
    });
  }

  activeUser() {
    let dataToSend: any = [{
      q: this.urlValue
    }];

    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.activationUser(dataToSend).subscribe({
        next: success => {
          this.alertService.setMessage("success", "You have successfully activated your account.");
          this.router.navigateByUrl('/');
          SpinnerFunctions.hideSpinner();
        },
        error: err => {
          this.alertService.setMessage("danger", "Error.");
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.isUrlValid = false;
  }

}
