import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { CellFormationProblemService } from '../api/cell-formation-problem.service';
import { BruscoService } from '../api/brusco.service';
import { SimilarityService } from '../api/similarity.service';
import { HttpClient } from '@angular/common/http';
import { AlgorithmListService } from '../api/algorithm-list.service';
import { InstancesListService } from '../api/instances-list.service';
import { ObjectiveListService } from '../api/objective-list.service';
import { TestCfpService } from '../api/test-cfp.service';

@Injectable({
  providedIn: 'root'
})
export class BlCellFormationProblemRequestsService extends BlRequestService {

  constructor(
    private alertServices: AlertMessageDataService,
    private apiService: CellFormationProblemService,
    private bruscoService: BruscoService,
    private similarityService: SimilarityService,
    private algorithmService: AlgorithmListService,
    private instancesService: InstancesListService,
    private objectivesService: ObjectiveListService,
    private testService: TestCfpService,
    private http: HttpClient
  ) {
    super(alertServices);
  }

  saveData(dataForSend: any) {
    return this.apiService.updateAll(dataForSend);
  }

  insertData(dataForSend: any) {
    return this.apiService.create(dataForSend);
  }

  getAllByType(type: string) {
    return this.apiService.getWithParams({ problemType: type });
  }

  getCFP(id: number) {
    return this.apiService.get(id);
  }

  getCFPs() {
    return this.apiService.getAll();
  }

  runBrusco(dataForSend: any) {
    return this.bruscoService.create(dataForSend);
  }

  runSimilarity(dataForSend: any) {
    return this.similarityService.create(dataForSend);
  }

  testProblem(dataForSend: any) {
    return this.testService.create(dataForSend);
  }

  getTestProblem() {
    return this.testService.getAll();
  } //@obrisati

  getAlgorithms() {
    return this.algorithmService.getAll();
  }

  getInstances() {
    return this.instancesService.getAll();
  }

  getObjectives() {
    return this.objectivesService.getAll();
  }
}
