import { Component, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { AlertMessage } from '../../interfaces/alert-message';
import { AlertMessageDataService } from '../../services/alert-message-data.service';
import { ServiceErrors } from '../../services/api.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor(
    private alertMessageDataService : AlertMessageDataService
  ) { }
  public _messages : Observable<AlertMessage[]>;
  public _messages1;

  ngOnInit() {
    this._messages = merge(this.alertMessageDataService.getAlertMessages$(), ServiceErrors.storage.asObservable());
    this._messages1 = ServiceErrors.storage.asObservable();
  }

  public closeAlert(message: AlertMessage) {
    let index: number = this.alertMessageDataService.getAlertMessages().indexOf(message);
    this.alertMessageDataService.getAlertMessages().splice(index, 1);
  }

}
