import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlDeterministicMrpDataService } from '../../../../services/shared/bl-deterministic-mrp-data.service';
import { Subscription } from 'rxjs';
import { ReportsFunctions } from '../../../../../../shared/functions/reports-functions';

@Component({
  selector: 'app-tabs-dashboard-mrp-report',
  templateUrl: './tabs-dashboard-mrp-report.component.html',
  styleUrls: ['./tabs-dashboard-mrp-report.component.scss']
})
export class TabsDashboardMrpReportComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlDeterministicMrpDataService
  ) { }

  private subscription: Subscription = new Subscription();
  public isShowTables: boolean = false;
  public dataFromService: any = [];

  public canChangeTab: boolean = true;
  public selectedTab: number = 0;

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.storageReportData.subscribe({
        next: data => {
          if(data) {
            this.dataFromService = data;
            this.isShowTables = true;
          }
        }
      })
    );
  }

  
  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab == 0) {

    }
  }

    /**Print functions */
    printReport() {
      ReportsFunctions.printReport();
    }
    /**EndPrint functions */

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.isShowTables = false;
    this.dataFromService = [];
    localStorage.removeItem("object");
  }

}
