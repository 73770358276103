import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'arrayColumn'
})
export class ArrayColumnPipe implements PipeTransform {

  transform(column: ITableColumn, row: any): boolean {
    return Array.isArray(row[column.index]);
  }

}
