import { Injectable } from '@angular/core';
import { IAuthResponse } from '../interfaces/i-auth-response';
import { JwtHelperService } from "@auth0/angular-jwt";
import { IAuthToken } from '../interfaces/i-auth-token';
import { LocalStorageKeys } from '../../shared/consts/local-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  private jwtService = new JwtHelperService();

  saveToken(data: IAuthResponse){
    localStorage.setItem(LocalStorageKeys.token, JSON.stringify(data));
  }

  hasToken(){
    return localStorage.getItem(LocalStorageKeys.token) != null && localStorage.getItem(LocalStorageKeys.token) != "";
  }

  getToken(){
    let token = null;
    try {
      token = JSON.parse(localStorage.getItem(LocalStorageKeys.token));
      let data = token as IAuthResponse;
      return data.token;

    } catch (e) {
        return token;
    }
  }

  deleteToken(){
    localStorage.removeItem(LocalStorageKeys.token);
  }

  getTokenData(): IAuthToken {
    let token = null;
    try {
      token = JSON.parse(localStorage.getItem(LocalStorageKeys.token));
      let data = token as IAuthResponse;
      return this.jwtService.decodeToken(data.token) as IAuthToken;

    } catch (e) {
        return token;
    }
  }
}
