import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { AlertMessageDataService } from "./alert-message-data.service";

@Injectable()
export class AuthResponseInterceptor implements HttpInterceptor {

    isRefresed : boolean = false;

    constructor(
        private injector: Injector,
        private router: Router,
        private alertService : AlertMessageDataService
    )
    { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request);
        
    }

    handleError(err: any, next: HttpHandler, request) : Observable<HttpEvent<any>>{
        if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
                // JWT token might be expired:
                // try to get a new one using refresh token

                //  ===[2018.01.05 FIX - BOOK UPDATE]===
                // cfr. https://github.com/PacktPublishing/ASP.NET-Core-2-and-Angular-5/issues/8
                // and  https://github.com/PacktPublishing/ASP.NET-Core-2-and-Angular-5/issues/15
                // store current request into a local variable
                var previousRequest = Object.create(request);

                if (
                    request.url.includes("api/token/modify") ||
                    request.url.includes("api/token/auth") || request.url.includes("Hotels") || request.url.includes("api/application")
                ) {
                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page
    
                    // if ( request.url.includes("api/token/modify")) {
                        this.alertService.setMessage('danger','You are not authorized. Check your email and password.');
                    // }
    
                    return throwError(err);
                }
                // thanks to @mattjones61 for the following code
                return of(null);
            }
        }
        return throwError(err);
    }

}
