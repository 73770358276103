import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByCustom'
})
export class GroupByCustomPipe implements PipeTransform {

  transform(collection: any, property: string): any {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if(!collection) {
        return null;
    }

    const groupedCollection = collection.reduce((previous, current)=> {
        if(!previous[current[property]]) {
            previous[current[property]] = [current];
        } else {
            previous[current[property]].push(current);
        }

        return previous;
    }, {});
    let obj = {}; 
    // this will return an array of objects, each object containing a group of objects
    Object.keys(groupedCollection).forEach(key => { 
      let prop = key;
      if(prop == "null"){
        prop = "empty";
      }
      obj[prop] = groupedCollection[key]; 
    });
    return obj;
  }

}
