import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { LeafletMapComponent } from '../../../../../../shared/components/leaflet-map/leaflet-map.component';
import { GeoPositioningService } from '../../../../../../shared/components/osm-view/geo-positioning.service';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';
import { IPosition } from '../../../../../../shared/interfaces/i-position';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IDistribution1ToNForm } from '../../../../interfaces/i-distribution-1-to-n';
import { IDistribution1ToNMapFilteringForm, IDistribution1ToNMapFilteringRequest } from '../../../../interfaces/i-distribution-1-to-n-map-filtering';
import { BlDistribution1ToNFormService } from '../../../../services/forms/bl-distribution-1-to-n-form.service';
import { BlDistribution1ToNMapFilteringFormService } from '../../../../services/forms/bl-distribution-1-to-n-map-filtering-form.service';
import { BlDistribution1ToNMapFilteringRequestsService } from '../../../../services/requests/bl-distribution-1-to-n-map-filtering-requests.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';
import { LatLong } from '../../../../../../shared/consts/lat-long';
import { BlDistribution1ToNSourceDestinationsTabFormService } from '../../../../services/forms/bl-distribution-1-to-n-source-destinations-tab-form.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-source-destination-form-dashboard-distribution',
  templateUrl: './source-destination-form-dashboard-distribution.component.html',
  styleUrls: ['./source-destination-form-dashboard-distribution.component.scss']
})
export class SourceDestinationFormDashboardDistributionComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    private formService: BlDistribution1ToNFormService,
    private formServiceMapFiltering: BlDistribution1ToNMapFilteringFormService,
    private requestsServiceMapFiltering: BlDistribution1ToNMapFilteringRequestsService,
    public dataService: BlDistribution1ToNDataService,
    private alertService: AlertMessageDataService,
    public geoPositioningService: GeoPositioningService,
    protected matDialog: MatDialog,
    private sourceDestinationTabFormService: BlDistribution1ToNSourceDestinationsTabFormService,
    private cdr: ChangeDetectorRef
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNForm>;
  filteringForm: FormGroupTypeSafe<IDistribution1ToNMapFilteringForm> = this.formServiceMapFiltering.getFormReference();

  filteringDataFromBack;

  markers: IPosition[] = [{
    latitude: LatLong.latitude,
    longitude: LatLong.longitude
  }];

  zoomIn: IPosition;
  usersCurrentLocation: IPosition = <IPosition>{};
  isClickedSave: boolean = false;
  isSource: boolean = false;
  isDestination: boolean = false;
  private index: number;
  zoomAfterUpdatingMarkers = true;
  subscription: Subscription = new Subscription();
  currentType: string = "";
  obj: any;
  zoomByFitBounds: boolean = false;

  @ViewChild(LeafletMapComponent) leafletMapComponent:LeafletMapComponent;

  ngOnInit(): void {
    this.formService.reset();
    this.form = this.formService.getFormReference();

    this.index = this.data.id;

    let obj;
    if (this.index == null) {
      this.isSource = true;
      obj = this.dataService.source;
    }
    else {
      if(this.dataService.problemType.shortcut != 'ptp' || this.index == 0)
        this.isDestination = true;

      obj = this.dataService.allDestinations[this.index];
    }

    this.markers = [{
      latitude: obj[0] == null ? LatLong.latitude : obj[0],
      longitude: obj[1] == null ? LatLong.longitude : obj[1]
    }]

    this.obj = obj;

    if (obj[0] == null && obj[1] == null) {
      this.zoomByFitBounds = true

      if(this.isDestination && this.dataService.destinationsQuantity && this.dataService.destinationsQuantity.length)
        this.formService.setQuantity(this.dataService.destinationsQuantity[this.data.id]);
    }
    else {
      this.formService.fillForm(this.index, this.data.id != null);
      this.markers = [{
        latitude: obj[0] != null ? obj[0] : LatLong.latitude,
        longitude: obj[1] != null ? obj[1] : LatLong.longitude,
      }]
    }

    this.trackMapFilterTypeChange();
    ValidationFunctions.runValidation(this.filteringForm);
  }

  trackMapFilterTypeChange(): void {
    this.subscription.add(this.filteringForm.getSafe(x => x.type).valueChanges.subscribe({
      next: value => {
        this.currentType = value;
        if(value != 'streets')
          this.filteringForm.getSafe(x => x.homeNumber).setValue("");

        this.formServiceMapFiltering.refreshValidators();
      }
    }));
  }

  getUsersCurrentLocation(zoomOnly: boolean = false): void {
    SpinnerFunctions.showSpinner();

    this.geoPositioningService.getLocation().subscribe({
      next: data => {
        SpinnerFunctions.hideSpinner();

        const latitude = parseFloat(data.coords.latitude);
        const longitude = parseFloat(data.coords.longitude);

        if (!zoomOnly) {
          this.formService.setLatitude(latitude);
          this.formService.setLongitude(longitude);
          this.markers[0] = {
            longitude: longitude,
            latitude: latitude
          }
        }

        this.zoomIn = {
          longitude: longitude,
          latitude: latitude
        }
      },
      error: error => {
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage('danger', error);

        this.formService.setLatitude(LatLong.latitude);
        this.formService.setLongitude(LatLong.longitude);
        this.markers[0] = {
          longitude: LatLong.longitude,
          latitude: LatLong.latitude
        }
      }
    })
  }

  save(): void {
    this.isClickedSave = true;
    if (this.data.id != null) {

      this.dataService.allDestinations[this.data.id] = [this.form.getSafe(x => x.latitude).value, this.form.getSafe(x => x.longitude).value]
      this.sourceDestinationTabFormService.form.getSafe(x => x.destinations)["controls"][this.data.id].get("longitude").setValue(this.form.getSafe(x => x.longitude).value);
      this.sourceDestinationTabFormService.form.getSafe(x => x.destinations)["controls"][this.data.id].get("latitude").setValue(this.form.getSafe(x => x.latitude).value);

      if(this.hasQuantity()) {
        this.sourceDestinationTabFormService.form.getSafe(x => x.destinations)["controls"][this.data.id].get("quantity").setValue(this.form.getSafe(x => x.quantity).value);

        this.dataService.destinationsQuantity[this.data.id] = this.form.getSafe(x => x. quantity).value == "" ? null : this.form.getSafe(x => x.quantity).value;
      }

    }
    else {
      this.dataService.source = [this.form.getSafe(x => x.latitude).value, this.form.getSafe(x => x.longitude).value];

      this.sourceDestinationTabFormService.form.getSafe(x => x.source).get("longitude").setValue(this.form.getSafe(x => x.longitude).value);
      this.sourceDestinationTabFormService.form.getSafe(x => x.source).get("latitude").setValue(this.form.getSafe(x => x.latitude).value);
      if(this.hasQuantity()) {
        this.sourceDestinationTabFormService.form.getSafe(x => x.source).get("quantity").setValue(this.form.getSafe(x => x.quantity).value);
      }

    }

    if(typeof this.filteringDataFromBack != "undefined") {
      const selectedDestinations = this.filteringDataFromBack.filter((value, index) => this.filteringForm.getSafe(x => x.searchedDestinations).value[index]);
      for(let item of selectedDestinations) {
        this.dataService.allDestinations.push([item.coordinates[0], item.coordinates[1]]);
      }
      this.sourceDestinationTabFormService.createDestinationsControls()
    }


    this.formService.close();
  }

  hasQuantity(): boolean {
    return this.isDestination && this.dataService.getGroupPropertiesId() == 2;
  }

  close(): void {
    this.resetData();
    this.matDialog.closeAll();
  }

  ngOnDestroy(): void {
    this.resetData();
  }

  resetData(): void {
    this.subscription.unsubscribe();
    this.formServiceMapFiltering.reset();
  }

  showMyLocation(): void {
    this.getUsersCurrentLocation();
  }

  filtering(): void {
    SpinnerFunctions.showSpinner();

    const centerFromMap = this.leafletMapComponent.map.getBounds().getCenter();
    const dataToSend = this.formServiceMapFiltering.prepareDataToSend(centerFromMap);
    this.markers.splice(1);

    this.subscription.add(this.requestsServiceMapFiltering.filtering(dataToSend).subscribe({
      next: data => {
        SpinnerFunctions.hideSpinner();
        this.formServiceMapFiltering.createSearchedDestinationsControls(cloneDeep(data));
        this.filteringDataFromBack = data;
        const obj = data.map(x => {
          return {
            latitude: x.coordinates[0],
            longitude: x.coordinates[1],
            isClickable: true,
            forFilter: true,
            title: x.title
          }
        });
        this.zoomAfterUpdatingMarkers = false;
        this.markers = this.markers.concat(obj);
      },
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    }));
  }

  private confirmDialog = {
    component: ConfirmDialogComponent,
    specification: {
      width: "370px",
      height: "auto",
      minHeight: '150px',
      panelClass: 'custom-dialog',
      data: { message: "DoYouWantToSelectThisLocation" }
    }
  };

  markerObjectClicked(data): void {
    this.subscription.add(this.matDialog.open(this.confirmDialog.component, this.confirmDialog.specification).afterClosed().subscribe({
      next: value => {
        if (value) {
          this.formService.setLongitudeAndLatitude(data.longitude, data.latitude, false);
          this.save();
        }
      }
    }));
  }

  selectLink(data): void {
    this.zoomIn = {
      latitude: data.coordinates[0],
      longitude: data.coordinates[1]
    }
    this.formService.setLongitudeAndLatitude(data.coordinates[1], data.coordinates[0], false);
  }

  setLongitudeAndLatitudeForMarkerAndZoom(): void {
    if(this.obj[0] == null && this.obj[1] == null) {
      const centerFromMap = this.leafletMapComponent.map.getBounds().getCenter();

      const latitude = centerFromMap.lat;
      const longitude = centerFromMap.lng;

      this.formService.setLatitude(latitude);
      this.formService.setLongitude(longitude);
      this.markers[0] = {
        longitude: longitude,
        latitude: latitude
      }
    }

  }

  ngAfterViewInit(): void {
    this.setLongitudeAndLatitudeForMarkerAndZoom();
    this.cdr.detectChanges()
  }

}
