import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { GraphNodesService } from '../api/graph-nodes.service';
import { BlGraphNodesDataService } from '../shared/bl-graph-nodes-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlGraphNodesRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: GraphNodesService,
    private dataService: BlGraphNodesDataService
  ) {
    super(alertService);
  }

  create(): Observable<any> {
    const dataToSend = this.prepareDataToSend();
    return this.apiService.create(dataToSend);
  }

  update(): Observable<any> {
    const dataToSend = this.prepareDataToSend();
    return this.apiService.update(dataToSend);
  }

  prepareDataToSend() {
    let currentObjCopy = cloneDeep(this.dataService.currentObj);
    currentObjCopy.nodes = currentObjCopy.nodes.map(x => {
      return {
        id: x.id,
        node1: x.node1,
        node2: x.node2,
        distance: x.distance
      }
    });
    return currentObjCopy;
  }

}