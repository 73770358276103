import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ILanguage } from '../interfaces/i-language';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../../config/api';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends ApiService<ILanguage> {

  constructor(http: HttpClient) {
    super(apiPaths.lanugages, http);
   }
}
