import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiGetAllResponse } from '../../../../shared/interfaces/api-get-all--response';
import { ApiService } from '../../../../shared/services/api.service';
import { IGraphNode, IGraphNodeItem } from '../../interfaces/i-graph-node';

@Injectable({
  providedIn: 'root'
})
export class GraphNodesService extends ApiService<IGraphNode>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.nodes, http);
  }
  
  selectedProblemType: string = "";

  create(obj): Observable<any> {
    return this.http.post(`${ this.baseUrl + apiPaths.routing.nodes}`, obj).pipe(
      catchError(this.handleErrors)
    );
  }

  update(obj): Observable<any> {
    return this.http.patch(`${ this.baseUrl + apiPaths.routing.nodes}`, obj).pipe(
      catchError(this.handleErrors)
    );
  }

  getAll() : Observable<IGraphNode[]>{
    return this.getAllCustomLiteratureHelper();
  }

  getWithTotalCount() : Observable<ApiGetAllResponse<IGraphNode>> {
    return this.getAllCustomLiteratureHelper();
  }

  getWithAllData() : Observable<IGraphNode[]> {
    return this.getAllCustomLiteratureHelper();
  }

  getWithData(id) : Observable<IGraphNode>{
    return this.getAllCustomLiteratureHelper();
  }

  getWithParams(params: any){
    return this.getAllCustomLiteratureHelper();
  }

  getAllCustomLiteratureHelper() {
    return this.http.get<IGraphNode[]>(`${this.baseUrl + this.url + "?problemType=" + this.selectedProblemType}`).pipe(
      map((items: any) => {
        return items.hasOwnProperty('data') ? items['data'] : items;
      }),
      catchError(this.handleErrors)
    );
  }

  get(id) : Observable<IGraphNode>{
    return this.isServer ? this.http.get<IGraphNode>(`${ this.baseUrl + this.url + "/"+ id }`).pipe(
      map((item :any)=> {
        let counter = 0;
        for(let i of item.nodes) {
          i.counter = ++counter;
        }
        return  item;
      })
    ) :
    this.http.get<IGraphNode>(`${ this.baseUrl + this.url }`)
      .pipe(
        map((items : any) => {
          let finded = items.filter(item => item.id == id)[0];
          let counter = 0;
          for(let i of finded.nodes) {
            i.counter = ++counter;
          }
          return finded;
        }),
        catchError(this.handleErrors)
      );
  }

}