import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { GeoLocationForwardService } from '../api/geo-location-forward.service';
import { IGeoLocationForwardRequest } from '../../interfaces/i-geo-location-forward';
@Injectable({
  providedIn: 'root'
})
export class BlGeoLocationForwardRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: GeoLocationForwardService
  ) {
    super(alertService);
  }

  search(objectForSearch: IGeoLocationForwardRequest): Observable<any> {
    return this.apiService.search(objectForSearch);
  }

}