<div mat-dialog-title>
  <div class="row">
      <div class="col-sm-12 closeIconBox">
          <mat-icon class="closeIconBold" (click)="close(true)">close</mat-icon>
      </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12" style="padding: 0 30px;">
      <iframe
        [src]="data.path"
        width="100%" height="500px"  frameborder="5">
        </iframe>
    </div>
  </div>
</div>
