import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LocalStorageKeys } from '../../shared/enums/local-storage-keys';
import { LocalStorageFunctions } from '../../shared/functions/local-storage-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../shared/helpers/reactive-forms-helper';
import { ILanguage, ILanguageForm } from '../../shared/interfaces/i-language';
import { AlertMessageDataService } from '../../shared/services/alert-message-data.service';
import { LanguageService } from '../../shared/services/language.service';
import { config } from '../../config/global';


@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<LanguageModalComponent>,
    private languageService: LanguageService,
    private alertService: AlertMessageDataService
  ) { }

  private subscription: Subscription = new Subscription();

  public languages: ILanguage[] = [];
  public selected: string = "";
  public baseUrl: string = config.baseApiUrl.AWS_IMG;

  public isClickedSave: boolean = false;
  public isSpinnerVissible: boolean = false;

  public form: FormGroupTypeSafe<ILanguageForm> = this.formBuilder.group<ILanguageForm>({
    language: this.formBuilder.control("")
  });

  ngOnInit() {
    this.isSpinnerVissible = true;
    this.subscription.add(
      this.languageService.getAll().subscribe({
        next: (data) => {
          this.languages = data;
          this.isSpinnerVissible = false;
        }
      })
    );


    // let lang = LocalStorageFunctions.get(LocalStorageKeys.lang);
    // this.form.getSafe(x => x.language).setValue(lang ? lang : "en");
    this.fillForm();
    this.translateContent();

    // this.form.getSafe(x => x.language).valueChanges.subscribe((val: string) => {
    //   if (val != null && val != undefined) {
    //     if (val.toLowerCase() == "en") {
    //       this.changeLanguage("en");
    //     } else if (val.toLowerCase() == "sr") {
    //       this.changeLanguage("sr");
    //     }
    //   }
    // });
  }

  changeLanguage(lang) {
    this.translate.use(lang);
    LocalStorageFunctions.set({ key: LocalStorageKeys.lang, value: lang });
  }

  translateContent() {
    if (this.form.get("language").value.toLowerCase() == "en") {
      this.changeLanguage("en");
    } else if (this.form.get("language").value.toLowerCase() == "sr") {
      this.changeLanguage("sr");
    } else if (this.form.get("language").value.toLowerCase() == "sr-Cyrl") {
      this.changeLanguage("sr-Cyrl");
    }
  }

  fillForm() {
    // this.subscription.add(
      this.languageService.get(1).subscribe({
        next: (data) => {
            this.selected = data["language"];
            this.changeLanguage(data["language"]);
            let lang = LocalStorageFunctions.get(LocalStorageKeys.lang);
            this.form.getSafe(x => x.language).setValue(lang ? lang : data["language"]);
          }
        })
    // );
  }

  save() {
    let selectedlanguages = this.form.getSafe(x => x.language).value;
    let oneLanguage = this.languages.filter(x => x["language"].indexOf(selectedlanguages) != -1);
    let id = oneLanguage[0]["id"];
    let dataToSend: any = {
      language: this.form.getSafe(x => x.language).value
    };
    if (id != null || id != undefined) {
      this.isClickedSave = true;
      this.subscription.add(
        this.languageService.update(id, dataToSend).subscribe({
          next: success => {
            this.changeLanguage(dataToSend["language"]);
            this.alertService.setMessage("success", "Succesfuly Change Language.");
            this.close();
          }, 
          error: error => {
            this.isClickedSave = false;
            this.alertService.setMessage("danger", "Error while try changing language.");
          }
        })
      );
    }

  }

  close() {
    this.dialogRef.close(this.form.value);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
