import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';
import { ICountryRouting } from '../../interfaces/i-country-routing';

@Injectable({
  providedIn: 'root'
})
export class RoutingCountriesService extends ApiService<ICountryRouting>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.countryRouting, http);
  }

  checkCountry(id: number): Observable<any> {
    return this.http.patch(this.baseUrl + this.url + "/" + id, {}).pipe(
      catchError(this.handleErrors)
    );
  }

}