import { Component, OnInit } from '@angular/core';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-main-map-dashboard-distribution',
  templateUrl: './main-map-dashboard-distribution.component.html',
  styleUrls: ['./main-map-dashboard-distribution.component.scss']
})
export class MainMapDashboardDistributionComponent implements OnInit {

  constructor(
    public dataService: BlDistribution1ToNDataService,
    public formService: BlLeftSidebarDistribution1ToNFormService,
  ) {}

  ngOnInit(): void {

  }

}
