import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MarkdownComponent } from '../../../../../../shared/components/markdown/markdown.component';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { BlGraphNodesRequestsService } from '../../../../services/requests/bl-graph-nodes-requests.service';
import { BlGraphNodesDataService } from '../../../../services/shared/bl-graph-nodes-data.service';
import { NewGraphNodesComponent } from '../new-graph-nodes/new-graph-nodes.component';
import { SaveAsNewGraphNodesComponent } from '../save-as-new-graph-nodes/save-as-new-graph-nodes.component';
import { ViewDialogGraphNodesComponent } from '../view-dialog-graph-nodes/view-dialog-graph-nodes.component';

@Component({
  selector: 'app-header-graph-nodes',
  templateUrl: './header-graph-nodes.component.html',
  styleUrls: ['./header-graph-nodes.component.scss']
})
export class HeaderGraphNodesComponent implements OnInit {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlGraphNodesDataService,
    public requestsService: BlGraphNodesRequestsService,
    private alertService: AlertMessageDataService,
    private translateService: TranslateService,
    public permissionsService: PermissionsService,
    private sanitizer: DomSanitizer
  ) { }

  private subscription: Subscription = new Subscription();
  public safeData: any;
  public unsafeHTML: string = "assets/markdown/routing/graph-nodes/help1.html";

  ngOnInit(): void {
    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  save(): void {
    if (this.isValidData()) {
      if(this.dataService.currentObj.id != null) {
        this.updateGraphNode();
      }
      else {
        this.createNewGraphNode();
      }
    }
  }

  saveAsNew(): void {
    if (this.isValidData()) {
      this.matDialog.open(SaveAsNewGraphNodesComponent, {
        width: "400px",
        height: "auto",
        minHeight: "100px",
        panelClass: "customModal"
      }).afterClosed().subscribe({
        next: val => {
          if (val) {
            this.dataService.currentObj.name = val;
            this.createNewGraphNode();
          }
        }
      });
    }
  }

  viewGraphNodes(): void {
    const dialogRef = this.matDialog.open(ViewDialogGraphNodesComponent, {
      width: '750px',
      height: "auto",
      minHeight: "200px",
      panelClass: "customModal"
    });
  }

  createNewGraphNode(): void {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestsService.create().subscribe({
        next: success => SpinnerFunctions.hideSpinner(),
        error: error => SpinnerFunctions.hideSpinner()
      })
    );
  }

  updateGraphNode(): void {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestsService.update().subscribe({
        next: success => SpinnerFunctions.hideSpinner(),
        error: error => SpinnerFunctions.hideSpinner()
      })
    );
  }

  openModalForNew(): void {
    const dialogRef = this.matDialog.open(NewGraphNodesComponent, {
      width: '80%',
      maxWidth: '600px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal"
    });
  }

  openMarkdown() {
    const dialogRef = this.matDialog.open(MarkdownComponent, {
      width: '90%',
      maxWidth: '1100px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal",
      data: {
        path: this.safeData
      }
    });
  }

  isValidData(): boolean {
    if(!this.dataService.checkIfAllNodesHasValue()) {
      this.setErrorAlertMessage();
    }
    return this.dataService.checkIfAllNodesHasValue();
  }

  setErrorAlertMessage(): void {
    let errMsg = "AllFieldsAreRequired";
    this.alertService.setMessage("danger", this.translateService.instant(errMsg));
  }

}
