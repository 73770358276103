import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiGetAllResponse } from '../../../../shared/interfaces/api-get-all--response';
import { ApiService } from '../../../../shared/services/api.service';
import { IDistribution1ToN, IDistribution1ToNRun } from '../../interfaces/i-distribution-1-to-n';
import { GROUP_PROPERTIES } from '../../../routing-common/constants/group-properties';
import { routingProblems } from '../../../routing-common/constants/routing-problems';

@Injectable({
  providedIn: 'root'
})
export class Distribution1ToNService extends ApiService<IDistribution1ToN>{

  constructor(
    http: HttpClient
  ) {
    super(apiPaths.routing.distribution1ToNGet, http);
  }

  selectedProblemType: string = "";

  calculate(data: IDistribution1ToNRun): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl + apiPaths.routing.calculateDistribution1n}`, data).pipe(
      map((items: any) => {
        let coordinates = [];
        let data = items.hasOwnProperty('data') ? items['data'] : items;
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

  updateWithoutParams(obj) {
    return this.http.patch(`${ this.baseUrl + apiPaths.routing.distribution1ToNSave}`, obj).pipe(
      catchError(this.handleErrors)
    );
  }

  insertWithoutParams(obj) {
    return this.http.post(`${ this.baseUrl + apiPaths.routing.distribution1ToNSave + "?idGroupProperties=" + this.getGroupPropertiesId()}`, obj).pipe(
      catchError(this.handleErrors)
    );
  }

  admin(): Observable<any> {
    let data = null;
    return this.http.patch<any>(`${this.baseUrl + apiPaths.routing.adminDistribution1n}`, data).pipe(
      map((items: any) => {
        let data = items.hasOwnProperty('data') ? items['data'] : items;
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

  getAll() : Observable<IDistribution1ToN[]>{
    return this.getAllCustomLiteratureHelper();
  }

  getWithTotalCount() : Observable<ApiGetAllResponse<IDistribution1ToN>> {
    return this.getAllCustomLiteratureHelper();
  }

  getWithAllData() : Observable<IDistribution1ToN[]> {
    return this.getAllCustomLiteratureHelper();
  }

  getWithData(id) : Observable<IDistribution1ToN>{
    return this.getAllCustomLiteratureHelper();
  }

  getWithParams(params: any){
    return this.getAllCustomLiteratureHelper();
  }

  getAllCustomLiteratureHelper() {
    return this.http.get<IDistribution1ToN[]>(`${this.baseUrl + this.url + "?problemType=" + this.selectedProblemType + "&idGroupProperties=" + this.getGroupPropertiesId()}`).pipe(
      map((items: any) => {
        if(this.getProblemType() != "ptp" && this.getProblemType() != "sssp")
          items = items.filter(x => x.typeOfMap == "map")
        return items.hasOwnProperty('data') ? items['data'] : items;
      }),
      catchError(this.handleErrors)
    );
  }

  getProblemType(): string {
    const currentPage = window.location.pathname;
    const myParam = currentPage.split("/")[2];
    return routingProblems.find(x => x.shortcut === myParam).shortcut;
  }

  getGroupPropertiesId(): number {
    return GROUP_PROPERTIES.find(x => x.problems.find(z => z == this.getProblemType())).id;
  }

}
