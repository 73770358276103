import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MIME_TYPES } from '../../consts/mime-types';
import { IMimeType } from '../../interfaces/i-mime-type';
import { AlertMessageDataService } from '../../services/alert-message-data.service';

@Component({
  selector: 'app-common-file-upload',
  templateUrl: './common-file-upload.component.html',
  styleUrls: ['./common-file-upload.component.scss']
})
export class CommonFileUploadComponent {

  @Input() allowedFileTypes: string[];
  @Input() maxSize: number = 2097152;
  @Output() onFileChanged = new EventEmitter<any>();

  constructor(
    private alertService: AlertMessageDataService,
    private translateService: TranslateService
  ) { }

  private mimeTypes: IMimeType[] = MIME_TYPES;
  public fileNotChosen:string = this.translateService.instant("File not chosen.")
  public infoMsg: string = this.fileNotChosen;

  onFileSelected(e) {
    let file = e.target.files;

    if(!file || file.length === 0){
      this.alertService.setMessage("danger", this.fileNotChosen);
      this.infoMsg = this.fileNotChosen;
      return;
    }

    if(file.length > 1){
      this.alertService.setMessage("danger", this.translateService.instant("OnlyOneFileCanBeUploaded"));
      this.infoMsg = this.fileNotChosen;
      return;
    }

    if(file[0].size > this.maxSize){
      const maxSizeMB = (this.maxSize / 1048576).toFixed(2) + " MB"
      this.alertService.setMessage("danger", this.translateService.instant("FileSizeTooLarge") + " " + maxSizeMB);
      this.infoMsg = this.fileNotChosen;
      return;
    }

    if(!this.mimeTypes.filter(x => this.allowedFileTypes.includes(x.name)).map(x => x.mimeType).includes(file[0].type)){
      this.alertService.setMessage(
        "danger", 
        this.translateService.instant("FileTypeNotAllowed") + " " +
        this.mimeTypes.filter(x => this.allowedFileTypes.includes(x.name)).map(x => x.name).join(", ")
      );
      this.infoMsg = this.fileNotChosen;
      return;
    }

    this.infoMsg = file[0].name;
    this.onFileChanged.emit(file);
  }

}
