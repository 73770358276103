import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ICapacityForm } from '../../interfaces/i-capacity';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BlCapacityFormService implements IFormService<ICapacityForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  form: FormGroupTypeSafe<ICapacityForm> = this.init();

  public isFromViewDialog: boolean = false;
  public rowData: any = null;
  public numberRows: number = 0;
  public numberCols: number = 0;
  public typePlanning: string = "";
  public colSpan: number = 0;

  init(): FormGroupTypeSafe<ICapacityForm> {
    let group: FormGroupTypeSafe<ICapacityForm> = this.formBuilder.group<ICapacityForm>({
      rowsTab5: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;
  }

  getFormReference(): FormGroupTypeSafe<ICapacityForm> {
    return this.form;
  }

  setRows(rowData: any) {
    this.form.removeControl("rowsTab5");
    this.form.addControl("rowsTab5", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.rows;
      let row5: any = rowData.rowsTab5;
      let numberRows: any = rowData.noProductPartItems;
      let numberCols: any = rowData.noPlanningPerod;

      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rowsTab5) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl({ value: row[i].itemId, disabled: true }),
          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.rowsTab5) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row5[i]["c" + j]));
        }
      }
      (this.form.get("rowsTab5") as UntypedFormArray).controls = [...(this.form.get("rowsTab5") as UntypedFormArray).controls];
    }
  }

  createTableWithDynamicRows(rowData: any, numberRows: number) {
    this.removeTableDYnamicRows();
    if (numberRows) {
      let row: any = rowData.rows;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rowsTab5) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl({ value: row[i].itemId, disabled: true }),
          })
        );
        for (let j = 0; j < rowData.noPlanningPerod; j++) {
          ((this.form.getSafe(x => x.rowsTab5) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(""));
        }
      }
      (this.form.get("rowsTab5") as UntypedFormArray).controls = [...(this.form.get("rowsTab5") as UntypedFormArray).controls];
    }
  }

  removeTableDYnamicRows() {
    this.form.removeControl("rowsTab5");
    this.form.addControl("rowsTab5", this.formBuilder.array([]));
  }

  reset(): void {
    this.form = this.init();
  }
}
