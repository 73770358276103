import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { ReportsFunctions } from '../../../../../../../../shared/functions/reports-functions';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { IPfspDropdownData } from '../../../../../../interfaces/i-pfsp';
import { BlPfspTestFormService } from '../../../../../../services/form/bl-pfsp-test-form.service';
import { BlPfspRequestsService } from '../../../../../../services/requests/bl-pfsp-requests.service';
import { BlPfspDataService } from '../../../../../../services/shared/bl-pfsp-data.service';
import { BlPfspResultTableService } from '../../../../../../services/table/bl-pfsp-result-table.service';

@Component({
  selector: 'app-tab-dashboard-results',
  templateUrl: './tab-dashboard-results.component.html',
  styleUrls: ['./tab-dashboard-results.component.scss']
})
export class TabDashboardResultsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public formService: BlPfspTestFormService,
    private dataService: BlPfspDataService,
    private requestService: BlPfspRequestsService,
    public tableService: BlPfspResultTableService,
    private alertService: AlertMessageDataService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  public dropdownData: IPfspDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    TargetFunctions: null,
    Methods: null,
    Pfsps: null
  }

  private subscription: Subscription = new Subscription();

  public dataForTable: any = null;
  public dataFromStorage: any = null;
  public clickedButton: boolean = false;
  public parametri: any = [];

  ngAfterViewInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: data => {
          this.dataFromStorage = data;
        }
      })
    );
  }

  ngOnInit(): void {
    this.getAlgorithms();
    this.getAllPfsps();
    this.getObjectives();
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestService.getAlgorithms().subscribe({
        next: data => {
          if (data) {
            this.dropdownData.Algorithms = data;
          }
        }
      })
    );
  }

  getObjectives() {
    this.subscription.add(
      this.requestService.getObjectives().subscribe({
        next: data => {
          if (data) {
            this.dropdownData.TargetFunctions = data;
          }
        }
      })
    );
  }

  getAllPfsps() {
    this.subscription.add(
      this.requestService.getAllPfsps().subscribe({
        next: data => {
          if (data) {
            this.dropdownData.Pfsps = data;
          }
        }
      })
    );
  }

  runTest() {
    this.clickedButton = true;
    this.formService.isShowReportTable = false;

    let formValue: any = this.formService.form.value;
    let objForSend: any = {
      id: this.dataFromStorage?this.dataFromStorage.id: null,
      algorithm: formValue.algorithm,
      targetFunction: formValue.targetFunction,
      problem: formValue.problem,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParamsForTestRun(this.formService.form)
    };

    if (this.clickedButton) {
      SpinnerFunctions.showSpinner();
      this.dataForTable = null;
      this.subscription.add(
        this.requestService.testProblem(objForSend).subscribe({
          next: success => {
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
            this.dataForTable = success;
            this.alertService.setMessage("success", "Successfully.");
            this.formService.isShowReportTable = true;
          }, 
          error: error => {
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
          }
        })
      )
    }
  }

  /**Print functions */
  printReport() {
    ReportsFunctions.printReport();
  }
  /**EndPrint functions */

  changeAlgorithmOptions(event): void {
    const item = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === event.value);
    if(event._selected) {
      this.dynamicAlgorithmFormControlsService.addAlgorithmGroup(item, this.formService.form)
      this.parametri.push(item);
    }
    else {
      this.dynamicAlgorithmFormControlsService.removeAlgorithmGroup(item, this.formService.form);
      this.parametri = this.parametri.filter(x => x.id !== item.id);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataForTable = null;
    this.clickedButton = false;
    this.formService.reset();
  }

}
