
<form [formGroup]="formService.form" class="reducedForm item-master">
    <div class="row">
        <!-- SOURCE AND DESTINATIONS TABLE -->
        <div [ngClass]="dataService.problemType.shortcut == 'cvrp'? 'col-md-6' : 'col-md-12'">
            <div class="row tab-header">
                <div class="col-md-12">
                    <ng-container *ngIf="dataService.problemType.shortcut == 'tsp'">
                        <mat-checkbox class="tab-title" [checked]="true" [formControl]="formService.form.get('isCircular')" (change)="changeIsCircular($event)">
                            <span>{{"Circular route" | translate }}</span>
                          </mat-checkbox>
                    </ng-container>
                    <h3 class="tab-title">
                        {{"SourceAndDestinations" | translate}}
                        <button class="addDestinationBtn bg-green" type="button" mat-mini-fab color="accent"
                            (click)="addNewControl()">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </h3>

                </div>
            </div>
            <!-- End Heading Item Master Tab -->

            <!-- Table Item Master Tab -->
            <div class="row">
                <div class="col-md-12">
                    <div class="source-destination-table-wrapper">
                        <ng-container *ngIf="dataService.typeOfMap != 'graph'; else graphControlsBlock">
                            <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>{{"Name" | translate}}</th>
                                        <ng-container *ngIf="dataService.typeOfMap == 'graph'; else longLat">
                                            <th>{{"Node" | translate}}</th>
                                        </ng-container>
                                        <ng-template #longLat>
                                            <th>{{"Longitude" | translate}}</th>
                                            <th>{{"Latitude" | translate}}</th>
                                        </ng-template>

                                        <ng-container *ngIf="dataService.problemType.shortcut == 'cvrp'">
                                            <th>{{"Quantity" | translate}}</th>
                                        </ng-container>

                                        <ng-container *ngIf="dataService.typeOfMap != 'graph'">
                                            <th>{{"Edit" | translate}}</th>
                                        </ng-container>

                                        <th>{{"Delete" | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="rowNameLabel">{{ "Source" | translate }}</span>
                                        </td>
                                        <ng-container *ngIf="dataService.typeOfMap != 'graph'">
                                            <td>
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>{{"Longitude" | translate}}</mat-label>
                                                    <input type="number" matInput min=0
                                                        [formControl]="formService.form.get('source').get('longitude')"
                                                        (change)="changeSourceLongitude($event)" />
                                                </mat-form-field>
                                            </td>
                                        </ng-container>

                                        <td>
                                            <mat-form-field appearance="outline" color="accent">
                                                <mat-label>
                                                    <ng-container *ngIf="dataService.typeOfMap == 'graph'; else latLabel">
                                                        {{"Node" | translate}}
                                                    </ng-container>
                                                    <ng-template #latLabel>
                                                        {{"Latitude" | translate}}
                                                    </ng-template>
                                                </mat-label>
                                                <input type="number" matInput min=0
                                                    [formControl]="formService.form.get('source').get('latitude')"
                                                    (change)="changeSourceLatitude($event)" />
                                            </mat-form-field>
                                        </td>

                                        <td *ngIf="dataService.problemType.shortcut == 'cvrp'">

                                        </td>

                                        <td *ngIf="dataService.typeOfMap != 'graph'">
                                            <button class="chooseBtn" mat-flat-button color="accent"
                                                (click)="openModal(null)">{{"Choose" | translate}}
                                            </button>
                                        </td>

                                        <td>
                                        </td>

                                    </tr>

                                    <ng-container
                                        *ngFor="let item of formService.form.get('destinations')['controls']; let i=index">
                                        <tr>
                                            <ng-container
                                                *ngIf="dataService.problemType.shortcut != 'ptp'; else ptpDestination">
                                                <td>
                                                    <span class="rowNameLabel">{{ "Destination" | translate }}
                                                        {{ i + 1 }}</span>
                                                </td>
                                            </ng-container>
                                            <ng-template #ptpDestination>
                                                <ng-container *ngIf="i==0; else inBetweenRoute">
                                                    <td>
                                                        <span class="rowNameLabel">{{ "Destination" | translate }}</span>
                                                    </td>
                                                </ng-container>
                                                <ng-template #inBetweenRoute>
                                                    <td>
                                                        <span class="rowNameLabel">{{ "InBetweenRoute" | translate }}
                                                            {{ i }}</span>
                                                    </td>
                                                </ng-template>
                                            </ng-template>

                                            <ng-container *ngIf="dataService.typeOfMap != 'graph'">
                                                <td>
                                                    <mat-form-field appearance="outline" color="accent">
                                                        <mat-label>{{"Longitude" | translate}}</mat-label>
                                                        <input type="number" matInput min=0
                                                            [formControl]="formService.form.get('destinations')['controls'][i].get('longitude')"
                                                            (change)="changeDestinationLongitude($event, i)" />
                                                    </mat-form-field>
                                                </td>
                                            </ng-container>


                                            <td>
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>
                                                        <ng-container
                                                            *ngIf="dataService.typeOfMap == 'graph'; else latLabel">
                                                            {{"Node" | translate}}
                                                        </ng-container>
                                                        <ng-template #latLabel>
                                                            {{"Latitude" | translate}}
                                                        </ng-template>
                                                    </mat-label>
                                                    <input type="number" matInput min=0
                                                        [formControl]="formService.form.get('destinations')['controls'][i].get('latitude')"
                                                        (change)="changeDestinationLatitude($event, i)" />
                                                </mat-form-field>
                                            </td>

                                            <td *ngIf="dataService.problemType.shortcut == 'cvrp'">
                                                <mat-form-field class="quantity" appearance="outline" color="accent">
                                                    <mat-label>
                                                        {{"Quantity" | translate}}
                                                    </mat-label>
                                                    <input type="number" matInput min=0
                                                        [formControl]="formService.form.get('destinations')['controls'][i].get('quantity')"
                                                        (change)="changeDestinationQuantity($event, i)" />
                                                </mat-form-field>
                                            </td>

                                            <td *ngIf="dataService.typeOfMap != 'graph'">
                                                <button class="chooseBtn" mat-flat-button color="accent"
                                                    (click)="openModal(i)">{{"Choose" | translate}}
                                                </button>
                                            </td>

                                            <td>
                                                <button class="removeDestinationBtn" *ngIf="i > 0" mat-mini-fab color="warn"
                                                    (click)="removeDestination(i)" type="button">
                                                    <mat-icon>remove_circle</mat-icon>
                                                </button>
                                            </td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </ng-container>
                        <ng-template #graphControlsBlock>
                            <app-controls-for-graph></app-controls-for-graph>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <!-- SOURCE AND DESTINATIONS TABLE END -->


        <!-- TRUCKS TABLE -->
        <ng-container *ngIf="dataService.problemType.shortcut == 'cvrp'">
            <div class="col-md-6 cvrp-part">
                <div class="row tab-header ">
                    <div class="col-md-12 tab-cvrp">
                        <h3 class="tab-title">
                            {{"Trucks" | translate}}
                            <button class="addDestinationBtn bg-green" type="button" mat-mini-fab color="accent"
                                (click)="addTruckControl()">
                                <mat-icon>add_circle</mat-icon>
                            </button>
                        </h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="source-destination-table-wrapper">
                            <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>{{"NumberOfTrucks" | translate}}</th>
                                        <th>{{"QuantityOfTrucks" | translate}}</th>
                                        <th>{{"Delete" | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <ng-container
                                        *ngFor="let item of formService.form.get('trucks')['controls']; let i=index">
                                        <tr>
                                            <td>
                                                <mat-form-field appearance="outline" color="accent" class="quantity">
                                                    <mat-label>{{"NumberOfTrucks" | translate}}</mat-label>
                                                    <input type="number" matInput min=1
                                                        [formControl]="formService.form.get('trucks')['controls'][i].get('truckNumber')"
                                                        (change)="changeTruckNumber($event, i)" />
                                                </mat-form-field>
                                            </td>

                                            <td>
                                                <mat-form-field appearance="outline" color="accent" class="quantity">
                                                    <mat-label>
                                                        {{"QuantityOfTrucks" | translate}}
                                                    </mat-label>
                                                    <input type="number" matInput min=1
                                                        [formControl]="formService.form.get('trucks')['controls'][i].get('truckQuantity')"
                                                        (change)="changeTruckQuantity($event, i)" />
                                                </mat-form-field>
                                            </td>

                                            <td>
                                                <button class="removeDestinationBtn" *ngIf="i > 0" mat-mini-fab color="warn"
                                                    (click)="removeTruckControl(i)" type="button">
                                                    <mat-icon>remove_circle</mat-icon>
                                                </button>
                                            </td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    <!-- TRUCKS TABLE END -->
    </div>
</form>
