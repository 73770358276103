import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlBomFormService } from '../../../../../../../../services/form/bl-bom-form.service';
import { BlDeterministicMrpDataService } from '../../../../../../../../services/shared/bl-deterministic-mrp-data.service';
import { BlBomTableService } from '../../../../../../../../services/table/bl-bom-table.service';
import { AlertMessageDataService } from '../../../../../../../../../../shared/services/alert-message-data.service';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../../../../../shared/helpers/reactive-forms-helper';
import { IBomForm } from '../../../../../../../../interfaces/i-bom';
import { BlBomRequestsService } from '../../../../../../../../services/requests/bl-bom-requests.service';
import { SpinnerFunctions } from '../../../../../../../../../../shared/functions/spinner-functions';
import { BlDeterministicMrpFormService } from '../../../../../../../../services/form/bl-deterministic-mrp-form.service';

@Component({
  selector: 'app-tab-dashboard-bom-left-block',
  templateUrl: './tab-dashboard-bom-left-block.component.html',
  styleUrls: ['./tab-dashboard-bom-left-block.component.scss']
})
export class TabDashboardBomLeftBlockComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlBomFormService,
    public deterministicFormService: BlDeterministicMrpFormService,
    private dataService: BlDeterministicMrpDataService,
    public tableService: BlBomTableService,
    private alertService: AlertMessageDataService,
    private requestsService: BlBomRequestsService
  ) { }

  public clickedButton: boolean = false;
  public clickedButton1: boolean = false;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IBomForm> = this.formService.form;

  public numbers: any = [];

  public dynamicHeight: string;

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.storageDataisFromViewDialog.subscribe({
        next: (data) => {
          if (data == false) {
            this.dataService.storageDataFromFormDialog.subscribe({
              next: (dataForm) => {
                if (dataForm) {
                  dataForm = dataForm[0] ? dataForm[0] : dataForm
                  this.formService.numberRows = dataForm.noProductPartItems;
                  this.formService.numberCols = dataForm.maxNoOfDirectComponent;
                  this.formService.colSpan = this.formService.numberCols + 1;

                  this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                  this.tableService.createDynamicHeaderColumns(this.formService.numberCols)
                  if (this.formService.numberRows && this.formService.numberRows > 0) {
                    if (this.dataService.clickedRun)
                      this.formService.createTableWithDynamicRows(dataForm, this.formService.numberRows);
                  }
                }
              }
            })
          } else {
            this.dataService.storageDataViewDialog.subscribe({
              next: (dataView) => {
                if (dataView) {
                let height = (dataView.rows.length + 1)*48;
                this.dynamicHeight  = height + 'px';

                  dataView = dataView[0] ? dataView[0] : dataView;
                  this.formService.numberCols = dataView.maxNoOfDirectComponent;
                  if (this.formService.numberCols && this.formService.numberCols > 0) {
                    this.tableService.createDynamicHeaderColumns(this.formService.numberCols)
                    this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                    this.formService.setRows(dataView);
                  }
                }
              }
            })
          }
        }
      })
    );

  }

  viewTree(itemRow: any) {
    this.clickedButton1 = true;
    this.subscription.add(this.dataService.storageDataisFromViewDialog.subscribe({
      next: (data) => {
        if (data == false) {
          if (this.clickedButton1) {
            let formValue: any = this.form.getRawValue();
            let rows: any = this.deterministicFormService.form.getSafe(x => x.rows).value;
            let rows2: any = formValue;
            rows = this.mapToCustomArray(rows);
            rows2 = this.mapToSingleArray(rows2.rowsTab2, rows2)

            let dataToSend: any = [{
              itemId: itemRow.value,
              rowsTab2: rows2,
              rows: rows
            }];

            this.dataService.storageNewDataTab2.next(null);
            SpinnerFunctions.showSpinner();
            this.requestsService.getTree(dataToSend).subscribe({
              next: (success) => {
                this.clickedButton1 = false;
                this.dataService.storageNewDataTab2.next(success);
                this.dataService.storageIsTreeView.next(true);
                this.alertService.setMessage("success", "Successfully showed.")
                SpinnerFunctions.hideSpinner();
              },
              error: (error) => {
                this.clickedButton1 = false;
                this.dataService.storageIsTreeView.next(false);
                SpinnerFunctions.hideSpinner();
              }
            });
          }
        } else {
          if (this.clickedButton1) {
            let formValue: any = this.form.getRawValue();
            let rows: any = this.deterministicFormService.form.getSafe(x => x.rows).value;
            let rows2: any = formValue;
            rows = this.mapToCustomArray(rows);
            rows2 = this.mapToSingleArray(rows2.rowsTab2, rows2)

            let dataToSend: any = [{
              itemId: itemRow.value,
              rowsTab2: rows2,
              rows: rows
            }];

            SpinnerFunctions.showSpinner();
            this.dataService.storageViewDataTab2.next(null);
            this.requestsService.getTree(dataToSend).subscribe({
              next: (success) => {
                this.clickedButton1 = false;
                this.dataService.storageViewDataTab2.next(success);
                this.dataService.storageIsTreeView.next(true);
                this.alertService.setMessage("success", "Successfully showed.")
                SpinnerFunctions.hideSpinner();
              },
              error: (error) => {
                this.clickedButton1 = false;
                SpinnerFunctions.hideSpinner();
                this.dataService.storageIsTreeView.next(false);
                this.alertService.setMessage("danger", "Error. "+ error["message"]);
              }
            });
          }
        }
      }
    }));
  }

  saveData() {
    this.clickedButton = true;
    this.subscription.add(this.dataService.storageDataisFromViewDialog.subscribe({
      next: (data) => {
        if (data == false && this.clickedButton) {
          this.dataService.storageDataFromFormDialog.subscribe({
            next: (dataForm) => {
            let formValue: any = this.form.getRawValue();
            this.formService.rowData =
              {
                rowsTab2: formValue.rowsTab2
              };
            }
          });
          if (this.clickedButton) {

            this.subscription.add(
              this.dataService.storageNewDataTab2.next(this.formService.rowData)
            );

            this.alertService.setMessage("primary", "Successfully temporarily saved.");
            this.clickedButton = false;
          }

        } else {
          this.dataService.storageDataViewDialog.subscribe({
            next: (dataView) => {
              if (dataView) {
                let formValue: any = this.form.getRawValue();

                this.formService.rowData =
                  {
                    rowsTab2: formValue.rowsTab2
                  };
              }
            }
          });
          if (this.clickedButton) {

            this.subscription.add(
              this.dataService.storageViewDataTab2.next(this.formService.rowData)
            );

            this.alertService.setMessage("primary", "Successfully temporarily saved.");
            this.clickedButton = false;
          }
        }
      }
    }));
  }

  //mapiranje niza iz prvog taba u izmenjeni objekat
  mapToCustomArray(arr: any[]) {
    let data: any = [];
    arr.forEach(el => {
      data.push({
        itemId: el.itemId,
        lotSize: el.lotSize
      })

    })
    return data;
  }
  //primjer pravljenja niza stringova mapiranjem i zatim konverzija iz niza stringova u string odvojen zarezom
  mapToSingleArray(arr: any[], form: any) {
    let namesOfDynamicControls = [];
    Object.keys(arr[0]).forEach(el => {
      if (el.startsWith("c")) {
        namesOfDynamicControls.push(el);
      }
    })
    arr.forEach(el => {
      el.stavke = "";
      el.tempArr = [];
      namesOfDynamicControls.forEach(name => {
        el.tempArr.push(el[name]);
        delete el[name];
      })
      el.stavke = el.tempArr.join(',');
      if(el.stavke.startsWith(",")){
        el.stavke = el.stavke.substring(1);
      }
      delete el["tempArr"];
    })
    return arr;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols = 0;
    this.formService.colSpan = 0;
    this.clickedButton = false;
    this.clickedButton1 = false;
    this.numbers = [];
  }

}
