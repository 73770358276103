import { Component, OnInit } from '@angular/core';
import { ICredentialsForgetPassword } from '../../interfaces/i-credentials';
import { Validators } from '@angular/forms';
import { ForgerPasswordService } from '../../services/api/forger-password.service';
import { FormBuilderTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { whitespaceValidator } from '../../../shared/validators/whitespace.validator';
import { config } from '../../../config/global';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private alertService: AlertMessageDataService,
    private forgetPassService: ForgerPasswordService
  ) { }

  public form = this.formBuilder.group<ICredentialsForgetPassword>({
    email: this.formBuilder.control("", [Validators.required, whitespaceValidator, Validators.email])
  });

  private serverPath: any = config.baseApiUrl.SERVER;

  ngOnInit() {
  }

  textareaEnterPressed($event: KeyboardEvent) {
    $event.preventDefault()
    $event.stopPropagation()

    let appName: string = "";
    if (this.serverPath.indexOf("dev-back") != -1) {
      appName = "olymoDev"
    } else {
      appName = "olymoLive"
    }

    let objToSend: ICredentialsForgetPassword = {
      email: this.form.getSafe(x => x.email).value,
      app: appName
    };
    this.forgetPassService.create(objToSend).subscribe({
      next: success => {
        this.alertService.setMessage("success", "Password successfully reset.");
        this.form.reset();
      },
      error: error => {
        this.alertService.setMessage("danger", "Error. Password not reset.");
      }
      });
  }

  submit() {

    let appName: string = "";
    if (this.serverPath.indexOf("dev-back") != -1) {
      appName = "olymoDev"
    } else {
      appName = "olymoLive"
    }

    let objToSend: ICredentialsForgetPassword = {
      email: this.form.getSafe(x => x.email).value,
      app: appName
    };
    this.forgetPassService.create(objToSend).subscribe({
        next: success => {
          this.alertService.setMessage("success", "Password successfully reset.");
          this.form.reset();
        },
        error: error => {
          this.alertService.setMessage("danger", "Error. Password not reset.");
        }
      });
  }

}
