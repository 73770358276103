import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '../../../../config/api';
import { config } from '../../../../config/global';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { BackpackProblemAlgorithmsService } from '../api/backpack-problem-algorithms.service';
import { BackpackProblemService } from '../api/backpack-problem.service';
import { RunBackpackProblemService } from '../api/run-backpack-problem.service';

@Injectable({
  providedIn: 'root'
})
export class BlBackpackProblemRequestsService extends BlRequestService {

  constructor(
    private alertServices: AlertMessageDataService,
    private apiService: BackpackProblemService,
    private changeArrayNumbersService: RunBackpackProblemService,
    private algorithmService: BackpackProblemAlgorithmsService,
    private http: HttpClient
  ) {
    super(alertServices);
  }

  saveData(dataForSend: any) {
    // update but with post http method
    return this.apiService.create(dataForSend);
  }

  insertData(dataForSend: any) {
    return this.apiService.create(dataForSend);
  }

  getAllByType(type: string) {
    return this.apiService.getWithParams({ problemType: type });
  }

  getBackpackProblem(id: number) {
    return this.apiService.get(id);
  }

  getBackpackProblems() {
    return this.apiService.getAll();
  }

  runProblem(dataForSend: any) {
    return this.changeArrayNumbersService.create(dataForSend);
  }

  getAlgorithms() {
    return this.algorithmService.getAll();
  }
}
