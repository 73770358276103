import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BlBomFormService } from '../../../../../../../../services/form/bl-bom-form.service';
import { BlDeterministicMrpDataService } from '../../../../../../../../services/shared/bl-deterministic-mrp-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-dashboard-bom-right-block',
  templateUrl: './tab-dashboard-bom-right-block.component.html',
  styleUrls: ['./tab-dashboard-bom-right-block.component.scss']
})
export class TabDashboardBomRightBlockComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(
    public formService: BlBomFormService,
    private dataService: BlDeterministicMrpDataService
  ) { }

  private subscription: Subscription = new Subscription();

  public isViewLoad: boolean = false;
  public nodes: any = [];

  ngAfterViewInit(): void {
    this.subscription.add(
      this.dataService.storageIsTreeView.subscribe({
        next: (showTree) => {
          if (showTree == true) {
            this.formService.isShowBomTree = true;
            this.dataService.storageDataisFromViewDialog.subscribe({
              next: (data) => {
                if (data == false) {
                    this.nodes = [];
                    this.isViewLoad = true;
                    this.dataService.storageNewDataTab2.subscribe({
                      next: (dataForm) => {
                      if (dataForm) {
                        if (this.isViewLoad) {
                          this.isViewLoad = false;
                          this.nodes.push(dataForm);
                        }
                      }
                    }
                  })
                } else {
                  this.nodes = [];
                  this.isViewLoad = true;
                  this.dataService.storageViewDataTab2.subscribe({
                    next: (dataView) => {
                      if (dataView) {
                        if (this.isViewLoad) {
                          this.isViewLoad = false;
                          this.nodes.push(dataView);
                        }
                      }
                    }
                  })
                }
              }
            })
          } else {
            this.formService.isShowBomTree = false;
          }
        }
      })
    );
  }
  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.nodes = [];

  }

}
