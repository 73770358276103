import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../shared/interfaces/i-filter-column';
import { IAdminConfigRouting } from '../../interfaces/i-admin-config-routing';
import { AdminConfigRoutingService } from '../api/admin-config-routing.service';

@Injectable({
  providedIn: 'root'
})
export class BlAdminConfigRoutingDataService extends BlDataLogicService implements ITableStorage<IAdminConfigRouting> {

  constructor(
    private apiService: AdminConfigRoutingService
  ) {
    super();
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<IAdminConfigRouting[]> = new BehaviorSubject<IAdminConfigRouting[]>([]);

  originalData: IAdminConfigRouting[];

  getOriginalData(): IAdminConfigRouting[] {
    return this.originalData;
  }

  setOriginalData(items: IAdminConfigRouting[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IAdminConfigRouting[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IAdminConfigRouting[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IAdminConfigRouting[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IAdminConfigRouting[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

}