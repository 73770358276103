import * as moment from 'moment';
import { cloneDeep } from "lodash";
import { LocalStorageFunctions } from './local-storage-functions';
import { LocalStorageKeys } from '../enums/local-storage-keys';
export class DateFunctions {
  /** @description Converts date from RFC3339 format to custom date format which depends on parameters
   * @param {string} date Date in RFC3339 format
   * @param {string} dateOrTime Expected output format (DD-MM-YYYY OR MM-DD-YYYY for Date OR 12H OR 24H for Time)
   * @description In case that both time and date are needed, call the function twice
   */

  static getUserTodayDate(){
    return moment((moment().toDate().setHours(0,0,0,0))).toDate();
  }
  
  static getUserTodayDateTime(){
    return moment().toDate();
  }

  static getUserTodayTimestamp(){
    return this.getUserTodayDate().getTime();
  }
  static getTommorowDate(){
    let tomorrow = this.getUserTodayDate();
    tomorrow.setDate(this.getUserTodayDate().getDate() + 1);
    return tomorrow;
  }
  static getYesterdayDate(){
    let tomorrow = this.getUserTodayDate();
    tomorrow.setDate(this.getUserTodayDate().getDate() - 1);
    return tomorrow;
  }
  static getTommorowDateTimestamp(){
    // return this.getTommorowDate().getTime();
    return this.getMomentDateNoTime(this.getTommorowDate());  
  }
  static getYesterdayDateTimestamp(){
    // return this.getTommorowDate().getTime();
    return this.getMomentDateNoTime(this.getYesterdayDate());  
  }
  static getDateBefore(date){
    let before = moment(DateFunctions.getMomentDateNoTime(date)).subtract(1,'days').toDate();
    return before;
  }
  static getDateAfter(date){
    let after = moment(DateFunctions.getMomentDateNoTime(date)).add(1,'days').toDate();
    return after;
  }
  
  static formatDate(datetime: any){
    if(!Date.parse(datetime)){
      // console.error("FAILED TO CONVERT DATE: " + datetime);
      return null;
    }
    let date = moment(datetime).toDate();
    return date.getFullYear() + "-" + ((date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1)) + "-" + (date.getDate() < 10? "0" + date.getDate() : date.getDate());
  }

  static timestampToString(timestamp: any) : string {
    return DateFunctions.formatDate(this.getMomentDateNoTime(timestamp));
  }
  
  static stringToTimestamp(date: string){
    return this.getMomentDateNoTime(date).getTime();
  }

  static isLessDate(dateA, dateB = null) : boolean {
    dateA = DateFunctions.getMomentDateNoTime(dateA); //dateToFormatedDate
    if(dateB==null){
      dateB = this.getTodayHotelDate();
    }
    dateB = DateFunctions.getMomentDateNoTime(dateB); //dateToFormatedDate
    return dateA < dateB;
  }
  
  static isLessOrEqualDate(dateA, dateB = null) : boolean {
    dateA = DateFunctions.getMomentDateNoTime(dateA);
    if(dateB==null){
      dateB = this.getTodayHotelDate();
    }
    dateB = DateFunctions.getMomentDateNoTime(dateB);
    return dateA <= dateB;
  }

  /**
   * @returns "0" if start greater or same than end, "1" if end greater or same than start
   * @param start First date to compare
   * @param end 
   */
  static compareDate(start: any, end: any){
    let dateStart = DateFunctions.getMomentDateNoTime(start).getTime();
    let dateEnd = DateFunctions.getMomentDateNoTime(end).getTime();
    return (dateStart >= dateEnd)? 0 : 1;
  }

  static isDate(value){
    if(value === undefined || value === null){
      return false;
    }

    let regex = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
    let isValidFormat = regex.test(value) && !isNaN(Date.parse(value));
    
    let isDateType = (typeof(value) === 'object' && value.hasOwnProperty('__proto__') && value instanceof Date);
    
    let isDate = Object.prototype.toString.call(value) === "[object Date]" && !isNaN(value);
    
    let isMomentType = moment.isMoment(value);

    // let result = isValidFormat || isDateType || isDate || isMomentType;

    return isDate;
  }

  static convertDate(date: string, dateOrTime: string) {
    try{
      var input = this.getMomentDateNoTime(date);

      if(isNaN(input.getDay())) {
          return "Date format passed as the 1st argument is not valid";
      }
  
      var day = input.getDate() < 10 ? "0" + input.getDate() : input.getDate(); //adding leading zero
      var month = input.getMonth() + 1 < 10 ? "0" + (input.getMonth() + 1) : input.getMonth() + 1; //adding leading zero
      var year = input.getFullYear();
      var hours = input.getHours();
      var minutes = input.getMinutes();
      var seconds = input.getSeconds();
      
      switch (dateOrTime) {
        case "YYYY-MM-DD":
          var formattedDate = year + "-" + month + "-" + day;
          return formattedDate;
        case "DD-MM-YYYY":
          var formattedDate = day + "-" + month + "-" + year;
          return formattedDate;
        case "MM-DD-YYYY":
          var formattedDate = month + "-" + day + "-" + year;
          return formattedDate;
        case "12H":
          var time = hours + ":" + minutes + ":" + seconds;
          var timeSplitted = time.split(":"),
            formattedTime;
          if (+timeSplitted[0] == 12) {
            formattedTime = timeSplitted[0] + ":" + timeSplitted[1] + " PM";
          } else {
            if (+timeSplitted[0] == 0o0) {
              formattedTime = "12:" + timeSplitted[1] + " AM";
            } else {
              if (+timeSplitted[0] > 12) {
                formattedTime =
                  +timeSplitted[0] - 12 + ":" + timeSplitted[1] + " PM";
              } else {
                formattedTime = +timeSplitted[0] + ":" + timeSplitted[1] + " AM";
              }
            }
          }
          return formattedTime;
        case "24H":
          var time24 = hours + ":" + minutes;
          return time24;
        default:
          return "Bad input. There are 5 available options: DD-MM-YYY/MM-DD-YYYY/YYYY-MM-DD/12H/24H";
      }
    } catch (e) {
      
    }

    
  }

  static setDayAfter(beforeDateEl, afterDateEl, canSameDate = false){
    let checkIn = this.getMomentDateNoTime(beforeDateEl.value);

    let countMore = (24 * 60 * 60 * 1000);
    if(canSameDate){
      countMore = 0;
    }

    afterDateEl.setValue(this.getMomentDateNoTime(checkIn.getTime() + countMore))
  }

  static setDayBefore(beforeDateEl, afterDateEl, canSameDate = false){
    let checkOut = moment(afterDateEl.value).toDate();
    let countDown = (24 * 60 * 60 * 1000);
    if(canSameDate){
      countDown = 0;
    }
    beforeDateEl.setValue(moment(checkOut.getTime() - countDown).toDate())
  }

  static setDatePickers(beforeDateEl, afterDateEl, canSameDate = false){
    beforeDateEl.setValue(moment().toDate());
    if(afterDateEl){
      DateFunctions.setDayAfter(beforeDateEl, afterDateEl, canSameDate);
      DateFunctions.manageDatePickers(beforeDateEl, afterDateEl, canSameDate);
    }
    
  }

  static manageDatePickers(beforeDateEl, afterDateEl, canSameDate = false){
    afterDateEl.valueChanges.subscribe({
      next: data=>{
        if(data){
          let condition = this.getMomentDate(data).getTime() <= this.getMomentDate(beforeDateEl.value).getTime();

          if(canSameDate){
            condition = this.getMomentDate(data).getTime() < this.getMomentDate(beforeDateEl.value).getTime()
          }
          if(condition){
            DateFunctions.setDayBefore(beforeDateEl, afterDateEl, canSameDate);
          }
        }
      }
    });
    beforeDateEl.valueChanges.subscribe({
      next: data=>{
        if(data){
          let condition = (DateFunctions.getMomentDate(cloneDeep(data)).getTime() >= DateFunctions.getMomentDate(cloneDeep(afterDateEl).value).getTime());

          if(canSameDate){
            condition = this.getMomentDate(afterDateEl.value) && (this.getMomentDate(data).getTime() > this.getMomentDate(afterDateEl.value).getTime());
          }

          if(condition){
            DateFunctions.setDayAfter(beforeDateEl, afterDateEl, canSameDate);
          }
        }
      }
    });
  }

  static date_diff_indays = function(a, b) {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
    }
  
  static is_date_between_dates(dateFrom, dateTo, checkDate){
    // dateFrom = this.formatDate(dateFrom);
    // dateTo = this.formatDate(dateTo);
    // checkDate = this.formatDate(checkDate);
    dateFrom = this.getMomentDateNoTime(dateFrom);
    dateTo = this.getMomentDateNoTime(dateTo);
    checkDate = this.getMomentDateNoTime(checkDate);

    return dateFrom<=checkDate && dateTo>=checkDate
  }
  

  static getTodayDateNoTime(){
    return moment(moment().toDate().setHours(0,0,0,0)).toDate();
  }

  static areSame(date1,date2){
    return moment(date1).isSame(date2);
  }

  static isDay1AfterDate2(date1,date2){ 
    return moment(date1).isAfter(date2);
  }

  static isDay1BeforeDay2(date1,date2){
    return moment(date1).isBefore(date2);
  }

  static getMomentDate(date: string | Date | number){
    return moment(date).toDate();
  }
  
  static getMomentDateNoTime(date : any){
    return moment(moment(date).toDate().setHours(0,0,0,0)).toDate();
  }
  
  static getTodayHotelDate() : Date {
    // TODO: Delete after companySettings DONE
    if(LocalStorageFunctions.get(LocalStorageKeys.company_date)) {
      return this.getMomentDate(LocalStorageFunctions.get(LocalStorageKeys.company_date))
    } 
    return this.getUserTodayDateTime();
  }
  

  static getHotelFormattedDate() : string {
    return this.formatDate(LocalStorageFunctions.get(LocalStorageKeys.company_date));
  }

  static getTodayHotelDateNoTime(){
    let today = this.getTodayHotelDate();
    today.setHours(0,0,0,0);
    return today;
  }

  static isDateInHotelPast(date: string | Date){
    return DateFunctions.getMomentDate(date).getTime() < this.getTodayHotelDateNoTime().getTime();
  }

  static isDateInHotelPastOrEqual(date: string | Date){
    return DateFunctions.getMomentDate(date).getTime() <= this.getTodayHotelDateNoTime().getTime();
  }

  static getTommorowHotelDate(){
    let tommorow = this.getTodayHotelDateNoTime();
    tommorow.setDate(tommorow.getDate() + 1);
    return tommorow;
  }

  static getYesterdayHotelDate(){
    let tommorow = this.getTodayHotelDateNoTime();
    tommorow.setDate(tommorow.getDate() - 1);
    return tommorow;
  }
}
