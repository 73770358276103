import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QapRoutingModule } from './qap-routing.module';
import { DashboardQapComponent } from './components/dashboard-qap/dashboard-qap.component';
import { HeaderDashboardQapComponent } from './components/dashboard-qap/components/header-dashboard-qap/header-dashboard-qap.component';
import { ViewDialogDashboardQapComponent } from './components/dashboard-qap/components/view-dialog-dashboard-qap/view-dialog-dashboard-qap.component';
import { EditFlowsDialogDashboardQapComponent } from './components/dashboard-qap/components/edit-flows-dialog-dashboard-qap/edit-flows-dialog-dashboard-qap.component';
import { EditDistancesDialogDashboardQapComponent } from './components/dashboard-qap/components/edit-distances-dialog-dashboard-qap/edit-distances-dialog-dashboard-qap.component';
import { FormDashboardQapComponent } from './components/dashboard-qap/components/form-dashboard-qap/form-dashboard-qap.component';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { SaveAsDialogDashboardQapComponent } from './components/dashboard-qap/components/save-as-dialog-dashboard-qap/save-as-dialog-dashboard-qap.component';
import { TabsDashboardQapComponent } from './components/dashboard-qap/components/tabs-dashboard-qap/tabs-dashboard-qap.component';
import { TabDashboardInputDataComponent } from './components/dashboard-qap/components/tabs-dashboard-qap/components/tab-dashboard-input-data/tab-dashboard-input-data.component';
import { TabDashboardInputDataLeftSideComponent } from './components/dashboard-qap/components/tabs-dashboard-qap/components/tab-dashboard-input-data/components/tab-dashboard-input-data-left-side/tab-dashboard-input-data-left-side.component';
import { TabDashboardInputDataRightSideComponent } from './components/dashboard-qap/components/tabs-dashboard-qap/components/tab-dashboard-input-data/components/tab-dashboard-input-data-right-side/tab-dashboard-input-data-right-side.component';
import { TabDashboardResultComponent } from './components/dashboard-qap/components/tabs-dashboard-qap/components/tab-dashboard-result/tab-dashboard-result.component';


@NgModule({
    declarations: [DashboardQapComponent, HeaderDashboardQapComponent, ViewDialogDashboardQapComponent, EditFlowsDialogDashboardQapComponent, EditDistancesDialogDashboardQapComponent, FormDashboardQapComponent, SaveAsDialogDashboardQapComponent, TabsDashboardQapComponent, TabDashboardInputDataComponent, TabDashboardInputDataLeftSideComponent, TabDashboardInputDataRightSideComponent, TabDashboardResultComponent],
    imports: [
        CommonModule,
        QapRoutingModule,
        SharedModule,
        CoreModule
    ]
})
export class QapModule { }
