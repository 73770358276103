import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'virtualScrollRowIndex'
})
export class VirtualScrollRowIndexPipe implements PipeTransform {

  transform(formGroups: UntypedFormGroup[], id: number): any {
    return formGroups.findIndex(x => x.get("id").value == id) ;
  }

}
