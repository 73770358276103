<div mat-dialog-title>
  <div class="row">
      <div class="col-sm-12 custom-dialog-title">
        <mat-icon class="closeIconBold" (click)="close(true)">close</mat-icon>
      </div>
  </div>
</div>
<div mat-dialog-content>
  <iframe [src]="data.path"
          width="100%" height="500px" frameborder="5">
  </iframe>
</div>
