import { Injectable } from '@angular/core';
import { NotificationService } from '../api/notification.service';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlNotificationRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: NotificationService
  ) {
    super(alertService);
  }

  getNotifications() {
    return this.apiService.getAll();
  }
}
