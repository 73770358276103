<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form class="custom-form" [formGroup]="form">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading" [innerHTML]="html"></h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field color="primary" appearance="outline" [ngClass]="form.get('title').errors ? 'requiredField' : ''">
          <mat-label>{{"Title" | translate}}</mat-label>
          <input [formControlName]="form.keys.title" matInput autocomplete="off" />
          <mat-error class="customError">
            @if (formService.form.get('title').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
            @if (formService.form.get('title').hasError('noWhiteSpace')) {
              {{"NoWhiteSpace" | translate}}
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
      <button mat-flat-button color="primary"
              [disabled]="!form.get('title').valid" (click)="complete()">{{"Complete" | translate}}</button>
      <button mat-flat-button color="warn" (click)="close()">{{"Close" | translate}}</button>
  </div>
</form>
