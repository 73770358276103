
  <mat-table [dataSource]="null" class="mat-elevation-z2 customTable">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.index">
        <mat-header-cell *matHeaderCellDef> {{ column.title | translate}} </mat-header-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumnProps"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnProps;">
    </mat-row>
  </mat-table>
  <div class="alert alert-warning">
    {{'NoRecordsFound' | translate}}
  </div>
