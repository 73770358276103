<div id="auto-logout">
  <mat-card class="auditCard">
    <h4 class="mb-20">{{"Stay logged in" | translate}}</h4>
    <ngx-gauge
      [type]="gaugeOptions.type"
      [duration]="duration"
      [value]="gaugeOptions.percent"
      [thick]="gaugeOptions.thick"
      [foregroundColor]="gaugeOptions.forgrounColor">
    </ngx-gauge>
    <countdown [config]="config" class="countdown" (event)="timesUp($event)" #count>$!m!:$!s!</countdown>
    <div class="row">
      <div class="col-md-12 alertButton">
          <button type="button" mat-flat-button color="primary" class="custom-flat-button"
            (click)="stayLoggedIn()">
            {{"Stay logged in" | translate}}
          </button>
      </div>
    </div>
  </mat-card>
</div>
