import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { FilterFunctions } from '../../functions/filter-functions';
import { SpinnerFunctions } from '../../functions/spinner-functions';
import { IFilterColumn } from '../../interfaces/i-filter-column';

export class BlDataLogicService {

  constructor() { }
  private totalCountPropName = "total_count";

  isServerFiltering(data: any, filters : IFilterColumn[]){
    return (data && data.length > 0 && data.hasOwnProperty(this.totalCountPropName)) || (data && data.length > 0 && data[0].hasOwnProperty(this.totalCountPropName)) || filters.findIndex(x => x.isServerFiltering) != -1;
  }

  setStorageWithFilteredDataFromApiOrCache(filters: IFilterColumn[], data: any, storage: BehaviorSubject<any>, apiService: ApiService<any>){
    if(this.isServerFiltering(data,filters)){
      // TODO: server filtering
        let request: Observable<any>;
        if(filters && filters.length > 0 && apiService.url.indexOf('json')===-1){
          request = apiService.getWithBodyAndUrlParams(
              this.preparePathParamsForApi(filters),
              {}, 
              // this.prepareQueryStringForApi(), 
              this.prepareBodyParamsForApi(filters)
          );
        }
        else if(filters && filters.length > 0 && this.isServerFiltering && apiService.url.indexOf('json')===-1){
          request = apiService.getWithBodyAndUrlParams(this.preparePathParamsForApi(filters), {}, this.prepareBodyParamsForApi(filters));
        }
        SpinnerFunctions.showSpinner();
        request.subscribe({
          next: data => {
            storage.next(data);
            SpinnerFunctions.hideSpinner();
          }
        });
    } else {
      let filteredData = FilterFunctions.filterArray(filters, data);
      storage.next(filteredData);
    }
  }

  preparePathParamsForApi(filters){
    return filters.filter(x => x.hasOwnProperty('filter_type')? x.filter_type == 'url' : false).map(x => x.value);
  }
  prepareBodyParamsForApi(filters){
    let body = {};
    filters.filter(x => ( (x.hasOwnProperty('filter_type') && x.filter_type != 'url' || (!x.hasOwnProperty('filter_type')))  && ((!x.hasOwnProperty('isServerFiltering'))|| (x.hasOwnProperty('isServerFiltering') && x.isServerFiltering)))).forEach(x => {  
      body[x.column] = x.value
    });
    return body;
  }

 

}
