import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlDeterministicMrpTableService {

  constructor() { }

  public fixedColumns: any[] = [
    {
      index: "itemId",
      title: "Item ID"
    },
    {
      index: "class",
      title: "ABC Class"
    },
    {
      index: "sourceCode",
      title: "Source Code"
    },
    {
      index: "materialType",
      title: "Material Type"
    },
    {
      index: "unitMeasure",
      title: "Unit Measure"
    },
    {
      index: "leadTime",
      title: "Lead Time"
    },
    {
      index: "lotSize",
      title: "Lot Size"
    },
    {
      index: "lotQuantity",
      title: "Lot Quantity"
    },
    {
      index: "scrap",
      title: "Scrap %"
    },
    {
      index: "demand",
      title: "Demand"
    },
    {
      index: "unitCost",
      title: "Unit Cost"
    },
    {
      index: "setupCost",
      title: "Setup Cost"
    },
    {
      index: "holdingCost",
      title: "Holding Cost"
    },
    {
      index: "shortageCost",
      title: "Shortage Cost"
    },
    {
      index: "itemDescription",
      title: "Item Description"
    },
    {
      index: "otherNote",
      title: "Other Note"
    }
  ];

  headersTable: any[] = this.fixedColumns;
}
