import { SpinnerFunctions } from './../../../../../../shared/functions/spinner-functions';
import { SaveAsNewDialogComponent } from './../save-as-new-dialog/save-as-new-dialog.component';
import { DeterministicMrpService } from './../../../../services/api/deterministic-mrp.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormDashboardDeterministicMrpComponent } from '../form-dashboard-deterministic-mrp/form-dashboard-deterministic-mrp.component';
import { ViewDialogDashboardDeterministicMrpComponen } from '../view-dialog-dashboard-deterministic-mrp/view-dialog-dashboard-deterministic-mrp.component';
import { BlDeterministicMrpDataService } from '../../../../services/shared/bl-deterministic-mrp-data.service';
import { BlDeterministicMrpFormService } from '../../../../services/form/bl-deterministic-mrp-form.service';
import { Subscription } from 'rxjs';
import { BlDeterministicMrpRequestsService } from '../../../../services/requests/bl-deterministic-mrp-requests.service';
import { BlBomFormService } from '../../../../services/form/bl-bom-form.service';
import { BlMpsFormService } from '../../../../services/form/bl-mps-form.service';
import { BlInventoryFormService } from '../../../../services/form/bl-inventory-form.service';
import { BlCapacityFormService } from '../../../../services/form/bl-capacity-form.service';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { UntypedFormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';
import { DmrpHelperFunctionsService } from '../../../../services/helper/dmrp-helper-functions.service';

@Component({
  selector: 'app-header-dashboard-deterministic-mrp',
  templateUrl: './header-dashboard-deterministic-mrp.component.html',
  styleUrls: ['./header-dashboard-deterministic-mrp.component.scss']
})
export class HeaderDashboardDeterministicMrpComponent implements OnInit, OnDestroy {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlDeterministicMrpDataService,
    public formService: BlDeterministicMrpFormService,
    public formServiceTab2: BlBomFormService,
    public formServiceTab3: BlMpsFormService,
    public formServiceTab4: BlInventoryFormService,
    public formServiceTab5: BlCapacityFormService,
    private requestsService: BlDeterministicMrpRequestsService,
    private alertService: AlertMessageDataService,
    private router: Router,
    public permissionsService: PermissionsService,
    public apiService: DeterministicMrpService,
    private sanitizer: DomSanitizer,
    private markdownService: MarkdownService,
    private dmrpHelperFunctionsService: DmrpHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  private dataRows1: any = null;
  private dataForSend: any = []

  public clickedButton: boolean = false;
  public nameOfCreated = null;

  public safeData: any;
  public unsafeHTML = "assets/markdown/mrp/deterministic-mrp/help1.html";

  ngOnInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        if (data == true) {
          this.formService.isShowOtherTabs = true;
        } else {
          this.formService.isShowOtherTabs = false;
        }
      }
    })

    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  newMrp() {
    const dialogRef = this.matDialog.open(FormDashboardDeterministicMrpComponent, {
      width: '90%',
      maxWidth: '600px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal"
    }).afterClosed().subscribe({
      next: (val)=>{
        if(val){
          this.dataService.storageDataViewDialog.next(null);
          this.nameOfCreated = val;
          this.dataService.patchFlag = false;
          this.dataService.insert(false);
          this.dataService.currentId = null;
        }
      }
    });
  }

  viewMrps() {
    const dialogRef = this.matDialog.open(ViewDialogDashboardDeterministicMrpComponen, {
      width: '90%',
      maxWidth: '750px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal"
    }).afterClosed().subscribe({
      next: (val)=>{
        if(val){
          SpinnerFunctions.showSpinner();
          this.dataService.insert(false);
          SpinnerFunctions.hideSpinner();
          this.trackItemIdChanges();
        }
      }
    });
  }

  openMarkdown() {
    this.markdownService.openMarkdown(this.safeData);
  }

  trackItemIdChanges(){
    (this.formService.form.get("rows") as UntypedFormArray).valueChanges.subscribe({
      next: (data) => {
        if(data){
          if(this.formServiceTab2.form.value.rowsTab2.length==data.length)
            this.updateOtherTabs("rowsTab2", data);
            if(this.formServiceTab3.form.value.rowsTab3.length==data.length)
            this.updateOtherTabs("rowsTab3", data);
            if(this.formServiceTab4.form.value.rowsTab4.length==data.length)
            this.updateOtherTabs("rowsTab4", data);
            if(this.formServiceTab5.form.value.rowsTab5.length==data.length)
            this.updateOtherTabs("rowsTab5", data);
        }
      }
    })
    this.dataService.insert(true);
  }

  updateOtherTabs(ctrlName : string, data){
      (this["formServiceTab"+ctrlName.charAt(ctrlName.length-1)].form.get(ctrlName) as UntypedFormArray).controls.forEach((ctrl,index)=>{
        if(this.dataService.inserted){
        ctrl.get("itemId").setValue(data[index].itemId);
        }
      })
  }

  openWindow() {
    this.clickedButton = true;
    this.dataForSend = [];
    this.subscription.add(
      this.dataService.storageDataisFromViewDialog.subscribe({
        next: (isDialog) => {
          if (isDialog == false) {

            this.subscription.add(
              this.dataService.storageDataFromFormDialog.subscribe({
                next: (data) => {
                  if (data) {
                    this.dataRows1 = data[0] ? data[0] : data;
                  }
                }
            }));

            
            let { rows2, rows3, rows4, rows5 } = this.getRowsFromTabs();

            let dataToSend = this.prepareDataToSend(rows2, rows3, rows4, rows5);

            dataToSend.title = this.dataRows1.title;

            this.dataForSend.push(dataToSend);

            if (this.clickedButton) {
              SpinnerFunctions.showSpinner();
              this.subscription.add(
                this.requestsService.filterReportData(this.dataForSend).subscribe({
                  next: success => {
                    this.dataService.requestedResults = true;
                    this.dataService.selectedTab = 5;
                    SpinnerFunctions.hideSpinner();
                    this.dataService.storageReportData.next(success);
                    this.clickedButton = false;
                  },
                  error: error => {
                    SpinnerFunctions.hideSpinner();
                    this.clickedButton = false;
                  }
                })
              );
            }
          } else {

            this.subscription.add(
              this.dataService.storageDataViewDialog.subscribe({
                next: (data) => {
                  if (data) {
                    this.dataRows1 = data[0] ? data[0] : data;
                  }
                }
            }));
            
              
            let { rows2, rows3, rows4, rows5 } = this.getRowsFromTabs();

            let dataToSend = this.prepareDataToSend(rows2, rows3, rows4, rows5);
            dataToSend.rows = this.dataRows1.rows;

            this.dataForSend.push(dataToSend);


            if (this.clickedButton) {
              SpinnerFunctions.showSpinner();
              this.subscription.add(
                this.requestsService.filterReportData(this.dataForSend).subscribe({
                  next: success => {
                    this.dataService.requestedResults = true;
                    this.dataService.selectedTab = 5;
                    this.dataService.storageReportData.next(success);
                    SpinnerFunctions.hideSpinner();
                    this.clickedButton = false;
                  },
                  error: error => {
                    SpinnerFunctions.hideSpinner();
                    this.clickedButton = false;
                  }
                })
              );
            }
          }
        }
      })
    )
  }

  saveDatas() {
    this.clickedButton = true;
    this.dataForSend = [];
    this.subscription.add(
      this.dataService.storageDataisFromViewDialog.subscribe({
        next: (isDialog) => {
          if (isDialog == false) {

            this.subscription.add(
              this.dataService.storageDataFromFormDialog.subscribe({
                next: (data) => {
                  if (data) {
                    this.dataRows1 = data[0] ? data[0] : data;
                  }
                }
            }));


            let { rows2, rows3, rows4, rows5 } = this.getRowsFromTabs();

            
            let dataToSend = this.prepareDataToSend(rows2, rows3, rows4, rows5);

            this.dataForSend.push(dataToSend);

            if (this.clickedButton) {
              SpinnerFunctions.showSpinner();
              if(this.dataService.currentId){

                if(this.dataService.currentId){
                  this.dataForSend[0]['idProblem'] = this.dataService.currentId;
                } else {
                  console.error("No ID for patching??");
                  return;
                }

                this.subscription.add(
                  this.requestsService.saveData(this.dataForSend).subscribe({
                    next: success => {
                      this.alertService.setMessage("success", "Successfully updated.");
                      SpinnerFunctions.hideSpinner();
                      this.clickedButton = false;
                      this.dataService.currentId = success['idProblem'];
                    },
                    error: err => {
                      this.alertService.setMessage("danger", "Error.");
                      SpinnerFunctions.hideSpinner();
                      this.clickedButton = false;
                    }
                  })
                )

              } else {
                this.subscription.add(
                  this.requestsService.insertData(this.dataForSend).subscribe({
                    next: success => {
                      this.nameOfCreated = this.dataService.storageDataFromFormDialog.getValue()[0]['title'];
                      this.alertService.setMessage("success", "Successfully created.");
                      SpinnerFunctions.hideSpinner();
                      this.clickedButton = false;
                      this.dataService.patchFlag = true;

                      if(success['idProblem']){
                        this.dataService.currentId = success['idProblem'];
                      } else {
                        this.subscription.add(
                          this.apiService.getAll().subscribe({
                            next: (data) => {
                              if (data) {
                                this.dataService.setIdForPatch(data[data.length-1]['idProblem']);
                                this.dataService.currentId = data[data.length-1]['idProblem']
                              }
                            }
                          })
                        );
                      }
                    },
                    error: error => {
                      this.alertService.setMessage("danger", "Error.");
                      SpinnerFunctions.hideSpinner();
                      this.clickedButton = false;
                    }
                  })
                );
              }
            }
          } else {

            this.subscription.add(
              this.dataService.storageDataViewDialog.subscribe({
                next: (data) => {
                  if (data) {
                    this.dataRows1 = data[0] ? data[0] : data;
                  }
                }
              }));
              
            let { rows2, rows3, rows4, rows5 } = this.getRowsFromTabs();
            
            
            let dataToSend = this.prepareDataToSend(rows2, rows3, rows4, rows5);

            this.dataForSend.push(dataToSend);
            
            if (this.clickedButton) {
              SpinnerFunctions.showSpinner();
              this.subscription.add(
                this.requestsService.saveData(this.dataForSend).subscribe({
                  next: success => {
                    this.alertService.setMessage("success", "Successfully updated.");
                    SpinnerFunctions.hideSpinner();
                    this.clickedButton = false;
                  },
                  error: error => {
                    this.alertService.setMessage("danger", "Error.");
                    SpinnerFunctions.hideSpinner();
                    this.clickedButton = false;
                  }
                })
              );
            }
          }
        }
      })
    )
  }

  saveAsNew(){
    this.matDialog.open(SaveAsNewDialogComponent,{
      width: '90%',
      maxWidth: '400px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal",
      data:{
        title:this.nameOfCreated
      }
    }).afterClosed().subscribe({
      next: (val) => {
        if(val){
          let form;
          if(this.dataService.storageDataViewDialog.getValue()){
            form = this.dataService.storageDataViewDialog.getValue()[0] ? this.dataService.storageDataViewDialog.getValue()[0] : this.dataService.storageDataViewDialog.getValue();
          } else if(this.dataService.storageDataFromFormDialog.getValue()){
            form = this.dataService.storageDataFromFormDialog.getValue()[0] ? this.dataService.storageDataFromFormDialog.getValue()[0] : this.dataService.storageDataFromFormDialog.getValue();
          }
          
          let { rows2, rows3, rows4, rows5 } = this.getRowsFromTabs();

          let newSend = [];

          newSend.push({
            maxNoOfDirectComponent: form.maxNoOfDirectComponent,
            noPeriodsYear: form.noPeriodsYear,
            noPlanningPerod: form.noPlanningPerod,
            noProductPartItems: form.noProductPartItems,
            timeUnitPlanningPeriod: form.timeUnitPlanningPeriod,
            title: val,
            rows: this.formService.form.value.rows,
            rowsTab2: rows2,
            rowsTab3: rows3,
            rowsTab4: rows4,
            rowsTab5: rows5,
          });
          this.subscription.add(
            this.requestsService.insertData(newSend).subscribe({
              next: success => {
                this.dataService.setIdForPatch(success['idProblem']);

                this.dataService.currentId = success['idProblem'];
                this.dataService.currentTitle = success['title'];

                if(this.dataService.storageDataViewDialog && this.dataService.storageDataViewDialog.getValue())
                this.dataService.storageDataViewDialog.getValue().title = success["title"];

                this.alertService.setMessage("success", "Successfully created.");
                SpinnerFunctions.hideSpinner();
              },
              error: error => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
              }
            })
          );
        }
      }
    })
  }

  getRowsFromTabs(): any {
    
    let rows2: any = this.formServiceTab2.form.getRawValue();
    let rows3: any = this.formServiceTab3.form.getRawValue();
    let rows4: any = this.formServiceTab4.form.getRawValue();
    let rows5: any = this.formServiceTab5.form.getRawValue();

    rows2 = this.dmrpHelperFunctionsService.mapToSingleArrayFor2Tab(rows2.rowsTab2, rows2);
    rows3 = this.dmrpHelperFunctionsService.mapToSingleArray(rows3.rowsTab3, rows3);
    rows4 = this.dmrpHelperFunctionsService.mapToSingleArray(rows4.rowsTab4, rows4);
    rows5 = this.dmrpHelperFunctionsService.mapToSingleArray(rows5.rowsTab5, rows5);

    return { rows2, rows3, rows4, rows5 };
  }

  prepareDataToSend(rows2: any, rows3: any, rows4: any, rows5: any): any {

    let dataToSend = {
      idProblem: this.dataService.currentId ? this.dataService.currentId : null,
      maxNoOfDirectComponent: this.dataRows1.maxNoOfDirectComponent,
      noPeriodsYear: this.dataRows1.noPeriodsYear,
      noPlanningPerod: this.dataRows1.noPlanningPerod,
      noProductPartItems: this.dataRows1.noProductPartItems,
      timeUnitPlanningPeriod: this.dataRows1.timeUnitPlanningPeriod,
      title: this.dataService.currentTitle,
      rows: this.dataRows1.rows,
      rowsTab2: rows2,
      rowsTab3: rows3,
      rowsTab4: rows4,
      rowsTab5: rows5,
    }

    return dataToSend;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.isShowOtherTabs = false;
    this.dataForSend = [];
    this.nameOfCreated = null;

    this.dataService.currentTitle = null;
    this.dataService.currentId = null;

  }

}
