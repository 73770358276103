import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { IMpsForm } from '../../../../../../interfaces/i-mps';
import { BlMpsFormService } from '../../../../../../services/form/bl-mps-form.service';
import { BlDeterministicMrpDataService } from '../../../../../../services/shared/bl-deterministic-mrp-data.service';
import { BlMpsTableService } from '../../../../../../services/table/bl-mps-table.service';

@Component({
  selector: 'app-tab-dashboard-mps',
  templateUrl: './tab-dashboard-mps.component.html',
  styleUrls: ['./tab-dashboard-mps.component.scss']
})
export class TabDashboardMpsComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlMpsFormService,
    private dataService: BlDeterministicMrpDataService,
    public tableService: BlMpsTableService,
    private alertService: AlertMessageDataService,
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IMpsForm> = this.formService.form;

  public numbers: any = [];

  public initial = true;

  ngOnInit(): void {
      this.subscription.add(
        this.dataService.storageDataisFromViewDialog.subscribe({
          next: (data) => {
            if (data == false) {
              this.dataService.storageDataFromFormDialog.subscribe({
                next: (dataForm) => {
                  if (dataForm) {
                    dataForm = dataForm[0] ? dataForm[0] : dataForm
                    this.formService.numberRows = dataForm.noProductPartItems;
                    this.formService.numberCols = dataForm.noPlanningPerod;
                    this.formService.typePlanning = dataForm.timeUnitPlanningPeriod;
                    this.formService.colSpan = this.formService.numberCols + 2;

                    this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                    this.tableService.createDynamicHeaderColumns(this.formService.numberCols, this.formService.typePlanning)
                    if (this.formService.numberRows && this.formService.numberRows > 0) {
                      if(this.dataService.clickedRun)
                      this.formService.createTableWithDynamicRows(dataForm, this.formService.numberRows);
                    }
                  }
                }
              })
            } else {
              this.dataService.storageDataViewDialog.subscribe({
                next: (dataView) => {
                  if (dataView) {
                    dataView = dataView[0] ? dataView[0] : dataView;
                    this.formService.numberCols = dataView.noPlanningPerod;
                    this.formService.typePlanning = dataView.timeUnitPlanningPeriod;
                    if (this.formService.numberCols && this.formService.numberCols > 0) {
                      this.tableService.createDynamicHeaderColumns(this.formService.numberCols, this.formService.typePlanning)
                      this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                      this.formService.setRows(dataView);
                    }
                  }
                }
              })
            }
          }
        })
      );

  }

  saveData() {
    this.clickedButton = true;
    this.subscription.add(this.dataService.storageDataisFromViewDialog.subscribe({
      next: data => {
        if (data == false  && this.clickedButton) {
          this.dataService.storageDataFromFormDialog.subscribe({
            next: dataForm => {

              let formValue: any = this.form.getRawValue();

              this.formService.rowData =
                {
                  rowsTab3: formValue.rowsTab3
                };

            }
          });
          if (this.clickedButton) {

            this.subscription.add(
              this.dataService.storageNewDataTab3.next(this.formService.rowData)
            );

            this.alertService.setMessage("primary", "Successfully temporarily saved.");
            this.clickedButton = false;
          }

        } else {
          this.dataService.storageDataViewDialog.subscribe({
            next: dataView => {
              if (dataView) {
                let formValue: any = this.form.getRawValue();

                this.formService.rowData =
                  {
                    rowsTab3: formValue.rowsTab3
                  };

              }
            }
          });
          if (this.clickedButton) {

            this.subscription.add(
              this.dataService.storageViewDataTab3.next(this.formService.rowData)
            );

            this.alertService.setMessage("primary", "Successfully temporarily saved.");
            this.clickedButton = false;
          }
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols = 0;
    this.formService.typePlanning = "";
    this.formService.colSpan = 0;
    this.clickedButton = false;
    this.numbers = [];
  }
}
