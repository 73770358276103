<div id="login-wrapper">
  <div id="login">

    <div class="title">
      <h3>{{"Choose Company" | translate}}</h3>
    </div>

    <div class="searchCompany">
      <mat-form-field appearance="outline">
        <mat-label>{{"Search Company" | translate}}</mat-label>
        <input matInput type="text" (keyup)="search($event)">
      </mat-form-field>
    </div>

    <div class="hotelList">
      <ul>
        @for (property of properites; track property) {
          <li>
            <a (click)="modify(property)">
              <div class="listContent">
                @if (property.photo) {
                  <img src="{{photoPath+property.photo}}" alt="logo">
                } @else {
                  <img class="avatarImg" src="../../../../assets/images/default_avatar.jpg" alt="">
                }
                <div class="listText">
                  <p>{{ property.accountName }}</p>
                  <span>Account ID: <b>{{ property.id }}</b></span>
                </div>
              </div>
            </a>
          </li>
        }
        @if (this.properites.length == 0) {
          <li>
            <div class="listContent">
              {{"No companies found" | translate}}
            </div>
          </li>
        }
      </ul>
    </div>

  </div>

</div>
