import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';
import { IDistribution1ToNMapFiltering, IDistribution1ToNMapFilteringRequest } from '../../interfaces/i-distribution-1-to-n-map-filtering';

@Injectable({
  providedIn: 'root'
})
export class Distribution1ToNMapFilteringService extends ApiService<IDistribution1ToNMapFiltering>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.distribution1ToNMapFiltering, http);
  }

  filtering(data: IDistribution1ToNMapFilteringRequest): Observable<any> {
    // Change to POST method when the backend is finished
    return this.http.patch<any>(`${this.baseUrl + this.url}`, data).pipe(
      map((items: any) => {
        let data = items.hasOwnProperty('data') ? items['data'] : items;
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

}
