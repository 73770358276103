import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'cellClass'
})
export class CellClassPipe implements PipeTransform {

  transform(column: ITableColumn, row: any): string {
    if(column.hasOwnProperty("class")){
      let cellClass = (typeof(column.class) == "function")? column.class(row): column.class;
      if((column.otherTemplate && column.otherTemplate.class && column.otherTemplate.options && column.otherTemplate.options.length == 1 && column.otherTemplate.options[0].condition(row))){
        return column.otherTemplate.class + ' ' + cellClass;
      } else {
        return cellClass;
      }
    }
    return "";
  }

}
