<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<form [formGroup]="form" class="reducedForm" (ngSubmit)="save()">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    <ng-container *ngIf="isSource; else destination">
                        {{ "ChooseSource" | translate }}
                    </ng-container>
                    <ng-template #destination>
                        <ng-container *ngIf="isDestination; else inBetweenRoute">
                            {{ "ChooseDestination" | translate }}
                        </ng-container>
                        <ng-template #inBetweenRoute>
                            {{ "ChooseInBetweenRoute" | translate }}
                        </ng-template>
                    </ng-template>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-md-3">
                <form [formGroup]="filteringForm">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 id="advancedSearchLabel">{{"AdvancedSearch" | translate}}</h3>
                        </div>
                    </div>
                    <div class="row mapFilters">
                        <div class="col-md-12">
                            <mat-radio-group formControlName="type">
                                <mat-radio-button value="streets"><span class="typeRbPicture streets"></span>
                                </mat-radio-button>
                                <mat-radio-button value="hotels"><span class="typeRbPicture hotels"></span>
                                </mat-radio-button>
                                <mat-radio-button value="restaurants"><span class="typeRbPicture restaurants"></span>
                                </mat-radio-button>
                                <mat-radio-button value="parkingLots"><span class="typeRbPicture parkingLots"></span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 search-box search-term">
                            <mat-form-field appearance="outline" [ngClass]="filteringForm.get('description').errors ? 'requiredField' : null">
                                <mat-label>{{"SearchTerm" | translate}}</mat-label>
                                <input [formControlName]="filteringForm.keys.description" matInput autocomplete="off" />
                                <mat-error class="customError">
                                    <ng-container *ngIf="filteringForm.get('description').hasError('required')">
                                        {{"ThisFieldIsRequired" | translate}}
                                    </ng-container>
                                    <ng-container *ngIf="filteringForm.get('description').hasError('minlength')">
                                        {{"MinimumNumberOfCharacters" | translate}}&nbsp; 3
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <button [disabled]="filteringForm.invalid" type="button" mat-flat-button color="accent"
                                (click)="filtering()">{{"Search" | translate}}</button>
                        </div>
                    </div>

                    <ng-container *ngIf="filteringDataFromBack">
                        <div class="row location-links">
                            <ng-container formArrayName="searchedDestinations">
                                <div class="col-md-12">
                                    <ng-container *ngFor="let item of filteringDataFromBack; let i = index;">
                                        <mat-checkbox class="checkList" [formControlName]="i">
                                        </mat-checkbox>
                                        <a class="locationLink" (click)="selectLink(item)">{{item.title}}</a>

                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </form>

            </div>
            <div class="col-sm-8 col-md-9">
                <div class="row">
                    <div class="col-sm-12 location-box"
                        [ngClass]="(dataService.problemType.shortcut == 'cvrp' && !isSource) ? 'all-inputs' : null">
                        <div class="latitude-longitude">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"Latitude" | translate}}</mat-label>
                                <input type="number" [formControlName]="form.keys.latitude" matInput
                                    autocomplete="off" />
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{"Longitude" | translate}}</mat-label>
                                <input type="number" [formControlName]="form.keys.longitude" matInput
                                    autocomplete="off" />
                            </mat-form-field>
                            <ng-container *ngIf="dataService.problemType.shortcut == 'cvrp' && !isSource">
                                <mat-form-field appearance="outline" class="quantity-input">
                                    <mat-label>{{"Quantity" | translate}}</mat-label>
                                    <input type="number" [formControlName]="form.keys.quantity" matInput
                                        autocomplete="off" />
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="show-location">
                            <button [disabled]="geoPositioningService.turnOnLocationMessage != ''" type="button"
                                mat-flat-button color="accent" (click)="showMyLocation()">{{"GetMyLocation" |
                                translate}}</button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <app-leaflet-map (markerObjectClicked)="markerObjectClicked($event)" [form]="form"
                            [markers]="markers" [zoomIn]="zoomIn" [zoomAfterUpdatingMarkers]="zoomAfterUpdatingMarkers" [routes]="(dataService.coordinatesForSelectedCountry && dataService.coordinatesForSelectedCountry.routes) ? dataService.coordinatesForSelectedCountry.routes : []" [fitBounds]="zoomByFitBounds">
                        </app-leaflet-map>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <hr />
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="row full-width">
            <app-button-with-spinner class="full-width" [isClickedSave]="isClickedSave" [form]="form">
            </app-button-with-spinner>
        </div>
    </div>
</form>
