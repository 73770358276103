import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BlDistribution1ToNDataService } from '../../../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-left-sidebar-dashboard-distribution-destinations',
  templateUrl: './left-sidebar-dashboard-distribution-destinations.component.html',
  styleUrls: ['./left-sidebar-dashboard-distribution-destinations.component.scss']
})
export class LeftSidebarDashboardDistributionDestinationsComponent implements OnInit {

  constructor(
    public dataService: BlDistribution1ToNDataService
  ) {}

  @Output() openDestinationModal = new EventEmitter<any>();

  ngOnInit(): void {}

  openModal(index): void {
    this.openDestinationModal.emit({index: index});
  }

  addDestination(): void {
    this.dataService.allDestinations.push([null, null]);
  }

  removeDestination(index): void {
    this.dataService.allDestinations.splice(index, 1);
  }

}
