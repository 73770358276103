import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { BlTableLogicService } from '../../../../shared/bussiness-logic/base/bl-table-logic.service';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { ISetOfColumns } from '../../../../shared/interfaces/i-set-of-columns';
import { ITableColumn } from '../../../../shared/interfaces/i-table-column';
import { IGraphNodeItem } from '../../interfaces/i-graph-node';

@Injectable({
  providedIn: 'root'
})
export class BlGraphNodesItemsTableService extends BlTableLogicService<IGraphNodeItem>{

  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(translateService, tableFunctions, matDialog, location);
  }

  public apis = [];
  public idColumn = "";
  
  protected columns: ITableColumn[] = [
    {
      index: "counter",
      title: "counter",
      hide: true
    },
    {
      index: "id",
      title: "#",
      hide: false
    },
    {
      index: "node1",
      title: "Node1",
      class: "centerFormElement",
      isFormControl: true,
      hide: false,
      validators: [Validators.required]
    },
    {
      index: "node2",
      title: "Node2",
      class: "centerFormElement",
      isFormControl: true,
      hide: false,
      validators: [Validators.required]
    },
    {
      index: "distance",
      title: "Distance",
      class: "centerFormElement",
      isFormControl: true,
      hide: false,
      validators: [Validators.required]
    }
  ];
  
  protected columnsStorage: BehaviorSubject<ISetOfColumns> = new BehaviorSubject<ISetOfColumns>({
    columns: this.columns,
    displayedColumns: this.displayedColumns
  });

  protected displayedColumns: string[] =  this.columns.map(x => x.index);
  
}