<!-- Loading -->
<!-- <ng-container *ngIf="isLoading && dataSource.data.length == 0">
  <app-skeleton></app-skeleton>
</ng-container> -->
<!-- End Loading -->



<div class="tableWrapper" style="z-index: -1;">

    <!-- No records -->
    <ng-container *ngIf="(!isLoading && dataSource.data && dataSource.data.length == 0 ) || dataSource.data == null">
        <app-table-no-records [columns]="columns" [displayedColumnProps]="displayedColumnProps"></app-table-no-records>
    </ng-container>
    <!-- End No records -->

    <!-- TABLE -->
    <mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" class="mat-elevation-z2 customTable"
        [ngClass]="(!isLoading && dataSource.data && dataSource.data.length > 0)? '': 'hide'">

        <!-- Dynamic Columns -->
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.index">

            <!-- th -->
            <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column | sortableColumn: fixedColumns"
                [ngClass]="column | columnClass">
                {{ column.title | translate }}
                <!-- {{ column.title }} -->
            </mat-header-cell>
            <!-- End th -->

            <!-- td -->
            <mat-cell *matCellDef="let row; let rowIndex = index" class="light_blue" [ngClass]="column | cellClass: row">
                <!-- Base Column Definition -->
                <ng-container *ngIf="(column | columnValueCondition: row) else otherTemplate">

                    <span (click)="columnMethod(column, row)">

                        <!-- Print Column Value By Column Type -->
                        <ng-container [ngSwitch]="true">

                            <!-- Row Index Column -->
                            <ng-container *ngSwitchCase="column | idColumn: idColumn">
                                {{ row | rowIndex: paginator : isServerRendering : dataSource }}
                            </ng-container>
                            <!-- End Row Index Column -->

                            <!-- Checkbox/Select Column -->
                            <ng-container *ngSwitchCase="column | checkboxColumn" class="tableSelectColumn">
                                <mat-checkbox disableRipple (change)="$event ? ((column.condition && column.condition(row, selectedRows))? checkboxMethod($event, column, row) : null)  : null; ($event && column.method)? checkboxMethod($event, column, row)  : null; "
                                    (change)="onSelect($event, row)" [disabled]="column | disabledColumn: row"
                                    [checked]="selectedRows.isSelected(row)"
                                    >
                                </mat-checkbox>
                            </ng-container>
                            <!-- End Checkbox/Select Column -->

                            <!-- Array Column -->
                            <ng-container *ngSwitchCase="(column | arrayColumn: row) && (!(column | templateColumn))">
                                {{ row[column.index] | arrayToString }}
                            </ng-container>
                            <!-- End Array Column -->

                            <!-- Template Column -->
                            <ng-container *ngSwitchCase="(column | templateColumn)">
                                <span [innerHTML]="(column | renderTemplateColumn: row) | safeHtml"></span>
                            </ng-container>
                            <!-- End Template Column -->

                            <!-- Boolean Column -->
                            <ng-container *ngSwitchCase="column | booleanColumn: row">
                                <mat-slide-toggle [checked]="row[column.index] | checked" disabled="true">
                                </mat-slide-toggle>
                            </ng-container>
                            <!-- End Boolean Column -->

                            <!-- Updatable Boolean Column -->
                            <ng-container *ngSwitchCase="column | updatableBooleanColumn: row">
                                <mat-slide-toggle (change)="column.method? column.method($event.checked? true: false, row) : update($event.checked, row, column)"
                                    [checked]="row[column.index] | checked" [disabled]="column | disabledColumn: row"></mat-slide-toggle>
                            </ng-container>
                            <!-- End Updatable Boolean Column -->

                            <!-- Date Column -->
                            <ng-container *ngSwitchCase="column | dateColumn">
                                {{ row[column.index] | dateFormat: column.dateColumn }}
                            </ng-container>
                            <!-- End Date Column -->

                            <!-- Nested Property Column -->
                            <ng-container *ngSwitchCase="column | nestedPropertyColumn">
                                {{ row | nestedPropertyValue: column.index }}
                            </ng-container>
                            <!-- End Nested Property Column -->

                            <!-- Image Column -->
                            <ng-container *ngSwitchCase="column | imageColumn">
                                <img src="{{ row[column.index] }}" />
                            </ng-container>
                            <!-- End Image Column -->

                            <!-- Action Column / Column With Options -->
                            <ng-container *ngSwitchCase="column | actionColumn">
                                <ng-container *ngFor="let option of column.options">
                                    <ng-container *ngIf="option | columnValueCondition: row">
                                        <button
                                        mat-flat-button
                                        color="accent"
                                        disableRipple
                                        (click)="optionMethod(option, row)"
                                        [disabled]="option | disabledColumn:row"
                                        [ngClass]="(option | optionClass) + ((option | disabledColumn:row)? 'disableButton': '')">
                                            {{ option.title | translate }}
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <!-- End Action Column / Column With Options -->

                            <!-- Default Column -->
                            <ng-container *ngSwitchDefault>
                                {{ (row[column.index]) }}
                            </ng-container>
                            <!-- End Default Column -->

                        </ng-container>
                        <!-- End Print Column Value By Column Type -->
                    </span>
                </ng-container>
                <!-- End Base Column Definition -->

                <!-- Other Template - If False Condition -->
                <ng-template #otherTemplate>
                    <ng-container *ngIf="column | otherTemplateColumn">

                        <!-- Action Column / Column With Options -->
                        <ng-container *ngIf="column.otherTemplate | actionColumn">
                            <ng-container *ngFor="let option of column.otherTemplate.options">
                                <ng-container *ngIf="option | columnValueCondition: row">
                                    <button disableRipple (click)="optionMethod(option, row)" [disabled]="option | disabledColumn:row"
                                    [ngClass]="(option | optionClass) + ((option | disabledColumn:row)? 'disableButton': '')">
                                        {{ option.title | translate }}
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <!-- End Action Column / Column With Options -->

                    </ng-container>
                </ng-template>
                <!-- End Other Template - If False Condition -->

            </mat-cell>
            <!-- End td -->
        </ng-container>

        <!-- Header and Row Definitions -->
        <mat-header-row *matHeaderRowDef="displayedColumnProps"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnProps;" [ngClass]="columns | rowClass:row">
        </mat-row>
        <!-- End Header and Row Definitions -->

    </mat-table>
    <!-- END TABLE -->

    <div class="actionsContainer">
        <div class="actions">

            <!-- Buttons After Table -->
            <ng-container *ngFor="let button of buttons">
                <ng-container *ngIf="button | buttonCondition">
                    <button disableRipple (click)="buttonMethod(button)" color="accent" mat-flat-button [ngClass]="button | buttonClass"
                        [class.delete]="button.title == 'Delete'">
                        <p>{{ button.title | translate }}</p>
                        <!-- TODO: For what is this? Animation? [class.run-yield-managment]="button.title == 'RunYieldManagement'" -->
                    </button>
                </ng-container>
            </ng-container>
            <!-- End Buttons After Table -->

        </div>

        <div class="paginations pageNumber reducedForm">

            <!-- Go To Page List -->
            <mat-form-field appearance="outline">
                <mat-label>{{"Go to page" | translate}}:</mat-label>
                <mat-select [ngModel]="(this.paginator.pageIndex + 1)">
                    <mat-option *ngFor="let num of pages" [value]="num" (click)="goToPage(num)">{{num}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- End Go To Page List -->

            <!-- Pagination -->
            <mat-paginator class="light_blue shortPaginator" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                [length]="totalCount" showFirstLastButtons>
            </mat-paginator>
            <!-- End Pagination -->

        </div>
    </div>
</div>
