export const routingProblems = [
    {
        value: "ptp/dashboard-distribution",
        title: "Point To Point",
        shortcut: "ptp"
    },
    {
        value: "sssp/dashboard-distribution",
        title: "Single Source SP",
        shortcut: "sssp"
    },
    {
        value: "tsp/dashboard-distribution",
        title: "TSP",
        shortcut: "tsp"
    },
    {
        value: "cvrp/dashboard-distribution",
        title: "Multi destinations centers",
        shortcut: "cvrp"
    }
];