import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PROBLEM_TYPES_FILTER } from '../../../../../../config/settings/cms/problem-types-filter';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IBackpackProblemDropdownData, IBackpackProblemFilterForm } from '../../../../interfaces/i-backpack-problem';
import { BackpackProblemService } from '../../../../services/api/backpack-problem.service';
import { BlBackpackProblemFormService } from '../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { BlBackpackProblemContainersTableService } from '../../../../services/table/bl-backpack-problem-containers-table.service';
import { BlBackpackProblemItemsTableService } from '../../../../services/table/bl-backpack-problem-items-table.service';
import { BlBackpackProblemTableService } from '../../../../services/table/bl-backpack-problem-table.service';
import { BackpackHelperFunctionsService } from '../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-view-dialog-backpack-problem',
  templateUrl: './view-dialog-backpack-problem.component.html',
  styleUrls: ['./view-dialog-backpack-problem.component.scss']
})
export class ViewDialogBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: BackpackProblemService,
    public tableService: BlBackpackProblemTableService,
    public dataService: BlBackpackProblemDataService,
    private requestService: BlBackpackProblemRequestsService,
    public formService: BlBackpackProblemFormService,
    protected matDialog: MatDialog,
    private ref : MatDialogRef<ViewDialogBackpackProblemComponent>,
    private tableitemservice: BlBackpackProblemItemsTableService,
    private tablecontainerservice: BlBackpackProblemContainersTableService,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  public clickedButton: boolean = false;
  public isOptionSelected: boolean = false;
  public isLiteratureProblem: boolean = false;
  public form: FormGroupTypeSafe<IBackpackProblemFilterForm> = this.formService.getFilterFormReference();

  public dropdownData: IBackpackProblemDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    Methods: null
  }

  ngOnInit(): void {
    this.dropdownData.ProblemTypes = PROBLEM_TYPES_FILTER;
    this.form.getSafe(x => x.problemTypes).valueChanges.subscribe({
      next: data => {
        if (data != "" || data != null) {
          this.fillTable(data);
          this.isOptionSelected = true;
          if(data == "l") {
            this.isLiteratureProblem = true;
          } else if(data == "c"){
            this.isLiteratureProblem = false;
          }
        } else {
          this.isOptionSelected = false;
        }

      }
    });

    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        class: 'flex-76',
        options: [
          {
            title: 'View',
            method: (row) => {
              this.dataService.setIdForPatch(row['id'] ? row['id'] : 777);
              this.formService.isRunProblemForTitle = false;
              this.formService.problemTitle = "";
              this.formService.resetAlgorithms();
              // this.resetView();
              this.showDataFromService(row);

              this.dataService.show3dTable = false;
              this.dataService.selectedIndex = 0;
            },
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close(false);
          },
        },
      ])
    );
  }

  fillTable(value: any) {
    let type: any = value;
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType(type).subscribe({
        next: success => {
          SpinnerFunctions.hideSpinner();
          this.dataService.setStorage(success);
        },
        error: error => {
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }

  refreshPage() {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType("l").subscribe({
        next: data => {
          this.dataService.setStorage(data);
          SpinnerFunctions.hideSpinner();
        }
      })
    );
    this.formService.formFilter.getSafe(x => x.problemTypes).setValue("l", { emitEvent: false });
  }

  // resetView() {
  //   setTimeout(() => {
  //     this.dataService.setView(false);
  //   }, 1000);
  // }

  showDataFromService(row: any) {
    this.clickedButton = true;
    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.requestService.getBackpackProblem(row.id).subscribe({
          next: (data: any) => {
            if (data) {
              this.backpackHelperFunctionsService.breakApartStavke(data);
              this.clickedButton = false;
              this.dataService.originalObject.next(data);

              this.formService.reducedItemsArray = data[0]? data[0].items: data["items"];
              this.formService.reducedContainersArray = data[0]? data[0].containers: data["containers"];
              this.dataService.showDashboard = true;
            }
            SpinnerFunctions.hideSpinner();
            this.close(true);
          }
        })
      );
    }
  }

  close(state : boolean) {
    this.ref.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataService.setStorage([]);
    this.formService.resetFilter();
    this.isLiteratureProblem = false;
    this.isOptionSelected = false;
  }

}
