import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BlPfspDataService } from '../../../../services/shared/bl-pfsp-data.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BlPfspSaveAsFormService } from '../../../../services/form/bl-pfsp-save-as-form.service';
import { IPfspSaveAsForm } from '../../../../interfaces/i-pfsp';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-save-as-dialog-dashboard-pfsp',
  templateUrl: './save-as-dialog-dashboard-pfsp.component.html',
  styleUrls: ['./save-as-dialog-dashboard-pfsp.component.scss']
})
export class SaveAsDialogDashboardPfspComponent implements OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    public dataService: BlPfspDataService,
    public dialogRef: MatDialogRef<SaveAsDialogDashboardPfspComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: BlPfspSaveAsFormService,
    private translateService: TranslateService
  ) { }

  public form: FormGroupTypeSafe<IPfspSaveAsForm> = this.formService.getFormReference();
  public html: string = `${this.translateService.instant("New PFSP derived from")} ${this.dataService.originalObject.getValue() ? (this.dataService.originalObject.getValue()[0] ? this.dataService.originalObject.getValue()[0].title : this.dataService.originalObject.getValue().title) : this.data.title}`

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  complete() {
    this.dialogRef.close(this.form.getSafe(x => x.title).value);
  }

  ngOnDestroy() {
    this.formService.reset();
  }

}
