import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CellFormationProblemModule } from './cell-formation-problem/cell-formation-problem.module';

const routes: Routes = [
  {
    path: "cell-formation-problem",
    loadChildren: () => CellFormationProblemModule
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CmsRoutingModule { }
