import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BlDeterministicMrpDataService } from '../../services/shared/bl-deterministic-mrp-data.service';
import { BlDeterministicMrpFormService } from '../../services/form/bl-deterministic-mrp-form.service';
import { LayoutSharedService } from '../../../../layout/layout-shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-deterministic-mrp',
  templateUrl: './dashboard-deterministic-mrp.component.html',
  styleUrls: ['./dashboard-deterministic-mrp.component.scss']
})
export class DashboardDeterministicMrpComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private dataService: BlDeterministicMrpDataService,
    public formService: BlDeterministicMrpFormService,
    private sharedService: LayoutSharedService
  ) { }

  private subscription = new Subscription();
  public isBackgroundImage: boolean;


  ngAfterViewInit(): void {
    this.dataService.storageIsTabsView.subscribe({
      next: (data) => {
        this.formService.isShowMainTabs = data;
      }
    });

  }

  ngOnInit(): void {
    this.trackIsBackgroundImage();
  }

  trackIsBackgroundImage() {
    this.subscription.add(
      this.sharedService.backgroundImageState.subscribe({
        next: (data: boolean) => (this.isBackgroundImage = data)
      })
    );
  }

  ngOnDestroy(): void {
    this.dataService.storageIsTabsView.next(false);
    this.dataService.storageDataIsShowOtherTabs.next(false);
    this.dataService.storageDataisFromViewDialog.next(false);
    this.dataService.storageDataFromFormDialog.next(null);
    this.dataService.storageDataViewDialog.next(null);
    this.dataService.storageDataNewForm.next(null);
    this.dataService.storageNewDataTab2.next(null);
    this.dataService.storageViewDataTab2.next(null);
    this.dataService.storageIsTreeView.next(false);
    this.dataService.storageNewDataTab3.next(null);
    this.dataService.storageViewDataTab3.next(null);
    this.dataService.storageNewDataTab4.next(null);
    this.dataService.storageViewDataTab4.next(null);
    this.dataService.storageNewDataTab5.next(null);
    this.dataService.storageViewDataTab5.next(null);
    this.sharedService.backgroundImageState.next(true);
  }
}
