
<div class="map-dashboard-wrapper">

  <div class="controls">
      <button mat-flat-button color="primary" class="custom-flat-btn"
      [disabled]="routes.length == allRoutes.length" (click)="addNext()">
      {{"Next" | translate}}
    </button>
      <button mat-flat-button color="warn" class="custom-flat-btn"
        [disabled]="routes.length == 0" (click)="removeLast()">
        {{"Previous" | translate}}
      </button>
  </div>

  <div class="map map-run">
      <app-leaflet-map
        #leafletMap
        [routes]="routes"
        [clickEvent]="false"
        [getUsersLocationOnInit]="false"
        [invalidateSize]="this.dataService.triggerInvalidateSizeOnMap"
        [zoomIn]="zoomIn">
      </app-leaflet-map>
  </div>
</div>

