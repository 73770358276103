import { Injectable } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private authService: AuthService) { }

  havePermission(claim: string, child?: string) {
    return child ? this.authService.getUserRoles(claim, child) : this.authService.getUserRoles(claim);
  }
}
