import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PROBLEM_TYPES_FILTER } from '../../../../../../config/settings/cms/problem-types-filter';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { GraphNodesService } from '../../../../services/api/graph-nodes.service';
import { BlGraphNodesDataService } from '../../../../services/shared/bl-graph-nodes-data.service';
import { BlGraphNodesTableService } from '../../../../services/table/bl-graph-nodes-table.service';

@Component({
  selector: 'app-view-dialog-graph-nodes',
  templateUrl: './view-dialog-graph-nodes.component.html',
  styleUrls: ['./view-dialog-graph-nodes.component.scss']
})
export class ViewDialogGraphNodesComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: GraphNodesService,
    public tableService: BlGraphNodesTableService,
    public dataService: BlGraphNodesDataService,
    protected matDialog: MatDialog
  ) { }

  private subscription: Subscription = new Subscription();
  clickedButton: boolean = false;
  isBackgroundImage: boolean;
  isProblemSelected: boolean = false;
  problemTypes = PROBLEM_TYPES_FILTER;

  ngOnInit(): void {
    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        options: [
          {
            title: 'View',
            class: 'mat-flat-button ',
            method: (row) => {
              this.showDataFromService(row);
            }
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close();
          }
        }
      ])
    );
  }

  showDataFromService(row): void {
    this.clickedButton = true;

    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.apiService.get(row.id).subscribe({
          next: (data) => {
            if (data) {
              this.dataService.currentObjBS.next(data);
              this.dataService.currentObj = data;

              this.dataService.isCustom = this.apiService.selectedProblemType == "c";
              this.clickedButton = false;
              this.dataService.showContent = true;
            }

            SpinnerFunctions.hideSpinner();
            this.close();
          },
          error: error => {
            SpinnerFunctions.hideSpinner();
          }
        })
      );
    }
  }

  changeProblemType(event): void {
    this.apiService.selectedProblemType = event.value;
    SpinnerFunctions.showSpinner();
    this.apiService.getAllCustomLiteratureHelper().subscribe({
      next: data => {
        this.dataService.setStorage(data);
        this.isProblemSelected = true;
        SpinnerFunctions.hideSpinner();
      }, 
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    });
  }

  close(): void {
    this.matDialog.closeAll();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}