<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
    </div>
  </div>
</div>

<form class="custom-form" [formGroup]="formService.formConfig" (ngSubmit)="save()">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{"Add" | translate}}&nbsp; {{"3DPacking" | translate}}
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 input-methods">
        <label class="sub-title">{{"Input Methods" | translate}}</label>
        <mat-radio-group [formControlName]="formConfig.keys.inputMethod"
          color="primary">
          @for (data of dropdownData.Methods; track data) {
            <mat-radio-button [value]="data.id">{{data.title | translate}}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
    </div>
    <div class="row" [ngClass]="!formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-sm-12 row-col-title">
        <mat-form-field color="primary" appearance="outline" [ngClass]="formService.formConfig.get('title').errors ? 'requiredField' : ''">
          <mat-label>{{"Problem Title" | translate}}</mat-label>
          <input [formControlName]="formConfig.keys.title" matInput autocomplete="off" />
          <mat-error class="customError">
            @if (formService.formConfig.get('title').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
            @if (formService.formConfig.get('title').hasError('noWhiteSpace')) {
              {{"NoWhiteSpace" | translate}}
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-md-12 inputfile-wrapper">
        <app-common-file-upload (onFileChanged)="onFileChanged($event)" [allowedFileTypes]="allowedFileTypes"></app-common-file-upload>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" type="submit" [disabled]="(!this.formConfig.valid) && formService.isImportMethod == false">{{'Apply' | translate}}</button>
    <button mat-flat-button type="button" color="warn" (click)="close()">{{'Close' | translate}}</button>
  </div>
</form>
