import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { BlTableLogicService } from '../../../../shared/bussiness-logic/base/bl-table-logic.service';
import { TABLE_APIS } from '../../../../shared/enums/table-apis';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { ISetOfColumns } from '../../../../shared/interfaces/i-set-of-columns';
import { ITableColumn } from '../../../../shared/interfaces/i-table-column';
import { IBackpackProblem } from '../../interfaces/i-backpack-problem';

@Injectable({
  providedIn: 'root'
})
export class BlBackpackProblemTableService extends BlTableLogicService<IBackpackProblem>{

  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(translateService, tableFunctions, matDialog, location);
  }

  public idColumn = "";
  public apis = [TABLE_APIS.Delete, TABLE_APIS.Update];

  protected columns: ITableColumn[] = [
    {
      index: "id",
      title: "#",
      class: "flex-56",
      hide: false
    },
    {
      index: "size",
      title: "Size",
      class: "flex-68",
      hide: false
    },
    {
      index: "title",
      title: "Problem Title",
      class: "text-left",
      template: {
        view: (params: any[]) => {
          if(params[0].includes('<br/>')){

            let data = params[0].split('<br/>');
            let template=`<ul class="pfsp-table-list">`;

            data.forEach(item=>{
              template += `<li>${item}</li>`
            })

            template +=`</ul>`

            return template
          }else{
            return params[0]
          }
        },
        params: ["title"]
      },
      hide: false
    }
  ];

  protected columnsStorage: BehaviorSubject<ISetOfColumns> = new BehaviorSubject<ISetOfColumns>({
    columns: this.columns,
    displayedColumns: this.displayedColumns
  });

  protected displayedColumns: string[] = this.columns.map(x => x.index);

}
