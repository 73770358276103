import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'templateColumn'
})
export class TemplateColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.hasOwnProperty('template') && column.template != null;
  }

}
