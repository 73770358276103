import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BlCellFormationProblemDataService } from '../../../../../../services/shared/bl-cell-formation-problem-data.service';
import { BlCellFormationProblemFormService } from '../../../../../../services/form/bl-cell-formation-problem-form.service';

@Component({
  selector: 'app-tab-dashboard-input-data',
  templateUrl: './tab-dashboard-input-data.component.html',
  styleUrls: ['./tab-dashboard-input-data.component.scss']
})
export class TabDashboardInputDataComponent implements OnInit, AfterViewInit {

  constructor(
    private dataService: BlCellFormationProblemDataService,
    public formService: BlCellFormationProblemFormService
  ) { }

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        this.formService.isShowOtherTabs = data;
      }
    })
  }

  ngOnInit(): void {
  }

}
