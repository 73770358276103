import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { IBackpackProblemDropdownData } from '../../../../../../interfaces/i-backpack-problem';
import { BlBackpackProblemFormService } from '../../../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../../../services/shared/bl-backpack-problem-data.service';
import { BlBackpackProblemContainersTableService } from '../../../../../../services/table/bl-backpack-problem-containers-table.service';
import { EditContainersDialogBackpackProblemComponent } from '../../../edit-containers-dialog-backpack-problem/edit-containers-dialog-backpack-problem.component';
import { BackpackHelperFunctionsService } from '../../../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-left-table-backpack-problem',
  templateUrl: './left-table-backpack-problem.component.html',
  styleUrls: ['./left-table-backpack-problem.component.scss']
})
export class LeftTableBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlBackpackProblemDataService,
    public formService: BlBackpackProblemFormService,
    public tableService: BlBackpackProblemContainersTableService,
    private alertService: AlertMessageDataService,
    private requestService: BlBackpackProblemRequestsService,
    private matDialog: MatDialog,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public dataForTable: any = [];
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;
  public isProblemLiterature: boolean = false;

  public dropdownData: IBackpackProblemDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    Methods: null
  }

  ngAfterViewInit(): void {
    this.dataService.originalObject.subscribe({
      next: data => {
        this.formService.isProblemLoad = !!data;

        if(data) {
          this.dataFromStorageForSend = data;
          if(this.dataFromStorageForSend.problemType == 'L')
            this.isProblemLiterature = true;
          else
            this.isProblemLiterature = false;

          let podaci = data.containers;

          if(podaci)
            this.dataForTable = this.backpackHelperFunctionsService.mapiranjeNizaNizova(podaci);

          this.tableService.createDynamicHeaderColumnsTabl2(data.size);

          }
      }
    });

    this.getAlgorithms();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: data => {
          if(data) {
            this.dataFromStorageForSend = data;
            if(data.id) {
              this.tableService.createDynamicHeaderColumnsTabl2(this.formService.sizes);
              this.dataFromStorage = data;
              let podaci: any = data.containers;
              this.dataForTable = this.backpackHelperFunctionsService.mapiranjeNizaNizova(podaci);

            } else {
              this.formService.sizes = this.dataFromStorageForSend.size;
              this.tableService.createDynamicHeaderColumnsTabl2(this.formService.sizes);

              let prepakovanNizObjekata: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(this.formService.sizes, this.dataService.containerNumberOfColumns);

              this.dataForTable = this.backpackHelperFunctionsService.mapiranjeNizaNizova(prepakovanNizObjekata);
            }

            if(this.dataFromStorageForSend && this.dataFromStorageForSend.problemType == 'L') {
              this.isProblemLiterature = true;
            } else {
              this.isProblemLiterature = false;
            }
          }
        }
      })
    );
  }

  openEditDialog() {
    this.matDialog.open(EditContainersDialogBackpackProblemComponent, {
      width: '450px',
      height: 'auto',
      minHeight: '150px',
      panelClass: 'custom-dialog'
    })
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestService.getAlgorithms().subscribe({
        next: data => {
          if(data) {
            this.dropdownData.Algorithms = data;
            this.formService.algorithmData = data;
            this.formService.form.getSafe(x => x.algorithm).setValue(data[0].id);
          }
        }
      })
    );
  }

  changeAlgorithm(value): void {
    const choosenAlgorithm = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === value);
    if(choosenAlgorithm.parametri) {
      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls((choosenAlgorithm.parametri == null)? {}: choosenAlgorithm.parametri, this.formService.form);
      this.formService.parametri = Object.keys((choosenAlgorithm.parametri == null)? {}: choosenAlgorithm.parametri);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.sizes = 0;
    this.formService.isProblemLoad = false;
    this.clickedButton = false;
    this.dataForTable = [];
    this.dataForSend = [];
    this.dataFromStorage = [];
    this.dataFromStorageForSend = [];
    this.isProblemLiterature = false;
  }

}
