import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Subscription } from 'rxjs';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-tabs-dashboard-distribution',
  templateUrl: './tabs-dashboard-distribution.component.html',
  styleUrls: ['./tabs-dashboard-distribution.component.scss']
})
export class TabsDashboardDistributionComponent implements OnInit, OnDestroy {

  constructor(
    public dataService: BlDistribution1ToNDataService
  ) {}

  private subscription: Subscription = new Subscription();
  selectedIndex = 0;
  @ViewChild("tabsElement", { static: false }) tabsElement: MatTabGroup;

  ngOnInit(): void {
    this.trackWhenCalculate();
  }

  onTabChange(event): void {
    if(event.index === 1 || event.index === 3) {
      this.dataService.triggerInvalidateSizeOnMap = !this.dataService.triggerInvalidateSizeOnMap;
    }
  }

  trackWhenCalculate() {
    this.subscription.add(this.dataService.calculateFinished.subscribe({
      next: value=>{
        if(value) {
          const tabGroup = this.tabsElement;
          if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
          
          if(this.dataService.typeOfMap == 'map')
            tabGroup.selectedIndex = 1;
          else
            tabGroup.selectedIndex = 2;
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
