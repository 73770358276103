import { Injectable } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { ArrayFunctions } from '../functions/array-functions';
import { config } from '../../config/global';
import { apiPaths } from '../../config/api';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlReportsService {

  private numberColumnsToNotRound: string[] = ["folioNo", "quantity", "revenueLabel", "count", "folio_no", "occupancy_adults", "occupancy_children", "countt", "adults", "children", "arrival_Rooms", "departure_Rooms", "occupied_Rooms", "nrRes", "rmNights", "avLos"];

  constructor(
    public authService: AuthService,
    private http: HttpClient
  ) { }

  exportFileToCsv(columnsToPrint: any[], data: any[]) {
    // this.hotelSettings.getStorage().subscribe(datas => {

    let keys = (typeof(columnsToPrint[0]) == 'string') ? columnsToPrint : columnsToPrint.filter(x => x.index != "").map(x => x.index);
    let ths = columnsToPrint.filter(x => x.index != "").map(x => x.title);
    const rowValues = [];

    for (const row of data) {
      const temp = [];
      for (const key of keys) {
        if (row[key] !== null && row[key] !== undefined) {

          if (typeof(row[key]) == 'number' && !ArrayFunctions.inArray(key, this.numberColumnsToNotRound)) {
            // let number = new FormatNumberPipe(this.hotelSettings).transform(row[key], false);
            let number = row[key];
            temp.push(number);
          } else {
            let columnValue = row[key];

            if (typeof(columnValue) == 'string') {
              columnValue = columnValue.replace("xxx", "");
            }
            temp.push(columnValue);
          }
        }
        else {
          temp.push("");
        }

      }
      rowValues.push(temp);
    }
    this.exportToCsv('Report_' + Date.now() + '.csv', [ths, ...rowValues]);
    //   }
    // })
  }

  private exportToCsv(filename, rows) {
    const processRow = function (row) {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null || row[j] === undefined ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }
        if (j > 0) {
          finalVal += ',';
        }
        finalVal += result;
      }
      return finalVal + '\n';
    };

    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    let nav: any = navigator;
    if (nav.msSaveBlob) { // IE 10+
      nav.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

    //bekenddd export to a lot type formats
    exportFile(type: string, dataToSend: any, actionName: string) {
      let extensionType: string = "";
      let paramsToSendType: string = "";
      let responseType: string = "";
      if (type == "csv") {
        extensionType = ".csv";
        paramsToSendType = "csv";
        responseType = "application/CSV";
      } else if (type == "pdf") {
        extensionType = ".pdf";
        paramsToSendType = "pdf";
        responseType = "arraybuffer";
      } else if (type == "word") {
        extensionType = ".doc";
        paramsToSendType = "word";
        responseType = "application/msword";
      } else if (type == "excel") {
        extensionType = ".xlsx";
        paramsToSendType = "excel";
        responseType = "arraybuffer";
      }
      const paramsToSend: any = {
        action: actionName,
        type: paramsToSendType,
        data: dataToSend
      };
      const headers = new HttpHeaders();
      this.http.post(config.baseApiUrl.SERVER + apiPaths.downloadFile, paramsToSend, <any>{ headers, responseType: responseType }).subscribe({
        next: (data: any) => {
          if (type == "pdf") {
            this.save(new Blob([data], { type: "application/pdf;charset=utf-8" }), "Report_" + Date.now() + extensionType);
          }
          else if (type == "excel") {
            this.save(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" }), "Report_" + Date.now() + extensionType);
          }
          else {
            this.save(new Blob(["\uFEFF" + data], { type: responseType + ";charset=utf-8" }), "Report_" + Date.now() + extensionType);
          }
        }
      });

  
    }

    save(blob, fileName) {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  
    //bekenddd export to a lot type formats

}
