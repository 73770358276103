import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { IPfsp } from '../../interfaces/i-pfsp';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../../../../config/api';

@Injectable({
  providedIn: 'root'
})
export class PfspService extends ApiService <IPfsp>{

  constructor(http: HttpClient) {
    super(apiPaths.order.pfsp, http);
  }
}
