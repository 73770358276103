import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ITableColumn } from '../../interfaces/i-table-column';
import { BlTableLogicService } from '../../bussiness-logic/base/bl-table-logic.service';

@Component({
  selector: 'app-show-hide-columns',
  templateUrl: './show-hide-columns.component.html',
  styleUrls: ['./show-hide-columns.component.scss'],
  animations: [
    trigger('showList', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('false <=> true', animate('100ms ease'))
    ])
    // trigger('showList', [
    //   state('true', style({ transform: 'scale(1)', opacity: 1, height: '100%'})),
    //   state('false', style({ transform: 'scale(0)', opacity: 0, height: '0px'})),
    //   transition('false <=> true', animate('800ms ease'))
    // ])
  ]
})
export class ShowHideColumnsComponent implements OnInit {

  @Input() public tableService : BlTableLogicService<any>;

  constructor() { }

  public isVisibleColumnList : boolean=false;

  public columns : ITableColumn[];
  public displayedColumns: string[];
  show: boolean = false;


  ngOnInit() {
    this.tableService.getColumnsStorage().subscribe({
      next: data=> {
        this.displayedColumns = data.displayedColumns;
        this.columns = this.tableService.getToggleableColumns();
      }
    })
  }

  openColumnList(){
    this.isVisibleColumnList=!this.isVisibleColumnList;
  }

  toggleColumn(propertyName, isChecked){
   this.tableService.setIsDisplayedColumn(propertyName, isChecked);
  }

}
