import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { DateAdapter, MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatOptionModule, MatPaginatorModule, MatProgressSpinnerModule, MatRadioModule, MatSelectModule, MatSlideToggleModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material";
import { MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { MatStepperModule } from "@angular/material/stepper";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from "moment";
// import { AngularOpenlayersModule } from 'ngx-openlayers';
import { NgPipesModule } from 'ngx-pipes';
import { ImageUploadComponent } from '../core/components/image-upload/image-upload.component';
import { CompanySettingsService } from '../core/services/company-settings.service';
import { CountryMapper } from "./classes/country-mapper";
import { AlertComponent } from './components/alert/alert.component';
import { BaseFormComponent } from './components/base-form/base-form.component';
import { BaseComponent } from './components/base/base.component';
import { ButtonWithSpinnerComponent } from './components/button-with-spinner/button-with-spinner.component';
import { ChipComponent } from "./components/chip/chip.component";
import { CommonAutocompleteComponent } from './components/common-autocomplete/common-autocomplete.component';
import { CommonDatepickerComponent } from './components/common-datepicker/common-datepicker.component';
import { ExportToCsvComponent } from './components/export-to-csv/export-to-csv.component';
import { GlobalSpinnerComponent } from './components/global-spinner/global-spinner.component';
import { InputWithSpinnerComponent } from './components/input-with-spinner/input-with-spinner.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GeoPositioningService } from './components/osm-view/geo-positioning.service';
// import { OsmViewComponent } from './components/osm-view/osm-view.component';
import { PrintComponent } from "./components/print/print.component";
import { SearchComponent } from "./components/search/search.component";
import { ShowHideColumnsComponent } from "./components/show-hide-columns/show-hide-columns.component";
import { TableFormComponent } from './components/table-form/table-form.component';
import { TableNoRecordsComponent } from "./components/table-no-records/table-no-records.component";
import { TableComponent } from "./components/table/table.component";
import { DropdownDirective } from './directives/dropdown.directive';
import { DynamicTagNameDirective } from "./directives/dynamic-tag-name.directive";
import { IframeAutoHeightDirective } from "./directives/iframe-auto-height.directive";
import { FormFunctions } from "./functions/form-functions";
import { TableFunctions } from "./functions/table-functions";
import { FormBuilderTypeSafe } from "./helpers/reactive-forms-helper";
import { ArrayToStringPipe } from "./pipes/array-to-string.pipe";
import { CheckedPipe } from "./pipes/checked.pipe";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { DateToTimestampPipe } from "./pipes/date-to-timestamp.pipe";
import { GroupByCustomPipe } from "./pipes/group-by-custom.pipe";
import { IndexOfFormElementForRecursiveIdPipe } from './pipes/index-of-form-element-for-recursive-id.pipe';
import { IndexOfFormElementPipe } from "./pipes/index-of-form-element.pipe";
import { IsNumberPipe } from "./pipes/is-number.pipe";
import { NestedPropertyValuePipe } from "./pipes/nested-property-value.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { StringReplaceCommaInNewLinePipe } from './pipes/string-replace-comma-in-new-line';
import { ActionColumnPipe } from "./pipes/table-pipes/action-column.pipe";
import { ArrayColumnPipe } from "./pipes/table-pipes/array-column.pipe";
import { BooleanColumnPipe } from "./pipes/table-pipes/boolean-column.pipe";
import { ButtonClassPipe } from "./pipes/table-pipes/button-class.pipe";
import { ButtonConditionPipe } from "./pipes/table-pipes/button-condition.pipe";
import { CellClassPipe } from "./pipes/table-pipes/cell-class.pipe";
import { CheckboxColumnPipe } from "./pipes/table-pipes/checkbox-column.pipe";
import { ColumnClassPipe } from "./pipes/table-pipes/column-class.pipe";
import { ColumnValueConditionPipe } from "./pipes/table-pipes/column-value-condition.pipe";
import { DateColumnPipe } from "./pipes/table-pipes/date-column.pipe";
import { DisabledColumnPipe } from "./pipes/table-pipes/disabled-column.pipe";
import { DisabledFormButtonPipe } from './pipes/table-pipes/disabled-form-button.pipe';
import { FormControlTypePipe } from './pipes/table-pipes/form-control-type.pipe';
import { FormControlPipe } from './pipes/table-pipes/form-control.pipe';
import { IdColumnPipe } from "./pipes/table-pipes/id-column.pipe";
import { ImageColumnPipe } from "./pipes/table-pipes/image-column.pipe";
import { NestedPropertyColumnPipe } from "./pipes/table-pipes/nested-property-column.pipe";
import { OptionClassPipe } from "./pipes/table-pipes/option-class.pipe";
import { OtherTemplateColumnPipe } from "./pipes/table-pipes/other-template-column.pipe";
import { RenderTemplateColumnPipe } from "./pipes/table-pipes/render-template-column.pipe";
import { RowClassPipe } from './pipes/table-pipes/row-class.pipe';
import { RowIndexPipe } from "./pipes/table-pipes/row-index.pipe";
import { SortableColumnPipe } from "./pipes/table-pipes/sortable-column.pipe";
import { StringToListPipe } from './pipes/table-pipes/string-to-list.pipe';
import { TemplateColumnPipe } from "./pipes/table-pipes/template-column.pipe";
import { UpdatableBooleanColumnPipe } from "./pipes/table-pipes/updatable-boolean-column.pipe";
import { ValuesPipe } from './pipes/values.pipe';
import { VMatSelectComponent } from './virtual-scroll-components/v-mat-select/v-mat-select.component';
import { VirtualScrollRowIndexPipe } from './pipes/virtual-scroll-row-index.pipe';
import { VCommonSelectComponent } from './components/v-common-select/v-common-select.component';
import { VCommonMultiselectComponent } from './components/v-common-multiselect/v-common-multiselect.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// imports for angular material 10
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter } from '@angular/material/core';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletMapComponent } from "./components/leaflet-map/leaflet-map.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MarkdownComponent } from './components/markdown/markdown.component';
import { CommonFileUploadComponent } from './components/common-file-upload/common-file-upload.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        MatTableModule,
        MatCheckboxModule,
        MatButtonModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatExpansionModule,
        MatIconModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatCardModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatStepperModule,
        RouterModule,
        // AngularOpenlayersModule,
        NgbModule,
        NgPipesModule,
        ScrollingModule,
        NgPipesModule,
        MatPasswordStrengthModule,
        LeafletModule,
        DragDropModule,
        MatRadioModule
    ],
    declarations: [
        PrintComponent,
        SafeHtmlPipe,
        DynamicTagNameDirective,
        DateToTimestampPipe,
        DateFormatPipe,
        SafePipe,
        GroupByCustomPipe,
        IsNumberPipe,
        IndexOfFormElementPipe,
        ChipComponent,
        ShowHideColumnsComponent,
        TableComponent,
        TableNoRecordsComponent,
        SortableColumnPipe,
        ColumnClassPipe,
        IdColumnPipe,
        RowIndexPipe,
        ColumnValueConditionPipe,
        ArrayColumnPipe,
        ArrayToStringPipe,
        TemplateColumnPipe,
        RenderTemplateColumnPipe,
        BooleanColumnPipe,
        UpdatableBooleanColumnPipe,
        DateColumnPipe,
        NestedPropertyColumnPipe,
        NestedPropertyValuePipe,
        ImageColumnPipe,
        DisabledColumnPipe,
        CheckedPipe,
        CheckboxColumnPipe,
        ActionColumnPipe,
        OptionClassPipe,
        CellClassPipe,
        OtherTemplateColumnPipe,
        ButtonConditionPipe,
        ButtonClassPipe,
        SearchComponent,
        BaseComponent,
        IframeAutoHeightDirective,
        ExportToCsvComponent,
        ButtonWithSpinnerComponent,
        AlertComponent,
        ImageUploadComponent,
        CommonAutocompleteComponent,
        GlobalSpinnerComponent,
        StringReplaceCommaInNewLinePipe,
        CommonDatepickerComponent,
        // OsmViewComponent,
        IndexOfFormElementForRecursiveIdPipe,
        InputWithSpinnerComponent,
        NotFoundComponent,
        FormControlPipe,
        FormControlTypePipe,
        TableFormComponent,
        DisabledFormButtonPipe,
        RowClassPipe,
        BaseFormComponent,
        DropdownDirective,
        ValuesPipe,
        StringToListPipe,
        VMatSelectComponent,
        VirtualScrollRowIndexPipe,
        VCommonSelectComponent,
        VCommonMultiselectComponent,
        MatSelectSearchComponent,
        PasswordStrengthComponent,
        LeafletMapComponent,
        MarkdownComponent,
        CommonFileUploadComponent,
    ],
    exports: [
        MatTableModule,
        MatCheckboxModule,
        MatButtonModule,
        MatSortModule,
        MatCardModule,
        MatMenuModule,
        MatListModule,
        MatExpansionModule,
        MatIconModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatDividerModule,
        MatTabsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTreeModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatButtonToggleModule,
        PrintComponent,
        IframeAutoHeightDirective,
        DateToTimestampPipe,
        SafeHtmlPipe,
        DateFormatPipe,
        SafePipe,
        GroupByCustomPipe,
        IsNumberPipe,
        IndexOfFormElementPipe,
        IndexOfFormElementForRecursiveIdPipe,
        ChipComponent,
        ShowHideColumnsComponent,
        TableComponent,
        SearchComponent,
        BaseComponent,
        ExportToCsvComponent,
        TranslateModule,
        RouterModule,
        ButtonWithSpinnerComponent,
        AlertComponent,
        ImageUploadComponent,
        CommonAutocompleteComponent,
        MatToolbarModule,
        StringReplaceCommaInNewLinePipe,
        CommonDatepickerComponent,
        GlobalSpinnerComponent,
        // OsmViewComponent,
        NgbModule,
        NgPipesModule,
        InputWithSpinnerComponent,
        NotFoundComponent,
        FormControlPipe,
        FormControlTypePipe,
        TableFormComponent,
        DisabledFormButtonPipe,
        BaseFormComponent,
        DropdownDirective,
        ValuesPipe,
        StringToListPipe,
        VMatSelectComponent,
        VirtualScrollRowIndexPipe,
        NgPipesModule,
        VCommonSelectComponent,
        VCommonMultiselectComponent,
        ScrollingModule,
        MatSelectSearchComponent,
        PasswordStrengthComponent,
        MatPasswordStrengthModule,
        NgxChartsModule,
        LeafletMapComponent,
        DragDropModule,
        CommonFileUploadComponent,
    ],
    providers: [
        TableFunctions,
        FormBuilderTypeSafe,
        FormFunctions,
        CountryMapper,
        GeoPositioningService,
        { provide: "apiUrl", useValue: "api_url" },
        { provide: MAT_DATE_LOCALE, useValue: null },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        // { provide: DateAdapter, useClass: MomentUtcDateAdapter }
    ]
})

export class SharedModule {
  constructor(
    private adapter: DateAdapter<any>,
    private companyService: CompanySettingsService,
    public countryMapper: CountryMapper,
  ) {

    this.adapter.setLocale("en-gb");
    this.companyService.getStorage().subscribe({
      next: data => {
        if (data) {
          if (data.country_id === "231") {
            moment.locale("en-gb");
          } else {
            let countryAbbr = this.countryMapper.getCountryById(data.country_id);
            this.adapter.setLocale(countryAbbr);
            moment.locale(countryAbbr);
          }
        }
      }
    });
  }
}
