
<div class="left-sidebar">

        <app-left-sidebar-dashboard-distribution-source (openSourceModal)="openModal($event)">
        </app-left-sidebar-dashboard-distribution-source>

        <app-left-sidebar-dashboard-distribution-destinations (openDestinationModal)="openModal($event)">
        </app-left-sidebar-dashboard-distribution-destinations>

        <app-left-sidebar-dashboard-distribution-additional-fields>
        </app-left-sidebar-dashboard-distribution-additional-fields>

</div>
