import { Injectable } from '@angular/core';
import { IMrpSpecification } from '../../interfaces/i-mrp-specification';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { DeterministicMrpService } from '../api/deterministic-mrp.service';
import { BlDeterministicMrpRequestsService } from '../requests/bl-deterministic-mrp-requests.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BlMrpSpecifiactionDialogDataService extends BlDataLogicService implements ITableStorage<IMrpSpecification>{

  constructor(
    private apiService: DeterministicMrpService,
    private requestService: BlDeterministicMrpRequestsService
  ) {
    super();
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  storage: BehaviorSubject<IMrpSpecification[]> = new BehaviorSubject<IMrpSpecification[]>([]);

  originalData: IMrpSpecification[];

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;
  
  public isAddNewDataAgain: boolean = false;

  getOriginalData(): IMrpSpecification[] {
    return this.originalData;
  }

  setOriginalData(items: IMrpSpecification[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IMrpSpecification[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IMrpSpecification[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IMrpSpecification[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IMrpSpecification[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }
}
