import { Injectable } from '@angular/core';
import { IFormService } from '../../../shared/bussiness-logic/interfaces/i-form-service';
import { IRegisterForm } from '../../interfaces/i-register';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { BlRegisterRequestsService } from '../requests/bl-register-requests.service';
import { Validators } from '@angular/forms';
import { whitespaceValidator } from '../../../shared/validators/whitespace.validator';

@Injectable({
  providedIn: 'root'
})
export class BlRegisterFormService implements IFormService<IRegisterForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private requestsService: BlRegisterRequestsService
  ) {}

  form: FormGroupTypeSafe<IRegisterForm> = this.init();
  public strength: number = 0;


  init(): FormGroupTypeSafe<IRegisterForm> {
    return this.formBuilder.group<IRegisterForm>({
      username: this.formBuilder.control("", [Validators.required, whitespaceValidator, Validators.email]),
      password: this.formBuilder.control("", Validators.required),
      passwordConfirm: this.formBuilder.control("", Validators.required),
    });
  }

  getFormReference(): FormGroupTypeSafe<IRegisterForm> {
    return this.form;
  }

  prepareDataToSend(): any {
    let formValue: IRegisterForm = this.form.value;

    let dataToSend: any = [{
      username: formValue.username,
      password: formValue.password
    }];

    return dataToSend;
  }

  passwordValidation(): void {
    const password = this.form.get('password').value;
    let confirmPasswordValue = this.form.get('passwordConfirm').value;
    
    if (password !== confirmPasswordValue) {
        this.form.get('passwordConfirm').setErrors( {MatchPassword: true} );
    } else {
      this.form.get('passwordConfirm').setErrors(null);
    }
  }

  changeCurrentPass(): void {
    this.passwordValidation();

    if(this.strength < 100)
      this.form.get("password").setErrors({'validatePass': true});
    else
      return this.form.get("password").setErrors(null);
  }

  passStrengthChanged(strength): void {
    this.strength = strength;
  }

  validatePass(): {} {
    if(this.strength < 100) 
     return {'validatePass': true};
    else
      return null;
  }

  submitInsert() {
    let dataToSend: any = this.prepareDataToSend();
    return this.requestsService.registerUser(dataToSend);
  }

  reset(): void {
    this.form = this.init();
  }
}
