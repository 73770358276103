<div class="dashboard-content">
    <div class="row">
        <div class="col-md-12 tablet-padding-right">
            <mat-tab-group dynamicHeight="true" class="routingMapTab">
                <mat-tab label="{{'Routing' | translate}}">
                  <ng-template matTabContent>
                    <app-routing-admin-config></app-routing-admin-config>
                  </ng-template>
                </mat-tab>
                <mat-tab label="{{'ShowAreaOnMap' | translate}}">
                  <ng-template matTabContent>
                    <app-show-area-on-map></app-show-area-on-map>
                  </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
