import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../../core/guards/role.guard';
import { DashboardBackpackProblemComponent } from './components/dashboard-backpack-problem/dashboard-backpack-problem.component';

const routes: Routes = [
  {
    path: "",
    component: DashboardBackpackProblemComponent,
    canActivate: [RoleGuard],
    data: {section: "packing", child: "dashboard-backpack-problem" }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackpackProblemRoutingModule { }
