import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { GeoPositioningService } from '../../../../shared/components/osm-view/geo-positioning.service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { onlyIntegerValidator } from '../../../../shared/validators/only-integer.validator';
import { IDistribution1ToN, IDistribution1ToNForm, IDistribution1ToNRequest } from '../../interfaces/i-distribution-1-to-n';
import { Distribution1ToNService } from '../api/distribution-1-to-n.service';
import { BlDistribution1ToNRequestsService } from '../requests/bl-distribution-1-to-n-requests.service';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNFormService implements IFormService<IDistribution1ToNForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private matDialog: MatDialog,
    private requestsService: BlDistribution1ToNRequestsService,
    private dataService: BlDistribution1ToNDataService,
    private apiService: Distribution1ToNService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNForm> = this.init();
  originalObj: IDistribution1ToN;

  init(): FormGroupTypeSafe<IDistribution1ToNForm> {
    return this.formBuilder.group<IDistribution1ToNForm>({
      latitude: this.formBuilder.control("", Validators.required),
      longitude: this.formBuilder.control("", Validators.required),
      location: this.formBuilder.control("mastercard"),
      quantity: this.formBuilder.control(null, onlyIntegerValidator)
    });
  }

  setLocation(location: string): void {
    this.form.getSafe(x => x.location).setValue(location);
  }

  setLatitude(latitude: number): void {
    latitude = latitude ? latitude : 0;
    this.form.getSafe(x => x.latitude).setValue(latitude);
  }

  setLongitude(longitude: number): void {
    longitude = longitude ? longitude : 0;
    this.form.getSafe(x => x.longitude).setValue(longitude);
  }

  setQuantity(quantity: number): void {
    this.form.getSafe(x => x.quantity).setValue(quantity);
  }

  setLongitudeAndLatitude(longitude: number, latitude: number, emitEvent: boolean = true) {
    this.form.getSafe(x => x.latitude).setValue(latitude ? latitude : 0, {emitEvent: emitEvent});
    this.form.getSafe(x => x.longitude).setValue(longitude ? longitude : 0, {emitEvent: emitEvent});
  }

  fillForm(id: number, isDestination: boolean): void {
    if(isDestination) {
      this.setLatitude(this.dataService.allDestinations[id][0]);
      this.setLongitude(this.dataService.allDestinations[id][1]);
      this.setQuantity(this.dataService.destinationsQuantity != null ? this.dataService.destinationsQuantity[id] : null);
    }
    else {
      this.setLatitude(this.dataService.source[0]);
      this.setLongitude(this.dataService.source[1]);
    }
  }

  prepareDataToSend(): IDistribution1ToNRequest {
    let formValue: IDistribution1ToNForm = this.form.getRawValue();

    let dataToSend: IDistribution1ToNRequest = {
      latitude: (formValue.latitude != null) ? parseFloat(formValue.latitude.toString()) : 44.797819,
      longitude: (formValue.longitude != null) ? parseFloat(formValue.longitude.toString()) : 20.450818,
      location: formValue.location,
    };

    return dataToSend;
  }

  submitUpdate(id: number): Observable<any> {
    let dataToSend: IDistribution1ToNRequest = this.prepareDataToSend();
    return this.requestsService.updateCustomRefill(id, dataToSend, this.originalObj, "Partner", this.apiService, this.dataService);
  }

  submitInsert(): Observable<any> {
    let dataToSend: IDistribution1ToNRequest = this.prepareDataToSend();
    return this.requestsService.insertCustomRefill(dataToSend, "Partner", this.apiService, this.dataService);
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNForm> {
    return this.form;
  }

  close(): void {
    this.matDialog.closeAll();
  }

  reset(): void {
    this.form = this.init();
  }

}