<style>
    @media print {
        html,
        body {
            height: 99%;
        }
        #reportButton,
        #allTabs {
            display: none;
        }
    }
</style>
<div class="row">
    <div class="col-md-12 header-button-box">
        <button (click)="printReport()" id="reportButton" mat-stroked-button color="accent">
            <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
        </button>
    </div>
</div>

<div class="row">
    <ng-container *ngIf="isShowTables == true; else noData;">
        <div class="col-md-12">
            <mat-tab-group dynamicHeight="true" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)">
                <mat-tab label="{{'MRP Report' | translate}}">
                    <app-tab-dashboard-mrp-report></app-tab-dashboard-mrp-report>
                </mat-tab>
                <mat-tab label="{{'Action List' | translate}}">
                    <app-tab-dashboard-action-list></app-tab-dashboard-action-list>
                </mat-tab>
                <mat-tab label="{{'Indented BOM' | translate}}">
                    <app-tab-dashboard-indented-bom></app-tab-dashboard-indented-bom>
                </mat-tab>
                <mat-tab label="{{'Cost Analysis' | translate}}">
                    <app-tab-dashboard-cost-analysis></app-tab-dashboard-cost-analysis>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ng-container>
    <ng-template #noData>
        <div class="col-md-12">
            <div class="alert alert-warning" role="alert">
                {{"NoRecordsFound" | translate}}
            </div>
        </div>
    </ng-template>
</div>