<!-- Table MRP Report Table Tab -->
<div class="row">
    <div class="col-md-12 tab-wrapper">
        <ng-container *ngIf="dataFromService.mrpReport.length > 0; else noAllTableData;">
            <ng-container *ngFor="let data of dataFromService.mrpReport; let i = index;">
                <div class="row">
                    <div class="col-sm-12 table-header">
                        <p class="table-title" [innerHTML]="data.nazivTabele"></p>
                        <p class="table-title" [innerHTML]="data.opis"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 table-wrapper">
                        <table class="table table-sm">
                            <thead class="thead-dark">
                                <tr>
                                    <th>&nbsp;</th>
                                    <th *ngFor="let header of data.table.tableHeader">{{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tableRow of data.table.tableBody">
                                    <ng-container *ngFor="let columns of tableRow.stavke">
                                            <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                                                {{columns.value}}
                                            </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #noAllTableData>
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                        {{"NoRecordsFound" | translate}}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<!-- End MRP Report Table Tab -->