export const config = {
    env: "DEV",
    baseApiUrl: {
      //    "DEV" : "http://localhost:4200/assets/jsons",
        "DEV": "/assets/jsons",
      "SERVER": "https://back.opr-man.net/",
        "AWS_IMG": "https://s3.eu-central-1.amazonaws.com/storage-opr-man.net/",
     //   "SERVER": "https://localhost:44365/"
    },
    autoLogoutTime: 15 * 60 * 1000, // 15 * 60 * 1000, // 15 mins activity
    stayLoggedInPeriod: 3 * 60 * 1000, // 3 * 60 * 1000, // 3 mins stopwatch to extend time
    alertMessagesPresentTime: 5000
}
