<div class="row">
  <div class="col-md-12">
    <span class="tab-subtitle">{{title}}</span>
  </div>
</div>
<div class="row extended-width">
  <div class="col-md-12 table-wrapper">
    @if (dataFromStorage?.rowsTab3 && dataFromStorage?.rowsTab3.table.tableData.length > 0) {
      <table class="table table-sm table-dynamic">
        <thead class="thead-dark">
          <tr>
            <th>&nbsp;</th>
            @for (header of dataFromStorage?.rowsTab3.table.tableHeader; track header) {
              <th>{{header}}</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (tableRow of dataFromStorage?.rowsTab3.table.tableData; track tableRow; let i = $index) {
            <tr>
              <td>{{i+1}}</td>
              @for (columns of tableRow.stavke; track columns) {
                <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                  {{columns.value}}
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-warning" role="alert">
            {{"NoRecordsFound" | translate}}
          </div>
        </div>
      </div>
    }
  </div>
</div>
