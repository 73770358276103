import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-dashboard-reductions',
  templateUrl: './tab-dashboard-reductions.component.html',
  styleUrls: ['./tab-dashboard-reductions.component.scss']
})
export class TabDashboardReductionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
