import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeterministicMrpService } from '../../../../services/api/deterministic-mrp.service';
import { BlMrpSpecifiactionDialogDataService } from '../../../../services/shared/bl-mrp-specifiaction-dialog-data.service';
import { BlMrpSpecificationDialogTableService } from '../../../../services/table/bl-mrp-specification-dialog-table.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { BlDeterministicMrpRequestsService } from '../../../../services/requests/bl-deterministic-mrp-requests.service';
import { BlDeterministicMrpDataService } from '../../../../services/shared/bl-deterministic-mrp-data.service';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { LayoutSharedService } from '../../../../../../layout/layout-shared.service';

@Component({
  selector: 'app-view-dialog-dashboard-deterministic-mrp',
  templateUrl: './view-dialog-dashboard-deterministic-mrp.component.html',
  styleUrls: ['./view-dialog-dashboard-deterministic-mrp.component.scss'],
})
export class ViewDialogDashboardDeterministicMrpComponen
  implements OnInit, OnDestroy {
  constructor(
    public apiService: DeterministicMrpService,
    public tableService: BlMrpSpecificationDialogTableService,
    public dataService: BlMrpSpecifiactionDialogDataService,
    public deterministicDataService: BlDeterministicMrpDataService,
    private requestService: BlDeterministicMrpRequestsService,
    protected matDialog: MatDialog,
    private mrpDataService: BlDeterministicMrpDataService,
    private sharedService: LayoutSharedService,
    public ref : MatDialogRef<ViewDialogDashboardDeterministicMrpComponen>
  ) {}

  private subscription: Subscription = new Subscription();
  public clickedButton: boolean = false;

  public isBackgroundImage: boolean;

  ngOnInit(): void {
    this.trackIsBackgroundImage();
    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        class: "flex-76",
        options: [
          {
            title: 'View',
            class: 'mat-flat-button ',
            method: (row) => {
              this.deterministicDataService.storageDataViewDialog.next(null);
              this.deterministicDataService.storageDataViewForm.next(null);
              this.resetView();
              this.showDataFromService(row);
              this.deterministicDataService.setIdForPatch(row.IdProblem);
              this.deterministicDataService.currentId = row.IdProblem;
              this.deterministicDataService.currentTitle = row.Title;

              this.deterministicDataService.requestedResults = false;
              this.changeBackgroundImage();
            },
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close(false);
          },
        },
      ])
    );
  }

  resetView(){
    setTimeout(() => {
      this.mrpDataService.setView(false);
    }, 1000);
  }

  showDataFromService(row) {
    this.clickedButton = true;
    // this.deterministicDataService.storageIsTabsView.next(true);
    // this.deterministicDataService.storageDataIsShowOtherTabs.next(true);
    this.deterministicDataService.storageDataisFromViewDialog.next(true);
    this.deterministicDataService.storageIsTreeView.next(false);

    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.requestService.getDeterministicMrp(row.IdProblem).subscribe({
          next: (data) => {
            if (data) {

              this.deterministicDataService.storageIsTabsView.next(true);
              this.deterministicDataService.storageDataIsShowOtherTabs.next(true);

              this.breakApartStavke(data);
              this.clickedButton = false;
              this.deterministicDataService.storageDataViewDialog.next(data);
              this.deterministicDataService.storageDataViewForm.next(data);
            }
            SpinnerFunctions.hideSpinner();
            this.close(true);
          }
        })
      );
    }
  }

  breakApartStavke(data: any) {
    data.rowsTab2.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl? ctrl: '';
      });
      delete row.stavke;
    });
    data.rowsTab3.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl ? ctrl : '';
      });
      delete row.stavke;
    });
    data.rowsTab4.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl ? ctrl : '';
      });
      delete row.stavke;
    });
    data.rowsTab5.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl ? ctrl : '';
      });
      delete row.stavke;
    });
  }

  trackIsBackgroundImage() {
    this.subscription.add(
      this.sharedService.backgroundImageState.subscribe({
        next: (data: boolean) => (this.isBackgroundImage = data)
      })
    );
  }

  changeBackgroundImage() {
    this.sharedService.backgroundImageState.next(false);
  }

  close(state : boolean) {
    this.ref.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
