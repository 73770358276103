import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BlCellFormationProblemDataService } from '../../../../services/shared/bl-cell-formation-problem-data.service';
import { BlCellFormationProblemFormService } from '../../../../services/form/bl-cell-formation-problem-form.service';

@Component({
  selector: 'app-tabs-dashboard-cell-formation-problem',
  templateUrl: './tabs-dashboard-cell-formation-problem.component.html',
  styleUrls: ['./tabs-dashboard-cell-formation-problem.component.scss']
})
export class TabsDashboardCellFormationProblemComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dataService: BlCellFormationProblemDataService,
    public formService: BlCellFormationProblemFormService,
    private cdr: ChangeDetectorRef
  ) { }

  public canChangeTab: boolean = true;
  public selectedTab: number = 0;
  public isBruscoRun: boolean = false;
  public isResultsRun: boolean = false;

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        this.formService.isShowOtherTabs = data;
      }
    })

    this.dataService.storageIsRunBrusco.subscribe({
      next: (isRun) => {
        this.isBruscoRun = isRun;
        if (isRun)
          this.selectedTab = 6;
        else
          {
            if(this.selectedTab == 6) {
              this.selectedTab = 0;
            }
          }
        }
    })

    this.dataService.storageIsRunResults.subscribe({
      next: (isRun) => {
      this.isResultsRun = isRun;
        if (isRun)
          this.selectedTab = 7;
        else
          {
            if(this.selectedTab == 7) {
              this.selectedTab = 0;
            }
          }
        }
    })
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab == 0) {

    }
  }

  ngOnDestroy(): void {
    this.isBruscoRun = false;
    this.isResultsRun = false;
  }
}
