import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlBomTableService {

  constructor() { }

  private fixedColumnsBeforeDynamic: any =
    {
      index: "itemId",
      title: "Item ID"
    };

  private fixedColumns: any[] = [];

  headersTable: any[] = this.fixedColumns;

  createDynamicHeaderColumns(numberCol: number) {
    this.fixedColumns = [];
    if (numberCol) {
      for (let i = 1; i <= numberCol; i++) {
        this.fixedColumns.push({ "index": i, title: i });
      }
      this.fixedColumns.unshift(this.fixedColumnsBeforeDynamic);
      this.headersTable = this.fixedColumns;
    }
  }

  removeDynamicHeaderColumns(){
    for (let i = 1; i <= this.fixedColumns.length; i++) {
      this.fixedColumns.slice(i, 1);
    }
  }

  
}
