<span class="parent-span">
    <a [matMenuTriggerFor]="usersMenu" class="user-data" (click)="calculatePositionLeft()">
        <p id="userText">
            <span >{{userName}}</span> 
            <mat-icon class="user-icon">person</mat-icon>
        </p>
    </a>
    <mat-menu #usersMenu="matMenu" overlapTrigger="false" class="user-toolbar">
        <mat-toolbar><mat-icon class="mat-menu-icon">verified</mat-icon> {{"Version" | translate}} 1.0 </mat-toolbar>
        <mat-list role="list">
            <!-- <mat-list-item class="d-block d-md-none">
                <p>{{userName}}</p>
            </mat-list-item> -->
            <mat-list-item>
                <a mat-menu-item disableRipple (click)="logout()">
                    <a id="logout" title="{{'Logout' | translate}}" (click)="logout()">
                        <span> 
                            <mat-icon class="mat-menu-icon">exit_to_app</mat-icon> {{'Logout' | translate}}
                        </span>
                    </a>
                </a>
            </mat-list-item>
        </mat-list>
    </mat-menu>
</span>