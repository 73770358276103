import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { SignalRNotificationService } from './signal-r-notification.service';
import { config } from '../../config/global';
import { apiPaths } from '../../config/api';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(
    private notificationService: SignalRNotificationService
  ) { }

  public data: any = "";

  private hubConnection: signalR.HubConnection

  // public startConnection() {
  //   this.hubConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${config.baseApiUrl.SERVER}${apiPaths.signalR.connection}`)
  //     .build();

  //   return this.hubConnection.start()
  // }

  // public addNotificationListener() {
  //   this.hubConnection.on('clientMethod', (data) => {
  //     this.notificationService.notification.next(data);
  //     // alert(data);
  //   });
  // }

}