import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { BlPfspFormService } from '../../../../services/form/bl-pfsp-form.service';
import { BlPfspRequestsService } from '../../../../services/requests/bl-pfsp-requests.service';
import { BlPfspDataService } from '../../../../services/shared/bl-pfsp-data.service';
import { FormDashboardPfspComponent } from '../form-dashboard-pfsp/form-dashboard-pfsp.component';
import { SaveAsDialogDashboardPfspComponent } from '../save-as-dialog-dashboard-pfsp/save-as-dialog-dashboard-pfsp.component';
import { ViewDialogDashboardPfspComponent } from '../view-dialog-dashboard-pfsp/view-dialog-dashboard-pfsp.component';
import { BlReportsService } from '../../../../../../shared/services/bl-reports.service';
import { cloneDeep } from 'lodash';
import { DynamicAlgorithmFormControlsService } from '../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { BlPfspTestFormService } from '../../../../services/form/bl-pfsp-test-form.service';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';
import { FileDownloadService } from '../../../../../../shared/services/file-download.service';
import { apiPaths } from '../../../../../../config/api';
import { FileType } from '../../../../../../shared/enums/file-type';
import { PfspHelperFunctionsService } from '../../../../services/helper/pfsp-helper-functions.service';

@Component({
  selector: 'app-header-dashboard-pfsp',
  templateUrl: './header-dashboard-pfsp.component.html',
  styleUrls: ['./header-dashboard-pfsp.component.scss']
})
export class HeaderDashboardPfspComponent implements OnInit, OnDestroy {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlPfspDataService,
    public formService: BlPfspFormService,
    private requestsService: BlPfspRequestsService,
    private alertService: AlertMessageDataService,
    public permissionsService: PermissionsService,
    private sanitizer: DomSanitizer,
    private reportService: BlReportsService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private testFormService: BlPfspTestFormService,
    private markdownService: MarkdownService,
    private fileDownloadService: FileDownloadService,
    private pfspHelperFunctionsService: PfspHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  private dataForSend: any = [];
  private dataFromStorage: any;
  public mainTitle: string = "";

  public clickedButton: boolean = false;
  public showTestButton: boolean = false;
  public isLiteratureProblem: boolean = false;

  public safeData: any;
  public unsafeHTML = "assets/markdown/scheduling/pfsp/help1.html";


   // ngAfterViewInit(): void {
  //   this.dataService.originalObject.subscribe(data => {
  //     this.formService.isExportShow = !!(data && data.id);
  //     if (data) {
  //       this.dataFromStorage = data;
  //     }
  //   });
  // }

  ngOnInit(): void {

    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: data => {
        if (data == true) {
          this.formService.isShowOtherTabs = true;
        } else {
          this.formService.isShowOtherTabs = false;
        }
      }
    });

    this.dataService.originalObject.subscribe({
      next: data => {
        this.dataFromStorage = data;
        this.formService.isExportShow = !!(data && data.id);
        this.formService.isShowSavesButtons = !!data;
        this.showTestButton = !!(data && data.id);
        if(data) {
          if(data.probemType && data.probemType.toLowerCase() == "l") {
            this.isLiteratureProblem = true;
          } else {
            this.isLiteratureProblem = false;
          }
        }
      }
    });

    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  newPFSP() {
    const dialogRef = this.matDialog.open(FormDashboardPfspComponent, {
      width: '90%',
      maxWidth: '500px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    }).afterClosed().subscribe({
      next: val => {
        if (val) {
          this.mainTitle = val;
          this.formService.resetAlgorithms();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.testFormService.isShowReportTable = false;
          this.testFormService.reset();
        }
      }
    });
  }

  viewPFSP() {
    const dialogRef = this.matDialog.open(ViewDialogDashboardPfspComponent, {
      width: '90%',
      maxWidth: '650px',
      height: "auto",
      minHeight: "210px",
      panelClass: ["custom-dialog", "no-actions-dialog"],
    }).afterClosed().subscribe({
      next: val => {
        if (val) {
          SpinnerFunctions.showSpinner();
          SpinnerFunctions.hideSpinner();
          this.testFormService.isShowReportTable = false;
          this.testFormService.reset();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.changeAlgorithm(this.formService.form.getSafe(x => x.algorithm).value);
        }
      }
    });
  }

  changeAlgorithm(value): void {
    if (value) {
      let choosenAlgorithm = cloneDeep(this.formService.algorithmData).find(x => x.id === value);
      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls(choosenAlgorithm.parametri, this.formService.form);
      this.formService.parametri = Object.keys(choosenAlgorithm.parametri);
    }
  }

  testPFSP() {
    this.dataService.storageIsRunResults.next(true);
    this.dataService.storageIsTabsView.next(true);
  }

  openMarkdown() {
    this.markdownService.openMarkdown(this.safeData)
  }

  export() {
    this.fileDownloadService.export(this.dataService.idForPatch, apiPaths.order.downloadReportCSV, "PFSP_Report_", FileType.csv)
  }

  saveDatas() {
    this.clickedButton = true;

    if(this.dataFromStorage) {
      this.prepareDataToSend(true);

      SpinnerFunctions.showSpinner();
      this.subscription.add(
        this.requestsService.insertData(this.dataForSend).subscribe({
          next: success => {
            this.pfspHelperFunctionsService.breakApartStavke(success);
            this.alertService.setMessage("success", this.dataFromStorage.id ? "Successfully updated." : "Successfully created.");
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
            this.dataService.originalObject.next(success);
            this.dataService.storageDataIsShowOtherTabs.next(true);
            if (success['id']) {
              this.dataService.setIdForPatch(success['id']);
            }
          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
          }
        })
      );

    }
  }

  runProblem() {
    this.clickedButton = true;

    if(this.dataFromStorage) {
      this.prepareDataToSend();

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.runProblem(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.dataService.storageDataResults.next(success);
              SpinnerFunctions.hideSpinner();
              this.dataService.storageDataIsRightBlockView.next(true);
              this.clickedButton = false;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].title : success["title"];
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }

    }
  }

  sortProblem() {
    this.clickedButton = true;

    if(this.dataFromStorage) {
      this.prepareDataToSend();

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.sortProblem(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.dataService.storageDataResults.next(success);
              SpinnerFunctions.hideSpinner();
              this.dataService.storageDataIsRightBlockView.next(true);
              this.clickedButton = false;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].title : success["title"];
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }

    }
  }

  loadProblem() {
    this.clickedButton = true;

    if(this.dataFromStorage) {
      this.prepareDataToSend();

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.loadProblem(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.dataService.storageDataResults.next(success);
              SpinnerFunctions.hideSpinner();
              this.dataService.storageDataIsRightBlockView.next(true);
              this.clickedButton = false;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].title : success["title"];
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }

    }
  }

  saveAsNew() {
    this.matDialog.open(SaveAsDialogDashboardPfspComponent, {
      width: '90%',
      maxWidth: '400px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog",
      data: {
        title: this.mainTitle
      }
    }).afterClosed().subscribe({
      next: val => {
        if (val) {
          let form: any;
          if (this.dataService.originalObject.getValue()) {
            form = this.dataService.originalObject.getValue()[0] ? this.dataService.originalObject.getValue()[0] : this.dataService.originalObject.getValue();
          }

          let obj: any = cloneDeep(this.dataService.originalObject).getValue();
          let datas = form ? form : obj;

          let newSend = [];
          let rows: any = this.formService.form.getRawValue();

          rows = this.pfspHelperFunctionsService.mapToSingleArray(rows.rows, this.formService.form.getRawValue())
          this.formService.formateArrayNumbers(datas.n);


          newSend.push({
            m: datas.m,
            n: datas.n,
            title: val,
            problemType: "c",
            leftBlock: {
              targetFunction: this.formService.form.getSafe(x => x.targetFunction).value,
              algorithm: this.formService.form.getSafe(x => x.algorithm).value,
              table: rows
            },
            rightBlock: {
              arrayNumbers: (this.formService.reducedNumbersArray.length > 0) ? this.formService.reducedNumbersArray : this.formService.originalNumbersArray
            }
          });
          this.subscription.add(
            this.requestsService.insertData(newSend).subscribe({
              next: success => {
                this.pfspHelperFunctionsService.breakApartStavke(success);
                this.dataService.originalObject.next(success);
                this.dataService.storageDataIsShowOtherTabs.next(true);
                this.alertService.setMessage("success", "Successfully created.");
                SpinnerFunctions.hideSpinner();

                this.mainTitle = success["title"];
                this.formService.problemTitle = success[0] ? success[0].title : success["title"];
                this.dataService.idForPatch = this.dataFromStorage.id = success["id"];

              },
              error: error => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
              }
            })
          );
        }

      }
    })
  }

  prepareDataToSend(isSave: boolean = false): void {
    this.dataForSend = [];

    let form: any = this.formService.form.getRawValue();
    let rows: any = this.pfspHelperFunctionsService.mapToSingleArray(form.rows, form)

    this.formService.formateArrayNumbers(this.dataFromStorage.n);

    let dataToSend = {
      id: this.dataFromStorage.id,
      m: this.dataFromStorage.m,
      n: this.dataFromStorage.n,
      title: (this.formService.problemTitle == "") ? this.dataFromStorage.title : this.formService.problemTitle,
      problemType: this.dataFromStorage.problemType,
      leftBlock: {
        targetFunction: this.formService.form.getSafe(x => x.targetFunction).value,
        algorithm: this.formService.form.getSafe(x => x.algorithm).value,
        table: rows
      },
      rightBlock: {
        arrayNumbers: (this.formService.reducedNumbersArray.length == 0) ? this.formService.originalNumbersArray : this.formService.reducedNumbersArray
      }
    };

    if(!isSave) {
      dataToSend.leftBlock['additionalAlgorithmData'] = this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParams(this.formService.form);
    }

    this.dataForSend.push(dataToSend);

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.isShowRightBlock = false;
    this.formService.isShowSavesButtons = false;
    this.formService.isShowRunButton = false;
    this.formService.originalNumbersArray = [];
    this.dataForSend = [];
    this.mainTitle = "";
    this.formService.isRunProblemForTitle = false;
    this.formService.problemTitle = "";
    this.showTestButton = false;
    this.formService.isExportShow = false;
    this.isLiteratureProblem = false;
  }
}
