import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AlertMessageDataService } from '../../services/alert-message-data.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit {

  constructor(
    public service : ApiService<any>,
    public alertMessageService : AlertMessageDataService
  ) { }

  ngOnInit() {
  }
  // TODO: From service!!!!!!!!
  insert(form : UntypedFormGroup | any, nameOfCreated: string, dataService? : any, onSuccess?: any, passData?: boolean) : Observable<any>{
    let values = form;
    if(form instanceof UntypedFormGroup){
      values = form.value;
    }


    if((form instanceof UntypedFormGroup) && !form.valid){
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }

    let result : Observable<any> = this.service.create(values).pipe(share());
    this.resultSubscribe(result, nameOfCreated, 'insert', dataService, onSuccess, passData);

    return result;
  }

  update(id : number, form : UntypedFormGroup | any, originalObj: any, nameOfCreated: string, dataService? : any, onSuccess?: any) : Observable<any> {
    let values = form;
    if(form instanceof UntypedFormGroup){
      values = form.value;
    }


    if((form instanceof UntypedFormGroup) && !form.valid){
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }

    // change for nested props!
    // let diffObj : any = diff(originalObj, values);


    // let keys = Object.keys(diffObj);
    // keys.forEach(
    //   key => {
    //     if(isArray(values[key]) && isNumber(values[key][0])){
    //         diffObj[key] = values[key] as number[];
    //       }
    //     }
    // )
    // if((!diffObj) || diffObj==null || diffObj == {} || (keys.length == 0) || (keys.length==1 && keys[0]=='id')){
    //   this.alertMessageService.setMessage('warning', 'INFO: There is nothing to update.');
    //   return new Observable<any>();
    // } else {

      let result : Observable<any> = this.service.update(id, values);
      this.resultSubscribe(result, nameOfCreated, 'updat', dataService, onSuccess);

      return result;
    // }

  }

  /**
   *
   * @param form FormGroup which must contains "items" FormArray
   * @param originalObj array of elements
   * @param nameOfCreated
   * @param dataService
   * @param onSuccess
   */
  updateAll(form : UntypedFormGroup | any, originalObj: any[], nameOfCreated: string, dataService? : any, onSuccess?: any){
    let values = form;
    if(form instanceof UntypedFormGroup){
      if(!form.contains('items')){
        this.alertMessageService.setMessage('danger','Programming error!!! You need to setup "items" FormArray!');
        return new Observable<any>();
      }
      values = form.get('items').value;
    }


    if((form instanceof UntypedFormGroup) && !form.valid){
      this.alertMessageService.setFormErrorsMessages(form);
      return new Observable<any>();
    }


    // change for nested props!
    // let diffObjs : any = diff(originalObj, values);

    // let ids = originalObj.map(x => x.id);

    // let keys = Object.keys(diffObjs);
    // keys.forEach(
    //   (key) => {
    //     diffObjs[key]['id'] = ids[key];
    //   }
    // );

    let result : Observable<any> = this.service.updateAll(values);
    this.resultSubscribe(result, nameOfCreated, 'updat', dataService, onSuccess);

    return result;
  }

  private resultSubscribe(result : Observable<any>, nameOfCreated: string, operation: string, dataService? : any, onSuccess?: any, passData? : boolean){

    result.subscribe({
      next: success => {
        this.alertMessageService.setMessage('success',nameOfCreated + " successfully " + operation + "ed.");
        // "DEV" env - move in "success"
        if(dataService!=null){
          this.service.getWithTotalCount().subscribe({
            next: data=> {
              dataService.storage.next(data);
            }
          });
        }
        if(onSuccess){
          if(passData){
            onSuccess(success);
          }
          else {
            onSuccess();
          }
        }

      },
      error: error => {
        this.alertMessageService.setMessage('danger', "Error on " + operation + "ing " + nameOfCreated);

        if(error.hasOwnProperty('statusCode') && error.statusCode == 400){
          if(dataService!=null){
            this.service.getWithTotalCount().subscribe({
              next: data=> {
                dataService.storage.next(data);
              }
            });
          }
        }

        // "DEV" env
        if(onSuccess)
          onSuccess();
      }
    });


  }
}
