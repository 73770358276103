import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CountdownComponent } from 'ngx-countdown';
import { CheckEvents } from '../../auto-logout/helpers/check-events';
import * as ApplicationActions from '../../auto-logout/redux/actions';
import * as fromRoot from '../../auto-logout/redux/reducers';
import { AutoLogoutService } from '../../auto-logout/services/auto-logout.service';
import { ICountdown } from '../../interfaces/i-countdown';
import { IGauge } from '../../interfaces/i-gauge';
import { config } from '../../../config/global';
import { SpinnerFunctions } from '../../../shared/functions/spinner-functions';


@Component({
  selector: 'app-countdown-stopwatch',
  templateUrl: './countdown-stopwatch.component.html',
  styleUrls: ['./countdown-stopwatch.component.scss']
})
export class CountdownStopwatchComponent implements OnInit {

  @Input() public duration: number = config.stayLoggedInPeriod;

  @ViewChild(CountdownComponent, { static: true }) public counter: CountdownComponent;  
  @ViewChild('count', { static: true }) public count: CountdownComponent;

  constructor(
    private autoLogoutService : AutoLogoutService, 
    private store : Store<fromRoot.State>
  ){
  
  }

  public gaugeOptions: IGauge = {
    type : "full",
    percent : 0,
    thick : "3",
    forgrounColor: "#f7941d"
  }
  public countdownTime: number = config.stayLoggedInPeriod / 1000;

  public config : ICountdown = { 
    leftTime: this.countdownTime, 
    demand: false 
  };

  public isStopwatchStarted = false;

  ngOnInit() {
    this.gaugeOptions.percent = 0;
    this.hideGaugeValueFromHtml(); //hiding ngx-gauge value 
    this.trackIsStopwatchStarted();
  }

  private hideGaugeValueFromHtml(){
    let readingBlocks = document.getElementsByClassName("reading-block");
    for (let i=0; i < readingBlocks.length; i++){
      readingBlocks[i]['style'].display='none';
    }
  }

  trackIsStopwatchStarted(){
    this.autoLogoutService._startStopwatch.subscribe({
      next: (isStopwatchStarted) => {
        if(this.isStopwatchStarted != isStopwatchStarted){
          this.isStopwatchStarted = isStopwatchStarted;
          SpinnerFunctions.hideSpinner();
          this.showBrowserNotification();
          this.config = {
            leftTime: this.countdownTime, 
            demand: false
          };
          this.gaugeOptions.percent = 100;
          this.hideGaugeValueFromHtml();
        }
      }
    });
  }

  stayLoggedIn(){
    CheckEvents.updateLastEventTime();
    this.autoLogoutService.startStopwatch.next(false);
  }

  private onFinished(){
    this.autoLogoutService.startStopwatch.next(false);
    this.store.dispatch(new ApplicationActions.LogOut());
  }

  close(){
    this.store.dispatch(new ApplicationActions.LogOut());
  }

  private showBrowserNotification(){
    //  this.notificationService.create("FrontdeskAnywhere", "Stay Logged In.");
  }

  timesUp(event) { 
    if (event.action == "done" && this.autoLogoutService.startStopwatch.getValue()) {
      this.onFinished();
    } 
  }

}
