import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { IDistribution1ToNNodesFilter } from '../../../../../../interfaces/i-distribution-1-to-n-nodes-filter';
import { Distribution1ToNNodesFilteringService } from '../../../../../../services/api/distribution-1-to-n-nodes-filtering.service';
import { Distribution1ToNService } from '../../../../../../services/api/distribution-1-to-n.service';
import { BlDistribution1ToNSourceDestinationsTabFormService } from '../../../../../../services/forms/bl-distribution-1-to-n-source-destinations-tab-form.service';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-controls-for-graph',
  templateUrl: './controls-for-graph.component.html',
  styleUrls: ['./controls-for-graph.component.scss']
})
export class ControlsForGraphComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlDistribution1ToNSourceDestinationsTabFormService,
    public dataService: BlDistribution1ToNDataService,
    private nodesFilteringService: Distribution1ToNNodesFilteringService,
    public mainApiService: Distribution1ToNService,
    private leftSidebarFormService: BlLeftSidebarDistribution1ToNFormService,
    private alertService: AlertMessageDataService
  ) {}

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {

    if(!this.dataService.isEdit) {
      this.formService.createGraphControls()
    }
  }

  filterByNodes(): void {
    const objForSend = this.makeFilterObj();

    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.nodesFilteringService.filterNodes(objForSend).subscribe({
        next: (data) => {
          if (data) {
            if(data.poruka != null && data.poruka != "") {
              this.alertService.setMessage("danger", data.poruka);
            }
            else {
              if(this.dataService.problemType.shortcut == 'ptp' && data.destinations.length > 0)
                data.destinations.unshift(data.destinations.pop());
              this.dataService.isCustom = this.mainApiService.selectedProblemType == "c";
              this.dataService.isEdit = true;
              this.leftSidebarFormService.objToPatch = cloneDeep(data);
              this.leftSidebarFormService.originalObj = cloneDeep(data);
              this.dataService.showTabs = true;
              this.leftSidebarFormService.fillForm();
              this.dataService.canRunBS.next(true);
              this.dataService.isCalculated = false;
              this.dataService.calculateFinished.next(false);
            }

          }
          SpinnerFunctions.hideSpinner();
        }, 
        error: err => {
          SpinnerFunctions.hideSpinner();
        }
      })
    );
  }

  makeFilterObj(): IDistribution1ToNNodesFilter {
    const value = this.formService.form.getSafe(x => x.filterByNodes).value;

    return {
      id: this.dataService.currentIdForRun,
      searchValue: value
    }
  }

  removeGraph(index): void {
    this.dataService.allGraphs.splice(index, 1);
    this.formService.removeGraphControl(index);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
