<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form [formGroup]="form" class="custom-form" (ngSubmit)="save()">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          @if (isSource) {
            {{ "ChooseSource" | translate }}
          } @else {
            @if (isDestination) {
              {{ "ChooseDestination" | translate }}
            } @else {
              {{ "ChooseInBetweenRoute" | translate }}
            }
          }
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 col-md-3">
        <form [formGroup]="filteringForm">
          <div class="row">
            <div class="col-md-12">
              <h3 id="advancedSearchLabel">{{"AdvancedSearch" | translate}}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 map-filters">
              <mat-radio-group formControlName="type" color="primary">
                <mat-radio-button value="streets">
                  <span class="typeRbPicture streets"></span>
                </mat-radio-button>
                <mat-radio-button value="hotels">
                  <span class="typeRbPicture hotels"></span>
                </mat-radio-button>
                <mat-radio-button value="restaurants">
                  <span class="typeRbPicture restaurants"></span>
                </mat-radio-button>
                <mat-radio-button value="parkingLots">
                  <span class="typeRbPicture parkingLots"></span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
  <div class="row">
    <div class="col-sm-12 search-box search-term small-subscript">
      <mat-form-field appearance="outline" [ngClass]="filteringForm.get('description').errors ? 'requiredField' : null">
        <mat-label>{{"SearchTerm" | translate}}</mat-label>
        <input [formControlName]="filteringForm.keys.description" matInput autocomplete="off" />
        <mat-error class="customError">
          @if (filteringForm.get('description').hasError('required')) {
            {{"ThisFieldIsRequired" | translate}}
          }
          @if (filteringForm.get('description').hasError('minlength')) {
            {{"MinimumNumberOfCharacters" | translate}}&nbsp; 3
          }
        </mat-error>
      </mat-form-field>

      <button [disabled]="filteringForm.invalid" type="button" mat-flat-button color="primary"
        class="custom-flat-btn"
        (click)="filtering()">{{"Search" | translate}}
      </button>
    </div>
  </div>

  @if (filteringDataFromBack) {
    <div class="row">
      <ng-container formArrayName="searchedDestinations">
        <div class="col-md-12 location-links">
          @for (item of filteringDataFromBack; track item; let i = $index) {
            <mat-checkbox class="checkList" [formControlName]="i" color="primary">
              <a class="location-link" (click)="selectLink(item)">{{item.title}}</a>
            </mat-checkbox>
          }
        </div>
      </ng-container>
    </div>
  }
</form>

</div>
<div class="col-sm-8 col-md-9">
  <div class="row">
    <div class="col-sm-12 location-box"
      [ngClass]="(dataService.problemType.shortcut == 'cvrp' && !isSource) ? 'all-inputs' : null">
      <div class="latitude-longitude hide-subscript">
        <mat-form-field appearance="outline">
          <mat-label>{{"Latitude" | translate}}</mat-label>
          <input type="number" [formControlName]="form.keys.latitude" matInput
            autocomplete="off" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{"Longitude" | translate}}</mat-label>
            <input type="number" [formControlName]="form.keys.longitude" matInput
              autocomplete="off" />
            </mat-form-field>
            @if (dataService.problemType.shortcut == 'cvrp' && !isSource) {
              <mat-form-field appearance="outline" class="quantity-input">
                <mat-label>{{"Quantity" | translate}}</mat-label>
                <input type="number" [formControlName]="form.keys.quantity" matInput
                  autocomplete="off" />
                </mat-form-field>
              }
            </div>
            <div class="show-location">
              <button mat-flat-button color="primary" class="custom-flat-btn"
                [disabled]="geoPositioningService.turnOnLocationMessage != ''" type="button"
                (click)="showMyLocation()">
                {{"GetMyLocation" | translate}}
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <app-leaflet-map (markerObjectClicked)="markerObjectClicked($event)" [form]="form"
              [markers]="markers" [zoomIn]="zoomIn" [zoomAfterUpdatingMarkers]="zoomAfterUpdatingMarkers" [routes]="(dataService.coordinatesForSelectedCountry && dataService.coordinatesForSelectedCountry.routes) ? dataService.coordinatesForSelectedCountry.routes : []" [fitBounds]="zoomByFitBounds">
            </app-leaflet-map>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div mat-dialog-actions>
      <app-button-with-spinner [isClickedSave]="isClickedSave" [form]="form">
      </app-button-with-spinner>
  </div>
</form>
