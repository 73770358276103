import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';
import { ColumnOption } from '../../interfaces/column-option';

@Pipe({
  name: 'disabledColumn'
})
export class DisabledColumnPipe implements PipeTransform {

  transform(column: ITableColumn | ColumnOption, row): boolean {
    if(!column.hasOwnProperty('disable')){
      return false;
    }
    if(row) {
      return column.disable(row);
    } else {
      return column.disable;
    }
  }

}
