import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PfspRoutingModule } from './pfsp-routing.module';
import { DashboardPfspComponent } from './components/dashboard-pfsp/dashboard-pfsp.component';
import { FormDashboardPfspComponent } from './components/dashboard-pfsp/components/form-dashboard-pfsp/form-dashboard-pfsp.component';
import { HeaderDashboardPfspComponent } from './components/dashboard-pfsp/components/header-dashboard-pfsp/header-dashboard-pfsp.component';
import { ViewDialogDashboardPfspComponent } from './components/dashboard-pfsp/components/view-dialog-dashboard-pfsp/view-dialog-dashboard-pfsp.component';
import { SaveAsDialogDashboardPfspComponent } from './components/dashboard-pfsp/components/save-as-dialog-dashboard-pfsp/save-as-dialog-dashboard-pfsp.component';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { TabsDashboardPfspComponent } from './components/dashboard-pfsp/components/tabs-dashboard-pfsp/tabs-dashboard-pfsp.component';
import { TabDashboardInputDataComponent } from './components/dashboard-pfsp/components/tabs-dashboard-pfsp/components/tab-dashboard-input-data/tab-dashboard-input-data.component';
import { TabDashboardResultsComponent } from './components/dashboard-pfsp/components/tabs-dashboard-pfsp/components/tab-dashboard-results/tab-dashboard-results.component';
import { TabDashboardInputDataLeftSideComponent } from './components/dashboard-pfsp/components/tabs-dashboard-pfsp/components/tab-dashboard-input-data/components/tab-dashboard-input-data-left-side/tab-dashboard-input-data-left-side.component';
import { TabDashboardInputDataRightSideComponent } from './components/dashboard-pfsp/components/tabs-dashboard-pfsp/components/tab-dashboard-input-data/components/tab-dashboard-input-data-right-side/tab-dashboard-input-data-right-side.component';


@NgModule({
  declarations: [DashboardPfspComponent, FormDashboardPfspComponent, HeaderDashboardPfspComponent, ViewDialogDashboardPfspComponent, SaveAsDialogDashboardPfspComponent, TabsDashboardPfspComponent, TabDashboardInputDataComponent, TabDashboardResultsComponent, TabDashboardInputDataLeftSideComponent, TabDashboardInputDataRightSideComponent],
  imports: [
    CommonModule,
    PfspRoutingModule,
    SharedModule,
    CoreModule
  ]
})
export class PfspModule { }
