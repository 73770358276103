@if (data.type==='delete') {
  <div mat-dialog-title>
    <div class="row">
      <div class="col-sm-12 custom-dialog-title">
        <mat-icon class="closeIconBold" (click)="close(true)">close</mat-icon>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{"Delete Confirmation" | translate}}
        </h3>
        <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 confirmInfo">
          <p>{{"Is that what you wanted to do" | translate}}</p>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
          <button mat-flat-button color="primary" (click)="close(true)">{{"Confirm" | translate}}</button>
          <button mat-flat-button color="warn" (click)="close(false)">{{"Cancel" | translate}}</button>
    </div>
  } @else {
    <div mat-dialog-title>
      <div class="row">
        <div class="col-sm-12 custom-dialog-title">
          <mat-icon class="closeIconBold" (click)="close(true)">close</mat-icon>
        </div>
      </div>
    </div>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-md-12 formHeading">
          <h3 class="modalHeading">
            @if (data.title) {
              {{data.title | translate}}
            } @else {
              {{"Confirm" | translate}}
            }
          </h3>
          <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 confirmInfo">
            @if (data.message) {
              <p>{{data.message | translate}}</p>
            } @else {
              <p>{{"Is that what you wanted to do" | translate}}</p>
            }
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
            <button mat-flat-button color="primary" (click)="close(true)">{{"Confirm" | translate}}</button>
            <button mat-flat-button color="warn" (click)="close(false)">{{"Cancel" | translate}}</button>S
      </div>
    }


