import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ICellFormationProblemForm, ICellFormationProblemFilterForm } from '../../interfaces/i-cell-formation-problem';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { UploadCfpCsvService } from '../api/upload-cfp-csv.service';
import { DynamicAlgorithmFormControlsService } from '../../../../core/services/dynamic-algorithm-form-controls.service';

@Injectable({
  providedIn: 'root'
})
export class BlCellFormationProblemFormService implements IFormService<ICellFormationProblemForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private uploadCSVService: UploadCfpCsvService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  form: FormGroupTypeSafe<ICellFormationProblemForm> = this.init();
  formFilter: FormGroupTypeSafe<ICellFormationProblemFilterForm> = this.initFilter();

  public isShowMainTabs: boolean = false;
  public isShowOtherTabs: boolean = false;
  public isFromViewDialog: boolean = false;
  public isLiteratureProblem: boolean = false;
  public isRunProblemForTitle: boolean = false;
  public isExportShow: boolean = false;
  public isImportMethod: boolean = false;
  public problemTitle: string = "";
  public numberRows: number = 0;
  public numberCols1: number = 0;
  public configP: number = 0;
  public numberCols2: number = 0;
  public numberCols3: number = 0;
  public numberCols4: number = 0;
  public rowData: any = null;
  public algorithmData: any = [];

  public parametri: any = [];

  init(): FormGroupTypeSafe<ICellFormationProblemForm> {
    let group: FormGroupTypeSafe<ICellFormationProblemForm> = this.formBuilder.group<ICellFormationProblemForm>({
      m: this.formBuilder.control(3, [Validators.required, Validators.min(3)]),
      p: this.formBuilder.control(3, [Validators.required, Validators.min(3)]),
      len: this.formBuilder.control(2, [Validators.required, Validators.min(2)]),
      description: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
      rows: this.formBuilder.array([]),
      inputMethod: this.formBuilder.control(0),
      algorithm: this.formBuilder.control("", [Validators.required]),
      objective: this.formBuilder.control("", [Validators.required]),
      algorithmParams: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;

  }
  initFilter(): FormGroupTypeSafe<ICellFormationProblemFilterForm> {
    let group: FormGroupTypeSafe<ICellFormationProblemFilterForm> = this.formBuilder.group<ICellFormationProblemFilterForm>({
      problemTypes: this.formBuilder.control(""),
    });

    return group;

  }

  getFormReference(): FormGroupTypeSafe<ICellFormationProblemForm> {
    return this.form;
  }

  getFilterFormReference(): FormGroupTypeSafe<ICellFormationProblemFilterForm> {
    return this.formFilter;
  }

  setRows(rowData: any, configP?: number) {
    this.form.removeControl("rows");
    this.form.addControl("rows", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.rowsTab1.table1;
      let numberRows: any = rowData.m;
      let numberCols: any = rowData.len;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rows) as UntypedFormArray).push(
          new UntypedFormGroup({

          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.rows) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row[i]["c" + j], [Validators.max(configP), Validators.min(1)]));
        }
      }
      (this.form.get("rows") as UntypedFormArray).controls = [...(this.form.get("rows") as UntypedFormArray).controls];
    }
  }

  createTableWithDynamicRows(numberColumns: any, numberRows: number, configP?: number) {
    this.removeTableDYnamicRows();
    if (numberRows) {
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rows) as UntypedFormArray).push(
          new UntypedFormGroup({
          })
        );
        for (let j = 0; j < numberColumns; j++) {
          ((this.form.getSafe(x => x.rows) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control("", [Validators.max(configP), Validators.min(1)]));
        }
      }
      (this.form.get("rows") as UntypedFormArray).controls = [...(this.form.get("rows") as UntypedFormArray).controls];
    }
  }

  removeTableDYnamicRows() {
    this.form.removeControl("rows");
    this.form.addControl("rows", this.formBuilder.array([]));
  }

  prepareDataToSend(multipart?: any) {
    let formData = new FormData();
    if (multipart) {
      formData.append('files', multipart[0]);
    } else {
      formData.append('files', null);
    }
    return formData as any;

  }

  uploadCSV(multipart?: any) {
    let dataToSend: any = this.prepareDataToSend(multipart);
    return this.uploadCSVService.create(dataToSend, true, true);
  }

  reset(): void {
    this.form = this.init();
  }

  resetAlgorithms(): void {
    this.dynamicAlgorithmFormControlsService.resetAlgorithmFormArray(this.form);
    this.parametri = [];
  }

  resetFilter(): void {
    this.formFilter = this.initFilter();
  }
}
