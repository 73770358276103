import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownComponent } from '../../../../shared/components/markdown/markdown.component';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {

  constructor(
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  public safeData: any;
  public unsafeHTML: string = "assets/markdown/home/help1.html";

  ngOnInit() {
    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  openMarkdown() {
    const dialogRef = this.matDialog.open(MarkdownComponent, {
      width: '90%',
      maxWidth: '1100px',
      height: "auto",
      minHeight: "100px",
      panelClass: "customModal",
      data: {
        path: this.safeData
      }
    });
  }

}
