import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TableFunctions } from '../../functions/table-functions';
import { ColumnOption } from '../../interfaces/column-option';
import { AlertMessageDataService } from '../../services/alert-message-data.service';
import { TableComponent } from '../table/table.component';
import { ValidationFunctions } from '../../functions/validation-functions';
import { RowIndexPipe } from '../../pipes/table-pipes/row-index.pipe';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent extends TableComponent implements OnInit {

  constructor(
    tableFunctions: TableFunctions,
    translateService: TranslateService,
    alertMessageService: AlertMessageDataService,
    matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) {
    super(tableFunctions, translateService, alertMessageService, matDialog);
  }

  @Output() fieldValueChange = new EventEmitter();
  @Input() resetPageIndex: boolean = true;

  public form = this.formBuilder.group({
    items: this.formBuilder.array([])
  });

  ngOnInit() {
    super.ngOnInit();
  }

  optionFormMethod(option: ColumnOption, row: any) {
    let rowIndex = new RowIndexPipe(this.tableFunctions).transform(row, this.paginator, this.isServerRendering, this.dataSource) - 1;
    if (option.hasOwnProperty('method')) {
      let formRowValue = (this.form.get("items") as UntypedFormArray).controls[rowIndex].value;
      let row = this.dataSource.data[rowIndex];
      Object.keys(row).forEach(key => {
        if (!formRowValue.hasOwnProperty(key)) {
          formRowValue[key] = row[key];
        }
      })
      let param = null;
      if (!option.hasOwnProperty('param')) {
        param = formRowValue;
      } else {
        param = formRowValue[option.param];
      }
      option.method(param);
    }
  }

  fillTable(isNotGet = false) {
    let request = super.fillTable(isNotGet);
    // if (request) {
    //   request.subscribe(data => {
    //     this.generateForm(data);
    //   })
    // }

    return request;

  }

  protected trackStorageUpdates() {
    this.subscription.add(this.dataService.getStorage().subscribe({
      next: (data: any) => {
        this.generateForm(data);
        this.buildTable(data, this.resetPageIndex);
      }
    }));
  }

  private generateForm(data) {
    let fields = this.columns.filter(x => x.isFormControl);
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([])
    });

    data.forEach(row => {
      let group = this.formBuilder.group({});
      fields.forEach(field => group.addControl(field.index, new UntypedFormControl(row[field.index], field.validators)));

      (this.form.get("items") as UntypedFormArray).push(group);
    })
    ValidationFunctions.runValidation(this.form);
  }

  isFormValid(row: any) {
    let rowIndex = new RowIndexPipe(this.tableFunctions).transform(row, this.paginator, this.isServerRendering, this.dataSource) - 1;
    return !(this.form.get("items") as UntypedFormArray).controls[rowIndex].valid;
  }

  changeTbValue(row, controlName, value): void {
    this.fieldValueChange.emit({
      row: row,
      controlName: controlName,
      value: value
    });
  }
}
