<ng-container *ngIf="data.type==='delete'; else common">
    <div mat-dialog-title>
        <div class="row">
            <div class="col-sm-12 closeIconBox">
                <mat-icon class="closeIconBold" (click)="close(true)">close</mat-icon>
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{"Delete Confirmation" | translate}}
                </h3>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 confirmInfo">
                <p>{{"Is that what you wanted to do" | translate}}</p>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="row full-width">
            <div class="col-md-12 confirm-actions">
                <button mat-flat-button disableRipple color="accent" (click)="close(true)">{{"Confirm" | translate}}</button>
                <button mat-flat-button disableRipple color="warn" (click)="close(false)">{{"Cancel" | translate}}</button>
            </div>
        </div>
    </div>
</ng-container>


<ng-template #common>
    <div mat-dialog-title>
        <div class="row">
            <div class="col-sm-12 closeIconBox">
                <mat-icon class="closeIconBold" (click)="close(true)">close</mat-icon>
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12 formHeading">
                <h3 class="modalHeading">
                    <ng-container *ngIf="data.title; else defaultTitle">
                        {{data.title | translate}}
                    </ng-container>
                    <ng-template #defaultTitle>
                        {{"Confirm" | translate}}
                    </ng-template>
                </h3>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 confirmInfo">
                <ng-container *ngIf="data.message; else defaultMessage">
                    <p>{{data.message | translate}}</p>
                </ng-container>
                <ng-template #defaultMessage>
                    <p>{{"Is that what you wanted to do" | translate}}</p>
                </ng-template>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="row full-width">
            <div class="col-md-12 confirm-actions">
                <button mat-flat-button disableRipple color="accent" (click)="close(true)">{{"Confirm" | translate}}</button>
                <button mat-flat-button disableRipple color="warn" (click)="close(false)">{{"Cancel" | translate}}</button>
            </div>
        </div>
    </div>
</ng-template>