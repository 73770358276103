import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeoLocationRoutingModule } from './geo-location-routing.module';
import { DashboardGeoLocationComponent } from './components/dashboard-geo-location/dashboard-geo-location.component';
import { TabsGeoLocationComponent } from './components/dashboard-geo-location/components/tabs-geo-location/tabs-geo-location.component';
import { ForwardGeoLocationComponent } from './components/dashboard-geo-location/components/tabs-geo-location/components/forward-geo-location/forward-geo-location.component';
import { BackwardGeoLocationComponent } from './components/dashboard-geo-location/components/tabs-geo-location/components/backward-geo-location/backward-geo-location.component';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { HeaderGeoLocationComponent } from './components/dashboard-geo-location/components/header-geo-location/header-geo-location.component';
import { GeoLocationTableComponent } from './components/dashboard-geo-location/components/tabs-geo-location/components/geo-location-table/geo-location-table.component';


@NgModule({
  declarations: [DashboardGeoLocationComponent, TabsGeoLocationComponent, ForwardGeoLocationComponent, BackwardGeoLocationComponent, HeaderGeoLocationComponent, GeoLocationTableComponent],
  imports: [
    CommonModule,
    GeoLocationRoutingModule,
    SharedModule,
    CoreModule
  ]
})
export class GeoLocationModule { }
