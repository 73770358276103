import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Distribution1ToNRoutingModule } from './distribution-1-to-n-routing.module';
import { DashboardDistributionComponent } from './components/dashboard-distribution/dashboard-distribution.component';
import { HeaderDashboardDistributionComponent } from './components/dashboard-distribution/components/header-dashboard-distribution/header-dashboard-distribution.component';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { LeftSidebarDashboardDistributionComponent } from './components/dashboard-distribution/components/left-sidebar-dashboard-distribution/left-sidebar-dashboard-distribution.component';
import { SourceDestinationFormDashboardDistributionComponent } from './components/dashboard-distribution/components/source-destination-form-dashboard-distribution/source-destination-form-dashboard-distribution.component';
import { MainMapDashboardDistributionComponent } from './components/dashboard-distribution/components/main-map-dashboard-distribution/main-map-dashboard-distribution.component';
import { ViewDialogDashboardDistributionComponent } from './components/dashboard-distribution/components/view-dialog-dashboard-distribution/view-dialog-dashboard-distribution.component';
import { LeftSidebarDashboardDistributionSourceComponent } from './components/dashboard-distribution/components/left-sidebar-dashboard-distribution/components/left-sidebar-dashboard-distribution-source/left-sidebar-dashboard-distribution-source.component';
import { LeftSidebarDashboardDistributionDestinationsComponent } from './components/dashboard-distribution/components/left-sidebar-dashboard-distribution/components/left-sidebar-dashboard-distribution-destinations/left-sidebar-dashboard-distribution-destinations.component';
import { LeftSidebarDashboardDistributionAdditionalFieldsComponent } from './components/dashboard-distribution/components/left-sidebar-dashboard-distribution/components/left-sidebar-dashboard-distribution-additional-fields/left-sidebar-dashboard-distribution-additional-fields.component';
import { SaveAsNewDashboardDistributionComponent } from './components/dashboard-distribution/components/save-as-new-dashboard-distribution/save-as-new-dashboard-distribution.component';
import { AdminDashboardDistributionComponent } from './components/dashboard-distribution/components/admin-dashboard-distribution/admin-dashboard-distribution.component';
import { RouteSettingsDashboardDistributionComponent } from './components/dashboard-distribution/components/route-settings-dashboard-distribution/route-settings-dashboard-distribution.component';
import { CalculateAlgorithmDashboardDistributionComponent } from './components/dashboard-distribution/components/calculate-algorithm-dashboard-distribution/calculate-algorithm-dashboard-distribution.component';
import { ReportsDashboardDistributionComponent } from './components/dashboard-distribution/components/reports-dashboard-distribution/reports-dashboard-distribution.component';
import { NewDashboardDistributionComponent } from './components/dashboard-distribution/components/new-dashboard-distribution/new-dashboard-distribution.component';
import { TabsDashboardDistributionComponent } from './components/dashboard-distribution/components/tabs-dashboard-distribution/tabs-dashboard-distribution.component';
import { SourceDestinationTabDashboardDistributionComponent } from './components/dashboard-distribution/components/tabs-dashboard-distribution/components/source-destination-tab-dashboard-distribution/source-destination-tab-dashboard-distribution.component';
import { MainMapTabDashboardDistributionComponent } from './components/dashboard-distribution/components/main-map-tab-dashboard-distribution/main-map-tab-dashboard-distribution.component';
import { ReportsTabDashboardDistributionComponent } from './components/dashboard-distribution/components/reports-tab-dashboard-distribution/reports-tab-dashboard-distribution.component';
import { OverviewDashboardDistributionComponent } from './components/dashboard-distribution/components/overview-dashboard-distribution/overview-dashboard-distribution.component';
import { PackingTestComponent } from './components/dashboard-distribution/components/packing-test/packing-test.component';
import { MapByIterationsTabDashboardDistributionComponent } from './components/dashboard-distribution/components/map-by-iterations-tab-dashboard-distribution/map-by-iterations-tab-dashboard-distribution.component';
import { ControlsForGraphComponent } from './components/dashboard-distribution/components/tabs-dashboard-distribution/components/controls-for-graph/controls-for-graph.component';

@NgModule({
  declarations: [DashboardDistributionComponent, HeaderDashboardDistributionComponent, LeftSidebarDashboardDistributionComponent, SourceDestinationFormDashboardDistributionComponent, MainMapDashboardDistributionComponent, ViewDialogDashboardDistributionComponent, LeftSidebarDashboardDistributionSourceComponent, LeftSidebarDashboardDistributionDestinationsComponent, LeftSidebarDashboardDistributionAdditionalFieldsComponent, SaveAsNewDashboardDistributionComponent, AdminDashboardDistributionComponent, RouteSettingsDashboardDistributionComponent, CalculateAlgorithmDashboardDistributionComponent, ReportsDashboardDistributionComponent, NewDashboardDistributionComponent, TabsDashboardDistributionComponent, SourceDestinationTabDashboardDistributionComponent, MainMapTabDashboardDistributionComponent, ReportsTabDashboardDistributionComponent, OverviewDashboardDistributionComponent, PackingTestComponent, MapByIterationsTabDashboardDistributionComponent, ControlsForGraphComponent],
  imports: [
    CommonModule,
    Distribution1ToNRoutingModule,
    SharedModule,
    CoreModule
  ]
})
export class Distribution1ToNModule { }
