import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControlTypeSafe } from '../../helpers/reactive-forms-helper';
import { DateFunctions } from '../../functions/date-functions';

@Component({
  selector: 'app-common-datepicker',
  templateUrl: './common-datepicker.component.html',
  styleUrls: ['./common-datepicker.component.scss']
})
export class CommonDatepickerComponent implements OnInit, OnChanges {

  @Input() public formControlToFill: FormControlTypeSafe<any>;
  @Input() public labelText: string;
  @Input() public type: string; // M, Y
  @Input() public minDate: Date = null;
  
  @ViewChild('picker', { static: false}) picker;

  constructor() { }

  ngOnInit() {
  }

  chosenHandler(event: any) {
    const date = DateFunctions.getMomentDateNoTime(event);
    this.formControlToFill.setValue(date);
    this.picker.close();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(this.formControlToFill && changes.formControlToFill && changes.formControlToFill.currentValue){
      this.formControlToFill = changes.formControlToFill.currentValue; 
    }
  }
}
