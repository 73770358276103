<div class="dashboard-content">
    <div class="row">
        <div class="col-md-12">
            <mat-tab-group dynamicHeight="true" [selectedIndex]="dataService.selectedTab" (selectedTabChange)="onTabChange($event)">
                <mat-tab label="{{'Item Master' | translate}}">
                    <app-tab-dashboard-item-master></app-tab-dashboard-item-master>
                </mat-tab>
                <ng-container *ngIf="formService.isShowOtherTabs">
                    <mat-tab label="{{'BOM' | translate}}">
                        <app-tab-dashboard-bom></app-tab-dashboard-bom>
                    </mat-tab>
                    <mat-tab label="{{'MPS' | translate}}">
                        <app-tab-dashboard-mps></app-tab-dashboard-mps>
                    </mat-tab>
                    <mat-tab label="{{'Inventory' | translate}}">
                        <app-tab-dashboard-inventory></app-tab-dashboard-inventory>
                    </mat-tab>
                    <mat-tab label="{{'Capacity' | translate}}">
                        <app-tab-dashboard-capacity></app-tab-dashboard-capacity>
                    </mat-tab>
                    <mat-tab [disabled]="!dataService.requestedResults" label="{{'Results' | translate}}">
                        <app-dashboard-mrp-report></app-dashboard-mrp-report>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </div>
    </div>
</div>