<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form [formGroup]="form" class="custom-form" (ngSubmit)="save()">
  <div mat-dialog-content>

    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{ "RouteSettings" | translate }}
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          @for (item of settings; track item; let i = $index) {
            <div class="col-sm-4 col-lg-3 control-box">
              <mat-form-field appearance="outline">
                <mat-label>{{item.title | translate}}</mat-label>
                <input type="number" [formControlName]="item.controlName" matInput autocomplete="off" />
              </mat-form-field>
              <mat-checkbox color="primary"
                [checked]="dataService.settingsObj[item.controlName] != null"
                (change)="checkboxChange($event, i)">
              </mat-checkbox>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <app-button-with-spinner [isClickedSave]="isClickedSave" [form]="form">
    </app-button-with-spinner>
  </div>
</form>
