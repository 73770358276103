import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { icon, Map, marker, point, polyline, tileLayer } from 'leaflet';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../functions/spinner-functions';
import { GeoPositioningService } from '../osm-view/geo-positioning.service';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit, OnChanges, OnDestroy {

  

  @Input() routes = [];
  @Input() lineColor = "red";
  @Input() lineWidth = 3;
  @Input() markers = [];
  @Input() fitBounds = false;
  @Input() zoomIn = null;
  @Input() form = null;
  @Input() zoomAfterUpdatingMarkers = true;
  @Input() clickEvent = true;
  @Input() getUsersLocationOnInit: boolean = true;
  @Input() invalidateSize: boolean;
  // @Input() showRouteByRouteOptions: boolean = false;

  @Output() addressChanged = new EventEmitter<any>();
  @Output() markerObjectClicked = new EventEmitter<any>();

  streetMaps;
  wMaps;
  summit;
  paradise;
  layersControl;
  options;
  polylines;
  isMapReady = false;
  markerObjects = [];
  map: Map;

  currentLocation: string;
  subscription: Subscription;

  defaultColorPolylines = true;
  defaultWidthPolylines = true;

  isHideMap: boolean = false;

  allPolylines;

  
  constructor(
    private httpClient: HttpClient,
    private geoPositioningService: GeoPositioningService
  ) {}

  ngOnInit(): void {
    this.initialize();
    this.subscription = new Subscription();
  }

  initialize(): void {
    this.streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    this.wMaps = tileLayer('http://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
      detectRetina: true,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    this.createMarkerControls();

    this.layersControl = {
      baseLayers: {
        'Street Maps': this.streetMaps,
        'Wikimedia Maps': this.wMaps
      },
      // overlays: {
      //   'Mt. Rainier Climb Route': this.polylines
      // }
    };

    let layers = [this.streetMaps];

    if (this.routes.length > 0) {
      this.makePolylines();
    }

    // this.allRoutes = cloneDeep(this.routes)

    layers = layers.concat(this.markerObjects);

    this.options = {
      layers: layers,
      zoom: 7,
      // center: latLng([44.879966, 21.726909])
    };

    this.isMapReady = true;
  }

  // POLYLINES

  makePolylines(defaultColor: boolean = false, defaultWidth: boolean = false, fitBounds = true): void {
    this.polylines = this.allPolylines = [];

    if(!defaultColor && !defaultWidth) {
      this.defaultWidthPolylines = false;
      this.defaultColorPolylines = false;
    }
    else if(!defaultColor) {
      this.defaultWidthPolylines = true;
    }
    else if(!defaultWidth) {
      this.defaultColorPolylines = true;
    }

    for(let route of this.routes) {
      const color = (route.color && !defaultColor && !this.defaultColorPolylines) ? route.color : this.lineColor;
      const weight = (route.width && !defaultWidth && !this.defaultWidthPolylines) ? route.width : this.lineWidth;

      const polylinee = new polyline(route.coordinates, { color: color, weight: weight });
      this.polylines.push(polylinee);
      this.allPolylines.push(polylinee);
      
    }
    // this.layersControl['overlays'] = {
    //   'Mt. Rainier Climb Route': this.polylines
    // }

    // layers.push(this.polylines);
    if(!defaultColor && !defaultWidth && this.fitBounds && fitBounds)
      this.zoomByFitBounds();
    
  }


  // EVENTS

  onMapReady(map: Map): void {
    this.map = map;
    this.zoom();

    if (this.form != null) {
      this.trackLongitudeInput();
      this.trackLatitudeInput();
    }

    if(this.getUsersLocationOnInit)
      this.getLocation();

    // setInterval(()=>{
    //   let xyz = map.getBounds().getCenter();
    // }, 2500)
  }

  invalidateSizeForMap(): void {
    if(this.map) {
      this.map.invalidateSize();
      if(this.fitBounds)
        this.zoomByFitBounds();
    }
  }

  clickOnMap(event): void {
    this.changeMarkerPosition(event.latlng);
  }

  changeMarkerPosition(latlng): void {
    if(this.clickEvent) {
      let latitude = latlng.lat;
      let longitude =latlng.lng;
  
      this.markers[0] = {
        latitude: latitude,
        longitude: longitude
      }
  
      this.createMarkerControls();
      if (this.form)
        this.fillLatLongInputs();
  
      this.getLocation();
  
      // this.addressChanged.emit({
      //   latitude: latitude,
      //   longitude: longitude
      // })
    }
  }

  ngOnChanges(changes: any): void {
    if (changes['markers'] && !changes['markers'].isFirstChange()) {
      this.createMarkerControls();
      if(this.zoomAfterUpdatingMarkers)
        this.zoom(true);
    }
    if (changes['zoomIn'] && !changes['zoomIn'].isFirstChange()) {
      this.zoomByZoomIn();
    }
    if (changes['lineColor'] && !changes['lineColor'].isFirstChange()) {
      this.makePolylines(true, false);
    }
    if (changes['lineWidth'] && !changes['lineWidth'].isFirstChange()) {
      this.makePolylines(false, true);
    }
    if (changes['routes'] && !changes['routes'].isFirstChange()) {
      this.makePolylines(false, false);
      // this.currentPolylineIndex = -1;
      // this.showAddDeleteButtons = false;
      // this.allRoutes = cloneDeep(this.routes);
      // this.headerForm.get("routeByRoute").setValue(0);
    }
    if(changes['invalidateSize']) {
      this.invalidateSizeForMap();
    }
  }

  // ZOOM

  zoom(zoomToFirstMarker = false): void {
    if (this.map) {
      if (zoomToFirstMarker || (!this.fitBounds && !this.zoomIn)) {
        this.zoomByFirstMarker();
      }
      else if (this.fitBounds) {
        if(this.polylines)
          this.zoomByFitBounds();
        else if(this.markers.length > 0)
          this.zoomByFirstMarker();
      }
      else {
        this.zoomByZoomIn();
      }
    }
  }

  zoomByFitBounds(): void {
    if (this.map && this.polylines && this.polylines.length > 0) {
      const allBounds = [];
      for(let item of this.polylines) {
        allBounds.push(item.getBounds())
      }

      this.map.fitBounds(allBounds, {
        padding: point(24, 24),
        maxZoom: 18,
        animate: true
      });
    }
  }

  zoomByZoomIn(): void {
    if (this.map)
      this.map.setView([this.zoomIn.latitude, this.zoomIn.longitude], 15);
  }

  zoomByFirstMarker(): void {
    if (this.map)
      this.map.setView([this.markers[0].latitude, this.markers[0].longitude], 15);
  }

  // MARKERS

  createMarkerControls(): void {
    this.markerObjects = [];
    if(this.markers) {
      for (let marker of this.markers) {
        let x = this.createMarkerObject(marker);
        this.markerObjects.push(x);
      }
    }
  }

  createMarkerObject(markerObj): void {
    let markerIcon = markerObj.forFilter ? "assets/images/map-icons/rsz_black-marker.png" : 'assets/images/map-icons/default-marker.png';
    let markerSize = markerObj.forFilter ? [20,20] : [25,41];
    let markerAnchor = markerObj.forFilter ? [13,25] : [13,41];

    let isDraggable = this.clickEvent && !markerObj.isClickable;

    let x = marker([markerObj.latitude, markerObj.longitude], {
      icon: icon({
        iconSize: markerSize,
        iconAnchor: markerAnchor,
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon
        // shadowUrl: 'leaflet/marker-shadow.png'
      }), draggable: isDraggable //, clickable: true
    });

    if (markerObj.isClickable) {
      x.on('click', data => {
        this.markerObjectClicked.next({
          latitude: data.latlng.lat,
          longitude: data.latlng.lng
        });
      });
    }

    if(isDraggable) {
      x.on('dragend', e => {
        this.changeMarkerPosition(e.target._latlng);
      })  
    }
    
    // x.on('mouseover', data => {
    // })

    if(markerObj.title || markerObj.desc) {
      x.bindPopup(markerObj.title ? markerObj.title : markerObj.desc, {className: 'map-popup'});

      x.on('mouseover', function (this:any, e) {
          this.openPopup();
      });
      x.on('mouseout', function (this:any, e) {
          this.closePopup();
      });
    }


    return x;
  }

  // INPUTS

  trackLongitudeInput(): void {
    this.subscription.add(this.form.get('longitude').valueChanges.subscribe({
      next: value => {
        this.refreshMarkersWhenFormInputsAreChanged();
      }
    }));
  }

  trackLatitudeInput(): void {
    this.subscription.add(this.form.get('latitude').valueChanges.subscribe({
      next: value => {
        this.refreshMarkersWhenFormInputsAreChanged();
      }
    }));
  }

  refreshMarkersWhenFormInputsAreChanged(): void {
    const latitude = this.form.get('latitude').value;
    const longitude = this.form.get('longitude').value;

    this.markers[0] = {
      latitude: !latitude ? 0.0 : parseFloat(latitude),
      longitude: !longitude ? 0.0 : parseFloat(longitude)
    };

    this.getLocation();
    this.createMarkerControls();
    this.zoomByFirstMarker();
  }

  fillLatLongInputs(): void {
    this.form.get('latitude').setValue(this.markers[0].latitude, { emitEvent: false });
    this.form.get('longitude').setValue(this.markers[0].longitude, { emitEvent: false });
  }

  // SERVICES CALLING

  getLocation(): void {
    if(this.markers && this.markers[0]) {
      // SpinnerFunctions.showSpinner();
      let geoReverseService = 'https://nominatim.openstreetmap.org/reverse?key=iTzWSiYpGxDvhATNtSrqx5gDcnMOkntL&format=json&addressdetails=1&lat={lat}&lon={lon}';
      const service = (geoReverseService || '')
        .replace(new RegExp('{lon}', 'ig'), `${this.markers[0].longitude}`)
        .replace(new RegExp('{lat}', 'ig'), `${this.markers[0].latitude}`);
  
      this.httpClient.get(service).subscribe({
        next: data => {
          // SpinnerFunctions.hideSpinner();
          const val = (data || {});
          const currentAdrress = this.geoPositioningService.locationFormat(val['address']);
          this.currentLocation = currentAdrress;
        }, 
        error: err => {
          // SpinnerFunctions.hideSpinner();
        }
      });
    }
  }

  showHideMap(event): void {
    event.stopPropagation();
    this.isHideMap = !this.isHideMap;
    if(this.isHideMap) {
      document.querySelector(".leaflet-layer").classList.add("none");
    }
    else {
      document.querySelector(".leaflet-layer").classList.remove("none");
    }
  }

  addPolyline(route): void {
    const polylinee = new polyline(route.coordinates, { color: route.color, weight: 3, groupId: route.groupId });
    this.polylines.push(polylinee);
    this.allPolylines.push(polylinee);
  }

  removePolyline(lastGroupId): void {
    const indexOfFirst = this.polylines.findIndex(x => x.options.groupId === lastGroupId);
    this.polylines.splice(indexOfFirst);
    this.allPolylines.splice(indexOfFirst);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // currentPolylineIndex: number = -1
  // allRoutes: any;
  // showAddDeleteButtons: boolean = false;

  // headerForm = new FormGroup({
  //   routeByRoute: new FormControl(0)
  // });

  // changeRouteByRouteOption(event): void {
  //   const selectedValue = event.value;
  //   this.currentPolylineIndex = -1;

  //   if(this.allRoutes.length > 0) {
  //     const indexOfLastRoute = this.routes.length - 1;
  //     this.showAddDeleteButtons = selectedValue === 1;
  //     this.routes[indexOfLastRoute].coordinates = selectedValue ? [] : this.allRoutes[indexOfLastRoute].coordinates;
  //     this.makePolylines(false, false, false);
  //   }

  // }

  // addRoute(): void {
  //   if(this.allRoutes.length > 0) {
  //     this.currentPolylineIndex++;
  //     const indexOfLastRoute = this.routes.length - 1;
  //     this.routes[indexOfLastRoute].coordinates.push(this.allRoutes[indexOfLastRoute].coordinates[this.currentPolylineIndex] );
  //     this.makePolylines(false, false, false);

  //     if(this.currentPolylineIndex == 0)
  //       this.addRoute();
  //   }
    
  // }

  // removeRoute(): void {
  //   if(this.allRoutes.length > 0) {
  //     this.currentPolylineIndex--;
  //     const indexOfLastRoute = this.routes.length - 1;
  //     this.routes[indexOfLastRoute].coordinates.pop();
  //     this.makePolylines(false, false, false);
  //     if(this.currentPolylineIndex == 0)
  //       this.removeRoute();
  //   }
  // }

}