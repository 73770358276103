import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackpackHelperFunctionsService {

  constructor() { }

  breakApartStavke(data: any) {
    let dataFromService: any = data[0]? data[0]: data;
    dataFromService.items.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl ? ctrl : '';
      });
      delete row.stavke;
    });

    dataFromService.containers.forEach((row) => {
      row.stavke = row.stavke.split(",");
      row.stavke.forEach((ctrl, index) => {
        row['c' + index] = ctrl ? ctrl : '';
      });
      delete row.stavke;
    });
  }

  mapiranjeNizaNizova(arr: any) {
    let nizNizova: any = [];
    for(let item of arr) {
      let nizTmp: any = Object.values(item);
      nizNizova.push(nizTmp)
    }
    return nizNizova;
  }

  createStaticsArrayObjectNewForm(size: any) {
    let noviNiz: any = [];
    let objRed: any = Object.create( {} );
    if (size) {
      for (let i = 0; i < size; i++) {
        objRed = {};
        for (let j = 0; j < size; j++) {
          objRed["c"+j] = "0";
        }
        noviNiz.push(objRed)
      }
    }
    return noviNiz;
  }

  //primjer pravljenja niza stringova mapiranjem
  mapToSingleArrayStavke(arr: any[], form: any) {
    let namesOfDynamicControls = [];
    Object.keys(arr[0]).forEach(el => {
      if (el.startsWith("c")) {
        namesOfDynamicControls.push(el);
      }
    })
    arr.forEach(el => {
      el.stavke = "";
      el.tempArr = [];
      namesOfDynamicControls.forEach(name => {
        el.tempArr.push(el[name]);
        delete el[name];
      })
      el.stavke = el.tempArr.join(',')
      delete el["tempArr"];
    })
    return arr;
  }

  createStaticsArrayObjectNewFormColumns(size: any, columns = 5) {
    let noviNiz: any = [];
    let objRed: any = Object.create({});
    if (size) {
      for (let i = 0; i < size; i++) {
        objRed = {};
        for (let j = 0; j < columns; j++) {
          objRed["c" + j] = "0";
        }
        noviNiz.push(objRed)
      }
    }
    return noviNiz;
  }
  
}
