<div class="dashboard-content source-destination"
  [ngClass]="(dataService.showTabs && dataService.problemType.shortcut == 'cvrp') ? 'all-header-buttons' : null">
  <div class="row">
    <div class="col-md-12">
      <mat-tab-group #tabsElement (selectedTabChange)="onTabChange($event)"
        [selectedIndex]="selectedIndex"
        dynamicHeight="true" class="custom-tab-group">
        <mat-tab label="{{'SourceAndDestinations' | translate}}">
          <app-source-destination-tab-dashboard-distribution>
          </app-source-destination-tab-dashboard-distribution>
        </mat-tab>
        @if (dataService.isCalculated) {
          <mat-tab [disabled]="dataService.typeOfMap == 'graph'" label="{{'Map' | translate}}" >
            <app-main-map-tab-dashboard-distribution></app-main-map-tab-dashboard-distribution>
          </mat-tab>
          <mat-tab label="{{'Results' | translate}}">
            <app-reports-tab-dashboard-distribution></app-reports-tab-dashboard-distribution>
          </mat-tab>
          @if (dataService.problemType.shortcut == 'sssp' && dataService.mapByIterations.routes.length > 0) {
            <mat-tab label="{{'MapByIterations' | translate}}">
              <app-map-by-iterations-tab-dashboard-distribution></app-map-by-iterations-tab-dashboard-distribution>
            </mat-tab>
          }
        }
      </mat-tab-group>
    </div>
  </div>
</div>
