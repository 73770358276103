export interface IAuthToken {
    aud: string
    exp: number
    iat: number
    iss: string
    jti: string
    nbf: number
    sub: string
    given_name: string // ime kompanije
    email: string // email kompanije
    family_name: string // adresa kompanije
    nameid: string // telefon kompanije
    unique_name: string //username
    website: string //image pathName
    birthdate: string //first i last name
    

}

export const AuthTokenFixedProps = ["aud", "exp", "iat","iss","jti","nbf","sub","given_name"];