<form [formGroup]="form" class="custom-form" style="height: 100%;">

  <div class="content-wrapper">
    <div class="upper">
      <!-- Heading Input Data Tab -->
      @if (!dataService.originalObject.getValue() || !dataService.originalObject.getValue().id) {
        <div class="row tab-header">
          <div class="col-md-8">
          </div>
          <div class="col-md-4 tab-actions">
            @if (formService.form.get('rows')['controls']?.length>0) {
              <button type="button" mat-flat-button color="primary"
                class="custom-flat-btn"
                (click)="saveData()"
                [disabled]="!this.form.get('rows').valid">{{"Insert" |
                translate}}
              </button>
            }
          </div>
        </div>
      }
      @if (!form.get('rows').valid) {
        <div class="row">
          <div class="col-md-12 alert alert-warning" role="alert">
            {{"The value entered in the table field is higher than" |
            translate}}&nbsp;<b>{{"P" | translate}}:&nbsp;{{formService.configP}}</b>.<br/>
            {{"The value entered in the table field must not be less than" |
            translate}}<b>&nbsp;1</b>.
          </div>
        </div>
      }

      <!-- End Heading Input Data Tab -->
      <!-- Table Input Data Tab -->
      @if (dataService.originalObject.getValue() && dataService.originalObject.getValue().id) {
        <div class="row">
          <div class="col-sm-4 input-mobile">
            <mat-form-field appearance="outline" [ngClass]="formService.form.get('objective').errors ? 'requiredField' : ''">
              <mat-label>{{ "Objective" | translate}}</mat-label>
              <mat-select [formControlName]="formService.form.keys.objective"
                panelClass="custom-mat-select-panel-class"
                disableOptionCentering>
                @for (option of dropdownData.Objectives; track option) {
                  <mat-option [value]="option.id">
                    {{option.title | translate}}
                  </mat-option>
                }
              </mat-select>
              <mat-error class="customError">
                @if (formService.form.get('objective').hasError('required')) {
                  {{"ThisFieldIsRequired" | translate}}
                }
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-4 input-mobile">
            <mat-form-field appearance="outline" [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''">
              <mat-label>{{ "Algorithm" | translate}}</mat-label>
              <mat-select (selectionChange)="changeAlgorithm($event.value)" [formControlName]="formService.form.keys.algorithm" disableOptionCentering panelClass="custom-mat-select-panel-class">
                @for (option of dropdownData.Algorithms; track option) {
                  <mat-option  [value]="option.id">
                    {{option.title | translate}}
                  </mat-option>
                }
              </mat-select>
              <mat-error class="customError">
                @if (formService.form.get('algorithm').hasError('required')) {
                  {{"ThisFieldIsRequired" | translate}}
                }
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-2 input-mobile">
          </div>
          <div class="col-sm-2 btn-run">
          </div>
        </div>
      }

      <div class="row">
        <div class="col-sm-4 input-mobile"></div>
        <div class="col-sm-4 input-mobile">
          @if (form.get('algorithmParams')['controls']) {
            <ng-container formArrayName="algorithmParams">
              @for (item of formService.parametri; track item; let i = $index) {
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{item | translate}}</mat-label>
                      <input [formControl]="form.get('algorithmParams')['controls'][i].get('value')" matInput autocomplete="off" type="number">
                    </mat-form-field>
                  </div>
                </div>
              }
            </ng-container>
          }
        </div>
        <div class="col-sm-4 input-mobile"></div>
      </div>
      <div class="row">
        <div class="col-sm-12 custom-form reduced-form">
          <cdk-virtual-scroll-viewport style="width: 100%; height: 330px; overflow-y: auto;" minBufferPx="200"
            maxBufferPx="400" [itemSize]="50">
            <div>
              @if (form.get('rows')['controls']?.length > 0) {
                <table class="table table-sm table-striped tab-input-data hide-subscript">
                  <thead class="thead-dark">
                    <tr>
                      <th>&nbsp;</th>
                      @for (header of tableService.headersTable1; track header) {
                        <th>{{header.index}}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *cdkVirtualFor="let item of form.get('rows')['controls']; let i=index">
                      <tr>
                        <td>{{i+1}}</td>
                        @for (cols of numbers; track cols; let j = $index) {
                          <td>
                            <mat-form-field appearance="outline">
                              <input
                                [formControl]="form.get('rows')['controls'][i].get('c'+j)"
                                matInput autocomplete="off" />
                              </mat-form-field>
                            </td>
                          }
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                } @else {
                  <div class="alert alert-warning" role="alert">
                    {{"NoRecordsFound" | translate}}
                  </div>
                }
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
        <!-- End Table Input Data Tab -->
      </div>
      <div class="lower">
        <!-- Second Block -->
        @if (formService.isShowOtherTabs && dataService.dataForSecondBlock) {
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm table-striped input-data-info">
                <tbody>
                  <tr>
                    <td><b>{{"Min number of cells" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.minNumberCells}}</td>
                  </tr>
                  <tr>
                    <td><b>{{"Max number of cells" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.maxNumberCells}}</td>
                  </tr>
                  <tr>
                    <td><b>{{"Group of machines" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.groupOfMachines}}</td>
                  </tr>
                  <tr>
                    <td><b>{{"Group of parts" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.groupOfParts}}</td>
                  </tr>
                  <tr>
                    <td><b>{{"Degrees of machines" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.degreesOfMachines}}</td>
                  </tr>
                  <tr>
                    <td><b>{{"Degrees of parts" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.degreesOfParts}}</td>
                  </tr>
                  <tr>
                    <td><b>{{"Resid. of parts" | translate}}</b></td>
                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.residOfParts}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              @for (table of dataService.dataForSecondBlock?.rowsTab1.table2?.statistics; track table) {
                <table class="table table-sm table-striped input-data-info">
                  <tbody>
                    @for (data of table.stavke; track data) {
                      <tr>
                        <td>{{data.name | translate}}</td>
                        <td>{{data.value}}</td>
                      </tr>
                    }
                  </tbody>
                </table>
                <hr />
              }
            </div>
          </div>
        }
        <!-- End Second Block -->
      </div>
    </div>
  </form>
