import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiGetAllResponse } from '../../../../shared/interfaces/api-get-all--response';
import { ApiService } from '../../../../shared/services/api.service';
import { IDistribution1ToNRun } from '../../interfaces/i-distribution-1-to-n';
import { IMapByDistances } from '../../interfaces/i-map-by-distances';

@Injectable({
  providedIn: 'root'
})
export class MapByDistancesService extends ApiService<IMapByDistances> {

  constructor(http: HttpClient)  {
    super(apiPaths.routing.mapByDistances, http);
  }

  calculate(data: IDistribution1ToNRun): Observable<any> {
    return this.http.post<any>(`${this.baseUrl + apiPaths.routing.mapByDistances}`, data).pipe(
      map((items: any) => {
        let data = items.hasOwnProperty('data') ? items['data'] : items;
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

}
