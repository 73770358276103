import { Component, OnInit } from '@angular/core';
import { BlGraphNodesDataService } from '../../services/shared/bl-graph-nodes-data.service';

@Component({
  selector: 'app-dashboard-graph-nodes',
  templateUrl: './dashboard-graph-nodes.component.html',
  styleUrls: ['./dashboard-graph-nodes.component.scss']
})
export class DashboardGraphNodesComponent implements OnInit {

  constructor(
    public dataService: BlGraphNodesDataService
  ) { }

  ngOnInit(): void {
    this.dataService.resetData();
    this.dataService.showContent = false;
  }

}
