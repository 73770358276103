import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IGraphNode, IGraphNodeItem, IGraphNodeNewForm } from '../../../../interfaces/i-graph-node';
import { BlNewGraphNodesFormService } from '../../../../services/forms/bl-new-graph-nodes-form.service';
import { BlGraphNodesDataService } from '../../../../services/shared/bl-graph-nodes-data.service';

@Component({
  selector: 'app-new-graph-nodes',
  templateUrl: './new-graph-nodes.component.html',
  styleUrls: ['./new-graph-nodes.component.scss']
})
export class NewGraphNodesComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<NewGraphNodesComponent>,
    public formService: BlNewGraphNodesFormService,
    public dataService: BlGraphNodesDataService
  ) {}

  form: FormGroupTypeSafe<IGraphNodeNewForm>;

  ngOnInit(): void {
    this.formService.reset();
    this.form = this.formService.getFormReference();
    ValidationFunctions.runValidation(this.form);
  }

  close(): void {
    this.ref.close();
  }

  save(): void {
    this.dataService.resetData();
    this.dataService.numberOfNodesForNewItem = parseInt(this.form.getSafe(x => x.numberOfNodes).value);
    this.dataService.currentObj = this.makeCurrentObj();
    this.dataService.currentObjBS.next(this.makeCurrentObj());

    this.dataService.showContent = true;
    this.close();
  }

  makeCurrentObj(): IGraphNode {
    const formValue: IGraphNodeNewForm = this.form.value;

    const currentObj: IGraphNode = {
      id: null,
      name: formValue.title,
      nodes: this.makeNodes()
    };

    return currentObj;
  }

  makeNodes(): IGraphNodeItem[] {
    const numberOfNodes = parseInt(this.form.getSafe(x => x.numberOfNodes).value);
    const nodes: IGraphNodeItem[] = [];
    for(let i=0; i<numberOfNodes; i++) {
      nodes.push({
        id: null,
        node1: null,
        node2: null,
        distance: null,
        counter: i+1
      })
    }

    return nodes;
  }

}