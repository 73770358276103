import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { IThreeDRecord } from '../../interfaces/i-three-d-record';

@Injectable({
  providedIn: 'root'
})
export class BlThreeDRecordsDataService extends BlDataLogicService implements ITableStorage<IThreeDRecord> {

  constructor(
  ) {
    super();
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<IThreeDRecord[]> = new BehaviorSubject<IThreeDRecord[]>([]);

  originalData: IThreeDRecord[];

  reportTables = [];

  getOriginalData(): IThreeDRecord[] {
    return this.originalData;
  }

  setOriginalData(items: IThreeDRecord[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IThreeDRecord[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IThreeDRecord[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IThreeDRecord[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IThreeDRecord[], setCurrentStorageValueAsOriginalData?: boolean): void {}

}