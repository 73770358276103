import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Pipe({
  name: 'disabledFormButton'
})
export class DisabledFormButtonPipe implements PipeTransform {

  transform(form: UntypedFormGroup, rowIndex: number): any {
    return !(form.get("items") as UntypedFormArray).controls[rowIndex].valid;
  }

}
