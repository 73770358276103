import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { INPUT_METHOD } from '../../../../../../config/settings/inputMethod';
import { FileType } from '../../../../../../shared/enums/file-type';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IPfspConfigForm, IPfspDropdownData } from '../../../../interfaces/i-pfsp';
import { BlPfspFormService } from '../../../../services/form/bl-pfsp-form.service';
import { BlPfspDataService } from '../../../../services/shared/bl-pfsp-data.service';
import { PfspHelperFunctionsService } from '../../../../services/helper/pfsp-helper-functions.service';

@Component({
  selector: 'app-form-dashboard-pfsp',
  templateUrl: './form-dashboard-pfsp.component.html',
  styleUrls: ['./form-dashboard-pfsp.component.scss']
})
export class FormDashboardPfspComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<FormDashboardPfspComponent>,
    public formService: BlPfspFormService,
    private matDialog: MatDialog,
    private alertService: AlertMessageDataService,
    private dataService: BlPfspDataService,
    private translateService: TranslateService,
    private pfspHelperFunctionsService: PfspHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();

  public formConfig: FormGroupTypeSafe<IPfspConfigForm> = this.formService.getFormConfigReference();

  public dropdownData: IPfspDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    TargetFunctions: null,
    Methods: null,
    Pfsps: null
  }

  public fileData: FormData;
  public fileName: any = "";
  public countFiles: any = 0
  public allowedFileTypes: string[] = [FileType.csv]

  ngOnInit(): void {
    this.dropdownData.Methods = INPUT_METHOD;

    this.formConfig.getSafe(x => x.inputMethod).valueChanges.subscribe({
      next: val => {
        if (val == 1) {
          this.formService.isImportMethod = true;
          this.formService.formConfig.get("m").setValue(3);
          this.formService.formConfig.get("n").setValue(5);
          this.formService.formConfig.get("title").setValue("");
          this.translateService.get("File not chosen.").subscribe({
            next: data => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          });
        } else if (val == 0) {
          this.formService.isImportMethod = false;
          this.translateService.get("File not chosen.").subscribe({
            next: data => {
              if (data) {
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          });
        }
      }
    });
  }

  onFileChanged(file){
    this.fileData = file;
    this.countFiles = 1;
  }

  close(state?: any) {
    this.ref.close(state);
  }

  save() {
    if (this.formService.isImportMethod == false) {
      let formValue: any = this.formService.formConfig.value;

      this.subscription.add(
        this.dataService.originalObject.next(formValue)
      );

      this.subscription.add(
        this.dataService.storageIsTabsView.next(true)
      );

      this.subscription.add(
        this.dataService.storageDataIsShowOtherTabs.next(false)
      );

      this.subscription.add(
        this.dataService.storageIsRunResults.next(false)
      );

      this.dataService.setIdForPatch(-1);
      this.alertService.setMessage("success", "Successfully applied.");
      this.close(this.formConfig.getSafe(x => x.title).value);

      this.formService.isRunProblemForTitle = false;
      this.formService.problemTitle = "";

    } else {
      if (this.fileData) {

        SpinnerFunctions.showSpinner();
        this.formService.uploadCSV(this.fileData).subscribe({
          next: success => {
            this.pfspHelperFunctionsService.breakApartStavke(success);
            this.dataService.storageIsTabsView.next(true);
            this.dataService.storageDataIsShowOtherTabs.next(true);
            this.dataService.storageIsRunResults.next(false);
            this.dataService.originalObject.next(success);

            this.dataService.idForPatch = success["id"];
            this.formService.problemTitle = success["title"];

            this.alertService.setMessage("success", "Successfully uploaded CSV.");
            this.countFiles = 0;
            this.close(success["title"]);
            SpinnerFunctions.hideSpinner();
          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
          }
        });
      } else {
        this.alertService.setMessage("danger", "You must select a CSV file and then upload it.")
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.formConfig.get("m").setValue(3);
    this.formService.formConfig.get("n").setValue(5);
    this.formService.formConfig.get("title").setValue("");
    this.formService.formConfig.get("inputMethod").setValue(0);
  }

}
