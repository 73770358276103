import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';
import { BlDeterministicMrpDataService } from '../../../../services/shared/bl-deterministic-mrp-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-save-as-new-dialog',
  templateUrl: './save-as-new-dialog.component.html',
  styleUrls: ['./save-as-new-dialog.component.scss'],
  host: {'collision-id': 'MRPSaveAsNewComponent'}
})
export class SaveAsNewDialogComponent implements OnInit {

  constructor(
    private fb : UntypedFormBuilder,
    public dataService: BlDeterministicMrpDataService,
    public dialogRef: MatDialogRef<SaveAsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public form : UntypedFormGroup;
  ngOnInit(): void {
    this.form = this.fb.group({
      title:['',[Validators.required]]
    })

    ValidationFunctions.runValidation(this.form);
  }

  close(){
    this.dialogRef.close();
  }

  complete(){
    this.dialogRef.close(this.form.get("title").value);
  }

}
