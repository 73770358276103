export class ArrayFunctions {
    static removeItem(item, array){
        array.splice(this.findIndex(item,array),1);
    }

    static findIndex(item,array){
        return array.indexOf(item);
    }

    static inArray(item, array : any[]){
        return (!array) ? false : array.includes(item);
    }

    static fromNumber(nums : number){
        return Array(nums).fill(0).map((x,i)=> i+1);
    }

    static fromNumberZeros(nums : number){
        return Array(nums).fill(0).map((x,i)=> 0);
    }

    static fromNumberValue(nums : number, value){
        return Array(nums).fill(0).map((x,i)=> value);
    }

    static isArrayMadeFromTheSameElements(arr){
            var x= arr[0];
            return arr.every(function(item){
                return item=== x;
            });
    }

    static crossCompareArrays(arrayA, arrayB){
        let compare_A_B = arrayA.find(z => ArrayFunctions.inArray(z, arrayB));
        let compare_B_A = arrayB.find(z => ArrayFunctions.inArray(z, arrayA));
        return compare_A_B || compare_B_A;
    }

    static distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    static arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;
      
        for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
      }

    static jsonEqual(a, b): boolean {   
        return JSON.stringify(a) === JSON.stringify(b);   
    }
    
    static hasDuplicates(arr){
        return (new Set(arr)).size !== arr.length;
    }


}