import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';
import { Distribution1ToNOverviewService } from '../../../../services/api/distribution-1-to-n-overview.service';
import { BlDistribution1ToNOverviewDataService } from '../../../../services/shared/bl-distribution-1-to-n-overview-data.service';
import { BlDistribution1ToNOverviewTableService } from '../../../../services/table/bl-distribution-1-to-n-overview-table.service';

@Component({
  selector: 'app-overview-dashboard-distribution',
  templateUrl: './overview-dashboard-distribution.component.html',
  styleUrls: ['./overview-dashboard-distribution.component.scss']
})
export class OverviewDashboardDistributionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    protected matDialog: MatDialog,
    public apiService: Distribution1ToNOverviewService,
    public tableService: BlDistribution1ToNOverviewTableService,
    public dataService: BlDistribution1ToNOverviewDataService
  ) {}

  ngOnInit(): void {
  }

  close(): void {
    this.matDialog.closeAll();
  }

}
