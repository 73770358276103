import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicAlgorithmFormControlsService } from '../../../../core/services/dynamic-algorithm-form-controls.service';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { IBackpackProblemConfigForm, IBackpackProblemFilterForm, IBackpackProblemForm } from '../../interfaces/i-backpack-problem';
import { UploadBackpackProblemCsvService } from '../api/upload-backpack-problem-csv.service';
import { BlBackpackProblemDataService } from '../shared/bl-backpack-problem-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlBackpackProblemFormService implements IFormService<IBackpackProblemForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private uploadCSVService: UploadBackpackProblemCsvService,
    private dataService: BlBackpackProblemDataService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  formConfig: FormGroupTypeSafe<IBackpackProblemConfigForm> = this.initConfig();
  form: FormGroupTypeSafe<IBackpackProblemForm> = this.init();
  formFilter: FormGroupTypeSafe<IBackpackProblemFilterForm> = this.initFilter();

  public isShowRunButton: boolean = false;
  public isShowSavesButtons: boolean = false;
  public isDisabledEditFlowButton: boolean = false;
  public isDisabledEditContainersButton: boolean = false;
  // public isLiteratureProblem: boolean = false;
  public isFromViewDialog: boolean = false;
  public isProblemLoad: boolean = false;
  public isExportShow: boolean = false;
  public isImportMethod: boolean = false;
  public isRunProblemForTitle: boolean = false;
  public problemType: string = "";
  public sizes: number = 0;
  public reducedNumbersArray: any = [];
  public reducedItemsArray: any = [];
  public reducedContainersArray: any = [];
  public rowData: any = null;
  public problemTitle: string = "";
  public algorithmData: any = [];

  public parametri: any = [];

  initConfig(): FormGroupTypeSafe<IBackpackProblemConfigForm> {
    let group: FormGroupTypeSafe<IBackpackProblemConfigForm> = this.formBuilder.group<IBackpackProblemConfigForm>({
      size: this.formBuilder.control(1, [Validators.required, Validators.min(1)]),
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
      problemType: this.formBuilder.control("c"),
      inputMethod: this.formBuilder.control(0)
    });
    ValidationFunctions.runValidation(group);
    return group;

  }
  init(): FormGroupTypeSafe<IBackpackProblemForm> {
    let group: FormGroupTypeSafe<IBackpackProblemForm> = this.formBuilder.group<IBackpackProblemForm>({
      algorithm: this.formBuilder.control(""),
      items: this.formBuilder.array([]),
      containers: this.formBuilder.array([]),
      algorithmParams: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;

  }

  initFilter(): FormGroupTypeSafe<IBackpackProblemFilterForm> {
    let group: FormGroupTypeSafe<IBackpackProblemFilterForm> = this.formBuilder.group<IBackpackProblemFilterForm>({
      problemTypes: this.formBuilder.control(""),
    });

    return group;

  }

  getFormConfigReference(): FormGroupTypeSafe<IBackpackProblemConfigForm> {
    return this.formConfig;
  }

  getFormReference(): FormGroupTypeSafe<IBackpackProblemForm> {
    return this.form;
  }

  getFilterFormReference(): FormGroupTypeSafe<IBackpackProblemFilterForm> {
    return this.formFilter;
  }

  setItemsRows(rowData: any) {
    this.form.removeControl("items");
    this.form.addControl("items", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.items;
      let numberRows: any = rowData.items.length;
      let numberCols: any = this.dataService.itemNumberOfColumns;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.items) as UntypedFormArray).push(
          new UntypedFormGroup({

          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.items) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row[i]["c" + j], [Validators.required]));
        }
      }
      (this.form.get("items") as UntypedFormArray).controls = [...(this.form.get("items") as UntypedFormArray).controls];
    }
    ValidationFunctions.runValidation(this.form);
  }

  createItemsTableWithDynamicRows(numberColumns: any, numberRows: number) {
    this.removeItemsTableDYnamicRows();
    if (numberRows) {
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.items) as UntypedFormArray).push(
          new UntypedFormGroup({
          })
        );
        for (let j = 0; j < numberColumns; j++) {
          ((this.form.getSafe(x => x.items) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control("0", [Validators.required]));
        }
      }
      (this.form.get("items") as UntypedFormArray).controls = [...(this.form.get("items") as UntypedFormArray).controls];
    }
  }

  removeItemsTableDYnamicRows() {
    this.form.removeControl("items");
    this.form.addControl("items", this.formBuilder.array([]));
  }

  setContainersRows(rowData: any) {
    this.form.removeControl("containers");
    this.form.addControl("containers", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.containers;
      let numberRows: any = rowData.containers.length;
      let numberCols: any = this.dataService.containerNumberOfColumns;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.containers) as UntypedFormArray).push(
          new UntypedFormGroup({

          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.containers) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row[i]["c" + j], [Validators.required]));
        }
      }
      (this.form.get("containers") as UntypedFormArray).controls = [...(this.form.get("containers") as UntypedFormArray).controls];
    }
    ValidationFunctions.runValidation(this.form);
  }

  createContainersTableWithDynamicRows(numberColumns: any, numberRows: number) {
    this.removeContainersTableDYnamicRows();
    if (numberRows) {

      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.containers) as UntypedFormArray).push(
          new UntypedFormGroup({
          })
        );
        for (let j = 0; j < numberColumns; j++) {
          ((this.form.getSafe(x => x.containers) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control("0", [Validators.required]));
        }
      }
      (this.form.get("containers") as UntypedFormArray).controls = [...(this.form.get("containers") as UntypedFormArray).controls];
    }
  }

  removeContainersTableDYnamicRows() {
    this.form.removeControl("containers");
    this.form.addControl("containers", this.formBuilder.array([]));
  }


  prepareDataToSend(multipart?: any) {
    let formData = new FormData();
    if (multipart) {
      formData.append('files', multipart[0]);
    } else {
      formData.append('files', null);
    }
    delete formData["size"];
    return formData as any;

  }

  uploadCSV(multipart?: any) {
    let dataToSend: any = this.prepareDataToSend(multipart);
    return this.uploadCSVService.create(dataToSend, true, true);
  }

  addContainer(): void {
    (this.form.getSafe(x => x.containers) as UntypedFormArray).push(new UntypedFormGroup({}));
    const controlsLength = (this.form.getSafe(x => x.containers) as UntypedFormArray).controls.length;
    for(let i=0; i<this.dataService.containerNumberOfColumns; i++) {
      ((this.form.getSafe(x => x.containers) as UntypedFormArray).controls[controlsLength-1] as UntypedFormGroup).addControl("c" + i, this.formBuilder.control("0", [Validators.required]));
    }
  }

  removeContainer(index: number): void {
    (<UntypedFormArray>(this.form.getSafe(x => x.containers))).removeAt(index);
  }

  addItem(): void {
    (this.form.getSafe(x => x.items) as UntypedFormArray).push(new UntypedFormGroup({}));
    const controlsLength = (this.form.getSafe(x => x.items) as UntypedFormArray).controls.length;
    for(let i=0; i<this.dataService.itemNumberOfColumns; i++) {
      ((this.form.getSafe(x => x.items) as UntypedFormArray).controls[controlsLength-1] as UntypedFormGroup).addControl("c" + i, this.formBuilder.control("0", [Validators.required]));
    }
  }

  removeItem(index: number): void {
    (<UntypedFormArray>(this.form.getSafe(x => x.items))).removeAt(index);
  }

  reset(): void {
    this.form = this.init();
  }

  resetAlgorithms(): void {
    this.dynamicAlgorithmFormControlsService.resetAlgorithmFormArray(this.form);
    this.parametri = [];
  }

  resetConfig(): void {
    this.formConfig = this.initConfig();
  }

  resetFilter(): void {
    this.formFilter = this.initFilter();
  }

}
