import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicAlgorithmFormControlsService } from '../../../../core/services/dynamic-algorithm-form-controls.service';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { IPfspConfigForm, IPfspFilterForm, IPfspForm } from '../../interfaces/i-pfsp';
import { UploadPfspCSVService } from '../api/upload-pfsp-csv.service';

@Injectable({
  providedIn: 'root'
})
export class BlPfspFormService implements IFormService<IPfspForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private uploadCSVService: UploadPfspCSVService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  formConfig: FormGroupTypeSafe<IPfspConfigForm> = this.initConfig();
  form: FormGroupTypeSafe<IPfspForm> = this.init();
  formFilter: FormGroupTypeSafe<IPfspFilterForm> = this.initFilter();

  public isShowRightBlock: boolean = false;
  public isShowRunButton: boolean = false;
  public isShowSavesButtons: boolean = false;
  public isShowMainTabs: boolean = false;
  public isShowOtherTabs: boolean = false;
  // public isLiteratureProblem: boolean = false;
  public isFromViewDialog: boolean = false;
  // public isProblemLoad: boolean = false;
  public isExportShow: boolean = false;
  public isImportMethod: boolean = false;
  public isRunProblemForTitle: boolean = false;
  public problemType: string = "";
  public numberRows: number = 0;
  public numberCols: number = 0;
  public originalNumbersArray: any = [];
  public reducedNumbersArray: any = [];
  public rowData: any = null;
  public problemTitle: string = "";
  public algorithmData: any = [];

  public parametri: any = [];

  initConfig(): FormGroupTypeSafe<IPfspConfigForm> {
    let group: FormGroupTypeSafe<IPfspConfigForm> = this.formBuilder.group<IPfspConfigForm>({
      m: this.formBuilder.control(3, [Validators.required, Validators.min(3)]),
      n: this.formBuilder.control(5, [Validators.required, Validators.min(5)]),
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
      problemType: this.formBuilder.control("c"),
      inputMethod: this.formBuilder.control(0)
    });
    ValidationFunctions.runValidation(group);
    return group;

  }
  init(): FormGroupTypeSafe<IPfspForm> {
    let group: FormGroupTypeSafe<IPfspForm> = this.formBuilder.group<IPfspForm>({
      targetFunction: this.formBuilder.control("", [Validators.required]),
      algorithm: this.formBuilder.control("", [Validators.required]),
      algorithmParams: this.formBuilder.array([]),
      rows: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;

  }

  initFilter(): FormGroupTypeSafe<IPfspFilterForm> {
    let group: FormGroupTypeSafe<IPfspFilterForm> = this.formBuilder.group<IPfspFilterForm>({
      problemTypes: this.formBuilder.control(""),
    });

    return group;

  }

  getFormConfigReference(): FormGroupTypeSafe<IPfspConfigForm> {
    return this.formConfig;
  }

  getFormReference(): FormGroupTypeSafe<IPfspForm> {
    return this.form;
  }

  getFilterFormReference(): FormGroupTypeSafe<IPfspFilterForm> {
    return this.formFilter;
  }

  setRows(rowData: any) {
    this.form.removeControl("rows");
    this.form.addControl("rows", this.formBuilder.array([]));
    if (rowData) {
      let row: any = rowData.leftBlock.table;
      let numberRows: any = rowData.m;
      let numberCols: any = rowData.n;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rows) as UntypedFormArray).push(
          new UntypedFormGroup({

          })
        );
        for (let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.rows) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control(row[i]["c" + j]));
        }
      }
      (this.form.get("rows") as UntypedFormArray).controls = [...(this.form.get("rows") as UntypedFormArray).controls];
    }
    ValidationFunctions.runValidation(this.form);
  }

  createTableWithDynamicRows(numberColumns: any, numberRows: number) {
    this.removeTableDYnamicRows();
    if (numberRows) {
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rows) as UntypedFormArray).push(
          new UntypedFormGroup({
          })
        );
        for (let j = 0; j < numberColumns; j++) {
          ((this.form.getSafe(x => x.rows) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c" + j, this.formBuilder.control("0", [Validators.required]));
        }
      }
      (this.form.get("rows") as UntypedFormArray).controls = [...(this.form.get("rows") as UntypedFormArray).controls];
    }
  }

  removeTableDYnamicRows() {
    this.form.removeControl("rows");
    this.form.addControl("rows", this.formBuilder.array([]));
  }

  formateArrayNumbers(numberCols: number){
    this.originalNumbersArray = [];
    for(let i = 1; i<=numberCols; i++) {
      this.originalNumbersArray.push(i);
    }
  }

  prepareDataToSend(multipart?: any) {
    let formData = new FormData();
    if (multipart) {
      formData.append('files', multipart[0]);
    } else {
      formData.append('files', null);
    }
    return formData as any;

  }

  uploadCSV(multipart?: any) {
    let dataToSend: any = this.prepareDataToSend(multipart);
    return this.uploadCSVService.create(dataToSend, true, true);
  }

  reset(): void {
    this.form = this.init();
  }

  resetAlgorithms(): void {
    this.dynamicAlgorithmFormControlsService.resetAlgorithmFormArray(this.form);
    this.parametri = [];
  }

  resetConfig(): void {
    this.formConfig = this.initConfig();
  }

  resetFilter(): void {
    this.formFilter = this.initFilter();
  }

}
