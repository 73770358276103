import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { apiPaths } from '../../../config/api';
import { ApiService } from '../../../shared/services/api.service';
import { IShowAreaOnMap, IShowAreaOnMapRequest } from '../../interfaces/i-show-area-on-map';

@Injectable({
  providedIn: 'root'
})
export class ShowAreaOnMapService extends ApiService<IShowAreaOnMap>{

  constructor(http: HttpClient) {
    super(apiPaths.admin.showAreaOnMap, http);
  }

  getMapData(id: number) {
    return this.http.get<any>(`${ this.baseUrl + this.url + '/' + id}`).pipe(
      catchError(this.handleErrors)
    );
  }

}
