import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-button-with-spinner',
  templateUrl: './button-with-spinner.component.html',
  styleUrls: ['./button-with-spinner.component.scss']
})
export class ButtonWithSpinnerComponent implements OnInit {

  constructor() { }
  @Input() isClickedSave: boolean;
  @Input() data: any;
  @Input() form: UntypedFormGroup;
  @Input() hideButton: boolean;
  @Input() buttonText: string;
  
  ngOnInit() {
  }

}
