import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '../../config/api';
import { ApiService } from '../../shared/services/api.service';
import { IProperty } from '../interfaces/i-property';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService extends ApiService<IProperty> {

  constructor(
    http: HttpClient
  ) {
    super(apiPaths.auth.properties, http);
  }
}
