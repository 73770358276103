import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as fromRoot from '../../auto-logout/redux/reducers';
import { AuthService } from '../../services/auth.service';
import { CheckEvents } from '../helpers/check-events';
import { AutoLogoutService } from '../services/auto-logout.service';
import * as ApplicationActions from './actions';
import { config } from '../../../config/global';
import { LocalStorageFunctions } from '../../../shared/functions/local-storage-functions';
import { LocalStorageKeys } from '../../../shared/enums/local-storage-keys';

@Injectable()
export class ApplicationEffects {
    private APPLICATION_TIMEOUT_TIME = config.autoLogoutTime;
    private startTime: number = 0;

    extendApplicationTimeout$ = createEffect(() => this.actions$.pipe(
        switchMap((action: Action) => {
            this.APPLICATION_TIMEOUT_TIME = config.autoLogoutTime;
            if (action.type == ApplicationActions.LOG_OUT) {
                this.authService.logout();
                return new Observable<any>()
            };
            if (this.authService.getAuthToken() == null)
                return new Observable<any>();

            // new - on return on page (open browser again) if time is left - auto logout
            if (localStorage.getItem("auth") != null && ((Date.now() - +LocalStorageFunctions.get(LocalStorageKeys.last_event_time)) >= (config.autoLogoutTime + config.stayLoggedInPeriod))) {
                this.store.dispatch(new ApplicationActions.LogOut());
                return new Observable<any>();
            }
            this.startTime = Date.now();

            return timer(this.APPLICATION_TIMEOUT_TIME); // change to be from token
        }),
        map((x) => {

            if (this.authService.getAuthToken() != null && Date.now() - LocalStorageFunctions.get(LocalStorageKeys.last_event_time) >= config.autoLogoutTime) {
                this.autoLogout.startStopwatch.next(true);
                this.APPLICATION_TIMEOUT_TIME = config.stayLoggedInPeriod;
            }
            CheckEvents.isEventEmmited = false;
            return new ApplicationActions.LogIn();
        })));

    constructor(private actions$: Actions, private authService: AuthService, private router: Router, private autoLogout: AutoLogoutService, private store: Store<fromRoot.State>, ) { }
}