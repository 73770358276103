<div class="tree" *ngIf="tree.width && tree.height">
    <div class="wrapper">
        <svg class="treeLink" [style.width]="tree.width+'px'" [style.height]="tree.height+'px'">
            <ng-container *ngFor="let node of tree.nDatabaseNodes">
                <path [attr.d]="node | orgChartDrawChildrenLinks : tree" />
            </ng-container>
        </svg>
        <ng-container *ngFor="let node of tree.nDatabaseNodes;let i=index">
            <div [ngStyle]="{position:'absolute',left:node.XPosition+'px',top:node.YPosition+'px',width:node.w+'px',height:node.h+'px'}">
                <ng-container *ngTemplateOutlet="defaultTemplate;context:{$implicit:node}" ></ng-container>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #defaultTemplate let-node>
    <div class="item">
        <span class="bold">{{node.name}}</span>
        <span>{{node.title}}</span>
    </div>
</ng-template>