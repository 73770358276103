import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { AlertMessageDataService } from '../../../../../../../../../../shared/services/alert-message-data.service';
import { BlQapFormService } from '../../../../../../../../services/forms/bl-qap-form.service';
import { BlQapRequestsService } from '../../../../../../../../services/requests/bl-qap-requests.service';
import { BlQapDataService } from '../../../../../../../../services/shared/bl-qap-data.service';
import { BlQapDistancesTableService } from '../../../../../../../../services/table/bl-qap-distances-table.service';
import { EditDistancesDialogDashboardQapComponent } from '../../../../../edit-distances-dialog-dashboard-qap/edit-distances-dialog-dashboard-qap.component';
import { QapHelperFunctionsService } from '../../../../../../../../services/helper/qap-helper-functions.service';

@Component({
  selector: 'app-tab-dashboard-input-data-right-side',
  templateUrl: './tab-dashboard-input-data-right-side.component.html',
  styleUrls: ['./tab-dashboard-input-data-right-side.component.scss']
})
export class TabDashboardInputDataRightSideComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private dataService: BlQapDataService,
    public formService: BlQapFormService,
    public tableService: BlQapDistancesTableService,
    private alertService: AlertMessageDataService,
    // public translateService: TranslateService,
    private requestService: BlQapRequestsService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public dataForTable: any = [];
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  public isProblemLiterature: boolean = false;

  ngAfterViewInit(): void {
    this.dataService.originalObject.subscribe({
      next: (data) => {
        if (data) {
          this.dataFromStorageForSend = data[0] ? data[0] : data;

          if(this.dataFromStorageForSend.problemType == 'l') {
            this.isProblemLiterature = true;
          } else {
            this.isProblemLiterature = false;
          }

          let podaci: any = data[0] ? data[0].distances : data.distances;
          if(podaci) {
            this.tableService.createDynamicHeaderColumnsTabl2(this.formService.sizes);
            this.dataForTable = this.flowsAndDistancesArrayFunstionsService.mapiranjeNizaNizova(podaci);
          }
        }
      }
    });
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          if(data) {
            if (data.id) {
              this.dataFromStorage = data;
    
              if(this.dataFromStorage.problemType == 'l') {
                this.isProblemLiterature = true;
              } else {
                this.isProblemLiterature = false;
              }
              this.tableService.createDynamicHeaderColumnsTabl2(this.formService.sizes);
              let podaci: any = data[0] ? data[0].distances : data.distances;
              this.dataForTable = this.flowsAndDistancesArrayFunstionsService.mapiranjeNizaNizova(podaci);            
            } else {
              this.dataFromStorageForSend = data;
              this.formService.sizes = this.dataFromStorageForSend.size;
              this.tableService.createDynamicHeaderColumnsTabl2(this.formService.sizes);
    
              if(this.dataFromStorageForSend.problemType == 'l') {
                this.isProblemLiterature = true;
              } else {
                this.isProblemLiterature = false;
              }
    
              let prepakovanNizObjekata: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.formService.sizes);
              this.dataForTable = this.flowsAndDistancesArrayFunstionsService.mapiranjeNizaNizova(prepakovanNizObjekata);
            }
          }
        }
      })
    );
  }

  openEditDialog() {
    this.matDialog.open(EditDistancesDialogDashboardQapComponent, {
      width: '90%',
      maxWidth: '1200px',
      height: 'auto',
      minHeight: '150px',
      panelClass: 'customModal'
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.sizes = 0;
    this.clickedButton = false;
    this.isProblemLiterature = false;
    this.dataForTable = [];
    this.dataForSend = [];
    this.dataFromStorage = [];
    this.dataFromStorageForSend = [];
  }

}
