<div
  [ngClass]="!(dataService.originalObject.getValue() || dataService.originalObject.getValue() || mainTitle || dataService.storageIsRunResults.getValue()) ? 'fixed-page-header' : 'relative-page-header'">

  <div class="row">
    <!-- Header CFP -->
    <div class="col-md-12">
      <h3 class="page-header-title"> <span>{{"CSM" | translate}}</span> / {{"CFP" | translate}}</h3>
    </div>
    <!-- End Header CFP -->
  </div>

  <div class="row">
    <!-- Buttons for Add,  View -->
    <div class="col-md-12">
      <div class="header-cms">
        <div class="header-button-box" [ngClass]="formService.isShowOtherTabs ? 'all-buttons' : null">
          <button type="button" mat-stroked-button color="accent" (click)="newCFP()"
            [disabled]="!permissionsService.havePermission('cell-formation-problem', 'ability-to-create-new-problem')">
            <mat-icon>create</mat-icon>{{"New" | translate}}
          </button>
          <button type="button" mat-stroked-button color="accent" (click)="viewCFP()"
            [disabled]="!permissionsService.havePermission('cell-formation-problem', 'ability-to-open-all-problems')">
            <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
          </button>
          <button type="button" mat-stroked-button color="accent" (click)="testCFP()"
            [disabled]="!permissionsService.havePermission('cell-formation-problem', 'ability-to-test-problem')">
            <mat-icon>sticky_note_2</mat-icon>{{"Test" | translate}}
          </button>
          <ng-container *ngIf="formService.isShowOtherTabs">
            <button type="button" *ngIf="formService.isLiteratureProblem!=true"
              mat-stroked-button color="accent" (click)="saveData()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-save-problem') || !formService.form.get('rows').valid)">
              <mat-icon>album</mat-icon>{{"Save" | translate}}
            </button>
            <button type="button" mat-stroked-button color="accent" (click)="saveAsNew()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-save-as') || !formService.form.get('rows').valid)">
              <mat-icon>album</mat-icon>{{"Save As" | translate}}
            </button>
            <button type="button" mat-stroked-button color="accent" (click)="runBrusco()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-run-brusco') || !formService.form.get('rows').valid  || !formService.form.get('algorithm').valid || !formService.form.get('objective').valid || !formService.form.get('algorithmParams').valid)">
              <mat-icon>school</mat-icon>{{"Run" | translate}}
            </button>
            <button type="button" mat-stroked-button color="accent" (click)="runSimilarity()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-run-similarity') || !formService.form.get('rows').valid)">
              <mat-icon>loop</mat-icon>{{"Similarity" | translate}}
            </button>
            <ng-container *ngIf="formService.isRunProblemForTitle == true; else noRunReport">
              <span class="problem-title" [innerHTML]="formService.problemTitle | safeHtml">
              </span>
            </ng-container>
            <ng-template #noRunReport>
              <span class="problem-title" *ngIf="dataService.originalObject.getValue() || mainTitle" [innerHTML]="dataService.originalObject.getValue() ?
                  (dataService.originalObject.getValue()[0] ?
                  dataService.originalObject.getValue()[0].description :
                  dataService.originalObject.getValue().description) : mainTitle">
              </span>
            </ng-template>
          </ng-container>
        </div>
        <div class="help-block">
          <ng-container *ngIf="formService.isExportShow==true">
            <button type="button" mat-stroked-button color="accent" (click)="export()">
              <mat-icon>save_alt</mat-icon>{{"Export CSV" | translate}}
            </button>
          </ng-container>
          <button type="button" mat-stroked-button color="accent" (click)="openMarkdown()">
            <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
          </button>
        </div>
      </div>
    </div>
    <!-- End Buttons for Add,  View -->
  </div>

  <div class="row">
    <div class="col-md-12 iframe-wrapper"
      *ngIf="!(dataService.originalObject.getValue() || dataService.originalObject.getValue() || mainTitle || dataService.storageIsRunResults.getValue())">
      <div class="iframe-holder">
        <iframe src="assets/markdown/cms/cell-formation-problem/help.html" width="100%" height="100%" frameborder="5">
        </iframe>
      </div>
    </div>
  </div>

</div>
