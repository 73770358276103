import { LocalStorageFunctions } from "../../../shared/functions/local-storage-functions";
import { LocalStorageKeys } from "../../../shared/enums/local-storage-keys";


export class CheckEvents {
    public static isEventEmmited = false;
    public static eventTime : number;

    public static updateLastEventTime(){
        CheckEvents.isEventEmmited = true;
        CheckEvents.eventTime = Date.now();
        LocalStorageFunctions.set({key:LocalStorageKeys.last_event_time, value: CheckEvents.eventTime.toString()});
    }
    
}