import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { IPfspSaveAsForm } from '../../interfaces/i-pfsp';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { Validators } from '@angular/forms';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';

@Injectable({
  providedIn: 'root'
})
export class BlPfspSaveAsFormService implements IFormService<IPfspSaveAsForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  form: FormGroupTypeSafe<IPfspSaveAsForm> = this.init();

  init(): FormGroupTypeSafe<IPfspSaveAsForm> {
    let group: FormGroupTypeSafe<IPfspSaveAsForm> = this.formBuilder.group<IPfspSaveAsForm>({
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
    });
    ValidationFunctions.runValidation(group);
    return group;

  }


  getFormReference(): FormGroupTypeSafe<IPfspSaveAsForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }

}
