import { IOption } from "../../../shared/interfaces/i-option";

export const PLANNING_PERIODS: IOption[] = [
    {
        id: 1,
        title: "Day"
    },
    {
        id: 2,
        title: "Week"
    },
    {
        id: 3,
        title: "Month"
    },
    {
        id: 4,
        title: "Quater"
    }
]