import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BlPfspFormService } from '../../../../services/form/bl-pfsp-form.service';
import { BlPfspDataService } from '../../../../services/shared/bl-pfsp-data.service';

@Component({
  selector: 'app-tabs-dashboard-pfsp',
  templateUrl: './tabs-dashboard-pfsp.component.html',
  styleUrls: ['./tabs-dashboard-pfsp.component.scss']
})
export class TabsDashboardPfspComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dataService: BlPfspDataService,
    public formService: BlPfspFormService,
    private cdr: ChangeDetectorRef
  ) { }

  public canChangeTab: boolean = true;
  public selectedTab: number = 0;
  public isResultsRun: boolean = false;

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: data => {
        this.formService.isShowOtherTabs = data;
      }
    });

    this.dataService.storageIsRunResults.subscribe({
      next: isRun => {
        this.isResultsRun = isRun;
        if (isRun)
          this.selectedTab = 6;
        else
          {
            if(this.selectedTab == 6) {
              this.selectedTab = 0;
            }
          }
      }
    })

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab == 0) {

    }
  }

  ngOnDestroy(): void {
    this.isResultsRun = false;
  }

}
