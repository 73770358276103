import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { ICfpTestForm } from '../../interfaces/i-cell-formation-problem';

@Injectable({
  providedIn: 'root'
})
export class BlCfpTestFormService implements IFormService<ICfpTestForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  public isShowReportTable: boolean = false;

  form: FormGroupTypeSafe<ICfpTestForm> = this.init();

  init(): FormGroupTypeSafe<ICfpTestForm> {
    let group: FormGroupTypeSafe<ICfpTestForm> = this.formBuilder.group<ICfpTestForm>({
      instance: this.formBuilder.control("", [Validators.required]),
      algorithm: this.formBuilder.control("", [Validators.required]),
      objective: this.formBuilder.control("", [Validators.required]),
      algorithmParams: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;

  }


  getFormReference(): FormGroupTypeSafe<ICfpTestForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }
}
