import { Component, OnInit } from '@angular/core';
import { ReportsFunctions } from '../../../../../../../../shared/functions/reports-functions';
import { BlThreeDRecordsDataService } from '../../../../../../services/shared/bl-three-d-records-data.service';

@Component({
  selector: 'app-three-d-table-report-backpack-problem',
  templateUrl: './three-d-table-report-backpack-problem.component.html',
  styleUrls: ['./three-d-table-report-backpack-problem.component.scss']
})
export class ThreeDTableReportBackpackProblemComponent implements OnInit {

  constructor(
    public dataService: BlThreeDRecordsDataService
  ) { }

  ngOnInit(): void {
  }

  printReport(): void {
    ReportsFunctions.printReport();
  }

  public regex =  /^[\-]?[\d]+(([\,]|[\.])[\d]+)*$/ ;


}
