<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form class="reducedForm" [formGroup]="formService.form" (ngSubmit)="save()">
  <div mat-dialog-content>

    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{"Add" | translate}} {{"GraphNode" | translate}}
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field color="primary" appearance='outline' [ngClass]="formService.form.get('title').errors ? 'requiredField' : ''">
          <mat-label>{{'Title' | translate}}</mat-label>
          <input matInput [formControlName]="formService.form.keys.title" />
          <mat-error class="customError">
            @if (formService.form.get('title').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline"
          [ngClass]="formService.form.get('numberOfNodes').errors ? 'requiredField' : ''">
          <mat-label> {{"NumberOfNodes" | translate}} </mat-label>
          <input type="number" min=1 [formControlName]="form.keys.numberOfNodes" matInput
            autocomplete="off" />
            <mat-error class="customError">
              @if (formService.form.get('numberOfNodes').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>

    <div mat-dialog-actions>
      <div class="row dialog-action-row">
        <div class="col-sm-12 dialog-action-box">
          <button mat-flat-button disableRipple color="accent" type="submit"
          [disabled]="form.invalid">{{'Apply' | translate}}</button>
          <button mat-flat-button disableRipple type="button" color="warn" (click)="close()">{{'Close' | translate}}</button>
        </div>
      </div>
    </div>

  </form>
