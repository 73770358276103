import { Component, OnInit } from '@angular/core';
import { BlGeoLocationDataService } from '../../../../services/shared/bl-geo-location-data.service';

@Component({
  selector: 'app-tabs-geo-location',
  templateUrl: './tabs-geo-location.component.html',
  styleUrls: ['./tabs-geo-location.component.scss']
})
export class TabsGeoLocationComponent implements OnInit {

  constructor(
    private dataService: BlGeoLocationDataService
  ) {}

  ngOnInit(): void {
  }

  onTabChange(event): void {
    this.dataService.triggerInvalidateSizeOnMap = !this.dataService.triggerInvalidateSizeOnMap;
  }

}
