import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlRequestService } from '../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { AdminConfigRoutingService } from '../api/admin-config-routing.service';

@Injectable({
  providedIn: 'root'
})
export class BlAdminConfigRoutingRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: AdminConfigRoutingService
  ) {
    super(alertService);
  }

  getAll(): Observable<any> {
    return this.apiService.getAllMaps();
  }

  updateDownloadMaps(objectToSend): Observable<any> {
    return this.apiService.updateDownloadMaps(objectToSend);
  }

}
