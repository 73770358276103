import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'actionColumn'
})
export class ActionColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.hasOwnProperty('options') && column.options != null;
  }

}
