import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';
import { IDistribution1ToNNodesFilter } from '../../interfaces/i-distribution-1-to-n-nodes-filter';

@Injectable({
  providedIn: 'root'
})
export class Distribution1ToNNodesFilteringService extends ApiService<IDistribution1ToNNodesFilter>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.nodesFiltering, http);
  }

  filterNodes(obj: any): Observable<any> {
    return this.http.post(`${ this.baseUrl + this.url }`, obj).pipe(
      catchError(this.handleErrors)
    );
  }

}
