import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { GraphNodesService } from '../../../../services/api/graph-nodes.service';
import { BlGraphNodesDataService } from '../../../../services/shared/bl-graph-nodes-data.service';
import { BlGraphNodesItemsDataService } from '../../../../services/shared/bl-graph-nodes-items-data.service';
import { BlGraphNodesItemsTableService } from '../../../../services/table/bl-graph-nodes-items-table.service';

@Component({
  selector: 'app-table-graph-nodes',
  templateUrl: './table-graph-nodes.component.html',
  styleUrls: ['./table-graph-nodes.component.scss']
})
export class TableGraphNodesComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: GraphNodesService,
    public dataService: BlGraphNodesItemsDataService,
    public tableService: BlGraphNodesItemsTableService,
    private mainDataService: BlGraphNodesDataService
  ) {}

  private subscription = new Subscription();

  ngOnInit(): void {
    this.trackSelectedItem();
    this.addDeleteColumn();
  }

  trackSelectedItem(): void {
    this.subscription.add(this.mainDataService.currentObjBS.subscribe({
      next: currentObj => {
        this.dataService.setStorage(currentObj.nodes);
      }
    }));
  }

  deletePairOfNodes(formValue): void {
    let x = this.mainDataService.currentObj.nodes.filter(x => x.counter != formValue.counter);
    this.mainDataService.currentObj.nodes = x;
    this.dataService.setStorage([]);
    this.dataService.setStorage(x);
  }

  changeValue(event): void {
    const counter = event.row.counter;
    const x = this.mainDataService.currentObj.nodes;
    x.find(x => x.counter == counter)[event.controlName] = Number(event.value);
    this.mainDataService.currentObj.nodes = x;
  }

  addNewPairOfNodes(): void {
    const x = cloneDeep(this.mainDataService.currentObj.nodes);
    const newCounter = ++(cloneDeep(x[x.length - 1]).counter);
    x.push({
      counter: newCounter,
      id: null,
      node1: null,
      node2: null,
      distance: null
    })
    this.mainDataService.currentObj.nodes = x;
    this.dataService.setStorage(x);
  }

  addDeleteColumn(): void {
    this.tableService.addColumnWithDialog({
      index: "actions",
      title: "Actions",
      options: [
        {
          title: "Delete",
          class: "deleteGraphNodePairBtn ",
          isFormControl: false,
          method: (formValue) => {
            this.deletePairOfNodes(formValue);
          }
        }
      ],
      hide: false
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}