import * as _ from "lodash";

export class ObjectFunctions {
    static getKeys(object : any){
        return Object.keys(object);
    }

    static isEmptyObject(object : any){
        return Object.keys(object).length === 0;
    }

    static clearObject(obj) {
        for (var propName in obj) { 
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
        return obj;
    }

    static makeComputedProperty(key, value) {
        return {
          [key] : value
        }
      }

    static objectsAreSame(x, y, props?) {
        var objectsAreSame = true;
        if(props){
          let length = props.length;
          for(let i=0; i<length; i++){
              if(x[props[i]]!=y[props[i]]){
                  objectsAreSame=false;
                  break;
              }
          }
        }
        else {
            for(var propertyName in x) {
                if(x[propertyName] !== y[propertyName]) {
                   objectsAreSame = false;
                   break;
                }
             }
        }
        return objectsAreSame;
     }

/** @description Returns whole different object even if there is change in only one property 
   * @param {any[]} x Original object
   * @param {any[]} y Updated object
   */
     static areArraysOfObjectsDifferent(x : any[], y: any[]){
         if(x.length != y.length)
         return true;

         let arrLength = x.length;
         let keys = Object.keys(x[0]);
         let keysLength = Object.keys(x[0]).length;

         for(let i=0; i<keysLength; i++){
             for(let j=0; j<arrLength; j++){
                 for(let k=0; k<arrLength; k++){
                     if(x[j][keys[i]]!=y[k][keys[i]])
                     return true;
                 }
             }
         }
         return false;
     }

     static twoArraysDifference(a1, a2){
        var a = [], diff = [];

        for (var i = 0; i < a1.length; i++) {
            a[a1[i]] = true;
        }
    
        for (var i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
                delete a[a2[i]];
            } else {
                a[a2[i]] = true;
            }
        }
    
        for (var k in a) {
            diff.push(k);
        }

        return diff;
        }
}