<!-- Header Dialog-->
<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
        </div>
    </div>
</div>
<!-- End Header Dialog-->
<!-- Form Dialog-->
<form class="reducedForm" [formGroup]="formService.form" (ngSubmit)="save()">
    <div mat-dialog-content>

        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{"Add" | translate}}&nbsp; {{"MRP Specification" | translate}}
                </h3>
            </div>
        </div>

        <!-- Title Control -->
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field color="accent" appearance="outline" [ngClass]="formService.form.get('title').errors ? 'requiredField' : ''">
                    <mat-label>{{"Problem Title" | translate}}</mat-label>
                    <input [formControlName]="form.keys.title" matInput autocomplete="off" />
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('title').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                        <ng-container *ngIf="formService.form.get('title').hasError('noWhiteSpace')">
                            {{"NoWhiteSpace" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!-- End Title Control -->


        <div class="row">
            <!-- Number Of Product And Part Items Control -->
            <div class="col-sm-6 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('noProductPartItems').errors ? 'requiredField' : ''">
                    <mat-label>{{"Number Of Product And Part Items" | translate}}</mat-label>
                    <input type="number" min=1 [formControlName]="form.keys.noProductPartItems" matInput autocomplete="off" />
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('noProductPartItems').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- End Number Of Product And Part Items Control -->

            <!-- Maximum Number Of Direct Components Per Parent Item Control -->
            <div class="col-sm-6 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('maxNoOfDirectComponent').errors ? 'requiredField' : ''">
                    <mat-label>{{"Maximum Number Of Direct Components Per Parent Item" | translate}}</mat-label>
                    <input type="number" min=1 [formControlName]="form.keys.maxNoOfDirectComponent" matInput autocomplete="off" />
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('maxNoOfDirectComponent').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- End Maximum Number Of Direct Components Per Parent Item Control -->
        </div>

        <div class="row">
            <!-- Time Unit Of Planning Period Control -->
            <div class="col-sm-4">
                <mat-form-field appearance="outline" color="accent" [ngClass]="formService.form.get('timeUnitPlanningPeriod').errors ? 'requiredField' : ''">
                    <mat-label>{{"Time Unit Of Planning Period" | translate}}</mat-label>
                    <mat-select (selectionChange)="onSelectOption($event)" [formControlName]="form.keys.timeUnitPlanningPeriod" disableOptionCentering>
                        <mat-option *ngFor="let option of dropdownData.PlanningPeriods" [value]="option.title">
                            {{option.title}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="customError ellipsis">
                        <ng-container *ngIf="formService.form.get('timeUnitPlanningPeriod').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- End Time Unit Of Planning Period Control -->

            <!-- Number Of Planning Periods Control -->
            <div class="col-sm-4 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('noPlanningPerod').errors ? 'requiredField' : ''">
                    <mat-label>{{"Number Of Planning Periods" | translate}}</mat-label>
                    <input type="number" min=1 [formControlName]="form.keys.noPlanningPerod" matInput autocomplete="off" />
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('noPlanningPerod').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- End Number Of Planning Periods Control -->

            <!-- Number Of Periods Per Year Control -->
            <div class="col-sm-4 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('noPeriodsYear').errors ? 'requiredField' : ''">
                    <mat-label>{{"Number Of Periods Per Year" | translate}}</mat-label>
                    <input type="number" [min]=formService.minValues [max]=formService.maxValues [formControlName]="form.keys.noPeriodsYear" matInput autocomplete="off" />
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('noPeriodsYear').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                        <ng-container *ngIf="formService.form.get('noPeriodsYear').hasError('min')">
                            {{"MinimalValueValidation" | translate}}&nbsp; {{this.formService.minValues}}.
                        </ng-container>
                        <ng-container *ngIf="formService.form.get('noPeriodsYear').hasError('max')">
                            {{"MaximalValueValidation" | translate}}&nbsp; {{this.formService.maxValues}}.
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- End Number Of Periods Per Year Control -->
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button disableRipple color="accent" type="submit" [disabled]="!checkIfFormIsValid()">{{'Apply' | translate}}</button>
        <button mat-flat-button disableRipple type="button" color="warn" (click)="close()">{{'Close' | translate}}</button>
    </div>
</form>
<!-- End Form Dialog-->
