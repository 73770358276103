import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminConfigRoutingModule } from './admin-config-routing.module';
import { DashboardAdminConfigComponent } from './components/dashboard-admin-config/dashboard-admin-config.component';
import { HeaderAdminConfigComponent } from './components/dashboard-admin-config/components/header-admin-config/header-admin-config.component';
import { TabsAdminConfigComponent } from './components/dashboard-admin-config/components/tabs-admin-config/tabs-admin-config.component';
import { RoutingAdminConfigComponent } from './components/dashboard-admin-config/components/tabs-admin-config/components/routing-admin-config/routing-admin-config.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { ShowAreaOnMapComponent } from './components/dashboard-admin-config/components/tabs-admin-config/components/show-area-on-map/show-area-on-map.component';


@NgModule({
  declarations: [DashboardAdminConfigComponent, HeaderAdminConfigComponent, TabsAdminConfigComponent, RoutingAdminConfigComponent, ShowAreaOnMapComponent],
  imports: [
    CommonModule,
    AdminConfigRoutingModule,
    SharedModule,
    CoreModule
  ]
})
export class AdminConfigModule { }