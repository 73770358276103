import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertMessageDataService } from '../../shared/services/alert-message-data.service';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
    if(next.data.hasOwnProperty('section')){

      if(next.data.hasOwnProperty('child')){
        if(!this.authService.getUserRoles(next.data['section'], next.data['child'])){
          this.goToUrl();
          return false;
        }
        return true;
      }else {
        if(!this.authService.getUserRoles(next.data['section'])){
          this.goToUrl();
          return false;
        }
        return true;
      }
       
    }
    this.alertService.setMessage('danger','Upps. Section is not defined.');
    return false;
  }

  constructor(private authService : AuthService, private alertService : AlertMessageDataService, private router: Router){}


  goToUrl(){
    this.alertService.setMessage('danger','You dont have access to perform this action.');
    this.authService.goToStartModule();

  }
}
