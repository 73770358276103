import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';

@Component({
  selector: 'app-save-as-new-graph-nodes',
  templateUrl: './save-as-new-graph-nodes.component.html',
  styleUrls: ['./save-as-new-graph-nodes.component.scss']
})
export class SaveAsNewGraphNodesComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SaveAsNewGraphNodesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public form: UntypedFormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['', [Validators.required]]
    });

    ValidationFunctions.runValidation(this.form);
  }

  close(): void {
    this.dialogRef.close();
  }

  complete(): void {
    this.dialogRef.close(this.form.get("title").value);
  }

}