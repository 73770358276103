import { AbstractControl, ValidationErrors } from "@angular/forms";

export function onlyIntegerValidator(control: AbstractControl) : ValidationErrors | null {
    return (control.value == "" || control.value == null || checkIfIsInteger(control.value)) ? null : { notNumeric: true }
}

function checkIfIsInteger(value): boolean {
    // return ((parseFloat(value) == parseInt(value)) && !isNaN(value));
    const intRegex = /^(\d)+$/;
    return intRegex.test(value);
}