import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidationFunctions } from '../functions/validation-functions';
import { AlertMessage } from '../interfaces/alert-message';
import { config } from '../../config/global';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageDataService {

  private alertMessage = new BehaviorSubject<AlertMessage[]>(null);
  private alertMessage$ = this.alertMessage.asObservable();

  constructor() { }

  setMessage(type, content){
    let message = {
      type: type,
      content: content
    };
    let data = this.alertMessage.getValue()==null? [] : this.alertMessage.getValue();
    data = data.concat(message)
    this.alertMessage.next(data);
    this.removeAllMessages();
  }

  setMessages(messages : AlertMessage[]){
    messages =  messages.map(message=> { 
      return { 
        type: message.type,
        content: message.content
      } 
    });
    let data = this.alertMessage.getValue()==null? [] : this.alertMessage.getValue();
    data.concat(messages);
    this.alertMessage.next(data);
    this.removeAllMessages();
  }

  getAlertMessages$() : Observable<AlertMessage[]>{
    return this.alertMessage$;
  }

  getAlertMessages(){
    return this.alertMessage.getValue()==null ? [] : this.alertMessage.getValue();
  }

  setFormErrorsMessages(form : UntypedFormGroup){
    let errors = ValidationFunctions.getFormErrorMessages(form);
		this.setMessages(errors);
  }

  removeAllMessages(){
      setTimeout(() => {
        this.alertMessage.next([]);
        }, config.alertMessagesPresentTime
      );
  }
}
