<div class="row extended-width">
  <div class="col-sm-12">
    <div class="row drag-drop-wrapper">
      <div class="col-md-12">
        <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
          @for (data of this.formService.reducedNumbersArray; track data) {
            <div class="example-box" cdkDrag>{{data}}</div>
          }
        </div>
      </div>
    </div>
    <div class="row first dynamic-wrapper">
      <div class="col-md-12 dynamic-column">
        @if (dataFromStorage?.rightBlock?.tables?.length > 0) {
          @for (data of dataFromStorage?.rightBlock.tables; track data; let i = $index) {
            <table class="table table-sm table-dynamic">
              <thead class="thead-dark">
                <tr>
                  <th>&nbsp;</th>
                  @for (header of tableService.headersTable; track header) {
                    <th>{{header.title}}</th>
                  }
                </tr>
              </thead>
              <tbody>
                @for (tableRow of data.tableData; track tableRow; let j = $index) {
                  <tr>
                    <td>{{j+1}}</td>
                    @for (columns of tableRow.stavke; track columns) {
                      <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                        {{columns.value}}
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          }
        } @else {
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-warning" role="alert">
                {{"NoRecordsFound" | translate}}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>