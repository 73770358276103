import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlDeterministicMrpDataService } from '../../../../../../services/shared/bl-deterministic-mrp-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-dashboard-action-list',
  templateUrl: './tab-dashboard-action-list.component.html',
  styleUrls: ['./tab-dashboard-action-list.component.scss']
})
export class TabDashboardActionListComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlDeterministicMrpDataService
  ) { }

  private subscription: Subscription = new Subscription();
  public isShowTable: boolean = false;
  public dataFromService: any = [];

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.storageReportData.subscribe({
        next: data => {
          if(data) {
            this.dataFromService = data;
            this.isShowTable = true;
          }
        }
      })
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.isShowTable = false;
    this.dataFromService = [];
  }

}
