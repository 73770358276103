import { Component, OnInit, Input } from '@angular/core';
import { ITableStorage } from '../../bussiness-logic/interfaces/i-storage';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() public dataService: ITableStorage<any>
  constructor() { }

  ngOnInit() {
  }

  onSearch(value : string){
    this.dataService.searchValue.next(value)
  }

}
