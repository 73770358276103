import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';
import { IGeoLocationForward } from '../../interfaces/i-geo-location-forward';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationForwardService extends ApiService<IGeoLocationForward>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.geoLocationForward, http);
  }

  search(objectForSend: IGeoLocationForward): Observable<any> {

    return this.http.post<any>(`${this.baseUrl + apiPaths.routing.geoLocationForward}`, objectForSend).pipe(
      map((items: any) => {
        let data = items.hasOwnProperty('data') ? items['data'] : items;
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

}
