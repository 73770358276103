<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form class="custom-form" [formGroup]="formService.form" (ngSubmit)="save()">
  <div mat-dialog-content>

    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{"Add" | translate}} {{dataService.problemType.title | translate}}
        </h3>
      </div>
    </div>

    @if (dataService.problemType.shortcut == 'sssp' || dataService.problemType.shortcut == 'ptp') {
      <div class="row">
        <div class="col-md-12 input-methods">
          <label class="sub-title">{{"Input Methods" | translate}}:</label>
          <mat-radio-group [formControlName]="form.keys.inputMethod" color="primary">
            @for (data of methods; track data) {
              <mat-radio-button [value]="data.id">{{data.title | translate}}</mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>
    }

    <div class="row" [ngClass]="!formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-sm-12">

        <div class="row">
          <div class="col-sm-6">
            <mat-form-field appearance="outline" color="primary"
              [ngClass]="formService.form.get('mapType').errors ? 'requiredField' : null">
              <mat-label>{{"TypeOfMap" | translate}}</mat-label>
              <mat-select [formControlName]="form.keys.mapType"
                panelClass="custom-mat-select-panel-class"
                disableOptionCentering>
                @for (option of mapTypes; track option) {
                  <mat-option [value]="option.value">
                    {{option.title | translate}}
                  </mat-option>
                }
              </mat-select>
              <mat-error class="customError">
                @if (formService.form.get('mapType').hasError('required')) {
                  {{"ThisFieldIsRequired" | translate}}
                }
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-form-field color="primary" appearance='outline' [ngClass]="formService.form.get('name').errors ? 'requiredField' : null">
              <mat-label>{{'Title' | translate}}</mat-label>
              <input matInput [formControlName]="formService.form.keys.name" />
              <mat-error class="customError">
                @if (formService.form.get('name').hasError('required')) {
                  {{"ThisFieldIsRequired" | translate}}
                }
                @if (formService.form.get('name').hasError('noWhiteSpace')) {
                  {{"NoWhiteSpace" | translate}}
                }
              </mat-error>
            </mat-form-field>

          </div>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-md-12 inputfile-wrapper">
        <app-common-file-upload (onFileChanged)="onFileChanged($event)" [allowedFileTypes]="allowedFileTypes"></app-common-file-upload>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button color="primary" type="submit"
    [disabled]="form.invalid && !formService.isImportMethod">{{'Apply' | translate}}</button>
    <button mat-flat-button type="button" color="warn"
    [mat-dialog-close]="true">{{'Close' | translate}}</button>
  </div>

</form>
