import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { BlShowAreaOnMapRequestsService } from '../../../../admin-config/services/requests/bl-show-area-on-map-requests.service';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { SpinnerFunctions } from '../../../../shared/functions/spinner-functions';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { IDistribution1ToN, IDistribution1ToNLeftSidebarForm, IDistribution1ToNRun } from '../../interfaces/i-distribution-1-to-n';
import { Distribution1ToNService } from '../api/distribution-1-to-n.service';
import { MapByDistancesService } from '../api/map-by-distances.service';
import { BlDistribution1ToNRequestsService } from '../requests/bl-distribution-1-to-n-requests.service';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';
import { BlDistribution1ToNNewFormService } from './bl-distribution-1-to-n-new-form.service';
import { BlDistribution1ToNSourceDestinationsTabFormService } from './bl-distribution-1-to-n-source-destinations-tab-form.service';

@Injectable({
  providedIn: 'root'
})
export class BlLeftSidebarDistribution1ToNFormService implements IFormService<IDistribution1ToNLeftSidebarForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private requestsService: BlDistribution1ToNRequestsService,
    private dataService: BlDistribution1ToNDataService,
    private alertService: AlertMessageDataService,
    private translateService: TranslateService,
    private apiService: Distribution1ToNService,
    private sourceDestinationsTabFormService: BlDistribution1ToNSourceDestinationsTabFormService,
    private left: BlDistribution1ToNSourceDestinationsTabFormService,
    private newFormService: BlDistribution1ToNNewFormService,
    private showAreaOnMapRequestsService: BlShowAreaOnMapRequestsService,
    private mapByDistancesService: MapByDistancesService,
    private distribution1ToNSourceDestinationsTabFormService: BlDistribution1ToNSourceDestinationsTabFormService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNLeftSidebarForm> = this.init();
  objToPatch: IDistribution1ToN;
  originalObj: IDistribution1ToN;

  init(): FormGroupTypeSafe<IDistribution1ToNLeftSidebarForm> {
    let group: FormGroupTypeSafe<IDistribution1ToNLeftSidebarForm> = this.formBuilder.group<IDistribution1ToNLeftSidebarForm>({
      name: this.formBuilder.control(""),
      width: this.formBuilder.control(2, Validators.required)
    });

    return group;
  }

  fillForm(): void {
    this.dataService.source = this.objToPatch.source;
    this.dataService.allDestinations = this.objToPatch.destinations;
    this.dataService.trucks = typeof(this.objToPatch.trucks != undefined) ? this.objToPatch.trucks : null;
    if(this.dataService.trucks) {
      let sum = 0;
      this.dataService.trucks.forEach(function(x) {
        sum += x.truckNumber;
      });
      this.dataService.truckNumber = sum;
    }

    this.dataService.typeOfMap = this.objToPatch.typeOfMap;

    this.dataService.destinationsQuantity = this.objToPatch.destinationsQuantity;
    this.dataService.countryName = this.objToPatch.countryName;
    this.dataService.countryId = this.objToPatch.countryId;

    if(this.dataService.typeOfMap != "graph") {
      this.sourceDestinationsTabFormService.fillForm();
    }
    if(this.objToPatch.settings != null)
      this.dataService.settingsObj = this.objToPatch.settings;

    this.form.getSafe(x => x.name).setValue(this.objToPatch.name);

    if(this.dataService.typeOfMap == "graph") {
      this.dataService.allGraphs = this.objToPatch.destinations;
      this.sourceDestinationsTabFormService.createGraphControls();
    }

    this.sourceDestinationsTabFormService.form.get('graphSource').setValue(1);
    this.sourceDestinationsTabFormService.form.get('graphDestination').setValue(2);
    this.sourceDestinationsTabFormService.form.get('filterByNodes').setValue("");

  }

  setCoordinatesForCountry(countryId): void {
    SpinnerFunctions.showSpinner();
    this.showAreaOnMapRequestsService.getMapData(countryId).subscribe({
      next: data => {
      SpinnerFunctions.hideSpinner();
        this.dataService.coordinatesForSelectedCountry = data;
      }, 
      error: err => {
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage("danger", "Error!");
      }
    });
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNLeftSidebarForm> {
    return this.form;
  }

  reset(): void {
    this.form.getSafe(x => x.width).setValue(2);
    this.form.getSafe(x => x.name).setValue("");
  }

  resetFormAndCreateNew(): void {
    this.dataService.resetData();
    this.dataService.isCalculated = false;
    this.reset();
    this.dataService.isEdit = false;
  }

  calculate(): void {
    if (this.checkIfDestinationsAreSelected() && this.checkIfSourceIsSelected()) {
      SpinnerFunctions.showSpinner();

      let graphSource = null;
      let graphDestination = null;

      if(this.dataService.typeOfMap === "graph") {
        if(this.dataService.problemType.shortcut === "ptp") {
          graphSource = this.distribution1ToNSourceDestinationsTabFormService.form.getSafe(x => x.graphSource).value;
          graphDestination = this.distribution1ToNSourceDestinationsTabFormService.form.getSafe(x => x.graphDestination).value;
        }
        else if(this.dataService.problemType.shortcut === "sssp") {
          graphSource = this.distribution1ToNSourceDestinationsTabFormService.form.getSafe(x => x.graphSource).value;
        }
      }

      const objForCalculateRoute: IDistribution1ToNRun = {
        id: this.dataService.currentIdForRun,
        algorithm: (this.dataService.algorithm && this.dataService.algorithm.algorithm) ? this.dataService.algorithm.algorithm : "dijkstra",
        settings: this.dataService.settingsObj,
        routeType: (this.dataService.algorithm && this.dataService.algorithm.routeType) ? this.dataService.algorithm.routeType : "f",
        source: graphSource,
        destination: graphDestination,
        isCircular: this.dataService.isCircular
      };

      this.requestsService.calculate(objForCalculateRoute).subscribe({
        next: data => {
          if(!data) {
            SpinnerFunctions.hideSpinner();
            return;
          }
          else if(data.poruka != null && data.poruka != "") {
            this.alertService.setMessage("danger", data.poruka);
          }
          else {
            if(this.dataService.problemType.shortcut == 'sssp') {

              if(data && data.mapIterations) {
                this.dataService.mapByIterations = <any>data.mapIterations;
                this.dataService.mapByIterationsHash = data.mapIterations.routes.reduce((acumulator, element) => {
                  !!acumulator[element.groupId.toString()] ?  acumulator[element.groupId.toString()].push(element) :  (acumulator[element.groupId.toString()] =  [element]);
                  return acumulator;
              }, {});


                this.dataService.changeMapByIterations.next(true);
              }
            }

            let brojac = 0;

            let mappedData = cloneDeep(data);
            mappedData.routes.map(x => {
              x["id"] = brojac++;
              return x;
            })

            this.dataService.coordinatesForMainMap = cloneDeep(mappedData);
            this.dataService.allCoordinatesForMainMap = cloneDeep(mappedData);

            this.dataService.reports = data.mrpReport ? data.mrpReport : [];
            this.dataService.isCalculated = true;

            this.dataService.calculateFinished.next(true);
          }

          SpinnerFunctions.hideSpinner();
        }, 
        error: err => {
          SpinnerFunctions.hideSpinner();
        }
      });
    }
    else {
      this.alertService.setMessage("danger", this.translateService.instant("ChooseSourceAndDestination"));
    }
  }

  makeObjForCalculate(newName: string = null): IDistribution1ToN {
    let destinations = this.dataService.allDestinations;
    let source = this.dataService.source;
    let trucks = null;

    if(this.dataService.problemType.shortcut == 'ptp') {
      destinations.push(destinations.shift());
    }

    if(this.dataService.getGroupPropertiesId() == 2) {
      trucks = this.dataService.trucks;
    }


    if(this.checkIfGraph()) {
      const tabForm = this.distribution1ToNSourceDestinationsTabFormService.form;
      destinations = [];
      for(let i=0; i<tabForm.value.graph.length; i++) {
        const currentControl = tabForm.value.graph[i];
        destinations.push([currentControl.v1, currentControl.v2, currentControl.w])
      }

      source = [tabForm.getSafe(x => x.graphSource).value]
      if(this.dataService.problemType.shortcut == 'ptp')
        source.push(tabForm.getSafe(x => x.graphDestination).value)
    }

    return {
      id: null,
      name: !newName ? this.form.getSafe(x => x.name).value : newName,
      source: source,
      destinations: destinations,
      algorithm: (this.dataService.algorithm && this.dataService.algorithm.algorithm) ? this.dataService.algorithm.algorithm : "dijkstra",
      trucks: trucks,
      destinationsQuantity: this.dataService.getGroupPropertiesId() == 2 ? this.dataService.destinationsQuantity : null,
      typeOfMap: this.dataService.typeOfMap,
      countryName: this.dataService.countryName,
      countryId: this.dataService.countryId,
      isCircular: this.dataService.isCircular
    }
  }

  checkIfDestinationsAreSelected(): boolean {
    const minDestinationsNumber = this.dataService.problemType.shortcut == "tsp" ? 2 : 0;

    return this.dataService.allDestinations.length > minDestinationsNumber && !this.dataService.allDestinations.find(x => x[1] == null || x[0] == null);
  }

  checkDestinationsQuantity(): boolean {
    const intRegex = /^(\d)+$/;
    return (this.dataService.getGroupPropertiesId() != 2) || !this.dataService.destinationsQuantity.find(x => (x != null && !intRegex.test(x)));
  }

  checkIfSourceIsSelected(): boolean {
    if(this.checkIfGraph()) {

      return true;
    }
    return this.dataService.source[0] != null && this.dataService.source[1] != null;
  }

  checkIfTrucksNumberCorrect(): boolean {
    return (this.dataService.getGroupPropertiesId() != 2) || (this.dataService.trucks.length > 0 && !this.dataService.trucks.find(x => x.truckNumber == null || x.truckQuantity == null || x.truckNumber < 1 || x.truckQuantity < 1) );
  }

  checkIfGraph(): boolean {
    return this.dataService.typeOfMap == 'graph';
  }

  checkIfControlsForGraphAreValid(): boolean {

    const tabForm = this.distribution1ToNSourceDestinationsTabFormService.form;

    let graphSourceValid = tabForm.getSafe(x => x.graphSource).valid
    let graphDestinationValid = this.dataService.problemType.shortcut === 'ptp' ? tabForm.getSafe(x => x.graphDestination).valid : true;

    return tabForm.controls.graph.valid && graphSourceValid && graphDestinationValid;

  }

  submitUpdate(): Observable<any> {
    let dataToSend: any = this.makeObjForCalculate();
    dataToSend["id"] = this.objToPatch.id;
    return this.requestsService.updateWithoutParams(dataToSend);
  }

  submitInsert(newName: string = null): Observable<any> {
    let dataToSend: any = this.makeObjForCalculate(newName);
    return this.requestsService.insertWithoutParams(dataToSend);
  }

  setValidatorsForName(): void {
    this.form.getSafe(x => x.name).setValidators(Validators.required);
    this.form.getSafe(x => x.name).updateValueAndValidity();
    ValidationFunctions.runValidation(this.form);
  }

}
