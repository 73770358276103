import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../config/api';
import { ApiService } from '../../../shared/services/api.service';
import { IAdminConfigRouting } from '../../interfaces/i-admin-config-routing';

@Injectable({
  providedIn: 'root'
})
export class AdminConfigRoutingService extends ApiService<IAdminConfigRouting>{

  constructor(http: HttpClient) {
    super(apiPaths.admin.routingConfig, http);
  }

  getAllMaps() : Observable<IAdminConfigRouting>{
    return this.http.get<IAdminConfigRouting[]>(`${ this.baseUrl + this.url}`).pipe(
      map((items :any)=> {
        return items;
      }),
      catchError(this.handleErrors)
    );
  }

  updateDownloadMaps(data: any): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl + this.url}`, data).pipe(
      map((items: any) => {
        return items;
      }),
      catchError(this.handleErrors)
    );
  }
  
}