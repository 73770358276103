import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { HeaderGraphNodesComponent } from './components/dashboard-graph-nodes/components/header-graph-nodes/header-graph-nodes.component';
import { NewGraphNodesComponent } from './components/dashboard-graph-nodes/components/new-graph-nodes/new-graph-nodes.component';
import { SaveAsNewGraphNodesComponent } from './components/dashboard-graph-nodes/components/save-as-new-graph-nodes/save-as-new-graph-nodes.component';
import { TableGraphNodesComponent } from './components/dashboard-graph-nodes/components/table-graph-nodes/table-graph-nodes.component';
import { ViewDialogGraphNodesComponent } from './components/dashboard-graph-nodes/components/view-dialog-graph-nodes/view-dialog-graph-nodes.component';
import { DashboardGraphNodesComponent } from './components/dashboard-graph-nodes/dashboard-graph-nodes.component';
import { GraphNodesRoutingModule } from './graph-nodes-routing.module';



@NgModule({
  declarations: [DashboardGraphNodesComponent, HeaderGraphNodesComponent, SaveAsNewGraphNodesComponent, TableGraphNodesComponent, NewGraphNodesComponent, ViewDialogGraphNodesComponent],
  imports: [
    CommonModule,
    GraphNodesRoutingModule,
    SharedModule,
    CoreModule
  ]
})
export class GraphNodesModule { }
