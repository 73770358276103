<div class="row">
  <div class="col-md-12 destination-header actions-cell">
    <h3 >{{"Add new destinations" | translate}}</h3>
    <button  type="button" mat-icon-button color="primary" (click)="addDestination()">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
  <div class="col-md-12">
    <mat-divider class="divider"></mat-divider>
  </div>

  @for (option of dataService.allDestinations; track option; let i = $index) {
    <div class="col-md-12 source-destination-header actions-cell">
      <h3 class="source-destination-title">{{"Destination" | translate}}
      </h3>
      @if (dataService.allDestinations.length > 1) {
        <button mat-icon-button color="warn" (click)="removeDestination(i)" type="button">
          <mat-icon>remove_circle</mat-icon>
        </button>
      }
      <button mat-flat-button color="primary"
        class="custom-flat-btn"
        (click)="openModal(i)">{{"Choose" | translate}}
      </button>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 source-destination-label">
          <table class="table table-sm">
            <tbody>
              <tr>
                <td>{{"Longitude" | translate}}:</td>
                <td>{{dataService.allDestinations[i][1] == null ? "-" : (dataService.allDestinations[i][1] | number : '1.2-7')}}</td>
              </tr>
              <tr>
                <td>{{"Latitude" | translate}}:</td>
                <td>{{dataService.allDestinations[i][0] == null ? "-" : (dataService.allDestinations[i][0] | number : '1.2-7')}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <hr  class='source-destination-divider' />
    </div>
  }
</div>
