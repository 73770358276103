import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlCellFormationProblemFormService } from '../../../../../../../../services/form/bl-cell-formation-problem-form.service';
import { BlCellFormationProblemDataService } from '../../../../../../../../services/shared/bl-cell-formation-problem-data.service';
import { BlInputDataTableService } from '../../../../../../../../services/table/bl-input-data-table.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-dashboard-input-data-right-side',
  templateUrl: './tab-dashboard-input-data-right-side.component.html',
  styleUrls: ['./tab-dashboard-input-data-right-side.component.scss']
})
export class TabDashboardInputDataRightSideComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlCellFormationProblemFormService,
    private dataService: BlCellFormationProblemDataService,
    public tableService: BlInputDataTableService,
  ) { }

  private subscription: Subscription = new Subscription();
  public dataFromStorage: any;

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          this.dataFromStorage = null;
          if(data && data.rowsTab1) {
            this.dataFromStorage = data;
            this.formService.numberRows = this.dataFromStorage.m;
            this.formService.numberCols2 = this.dataFromStorage.p;
            this.tableService.createDynamicHeaderColumnsTable2a(this.formService.numberCols2);
          }
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols2 = 0;
  }
}
