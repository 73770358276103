<form [formGroup]="formService.form" class="reducedForm order-form qap-form">
    <div class="row">
        <div class="col-sm-6 input-mobile">
            <mat-form-field appearance="outline" [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''">
                <mat-label>{{ "Algorithm" | translate}}</mat-label>
                <mat-select (selectionChange)="changeAlgorithm($event.value)" [formControlName]="formService.form.keys.algorithm" disableOptionCentering>
                    <mat-option *ngFor="let option of dropdownData.Algorithms" [value]="option.id">
                        {{option.title | translate}}
                    </mat-option>
                </mat-select>
                <mat-error class="customError">
                    <ng-container *ngIf="formService.form.get('algorithm').hasError('required')">
                        {{"ThisFieldIsRequired" | translate}}
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 input-mobile">
            <ng-container *ngIf="formService.form.get('algorithmParams')['controls']" formArrayName="algorithmParams">
                <ng-container *ngFor="let item of formService.parametri; let i=index">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                            <mat-label>{{item | translate}}</mat-label>
                            <input [formControl]="formService.form.get('algorithmParams')['controls'][i].get('value')" matInput autocomplete="off" type="number">
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</form>

  <div class="row drag-drop-wrapper">
        <div class="col-sm-12 btn-edit-packing">
            <span class="mr-15">{{"Containers" | translate}}</span>
            <button  [disabled]="isProblemLiterature" type="button" mat-stroked-button color="accent" (click)="openEditDialog()">
                <mat-icon>drive_file_rename_outline
                </mat-icon>{{"Edit" | translate}}
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
          <div class="qap-table-wrapper">

            <cdk-virtual-scroll-viewport
              [ngStyle]="{'height' : dataForTable.length < 15 ? (dataForTable.length + 2)*30+'px' : '480px'}"
              style="width: 100%; overflow-y: auto;"
              minBufferPx="200"
              maxBufferPx="600"
              [itemSize]="30">
            <div>
                <ng-container *ngIf="(dataForTable && dataForTable.length) > 0; else noAllTableData;">
                    <table class="table table-sm table-dynamic">
                        <thead class="thead-dark">
                          <tr>
                              <th>&nbsp;</th>
                              <th *ngFor="let header of tableService.headersTable2">{{header.title}}</th>
                          </tr>
                        </thead>
                        <tbody>
                            <ng-container *cdkVirtualFor="let tableRow of dataForTable; let i=index;">
                              <tr>
                                <td>{{i+1}}</td>
                                <ng-container *ngFor="let columns of tableRow; let j=index;">
                                    <td [ngStyle]="(i == j) ? {'background': '#fff'} : {'background': '#fff'}">
                                        {{columns}}
                                    </td>
                                </ng-container>
                              </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
                <ng-template #noAllTableData>
                    <div class="alert alert-warning" role="alert">
                        {{"NoRecordsFound" | translate}}
                    </div>
                </ng-template>
            </div>
        </cdk-virtual-scroll-viewport>

          </div>
        </div>
    </div>
    <!-- Right Table QAP -->
