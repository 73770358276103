import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';
import { TableFunctions } from '../../functions/table-functions';

@Pipe({
  name: 'renderTemplateColumn'
})
export class RenderTemplateColumnPipe implements PipeTransform {

  constructor(
    private tableFunctions: TableFunctions
  ){}
  
  transform(column: ITableColumn, row: any): string {
    const params = column.template.params.map(param => {
      if (param.indexOf('.') !== -1) { return this.tableFunctions.getProperty(row, param); }
      if (row.hasOwnProperty(param)) { return row[param]; } else {
        return null;
      }
    });
    return column.template.view(params, row);
  }

}
