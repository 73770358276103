import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';
import { UPDATABLE_COLUMN_PROPS } from '../../consts/table-settings';

@Pipe({
  name: 'updatableBooleanColumn'
})
export class UpdatableBooleanColumnPipe implements PipeTransform {

  transform(column: ITableColumn, row: any): boolean {
    return this.isSpecific(column, row[column.index]);
  }

  isSpecific(propertyName, propertyValue){
    return UPDATABLE_COLUMN_PROPS.indexOf(propertyName['index'])!==-1 && (this.isBinaryColumn(propertyValue) || typeof(propertyValue) == 'boolean')
  }

  isBinaryColumn(value) {
    return value === 0 || value===1;
  }

}
