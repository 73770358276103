import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { IGeoLocationForward, IGeoLocationForwardForm, IGeoLocationForwardRequest } from '../../interfaces/i-geo-location-forward';
import { GeoLocationForwardService } from '../api/geo-location-forward.service';
import { BlGeoLocationForwardRequestsService } from '../requests/bl-geo-location-forward-requests.service';

@Injectable({
  providedIn: 'root'
})
export class BlGeoLocationForwardFormService implements IFormService<IGeoLocationForwardForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private matDialog: MatDialog,
    private requestsService: BlGeoLocationForwardRequestsService,
    private apiService: GeoLocationForwardService
  ) {}

  form: FormGroupTypeSafe<IGeoLocationForwardForm> = this.init();
  originalObj: IGeoLocationForward;

  init(): FormGroupTypeSafe<IGeoLocationForwardForm> {
    return this.formBuilder.group<IGeoLocationForwardForm>({
      address: this.formBuilder.control("", [Validators.required, Validators.minLength(3)])
    });
  }

  fillForm(): void {}

  prepareDataToSend(): IGeoLocationForwardRequest {
    let formValue: IGeoLocationForwardForm = this.form.getRawValue();

    let dataToSend: IGeoLocationForwardRequest = {
      address: formValue.address
    };

    return dataToSend;
  }

  getFormReference(): FormGroupTypeSafe<IGeoLocationForwardForm> {
    return this.form;
  }

  close(): void {
    this.matDialog.closeAll();
  }

  reset(): void {
    this.form = this.init();
  }

}