import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';
import { IMAGE_PROP } from '../../consts/table-settings';

@Pipe({
  name: 'imageColumn'
})
export class ImageColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.index == IMAGE_PROP;
  }

}
