import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-dashboard-bom',
  templateUrl: './tab-dashboard-bom.component.html',
  styleUrls: ['./tab-dashboard-bom.component.scss']
})
export class TabDashboardBomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
