import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutSharedService {
  constructor() {}

  public leftSidebarState = new BehaviorSubject<boolean>(false);
  public backgroundImageState = new BehaviorSubject<boolean>(true);

  toggleLeftBar(state: boolean): void {
    this.leftSidebarState.next(state);
  }

}
