<div
  [ngClass]="!(dataService.originalObject.getValue() || dataService.originalObject.getValue() || mainTitle || dataService.storageIsRunResults.getValue()) ? 'fixed-page-header' : 'relative-page-header'">

  <div class="row">
    <div class="col-md-12">
      <h3 class="page-header-title"> <span>{{"CSM" | translate}}</span> / {{"CFP" | translate}}</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="header-cms">
        <div class="header-button-box" [ngClass]="formService.isShowOtherTabs ? 'all-buttons' : null">
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="newCFP()"
            [disabled]="!permissionsService.havePermission('cell-formation-problem', 'ability-to-create-new-problem')">
            <mat-icon>create</mat-icon>{{"New" | translate}}
          </button>
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="viewCFP()"
            [disabled]="!permissionsService.havePermission('cell-formation-problem', 'ability-to-open-all-problems')">
            <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
          </button>
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="testCFP()"
            [disabled]="!permissionsService.havePermission('cell-formation-problem', 'ability-to-test-problem')">
            <mat-icon>sticky_note_2</mat-icon>{{"Test" | translate}}
          </button>
          @if (formService.isShowOtherTabs) {
            @if (formService.isLiteratureProblem!=true) {
              <button type="button"
                mat-stroked-button color="primary"
                class="custom-stroked-btn"
                (click)="saveData()"
                [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-save-problem') || !formService.form.get('rows').valid)">
                <mat-icon>album</mat-icon>{{"Save" | translate}}
              </button>
            }
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="saveAsNew()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-save-as') || !formService.form.get('rows').valid)">
              <mat-icon>album</mat-icon>{{"Save As" | translate}}
            </button>
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="runBrusco()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-run-brusco') || !formService.form.get('rows').valid  || !formService.form.get('algorithm').valid || !formService.form.get('objective').valid || !formService.form.get('algorithmParams').valid)">
              <mat-icon>school</mat-icon>{{"Run" | translate}}
            </button>
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="runSimilarity()"
              [disabled]="(!permissionsService.havePermission('cell-formation-problem', 'ability-to-run-similarity') || !formService.form.get('rows').valid)">
              <mat-icon>loop</mat-icon>{{"Similarity" | translate}}
            </button>
            @if (formService.isRunProblemForTitle == true) {
              <span class="problem-title" [innerHTML]="formService.problemTitle | safeHtml">
              </span>
            } @else {
              @if (dataService.originalObject.getValue() || mainTitle) {
                  <span class="problem-title" [innerHTML]="dataService.originalObject.getValue() ?
                  (dataService.originalObject.getValue()[0] ?
                  dataService.originalObject.getValue()[0].description :
                  dataService.originalObject.getValue().description) : mainTitle">
                </span>
              }
            }
          }
        </div>
        <div class="help-block">
          @if (formService.isExportShow==true) {
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="export()">
              <mat-icon>save_alt</mat-icon>{{"Export CSV" | translate}}
            </button>
          }
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="openMarkdown()">
            <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    @if (!(dataService.originalObject.getValue() || dataService.originalObject.getValue() || mainTitle || dataService.storageIsRunResults.getValue())) {
      <div class="col-md-12 iframe-wrapper"
        >
        <div class="iframe-holder">
          <iframe src="assets/markdown/cms/cell-formation-problem/help.html" width="100%" height="100%" frameborder="5">
          </iframe>
        </div>
      </div>
    }
  </div>

</div>
