<div class="reducedForm tableForm">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="tab-title">
                        {{"CheckMapsToDownload" | translate}} :
                    </h3>
                </div>
            </div>
            <hr />
            <ng-container *ngIf="showControls">
                <form class="reducedForm item-master" [formGroup]="form">

                    <div class="row">
                        <ng-container formArrayName="maps">
                            <ng-container *ngFor="let map of freeMaps; let i=index">
                                <div class="col-md-6 col-lg-4 download-maps">
                                    <mat-checkbox matTooltip={{map.description}} class="checkList" [formControlName]="i"
                                        [value]="map.id">
                                        <span>{{ map.description }}</span>
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="form.get('maps').hasError('notSelected')">
                        <div class="row mt-10">
                            <div class="col-md-12">
                                <mat-error class="customError">
                                    <p>{{"AtLeastOneMapIsRequired" | translate}}</p>
                                </mat-error>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <div class="col-md-12" [ngStyle]="{'margin-top': form.valid ? '20px' : null}">
                            <button class="choose-map" mat-flat-button color="accent" (click)="save()"
                                [disabled]="form.invalid">{{"Save" | translate}}
                            </button>
                        </div>
                    </div>

                </form>
            </ng-container>
        </div>

        <div class="col-lg-3">
            <div class="occupiedMaps">
                <h3>{{"DownloadedMaps" | translate}}</h3>
                <ng-container *ngIf="occupiedMaps.length > 0; else noOccupiedMaps">
                    <ng-container *ngFor="let occupiedMap of occupiedMaps">
                        <div class="row">
                            <div class="col-md-12">
                                <p> - {{ occupiedMap.description }}</p>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #noOccupiedMaps>
                    <div class="alert alert-warning mt-10">
                        {{'NoRecordsFound' | translate}}
                    </div>
                </ng-template>

            </div>
        </div>
    </div>

</div>