<div class="row">
    <div class="col-md-12">
        <app-header-admin-config></app-header-admin-config>
    </div>
</div>
<div class="row geo-location-tab-content">
    <div class="col-md-12">
        <app-tabs-admin-config></app-tabs-admin-config>
    </div>
</div>
