import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { TokenService } from '../../../../core/services/token.service';
import { PermissionsService } from '../../../../shared/services/permissions.service';

@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss']
})
export class UserNavigationComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    public permissionsService: PermissionsService
  ) { }

  //promenljiva za ime kompanije koja se prikazuje u headeru
  public userName: any = this.tokenService.getTokenData().given_name;
  // public image: any = this.tokenService.getTokenData().website;
  // public fullPath: string = config.baseApiUrl.AWS_IMG + this.image;
 
  ngOnInit() {
  }
  // ovdje input iz koga cemo citati username, pandam HOTEL SETTINGS
  // printSpicedName(fullname: string) {
  //   return fullname ? fullname.match(/\b(\w)/g).join('') : '';
  // }

  calculatePositionLeft(){
    const userTextWidth = document.getElementById("userText").offsetWidth;
    const userToolbar = <HTMLElement>document.querySelector(".user-toolbar");

    if( userToolbar && userTextWidth < 160) {
      const userToolbarWidth = userToolbar.offsetWidth;
      let left = (userTextWidth + 10 - userToolbarWidth );
      // sirina texta usera + pading(10px) - sirina panela
      if (window.innerWidth >= 768){
        userToolbar.style.setProperty("left", `${left}px`);
      }else{
        userToolbar.style.setProperty("left", "-65px");
      }
    }
  }

  logout() {
    this.authService.logout();
  }
}
