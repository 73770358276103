import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlRequestService } from '../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { AdminConfigRoutingService } from '../api/admin-config-routing.service';
import { ShowAreaOnMapService } from '../api/show-area-on-map.service';

@Injectable({
  providedIn: 'root'
})
export class BlShowAreaOnMapRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: ShowAreaOnMapService,
    private adminConfigRoutingService: AdminConfigRoutingService
  ) {
    super(alertService);
  }

  getAllMaps(): Observable<any> {
    return this.adminConfigRoutingService.getAllMaps();
  }

  getMapData(id: number): Observable<any> {
    return this.apiService.getMapData(id);
  }

}
