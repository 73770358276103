import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppComponent } from './app.component';
import { HeaderHomeComponent } from './components/home/components/header-home/header-home.component';
import { TableChartHomeComponent } from './components/home/components/table-chart-home/table-chart-home.component';
import { HomeComponent } from './components/home/home.component';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth.service';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { AlertComponent } from './shared/components/alert/alert.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { AuthResponseInterceptor } from './shared/services/auth.response.interceptor';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './core/auto-logout/redux/reducers';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from './core/auto-logout/redux/effects';
import { ChangePasswordComponent } from './components/change-password/components/change-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MrpModule } from './mrp/mrp.module';
import { CmsModule } from './cms/cms.module';
import { RoutingModule } from './routing/routing.module';
import { OrderModule } from './order/order.module';
import { AdminConfigModule } from './admin-config/admin-config.module';
import { AssignmentModule } from './assignment/assignment.module';
import { PackingModule } from './packing/packing.module';

const routes: Routes = [
  {
    path: "",
    component: AppLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: "home",
        component: HomeComponent,
        canActivate: [ AuthGuard ],
      },
      {
        path: "layout",
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [ AuthGuard ]
      },
      {
        path: "mrp",
        // loadChildren: "./mrp/mrp.module#MrpModule",
        loadChildren: () => MrpModule,
        canActivate: [ AuthGuard ]
      },
      {
        path: "cms",
        loadChildren: () => CmsModule,
        canActivate: [ AuthGuard ]
      },
      {
        path: "routing",
        loadChildren: () => RoutingModule,
        canActivate: [ AuthGuard ]
      },
      {
        path: "order",
        loadChildren: () => OrderModule,
        canActivate: [ AuthGuard ]
      },
      {
        path: "assignment",
        loadChildren: () => AssignmentModule,
        canActivate: [ AuthGuard ]
      },
      {
        path: "admin-config",
        loadChildren: () => AdminConfigModule,
        canActivate: [ AuthGuard ]
      },
      {
        path: "packing",
        loadChildren: () => PackingModule,
        canActivate: [ AuthGuard ]
      }
      // {
      //   path: "change-password",
      //   component: ChangePasswordComponent,
      //   canActivate: [ AuthGuard ],
      // },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        HeaderHomeComponent,
        TableChartHomeComponent,
        ChangePasswordComponent
    ],
    bootstrap: [AppComponent, AlertComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, {}),
        SharedModule,
        CoreModule,
        LoginModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(<any>reducers),
        EffectsModule.forRoot([ApplicationEffects])], providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthResponseInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
