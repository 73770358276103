<ng-container *ngIf="dataFromStorage">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-blue">
                <thead class="thead-dark">
                    <tr>
                        <th colspan="2"><b>{{"Grupped machines" | translate}}</b></th>
                    </tr>
                </thead> 
                <tbody>
                    <tr *ngFor="let data of dataFromStorage?.rowsTab2.groupedMachines; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{data.stavke}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-blue">
                <thead class="thead-dark">
                    <tr>
                        <th colspan="2"><b>{{"Grupped parts" | translate}}</b></th>
                    </tr>
                </thead> 
                <tbody>
                    <tr *ngFor="let data of dataFromStorage?.rowsTab2.groupedParts; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{data.stavke}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-blue">
                    <thead class="thead-dark">
                        <tr>
                            <th colspan="2"><b>{{"Parts processed on each machine" | translate}}</b></th>
                        </tr>
                    </thead> 
                    <tbody>
                        <tr *ngFor="let data of dataFromStorage?.rowsTab2.partsProcessed; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{data.stavke}}</td>
                        </tr>
                    </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-normal">
                    <thead class="thead-dark">
                        <tr>
                            <th colspan="2"><b>{{"Total degrees of parts processed on each machine" | translate}}</b></th>
                        </tr>
                    </thead> 
                    <tbody>
                        <tr>
                            <td class="full-width">{{dataFromStorage?.rowsTab2.totalDegreesPartsProcessed}}</td>
                        </tr>
                    </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-blue">
                <thead class="thead-dark">
                    <tr>
                        <th colspan="2"><b>{{"Degreees of parts processed on each machine" | translate}}</b></th>
                    </tr>
                </thead> 
                <tbody>
                    <tr *ngFor="let data of dataFromStorage?.rowsTab2.degreesPartsProcessed; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{data.stavke}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-blue">
                <thead class="thead-dark">
                    <tr>
                        <th colspan="2"><b>{{"Machines processing each part" | translate}}</b></th>
                    </tr>
                </thead> 
                <tbody>
                    <tr *ngFor="let data of dataFromStorage?.rowsTab2.machinesProcessing; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{data.stavke}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-normal">
                <thead class="thead-dark">
                    <tr>
                        <th colspan="2"><b>{{"Total degrees of machines processing each part" | translate}}</b></th>
                    </tr>
                </thead> 
                <tbody>
                    <tr>
                        <td class="full-width">{{dataFromStorage?.rowsTab2.totalDegreesMachinesProcessing}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped first-col-blue">
                <thead class="thead-dark">
                    <tr>
                        <th colspan="2"><b>{{"Degrees of machines processing each part" | translate}}</b></th>
                    </tr>
                </thead> 
                <tbody>
                    <tr *ngFor="let data of dataFromStorage?.rowsTab2.degreesMachinesProcessing; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{data.stavke}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>