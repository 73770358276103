<div class="row">
  <div class="col-sm-12 header-button-box">
      <button (click)="printReport()" mat-stroked-button color="primary"
        class="custom-stroked-btn">
        <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
      </button>
  </div>
</div>
<div id="report" class="row">
  <div class="col-md-12 tab-wrapper">
    @if (dataService.reportTables.length > 0) {
      @for (data of dataService.reportTables; track data; let i = $index) {
        <div class="row">
          <div class="col-sm-12 ">
            <p class="table-title" [innerHTML]="data.nazivTabele | safeHtml"></p>
            <p class="table-title" [innerHTML]="data.opis | safeHtml"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 table-wrapper">
            <table class="table table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>&nbsp;</th>
                  @for (header of data.table.tableHeader; track header) {
                    <th>{{header}}</th>
                  }
                </tr>
              </thead>
              <tbody>
                @for (tableRow of data.table.tableBody; track tableRow) {
                  <tr>
                    @for (columns of tableRow.stavke; track columns) {
                      <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                        <span [ngStyle]="{'textAlign' : regex.test(columns.value) ? 'right' : 'left'}">
                          {{columns.value}}
                        </span>
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    } @else {
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-warning" role="alert">
            {{"NoRecordsFound" | translate}}
          </div>
        </div>
      </div>
    }
  </div>
</div>
