import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ArrayFunctions } from '../../functions/array-functions';
import { TableFunctions } from '../../functions/table-functions';
import { IOption } from '../../interfaces/i-option';
import { IVCommonSelect, IVCommonOption, IVCommonNotFoundElement } from '../../interfaces/i-vcommon-select';
import { cloneDeep } from 'lodash';
import { PrintDeletedOptionHelper } from '../../helpers/print-deleted-option-helper';
import { TranslateService } from '@ngx-translate/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-v-common-multiselect',
  templateUrl: './v-common-multiselect.component.html',
  styleUrls: ['./v-common-multiselect.component.scss']
})
export class VCommonMultiselectComponent implements OnInit, OnChanges {

  @Input() public labelText = "";
  @Input() public formControlToFill: UntypedFormControl;
  @Input() public options: IOption[] = [];
  @Input() public setup: IVCommonSelect = {};
  @Input() public notFoundElementSetup: IVCommonNotFoundElement;

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  constructor(
    private cdref: ChangeDetectorRef,
    private translateService: TranslateService
  ) { }

  public selectAllValue = 0;
  public isOptionsCame: boolean = false;
  public selected: any[] = [];

  public filterControl = new UntypedFormControl("");
  private originalOptions: IVCommonOption[] = [];
  
  public deletedOptionInfo: string = null;
  public deletedOptionStatus: string = "";

  ngOnInit() {
    this.filterControl.valueChanges.subscribe({
      next: data => {
        if(data){
          this.options = this.originalOptions.filter(x =>(x.title)? (x.title.toLowerCase().indexOf(data.toLowerCase()) != -1 || x.id == this.formControlToFill.value): false);
          
          let currentValue = this.formControlToFill.value;
          let currentObject = this.originalOptions.filter(x => ArrayFunctions.inArray(x.id, currentValue));
          this.options.unshift(...currentObject);

          // if(this.options.length == 0){
          //   this.options = cloneDeep(this.originalOptions);
          // }
        } else {
          this.options = cloneDeep(this.originalOptions);
          let currentValue = this.formControlToFill.value;
          let currentObject = this.originalOptions.filter(x => ArrayFunctions.inArray(x.id, currentValue));
          this.options = this.options.filter(x => !ArrayFunctions.inArray(x.id, currentValue));
          this.options.unshift(...currentObject);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.isOptionsCame = true;
    }

    if (changes.options && changes.options.currentValue && this.formControlToFill && this.formControlToFill.value != null) {
      let currentValue = this.formControlToFill.value;
      if(!currentValue){
        currentValue = [];
      }
      this.selected = currentValue;
      let currentObject = this.options.filter(x => ArrayFunctions.inArray(x.id, currentValue));
      
      let foundedOptions = currentObject.map(x => x.id);
      

      let notFound: number[] = currentValue.filter(x => !ArrayFunctions.inArray(x, foundedOptions) && x != 0);
      let notFoundOptions = [];
      if(notFound && notFound.length > 0){
        notFoundOptions = notFound.map(x => {
          return {
            id: x,
            title: this.setNotFoundTitle(),
            isNotFound: true
          }
        });
      }
      TableFunctions.sortBy("title", currentObject);
      this.options = this.options.filter(x => !ArrayFunctions.inArray(x.id, currentValue));
      this.options.unshift(...notFoundOptions);
      this.options.unshift(...currentObject);

      let validOptions = this.options.filter(x => ArrayFunctions.inArray(x.id, currentValue) && x.id != 0);
      if (validOptions.length == this.options.length) {
        this.setup.isDisabledAllOptions = true;
      } else {
        this.setup.isDisabledAllOptions = false;
      }
      if (this.options.length == 0 || this.options == null) {
        this.setup.isDisabledAllOptions = false;
      }
      this.originalOptions = cloneDeep(this.options);
    }
    this.cdref.detectChanges();
  }

  openedChange(opened) {
    if (opened) {
      let currentValue = this.formControlToFill.value;
      this.options = cloneDeep(this.originalOptions);
      let currentObject = this.options.filter(x => ArrayFunctions.inArray(x.id, currentValue));
      TableFunctions.sortBy("title", currentObject);
      this.options = this.options.filter(x => !ArrayFunctions.inArray(x.id, currentValue));
      this.options.unshift(...currentObject);

      let index = this.options.findIndex(item => item.id == this.formControlToFill.value);
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    } else {
      let currentValue = this.formControlToFill.value;
      this.selected = currentValue;
      let currentObject = this.options.filter(x => ArrayFunctions.inArray(x.id, currentValue));
      TableFunctions.sortBy("title", currentObject);
      this.options = this.options.filter(x => !ArrayFunctions.inArray(x.id, currentValue));
      this.options.unshift(...currentObject);
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
      this.formControlToFill.setValue(currentValue);
    }
  }

  onSelection(event) {
    if (event.isUserInput) {
      if (event.source._selected) {
        if (this.selected) {
          if (Array.isArray(this.selected) == false) {
            this.selected.concat(event.source.value);
          } else {
            this.selected.push(event.source.value);
          }
        }
      } else {
        this.selected = this.selected.filter(x => x != event.source.value);
      }
      let currentValue = this.formControlToFill.value;
      // this.selected = currentValue;
      let currentObject = this.options.filter(x => ArrayFunctions.inArray(x.id, currentValue));
      TableFunctions.sortBy("title", currentObject);
      this.options = this.options.filter(x => !ArrayFunctions.inArray(x.id, currentValue));
      this.options.unshift(...currentObject);
      this.formControlToFill.setValue(this.selected);
    }
  }

  isSelected(option: any) {
    return ArrayFunctions.inArray(option, this.selected);
  }

  selectAll(selected) {
    if (selected) {
      let array = [this.selectAllValue].concat(this.options.map(x => x.id));
      this.setup.isDisabledAllOptions = true;
      this.formControlToFill.setValue(array);
    }
    else {
      this.setup.isDisabledAllOptions = false;
      this.formControlToFill.setValue([]);
    }
  }

  showDeletedOptionInfo(id: number){
    if(!this.notFoundElementSetup || !this.notFoundElementSetup.apiService) {
      console.error("V COMMON SELECT: ApiService not passed for deleted item!");
    }
    else if(!this.deletedOptionInfo) {
      this.notFoundElementSetup.apiService.get(id).subscribe({
        next: data => {

          let helper = new PrintDeletedOptionHelper(this.translateService, this.notFoundElementSetup.props);
          this.deletedOptionInfo = helper.getDeletedOptionHtml(data);
          
          if(this.notFoundElementSetup.infoCondition){
            this.deletedOptionStatus = this.notFoundElementSetup.infoCondition(data);
          }
          if(!this.deletedOptionStatus) {
            this.deletedOptionStatus = "Deleted";
          }
        }
      })
    }
  }

  private setNotFoundTitle(){
    return this.notFoundElementSetup && this.notFoundElementSetup.message? this.notFoundElementSetup.message : "DeletedNotActive";
  }

}
