<div class="navbar navbar-expand-lg">
  <div [ngClass]="isLeftSidebarOpen ? 'show' : false">
    <mat-accordion class="sidebar">
      <mat-expansion-panel hideToggle [disabled]="disableHome" [ngClass]="disableHome ? 'dark' : 'light'" *ngIf="permissionsService.havePermission('home')">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <a routerLink="/home" class="panelHomeTitle" (click)="toggleLeftBar()"><span>{{"Home" | translate}}</span></a>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <!-- <mat-expansion-panel (click)="disableFirst()" hideToggle>
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"DRP" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <li routerLinkActive="active"><a routerLink="/account-data/contracts/dashboard-contracts" (click)="toggleLeftBar()">{{"DRP Dashboard" | translate}}</a></li>
        </ul>
      </mat-expansion-panel> -->
      <mat-expansion-panel (click)="disableFirst()" hideToggle *ngIf="permissionsService.havePermission('order')">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"Scheduling" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <!-- <li routerLinkActive="active"><a routerLink="/account-data/contracts/dashboard-contracts" (click)="toggleLeftBar()">{{"Two Machines" | translate}}</a></li> -->
          <li routerLinkActive="active"><a routerLink="/order/pfsp/dashboard-pfsp" (click)="toggleLeftBar()" *ngIf="permissionsService.havePermission('order', 'dashboard-pfsp')">{{"PFSP"
              | translate}}</a></li>
          <!-- <li routerLinkActive="active"><a routerLink="/account-data/contracts/dashboard-contracts" (click)="toggleLeftBar()">{{"Job-Shop"
              | translate}}</a></li> -->
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="disableFirst()" hideToggle *ngIf="permissionsService.havePermission('cms')">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"CSM" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <li routerLinkActive="active"><a routerLink="/cms/cell-formation-problem/dashboard-cell-formation-problem"
              (click)="toggleLeftBar()" *ngIf="permissionsService.havePermission('cms', 'dashboard-cell-formation-problem')">{{"CFP"
              | translate}}</a></li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="disableFirst()" hideToggle *ngIf="permissionsService.havePermission('routing')">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"Routing" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <!-- <li routerLinkActive="active"><a routerLink="/reports/assortment-by-property-report/dashboard-assortment-by-property-report"
              (click)="toggleLeftBar()">{{"SSSP" | translate}}</a></li>
          <li routerLinkActive="active"><a routerLink="/reports/pos-by-property-report/dashboard-pos-by-property-report"
              (click)="toggleLeftBar()">{{"APSP" | translate}}</a></li> -->
          <li routerLinkActive="active"><a routerLink="/routing/ptp/dashboard-distribution"
              (click)="toggleLeftBar()">{{"Point To Point" | translate}}</a></li>
          <!-- <li routerLinkActive="active"><a routerLink="/reports/statistics-of-visiting-report/dashboard-statistics-of-visiting-report"
              (click)="toggleLeftBar()">{{"Klark - Rajt" | translate}}</a></li>
          <li routerLinkActive="active"><a routerLink="/reports/sales/dashboard-sales" (click)="toggleLeftBar()">{{"Routes"
              | translate}}</a></li> -->
          <li routerLinkActive="active"><a routerLink="/routing/sssp/dashboard-distribution" (click)="toggleLeftBar()"
              *ngIf="permissionsService.havePermission('routing', 'dashboard-distribution')">{{"Single Source SP" |
              translate}}</a></li>
          <li routerLinkActive="active"><a routerLink="/routing/tsp/dashboard-distribution" (click)="toggleLeftBar()">{{"TSP"
              | translate}}</a></li>
          <li routerLinkActive="active"><a routerLink="/routing/cvrp/dashboard-distribution" (click)="toggleLeftBar()">{{"Multi destinations centers"
              | translate}}</a></li>
          <li routerLinkActive="active"><a routerLink="/routing/geo-location/dashboard-geo-location" (click)="toggleLeftBar()">{{"GeoLocation"
            | translate}}</a></li>
            <!-- <li routerLinkActive="active"><a routerLink="/routing/graph-nodes/dashboard-graph-nodes" (click)="toggleLeftBar()">{{"GraphNodes"
              | translate}}</a></li> -->
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="disableFirst()" hideToggle>
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"Assignment" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <li routerLinkActive="active"><a routerLink="/assignment/qap/dashboard-qap" (click)="toggleLeftBar()" *ngIf="permissionsService.havePermission('assignment', 'dashboard-qap')">{{"Square Assignment" | translate}}</a></li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="disableFirst()" hideToggle>
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"Packaging" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <li routerLinkActive="active"><a routerLink="/packing/backpack-problem"
              (click)="toggleLeftBar()">{{"3DPacking" | translate}}</a></li>
          <!-- <li routerLinkActive="active"><a routerLink="/leads/potentional-partners/dashboard-potentional-partners"
              (click)="toggleLeftBar()">{{"Bin-Packing" | translate}}</a></li> -->
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="disableFirst()" hideToggle *ngIf="permissionsService.havePermission('mrp')">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <p class="panelSquareTitle"><span>{{"MRP" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <li routerLinkActive="active"><a routerLink="/mrp/deterministic-mrp/dashboard-deterministic-mrp" (click)="toggleLeftBar()"
              *ngIf="permissionsService.havePermission('mrp', 'dashboard-deterministic-mrp')">{{"Deterministic MRP" |
              translate}}</a></li>
          <!-- <li routerLinkActive="active" s><a routerLink="/basic-sales-data/partners/dashboard-change-group-partners-data"
              (click)="toggleLeftBar()">
              {{"Stochastic MRP" | translate}}</a>
          </li> -->
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle [disabled]="disableHome" [ngClass]="disableHome ? 'dark' : 'light'" *ngIf="permissionsService.havePermission('admin-config')">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <a routerLink="/admin-config/dashboard-admin-config" class="panelHomeTitle" (click)="toggleLeftBar()"><span>{{"AdminConfig" | translate}}</span></a>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>
<!-- <a class="navbar-brand" href="#">Navbar</a> -->
