export const TYPE_OF_MAP_FILTER: any[] = [
    {
        id: 1,
        title: "AllTypes",
        type: "all"
    },
    {
        id: 2,
        title: "Map",
        type: "map"
    },
    {
        id: 3,
        title: "Graph",
        type: "graph"
    }
]