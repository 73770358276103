import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';

@Component({
  selector: 'app-header-geo-location',
  templateUrl: './header-geo-location.component.html',
  styleUrls: ['./header-geo-location.component.scss']
})
export class HeaderGeoLocationComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    private markdownService: MarkdownService
  ) { }

  public safeData: any;
  public unsafeHTML = "assets/markdown/routing/geolocation/help1.html";

  ngOnInit(): void {
    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  openMarkdown() {
    this.markdownService.openMarkdown(this.safeData);
  }

}
