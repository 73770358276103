export class SpinnerFunctions {
    static showSpinner(id?: string){
      
        let spinner = document.querySelector(id ? id : "#pms-global-spinner") as HTMLElement;
        
        if(spinner){
            // spinner.style.display = SpinnerFunctions.checkIsDisplayed()? 'none' : 'flex';
            spinner.style.display = 'flex';
        }
        this.hideSkeleton();
    }

    static hideSpinner(id?: string){
        let spinner = document.querySelector(id? id : "#pms-global-spinner")  as HTMLElement;
        if(spinner){
            spinner.style.display = 'none';
        }
        this.hideSkeleton();
    }

    static checkIsDisplayed(){
        let spinner = document.getElementById("#pms-global-spinner");
        if(spinner){
            return spinner.style.display == 'flex';
        }
        return false;
    }

    private static hideSkeleton(){
        let skeletonSpinner = document.getElementById("skeletonBox");

        if(skeletonSpinner){
            skeletonSpinner.style.display = "none";
        }
    }
    
}