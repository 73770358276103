@if (isMapReady) {
  <div style="height: inherit" class="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="clickOnMap($event)"
    >
    <!-- [leafletLayersControl]="layersControl" -->
    @for (marker of markerObjects; track marker) {
      <div>
        <div [leafletLayer]="marker"></div>
      </div>
    }
    @if (polylines) {
      <div>
        @for (item of polylines; track i; let i = $index;) {
          <div>
            <div [leafletLayer]="item"></div>
          </div>
        }
      </div>
    }
    <button
      mat-raised-button
      color="basic"
      id="showHideMapBtn"
      type="button"
      (click)="showHideMap($event)">
      {{ (isHideMap ? "ShowMap" : "HideMap") | translate }}
    </button>
  </div>
}

<span class="current-location">
  {{ currentLocation }}
</span>
