import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { cloneDeep, flow } from 'lodash';
import { Subscription } from 'rxjs';
import { apiPaths } from '../../../../../../config/api';
import { DynamicAlgorithmFormControlsService } from '../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';
import { FileType } from '../../../../../../shared/enums/file-type';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlReportsService } from '../../../../../../shared/services/bl-reports.service';
import { FileDownloadService } from '../../../../../../shared/services/file-download.service';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { BlQapFormService } from '../../../../services/forms/bl-qap-form.service';
import { BlQapTestFormService } from '../../../../services/forms/bl-qap-test-form.service';
import { BlQapRequestsService } from '../../../../services/requests/bl-qap-requests.service';
import { BlQapDataService } from '../../../../services/shared/bl-qap-data.service';
import { FormDashboardQapComponent } from '../form-dashboard-qap/form-dashboard-qap.component';
import { SaveAsDialogDashboardQapComponent } from '../save-as-dialog-dashboard-qap/save-as-dialog-dashboard-qap.component';
import { ViewDialogDashboardQapComponent } from '../view-dialog-dashboard-qap/view-dialog-dashboard-qap.component';
import { QapHelperFunctionsService } from '../../../../services/helper/qap-helper-functions.service';

@Component({
  selector: 'app-header-dashboard-qap',
  templateUrl: './header-dashboard-qap.component.html',
  styleUrls: ['./header-dashboard-qap.component.scss']
})
export class HeaderDashboardQapComponent implements OnInit, OnDestroy {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlQapDataService,
    public formService: BlQapFormService,
    private requestsService: BlQapRequestsService,
    private alertService: AlertMessageDataService,
    public permissionsService: PermissionsService,
    private sanitizer: DomSanitizer,
    private reportService: BlReportsService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private testFormService: BlQapTestFormService,
    private markdownService: MarkdownService,
    private fileDownloadService: FileDownloadService,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  private dataForSend: any = [];
  private dataFromStorage: any;
  public mainTitle: string = "";

  public clickedButton: boolean = false;
  public showTestButton: boolean = false;
  public isLiteratureProblem: boolean = false;

  public safeData: any;
  public unsafeHTML: string = "assets/markdown/assignment/quadratic-assignment-problem/help1.html";


  // ngAfterViewInit(): void {
  //   this.dataService.originalObject.subscribe(data => {
  //     this.formService.isExportShow = !!(data && data.id);
  //     if (data) {
  //       this.dataFromStorage = data;
  //     }
  //   });
  // }

  ngOnInit(): void {
    this.dataService.originalObject.subscribe({
      next: (data) => {
        this.dataFromStorage = cloneDeep(data);
        this.formService.isExportShow = !!(data && data.id);
        this.formService.isShowSavesButtons = !!data;
        this.showTestButton = !!(data && data.id);
        if(data) {
          if(data.problemType && data.problemType.toLowerCase() == "l") {
            this.isLiteratureProblem = true;
          } else {
            this.isLiteratureProblem = false;
          }
        }
      }
    });

    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  newQAP() {
    const dialogRef = this.matDialog.open(FormDashboardQapComponent, {
      width: '80%',
      maxWidth: '500px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    }).afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.mainTitle = val;
          this.testFormService.isShowReportTable = false;
          this.testFormService.reset();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.changeAlgorithm(this.formService.form.getSafe(x => x.algorithm).value);
          this.formService.createFlowsTableWithDynamicRows(this.formService.sizes, this.formService.sizes);
          this.formService.createDistancesTableWithDynamicRows(this.formService.sizes, this.formService.sizes);
        }
      }
    });
  }

  viewQAP() {
    const dialogRef = this.matDialog.open(ViewDialogDashboardQapComponent, {
      width: '90%',
      maxWidth: '550px',
      height: "auto",
      minHeight: "210px",
      panelClass: ["custom-dialog", "no-actions-dialog"]
    }).afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.testFormService.isShowReportTable = false;
          this.testFormService.reset();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.changeAlgorithm(this.formService.form.getSafe(x => x.algorithm).value);
        }
      }
    });
  }

  changeAlgorithm(value): void {
    if (value) {
      let choosenAlgorithm = cloneDeep(this.formService.algorithmData).find(x => x.id === value);

      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls(choosenAlgorithm.parametri, this.formService.form);
      this.formService.parametri = Object.keys(choosenAlgorithm.parametri);
    }
  }

  testQAP() {
    this.dataService.storageIsRunResults.next(true);
    this.dataService.storageIsTabsView.next(true);
  }

  openMarkdown() {
    this.markdownService.openMarkdown(this.safeData);
  }

  export() {
    this.fileDownloadService.export(this.dataService.idForPatch, apiPaths.assignment.downloadReportCSV, "QAP_Report_", FileType.csv)
  }

  saveDatas() {
    this.clickedButton = true;
    this.dataForSend = [];
    if (this.dataFromStorage) {

      let {flowRows, distanceRows} = this.getFlowAndDistanceRows();

      this.dataForSend.push(this.prepareDataToSend(flowRows, distanceRows));

      SpinnerFunctions.showSpinner();
      this.subscription.add(
        this.requestsService.saveData(this.dataForSend).subscribe({
          next: success => {
            this.flowsAndDistancesArrayFunstionsService.breakApartStavke(success);
            this.alertService.setMessage("success", this.dataFromStorage.id ? "Successfully updated." : "Successfully created.");
            this.clickedButton = false;
            this.dataService.originalObject.next(success[0] ? success[0] : success);

            this.formService.reducedFlowsArray = success[0]? success[0].flows: success["flows"];
            this.formService.reducedDistancesArray = success[0]? success[0].distances: success["distances"];
            this.formService.setFlowsRows(success[0]? success[0]: success);
            this.formService.setDistancesRows(success[0]? success[0]: success);

            if (success['id']) {
              this.dataService.setIdForPatch(success['id']);
            }
            if(!this.dataForSend.id)
              this.mainTitle = success['title'];
            SpinnerFunctions.hideSpinner();
          },
          error: err => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
          }
        })
      );
    }
  }

  runProblem() {
    this.clickedButton = true;
    this.dataForSend = [];
    if (this.dataFromStorage) {

      let {flowRows, distanceRows} = this.getFlowAndDistanceRows();
      let dataToSend = this.prepareDataToSend(flowRows, distanceRows);

      this.dataForSend.push({
        ...dataToSend,
        algorithm: this.formService.form.getSafe(x => x.algorithm).value,
        optimalSequence: (this.formService.reducedNumbersArray && this.formService.reducedNumbersArray.length == 0) ? this.formService.originalNumbersArray : this.formService.reducedNumbersArray,
        ub: this.dataFromStorage.ub ? this.dataFromStorage.ub : null,
        additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParams(this.formService.form),
      });

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        if (this.dataForSend.ub == null) {
          this.alertService.setMessage("danger", "Error. UB is null. Before Run problem, please Save.");
          SpinnerFunctions.hideSpinner();
          this.clickedButton = false;
        } else {
          this.subscription.add(
            this.requestsService.runProblem(this.dataForSend).subscribe({
              next: success => {
                this.flowsAndDistancesArrayFunstionsService.breakApartStavke(success);
                this.alertService.setMessage("success", "Successfully.");
                this.dataService.originalObject.next(success[0] ? success[0] : success);
                SpinnerFunctions.hideSpinner();
                this.clickedButton = false;
                this.formService.isRunProblemForTitle = true;
                this.formService.problemTitle = success[0] ? success[0].title : success["title"];
              },
              error: err => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
                this.clickedButton = false;
              }
            })
          );
        }
      }
    }
  }

  saveAsNew() {
    this.matDialog.open(SaveAsDialogDashboardQapComponent, {
      width: '90%',
      maxWidth: '400px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog",
      data: {
        title: this.mainTitle
      }
    }).afterClosed().subscribe({
      next: (val) => {
        if (val) {
          let form: any;
          if (this.dataService.originalObject.getValue()) {
            form = this.dataService.originalObject.getValue()[0] ? this.dataService.originalObject.getValue()[0] : this.dataService.originalObject.getValue();
          }
          let newSend = [];
          let obj: any = cloneDeep(this.dataService.originalObject.getValue());
          let prepakovanNizObjekataFlows: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(obj.size ? obj.size : form.size);
          let prepakovanNizObjekataDistances: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(obj.size ? obj.size : form.size);


          let flowRows: any = this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(obj && obj.flows ? obj.flows : prepakovanNizObjekataFlows, null);
          let distanceRows: any = this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(obj && obj.distances ? obj.distances : prepakovanNizObjekataDistances, null);
          this.formService.formateArrayNumbers(obj.size ? obj.size : form.size);

          newSend.push({
            id: null,
            size: (obj && obj.size) ? obj.size : form.size,
            title: val,
            problemType: 'c',
            flows: flowRows,
            distances: distanceRows,
          });

          this.formService.problemTitle = "";
          this.subscription.add(
            this.requestsService.insertData(newSend).subscribe({
              next: success => {
                this.flowsAndDistancesArrayFunstionsService.breakApartStavke(success);
                this.dataService.originalObject.next(success);
                this.alertService.setMessage("success", "Successfully created.");
                SpinnerFunctions.hideSpinner();
                this.formService.problemTitle = success[0] ? success[0].title : success["title"];

                this.formService.reducedFlowsArray = success[0]? success[0].flows: success["flows"];
                this.formService.reducedDistancesArray = success[0]? success[0].distances: success["distances"];
                this.formService.setFlowsRows(success[0]? success[0]: success);
                this.formService.setDistancesRows(success[0]? success[0]: success);
              },
              error: error => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
              }
            })
          );
        }
      }
    })
  }

  getFlowAndDistanceRows(): any {
    let flowRows: any = null;
    let distanceRows: any = null;

    let form: any = this.formService.form.getRawValue();
    if(this.dataFromStorage && this.dataFromStorage.id) {
      flowRows = (this.dataFromStorage.flows.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.dataFromStorage.flows, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.formService.reducedFlowsArray, this.formService.reducedFlowsArray);
      distanceRows = (this.dataFromStorage.distances.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.dataFromStorage.distances, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.formService.reducedDistancesArray, this.formService.reducedDistancesArray);
    } else {
      let prepakovanNizObjekataFlows: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
      let prepakovanNizObjekataDistances: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
      flowRows = (form.flows.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(form.flows, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(prepakovanNizObjekataFlows, prepakovanNizObjekataFlows);
      distanceRows = (form.distances.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(form.distances, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(prepakovanNizObjekataDistances, prepakovanNizObjekataDistances);
    }

    this.formService.formateArrayNumbers(this.dataFromStorage.size);

    return { flowRows, distanceRows };
  }

  prepareDataToSend(flowRows: any, distanceRows: any): any {
    let dataToSend = {
      id: this.dataFromStorage.id ? this.dataFromStorage.id : null,
      size: this.dataFromStorage.size,
      title: (this.formService.problemTitle == "") ? this.dataFromStorage.title : this.formService.problemTitle,
      problemType: this.dataFromStorage.problemType,
      flows: flowRows,
      distances: distanceRows,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParams(this.formService.form),
      algorithm: this.formService.form.getSafe(x => x.algorithm).value
    };

    return dataToSend;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.isShowSavesButtons = false;
    this.formService.isShowRunButton = false;
    this.formService.isExportShow = false;
    this.formService.originalNumbersArray = [];
    this.dataForSend = [];
    this.mainTitle = "";
    this.formService.isRunProblemForTitle = false;
    this.formService.problemTitle = "";
    this.showTestButton = false;
    this.isLiteratureProblem = false;
  }
}
