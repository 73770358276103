<div id="not-found-wrapper">

    <div class="content">
        <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
        </h1>
        <h3>{{"Page not found" | translate}}. {{"Back to" | translate}} <span><a routerLink="/home">{{"Home page" | translate}}</a></span>.</h3>
    </div>
    
</div>
