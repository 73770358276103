import { Component, OnInit, Input } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Component({
  selector: 'app-table-no-records',
  templateUrl: './table-no-records.component.html',
  styleUrls: ['./table-no-records.component.scss']
})
export class TableNoRecordsComponent implements OnInit {

  @Input() public columns: ITableColumn[] = [];
  @Input() public displayedColumnProps: string[] = [];
  
  constructor() { }

  ngOnInit() {
  }

}
