<!-- Loading -->
<!-- <ng-container *ngIf="isLoading && dataSource.data.length == 0">
<app-skeleton></app-skeleton>
</ng-container> -->
<!-- End Loading -->

<!-- No records -->
@if ((!isLoading && dataSource.data && dataSource.data.length == 0 ) || dataSource.data == null) {
  <app-table-no-records [columns]="columns" [displayedColumnProps]="displayedColumnProps"></app-table-no-records>
}
<!-- End No records -->

<div>
  <!-- TABLE -->
  <form [formGroup]="form">
    <ng-container formArrayName="items">
      <mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" class="mat-elevation-z2 customTable"
        [ngClass]="(!isLoading && dataSource.data && dataSource.data.length > 0)? '': 'hide'">

        <!-- Dynamic Columns -->
        @for (column of columns; track column) {
          <ng-container [matColumnDef]="column.index">
            <!-- th -->
            <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column | sortableColumn: fixedColumns"
              [ngClass]="column | columnClass">
              {{ column.title | translate}}
            </mat-header-cell>
            <!-- End th -->
            <!-- td -->
            <mat-cell *matCellDef="let row; let rowIndex = index" class="light_blue" [ngClass]="column | cellClass: row">
              <!-- Base Column Definition -->
              <ng-container [formGroupName]="(row | rowIndex: paginator : isServerRendering : dataSource) - 1">
                @if ((column | columnValueCondition: row)) {
                  <span (click)="columnMethod(column, row)">
                    <!-- Print Column Value By Column Type -->
                    @switch (true) {
                      <!-- Row Index Column -->
                      @case (column | idColumn: idColumn) {
                        {{ row | rowIndex: paginator : isServerRendering : dataSource }}
                      }
                      <!-- End Row Index Column -->
                      <!-- Checkbox/Select Column -->
                      @case (column | checkboxColumn) {
                        <ng-container class="tableSelectColumn">
                          <mat-checkbox (change)="$event ? ((column.condition && column.condition(row, selectedRows))? checkboxMethod($event, column, row) : null)  : null; ($event && column.method)? checkboxMethod($event, column, row)  : null; "
                            [checked]="selectedRows.isSelected(row)" [disabled]="column | disabledColumn: row">
                          </mat-checkbox>
                        </ng-container>
                      }
                      <!-- End Checkbox/Select Column -->
                      <!-- Array Column -->
                      @case ((column | arrayColumn: row) && (!(column | templateColumn))) {
                        {{ row[column.index] | arrayToString }}
                      }
                      <!-- End Array Column -->
                      <!-- Template Column -->
                      @case ((column | templateColumn)) {
                        <span [innerHTML]="(column | renderTemplateColumn: row) | safeHtml"></span>
                      }
                      <!-- End Template Column -->
                      <!-- Boolean Column -->
                      @case (column | booleanColumn: row) {
                        <mat-slide-toggle [checked]="row[column.index] | checked" disabled="true">
                        </mat-slide-toggle>
                      }
                      <!-- End Boolean Column -->
                      <!-- Updatable Boolean Column -->
                      @case ((column | updatableBooleanColumn: row) && !(column | formControl: row)) {
                        <mat-slide-toggle (change)="column.method? column.method($event.checked? true: false, row) : update($event.checked, row, column)"
                        [checked]="row[column.index] | checked" [disabled]="column | disabledColumn: row"></mat-slide-toggle>
                      }
                      <!-- End Updatable Boolean Column -->
                      <!-- Date Column -->
                      @case (column | dateColumn) {
                        {{ row[column.index] | dateFormat: column.dateColumn }}
                      }
                      <!-- End Date Column -->
                      <!-- Nested Property Column -->
                      @case (column | nestedPropertyColumn) {
                        {{ row | nestedPropertyValue: column.index }}
                      }
                      <!-- End Nested Property Column -->
                      <!-- Image Column -->
                      @case (column | imageColumn) {
                        <img src="{{ row[column.index] }}" />
                      }
                      <!-- End Image Column -->
                      <!-- Form Control Column -->
                      @case (column | formControl: row) {
                        @switch (true) {
                          @case ((column | updatableBooleanColumn: row)) {
                            <mat-slide-toggle [formControlName]="column.index">
                            </mat-slide-toggle>
                          }
                          @default {
                            <mat-form-field appearance="outline">
                              <input matInput [type]="column | formControlType: row"
                                [formControlName]="column.index" (change)="changeTbValue(row, column.index, $event.target.value)" />
                              </mat-form-field>
                            }
                          }
                        }
                        <!-- End Form Control Column -->
                        <!-- Action Column / Column With Options -->
                        @case (column | actionColumn) {
                          @for (option of column.options; track option) {
                            @if (option | columnValueCondition: row) {
                              @if (option | formControl: row) {
                                <button mat-flat-button color="accent" (click)="optionFormMethod(option, row)"
                                  [disabled]="isFormValid(row)" [class.disabled]="isFormValid(row)"
                                  [ngClass]="(option | optionClass) + ((isFormValid(row))? ' disableButton': '')">
                                  {{ option.title | translate }}
                                </button>
                              } @else {
                                <button mat-flat-button color="accent" (click)="optionMethod(option, row)"
                                  [disabled]="option | disabledColumn:row" [class.disabled]="option | disabledColumn:row"
                                  [ngClass]="(option | optionClass) + ((option | disabledColumn:row)? 'disableButton': '')">
                                  {{ option.title | translate }}
                                </button>
                              }
                            }
                          }
                        }
                        <!-- End Action Column / Column With Options -->
                        <!-- Default Column -->
                        @default {
                          {{ (row[column.index]) }}
                        }
                        <!-- End Default Column -->
                      }
                      <!-- End Print Column Value By Column Type -->
                    </span>
                  } @else {
                    @if (column | otherTemplateColumn) {
                      <!-- Action Column / Column With Options -->
                      @if (column.otherTemplate | actionColumn) {
                        @for (option of column.otherTemplate.options; track option) {
                          @if (option | columnValueCondition: row) {
                            <button (click)="optionMethod(option, row)" [disabled]="option | disabledColumn:row"
                              [ngClass]="(option | optionClass) + ((option | disabledColumn:row)? 'disableButton': '')">
                              {{ option.title | translate }}
                            </button>
                          }
                        }
                      }
                      <!-- End Action Column / Column With Options -->
                    }
                  }
                
                </ng-container>
              </mat-cell>
              <!-- End td -->
            </ng-container>
          }

          <!-- Header and Row Definitions -->
          <mat-header-row *matHeaderRowDef="displayedColumnProps"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnProps;">
          </mat-row>
          <!-- End Header and Row Definitions -->

        </mat-table>
      </ng-container>
      <!-- END TABLE -->
    </form>

    <div class="actionsContainer light_blue">
      <div class="actions flexStart">

        <!-- Buttons After Table -->
        @for (button of buttons; track button) {
          @if (button | buttonCondition) {
            <button (click)="buttonMethod(button)" mat-flat-button [ngClass]="button | buttonClass" [color]="(button.title === 'Delete')? 'warn' : 'accent'">
              <p>{{ button.title | translate }}</p>
              <!-- TODO: For what is this? Animation? [class.run-yield-managment]="button.title == 'RunYieldManagement'" -->
            </button>
          }
        }
        <!-- End Buttons After Table -->

      </div>

      <div class="paginations pageNumber">

        <!-- Go To Page List -->
        <div class="goToPage">
          <mat-form-field appearance="outline">
            <mat-label>{{"Go to page" | translate}}:</mat-label>
            <mat-select [(ngModel)]="defaultPage">
              @for (num of pages; track num) {
                <mat-option [value]="num" (click)="goToPage(num)">{{num}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <!-- End Go To Page List -->

        <!-- Pagination -->
        <mat-paginator class="shortPaginator" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalCount"
          showFirstLastButtons>
        </mat-paginator>
        <!-- End Pagination -->

      </div>
    </div>
  </div>