import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';
import { IProblemAlgorithm } from '../../interfaces/i-problem-algorithm';

@Injectable({
  providedIn: 'root'
})
export class ProblemAlgorithmsService extends ApiService<IProblemAlgorithm>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.problemAlgorithms, http);
  }

  getAlgorithmsByProblemType(problemType: string): Observable<IProblemAlgorithm[]> {
    this.url = apiPaths.routing.problemAlgorithms + "/" + problemType;
    return this.getAll();
  }

}
