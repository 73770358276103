import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { cloneDeep } from 'lodash';
import { ICompanySettings } from '../interfaces/i-company-settings';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AlertMessageDataService } from '../../shared/services/alert-message-data.service';
import { DateFunctions } from '../../shared/functions/date-functions';
import { LocalStorageFunctions } from '../../shared/functions/local-storage-functions';
import { LocalStorageKeys } from '../../shared/enums/local-storage-keys';
import { config } from '../../config/global';
import { apiPaths } from '../../config/api';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {

  private storage = new BehaviorSubject<ICompanySettings>(null);
  private realTime = new BehaviorSubject<Date>(null);
  private isDateChanged = new BehaviorSubject<boolean>(false);
  private stopwatch: any;

  constructor(
    private alertService: AlertMessageDataService,
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  getCurrentValue() : ICompanySettings {
    return this.storage.getValue();
  }

  getStorage() {
    return this.storage.asObservable();
  }

  getRealTime() {
    return this.realTime.asObservable();
  }

  getIsDateChanged(){
    return this.isDateChanged.asObservable();
  }

  getIsDateChangedCurrentValue() {
    return this.isDateChanged.getValue();
  }

  getRealTimeCurrentValue(){
    return DateFunctions.getMomentDate(this.realTime.getValue());
  }

  setICompanySettingsGotDateTime(value: ICompanySettings){
    let usertime = DateFunctions.getUserTodayDateTime();
    value.current_time = usertime;
  }

  setICompanySettingsTimezone(value: ICompanySettings) {
    let usertime = DateFunctions.getUserTodayDateTime();
    value.timezone = usertime.getTimezoneOffset()
  }

  updateTimezone(){
    let value = this.getCurrentValue();
    this.setICompanySettingsTimezone(value);
    this.storage.next(value);
  }

  // API
  getHotelSettings(reload=false){
    let application = this.getCurrentValue();

    if(application){
        let isTimeZoneChanged = this.isTimeZoneChanged(application);
        if(isTimeZoneChanged){
            return this.getHotelSettingFromApi();
        }
    }
    if((!application) || reload){
        let settingsInLocalStorage = LocalStorageFunctions.get(LocalStorageKeys.company_settings);
        if(settingsInLocalStorage != null && !reload) {
            let isTimeZoneChanged = this.isTimeZoneChanged(settingsInLocalStorage) || this.isUserTimeChanged(settingsInLocalStorage);
            if(isTimeZoneChanged){
                return this.getHotelSettingFromApi();
            }
            this.setStorage(settingsInLocalStorage);
        } else {
            return this.getHotelSettingFromApi();
        }
    }
    return of(application)
    
  }
  
  getHotelSettingFromApi(){
      let url = config.baseApiUrl["DEV"];
          if(apiPaths.auth.companySettings.indexOf('.json')==-1){
              url = config.baseApiUrl["SERVER"];
          }
      let request = this.http.get(url + apiPaths.auth.companySettings).pipe(share());
      request.subscribe({
          next: (data : any) => {
              this.setHotelSettingsGotDateTime(data);
              let storage = {decimal_points: data["decimal_points"], real_company_time: data['time'], current_time: data['current_time'], isExternalCustommer: data['isExternalCustommer']} as ICompanySettings;
              this.setStorage(storage);
              LocalStorageFunctions.set({
                  key: LocalStorageKeys.company_settings,
                  value: JSON.stringify(storage)
              });
          },
          error: (error: any) => {
              this.alertService.setMessage("danger","Oops. Error with property configuration.");
              this.authService.logout();
          }
      })
      return request;
  }

  setHotelSettingsGotDateTime(value: ICompanySettings){
    let usertime = DateFunctions.getUserTodayDateTime();
    value.current_time = usertime;
  }

  setStorage(value: ICompanySettings){
    this.setICompanySettingsTimezone(value);
    this.storage.next(value);

    let current: Date = DateFunctions.getUserTodayDateTime();
    let saved: Date =  DateFunctions.getMomentDate(value.current_time);
    let hours = current.getHours() - saved.getHours();
    let minutes = current.getMinutes() - saved.getMinutes();
    let seconds = current.getSeconds() - saved.getSeconds();

    let hotelDate = DateFunctions.getMomentDate(value.real_company_time);

    hotelDate.setHours(hotelDate.getHours() + hours);
    hotelDate.setMinutes(hotelDate.getMinutes() + minutes);
    hotelDate.setSeconds(hotelDate.getSeconds() + seconds);
    
    // hotelDate.setDate(30);
    // hotelDate.setHours(23);
    // hotelDate.setMinutes(59);
    // hotelDate.setSeconds(40);

    hotelDate.setMilliseconds(0);
    
    LocalStorageFunctions.setCompanyDate(DateFunctions.formatDate(hotelDate));

    this.trackHotelDate(hotelDate)
  }

  trackHotelDate(hotelDate){
    this.stopwatch = setInterval(() => {
      let userDate = DateFunctions.getUserTodayDateTime();
      let currentDate = DateFunctions.getMomentDate(hotelDate);
      hotelDate.setSeconds(hotelDate.getSeconds() + 1);

      if(DateFunctions.formatDate(currentDate) != DateFunctions.formatDate(hotelDate)) {
        if((this.isDateChanged.getValue() !== true)){
          this.alertService.setMessage("info", "<b>New hotel date started</b>")
        }
        LocalStorageFunctions.setCompanyDate(DateFunctions.formatDate(hotelDate));
        this.isDateChanged.next(true);
      }
      
      this.realTime.next(hotelDate);
    }, 1000)

  }

  isDateLessThanCurrentHotelDate(date: string | Date){
    return DateFunctions.formatDate(date) != DateFunctions.formatDate(this.getRealTimeCurrentValue()) && (DateFunctions.getMomentDate(date).getTime() < this.getRealTimeCurrentValue().getTime());
  }

  isDateLessOEqualThanCurrentHotelDate(date: string | Date){
    return DateFunctions.formatDate(date) == DateFunctions.formatDate(this.getRealTimeCurrentValue()) || (DateFunctions.getMomentDate(date).getTime() <= this.getRealTimeCurrentValue().getTime());
  }

  isTimeZoneChanged(value: ICompanySettings){
    let timezone = DateFunctions.getUserTodayDateTime().getTimezoneOffset(); 
    
    return cloneDeep(value).timezone != timezone;
  }

  isUserTimeChanged(value: ICompanySettings){
    let savedtime = DateFunctions.getMomentDate(cloneDeep(value).current_time);
    let usertime = DateFunctions.getUserTodayDateTime();
    return savedtime.getTime() != usertime.getTime();
  }

  stopHotelDateTime(){
    clearInterval(this.stopwatch)
  }
  
}
