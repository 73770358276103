<div id="login-wrapper">
    
    <div class="login-form">
        <div class="row">
            <div class="col-md-12">
                <h3>{{"COMPUTER INTEGRATED MANUFACTURING" | translate}}</h3>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Username' | translate}} / {{'Email' | translate}}</mat-label>
                        <input matInput (keydown.enter)="textareaEnterPressed($event)" type="text" formControlName="username" />
                        <mat-error *ngIf="form.get('username').invalid">
                            {{'PleaseInsertValidUsername' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
             </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Password' | translate}}</mat-label>
                        <input matInput (keydown.enter)="textareaEnterPressed($event)" type="password" formControlName="password" />
                        <mat-error *ngIf="form.get('password').invalid">
                                {{'PleaseInsertValidPassword' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="button" mat-flat-button color="accent" (click)="submit()" [disabled]="form.invalid">{{'Login' | translate}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 register">
                    <a routerLink="/register"><span>{{"Register" | translate}}</span></a>
                </div>
                <div class="col-sm-6 forget-password">
                    <a routerLink="/forget-password"><span>{{"ForgotPassword" | translate}}</span></a>
                </div>
            </div>
        </form>
    </div>
    
</div>
