import { Pipe, PipeTransform } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableFunctions } from '../../functions/table-functions';

@Pipe({
  name: 'rowIndex'
})
export class RowIndexPipe implements PipeTransform {

  constructor(
    private tableFunctions: TableFunctions
  ){

  }
  
  transform(row: any, paginator: MatPaginator, isServerRendering: boolean, dataSource: MatTableDataSource<any>): number {
    return this.tableFunctions.getIndexOfRow(dataSource, row, isServerRendering, paginator);
  }

}
