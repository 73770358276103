import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { cloneDeep, flow } from 'lodash';
import { Subscription } from 'rxjs';
import { apiPaths } from '../../../../../../config/api';
import { DynamicAlgorithmFormControlsService } from '../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';
import { FileType } from '../../../../../../shared/enums/file-type';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlReportsService } from '../../../../../../shared/services/bl-reports.service';
import { FileDownloadService } from '../../../../../../shared/services/file-download.service';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { BlBackpackProblemFormService } from '../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { BlThreeDRecordsDataService } from '../../../../services/shared/bl-three-d-records-data.service';
import { FormBackpackProblemComponent } from '../form-backpack-problem/form-backpack-problem.component';
import { SaveAsDialogBackpackProblemComponent } from '../save-as-dialog-backpack-problem/save-as-dialog-backpack-problem.component';
import { ViewDialogBackpackProblemComponent } from '../view-dialog-backpack-problem/view-dialog-backpack-problem.component';
import { BackpackHelperFunctionsService } from '../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-header-backpack-problem',
  templateUrl: './header-backpack-problem.component.html',
  styleUrls: ['./header-backpack-problem.component.scss']
})
export class HeaderBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlBackpackProblemDataService,
    public formService: BlBackpackProblemFormService,
    private requestsService: BlBackpackProblemRequestsService,
    private alertService: AlertMessageDataService,
    public permissionsService: PermissionsService,
    private sanitizer: DomSanitizer,
    private reportService: BlReportsService,
    public threeDRecordsDataService: BlThreeDRecordsDataService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private markdownService: MarkdownService,
    private fileDownloadService: FileDownloadService,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  private dataForSend: any = [];
  private dataFromStorage: any;
  public mainTitle: string = "";

  public clickedButton: boolean = false;
  public isLiteratureProblem: boolean = false;

  public safeData: any;
  public unsafeHTML: string = "assets/markdown/packaging/3d-packing-problem/help1.html";

  // ngAfterViewInit(): void {
  //   this.dataService.originalObject.subscribe(data => {
  //     this.formService.isExportShow = !!data;
  //     if(data) {
  //       this.dataFromStorage = data;
  //     }
  //   })
  // }

  ngOnInit(): void {
    this.dataService.originalObject.subscribe({
      next: data => {
        this.formService.isShowSavesButtons = !!data;
        this.dataFromStorage = data;
        this.formService.isExportShow = !!(data && data.id);
        if(data) {
          if(data.problemType && data.problemType.toLowerCase() == "l") {
            this.isLiteratureProblem = true;
          } else {
            this.isLiteratureProblem = false;
          }
        }
      }
    });

    this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
  }

  newBackpackProblem() {
    const dialogRef = this.matDialog.open(FormBackpackProblemComponent, {
      width: '80%',
      maxWidth: '500px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    }).afterClosed().subscribe({
      next: val => {
        if (val) {
          this.mainTitle = val;
          this.formService.resetAlgorithms();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
        }
      }
    });
  }

  viewBackpackProblem() {
    const dialogRef = this.matDialog.open(ViewDialogBackpackProblemComponent, {
      width: '90%',
      maxWidth: '650px',
      height: "auto",
      minHeight: "210px",
      panelClass: ["custom-dialog", "no-actions-dialog"],
    }).afterClosed().subscribe({
      next: val => {
        if (val) {
          SpinnerFunctions.showSpinner();
          if (this.formService.form.getSafe(x => x.algorithm).value != "")
            this.formService.form.getSafe(x => x.algorithm).setValue(this.formService.algorithmData[0].id);
          this.changeAlgorithm(this.formService.form.getSafe(x => x.algorithm).value);
          SpinnerFunctions.hideSpinner();
        }
      }
    });
  }

  changeAlgorithm(value): void {
    if (value) {
      let choosenAlgorithm = cloneDeep(this.formService.algorithmData).find(x => x.id === value);
      this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls((choosenAlgorithm.parametri == null)? {}: choosenAlgorithm.parametri, this.formService.form);
      this.formService.parametri = Object.keys((choosenAlgorithm.parametri == null)? {}: choosenAlgorithm.parametri);
    }
  }

  openMarkdown() {
    this.markdownService.openMarkdown(this.safeData);
  }

  export() {
    this.fileDownloadService.export(this.dataService.idForPatch, apiPaths.packing.downloadBackpackProblemReportCSV, "3D_Packing_Report_", FileType.csv)
  }

  saveDatas() {
    this.clickedButton = true;

    if(this.dataFromStorage) {
      let form: any = this.formService.form.getRawValue();
      let flowRows = null;
      let distanceRows = null;
      if(this.dataFromStorage.id) {
        flowRows = (cloneDeep(this.dataFromStorage.items) && cloneDeep(this.dataFromStorage.items).length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.dataFromStorage.items), form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.formService.reducedItemsArray), cloneDeep(this.formService.reducedItemsArray));
        distanceRows = (cloneDeep(this.dataFromStorage.containers) && cloneDeep(this.dataFromStorage.containers).length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.dataFromStorage.containers), form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.formService.reducedContainersArray), cloneDeep(this.formService.reducedContainersArray));
      } else {
        let prepakovanNizObjekataItems: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(this.dataFromStorage.size, 5);
        let prepakovanNizObjekataContainers: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(this.dataFromStorage.size, 4);
        flowRows = (form.items.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.items, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataItems, prepakovanNizObjekataItems);
        distanceRows = (form.containers.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.containers, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataContainers, prepakovanNizObjekataContainers);
      }

      this.prepareDataToSend(flowRows, distanceRows);

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.subscription.add(
          this.requestsService.saveData(this.dataForSend).subscribe({
            next: success => {
              this.backpackHelperFunctionsService.breakApartStavke(success);
              this.alertService.setMessage("success", this.dataForSend.id ? "Successfully updated." : "Successfully created.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.dataService.originalObject.next(success);
              if (success['id']) {
                this.dataService.setIdForPatch(success['id']);
              }
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }

  }

  runProblem() {
    this.clickedButton = true;
    let flowRows = null;
    let distanceRows = null;


    if(this.dataFromStorage) {
      if(this.dataFromStorage.id) {
        let form: any = this.formService.form.getRawValue();

        flowRows = (this.dataFromStorage.items && this.dataFromStorage.items.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.dataFromStorage.items), form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.formService.reducedItemsArray), cloneDeep(this.formService.reducedItemsArray));

        distanceRows = (this.dataFromStorage.containers && this.dataFromStorage.containers.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.dataFromStorage.containers), form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(cloneDeep(this.formService.reducedContainersArray), cloneDeep(this.formService.reducedContainersArray));
      } else {
        let form: any = this.formService.form.getRawValue();
        let prepakovanNizObjekataItems: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(this.dataFromStorage.size, 5);
        let prepakovanNizObjekataContainers: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(this.dataFromStorage.size, 4);
        flowRows = (form.items.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.items, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataItems, prepakovanNizObjekataItems);
        distanceRows = (form.containers.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.containers, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataContainers, prepakovanNizObjekataContainers);
      }

      this.prepareDataToSendRunProblem(flowRows, distanceRows);

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.runProblem(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.threeDRecordsDataService.setStorage(success['kontejneri'] as any);
              this.threeDRecordsDataService.reportTables = success["riport"];

              this.dataService.show3dTable = true;
              this.dataService.selectedIndex = 1;
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }
  }

  saveAsNew() {
    this.matDialog.open(SaveAsDialogBackpackProblemComponent, {
      width: '90%',
      maxWidth: '450px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog",
      data: {
        title: this.mainTitle
      }
    }).afterClosed().subscribe({
      next: val => {
        if (val) {
          let form: any;
          if (this.dataService.originalObject.getValue()) {
            form = this.dataService.originalObject.getValue()[0] ? this.dataService.originalObject.getValue()[0] : this.dataService.originalObject.getValue();
          }

          let newSend = [];
          let prepakovanNizObjekataItems: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(form.size, 5);
          let prepakovanNizObjekataContainers: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewFormColumns(form.size, 4);

          let obj = cloneDeep(this.dataService.originalObject).getValue();
          let flowRows = this.backpackHelperFunctionsService.mapToSingleArrayStavke(obj && obj.items ? obj.items : prepakovanNizObjekataItems, null)
          let distanceRows = this.backpackHelperFunctionsService.mapToSingleArrayStavke(obj && obj.containers ? obj.containers : prepakovanNizObjekataContainers, null)

          newSend.push({
            id: null,
            size: form.size,
            title: val,
            problemType: 'c',
            items: flowRows,
            containers: distanceRows,
          });
          this.subscription.add(
            this.requestsService.insertData(newSend).subscribe({
              next: success => {
                this.backpackHelperFunctionsService.breakApartStavke(success);

                this.dataService.originalObject.next(success);
                this.formService.reducedItemsArray = success[0] ? success[0].items : success["items"];
                this.formService.reducedContainersArray = success[0] ? success[0].containers : success["containers"];

                this.alertService.setMessage("success", "Successfully created.");
                SpinnerFunctions.hideSpinner();


              },
              error: error => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
              }
            })
          );
        }

      }
    });
  }

  prepareDataToSend(flowRows: any, distanceRows: any): void {
    this.dataForSend = [];

    this.dataForSend.push({
      id: this.dataFromStorage.id ? this.dataFromStorage.id : null,
      size: this.dataFromStorage.size,
      title: this.dataFromStorage.title,
      problemType: this.dataFromStorage.problemType,
      items: flowRows,
      containers: distanceRows,
    });
  }

  prepareDataToSendRunProblem(flowRows: any, distanceRows: any): void {
    this.dataForSend = [];

    this.dataForSend.push({
      size: this.dataFromStorage.size,
      title: (this.formService.problemTitle == "") ? this.dataFromStorage.title : this.formService.problemTitle,
      items: flowRows,
      containers: distanceRows,
      algorithm: this.formService.form.getSafe(x => x.algorithm).value,
      ub: this.dataFromStorage.ub ? this.dataFromStorage.ub : null,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParams(this.formService.form)
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.isShowSavesButtons = false;
    this.formService.isShowRunButton = false;
    this.formService.isExportShow = false;
    this.dataForSend = [];
    this.mainTitle = "";
    this.formService.isRunProblemForTitle = false;
    this.formService.problemTitle = "";
    this.isLiteratureProblem = false;
  }

}
