import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LocalStorageKeys } from '../enums/local-storage-keys';
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, custom? : string): any {

    // https://momentjs.com/
    let format = "L";
    switch(custom){
      case 'day':
        format = "ddd";
        break;
      case 'month':
        format = "MMM";
        break;
      case 'full':
        format = "LL";
        break;
      case 'fulltime':
        format = "L LT";
        break;
      case 'time':
        format = "LT";
        break;
      case 'fullWtime':
        format = "LLL";
        break;
      case 'timeWSeconds':
        format = "LTS";
        break;
      case 'date':
        format = "L";
        break;
      default: 
      format = "L";
        break;
    }
    moment.locale(localStorage.getItem(LocalStorageKeys.country)); // "DEV" - everytime reset locale
    return value? moment(value, "YYYY-MM-DD").format(format) : '';
  }

}
