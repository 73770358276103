<div class="row">
    <div class="col-md-12">
        <span class="tab-subtitle">{{title}}</span>
    </div>
</div>
<div class="row extended-width">
    <div class="col-md-12 table-wrapper">
        <ng-container *ngIf="dataFromStorage?.rowsTab4 && dataFromStorage?.rowsTab4.table && dataFromStorage?.rowsTab4.table.tableData.length > 0; else noAllTableData;">
            <table class="table table-sm table-dynamic">
                <thead class="thead-dark">
                    <tr>
                        <th>&nbsp;</th>
                        <th *ngFor="let header of dataFromStorage?.rowsTab4.table.tableHeader">{{header}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tableRow of dataFromStorage?.rowsTab4.table.tableData; let i=index;">
                        <td>{{i+1}}</td>
                        <ng-container *ngFor="let columns of tableRow.stavke">
                            <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                                {{columns.value}}
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-template #noAllTableData>
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                        {{"NoRecordsFound" | translate}}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
