import { IOption } from "../../../shared/interfaces/i-option";

export const MATERIAL_TYPES: IOption[] = [
    {
        id: 1,
        title: "Asembly"
    },
    {
        id: 2,
        title: "Comp."
    },
    {
        id: 3,
        title: "F"
    },
    {
        id: 4,
        title: "Semi"
    }
]