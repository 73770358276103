import { Injectable } from "@angular/core";

@Injectable()
export class CountryMapper{
  public getCountryById(id : string) : string{
    let countryId = parseInt(id);
    return this.dohvatiSkracenicuPoId(countryId);
  }

  private dohvatiSkracenicuPoId(id : number) : string{
    switch(id){
        case 43:
        case 216:
        return 'af';
        case 227:
        case 244:
        return 'ar';
        case 205:
        return 'ar-sa';
        case 127:
        return 'ar-ly';
        case 161: 
        return 'ar-mo';
        case 4:
        return 'ar-dz';
        case 120:
        return 'ar-kw';
        case 237:
        return 'ar-tn';
        case 12:
        return 'hy-am';
        case 16:
        return 'az';
        case 21:
        return 'be';
        case 29:
        return 'bs';
        case 35:
        return 'bg';
        case 39:
        return 'km';
        case 46:
        return 'zh-cn';
        case 100:
        return 'zh-hk';
        case 228:
        return 'zh-tw';
        case 56:
        return 'hr';
        case 60:
        return 'cs';
        case 61:
        return 'da';
        case 211:
        case 167:
        return 'nl';
        case 22:
        return 'nl-be';
        case 14:
        return 'en-au';
        case 41:
        return 'en-ca';
        case 107:
        return 'en-ie'
        case 109:
        return 'en-il';
        case 169:
        return 'en-nz';
        case 210:
        return 'en-sg';
        case 245:
        return 'en-gb';
        case 246:
        case 247:
        return 'en';
        case 70:
        return 'et';
        case 73:
        return 'fo';
        case 75: 
        return 'fi';
        case 76:
        return 'fr';
        case 226:
        return 'fr-ch';
        case 82:
        return 'ka';
        case 83:
        return 'de';
        case 15:
        return 'de-at';
        case 86:
        return 'el';
        case 103:
        case 33:
        return 'hi';
        case 101:
        return 'hu';
        case 102:
        return 'is';
        case 104:
        return 'id';
        case 110:
        return 'it';
        case 112:
        return 'ja';
        case 115:
        return 'kk';
        case 118:
        case 119:
        return 'ko';
        case 121:
        return 'ky';
        case 122:
        return 'lo';
        case 123:
        return 'lv';
        case 129:
        return 'lt';
        case 130:
        return 'lb';
        case 132:
        return 'mk';
        case 134:
        return 'ms';
        case 136:
        return 'dv';
        case 138:
        return 'mt';
        case 158:
        return 'mn';
        case 159:
        return 'me';
        case 166: 
        return 'ne';
        case 176:
        return 'nn';
        case 187:
        return 'pl';
        case 188:
        return 'pt';
        case 192:
        return 'ro';
        case 193:
        return 'ru';
        case 207:
        return 'sr-cyrl';
        case 212:
        return 'sk';
        case 213:
        return 'sl';
        case 219:
        return 'es';
        case 225:
        return 'sv';
        case 231:
        return 'th';
        case 238:
        return 'tr';
        case 243:
        return 'uk';
        case 249:
        return 'uz';
        case 252:
        return 'vi';
        default:
        return 'en-gb';
    }
  }
}