import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { BlRegisterFormService } from '../../services/forms/bl-register-form.service';
import { FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { IRegisterForm } from '../../interfaces/i-register';
import { SpinnerFunctions } from '../../../shared/functions/spinner-functions';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlRegisterFormService,
    private alertService: AlertMessageDataService,
    private router: Router
  ) {}

  public form: FormGroupTypeSafe<IRegisterForm> = this.formService.getFormReference();

  ngOnInit() {
  }

  setConfirmPassword(): void {
    this.formService.passwordValidation();
  }

  passStrengthChanged(strength): void {
    this.formService.passStrengthChanged(strength);
  }

  changeCurrentPass(): void {
    this.formService.changeCurrentPass();
  }

  save(): void {
    SpinnerFunctions.showSpinner();

    this.formService.submitInsert().subscribe({
      next: success => {
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage("success", "Successfully registered user. Check your email to activate your account.")
        this.router.navigateByUrl('/');
      },
      error: err => {
        this.alertService.setMessage("danger", "Error.")
        SpinnerFunctions.hideSpinner();
      }
    })
  }

  textareaEnterPressed($event: KeyboardEvent) {
    $event.preventDefault()
    $event.stopPropagation()

    SpinnerFunctions.showSpinner();

    this.formService.submitInsert().subscribe({
      next: success => {
        SpinnerFunctions.hideSpinner();
        this.alertService.setMessage("success", "Successfully registered user. Check your email to activate your account.")
        this.router.navigateByUrl('/');
      },
      error: err => {
        this.alertService.setMessage("danger", "Error.")
        SpinnerFunctions.hideSpinner();
      }
    })
  }

  ngOnDestroy(): void {
    this.formService.reset();
  }

}
