<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
    </div>
  </div>
</div>

<form class="custom-form" [formGroup]="formService.form" (ngSubmit)="save()">
  <div mat-dialog-content>
    <div class="form-content">
      <div class="row">
        <div class="col-sm-12 formHeading">
          <h3 class="modalHeading">
            {{"Add" | translate}}&nbsp; {{"MRP Specification_akuzativ" | translate}}
          </h3>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field color="primary" appearance="outline"
            [ngClass]="formService.form.get('title').errors ? 'requiredField' : ''">
            <mat-label>{{"Problem Title" | translate}}</mat-label>
            <input [formControlName]="form.keys.title" matInput autocomplete="off" />
            <mat-error>
              @if (formService.form.get('title').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
              @if (formService.form.get('title').hasError('noWhiteSpace')) {
                {{"NoWhiteSpace" | translate}}
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline" [ngClass]="formService.form.get('noProductPartItems').errors ? 'requiredField' : ''">
            <mat-label>{{"Number Of Product And Part Items" | translate}}</mat-label>
            <input type="number" min=1 [formControlName]="form.keys.noProductPartItems" matInput autocomplete="off" />
            <mat-error>
              @if (formService.form.get('noProductPartItems').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <mat-form-field appearance="outline" [ngClass]="formService.form.get('maxNoOfDirectComponent').errors ? 'requiredField' : ''">
            <mat-label>{{"Maximum Number Of Direct Components Per Parent Item" | translate}}</mat-label>
            <input type="number" min=1 [formControlName]="form.keys.maxNoOfDirectComponent" matInput autocomplete="off" />
            <mat-error>
              @if (formService.form.get('maxNoOfDirectComponent').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline" color="primary"
            [ngClass]="formService.form.get('timeUnitPlanningPeriod').errors ? 'requiredField' : ''">
            <mat-label>{{"Time Unit Of Planning Period" | translate}}</mat-label>
            <mat-select (selectionChange)="onSelectOption($event)"
              [formControlName]="form.keys.timeUnitPlanningPeriod"
              panelClass="custom-mat-select-panel-class"
              disableOptionCentering>
              @for (option of dropdownData.PlanningPeriods; track option) {
                <mat-option [value]="option.title">
                  {{option.title}}
                </mat-option>
              }
            </mat-select>
            <mat-error>
              @if (formService.form.get('timeUnitPlanningPeriod').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-3">
          <mat-form-field appearance="outline" [ngClass]="formService.form.get('noPlanningPerod').errors ? 'requiredField' : ''">
            <mat-label>{{"Number Of Planning Periods" | translate}}</mat-label>
            <input type="number" min=1 [formControlName]="form.keys.noPlanningPerod" matInput autocomplete="off" />
            <mat-error>
              @if (formService.form.get('noPlanningPerod').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-3">
          <mat-form-field appearance="outline" [ngClass]="formService.form.get('noPeriodsYear').errors ? 'requiredField' : ''">
            <mat-label>{{"Number Of Periods Per Year" | translate}}</mat-label>
            <input type="number" [min]=formService.minValues [max]=formService.maxValues [formControlName]="form.keys.noPeriodsYear" matInput autocomplete="off" />
            <mat-error>
              @if (formService.form.get('noPeriodsYear').hasError('required')) {
                {{"ThisFieldIsRequired" | translate}}
              }
              @if (formService.form.get('noPeriodsYear').hasError('min')) {
                {{"MinimalValueValidation" | translate}}&nbsp; {{this.formService.minValues}}.
              }
              @if (formService.form.get('noPeriodsYear').hasError('max')) {
                {{"MaximalValueValidation" | translate}}&nbsp; {{this.formService.maxValues}}.
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button color="primary" type="submit" [disabled]="!checkIfFormIsValid()">{{'Apply' | translate}}</button>
    <button mat-flat-button type="button" color="warn" (click)="close()">{{'Close' | translate}}</button>
  </div>
</form>
