import { Pipe, PipeTransform } from '@angular/core';
import { TableFunctions } from '../functions/table-functions';

@Pipe({
  name: 'nestedPropertyValue'
})
export class NestedPropertyValuePipe implements PipeTransform {

  constructor(
    private tableFunctions: TableFunctions
  ){
  }

  transform(obj: any, propertyName: string): any {
    return this.tableFunctions.getProperty(obj, propertyName);
  }

}
