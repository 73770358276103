import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


const GEOLOCATION_ERRORS = {
  'errors.location.unsupportedBrowser': 'Browser does not support location services',
  'errors.location.permissionDenied': 'You have rejected access to your location',
  'errors.location.positionUnavailable': 'Unable to determine your location',
  'errors.location.timeout': 'Service timeout has been reached'
};

@Injectable()
export class GeoPositioningService {

  constructor(
    private translateService: TranslateService
  ) { }

  public turnOnLocationMessage = "";

  public getLocation(geoLocationOptions?: any): Observable<any> {
    geoLocationOptions = geoLocationOptions || { timeout: 5000 };

    return Observable.create(observer => {

      if (window.navigator && window.navigator.geolocation) {
        this.turnOnLocationMessage = "";

        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position);
            observer.complete();
          },
          (error) => {
            switch (error.code) {
              case 1:
                observer.error(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
                this.turnOnLocationMessage = this.translateService.get("TurnOnLocationMessage")["value"];
                break;
              case 2:
                observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
                break;
              case 3:
                observer.error(GEOLOCATION_ERRORS['errors.location.timeout']);
                break;
            }
          },
          geoLocationOptions);
      } else {
        observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
      }
    });
  }

  public locationFormat(location) {

    if (typeof (location) != "undefined") {
      const address = [];

      const building = [];
      if (location['building']) {
        building.push(location['building']);
      }
      if (location['mall']) {
        building.push(location['mall']);
      }
      if (location['theatre']) {
        building.push(location['theatre']);
      }

      const zip_city = [];
      if (location['postcode']) {
        zip_city.push(location['postcode']);
      }
      if (location['city']) {
        zip_city.push(location['city']);
      }
      const street_number = []
      if (location['street']) {
        street_number.push(location['street']);
      }
      if (location['road']) {
        street_number.push(location['road']);
      }
      if (location['footway']) {
        street_number.push(location['footway']);
      }
      if (location['pedestrian']) {
        street_number.push(location['pedestrian']);
      }
      if (location['house_number']) {
        street_number.push(location['house_number']);
      }

      if (building.length) {
        address.push(building.join(' '));
      }
      if (zip_city.length) {
        address.push(zip_city.join(' '));
      }
      if (street_number.length) {
        address.push(street_number.join(' '));
      }

      let pointedAddress = address.join(', ');
      return pointedAddress;
    }

    return "Unknown location";

  }
}

export let geolocationServiceInjectables: Array<any> = [
  { provide: GeoPositioningService, useClass: GeoPositioningService }
];