<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 formHeading">
            <h3 class="modalHeading">
                {{"PFSP" | translate}}
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-4">
            <form [formGroup]="formService.formFilter" class="reducedForm">
                <mat-form-field appearance="outline" [ngClass]="formService.formFilter.get('problemTypes').errors ? 'requiredField' : ''">
                    <mat-label>{{ "Instance Type" | translate}}</mat-label>
                    <mat-select [formControlName]="form.keys.problemTypes" disableOptionCentering>
                        <mat-option *ngFor="let option of dropdownData.ProblemTypes" [value]="option.type">
                            {{option.title}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.formFilter.get('problemTypes').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="col-sm-6 col-md-8"></div>
    </div>
    <ng-container *ngIf="isOptionSelected == true">
        <div class="row">
            <!-- App Search components -->
            <div class="col-sm-6 col-md-4 searchBox">
                <app-search class="reducedForm" [dataService]="dataService"></app-search>
            </div>
            <!-- End App Search components -->
            <div class="col-sm-6 col-md-8 show-hide-btn-box">
                <app-show-hide-columns class="reducedButton" [tableService]="tableService"></app-show-hide-columns>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 table-box pfsp-table">
                <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService"
                    (refillStorage)="refreshPage()">
                </app-table>
            </div>
        </div>
    </ng-container>
</div>