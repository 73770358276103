import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IFormService } from '../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { INewPasswordActivationForm, INewPasswordActivationRequest } from '../../interfaces/i-new-password-activation';
import { NewPasswordActivationService } from '../api/new-password-activation.service';
import { BlNewPasswordActivationRequestsService } from '../requests/bl-new-password-activation-requests.service';

@Injectable({
  providedIn: 'root'
})
export class BlNewPasswordActivationFormService implements IFormService<INewPasswordActivationForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private requestsService: BlNewPasswordActivationRequestsService,
    private matDialog: MatDialog,
    private apiService: NewPasswordActivationService
  ) {}

  form: FormGroupTypeSafe<INewPasswordActivationForm> = this.init();
  public q = "";
  public strength: number = 0;


  init(): FormGroupTypeSafe<INewPasswordActivationForm> {
    return this.formBuilder.group<INewPasswordActivationForm>({
      password: this.formBuilder.control("", Validators.required),
      passwordConfirm: this.formBuilder.control("", Validators.required),
    });
  }

  getFormReference(): FormGroupTypeSafe<INewPasswordActivationForm> {
    return this.form;
  }

  prepareDataToSend(): INewPasswordActivationRequest {
    let formValue: INewPasswordActivationForm = this.form.value;

    let dataToSend: INewPasswordActivationRequest = {
      q: this.q,
      password: formValue.password
    };

    return dataToSend;
  }

  passwordValidation(): void {
    const password = this.form.get('password').value;
    let confirmPasswordValue = this.form.get('passwordConfirm').value;
    
    if (password !== confirmPasswordValue) {
        this.form.get('passwordConfirm').setErrors( {MatchPassword: true} );
    } else {
      this.form.get('passwordConfirm').setErrors(null);
    }
  }

  changeCurrentPass(): void {
    this.passwordValidation();

    if(this.strength < 100)
      this.form.get("password").setErrors({'validatePass': true});
    else
      return this.form.get("password").setErrors(null);
  }

  passStrengthChanged(strength): void {
    this.strength = strength;
  }

  validatePass(): {} {
    if(this.strength < 100) 
     return {'validatePass': true};
    else
      return null;
  }

  submitInsert() {
    let dataToSend: INewPasswordActivationRequest = this.prepareDataToSend();
    return this.requestsService.insert(dataToSend, "Password", this.apiService, null);
  }

  close(): void {
    this.matDialog.closeAll();
  }

  reset(): void {
    this.form = this.init();
  }

}