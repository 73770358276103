import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'otherTemplateColumn'
})
export class OtherTemplateColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.hasOwnProperty('otherTemplate') && column.otherTemplate != null;
  }

}
