import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'idColumn'
})
export class IdColumnPipe implements PipeTransform {

  transform(column: ITableColumn, idColumn: string): boolean {
    return column.index == idColumn && !column.template;
  }

}
