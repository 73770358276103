<!-- Header Dialog-->
<div mat-dialog-title>
        <div class="row">
            <div class="col-sm-12 closeIconBox">
                <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
            </div>
        </div>
    </div>
    <!-- End Header Dialog-->
    <!-- Form Dialog-->
    <form class="reducedForm" [formGroup]="form">
        <div mat-dialog-content>
            <div class="row">
                <div class="col-sm-12 formHeading">
                    <h3 class="modalHeading" [innerHTML]="html"></h3>
                </div>
            </div>
            <!-- Title Control -->
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field color="accent" appearance="outline" [ngClass]="form.get('title').errors ? 'requiredField' : ''">
                        <mat-label>{{"Title" | translate}}</mat-label>
                        <input [formControlName]="form.keys.title" matInput autocomplete="off" />
                        <mat-error class="customError">
                            <ng-container *ngIf="formService.form.get('title').hasError('required')">
                                {{"ThisFieldIsRequired" | translate}}
                            </ng-container>
                            <ng-container *ngIf="formService.form.get('title').hasError('noWhiteSpace')">
                                    {{"NoWhiteSpace" | translate}}
                                </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- End Title Control -->
        </div>
    
        <div mat-dialog-actions>
            <div class="row" style="width: 100%;">
                <div class="col-sm-12 dialog-action-box">
                    <button mat-flat-button color="accent" [disabled]="!form.get('title').valid" (click)="complete()">{{"Complete" | translate}}</button>
                    <button mat-flat-button color="warn" (click)="close()">{{"Close" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
    <!-- End Form Dialog-->