import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlCellFormationProblemDataService } from '../../../../../../../../services/shared/bl-cell-formation-problem-data.service';

@Component({
  selector: 'app-tab-dashboard-reductions-left-side',
  templateUrl: './tab-dashboard-reductions-left-side.component.html',
  styleUrls: ['./tab-dashboard-reductions-left-side.component.scss']
})
export class TabDashboardReductionsLeftSideComponent implements OnInit, OnDestroy {

  constructor(
    private dataService: BlCellFormationProblemDataService,
  ) { }

  public dataFromStorage: any = null;

  private subscription: Subscription = new Subscription();

  public numbers: any = [];

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          this.dataFromStorage = null;
          if(data && data.rowsTab2) {
            this.dataFromStorage = data;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
