import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { ReportsFunctions } from '../../../../../../../../shared/functions/reports-functions';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { ICellFormationProblemTestDropDownData, ICfpTestForm } from '../../../../../../interfaces/i-cell-formation-problem';
import { BlCfpTestFormService } from '../../../../../../services/form/bl-cfp-test-form.service';
import { BlCellFormationProblemRequestsService } from '../../../../../../services/requests/bl-cell-formation-problem-requests.service';
import { BlCellFormationProblemDataService } from '../../../../../../services/shared/bl-cell-formation-problem-data.service';

@Component({
  selector: 'app-tab-dashboard-test-results',
  templateUrl: './tab-dashboard-test-results.component.html',
  styleUrls: ['./tab-dashboard-test-results.component.scss']
})
export class TabDashboardTestResultsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public formService: BlCfpTestFormService,
    private dataService: BlCellFormationProblemDataService,
    private requestService: BlCellFormationProblemRequestsService,
    private alertService: AlertMessageDataService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  public parametri:any = [];

  public dropdownData: ICellFormationProblemTestDropDownData = {
    Instances: null,
    Algorithms: null,
    Objectives: null
  }

  private subscription: Subscription = new Subscription();

  public dataForTable: any = null;
  public dataFromStorage: any = null;
  public clickedButton: boolean = false;

  ngAfterViewInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          if(data) {
            this.dataFromStorage = data;
          }
        }
      })
    )
  }

  ngOnInit(): void {

    this.getAlgorithms();
    this.getInstances();
    this.getObjectives();
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestService.getAlgorithms().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Algorithms = data;
          }
        }
      })
    )
  }

  getInstances() {
    this.subscription.add(
      this.requestService.getInstances().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Instances = data;
          }
        }
      })
    )
  }

  getObjectives() {
    this.subscription.add(
      this.requestService.getObjectives().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Objectives = data;
          }
        }
      })
    )
  }

  runTest() {
    this.clickedButton = true;
    this.formService.isShowReportTable = false;

    let formValue: ICfpTestForm = this.formService.form.value;
    let objForSend: any = {
      id: this.dataFromStorage? this.dataFromStorage.id: null,
      algorithm: formValue.algorithm,
      instance: formValue.instance,
      objective: formValue.objective,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParamsForTestRun(this.formService.form)
    };

    if (this.clickedButton) {
      SpinnerFunctions.showSpinner();
      this.dataForTable = null;
      this.subscription.add(
        this.requestService.testProblem(objForSend).subscribe({
          next: (success) => {
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;

            this.dataForTable = success;
            this.alertService.setMessage("success", "Successfully.");
            this.formService.isShowReportTable = true;
          },
          error: (error) => {
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
          }
        })
      )
    }
  }

  /**Print functions */
  printReport() {
    ReportsFunctions.printReport();
  }
  /**EndPrint functions */

  changeAlgorithmOptions(event): void {
    const item = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === event.value);
    if(item.parametri) {
      if(event._selected) {
        this.dynamicAlgorithmFormControlsService.addAlgorithmGroup(item, this.formService.form)
        this.parametri.push(item);
      }
      else {
        this.dynamicAlgorithmFormControlsService.removeAlgorithmGroup(item, this.formService.form);
        this.parametri = this.parametri.filter(x => x.id !== item.id);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataForTable = null;
    this.clickedButton = false;
    this.formService.reset();
  }

}
