import { Pipe, PipeTransform } from '@angular/core';
import { TableFunctions } from '../functions/table-functions';

@Pipe({
  name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

  constructor(
    private tableFunctions: TableFunctions
  ){

  }
  
  transform(array: any[]): any {
    if (array.length > 0) {
      const values = array.map( item => item[this.tableFunctions.getNestedKey(item)]? item[this.tableFunctions.getNestedKey(item)] : item);
      return values.join(',');
    }
    return '';
  }

}
