import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { IBomForm } from '../../interfaces/i-bom';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BlBomFormService implements IFormService<IBomForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  form: FormGroupTypeSafe<IBomForm> = this.init();

  public isShowBomTree: boolean = false;
  public isFromViewDialog: boolean = false;
  public rowData: any = null;
  public numberRows: number = 0;
  public numberCols: number = 0;
  public colSpan: number = 0;

  init(): FormGroupTypeSafe<IBomForm> {
    let group: FormGroupTypeSafe<IBomForm> = this.formBuilder.group<IBomForm>({
      rowsTab2: this.formBuilder.array([])
    });

    ValidationFunctions.runValidation(group);
    return group;
  }

  getFormReference(): FormGroupTypeSafe<IBomForm> {
    return this.form;
  }

  setRows(rowData: any) {
    this.form.removeControl("rowsTab2");
    this.form.addControl("rowsTab2", this.formBuilder.array([]));
    if(rowData) {
      let row: any = rowData.rows;
      let row2: any = rowData.rowsTab2;
      let numberRows: any = rowData.noProductPartItems;
      let numberCols: any = rowData.maxNoOfDirectComponent;

      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rowsTab2) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl({value: row[i].itemId, disabled: true }),
          })
        );
        for(let j = 0; j < numberCols; j++) {
          ((this.form.getSafe(x => x.rowsTab2) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c"+j, this.formBuilder.control(row2[i]["c"+j]));
        }
      }
      (this.form.get("rowsTab2") as UntypedFormArray).controls = [...(this.form.get("rowsTab2") as UntypedFormArray).controls];
    }
  }
  createTableWithDynamicRows(rowData: any, numberRows: number) {
    this.removeTableDYnamicRows();
    if (numberRows) {
      let row: any = rowData.rows;
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rowsTab2) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl({value: row[i].itemId, disabled: true }),
          })
        );
        for(let j = 0; j < rowData.maxNoOfDirectComponent; j++) {
          ((this.form.getSafe(x => x.rowsTab2) as UntypedFormArray).controls[i] as UntypedFormGroup).addControl("c"+j, this.formBuilder.control(""));
        }
      }
      (this.form.get("rowsTab2") as UntypedFormArray).controls = [...(this.form.get("rowsTab2") as UntypedFormArray).controls];
    }
  }

  removeTableDYnamicRows() {
    this.form.removeControl("rowsTab2");
    this.form.addControl("rowsTab2", this.formBuilder.array([]));
  }

  reset(): void {
    this.form = this.init();
  }
}
