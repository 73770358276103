import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PROBLEM_TYPES_FILTER } from '../../../../../../config/settings/cms/problem-types-filter';
import { TYPE_OF_MAP_FILTER } from '../../../../../../config/settings/routing/type-of-map-filter';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { Distribution1ToNService } from '../../../../services/api/distribution-1-to-n.service';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';
import { BlDistribution1ToNTableService } from '../../../../services/table/bl-distribution-1-to-n-table.service';

@Component({
  selector: 'app-view-dialog-dashboard-distribution',
  templateUrl: './view-dialog-dashboard-distribution.component.html',
  styleUrls: ['./view-dialog-dashboard-distribution.component.scss']
})
export class ViewDialogDashboardDistributionComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: Distribution1ToNService,
    public tableService: BlDistribution1ToNTableService,
    public dataService: BlDistribution1ToNDataService,
    protected matDialog: MatDialog,
    private leftSidebarFormService: BlLeftSidebarDistribution1ToNFormService,
    private alertService: AlertMessageDataService
  ) { }

  private subscription: Subscription = new Subscription();
  clickedButton: boolean = false;
  isBackgroundImage: boolean;
  isProblemSelected: boolean = false;
  problemTypes = PROBLEM_TYPES_FILTER;
  mapTypes = TYPE_OF_MAP_FILTER;

  ngOnInit(): void {
    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        class: 'flex-76',
        options: [
          {
            title: 'View',
            method: (row) => {
              this.showDataFromService(row);
            }
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close();
          }
        }
      ])
    );
  }

  showDataFromService(row): void {
    this.clickedButton = true;

    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.apiService.get(row.id).subscribe({
          next: (data) => {
            if (data) {
              if(data.poruka != null && data.poruka != "") {
                SpinnerFunctions.hideSpinner();
                this.alertService.setMessage("danger", data.poruka);
              }
              else {
                if(this.dataService.problemType.shortcut == 'ptp')
                data.destinations.unshift(data.destinations.pop());
                this.dataService.isCustom = this.apiService.selectedProblemType == "c";
                this.clickedButton = false;
                this.dataService.isEdit = true;
                this.leftSidebarFormService.objToPatch = data;
                this.leftSidebarFormService.originalObj = cloneDeep(data);
                this.leftSidebarFormService.objToPatch["id"] = row.id;
                this.dataService.showTabs = true;
                this.leftSidebarFormService.fillForm();

                this.dataService.canRunBS.next(true);
                this.dataService.currentIdForRun = row.id;

                this.dataService.isCalculated = false;
                this.dataService.calculateFinished.next(false);

                SpinnerFunctions.hideSpinner();
                this.close();
              }

            }
          }
        })
      );
    }
  }

  changeProblemType(event): void {
    this.apiService.selectedProblemType = event.value;

    if (!this.isProblemSelected) {
      this.isProblemSelected = true;
    }
    else {
      SpinnerFunctions.showSpinner();

      this.apiService.getAllCustomLiteratureHelper().subscribe({
        next: data => {
          this.dataService.setStorage(data);

          SpinnerFunctions.hideSpinner();
        },
        error: err => {
          SpinnerFunctions.hideSpinner();
        }
      });
    }

  }

  changeMapType(event): void {
    let selectedValue = event.value;
    if(selectedValue === "all") {
      this.dataService.filterStorageBy([])
    }
    else {
      this.dataService.filterStorageBy([{
        column: "typeOfMap",
        value: selectedValue
      }]);
    }
  }

  close(): void {
    this.matDialog.closeAll();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
