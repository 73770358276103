<div mat-dialog-title>
    <div class="row">
        <div class="col-md-12 formHeading">
            <h3 class="modalHeading">
                {{"Notifications" | translate}}
            </h3>
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <app-header-dashboard-notification></app-header-dashboard-notification>
    <app-table-dashboard-notification></app-table-dashboard-notification>
</div>