<form class="custom-form" [formGroup]="this.formService.form">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="searchLabel">{{"Search" | translate}}</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 search-box">
              <mat-form-field class="addressField requiredField" color="primary" appearance='outline'>
                <mat-label>{{'Address' | translate}}</mat-label>
                <textarea rows="4" matInput type="textarea"
                [formControlName]="formService.form.keys.address"></textarea>
                <mat-error class="customError">
                  @if (formService.form.get('address').hasError('required')) {
                    {{"ThisFieldIsRequired" | translate}}
                  }
                  @if (formService.form.get('address').hasError('minlength')) {
                    {{"MinimumNumberOfCharacters" | translate}}&nbsp; 3
                  }
                </mat-error>
              </mat-form-field>
              <div class="search-button">
                <button (click)="search()" mat-flat-button color="primary" class="custom-flat-btn"
                  [disabled]="formService.form.invalid">{{"Search" | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 geo-location-table tablet-padding-right">
          @if (showAllItemsTable) {
            <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService">
            </app-table>
          }
        </div>
      </div>

    </div>
    <div class="col-sm-12 col-md-6 forward-info">
      @if (renderMap) {
        <div class="row forwardMapBlock">
          <div class="col-sm-12" style="padding-right: 0px;">
            <app-leaflet-map [clickEvent]="false" [zoomIn]="zoomIn" [markers]="markers"
              [invalidateSize]="mainDataService.triggerInvalidateSizeOnMap">
            </app-leaflet-map>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-sm-12">
          <app-geo-location-table [selectedItem]="selectedItem" [columnsForItem]="columnsForItem">
          </app-geo-location-table>
        </div>
      </div>
    </div>
  </div>
</form>
