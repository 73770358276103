import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PROBLEM_TYPES_FILTER } from '../../../../../../config/settings/cms/problem-types-filter';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IPfspDropdownData, IPfspFilterForm } from '../../../../interfaces/i-pfsp';
import { PfspService } from '../../../../services/api/pfsp.service';
import { BlPfspFormService } from '../../../../services/form/bl-pfsp-form.service';
import { BlPfspRequestsService } from '../../../../services/requests/bl-pfsp-requests.service';
import { BlPfspDataService } from '../../../../services/shared/bl-pfsp-data.service';
import { BlPfspTableService } from '../../../../services/table/bl-pfsp-table.service';
import { PfspHelperFunctionsService } from '../../../../services/helper/pfsp-helper-functions.service';

@Component({
  selector: 'app-view-dialog-dashboard-pfsp',
  templateUrl: './view-dialog-dashboard-pfsp.component.html',
  styleUrls: ['./view-dialog-dashboard-pfsp.component.scss']
})
export class ViewDialogDashboardPfspComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: PfspService,
    public tableService: BlPfspTableService,
    public dataService: BlPfspDataService,
    private requestService: BlPfspRequestsService,
    public formService: BlPfspFormService,
    protected matDialog: MatDialog,
    private ref : MatDialogRef<ViewDialogDashboardPfspComponent>,
    private pfspHelperFunctionsService: PfspHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  public clickedButton: boolean = false;
  public isOptionSelected: boolean = false;
  public isLiteratureProblem: boolean = false;
  public form: FormGroupTypeSafe<IPfspFilterForm> = this.formService.getFilterFormReference();

  public dropdownData: IPfspDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    TargetFunctions: null,
    Methods: null,
    Pfsps: null
  }

  ngOnInit(): void {
    this.dropdownData.ProblemTypes = PROBLEM_TYPES_FILTER;
    this.form.getSafe(x => x.problemTypes).valueChanges.subscribe({
      next: data => {
        if (data != "" || data != null) {
          this.fillTable(data);
          this.isOptionSelected = true;
          if(data == "l") {
            this.isLiteratureProblem = true;
          } else if(data == "c"){
            this.isLiteratureProblem = false;
          }
        } else {
          this.isOptionSelected = false;
        }

      }
    });

    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        options: [
          {
            title: 'View',
            class: 'mat-flat-button ',
            method: (row) => {
              this.dataService.setIdForPatch(row['id'] ? row['id'] : 777);
              this.formService.isRunProblemForTitle = false;
              this.formService.problemTitle = "";
              this.formService.resetAlgorithms();
              // this.resetView();
              this.showDataFromService(row);
            },
          },
        ],
      })
    );

    this.tableService.setDefaultButtons(
      cloneDeep([
        {
          title: 'Close',
          class: 'mat-flat-button ',
          method: () => {
            this.close(false);
          },
        },
      ])
    );
  }

  fillTable(value: any) {
    let type: any = value;
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType(type).subscribe({
        next: success => {
          SpinnerFunctions.hideSpinner();
          this.dataService.setStorage(success);
        }, 
        error: error => {
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }

  refreshPage() {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestService.getAllByType("l").subscribe({
        next: data => {
          this.dataService.setStorage(data);
          SpinnerFunctions.hideSpinner();
        }
      })
    );
    this.formService.formFilter.getSafe(x => x.problemTypes).setValue("l", { emitEvent: false });
  }

  // resetView() {
  //   setTimeout(() => {
  //     this.dataService.setView(false);
  //   }, 1000);
  // }

  showDataFromService(row: any) {
    this.clickedButton = true;
    this.dataService.storageIsTabsView.next(true);
    this.dataService.storageDataIsShowOtherTabs.next(true);
    this.dataService.storageIsRunResults.next(false);
    this.dataService.storageDataIsRightBlockView.next(false);
    SpinnerFunctions.showSpinner();
    if (this.clickedButton) {
      this.subscription.add(
        this.requestService.getPFSP(row.id).subscribe({
          next: (data) => {
            if (data) {
              this.pfspHelperFunctionsService.breakApartStavke(data);
              this.clickedButton = false;
              this.dataService.originalObject.next(data);
              this.formService.problemTitle = data["title"];
            }
            SpinnerFunctions.hideSpinner();
            this.close(true);
          }
        })
      );
    }
  }

  close(state : boolean) {
    this.ref.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataService.setStorage([]);
    this.formService.resetFilter();
    this.isLiteratureProblem = false;
    this.isOptionSelected = false;
  }

}
