import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToList'
})
export class StringToListPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value)
      return "";
    let array = value.split(",");
    let html = "<ul>";
    for (let element of array) {
      html += `<li>${element}</li>`
    }
    html += "</ul>";
    return html;
  }

}
