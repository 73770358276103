import { Injectable } from '@angular/core';
import { BlTableLogicService } from '../../../../shared/bussiness-logic/base/bl-table-logic.service';
import { IMrpSpecification } from '../../interfaces/i-mrp-specification';
import { TranslateService } from '@ngx-translate/core';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Location } from '@angular/common';
import { ITableColumn } from '../../../../shared/interfaces/i-table-column';
import { BehaviorSubject } from 'rxjs';
import { ISetOfColumns } from '../../../../shared/interfaces/i-set-of-columns';

@Injectable({
  providedIn: 'root'
})
export class BlMrpSpecificationDialogTableService extends BlTableLogicService<IMrpSpecification>{

  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(translateService, tableFunctions, matDialog, location);
  }

  public idColumn = "";
  
  protected columns: ITableColumn[] = [
    {
      index: "IdProblem",
      title: "#",
      hide: false
    },
    {
      index: "Title",
      title: "Problem Title",
      hide: false
    },
    {
      index: "NumberOfItems",
      title: "Number Of Items",
      hide: false
    },
    {
      index: "UnitPlanningPeriod",
      title: "Time Unit",
      hide: false
    },
    {
      index: "NumberOfPlanningPeriods",
      title: "Number Of Planning Periods",
      hide: false
    },
    {
      index: "PeriodsPerYear",
      title: "Number Of Periods Per Year",
      hide: false
    },
    {
      index: "MaxBomDepth",
      title: "Max. Number Of Ancestors",
      hide: false
    }
  ];
  
  protected columnsStorage: BehaviorSubject<ISetOfColumns> = new BehaviorSubject<ISetOfColumns>({
    columns: this.columns,
    displayedColumns: this.displayedColumns
  });

  protected displayedColumns: string[] =  this.columns.map(x => x.index);
}
