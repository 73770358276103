<form class="light_blue commonSearch">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>{{'Search' | translate}}</mat-label>
      <input 
      matInput 
      class="custom-field"
      (keyup)="onSearch(filter.value)"
      #filter
      autocomplete="off">
      <span matSuffix><mat-icon >search</mat-icon></span>
    </mat-form-field>
</form>
     