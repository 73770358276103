<ng-container>
  <form
    [formGroup]="formService.form"
    class="custom-form order-form qap-form"
    style="height: 100%"
    >
    <div class="row">
      <div class="col-sm-6 input-mobile">
        <mat-form-field
          appearance="outline"
          [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''">
          <mat-label>{{ "Algorithm" | translate }}</mat-label>
          <mat-select
            (selectionChange)="changeAlgorithm($event.value)"
            [formControlName]="formService.form.keys.algorithm"
            disableOptionCentering
            panelClass="custom-mat-select-panel-class">
            @for (option of dropdownData.Algorithms; track option) {
              <mat-option
                [value]="option.id"
                >
                {{ option.title | translate }}
              </mat-option>
            }
          </mat-select>
          <mat-error class="customError">
            @if (formService.form.get('algorithm').hasError('required')) {
              {{ "ThisFieldIsRequired" | translate }}
            }
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-sm-6"></div> -->

      <div class="col-sm-6 input-mobile">
        @if (formService.form.get('algorithmParams')['controls']) {
          <ng-container
            formArrayName="algorithmParams"
            >
            @for (item of formService.parametri; track item; let i = $index) {
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ item | translate }}</mat-label>
                    <input
                    [formControl]="
                      formService.form
                        .get('algorithmParams')
                        ['controls'][i].get('value')
                    "
                      matInput
                      autocomplete="off"
                      type="number"
                      />
                    </mat-form-field>
                  </div>
                </div>
              }
            </ng-container>
          }
        </div>
        <!-- <div class="col-sm-6"></div> -->

        <div class="col-sm-12 flow-box">
          <span class="subtitle">{{ "Flows" | translate }}</span>
          <button
            type="button"
            mat-stroked-button
            class="custom-stroked-btn"
            color="primary"
            (click)="openEditDialog()"
            [disabled]="isProblemLiterature"
            >
            <mat-icon>drive_file_rename_outline </mat-icon
              >{{ "Edit" | translate }}
            </button>
          </div>
        </div>
        <!-- Left Table QAP -->
        <div class="row">
          <div class="col-md-12">
            <div class="qap-table-wrapper">
              <cdk-virtual-scroll-viewport
            [ngStyle]="{
              height:
                dataForTable.length < 15
                  ? (dataForTable.length + 2) * 30 + 'px'
                  : '480px'
            }"
                style="width: 100%; overflow-y: auto"
                minBufferPx="200"
                maxBufferPx="600"
                [itemSize]="30"
                >
                <div>
                  @if (
                    (dataForTable && dataForTable.length) > 0) {
                    <table class="table table-sm table-dynamic">
                      <thead class="thead-dark">
                        <tr>
                          <th>&nbsp;</th>
                          @for (header of tableService.headersTable2; track header) {
                            <th>
                              {{ header.title }}
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                      *cdkVirtualFor="
                        let tableRow of dataForTable;
                        let i = index
                      "
                          >
                          <tr>
                            <td>{{ i + 1 }}</td>
                            @for (columns of tableRow; track j; let j = $index) {
                              <td
                            [ngStyle]="
                              i == j
                                ? { background: '#bbb' }
                                : { background: '#fff' }
                            "
                                >
                                {{ columns }}
                              </td>
                            }
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  } @else {
                    <div class="alert alert-warning" role="alert">
                      {{ "NoRecordsFound" | translate }}
                    </div>
                  }
                </div>
              </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</form>
</ng-container>
