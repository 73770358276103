@if (formControlToFill) {
  <mat-form-field appearance="outline" [ngClass]="(formControlToFill.hasError('required')||formControlToFill.hasError('notselected')) ? 'requiredField' : false">
    <mat-label>{{ labelText | translate}}</mat-label>
    <input [formControl]='formControlToFill' matInput type="text" aria-label="Number" [matAutocomplete]="auto" autocomplete="off" (keyup)="setAutocompleteError()">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="checkAutocomplete">
      @for (option of _filteredOptions | async; track option) {
        <mat-option [value]="option" (click)="selectOption(option)">
          {{option.title}}
        </mat-option>
      }
    </mat-autocomplete>
    @if (formControlToFill.hasError('required')) {
      <mat-error>
        {{'ThisFieldIsRequired' | translate}}
      </mat-error>
    }
    @if (formControlToFill.hasError('notselected')) {
      <mat-error>
        {{'ThisFieldIsRequired' | translate}}.  {{"Select from dropdown" | translate}}.
      </mat-error>
    }
  </mat-form-field>
}