import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-geo-location',
  templateUrl: './dashboard-geo-location.component.html',
  styleUrls: ['./dashboard-geo-location.component.scss']
})
export class DashboardGeoLocationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
