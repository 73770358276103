import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlCellFormationProblemFormService } from '../../../../services/form/bl-cell-formation-problem-form.service';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlCellFormationProblemDataService } from '../../../../services/shared/bl-cell-formation-problem-data.service';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { ICellFormationProblemDropDownData, ICellFormationProblemForm } from '../../../../interfaces/i-cell-formation-problem';
import { TranslateService } from '@ngx-translate/core';
import { INPUT_METHOD } from '../../../../../../config/settings/inputMethod';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { CellFormatingHelperFunctionsService } from '../../../../services/helper/cell-formating-helper-functions.service';
import { FileType } from 'src/app/shared/enums/file-type';

@Component({
  selector: 'app-form-dashboard-cell-formation-problem',
  templateUrl: './form-dashboard-cell-formation-problem.component.html',
  styleUrls: ['./form-dashboard-cell-formation-problem.component.scss']
})
export class FormDashboardCellFormationProblemComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: BlCellFormationProblemFormService,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<FormDashboardCellFormationProblemComponent>,
    private alertService: AlertMessageDataService,
    private dataService: BlCellFormationProblemDataService,
    private translateService: TranslateService,
    private cellFormatingHelperFunctionsService: CellFormatingHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<ICellFormationProblemForm> = this.formService.getFormReference();

  public dropdownData: ICellFormationProblemDropDownData = {
    ProblemTypes: null,
    Methods: null
  }

  public fileData: FormData;
  public fileName: any = "";
  public countFiles: any = 0
  private fileNotChosen: string = this.translateService.get("File not chosen.")["value"];
   public allowedFileTypes: string[] = [FileType.csv]

  ngOnInit(): void {
    this.dropdownData.Methods = INPUT_METHOD;

    document.getElementById("file-name").innerHTML = this.fileNotChosen;

    this.translateService.onLangChange.subscribe({
      next: (data) => {
        if (data) {
          if (this.countFiles == 0) {
            document.getElementById("file-name").innerHTML = this.translateService.instant("File not chosen.");
          } else if (this.countFiles == 1) {
            document.getElementById("file-name").innerHTML = this.fileName;
          }
        }
      }
    });

    this.form.getSafe(x => x.inputMethod).valueChanges.subscribe({
      next: (val) => {
        if (val == 1) {
          this.formService.isImportMethod = true;
          this.formService.form.get("m").setValue(3);
          this.formService.form.get("p").setValue(3);
          this.formService.form.get("len").setValue(2);
          this.formService.form.get("description").setValue("");
          this.translateService.get("File not chosen.").subscribe({
            next: (data) => {
              if (data) {
                document.getElementById("file-name").innerHTML = data;
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          })
        } else if (val == 0) {
          this.formService.isImportMethod = false;
          this.translateService.get("File not chosen.").subscribe({
            next: (data) => {
              if (data) {
                document.getElementById("file-name").innerHTML = data;
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          })
        }
      }
    })
  }

  onFileChanged(e) {
    let file = e.target.files;
    if (file && file.length > 0) {
      if (file && file[0].size > 2100000) {
        this.alertService.setMessage("danger", "File too big (exceeds 2MB in size)");
        this.translateService.get("File not chosen.").subscribe({
          next: (data) => {
            if (data) {
              document.getElementById("file-name").innerHTML = data;
              this.fileData = null;
              this.countFiles = 0;
            }
          }
        })
        return;
      }

      if (file ) {
        if (file && file.length == 1) {
          this.countFiles = 1;
          this.fileName = file[0].name;
          document.getElementById("file-name").innerHTML = this.fileName;
        } else if (file && file.length == 0) {

          this.translateService.get("File not chosen.").subscribe({
            next: (data) => {
              if (data) {
                document.getElementById("file-name").innerHTML = data;
                this.fileData = null;
                this.countFiles = 0;
              }
            }
          })
        }
        this.fileData = file;
      } else {
        this.alertService.setMessage("danger", "File must be in CSV format extension.");
        this.translateService.get("File not chosen.").subscribe({
          next: (data) => {
            if (data) {
              document.getElementById("file-name").innerHTML = data;
              this.fileData = null;
              this.countFiles = 0;
            }
          }
        })
      }
    } else {
      this.translateService.get("File not chosen.").subscribe({
        next: (data) => {
          if (data) {
            document.getElementById("file-name").innerHTML = data;
            this.fileData = null;
            this.countFiles = 0;
          }
        }
      })
    }

  }

  close(state?: any) {
    this.matDialogRef.close(state);
  }

  save() {
    if (this.formService.isImportMethod == false) {
      let formValue: any = this.formService.form.value;

      this.subscription.add(
        this.dataService.originalObject.next(formValue)
      );

      this.subscription.add(
        this.dataService.storageIsTabsView.next(true)
      );

      this.subscription.add(
        this.dataService.storageDataIsShowOtherTabs.next(false)
      );

      this.subscription.add(
        this.dataService.storageDataResults.next(null)
      );

      this.subscription.add(
        this.dataService.storageIsRunBrusco.next(false)
      );

      this.subscription.add(
        this.dataService.storageIsRunResults.next(false)
      );


      this.formService.isRunProblemForTitle = false;
      this.formService.problemTitle = "";
      this.dataService.setIdForPatch(-1);
      this.alertService.setMessage("success", "Successfully applied.");
      this.close(this.form.getSafe(x => x.description).value);
    } else {
      if (this.fileData) {
        SpinnerFunctions.showSpinner();
        this.formService.uploadCSV(this.fileData).subscribe({
          next: success => {
            this.cellFormatingHelperFunctionsService.breakApartStavke(success);
            this.dataService.storageIsTabsView.next(true);
            this.dataService.storageDataIsShowOtherTabs.next(true);
            this.dataService.originalObject.next(success);
            this.dataService.storageDataResults.next(null);
            this.dataService.storageIsRunBrusco.next(false);
            this.dataService.storageIsRunResults.next(false);

            this.dataService.idForPatch = success["id"];
            this.formService.problemTitle = success["name"];

            this.alertService.setMessage("success", "Successfully uploaded CSV.");
            this.translateService.get("File not chosen.").subscribe({
              next: (data) => {
                if (data) {
                  document.getElementById("file-name").innerHTML = data;
                }
              }
            });
            this.countFiles = 0;
            this.close(success["description"]);
            SpinnerFunctions.hideSpinner();
          },
          error: error => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
          }
        });
      } else {
        this.alertService.setMessage("danger", "You must select a CSV file and then upload it.")
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.form.get("m").setValue(3);
    this.formService.form.get("p").setValue(3);
    this.formService.form.get("len").setValue(2);
    this.formService.form.get("description").setValue("");
    this.formService.form.get("inputMethod").setValue(0);
  }

}
