import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'dateColumn'
})
export class DateColumnPipe implements PipeTransform {

  transform(column: ITableColumn): boolean {
    return column.hasOwnProperty('dateColumn') && column.dateColumn != null && !column.isFormControl;
  }

}
