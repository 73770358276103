<div class="row">
  <div class="col-md-12 section-content"
    [ngClass]="isBackgroundImage ? 'header-page' : 'tabs-page'">
    <app-header-dashboard-deterministic-mrp>
    </app-header-dashboard-deterministic-mrp>
    @if (formService.isShowMainTabs) {
      <app-tabs-dashboard-deterministic-mrp>
      </app-tabs-dashboard-deterministic-mrp>
    }
  </div>
</div>
