import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, take, catchError } from 'rxjs';
import { config } from '../../config/global';
import { NotFoundError } from '../handlers/not-found-error';
import { BadInputError } from '../handlers/bad-input-error';
import { AppError } from '../handlers/app-error';
import { SpinnerFunctions } from '../functions/spinner-functions';
import { ApiGetAllResponse } from '../interfaces/api-get-all--response';
import { IOption } from '../interfaces/i-option';
import { LocalStorageKeys } from '../consts/local-storage-keys';
import { AlertMessage } from '../interfaces/alert-message';
import { map } from 'rxjs/operators';


@Injectable()
export class ApiService<T> {

  protected baseUrl = config.baseApiUrl[config.env];
  public isServer = false;
  constructor(@Inject('apiUrl') public url : string, protected http : HttpClient) {
    if(this.url.indexOf('.json')==-1){
      this.isServer = true;
      this.baseUrl = config.baseApiUrl.SERVER;
    }
    if(this.url.indexOf('http')!=-1){
      this.baseUrl = "";
    }
  }

  checkIsServer() {
    if(this.url.indexOf('.json')==-1){
      this.isServer = true;
      this.baseUrl = config.baseApiUrl.SERVER;
    } else {
      this.isServer = false;
      this.baseUrl = config.baseApiUrl.DEV;
    }
  }
  getAll() : Observable<T[]>{
    return this.http.get<T[]>(`${ this.baseUrl + this.url}`).pipe(
      map((items :any)=> {
        return items.hasOwnProperty('data') ? items['data'] : items;
      }),
      take(6),
      catchError(this.handleErrors)
    );
  }

  getWithTotalCount() : Observable<ApiGetAllResponse<T>> {
    return this.http.get<ApiGetAllResponse<T>>(`${ this.baseUrl + this.url}`).pipe(
      catchError(this.handleErrors)
    );
  }

  getWithAllData() : Observable<T[]> {
    return this.http.get<T[]>(`${ this.baseUrl + this.url}`).pipe(
      catchError(this.handleErrors)
    );
  }

  get(id) : Observable<T>{
    return this.isServer ? this.http.get<T>(`${ this.baseUrl + this.url + "/"+ id }`).pipe(
      map((items :any)=> {
        return items.hasOwnProperty('data') ? items['data'] : items;
      })
    ) :
    this.http.get<T>(`${ this.baseUrl + this.url }`)
      .pipe(
        map((items : any) => {
          let itemsArray = items.hasOwnProperty('data') ? items['data'] : items
          return itemsArray.filter(item => item.id == id)[0];
        }),
        catchError(this.handleErrors)
      );
  }

  getWithData(id) : Observable<T>{
    return this.isServer ? this.http.get<T>(`${ this.baseUrl + this.url + "/"+ id }`) :
    this.http.get<T>(`${ this.baseUrl + this.url }`)
      .pipe(
        map((items : any) => {
          return items.filter(item => item.id == id)[0];
        }),
        catchError(this.handleErrors)
      );
  }

  getWithParams(params: any){
    return this.http.get<T>(`${ this.baseUrl + this.url }`, { params: params } )
    .pipe(
      map((items :any)=> {
        return items.hasOwnProperty('data') ? items['data'] : items;
      }),
      catchError(this.handleErrors)
    );
  }

  getWithBodyParams(params: any, includeData = true){
    let obj = { data: params };
    if(!includeData){
      obj = params;
    }
    return this.http.post<T>(`${ this.baseUrl + this.url }`, obj )
    .pipe(
      map((items :any)=> {
        return items.hasOwnProperty('data') ? items['data'] : items;
      }),
      catchError(this.handleErrors)
    );
  }

  getWithBodyAndUrlParams(pathParams: any, queryParams, bodyParams: any, justPathPars = false){
    let urlQueryParams = queryParams? Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&') : '';
    let urlPathParams = Object.keys(pathParams).map(key => pathParams[key]).join('/');
    let urlPath = `${ this.baseUrl + this.url + "/" + urlPathParams }`;
    
    if(urlQueryParams){
      urlPath += "?" + urlQueryParams;
    }
    
    let request = this.http.get<T>(urlPath);
    if((!justPathPars) && (bodyParams && bodyParams!==null && !(Object.entries(bodyParams).length === 0 && bodyParams.constructor === Object))){
      return this.http.post<T>(urlPath, { data: bodyParams } );
    } else if(justPathPars) {
      return this.http.post<T>(urlPath, null);
    }
    return request.pipe(
      map((items :any)=> {
        return items.hasOwnProperty('data') ? items['data'] : items;
      }),
      catchError(this.handleErrors)
    );
  }

  getWithParamsAllData(params: any){
    return this.http.get<any[]>(`${ this.baseUrl + this.url }`, { params: params } )
    .pipe(
      catchError(this.handleErrors)
    );
  }

  getBasicData() : Observable<IOption[]>{
    return (this.isServer)? this.getWithParams({"Include": ['id','title']}) : this.getAll().pipe(
      map(data => {
        return data.map(
          (x : any) => {
            return {
              id: x.id,
              title: x.title
            }
          }
        )
      })
    ); // "DEV env"
  }

  updateWithoutParams(obj:any){
    let objToSend = obj;
    if(!obj.hasOwnProperty('data')){
      objToSend = { data: obj }
    }
    return this.http.patch(`${ this.baseUrl + this.url}`,objToSend).pipe(
      catchError(this.handleErrors)
    );
  }
  // updateWithoutParamsRandom(obj:any){
  //   let objToSend = obj;
  //   if(!obj.hasOwnProperty('data')){
  //     objToSend = { data: obj }
  //   }
  //   let random = "?number="+Math.random();
  //   return this.http.patch(`${ this.baseUrl + this.url + random}`,objToSend).pipe(
  //     catchError(this.handleErrors)
  //   );
  // }

  update(id : any, obj : any, includeData = true){ // UpdatedItem
    // obj['roomClass'].id = id;
    let objToSend = obj;
    if(!obj.hasOwnProperty('data') && includeData){
      objToSend = { data: obj }
    }
    let url = this.baseUrl + this.url;
    if(id){
      url += '/' + id;
    }
    return this.http.patch(url, objToSend).pipe(
      catchError(this.handleErrors)
    );
  }

  updateWithMultipleParams(id : any[]){ // UpdatedItem
    // obj['roomClass'].id = id;
    let url = this.baseUrl + this.url;
    if(id){
      url += '/' + id.join("/");
    }
    return this.http.patch(url, {}).pipe(
      catchError(this.handleErrors)
    );
  }

  updateAll(obj: any[]){
    return this.http.patch(`${ this.baseUrl + this.url }`, { data: obj }).pipe(
      catchError(this.handleErrors)
    );
  }

  delete(id : number){
    return this.http.delete(`${ this.baseUrl + this.url + "/" + id}`).pipe(
      catchError(this.handleErrors)
    );
  }

  deleteWithBody(id:number, param:any){
    return this.http.request('delete', `${ this.baseUrl + this.url + "/" + id }`,{body:param}).pipe(
      catchError(this.handleErrors)
    )
  }

  deleteAll(ids : any){
    //{ params: new HttpParams({fromObject: { ids: ids}})}
    return this.http.request('delete', `${ this.baseUrl + this.url}`, {body: ids }).pipe(
      catchError(this.handleErrors)
    );
  }

  create(obj : any, noArray = false, noData = false){
    let objToSend = obj;
    if(!obj.hasOwnProperty('data') && !noData){
      objToSend = { data: (Array.isArray(obj))? obj : [obj] };
      if(noArray){
        objToSend = { data: obj};
      }
    }
    return this.http.post(`${ this.baseUrl + this.url }`, objToSend).pipe(
      catchError(this.handleErrors)
    );
  }

  handleErrors(error){
    SpinnerFunctions.hideSpinner();
    
    let response;
    let exception = error.error;
    let message = exception.hasOwnProperty('message') && exception.message ? exception.message+ " " : '';
    // message += exception.hasOwnProperty('details') && exception.details ? exception.details + " - " : '';
    message += exception.hasOwnProperty('exceptionMessage') && exception.exceptionMessage+" " ? exception.exceptionMessage : '';
    
    if(localStorage.getItem(LocalStorageKeys.token)!=null){
      if(message==''){
        ServiceErrors.setMessage("Error: " + error.statusText);
      } else {
        ServiceErrors.setMessage("Error: " + message);
      }
    }
    
    switch(error.status){
      case 404:
        response = new NotFoundError(error.error);
        break;
      case 401:
        response = new BadInputError();
        break;
      default:
        response = new AppError();
    }
    return throwError(response);
  }
}

export class ServiceErrors {
  public static storage = new BehaviorSubject<AlertMessage[]>(null);


  static setMessage(message: string){
    let data = this.storage.getValue()==null? [] : this.storage.getValue();
    data = data.concat({ type: 'danger', content: message })
    this.storage.next(data);
    this.removeAllMessages();
  }

  static removeAllMessages(){
    setTimeout(() => {
      this.storage.next([]);
      }, config.alertMessagesPresentTime
    );
  }
}
