import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { BaseComponent } from '../../../../../../../../shared/components/base/base.component';
import { TableFunctions } from '../../../../../../../../shared/functions/table-functions';
import { BlThreeDRecordsDataService } from '../../../../../../services/shared/bl-three-d-records-data.service';
import { BlThreeDRecordsTableService } from '../../../../../../services/table/bl-three-d-records-table.service';
import { PackingDialogBackpackProblemComponent } from '../../../packing-dialog-backpack-problem/packing-dialog-backpack-problem.component';

@Component({
  selector: 'app-three-d-table-backpack-problem',
  templateUrl: './three-d-table-backpack-problem.component.html',
  styleUrls: ['./three-d-table-backpack-problem.component.scss']
})
export class ThreeDTableBackpackProblemComponent extends BaseComponent implements OnInit, AfterViewInit {

  constructor(
    public threeDRecordsTableService: BlThreeDRecordsTableService,
    public threeDRecordsDataService: BlThreeDRecordsDataService,
    protected location: Location,
    protected matDialog: MatDialog,
    protected tableFunctions: TableFunctions,
    protected cdr: ChangeDetectorRef
  ) {
    super(tableFunctions, matDialog, location);
  }

  showTable: boolean = false;

  private dialog = {
    component: PackingDialogBackpackProblemComponent,
    dimensions: {
      width: "80%",
      height: "auto",
      minHeight: "130px",
      panelClass: "custom-dialog"
    }
  };

  @ViewChild('commonTable') commonTable;

  ngOnInit(): void {
    this.threeDRecordsTableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        class: 'flex-76',
        options: [
          {
            title: 'View',
            method: (row) => {
              this.openModal(row)
            },
          },
        ],
      })
    );
  }

  ngAfterViewInit(): void {
    this.commonTable.paginator.pageSize = 10;
    this.commonTable.fillDataServiceStorage();
    this.cdr.detectChanges();
  }

  openModal(event): void {
    this.openDialog(this.dialog.component, event, this.dialog.dimensions);
  }

  close(): void {
    this.location.back();
  }

}
