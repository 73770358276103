import { Injectable } from '@angular/core';
import { INotification } from '../../interfaces/i-notification';
import { NotificationService } from '../api/notification.service';
import { BlNotificationRequestsService } from '../requests/bl-notification-requests.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';

@Injectable({
  providedIn: 'root'
})
export class BlNotificationDataService extends BlDataLogicService implements ITableStorage<INotification> {

  constructor(
    private apiService: NotificationService,
    private requestService: BlNotificationRequestsService
  ) {
    super();
  }

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)
  
  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<INotification[]> = new BehaviorSubject<INotification[]>([]);

  originalData: INotification[];

  getOriginalData(): INotification[] {
    return this.originalData;
  }

  setOriginalData(items: INotification[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<INotification[]> {
    return this.storage.asObservable();
  }

  setStorage(items: INotification[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): INotification[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: INotification[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }
}
