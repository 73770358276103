import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BlQapFormService } from '../../../../services/forms/bl-qap-form.service';
import { BlQapDataService } from '../../../../services/shared/bl-qap-data.service';

@Component({
  selector: 'app-tabs-dashboard-qap',
  templateUrl: './tabs-dashboard-qap.component.html',
  styleUrls: ['./tabs-dashboard-qap.component.scss']
})
export class TabsDashboardQapComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dataService: BlQapDataService,
    public formService: BlQapFormService,
    private cdr: ChangeDetectorRef
  ) { }

  public canChangeTab: boolean = true;
  public selectedTab: number = 0;
  public isResultsRun: boolean = false;

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: (data) => {
        this.formService.isShowOtherTabs = data;
      }
    })

    this.dataService.storageIsRunResults.subscribe({
      next: (isRun) => {
      this.isResultsRun = isRun;
      if (isRun)
        this.selectedTab = 6;
      else {
          if(this.selectedTab == 6) {
            this.selectedTab = 0;
          }
        }
      }
    })

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab == 0) {

    }
  }

  ngOnDestroy(): void {
    this.isResultsRun = false;
  }

}
