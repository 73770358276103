import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { LeafletMapComponent } from '../../../../../../shared/components/leaflet-map/leaflet-map.component';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-map-by-iterations-tab-dashboard-distribution',
  templateUrl: './map-by-iterations-tab-dashboard-distribution.component.html',
  styleUrls: ['./map-by-iterations-tab-dashboard-distribution.component.scss']
})
export class MapByIterationsTabDashboardDistributionComponent implements OnInit, AfterViewInit {

  constructor(
    public dataService: BlDistribution1ToNDataService,
    private cdRef: ChangeDetectorRef
  ) {}

  routes = [];
  lastGroupId: number = 0;
  allRoutes = [];
  private subscription: Subscription = new Subscription();
  @ViewChild('leafletMap') leafletMap: LeafletMapComponent;
  difference = 0;

  zoomIn = {
    latitude: 44,
    longitude: 22
  }

  ngOnInit(): void {
    this.subscription.add(this.dataService.changeMapByIterations.subscribe({
      next: x => {
        this.allRoutes = cloneDeep(this.dataService.mapByIterations.routes);
        this.routes = [];
        this.zoomIn = {
          latitude: this.dataService.mapByIterations.routes[0].coordinates[0][0],
          longitude: this.dataService.mapByIterations.routes[0].coordinates[0][1]
        }
        this.difference = this.allRoutes[0].groupId - 1;
      }
    }));
  }

  ngAfterViewInit(): void {
    this.leafletMap.polylines = this.leafletMap.allPolylines = [];
    this.cdRef.detectChanges();
  }

  addNext(): void {
      this.lastGroupId++;
      const selectedRoutes = this.dataService.mapByIterationsHash[this.lastGroupId + this.difference];
      if(selectedRoutes && selectedRoutes.length) {
        selectedRoutes.forEach(route => {
          this.leafletMap.addPolyline(route);
          this.routes.push(route);
        });
      }
  }

  removeLast(): void {
    let indexOfFirst = this.routes.findIndex(x => x.groupId == this.lastGroupId + this.difference) ;
    this.leafletMap.removePolyline(this.lastGroupId + this.difference);
    this.routes.splice(indexOfFirst);
    this.lastGroupId--;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
