
<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<form class="custom-form" [formGroup]="formService.form" (ngSubmit)="save()">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{"Add" | translate}}&nbsp; {{"CFP" | translate}}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 input-methods">
        <label class="sub-title">{{"Input Methods" | translate}}:</label>
        <mat-radio-group [formControlName]="form.keys.inputMethod" color="primary">
          @for (data of dropdownData.Methods; track data) {
            <mat-radio-button [value]="data.id">{{data.title |
            translate}}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
    </div>
    <div class="row" [ngClass]="!formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-sm-4 input-mobile">
        <mat-form-field appearance="outline"
          [ngClass]="formService.form.get('m').errors ? 'requiredField' : ''">
          <mat-label>{{"M" | translate}}</mat-label>
          <input type="number" min=3 [formControlName]="form.keys.m" matInput autocomplete="off" />
          <mat-error class="customError">
            @if (formService.form.get('m').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
            @if (formService.form.get('m').hasError('min')) {
              {{"MinimalValueValidation" | translate}}&nbsp; 3.
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4 input-mobile">
        <mat-form-field appearance="outline"
          [ngClass]="formService.form.get('p').errors ? 'requiredField' : ''">
          <mat-label>{{"P" | translate}}</mat-label>
          <input type="number" min=3 [formControlName]="form.keys.p" matInput autocomplete="off" />
          <mat-error class="customError">
            @if (formService.form.get('p').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
            @if (formService.form.get('p').hasError('min')) {
              {{"MinimalValueValidation" | translate}}&nbsp; 3.
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4 input-mobile">
        <mat-form-field appearance="outline"
          [ngClass]="formService.form.get('len').errors ? 'requiredField' : ''">
          <mat-label>{{"Length" | translate}}</mat-label>
          <input type="number" min=2 [formControlName]="form.keys.len" matInput autocomplete="off" />
          <mat-error class="customError">
            @if (formService.form.get('len').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
            @if (formService.form.get('len').hasError('min')) {
              {{"MinimalValueValidation" | translate}}&nbsp; 2.
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" color="primary"
          [ngClass]="formService.form.get('description').errors ? 'requiredField' : ''">
          <mat-label>{{"Name" | translate}}</mat-label>
          <textarea [formControlName]="form.keys.description" matInput cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="7"></textarea>
          <mat-error class="customError">
            @if (form.get('description').hasError('required')) {
              {{"ThisFieldIsRequired" | translate}}
            }
            @if (form.get('description').hasError('noWhiteSpace')) {
              {{"NoWhiteSpace" | translate}}
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-md-12 inputfile-wrapper">
        <app-common-file-upload (onFileChanged)="onFileChanged($event)" [allowedFileTypes]="allowedFileTypes"></app-common-file-upload>
      </div>
    </div>
    <!-- <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
      <div class="col-md-12 inputfile-wrapper">
        <div class="inputfile-box">
          <input type="file" name="file" id="file" class="inputfile"
            (change)="onFileChanged($event)" #fileNameId />
            <label for="file">
              <span id="file-name" class="file-box"></span>
              <span class="file-button">
                <mat-icon>publish</mat-icon>
                <span>{{"Select File" | translate}}</span>
              </span>
            </label>
          </div>
        </div>
      </div> -->

    </div>
    <div mat-dialog-actions>

      <button mat-flat-button disableRipple color="primary" type="submit"
        [disabled]="(!this.form.get('m').valid || !this.form.get('p').valid || !this.form.get('len').valid || !this.form.get('description').valid) && formService.isImportMethod == false">{{'Apply' | translate}}
      </button>
      <button mat-flat-button disableRipple type="button" color="warn" (click)="close()">
        {{'Close' | translate}}
      </button>

    </div>
  </form>
