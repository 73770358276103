<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<form [formGroup]="form" class="reducedForm" (ngSubmit)="save()">
    <div mat-dialog-content>

        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{ "RouteSettings" | translate }}
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <ng-container *ngFor="let item of settings; let i = index">
                        <div class="col-sm-4 col-lg-3 input-box">
                            <mat-form-field appearance="outline">
                                <mat-label>{{item.title | translate}}</mat-label>
                                <input type="number" [formControlName]="item.controlName" matInput autocomplete="off" />
                            </mat-form-field>
                            <mat-checkbox [checked]="dataService.settingsObj[item.controlName] != null"
                            (change)="checkboxChange($event, i)"></mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <hr />
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <div class="row full-width">
            <app-button-with-spinner class="full-width" [isClickedSave]="isClickedSave" [form]="form">
            </app-button-with-spinner>
        </div>
    </div>
</form>