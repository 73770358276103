import { Component, OnInit } from '@angular/core';
import { SpinnerFunctions } from '../../../../shared/functions/spinner-functions';
import { routingProblems } from '../../../routing-common/constants/routing-problems';
import { BlDistribution1ToNRequestsService } from '../../services/requests/bl-distribution-1-to-n-requests.service';
import { BlDistribution1ToNDataService } from '../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-dashboard-distribution',
  templateUrl: './dashboard-distribution.component.html',
  styleUrls: ['./dashboard-distribution.component.scss']
})
export class DashboardDistributionComponent implements OnInit {

  constructor(
    public dataService: BlDistribution1ToNDataService,
    private requestsService: BlDistribution1ToNRequestsService
  ) {}

  ngOnInit(): void {
    this.dataService.resetData();
    this.dataService.isCalculated = false;
    this.dataService.showTabs = false;

    let currentPage = window.location.pathname;
    let myParam = currentPage.split("/")[2];

    this.dataService.problemType = routingProblems.find(x => x.shortcut === myParam);

    SpinnerFunctions.showSpinner();
    this.requestsService.getAlgorithmProblems().subscribe({
      next: data => {
        this.dataService.problemAlgorithms = data;
        SpinnerFunctions.hideSpinner();
      },
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    })
  }
}
