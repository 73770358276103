<style>
  @media print {
  html,
  body {
  height: 99%;
}

#reportButton,
#title {
display: none;
}
}
</style>

<div id="report"></div>

<div id="title" mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<mat-dialog-content id="report-modal-dialog">

  <div class="row">
    <div class="col-sm-12 formHeading">
      <h3 class="modalHeading">
        {{ "Results" | translate }}
      </h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 header-button-box">
      <button (click)="printReport()" mat-stroked-button color="accent">
        <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
      </button>
    </div>
  </div>

  <div class="row" id="report-modal-content">
    <div class="col-sm-12 tab-wrapper">
      @if (dataService.reports.length > 0) {
        @for (data of dataService.reports; track data; let i = $index) {
          <div class="row">
            <div class="col-sm-12 table-header">
              <p class="table-title">{{data.nazivTabele}}</p>
              <p class="table-title">{{data.opis}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 table-wrapper">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th>&nbsp;</th>
                    @for (header of data.table.tableHeader; track header) {
                      <th>{{header}}</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (tableRow of data.table.tableBody; track tableRow) {
                    <tr>
                      @for (columns of tableRow.stavke; track columns) {
                        <td
                          [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                          {{columns.value}}
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
      } @else {
        <div class="row">
          <div class="col-sm-12">
            <div class="alert alert-warning" role="alert">
              {{"NoRecordsFound" | translate}}
            </div>
          </div>
        </div>
      }
    </div>
  </div>

</mat-dialog-content>
