<div class="row">
  <div class="col-md-12 tab-wrapper">
    @if (dataFromService.mrpReport.length > 0) {
      @for (data of dataFromService.mrpReport; track data; let i = $index) {
        <div class="row">
          <div class="col-sm-12 table-header">
            <p class="table-title" [innerHTML]="data.nazivTabele"></p>
            <p class="table-title" [innerHTML]="data.opis"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 table-wrapper">
            <table class="table table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>&nbsp;</th>
                  @for (header of data.table.tableHeader; track header) {
                    <th>{{header}}</th>
                  }
                </tr>
              </thead>
              <tbody>
                @for (tableRow of data.table.tableBody; track tableRow) {
                  <tr>
                    @for (columns of tableRow.stavke; track columns) {
                      <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                        {{columns.value}}
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    } @else {
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-warning" role="alert">
            {{"NoRecordsFound" | translate}}
          </div>
        </div>
      </div>
    }
  </div>
</div>
