import { Component, EventEmitter, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FormGroupTypeSafe } from '../../../../../../../../shared/helpers/reactive-forms-helper';
import { IDistribution1ToNLeftSidebarForm } from '../../../../../../interfaces/i-distribution-1-to-n';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-left-sidebar-dashboard-distribution-additional-fields',
  templateUrl: './left-sidebar-dashboard-distribution-additional-fields.component.html',
  styleUrls: ['./left-sidebar-dashboard-distribution-additional-fields.component.scss']
})
export class LeftSidebarDashboardDistributionAdditionalFieldsComponent implements OnInit {

  constructor(
    public formService: BlLeftSidebarDistribution1ToNFormService,
    public dataService: BlDistribution1ToNDataService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNLeftSidebarForm> = this.formService.getFormReference();

  ngOnInit(): void {}

  changeRoutes(event: any, color: string, id: number): void {

    if(!event.checked) {
      this.dataService.coordinatesForMainMap.routes = this.dataService.coordinatesForMainMap.routes.filter(x => x.id != id);
    }
    else {
      const route = cloneDeep(this.dataService.allCoordinatesForMainMap.routes.find(x => x.id == id));

      this.dataService.coordinatesForMainMap.routes.push(route);
    }

    this.dataService.mainMapFitBounds = false;
    this.dataService.coordinatesForMainMap.routes = cloneDeep(this.dataService.coordinatesForMainMap.routes);
  }

}
