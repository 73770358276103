<ng-container>
  <form [formGroup]="formService.form" class="reducedForm" style="height: 100%">
    <div class="content-wrapper">
      <div class="upper">
        <div class="row mt-15">
          <div class="col-sm-6 input-mobile">
            <mat-form-field
              appearance="outline"
              [ngClass]="
                formService.form.get('targetFunction').errors
                  ? 'requiredField'
                  : ''
              "
            >
              <mat-label>{{ "Objective" | translate }}</mat-label>
              <mat-select
                [formControlName]="formService.form.keys.targetFunction"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let option of dropdownData.TargetFunctions"
                  [value]="option.id"
                >
                  {{ option.title | translate }}
                </mat-option>
              </mat-select>
              <mat-error class="customError">
                <ng-container
                  *ngIf="
                    formService.form.get('targetFunction').hasError('required')
                  "
                >
                  {{ "ThisFieldIsRequired" | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 input-mobile">
            <mat-form-field
              appearance="outline"
              [ngClass]="
                formService.form.get('algorithm').errors ? 'requiredField' : ''
              "
            >
              <mat-label>{{ "Algorithm" | translate }}</mat-label>
              <mat-select
                (selectionChange)="changeAlgorithm($event.value)"
                [formControlName]="formService.form.keys.algorithm"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let option of dropdownData.Algorithms"
                  [value]="option.id"
                >
                  {{ option.title | translate }}
                </mat-option>
              </mat-select>
              <mat-error class="customError">
                <ng-container
                  *ngIf="formService.form.get('algorithm').hasError('required')"
                >
                  {{ "ThisFieldIsRequired" | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <ng-container
              *ngIf="form.get('algorithmParams')['controls']"
              formArrayName="algorithmParams"
            >
              <ng-container
                *ngFor="let item of formService.parametri; let i = index"
              >
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ item | translate }}</mat-label>
                      <input
                        [formControl]="
                          form
                            .get('algorithmParams')
                            ['controls'][i].get('value')
                        "
                        matInput
                        autocomplete="off"
                        type="number"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <!-- Left Table PFSP -->
        <div class="row custom-margin-top-left-part">
          <div class="col-md-12">
            <cdk-virtual-scroll-viewport
              [ngStyle]="{
                height:
                  formService.numberRows < 15
                    ? (formService.numberRows + 2) * 30 + 'px'
                    : '465px'
              }"
              style="width: 100%; overflow-y: auto"
              minBufferPx="200"
              maxBufferPx="600"
              [itemSize]="30"
            >
              <div>
                <ng-container
                  *ngIf="
                    formService.form.get('rows')['controls']?.length > 0;
                    else noAllTableData
                  "
                >
                  <table
                    class="
                      table table-sm table-striped
                      tab-input-data
                      table-pfsp
                    "
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th>&nbsp;</th>
                        <th *ngFor="let header of tableService.headersTable1">
                          {{ header.index }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *cdkVirtualFor="
                          let item of formService.form.get('rows')['controls'];
                          let i = index
                        "
                      >
                        <tr>
                          <td>{{ i + 1 }}</td>
                          <td *ngFor="let cols of numbers; let j = index">
                            <mat-form-field appearance="outline">
                              <input
                                [formControl]="
                                  formService.form
                                    .get('rows')
                                    ['controls'][i].get('c' + j)
                                "
                                matInput
                                autocomplete="off"
                              />
                            </mat-form-field>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </ng-container>
                <ng-template #noAllTableData>
                  <div class="alert alert-warning" role="alert">
                    {{ "NoRecordsFound" | translate }}
                  </div>
                </ng-template>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
        <!-- End Left Table PFSP -->
      </div>
    </div>
  </form>
</ng-container>
