<div id="login-wrapper">

  <div class="login-form">
    <div class="row">
      <div class="col-md-12">
        <h3>{{"COMPUTER INTEGRATED MANUFACTURING" | translate}}</h3>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="save()">

      <div class="row passwordBox">
        <div class="col-sm-10 customPadding">
          <mat-form-field color="primary" appearance='outline'>
            <mat-label>{{'Password' | translate}}</mat-label>
            <input matInput type='password' formControlName="password" id="password" (keyup)="changeCurrentPass()">
          </mat-form-field>

          <app-password-strength [form]="form" (currentPassStrengthEvent)='passStrengthChanged($event)'
            [currentPass]="this.form.get('password').value"></app-password-strength>
        </div>

        <div class="col-sm-2 customPadding iconBox">
          <span [ngbPopover]="popContent1" popoverTitle="Password Info" placement="bottom"
          popoverClass="PasswordPopoverBottom" triggers="mouseenter:mouseleave" container="body">
              <mat-icon class="help-icon">help_outline</mat-icon>
              <ng-template #popContent1>
                  {{"PasswordInfo" | translate}}
              </ng-template>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10 customPadding">
          <mat-form-field color="primary" appearance='outline'>
            <mat-label>{{'Confirm Password' | translate}}</mat-label>
            <input matInput type='password' formControlName="passwordConfirm" #passwordConfirm
              (keyup)='setConfirmPassword()' class="postal-code-font-size">
          </mat-form-field>
        </div>
        <div class="col-sm-2 customPadding iconBox">
          <span [ngbPopover]="popContent1" popoverTitle="Password Info" placement="bottom"
          popoverClass="PasswordPopoverBottom" triggers="mouseenter:mouseleave" container="body">
              <mat-icon class="help-icon">help_outline</mat-icon>
              <ng-template #popContent1>
                  {{"PasswordInfo" | translate}}
              </ng-template>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10 customPadding">
          <button type="submit" mat-flat-button color="accent" [disabled]="form.invalid">{{'Submit' | translate}}</button>
        </div>
        <div class="col-sm-2 customPadding"></div>
      </div>

    </form>
  </div>

</div>
