<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 formHeading">
      <h3 class="modalHeading">
        {{ "Packing" | translate }}
      </h3>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 itemData">
      @if (itemsTmp.length > 0) {
        <p>{{ itemsTmp[currentItemIndex].description }}</p>
      }
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-10">
      <app-three-d-container #threeDContainerComponent [items]="itemsTmp" [container]="container"></app-three-d-container>
    </div>
  </div>
</div>

<div mat-dialog-actions>
      <button type="button" mat-flat-button color="primary"
        [disabled]="currentItemIndex == items.length - 1"
        (click)="pack()">{{"Add" | translate}}
      </button>
      <button type="button" mat-flat-button color="warn"
        [disabled]="currentItemIndex == -1"
        (click)="unpack()">{{"Delete" | translate}}
      </button>
</div>
