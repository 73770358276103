export const modules = [
    {
        id: 1,
        title: "Home",
        claim: "home",
        path: "/home"
    }
];

export function getStartModuleUrl(settings) {
    let startModule = modules.find(x => x.id == settings.startingModule);
    return startModule ? startModule.path : modules[0].path;
}