import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../../../shared/services/api.service';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';

@Injectable({
  providedIn: 'root'
})
export class Distribution1ToNOverviewService extends ApiService<any>{

  constructor(
    http: HttpClient,
    private dataService: BlDistribution1ToNDataService
  ) {
    super("api/mladen", http);
  }

  getAll() : Observable<any>{
    return this.getAll2();
  }

  getWithTotalCount() : Observable<any> {
    return this.getAll2();

  }

  getWithAllData() : Observable<any> {
    return this.getAll2();
  }

  getWithParams(params: any) {
    return this.getAll2();
  }

  getWithBodyParams(params: any) {
    return this.getAll2();
  }
  

  getAll2(): Observable<any> {
    let finalObject = [];

    const source = this.dataService.source;
    const destinations = this.dataService.allDestinations;
    const destinationsQuantity = this.dataService.destinationsQuantity;

    finalObject[0] =  {
      latitude: this.makeObj(source[0]),
      longitude: this.makeObj(source[1]),
      isSource: 1
    }

    destinations.forEach((x, index) => {
      finalObject.push({
        latitude: this.makeObj(x[0]),
        longitude: this.makeObj(x[1]),
        quantity: this.makeObj(destinationsQuantity[index]),
        isSource: 0
      })
    })

    return of(finalObject);
  }

  makeObj(obj) {
    return obj == null ? "-" : obj;
  }
  
}
