<button  mat-flat-button color="accent" type="button" class="uploadImage" (click)="fileInput.click()">{{'SelectPhoto' | translate}}</button>
<input multiple style="display: none" type="file" class="upload" accept="image/*" (change)="onFileChanged($event)" #fileInput>

<!-- include this in every parent component -->
<!-- <button mat-raised-button (click)="appendImageToForm()" color="primary">Upload</button> -->
<!-- <p *ngIf="noPhotoSelected" class="alert alert-danger">You have to select photo first!</p> -->

<!-- for reading -->
<!-- 

<div class="alert-dark" *ngIf="logoUpload==null">
	No Logo Uploaded
</div>
<div class="alert-dark" *ngIf="logoUpload!=null">
	<ng-container *ngFor="let logo of logoUpload">
		<img [src]="logo.path" [alt]="logo.alt" />
	</ng-container>
</div>

-->