import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IDistribution1ToNRouteSettingsForm } from '../../../../interfaces/i-distribution-1-to-n-route-settings';
import { BlDistribution1ToNRouteSettingsFormService } from '../../../../services/forms/bl-distribution-1-to-n-route-settings-form.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-route-settings-dashboard-distribution',
  templateUrl: './route-settings-dashboard-distribution.component.html',
  styleUrls: ['./route-settings-dashboard-distribution.component.scss']
})
export class RouteSettingsDashboardDistributionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    private formService: BlDistribution1ToNRouteSettingsFormService,
    public dataService: BlDistribution1ToNDataService,
    private alertService: AlertMessageDataService,
    protected matDialog: MatDialog
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNRouteSettingsForm> = this.formService.getFormReference();

  isClickedSave = false;
  subscription: Subscription = new Subscription();

  settings = [
    {
      controlName: "motorway",
      title: "Motorway"
    },
    {
      controlName: "trunk",
      title: "Trunk"
    },
    {
      controlName: "primary",
      title: "Primary"
    },
    {
      controlName: "secondary",
      title: "Secondary"
    },
    {
      controlName: "tertiary",
      title: "Tertiary"
    },
    {
      controlName: "unclassified",
      title: "Unclassified"
    },
    {
      controlName: "residential",
      title: "Residential"
    }
  ];

  ngOnInit(): void {
    this.formService.fillForm();

    this.settings.forEach(x => {
      if(this.dataService.settingsObj[x.controlName] == null) {
        this.form.get(x.controlName).disable();
      }
      else {
        this.form.get(x.controlName).enable();
      }

    })
  }

  checkboxChange(event, index): void {
    const controlName = this.settings[index].controlName;

    if(!event.checked) {
      this.form.get(controlName).setValue(null);
      this.form.get(controlName).disable();
    }
    else {
      this.form.get(controlName).enable();
    }
  }

  save(): void {
    this.isClickedSave = true;
    this.dataService.settingsObj = this.formService.createSettingsObj();
    this.close();
  }

  close(): void {
    this.matDialog.closeAll();
  }

}
