import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'buttonCondition'
})
export class ButtonConditionPipe implements PipeTransform {

  transform(button: ITableColumn): boolean { // TODO: set type table buttons
    return (!button.hasOwnProperty('condition')) || (button.hasOwnProperty('condition') && button.condition());
  }

}
