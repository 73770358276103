import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { PrintDeletedOptionHelper } from '../../helpers/print-deleted-option-helper';
import { IVCommonNotFoundElement, IVCommonOption, IVCommonSelect } from '../../interfaces/i-vcommon-select';
import { TranslateService } from '@ngx-translate/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-v-common-select',
  templateUrl: './v-common-select.component.html',
  styleUrls: ['./v-common-select.component.scss']
})
export class VCommonSelectComponent implements OnInit, OnChanges {

  @Input() public labelText = "";
  @Input() public formControlToFill: UntypedFormControl;
  @Input() public options: IVCommonOption[] = null;
  @Input() public setup: IVCommonSelect = {};
  @Input() public notFoundElementSetup: IVCommonNotFoundElement;
  @Output() public onSelect: EventEmitter<any> = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  
  public isOptionsCame = false;
  public deletedOptionInfo: string = null;
  public deletedOptionStatus: string = "";

  public filterControl = new UntypedFormControl("");
  private originalOptions: IVCommonOption[] = [];
  public deletedOption: IVCommonOption;

  constructor(
    private translateService: TranslateService
  ) { }
  
  ngOnInit() {
    this.filterControl.valueChanges.subscribe({
      next: data => {
        if(data){
          let options = this.originalOptions.filter( x =>(x.title)? (x.title.toLowerCase().indexOf(data.toLowerCase()) != -1 || x.id == this.formControlToFill.value): false);
          let foundOption = options.find(x => x.id == this.formControlToFill.value);
          if(foundOption) {
            options = options.filter(x => x.id != this.formControlToFill.value);
            options.unshift(foundOption);
          }
          this.options = options;
          // if(this.options.length == 0){
          //   this.options = cloneDeep(this.originalOptions);
          // }
        } else {
          this.options = cloneDeep(this.originalOptions);
          let foundOption = this.originalOptions.find(x => x.id == this.formControlToFill.value);
          if(foundOption) {
            this.options = this.originalOptions.filter(x => x.id != this.formControlToFill.value);
            this.options.unshift(foundOption);
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.options && changes.options.currentValue){
      this.isOptionsCame = true;
    }
    if ( this.options && this.options.length > 0 && this.formControlToFill && this.formControlToFill.value != null) {
        // this.options = changes.options.currentValue;
        let originalOptions = cloneDeep(this.options);

        let currentValue = this.formControlToFill.value;
        let index = this.options.findIndex(item => item.id == currentValue);

        if (index != -1) {
          let trenutniObjekat = this.options.find(x => x.id == currentValue);
          this.options = this.options.filter(x => x.id != currentValue && x.id != 0);
          this.options.unshift(trenutniObjekat);
        } else if(currentValue != 0){
          this.deletedOption = {
            id: currentValue,
            title: this.setNotFoundTitle(),
            isNotFound: true
          }
          this.options.unshift(this.deletedOption);
        }

        let defaultOption = originalOptions.find(x => x.id == 0);
        if(defaultOption && (!this.options[index] || this.options[index].id != 0)){
          this.options.unshift(defaultOption);
        }
        this.originalOptions = cloneDeep(this.options);
    }
  }

  openedChange(opened) {
    if (opened) {
      this.options = cloneDeep(this.originalOptions);
      let index = this.options.findIndex(item => item.id == this.formControlToFill.value);
      let currentValue = this.formControlToFill.value;
      if (index != -1) {
        let trenutniObjekat = this.options.find(x => x.id == currentValue);
        let originalOptions = cloneDeep(this.options);
        this.options = this.options.filter(x => x.id != currentValue && x.id != 0);
        this.options.unshift(trenutniObjekat);
        let defaultOption = originalOptions.find(x => x.id == 0);
        if(defaultOption && trenutniObjekat.id != 0){
          this.options.unshift(defaultOption);
        }
      }
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    } else {
      let currentValue = this.formControlToFill.value;
      this.options = cloneDeep(this.originalOptions);
      let index = this.options.findIndex(item => item.id == currentValue);
      if (index != -1) {
        let trenutniObjekat = this.options.find(x => x.id == currentValue);
        let originalOptions = cloneDeep(this.options);
        this.options = this.options.filter(x => x.id != currentValue && x.id != 0);
        this.options.unshift(trenutniObjekat);
        let defaultOption = originalOptions.find(x => x.id == 0);
        if(defaultOption && trenutniObjekat.id != 0){
          this.options.unshift(defaultOption);
        }
      }
      this.formControlToFill.setValue(currentValue, {emitEvent: false});
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }

  showDeletedOptionInfo(id: number){
    if(!this.notFoundElementSetup || !this.notFoundElementSetup.apiService) {
      console.error("V COMMON SELECT: ApiService not passed for deleted item!");
    }
    else if(!this.deletedOptionInfo) {
      this.notFoundElementSetup.apiService.get(id).subscribe({
        next: data => {
          let helper = new PrintDeletedOptionHelper(this.translateService, this.notFoundElementSetup.props);
          this.deletedOptionInfo = helper.getDeletedOptionHtml(data);
          if(this.notFoundElementSetup.infoCondition){
            this.deletedOptionStatus = this.notFoundElementSetup.infoCondition(data);
          }
          if(!this.deletedOptionStatus) {
            this.deletedOptionStatus = "Deleted";
          }
        }
      });
    }
  }
  private setNotFoundTitle(){
    return this.notFoundElementSetup && this.notFoundElementSetup.message? this.notFoundElementSetup.message : "Deleted / Not active";
  }

  select(event) {
    this.onSelect.emit(event);
    this.cdkVirtualScrollViewPort.scrollToIndex(0);
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }
  

}
