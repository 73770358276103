<div class="row">
  <div class="col-md-12">
    <h3 class="page-header-title"> <span>{{"MRP" | translate}}</span> / {{"Deterministic MRP" | translate}}</h3>
  </div>
</div>

<div class="row">
  <!-- Buttons for Add,  View -->
  <div class="col-md-12">
    <div class="header-button-box">
      <div class="header-buttons">
        <button type="button" mat-stroked-button color="primary"
          class="custom-stroked-btn"
          (click)="newMrp()"
          [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-create-new-problem')">
          <mat-icon>create</mat-icon>{{"New" | translate}}
        </button>
        <button type="button" mat-stroked-button color="primary"
          class="custom-stroked-btn"
          (click)="viewMrps()"
          [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-open-all-problems')">
          <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
        </button>
        @if (formService.isShowOtherTabs == true) {
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="saveDatas()"
            [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-save-problem') || !this.formService.form.get('rows').valid">
            <mat-icon>album</mat-icon>{{"Save" | translate}}
          </button>
          @if ((nameOfCreated && dataService.patchFlag) || dataService.idForPatch) {
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="saveAsNew()"
              [disabled]="(!permissionsService.havePermission('deterministic-mrp', 'ability-to-save-as') || !this.formService.form.get('rows').valid)">
              <mat-icon>album</mat-icon>{{"Save As" | translate}}
            </button>
          }
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="openWindow()"
            [disabled]="!permissionsService.havePermission('deterministic-mrp', 'ability-to-run-problem') || !this.formService.form.get('rows').valid">
            <mat-icon>play_circle_outline</mat-icon>{{"Run" | translate}}
          </button>
        }
      </div>
      <div class="header-info-and-help">
        @if (dataService.currentTitle) {
          <div class="header-info">
            {{dataService.currentTitle}}
          </div>
        }

        <button type="button" mat-stroked-button color="primary"
          class="custom-stroked-btn"
          (click)="openMarkdown()">
          <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  @if (!(dataService.storageDataViewDialog.getValue() || nameOfCreated)) {
    <div class="col-md-12 iframe-wrapper">
      <div class="iframe-holder">
        <iframe
          src="assets/markdown/mrp/deterministic-mrp/help.html"
          width="100%" height="100%"  frameborder="5">
        </iframe>
      </div>
    </div>
  }
</div>
