<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 formHeading">
            <h3 class="modalHeading">
                {{"MRP Specification" | translate}}
            </h3>
        </div>
    </div>
    <div class="row">
        <!-- App Search components -->
        <div class="col-sm-6 col-md-4 searchBox">
            <app-search class="reducedForm" [dataService]="dataService"></app-search>
        </div>
        <!-- End App Search components -->
        <div class="col-sm-6 col-md-8 show-hide-btn-box">
            <app-show-hide-columns class="reducedButton" [tableService]="tableService"></app-show-hide-columns>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 table-box mrp-table">
            <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService">
            </app-table>
        </div>
    </div>
</div>