import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IBackpackProblemForm } from '../../../../interfaces/i-backpack-problem';
import { BlBackpackProblemFormService } from '../../../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemRequestsService } from '../../../../services/requests/bl-backpack-problem-requests.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { BlBackpackProblemContainersTableService } from '../../../../services/table/bl-backpack-problem-containers-table.service';
import { BackpackHelperFunctionsService } from '../../../../services/helper/backpack-helper-functions.service';

@Component({
  selector: 'app-edit-containers-dialog-backpack-problem',
  templateUrl: './edit-containers-dialog-backpack-problem.component.html',
  styleUrls: ['./edit-containers-dialog-backpack-problem.component.scss']
})
export class EditContainersDialogBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<EditContainersDialogBackpackProblemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: BlBackpackProblemDataService,
    public formService: BlBackpackProblemFormService,
    public tableService: BlBackpackProblemContainersTableService,
    private alertService: AlertMessageDataService,
    private requestService: BlBackpackProblemRequestsService,
    private cdr: ChangeDetectorRef,
    private backpackHelperFunctionsService: BackpackHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;
  public isViewLoad: boolean = false;
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IBackpackProblemForm> = this.formService.form;
  public numbers: any = [];

  public showForm: boolean = false;

  ngAfterViewInit() {
  setTimeout(() => {

    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: dataView => {
          if (dataView && dataView.id) {
            this.numbers = Array(this.dataService.containerNumberOfColumns).fill(0).map((x, i) => i);
            this.tableService.createDynamicHeaderColumnsTable(this.formService.sizes);
            this.formService.setContainersRows(dataView[0] ? dataView[0] : dataView);
            this.showForm = true;
          }
        }
      })
    );
      this.cdr.detectChanges()
    },0)
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.subscription.add(
        this.dataService.originalObject.subscribe({
          next: data => {
            if(data) {
              this.dataFromStorage = data;
              this.formService.sizes = this.dataFromStorage.size;
              this.dataFromStorageForSend = data;
              this.numbers = Array(this.dataService.containerNumberOfColumns).fill(0).map((x, i) => i);
              this.tableService.createDynamicHeaderColumnsTable(this.formService.sizes);
              this.showForm = true
              if(data.id) {
                this.formService.setContainersRows(data);
              } else {
                this.formService.createContainersTableWithDynamicRows(this.dataService.containerNumberOfColumns, this.formService.sizes);
              }
            }
          }
        })
      );

    }, 0);

  }

  save() {
    this.clickedButton = true;
    let distanceRows: any = null;
    let flowRows: any = null;

    if(this.dataFromStorage) {
      let form: any = this.formService.form.getRawValue();
      if(this.dataFromStorage.id) {
        distanceRows = this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.containers, form);
        flowRows = (this.dataFromStorage.items.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.dataFromStorage.items, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(this.formService.reducedItemsArray, this.formService.reducedItemsArray);
      } else {
        distanceRows = this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.containers, form);
        let prepakovanNizObjekataItems: any = this.backpackHelperFunctionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
        flowRows = (form.items.length > 0) ? this.backpackHelperFunctionsService.mapToSingleArrayStavke(form.items, form) : this.backpackHelperFunctionsService.mapToSingleArrayStavke(prepakovanNizObjekataItems, prepakovanNizObjekataItems);
      }

      this.prepareDataToSend(flowRows, distanceRows);

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.subscription.add(
          this.requestService.saveData(this.dataForSend).subscribe({
            next: success => {
              this.backpackHelperFunctionsService.breakApartStavke(success);
              this.alertService.setMessage("success", this.dataForSend.id ? "Successfully updated." : "Successfully created.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.dataService.originalObject.next(success);
              if (success['id']) {
                this.dataService.setIdForPatch(success['id']);
              }
              this.close(true);
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }

  }

  prepareDataToSend(flowRows: any, distanceRows: any): void {
    this.dataForSend = [];

    this.dataForSend.push({
      id: this.dataFromStorage.id ? this.dataFromStorage.id : null,
      title: this.dataFromStorage.title,
      problemType: this.dataFromStorage.problemType,
      items: flowRows,
      containers: distanceRows,
    });
  }

  close(state?: boolean) {
    this.dialogRef.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clickedButton = false;
  }

}
