@if (options && (setup.isAllowedOptionsEmptyArray? true: (isOptionsCame))) {
  <mat-form-field appearance="outline" [ngClass]="(formControlToFill.invalid)? 'requiredField': false">
    <mat-label>{{ labelText | translate}}</mat-label>
    <mat-select (openedChange)="openedChange($event)" class="virtual-scroll"
      [formControl]="formControlToFill" panelClass="custom-mat-select-panel-class"
      disableOptionCentering>
      <mat-select-search [formControl]="filterControl"></mat-select-search>
      <cdk-virtual-scroll-viewport class="example-viewport-select" minBufferPx="200" maxBufferPx="400" [itemSize]="36"
        [style.height.px]="options.length > 5 ? 6 * 36 : options.length * 36">
        <mat-option *cdkVirtualFor="let option of options" [value]="option.id" (click)="select(option)">
          @if (!option.isNotFound) {
            <span>
              {{option.title}}
            </span>
          } @else {
            <span>
              {{option.title | translate}}
              <a (mouseover)="showDeletedOptionInfo(option.id)" class="deleted-link-cursor option-title" [ngbPopover]="popContent"
                popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom"
                container="body" triggers="mouseenter:mouseleave">
                #{{ option.id }}
              </a>
              <ng-template #popContent>
                @if (deletedOptionInfo && deletedOptionStatus) {
                  <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{
                deletedOptionStatus }}</p>
                <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
              }
            </ng-template>
          </span>
        }
        <ng-template #deletedOption>
          <span>
            {{option.title | translate}}
            <a (mouseover)="showDeletedOptionInfo(option.id)" class="deleted-link-cursor option-title" [ngbPopover]="popContent1"
              popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom"
              container="body" triggers="mouseenter:mouseleave">
              #{{ option.id }}
            </a>
            <ng-template #popContent1>
              @if (deletedOptionInfo && deletedOptionStatus) {
                <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{
              deletedOptionStatus }}</p>
              <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
            }
          </ng-template>
        </span>
      </ng-template>
    </mat-option>
  </cdk-virtual-scroll-viewport>
</mat-select>
@if (formControlToFill.hasError('required')) {
  <mat-error class="customError">
    {{'ThisFieldIsRequired' | translate}}
  </mat-error>
}
@if (formControlToFill.disabled &&  this.deletedOption && this.deletedOption.isNotFound) {
  <span>
    <a (mouseover)="showDeletedOptionInfo(deletedOption.id)" class="deleted-link-cursor" [ngbPopover]="popContent2"
      popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom"
      triggers="mouseenter:mouseleave" container="body">
      #{{ deletedOption.id }}
    </a>
    <ng-template #popContent2>
      @if (deletedOptionInfo && deletedOptionStatus) {
        <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{
      deletedOptionStatus }}</p>
      <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
    }
  </ng-template>
</span>
}
</mat-form-field>
} @else {
  <app-input-with-spinner inputLabel="{{labelText}}"></app-input-with-spinner>
}
