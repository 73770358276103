import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { BlQapFormService } from '../../../../../../services/forms/bl-qap-form.service';
import { BlQapRequestsService } from '../../../../../../services/requests/bl-qap-requests.service';
import { BlQapDataService } from '../../../../../../services/shared/bl-qap-data.service';
import { BlQapDistancesTableService } from '../../../../../../services/table/bl-qap-distances-table.service';
import { QapHelperFunctionsService } from '../../../../../../services/helper/qap-helper-functions.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-tab-dashboard-input-data',
  templateUrl: './tab-dashboard-input-data.component.html',
  styleUrls: ['./tab-dashboard-input-data.component.scss']
})
export class TabDashboardInputDataComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private dataService: BlQapDataService,
    public formService: BlQapFormService,
    public tableService: BlQapDistancesTableService,
    private alertService: AlertMessageDataService,
    // public translateService: TranslateService,
    private requestService: BlQapRequestsService,
    private cdr: ChangeDetectorRef,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;

  private subscription: Subscription = new Subscription();

  public dataForTable: any = [];
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  ngAfterViewInit(): void {
    this.dataService.originalObject.subscribe({
      next: (data) => {
        if (data && data.id) {
          this.dataFromStorageForSend = cloneDeep(data);
          this.formService.reducedNumbersArray = this.dataFromStorageForSend.optimalSequence;
          this.formService.sizes = this.dataFromStorageForSend.size;
        }
      }
    });

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          if (data) {
            this.dataFromStorage = cloneDeep(data);
            if(data.id) {
              this.formService.reducedNumbersArray = this.dataFromStorage.optimalSequence;
              this.formService.sizes = this.dataFromStorage.size;
            } else {
              this.formService.formateArrayNumbers(data["size"]);
              this.dataFromStorage = cloneDeep(data);
              this.formService.reducedNumbersArray = this.formService.originalNumbersArray;
    
              this.formService.sizes = this.dataFromStorage.size;
              this.dataFromStorageForSend = cloneDeep(data);
            }
          }
        }
      })
    );

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formService.reducedNumbersArray, event.previousIndex, event.currentIndex);

    this.clickedButton = true;
    this.dataForSend = [];
    if(this.dataFromStorage) {
      let flowRows: any = null;
      let distanceRows: any = null;
      if(this.dataFromStorage.id) {
        let form: any = this.formService.form.getRawValue();
        flowRows = (this.dataFromStorageForSend.flows.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.dataFromStorageForSend.flows, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.formService.reducedFlowsArray, this.formService.reducedFlowsArray);
        distanceRows = (this.dataFromStorageForSend.distances.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.dataFromStorageForSend.distances, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(this.formService.reducedDistancesArray, this.formService.reducedDistancesArray);

      } else {
        let form: any = this.formService.form.getRawValue();
        let prepakovanNizObjekataFlows: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
        let prepakovanNizObjekataDistances: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
        flowRows = (form.flows.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(form.flows, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(prepakovanNizObjekataFlows, prepakovanNizObjekataFlows);
        distanceRows = (form.distances.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(form.distances, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(prepakovanNizObjekataDistances, prepakovanNizObjekataDistances);
      }

      this.dataForSend.push({
        size: this.dataFromStorageForSend.size,
        title: (this.formService.problemTitle == "") ? this.dataFromStorageForSend.title : this.formService.problemTitle,
        flows: flowRows,
        distances: distanceRows,
        algorithm: this.formService.form.getSafe(x => x.algorithm).value,
        optimalSequence: (this.formService.reducedNumbersArray.length == 0) ? this.formService.originalNumbersArray : this.formService.reducedNumbersArray,
        // ub: null
        ub: this.dataFromStorage.ub
      });

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        if (this.dataForSend.ub == null) {
          this.alertService.setMessage("danger", "Error. UB is null. Before Run problem, please Save.");
          SpinnerFunctions.hideSpinner();
          this.clickedButton = false;
        } else {
          this.subscription.add(
            this.requestService.runProblem(this.dataForSend).subscribe({
              next: success => {
                this.flowsAndDistancesArrayFunstionsService.breakApartStavke(success);
                this.alertService.setMessage("success", "Successfully.");
                this.dataService.originalObject.next(success[0] ? success[0] : success);
                SpinnerFunctions.hideSpinner();
                this.clickedButton = false;
                this.formService.isRunProblemForTitle = true;
                this.formService.problemTitle = success[0] ? success[0].title : success["title"];
              },
              error: error => {
                this.alertService.setMessage("danger", "Error.");
                SpinnerFunctions.hideSpinner();
                this.clickedButton = false;
              }
            })
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.sizes = 0;
    this.clickedButton = false;
    // this.dataForTable = [];
    this.dataForSend = [];
    this.dataFromStorage = [];
    this.dataFromStorageForSend = [];
  }

}
