import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  constructor(
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
  }
  
  close() {
    this.matDialog.closeAll();
  }
}
