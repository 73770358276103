import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeterministicMrpModule } from './deterministic-mrp/deterministic-mrp.module';

const routes: Routes = [
  {
    path: "deterministic-mrp",
    // loadChildren: "./deterministic-mrp/deterministic-mrp.module#DeterministicMrpModule"
    loadChildren: () => DeterministicMrpModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MrpRoutingModule { }
