import { Component, Input, TemplateRef } from '@angular/core';
import { ORGTree, ORGNode } from "./utility/orgnode";

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent {

  @Input() set data(value){
     this.tree = new ORGTree();
     this.addNodes(this.tree, value)
     this.tree.UpdateTree();
  }

  update(): void {
    this.tree.UpdateTree();
  }

  get config(): any {
    return this.tree.config;
  }

  get nodes(): any {
    return this.tree.nDatabaseNodes
  }

  tree: ORGTree = new ORGTree();

  getChildren(node:ORGNode,nodes:ORGNode[]=[]): ORGNode[]
  {
    const children=node.nodeChildren;
    if (children && children.length) {
      nodes=[...nodes,...children];
      children.forEach(x=>{
        nodes=this.getChildren(x,nodes);
      });
    }
    return nodes;
  }

  getParent(node:ORGNode,nodes:ORGNode[]=[]): ORGNode[]
  {
    if (node.nodeParent){
      nodes=[...nodes,node.nodeParent];
      nodes=this.getParent(node.nodeParent,nodes);
    }
    return nodes;
  }

  getSlibingNodes(node:ORGNode): ORGNode[] {
    return [...this.getParent(node),...this.getChildren(node)];
  }

  private addNodes(tree:ORGTree,node:any,parent:any=null): void
  {
    parent=parent || {id:-1};

    if(Array.isArray(node)) {
      node = {...node[0]};
    } else {
      node = {...node};
    }

    node.id=tree.nDatabaseNodes.length;

    tree.add(node.id, parent.id, node.name, node.title, node.message, null, null);
    if (node.childs)
    {
      node.childs.forEach((x:any)=>{
        this.addNodes(tree, x, node);
      })
    }
  }
}
