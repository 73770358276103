import { Component, OnInit, OnDestroy, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BlQapDataService } from '../../../../services/shared/bl-qap-data.service';
import { BlQapFormService } from '../../../../services/forms/bl-qap-form.service';
import { BlQapFlowsTableService } from '../../../../services/table/bl-qap-flows-table.service';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlQapRequestsService } from '../../../../services/requests/bl-qap-requests.service';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IQapForm } from '../../../../interfaces/i-qap';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { QapHelperFunctionsService } from '../../../../services/helper/qap-helper-functions.service';
import { DynamicAlgorithmFormControlsService } from '../../../../../../core/services/dynamic-algorithm-form-controls.service';

@Component({
  selector: 'app-edit-flows-dialog-dashboard-qap',
  templateUrl: './edit-flows-dialog-dashboard-qap.component.html',
  styleUrls: ['./edit-flows-dialog-dashboard-qap.component.scss']
})
export class EditFlowsDialogDashboardQapComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<EditFlowsDialogDashboardQapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: BlQapDataService,
    public formService: BlQapFormService,
    public tableService: BlQapFlowsTableService,
    private alertService: AlertMessageDataService,
    private requestService: BlQapRequestsService,
    private cdr: ChangeDetectorRef,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService,
    private flowsAndDistancesArrayFunstionsService: QapHelperFunctionsService
  ) { }

  public clickedButton: boolean = false;
  public isViewLoad: boolean = false;
  public dataForSend: any;
  public dataFromStorageForSend: any;
  public dataFromStorage: any;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IQapForm> = this.formService.form;
  public numbers: any = [];

  ngAfterViewInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (dataView) => {
          if (dataView && dataView.id) {
            this.numbers = Array(this.formService.sizes).fill(0).map((x, i) => i);
            this.tableService.createDynamicHeaderColumnsTable(this.formService.sizes);
            this.formService.setFlowsRows(dataView[0] ? dataView[0] : dataView);
          }
        }
      })
    );
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          if(data) {
            this.dataFromStorage = data;
            this.formService.sizes = this.dataFromStorage.size;
            this.dataFromStorageForSend = data;

            this.numbers = Array(this.formService.sizes).fill(0).map((x, i) => i);
            this.tableService.createDynamicHeaderColumnsTable(this.formService.sizes);
            if (data.id) {
              this.formService.setFlowsRows(data);
            } else {
              this.formService.createFlowsTableWithDynamicRows(this.formService.sizes, this.formService.sizes);
            }
          }
        }
      })
    );
  }

  save() {
    this.clickedButton = true;
    this.dataForSend = [];
    if (this.dataFromStorage) {
      let form: any = this.formService.form.getRawValue();

      let flowRows: any = this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(form.flows, form);
      let prepakovanNizObjekataDistances: any = this.flowsAndDistancesArrayFunstionsService.createStaticsArrayObjectNewForm(this.dataFromStorage.size);
      let distanceRows: any = (form.distances.length > 0) ? this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(form.distances, form) : this.flowsAndDistancesArrayFunstionsService.mapToSingleArray(prepakovanNizObjekataDistances, prepakovanNizObjekataDistances);

      this.formService.formateArrayNumbers(this.dataFromStorage.size);

      this.dataForSend.push(this.prepareDataToSend(flowRows, distanceRows));

      SpinnerFunctions.showSpinner();
      this.subscription.add(
        this.requestService.insertData(this.dataForSend).subscribe({
          next: success => {
            this.flowsAndDistancesArrayFunstionsService.breakApartStavke(success);
            this.alertService.setMessage("success", this.dataFromStorage.id ? "Successfully updated." : "Successfully created.");
            this.clickedButton = false;
            this.dataService.originalObject.next(success);
            if (success['id']) {
              this.dataService.setIdForPatch(success['id']);
            }
            SpinnerFunctions.hideSpinner();
            this.close(true);
          },
          error: err => {
            this.alertService.setMessage("danger", "Error.");
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
          }
        })
      );
      
    }
  }

  prepareDataToSend(flowRows: any, distanceRows: any): any {
    let dataToSend = {
      id: this.dataFromStorage.id ? this.dataFromStorage.id : null,
      size: this.dataFromStorage.size,
      title: this.dataFromStorage.title,
      problemType: this.dataFromStorage.problemType,
      flows: flowRows,
      distances: distanceRows,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParams(this.formService.form),
      algorithm: this.formService.form.getSafe(x => x.algorithm).value
    };

    return dataToSend;
  }

  close(state?: boolean) {
    this.dialogRef.close(state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clickedButton = false;
  }

}
