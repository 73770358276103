<div class="navbar navbar-expand-lg">
  <div [ngClass]="isLeftSidebarOpen ? 'show' : false">
    <mat-accordion class="sidebar-accordion">
      @if (permissionsService.havePermission('home')) {
        <mat-expansion-panel hideToggle [disabled]="disableHome" [ngClass]="disableHome ? 'dark' : 'light'">
          <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
            <mat-panel-title>
              <a routerLink="/home" class="home-panel-title" (click)="toggleLeftBar()"><span>{{"Home" | translate}}</span></a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      }
    @if (permissionsService.havePermission('order')) {
      <mat-expansion-panel (click)="disableFirst()" hideToggle>
        <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
          <mat-panel-title>
            <p class="section-panel-title"><span>{{"Scheduling" | translate}}</span></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="panelList">
          <li routerLinkActive="active">@if (permissionsService.havePermission('order', 'dashboard-pfsp')) {
            <a routerLink="/order/pfsp/dashboard-pfsp" (click)="toggleLeftBar()">{{"PFSP"
            | translate}}</a>
          }</li>
      </ul>
    </mat-expansion-panel>
  }
  @if (permissionsService.havePermission('cms')) {
    <mat-expansion-panel (click)="disableFirst()" hideToggle>
      <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
        <mat-panel-title>
          <p class="section-panel-title"><span>{{"CSM" | translate}}</span></p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul class="panelList">
        <li routerLinkActive="active">@if (permissionsService.havePermission('cms', 'dashboard-cell-formation-problem')) {
          <a routerLink="/cms/cell-formation-problem/dashboard-cell-formation-problem"
            (click)="toggleLeftBar()">{{"CFP"
          | translate}}</a>
        }</li>
      </ul>
    </mat-expansion-panel>
  }
  @if (permissionsService.havePermission('routing')) {
    <mat-expansion-panel (click)="disableFirst()" hideToggle>
      <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
        <mat-panel-title>
          <p class="section-panel-title"><span>{{"Routing" | translate}}</span></p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul class="panelList">
      <li routerLinkActive="active"><a routerLink="/routing/ptp/dashboard-distribution"
      (click)="toggleLeftBar()">{{"Point To Point" | translate}}</a></li>
    <li routerLinkActive="active">@if (permissionsService.havePermission('routing', 'dashboard-distribution')) {
      <a routerLink="/routing/sssp/dashboard-distribution" (click)="toggleLeftBar()"
        >{{"Single Source SP" |
      translate}}</a>
    }</li>
    <li routerLinkActive="active"><a routerLink="/routing/tsp/dashboard-distribution" (click)="toggleLeftBar()">{{"TSP"
    | translate}}</a></li>
    <li routerLinkActive="active"><a routerLink="/routing/cvrp/dashboard-distribution" (click)="toggleLeftBar()">{{"Multi destinations centers"
    | translate}}</a></li>
    <li routerLinkActive="active"><a routerLink="/routing/geo-location/dashboard-geo-location" (click)="toggleLeftBar()">{{"GeoLocation"
    | translate}}</a></li>
</ul>
</mat-expansion-panel>
}
<mat-expansion-panel (click)="disableFirst()" hideToggle>
  <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
    <mat-panel-title>
      <p class="section-panel-title"><span>{{"Assignment" | translate}}</span></p>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ul class="panelList">
    <li routerLinkActive="active">@if (permissionsService.havePermission('assignment', 'dashboard-qap')) {
      <a routerLink="/assignment/qap/dashboard-qap" (click)="toggleLeftBar()">{{"Square Assignment" | translate}}</a>
    }</li>
  </ul>
</mat-expansion-panel>
<mat-expansion-panel (click)="disableFirst()" hideToggle>
  <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
    <mat-panel-title>
      <p class="section-panel-title"><span>{{"Packaging" | translate}}</span></p>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ul class="panelList">
    <li routerLinkActive="active"><a routerLink="/packing/backpack-problem"
    (click)="toggleLeftBar()">{{"3DPacking" | translate}}</a></li>
</ul>
</mat-expansion-panel>
@if (permissionsService.havePermission('mrp')) {
  <mat-expansion-panel (click)="disableFirst()" hideToggle>
    <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
      <mat-panel-title>
        <p class="section-panel-title"><span>{{"MRP" | translate}}</span></p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="panelList">
      <li routerLinkActive="active">@if (permissionsService.havePermission('mrp', 'dashboard-deterministic-mrp')) {
        <a routerLink="/mrp/deterministic-mrp/dashboard-deterministic-mrp" (click)="toggleLeftBar()"
          >{{"Deterministic MRP" |
        translate}}</a>
      }</li>
</ul>
</mat-expansion-panel>
}
@if (permissionsService.havePermission('admin-config')) {
  <mat-expansion-panel hideToggle [disabled]="disableHome" [ngClass]="disableHome ? 'dark' : 'light'">
    <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
      <mat-panel-title>
        <a routerLink="/admin-config/dashboard-admin-config" class="home-panel-title" (click)="toggleLeftBar()"><span>{{"AdminConfig" | translate}}</span></a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
}

</mat-accordion>
</div>
</div>
