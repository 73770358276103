import { BlCellFormationProblemDataService } from './../../../../services/shared/bl-cell-formation-problem-data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';

@Component({
  selector: 'app-save-as-new-dialog',
  templateUrl: './save-as-new-dialog.component.html',
  styleUrls: ['./save-as-new-dialog.component.scss'],
  host: {'collision-id': 'CFPSaveAsNewComponent'}
})
export class SaveAsNewDialogComponent implements OnInit {

  constructor(
    private fb : UntypedFormBuilder,
    public dataService: BlCellFormationProblemDataService,
    public dialogRef: MatDialogRef<SaveAsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public form : UntypedFormGroup;
  ngOnInit(): void {
    this.form = this.fb.group({
      title:['',[Validators.required]]
    })

    ValidationFunctions.runValidation(this.form);
  }

  close(){
    this.dialogRef.close();
  }

  complete(){
    this.dialogRef.close(this.form.get("title").value);
  }


}
