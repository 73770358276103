import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEditModalData } from '../../../shared/interfaces/i-edit-modal-data';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

@Component({
  selector: 'app-three-d-container',
  templateUrl: './three-d-container.component.html',
  styleUrls: ['./three-d-container.component.scss']
})
export class ThreeDContainerComponent implements OnInit, OnChanges {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    protected matDialog: MatDialog
  ) { }

  @Input() items;
  @Input() container;

  private controls;
  private scene;
  private camera;
  private renderer;
  private containerOriginOffset = {
    x: 0,
    y: 0,
    z: 0
  };

  currentItemIndex = -1;

  ngOnInit(): void {
    this.initializeScene();
  }

  initializeScene(): void {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 3000); // 1. param => zoom/unzoom camera, 2. aspect ratio (uvek ok da bude width/heigth), 3. i 4. na kom zoom-u unzoom-u da nestaje objekat
    this.camera.lookAt(this.scene.position);
    this.renderer = new THREE.WebGLRenderer({
      alpha: true, // transparent background
      antialias: true // smooth edges
    });

    this.renderer.setClearColor(0xf0f0f0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // document.body.appendChild(this.renderer.domElement);
    document.getElementById("canvasBlock").appendChild(this.renderer.domElement)

    this.camera.position.set(this.container.x, this.container.x, this.container.x);

    // this.createContainer(15, 9, 13);
    this.createContainer(this.container.x, this.container.z, this.container.y);

    const that = this;
    var animate = () => {
      requestAnimationFrame(animate);
      that.controls.update();
      that.renderer.render(that.scene, that.camera);
    };

    animate();
  }

  createContainer(x: number, y: number, z: number): void {
    const boxGeometry = new THREE.BoxGeometry(x, y, z);
    const edgesGeometry = new THREE.EdgesGeometry(boxGeometry); // or WireframeGeometry(geometry)
    const containerMaterial = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 });
    const container = new THREE.LineSegments(edgesGeometry, containerMaterial);
    container.position.set(0, 0, 0);
    container.name = 'container';
    this.scene.add(container);

    this.containerOriginOffset.x = -1 * x / 2;
    this.containerOriginOffset.y = -1 * y / 2;
    this.containerOriginOffset.z = -1 * z / 2;
  }

  pack(): void {
    this.currentItemIndex++;
    const itemIndex = this.currentItemIndex;

    const itemOriginOffset = this.getItemOriginOffset(itemIndex);

    const itemMaterial = new THREE.MeshNormalMaterial({ transparent: true, opacity: 0.6 });
    const itemGeometry = new THREE.BoxGeometry(this.items[itemIndex].packDimX, this.items[itemIndex].packDimY, this.items[itemIndex].packDimZ);
    const cube = new THREE.Mesh(itemGeometry, itemMaterial);
    cube.position.set(this.containerOriginOffset.x + itemOriginOffset.x + this.items[itemIndex].coordX, this.containerOriginOffset.y + itemOriginOffset.y + this.items[itemIndex].coordY, this.containerOriginOffset.z + itemOriginOffset.z + this.items[itemIndex].coordZ);
    cube.name = 'package' + itemIndex;
    this.scene.add(cube);

    // this.makeIdLabel();
  };

  unpack(): void {
    const itemIndex = this.currentItemIndex;

    const selectedObject = this.scene.getObjectByName('package' + itemIndex);
    this.scene.remove(selectedObject);

    // this.makeIdLabel();
    this.currentItemIndex--;
  }

  // changeObjects(): void {
  //   this.currentItemIndex = 0;

  //   for(let itemIndex=0; itemIndex<this.items.length + 1; itemIndex++) {
  //     const selectedObject = this.scene.getObjectByName('package' + itemIndex);
  //     this.scene.remove(selectedObject);
  //   }

  //   for(let itemIndex=0; itemIndex<this.items.length; itemIndex++) {
  //     this.pack();
  //   }

  //   this.makeIdLabel();
  // }

  ngOnChanges(changes): void {
    if (changes['items'] && !changes['items'].isFirstChange()) {
      // this.changeObjects();
      if (changes['items']["currentValue"].length > changes['items']["previousValue"].length) {
        this.pack();
      }
      else {
        this.unpack();
      }
    }
  }

  makeIdLabel(itemIndex = null): void {
    const selectedObject = this.scene.getObjectByName('idLabel');
    this.scene.remove(selectedObject);

    if (this.items.length > 0) {
      itemIndex = itemIndex == null ? this.items.length - 1 : itemIndex;

      const itemOriginOffset = this.getItemOriginOffset(itemIndex);
      const spritey = this.makeTextSprite(this.items[itemIndex].id,
        { fontsize: 20, textColor: { r: 0, g: 0, b: 0, a: 1.0 } });
      // spritey.position.set(5,-5,-5);
      spritey.position.set(5+this.containerOriginOffset.x + itemOriginOffset.x + this.items[itemIndex].coordX, this.containerOriginOffset.y + itemOriginOffset.y + this.items[itemIndex].coordY, this.containerOriginOffset.z + itemOriginOffset.z + this.items[itemIndex].coordZ)
      spritey.name = "idLabel";
      this.scene.add(spritey);
    }
  }

  getItemOriginOffset(itemIndex: number) {
    return {
      x: this.items[itemIndex].packDimX / 2,
      y: this.items[itemIndex].packDimY / 2,
      z: this.items[itemIndex].packDimZ / 2
    };
  }

  makeTextSprite(message, parameters): any {
    if (parameters === undefined) parameters = {};
    const fontface = parameters.hasOwnProperty("fontface") ? parameters["fontface"] : "Courier New";
    const fontsize = parameters.hasOwnProperty("fontsize") ? parameters["fontsize"] : 18;
    const borderThickness = parameters.hasOwnProperty("borderThickness") ? parameters["borderThickness"] : 4;
    const borderColor = parameters.hasOwnProperty("borderColor") ? parameters["borderColor"] : { r: 0, g: 0, b: 0, a: 1.0 };
    const backgroundColor = parameters.hasOwnProperty("backgroundColor") ? parameters["backgroundColor"] : { r: 0, g: 0, b: 255, a: 1.0 };
    const textColor = parameters.hasOwnProperty("textColor") ? parameters["textColor"] : { r: 0, g: 0, b: 0, a: 1.0 };

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = "Bold " + fontsize + "px " + fontface;
    const metrics = context.measureText(message);
    const textWidth = metrics.width;

    context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," + backgroundColor.b + "," + backgroundColor.a + ")";
    context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + "," + borderColor.b + "," + borderColor.a + ")";
    context.fillStyle = "rgba(" + textColor.r + ", " + textColor.g + ", " + textColor.b + ", 1.0)";
    context.fillText(message, borderThickness, fontsize + borderThickness);

    const texture = new THREE.Texture(canvas)
    texture.needsUpdate = true;
    const spriteMaterial = new THREE.SpriteMaterial({ map: texture, useScreenCoordinates: false });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(0.5 * fontsize, 0.25 * fontsize, 0.75 * fontsize);
    // return texture;
    return sprite;
  }

}
