import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'rowClass'
})
export class RowClassPipe implements PipeTransform {

  transform(columns: ITableColumn[], row: any): any {
    let column = columns.find(x => typeof(x.rowClass) == 'function');
    if(column) {
      return column.rowClass(row);
    }
    return "";
  }

}
