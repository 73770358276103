export const apiPaths = {
    signalR: {
        connection: 'notificationHub'
    },
    auth: {
        login: "api/TokenMaster/auth",
        properties: "api/AccountMasters",
        modify: "api/Token/Modify",
        forgetPassword: "api/ForgetPassword",
        companySettings: "api/notificationHub",
        newPasswordActivation: "api/new-password",
        register: "api/userRegistration",
        registerActivation: "api/userActivation "
    },
    lanugages: "api/languages", // "/languages.json"
    country: "api/countries", // "/country.json",
    dashboardHome: "api/HomePage", // "/dashboardHome.json"
    changePassword: "api/change-password",
    notificationHub: "api/notificationHub",
    notification: "api/NotificationPage", // /notification.json
    downloadFile: "api/download-file",
    mrp: {
        deterministicMrp: "api/mrp/problems", // /detMrp.json
        bom: "api/mrp/problems/bom", ///bom-right-block.json
        mprReport: "api/mrp/problems/result" // /izvestajTabela.json
    },
    cms: {
        cellFormationProblem: "api/cfp/problems", // /cellFormationProblem.json
        // inputData: "/cellFormationProblem.json",
        brusco: "api/cfp/problems/brusco", // /cellFormationProblem.json
        similarity: "api/cfp/problems/similarity", // /cellFormationProblem.json
        downloadReportCSV: "api/cfp/problems/download",
        cfpReportCSV: "api/cfp/problems/upload",
        algorithmsList: "api/cfp/problems/algorithm",
        objectivesList: "api/cfp/problems/objectives",
        instancesList: "api/cfp/problems/instances",
        testCfp: "api/cfp/problems/test", //  /testQap.json
    },
    routing: {
        distribution1ToNSave: "api/saveDistribution1n", //"/distribution1ToN.json",
        distribution1ToNGet: "api/getDistribution1n", // "/distribution1ToN.json"
        distribution1ToNMapFiltering: "api/mapAdvancedSearch", // "/map-filtering.json",
        calculateDistribution1n: "api/fullCalculateDistribution1n", //"api/calculateDistribution1n", // "/calculate.json"
        adminDistribution1n: "api/adminSSSP",

        problemAlgorithms: "api/algorithms", // "api/problem-algorithms"
        geoLocationForward: "api/forwardGeocoding", // "/geo-location.json"
        geoLocationBackward: "api/reverseGeocoding", // "/geo-location.json"
        nodes: "api/nodes", // "/nodes.json",

        downloadMap: "api/downloadMap",
        countryRouting: "api/countries-for-routing", // "/country.json",
        mapByDistances: "api/mapIteration", //"/map-by-distances.json",

        // pointToPointSave: "api/savePointToPoint",
        // pointToPointGet: "api/getPointToPoint", // "/distribution1ToN.json"
        // pointToPointMapFiltering: "api/mapAdvancedSearch", // "/map-filtering.json",
        // calculatePointToPoint: "api/calculatePointToPoint", // "/calculate.json"
        // adminPointToPoint: "api/adminPTP",

        // tspSave: "api/saveTsp",
        // tspGet: "api/getTsp", // "/distribution1ToN.json"
        // tspMapFiltering: "api/mapAdvancedSearch", // "/map-filtering.json",
        // calculateTsp: "api/calculateTsp", // "/calculate.json"
        // adminTsp: "api/adminTSP",

        // mdcSave: "api/saveMdc",
        // mdcGet: "api/getMdc", // "/distribution1ToN.json"
        // mdcMapFiltering: "api/mapAdvancedSearch", // "/map-filtering.json",
        calculateMdc: "api/calculateMdc", // "/calculate.json"
        // adminMdc: "api/adminMDC",
        uploadDistribution1ToNCsv: "api/sssp/problems/upload",
        downloadRoutingCSV: "api/sssp/problems/download",
        nodesFiltering: "api/nodes-filtering"
    },
    order: {
        pfsp: "api/pfsp/problems", // /pfsp.json
        pfspReport: "api/pfsp/problems/results",
        sortPfspReport: "api/pfsp/problems/sorts",
        loadPfspReport: "api/pfsp/problems/load",
        algorithmsList: "api/pfsp/problems/algorithm",
        testPfsp: "api/pfsp/problems/test", // /testReportv2.json
        downloadReportCSV: "api/pfsp/problems/download",
        pfspReportCSV: "api/pfsp/problems/upload",
        pfspAll: "api/pfsp/problems/all",
        objectivesList: "api/pfsp/problems/objectives",
        //changeArrayNumbers: "/pfsp.json"
    },
    assignment: {
        qap: "api/qap/problems", // /qap.json
        qapAlgorithmn: "api/qap/problems/algorithm",
        qapInstances: "api/qap/problems/instances",
        testQap: "api/qap/problems/test", // /testQap.json
        qapReport: "api/qap/problems/run",
        qapReportCSV: "api/qap/problems/upload",
        downloadReportCSV: "api/qap/problems/download"
    },
    admin: {
        routingConfig: "api/admin-routing", //"/map-download.json",
        showAreaOnMap: "api/showMapArea" //"/show-area-on-map.json",
    },
    packing: {
        backpackProblem: "api/p3d/problems", // /backpack-problem.json
        backpackProblemAlgorithms: "api/p3d/problems/algorithm",
        backpackProblemReport: "api/p3d/problems/run",
        backpackProblemReportCSV: "api/p3d/problems/upload",
        downloadBackpackProblemReportCSV: "api/p3d/problems/download"
    },
};
