import { Injectable } from '@angular/core';
import { INotification } from '../../interfaces/i-notification';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { BlTableLogicService } from '../../../../shared/bussiness-logic/base/bl-table-logic.service';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ITableColumn } from '../../../../shared/interfaces/i-table-column';
import { ISetOfColumns } from '../../../../shared/interfaces/i-set-of-columns';

@Injectable({
  providedIn: 'root'
})
export class BlNotificationTableService extends BlTableLogicService<INotification> {

  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(translateService, tableFunctions, matDialog, location);
  }

  public idColumn = "";

  protected columns: ITableColumn[] = [
    {
      index: "id",
      title: "#",
      rowClass: (row) => {
        if(row.status == 0)
          return "boldRow";
        else if(row.status == 1)
          return "dontBoldRow";
      },
      hide: false
    },
    {
      index: "title",
      title: "Notifications",
      hide: false
    },
    {
      index: "status",
      title: "Status",
      hide: false
    },
  ];

  protected columnsStorage: BehaviorSubject<ISetOfColumns> = new BehaviorSubject<ISetOfColumns>({
    columns: this.columns,
    displayedColumns: this.getDisplayedColumns()
  });

  protected displayedColumns: string[] = this.columns.map(x => x.index);
}
