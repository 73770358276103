<div id="login-wrapper">
    <div id="login">

            <div class="title">
                <h3>{{"Choose Company" | translate}}</h3>
             </div>

            <div class="searchCompany">
                <mat-form-field appearance="outline">
                    <mat-label>{{"Search Company" | translate}}</mat-label>
                    <input matInput type="text" (keyup)="search($event)">
                </mat-form-field>
            </div>

            <div class="hotelList">
                <ul>
                    <ng-container *ngFor="let property of properites">
                        <li>
                            <a (click)="modify(property)">
                                <div class="listContent">
                                    <ng-container *ngIf="property.photo; else avatar">
                                        <img src="{{photoPath+property.photo}}" alt="logo">
                                    </ng-container>
                                    <ng-template #avatar>
                                        <img class="avatarImg" src="../../../../assets/images/default_avatar.jpg" alt="">
                                    </ng-template>

                                    <div class="listText">
                                        <p>{{ property.accountName }}</p>
                                        <span>Account ID: <b>{{ property.id }}</b></span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="this.properites.length == 0">
                        <li>
                            <div class="listContent">
                               {{"No companies found" | translate}}
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

    </div>

</div>
