import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { onlyIntegerValidator } from '../../../../shared/validators/only-integer.validator';
import { IGraphNodeNewForm } from '../../interfaces/i-graph-node';
import { BlGraphNodesDataService } from '../shared/bl-graph-nodes-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlNewGraphNodesFormService implements IFormService<IGraphNodeNewForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private dataService: BlGraphNodesDataService
  ) {}

  form: FormGroupTypeSafe<IGraphNodeNewForm> = this.init();
  isEdit: boolean = false;

  init(): FormGroupTypeSafe<IGraphNodeNewForm> {
    let group: FormGroupTypeSafe<IGraphNodeNewForm> = this.formBuilder.group<IGraphNodeNewForm>({
      title: this.formBuilder.control("", Validators.required),
      numberOfNodes: this.formBuilder.control("", [Validators.required, onlyIntegerValidator]),
    });
    return group;
  }

  getFormReference(): FormGroupTypeSafe<IGraphNodeNewForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }
  
}