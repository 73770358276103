import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { ReportsFunctions } from '../../../../../../../../shared/functions/reports-functions';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { IQapTestDropdownData, IQapTestForm } from '../../../../../../interfaces/i-qap';
import { BlQapTestFormService } from '../../../../../../services/forms/bl-qap-test-form.service';
import { BlQapRequestsService } from '../../../../../../services/requests/bl-qap-requests.service';
import { BlQapDataService } from '../../../../../../services/shared/bl-qap-data.service';
import { BlQapResultTableService } from '../../../../../../services/table/bl-qap-result-table.service';

@Component({
  selector: 'app-tab-dashboard-result',
  templateUrl: './tab-dashboard-result.component.html',
  styleUrls: ['./tab-dashboard-result.component.scss']
})
export class TabDashboardResultComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlQapTestFormService,
    private dataService: BlQapDataService,
    private requestService: BlQapRequestsService,
    public tableService: BlQapResultTableService,
    private alertService: AlertMessageDataService,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  public parametri: any = [];

  public dropdownData: IQapTestDropdownData = {
    Algorithms: null,
    Instances: null
  }

  private subscription: Subscription = new Subscription();

  public dataForTable: any = null;
  public dataFromStorage: any = null;
  public clickedButton: boolean = false;

  ngAfterViewInit(): void {
    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: (data) => {
          this.dataFromStorage = data;
        }
      })
    )
  }

  ngOnInit(): void {

    this.getAlgorithms();
    this.getInstances();
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestService.getAlgorithms().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Algorithms = data;
          }
        }
      })
    )
  }

  getInstances() {
    this.subscription.add(
      this.requestService.getInstances().subscribe({
        next: (data) => {
          if (data) {
            this.dropdownData.Instances = data;
          }
        }
      })
    )
  }

  runTest() {
    this.clickedButton = true;
    this.formService.isShowReportTable = false;

    let formValue: IQapTestForm = this.formService.form.value;
    let objForSend: any = {
      id: this.dataFromStorage? this.dataFromStorage.id: null,
      algorithm: formValue.algorithm,
      instances: formValue.instances,
      additionalAlgorithmData: this.dynamicAlgorithmFormControlsService.getEnteredAlgorithmParamsForTestRun(this.formService.form)
    };

    if (this.clickedButton) {
      SpinnerFunctions.showSpinner();
      this.dataForTable = null;
      this.subscription.add(
        this.requestService.testProblem(objForSend).subscribe({
          next: success => {
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
            
            this.dataForTable = success;
            // this.tableService.createTestDynamicReducedHeaderColumnsTable(this.dataForTable.arrayNumbers);
            this.alertService.setMessage("success", "Successfully.");
            this.formService.isShowReportTable = true;
          }, 
          error: error => {
            SpinnerFunctions.hideSpinner();
            this.clickedButton = false;
            this.requestService.getTestProblem().subscribe({
              next: (data) => {
                this.dataForTable =  data;
                // this.tableService.createTestDynamicReducedHeaderColumnsTable(this.dataForTable.arrayNumbers);
                this.alertService.setMessage("danger", "Error.");
                this.formService.isShowReportTable = true; //@obrisati
              }
            })
          }
        })
      )
    }
  }

  /**Print functions */
  printReport() {
    ReportsFunctions.printReport();
  }
  /**EndPrint functions */

  changeAlgorithmOptions(event): void {
    const item = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === event.value);
    if(item.parametri) {
      if(event._selected) {
        this.dynamicAlgorithmFormControlsService.addAlgorithmGroup(item, this.formService.form)
        this.parametri.push(item);
      }
      else {
        this.dynamicAlgorithmFormControlsService.removeAlgorithmGroup(item, this.formService.form);
        this.parametri = this.parametri.filter(x => x.id !== item.id);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataForTable = null;
    this.clickedButton = false;
    this.formService.reset();
  }

}
