import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardCellFormationProblemComponent } from './components/dashboard-cell-formation-problem/dashboard-cell-formation-problem.component';
import { RoleGuard } from '../../core/guards/role.guard';

const routes: Routes = [
  {
    path: "dashboard-cell-formation-problem",
    component: DashboardCellFormationProblemComponent,
    canActivate: [RoleGuard],
    data: {section: "cms", child: "dashboard-cell-formation-problem" }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CellFormationProblemRoutingModule { }
