import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IFormService } from '../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { IShowAreaOnMap, IShowAreaOnMapForm, IShowAreaOnMapRequest } from '../../interfaces/i-show-area-on-map';
import { BlShowAreaOnMapRequestsService } from '../requests/bl-show-area-on-map-requests.service';

@Injectable({
  providedIn: 'root'
})
export class BlShowAreaOnMapFormService implements IFormService<IShowAreaOnMapForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private matDialog: MatDialog,
    private requestsService: BlShowAreaOnMapRequestsService
  ) {}

  form: FormGroupTypeSafe<IShowAreaOnMapForm> = this.init();
  originalObj: IShowAreaOnMap[] = [];

  init(): FormGroupTypeSafe<IShowAreaOnMapForm> {
    return this.formBuilder.group<IShowAreaOnMapForm>({
      maps: this.formBuilder.control("", Validators.required)
    })
  }

  getFormReference(): FormGroupTypeSafe<IShowAreaOnMapForm> {
    return this.form;
  }

  fillForm(): void {}

  prepareDataToSend(): IShowAreaOnMapRequest {
    let formValue = this.form.getRawValue() as IShowAreaOnMapForm;

    let dataToSend: IShowAreaOnMapRequest = {
      maps: Number(formValue.maps)
    }

    return dataToSend;
  }

  getMap(): Observable<any> {
    const mapId: number = this.prepareDataToSend().maps;
    return this.requestsService.getMapData(mapId);
  }

  reset(): void {
    this.form = this.init();
  }

  close(): void {
		this.matDialog.closeAll();
  }

}
