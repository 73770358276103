import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BlPfspFormService } from '../../../../../../services/form/bl-pfsp-form.service';
import { BlPfspDataService } from '../../../../../../services/shared/bl-pfsp-data.service';

@Component({
  selector: 'app-tab-dashboard-input-data',
  templateUrl: './tab-dashboard-input-data.component.html',
  styleUrls: ['./tab-dashboard-input-data.component.scss']
})
export class TabDashboardInputDataComponent implements OnInit, AfterViewInit {

  constructor(
    private dataService: BlPfspDataService,
    public formService: BlPfspFormService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: data => {
        this.formService.isShowOtherTabs = data;
      }
    });

    this.dataService.storageDataIsRightBlockView.subscribe({
      next: data => {
        this.formService.isShowRightBlock = data;
      }
    });

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

}
