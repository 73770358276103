import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutSharedService } from '../layout-shared.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [
    trigger('showLeftBar', [
      state('true', style({ left: '0px' })),
      state('false', style({ left: '-250px' })),
      transition('false => true', animate('400ms ease-out')),
      transition('true => false', animate('400ms ease-in')),
    ]),
  ],
})
export class AppLayoutComponent implements OnInit {
  constructor(private sharedService: LayoutSharedService) {}

  private subscription = new Subscription();
  public isLeftSidebarOpen: boolean;

  ngOnInit() {
    this.trackIsLeftSidebarOpen();
  }

  trackIsLeftSidebarOpen() {
    this.subscription.add(
      this.sharedService.leftSidebarState.subscribe({
        next: (data: boolean) => (this.isLeftSidebarOpen = data)
      })
    );
  }

  toggleLeftBar() {
    this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
    this.sharedService.toggleLeftBar(this.isLeftSidebarOpen);
  }
}
