import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CellFormationProblemRoutingModule } from './cell-formation-problem-routing.module';
import { DashboardCellFormationProblemComponent } from './components/dashboard-cell-formation-problem/dashboard-cell-formation-problem.component';
import { HeaderDashboardCellFormationProblemComponent } from './components/dashboard-cell-formation-problem/components/header-dashboard-cell-formation-problem/header-dashboard-cell-formation-problem.component';
import { FormDashboardCellFormationProblemComponent } from './components/dashboard-cell-formation-problem/components/form-dashboard-cell-formation-problem/form-dashboard-cell-formation-problem.component';
import { TabsDashboardCellFormationProblemComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/tabs-dashboard-cell-formation-problem.component';
import { ViewDialogDashboardCellFormationProblemComponent } from './components/dashboard-cell-formation-problem/components/view-dialog-dashboard-cell-formation-problem/view-dialog-dashboard-cell-formation-problem.component';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { TabDashboardInputDataComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-input-data/tab-dashboard-input-data.component';
import { TabDashboardReductionsComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-reductions/tab-dashboard-reductions.component';
import { TabDashboardDissimilMComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-dissimil-m/tab-dashboard-dissimil-m.component';
import { TabDashboardDissimilPComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-dissimil-p/tab-dashboard-dissimil-p.component';
import { TabDashboardSimilarityMComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-similarity-m/tab-dashboard-similarity-m.component';
import { TabDashboardSimilarityPComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-similarity-p/tab-dashboard-similarity-p.component';
import { TabDashboardResultsComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-results/tab-dashboard-results.component';
import { TabDashboardInputDataLeftSideComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-input-data/components/tab-dashboard-input-data-left-side/tab-dashboard-input-data-left-side.component';
import { TabDashboardInputDataRightSideComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-input-data/components/tab-dashboard-input-data-right-side/tab-dashboard-input-data-right-side.component';
import { TabDashboardReductionsLeftSideComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-reductions/components/tab-dashboard-reductions-left-side/tab-dashboard-reductions-left-side.component';
import { TabDashboardReductionsRightSideComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-reductions/components/tab-dashboard-reductions-right-side/tab-dashboard-reductions-right-side.component';
import { TabDashboardResultsLeftSideComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-results/components/tab-dashboard-results-left-side/tab-dashboard-results-left-side.component';
import { TabDashboardResultsRightSideComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-results/components/tab-dashboard-results-right-side/tab-dashboard-results-right-side.component';
import { SaveAsNewDialogComponent } from './components/dashboard-cell-formation-problem/components/save-as-new-dialog/save-as-new-dialog.component';
import { TabDashboardTestResultsComponent } from './components/dashboard-cell-formation-problem/components/tabs-dashboard-cell-formation-problem/components/tab-dashboard-test-results/tab-dashboard-test-results.component';


@NgModule({
    declarations: [DashboardCellFormationProblemComponent, HeaderDashboardCellFormationProblemComponent, FormDashboardCellFormationProblemComponent, TabsDashboardCellFormationProblemComponent, ViewDialogDashboardCellFormationProblemComponent, TabDashboardInputDataComponent, TabDashboardReductionsComponent, TabDashboardDissimilMComponent, TabDashboardDissimilPComponent, TabDashboardSimilarityMComponent, TabDashboardSimilarityPComponent, TabDashboardResultsComponent, TabDashboardInputDataLeftSideComponent, TabDashboardInputDataRightSideComponent, TabDashboardReductionsLeftSideComponent, TabDashboardReductionsRightSideComponent, TabDashboardResultsLeftSideComponent, TabDashboardResultsRightSideComponent, SaveAsNewDialogComponent, TabDashboardTestResultsComponent],
    imports: [
        CommonModule,
        CellFormationProblemRoutingModule,
        SharedModule,
        CoreModule
    ]
})
export class CellFormationProblemModule { }
