import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { whitespaceValidator } from '../../../../shared/validators/whitespace.validator';
import { IDeterministicMrpForm } from '../../interfaces/i-deterministic-mrp';

@Injectable({
  providedIn: 'root'
})
export class BlDeterministicMrpFormService implements IFormService<IDeterministicMrpForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  form: FormGroupTypeSafe<IDeterministicMrpForm> = this.init();

  public isShowMainTabs: boolean = false;
  public isShowOtherTabs: boolean = false;
  public isFromViewDialog: boolean = false;
  public minValues: number = 1;
  public maxValues: number = 1;
  public numberRows: number = 0;
  public rowData: any = null;

  init(): FormGroupTypeSafe<IDeterministicMrpForm> {
    let group: FormGroupTypeSafe<IDeterministicMrpForm> = this.formBuilder.group<IDeterministicMrpForm>({
      title: this.formBuilder.control("", [Validators.required, whitespaceValidator]),
      noProductPartItems: this.formBuilder.control(1, [Validators.required, Validators.min(1)]),
      timeUnitPlanningPeriod: this.formBuilder.control("", [Validators.required]),
      noPlanningPerod: this.formBuilder.control(1, [Validators.required, Validators.min(1)]),
      noPeriodsYear: this.formBuilder.control(1, [Validators.required, Validators.min(this.minValues), Validators.max(this.maxValues)]),
      maxNoOfDirectComponent: this.formBuilder.control(1, [Validators.required, Validators.min(1)]),
      rows: this.formBuilder.array([])
    });

    ValidationFunctions.runValidation(group);
    return group;

  }

  getFormReference(): FormGroupTypeSafe<IDeterministicMrpForm> {
    return this.form;
  }

  setRows(data) {
    if (data) {
      this.form.removeControl("rows");
      this.form.addControl("rows", this.formBuilder.array([]));
      data.forEach((element, index) => {
        (this.form.getSafe(x => x.rows) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl(element.itemId, [Validators.required]),
            itemClass: new UntypedFormControl(element.itemClass),
            sourceCode: new UntypedFormControl(element.sourceCode),
            materialType: new UntypedFormControl(element.materialType),
            unitMeasure: new UntypedFormControl(element.unitMeasure),
            leadTime: new UntypedFormControl(element.leadTime),
            lotSize: new UntypedFormControl(element.lotSize),
            lotQuantity: new UntypedFormControl(element.lotQuantity),
            scrap: new UntypedFormControl(element.scrap),
            demand: new UntypedFormControl(element.demand),
            unitCost: new UntypedFormControl(element.unitCost),
            setupCost: new UntypedFormControl(element.setupCost),
            holdingCost: new UntypedFormControl(element.holdingCost),
            shortageCost: new UntypedFormControl(element.shortageCost),
            itemDescription: new UntypedFormControl(element.itemDescription),
            otherNote: new UntypedFormControl(element.otherNote)
          })
        )
      });
      this.form.get("rows").markAsTouched();
    }
  }

  createTableWithDynamicRows(numberRows: number) {
    this.removeTableDYnamicRows();
    if (numberRows) {
      for (let i = 0; i < numberRows; i++) {
        (this.form.getSafe(x => x.rows) as UntypedFormArray).push(
          new UntypedFormGroup({
            itemId: new UntypedFormControl("", [Validators.required]),
            itemClass: new UntypedFormControl(""),
            sourceCode: new UntypedFormControl(""),
            materialType: new UntypedFormControl(""),
            unitMeasure: new UntypedFormControl(""),
            leadTime: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            lotSize: new UntypedFormControl(""),
            lotQuantity: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            scrap: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            demand: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            unitCost: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            setupCost: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            holdingCost: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            shortageCost: new UntypedFormControl(0, [Validators.min(0), Validators.required]),
            itemDescription: new UntypedFormControl(""),
            otherNote: new UntypedFormControl("")
          })
        )
      }

      (this.form.get("rows") as UntypedFormArray).controls = [...(this.form.get("rows") as UntypedFormArray).controls];
      this.form.get("rows").markAsTouched();
    }
  }

  removeTableDYnamicRows() {
    while ((this.form.get("rows") as UntypedFormArray).length !== 0) {
      (this.form.get("rows") as UntypedFormArray).removeAt(0);
    }
  }

  setPeriodValidators(min: number, max: number): void {
    this.minValues = min;
    this.maxValues = max;
    this.form.getSafe(x => x.noPeriodsYear).setErrors({ 'min': this.minValues });
    this.form.getSafe(x => x.noPeriodsYear).setErrors({ 'max': this.maxValues });
    this.form.getSafe(x => x.noPeriodsYear).setValidators([Validators.min(this.minValues), Validators.max(this.maxValues)]);
    this.form.getSafe(x => x.noPeriodsYear).setValue(this.maxValues, { emitEvent: false });
  }

  reset(): void {
    this.form.get("title").setValue("");
    this.form.get("noProductPartItems").setValue(1);
    this.form.get("timeUnitPlanningPeriod").setValue("");
    this.form.get("noPlanningPerod").setValue(1);
    this.form.get("noPeriodsYear").setValue(1);
    this.form.get("maxNoOfDirectComponent").setValue(1);
    this.form.markAllAsTouched();
  }
}
