import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '../../../config/api';
import { ApiService } from '../../../shared/services/api.service';
import { INewPasswordActivation } from '../../interfaces/i-new-password-activation';

@Injectable({
  providedIn: 'root'
})
export class NewPasswordActivationService extends ApiService<INewPasswordActivation> {

  constructor(http: HttpClient) {
    super(apiPaths.auth.newPasswordActivation, http);
  }

}