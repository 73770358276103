<div class="row">
    <div class="col-md-12 section-content">
        <app-header-dashboard-qap>
        </app-header-dashboard-qap>
        <ng-container *ngIf="formServices.isShowMainTabs">
            <app-tabs-dashboard-qap>
            </app-tabs-dashboard-qap>
        </ng-container>
        <!-- <app-tables-dashboard-qap>
        </app-tables-dashboard-qap> -->
    </div>
</div>
