<form [formGroup]="formService.form" class="custom-form reduced-form tab-mps">

  <div class="row">
    <div class="col-md-12">
      <cdk-virtual-scroll-viewport style="width: 100%; height: calc(100vh - 250px); overflow-y: auto;"
        minBufferPx="200"
        maxBufferPx="400" [itemSize]="50">
        <div class="col-md-12">
          @if (formService.form.get('rowsTab3')['controls']?.length > 0) {
            <table class="table table-sm table-striped hide-subscript">
              <thead class="thead-dark">
                <tr>
                  <th>{{"Item ID" | translate}}</th>
                  <th>{{"Overdue Requirement" | translate}}</th>
                  @for (header of tableService.headersTable; track header) {
                    <th>{{header.title | translate}} &nbsp;{{header.index}}</th>
                  }
                </tr>
              </thead>
              <tbody>
                <ng-container *cdkVirtualFor="let item of formService.form.get('rowsTab3')['controls']; let i=index">
                  <tr>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>{{"Item ID" | translate}}</mat-label>
                        <input [formControl]="formService.form.get('rowsTab3')['controls'][i].get('itemId')"
                          matInput autocomplete="off" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-label>{{"Overdue Requirement" | translate}}</mat-label>
                          <input [formControl]="formService.form.get('rowsTab3')['controls'][i].get('requirement')"
                            matInput autocomplete="off" type="number" min=0 />
                          </mat-form-field>
                        </td>
                        @for (cols of numbers; track cols; let j = $index) {
                          <td>
                            <mat-form-field appearance="outline">
                              <input [formControl]="formService.form.get('rowsTab3')['controls'][i].get('c'+j)" matInput autocomplete="off" />
                            </mat-form-field>
                          </td>
                        }
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              } @else {
                <table class="table table-sm table-striped">
                  <thead class="thead-dark">
                    <tr>
                      @for (header of tableService.headersTable; track header) {
                        <th>{{header.title | translate}}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td [attr.colspan]="formService.colSpan" class="alert alert-warning">
                        {{'NoRecordsFound' | translate}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </form>
