import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { NotificationService } from '../../services/api/notification.service';
import { BlNotificationDataService } from '../../services/shared/bl-notification-data.service';
import { BlNotificationTableService } from '../../services/table/bl-notification-table.service';

@Component({
  selector: 'app-table-dashboard-notification',
  templateUrl: './table-dashboard-notification.component.html',
  styleUrls: ['./table-dashboard-notification.component.scss']
})
export class TableDashboardNotificationComponent extends BaseComponent implements OnInit {

  constructor(
    public apiService: NotificationService,
    protected location: Location,
    protected matDialog: MatDialog,
    public tableService: BlNotificationTableService,
    public dataService: BlNotificationDataService,
    protected tableFunctions: TableFunctions,
    private translateService: TranslateService,
    private alertService: AlertMessageDataService
  ) {
    super(tableFunctions, matDialog, location);
  }

  
  
}