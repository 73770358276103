import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { PropertyComponent } from './components/property/property.component';
import { NewPasswordActivationComponent } from './components/new-password-activation/new-password-activation.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterActivationComponent } from './components/register-activation/register-activation.component';

@NgModule({
  declarations: [LoginComponent, PropertyComponent, ForgetPasswordComponent, NewPasswordActivationComponent, RegisterComponent, RegisterActivationComponent],
  imports: [
    CommonModule,
    SharedModule,
    LoginRoutingModule
  ]
})
export class LoginModule { }
