import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ValidationFunctions } from '../../../../../../shared/functions/validation-functions';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IDistribution1ToNAlghoritmForm } from '../../../../interfaces/i-distribution-1-to-n-alghoritm';
import { BlDistribution1ToNAlghoritmFormService } from '../../../../services/forms/bl-distribution-1-to-n-alghoritm-form.service';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-calculate-algorithm-dashboard-distribution',
  templateUrl: './calculate-algorithm-dashboard-distribution.component.html',
  styleUrls: ['./calculate-algorithm-dashboard-distribution.component.scss']
})
export class CalculateAlgorithmDashboardDistributionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    private formService: BlDistribution1ToNAlghoritmFormService,
    private alertService: AlertMessageDataService,
    protected matDialog: MatDialog,
    public dataService: BlDistribution1ToNDataService,
    public leftSidebarFormService: BlLeftSidebarDistribution1ToNFormService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNAlghoritmForm>;

  isClickedSave = false;
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.formService.reset();
    this.form = this.formService.getFormReference();

    this.form.getSafe(x => x.routeType).setValue("f");

    if(this.dataService.problemAlgorithms && this.dataService.problemAlgorithms.length == 1) {
      this.form.getSafe(x => x.algorithm).setValue(this.dataService.problemAlgorithms[0].code);
    }
    ValidationFunctions.runValidation(this.form);
  }

  save(): void {
    this.isClickedSave = true;
    this.dataService.algorithm = this.formService.createAlgorithmObj();
    this.leftSidebarFormService.calculate();
    this.close();
  }

  close(): void {
    this.matDialog.closeAll();
  }

}
