import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BlDistribution1ToNDataService } from '../../../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-left-sidebar-dashboard-distribution-source',
  templateUrl: './left-sidebar-dashboard-distribution-source.component.html',
  styleUrls: ['./left-sidebar-dashboard-distribution-source.component.scss']
})
export class LeftSidebarDashboardDistributionSourceComponent implements OnInit {

  constructor(
    public dataService: BlDistribution1ToNDataService
  ) {}
  
  @Output() openSourceModal = new EventEmitter<any>();

  ngOnInit(): void {
  }

  openModal(): void {
    this.openSourceModal.emit({index: null});
  }

}
