import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { BlTableLogicService } from '../../../../shared/bussiness-logic/base/bl-table-logic.service';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { ISetOfColumns } from '../../../../shared/interfaces/i-set-of-columns';
import { ITableColumn } from '../../../../shared/interfaces/i-table-column';
import { IDistribution1ToN } from '../../interfaces/i-distribution-1-to-n';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNOverviewTableService extends BlTableLogicService<IDistribution1ToN>{

  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(translateService, tableFunctions, matDialog, location);
  }

  public idColumn = "";

  protected columns: ITableColumn[] = [
    {
      index: "longitude",
      title: "Longitude",
      class: "text-left",
      hide: false
    },
    {
      index: "latitude",
      title: "Latitude",
      class: "text-left",
      hide: false
    },
    {
      index: "quantity",
      title: "Quantity",
      class: "flex-72",
      hide: false
    },
    {
      index: 'isSource',
      title: "Is Source",
      class: "flex-72",
      template: {
        view: (params: any[]) => {
          if (params[0] == 0)
            return `<span class="material-icons custom-indeterminate-check-box">indeterminate_check_box</span>`;
          else if (params[0] == 1)
            return `<span class="material-icons custom-indeterminate-check-box">check_box</span>`;
        },
        params: ["isSource"]
      },
      hide: false,
    },
  ];

  protected columnsStorage: BehaviorSubject<ISetOfColumns> = new BehaviorSubject<ISetOfColumns>({
    columns: this.columns,
    displayedColumns: this.displayedColumns
  });

  protected displayedColumns: string[] =  this.columns.map(x => x.index);

}
