import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PropertiesService } from '../../../core/services/properties.service';
import { AuthService } from '../../../core/services/auth.service';
import { CompanySettingsService } from '../../../core/services/company-settings.service';
import { config } from '../../../config/global';
import { IProperty } from '../../../core/interfaces/i-property';
import { SpinnerFunctions } from '../../../shared/functions/spinner-functions';


@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {

  constructor(
    private propertyService: PropertiesService,
    private authService: AuthService,
    private companySettings: CompanySettingsService,
    private router: Router
  ) { }

  public _properties: any;
  public properites: any[] = [];
  public allProperties: any[];
  public photoPath: string = config.baseApiUrl.AWS_IMG;

  ngOnInit() {
    this._properties = this.propertyService.getAll();
    SpinnerFunctions.showSpinner();
    this._properties.subscribe({
      next: (data) => {
        this.properites = data;
        this.allProperties = data;
        SpinnerFunctions.hideSpinner();
      }
    })
  }

  modify(property: IProperty) {
    this.authService.modify(property).subscribe({
      next: (data) => {
        this.companySettings.getHotelSettings();
        this.router.navigateByUrl("/home");
      }
    });
  }

  search(event) {//provjeriti kad bude vise kompanija
    let input = event.target.value.toLocaleUpperCase();
    this.properites = this.allProperties.filter(x => {
      x.accountName = x.accountName ? x.accountName : "";
      return x.accountName.toLocaleUpperCase().indexOf(input) != -1 || x.id.toLocaleUpperCase().indexOf(input) != -1
    });
  }

}
