import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationFunctions } from '../../shared/functions/validation-functions';
import { FormBuilderTypeSafe } from '../../shared/helpers/reactive-forms-helper';

@Injectable({
  providedIn: 'root'
})
export class DynamicAlgorithmFormControlsService {

  constructor(
    private formBuilder: FormBuilderTypeSafe
  ) {}

  createAlgorithmParamsFormControls(items, form): void {
    form.removeControl("algorithmParams");
    form.addControl("algorithmParams", this.formBuilder.array([]));
    const formArray = new UntypedFormArray([]);
    Object.keys(items).forEach(item => {
      const value = items[item].trim();
      const group = new UntypedFormGroup({
        value: this.formBuilder.control(value, [Validators.required]),
        id: this.formBuilder.control(item)
      });
      formArray.push(group);
    });
    form.setControlSafe(x => x.algorithmParams, formArray);
    ValidationFunctions.runValidation(form);
  }

  getEnteredAlgorithmParams(form): any {
    const formValue = form.getRawValue();
    return formValue.algorithmParams.reduce(
      (obj, item) => Object.assign(obj, { [item.id]: item.value.toString() }), {}
    );
  }

  addAlgorithmGroup(item, form) {
    const formArrayForLastSelectedAlgorithmControls = new UntypedFormArray([]);
    Object.keys(item.parametri).forEach(x => {
      const value = item.parametri[x];
      const group = new UntypedFormGroup({
        value: this.formBuilder.control(value.trim(), [Validators.required]),
        id: this.formBuilder.control(x)
      });
      formArrayForLastSelectedAlgorithmControls.push(group);
    });

    const group = new UntypedFormGroup({
      values: formArrayForLastSelectedAlgorithmControls,
      algorithmId: this.formBuilder.control(item.id)
    });
    (form.get("algorithmParams") as UntypedFormArray).push(group);

    ValidationFunctions.runValidation(form);
  }

  removeAlgorithmGroup(item, form) {
    let x = (form.get("algorithmParams") as UntypedFormArray).controls.findIndex(x => x.value.algorithmId === item.id);
    (form.get("algorithmParams") as UntypedFormArray).removeAt(x)
  }

  getEnteredAlgorithmParamsForTestRun(form): any {
    const formValue = form.getRawValue();
    return formValue.algorithmParams.map(x => {
      let obj = Object.create({});
      obj.algorithmId = x.algorithmId
      obj.parametri = x.values.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.value.toString() }), {}
      )
      return obj;
    })
  }

  resetAlgorithmFormArray(form): void {
    form.removeControl("algorithmParams");
    form.addControl("algorithmParams", this.formBuilder.array([]));

    // while (form.get("algorithmParams").controls.length !== 0) {
    //   form.get("algorithmParams").removeAt(0)
    // }
  }

  // createAlgorithmParamsFormControls(items): void {
  //   this.form.removeControl("algorithmParams");
  //   this.form.addControl("algorithmParams", this.formBuilder.array([]));
  //   const formArray = new FormArray([]);
  //   items.forEach(item => {
  //       const formArray2 = new FormArray([]);
  //       Object.keys(item.parametri).forEach(x => {
  //         const value = item.parametri[x];
  //         const group = new FormGroup({
  //           value: this.formBuilder.control(value, [Validators.required]),
  //           id: this.formBuilder.control(x)
  //         });
  //         formArray2.push(group);
  //       });
  //       const group = new FormGroup({
  //         values: formArray2,
  //         algorithmId: this.formBuilder.control(item.id)
  //       });
  //       formArray.push(group);
  //   });
  //   this.form.setControlSafe(x => x.algorithmParams, formArray);
  //   ValidationFunctions.runValidation(this.form);
  // }

}
