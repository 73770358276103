import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsFunctions } from '../../../../../../shared/functions/reports-functions';
import { IEditModalData } from '../../../../../../shared/interfaces/i-edit-modal-data';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';

@Component({
  selector: 'app-reports-dashboard-distribution',
  templateUrl: './reports-dashboard-distribution.component.html',
  styleUrls: ['./reports-dashboard-distribution.component.scss']
})
export class ReportsDashboardDistributionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditModalData,
    protected matDialog: MatDialog,
    public dataService: BlDistribution1ToNDataService
  ) {}

  ngOnInit(): void {}

  printReport(): void {
    ReportsFunctions.printReport();
  }

  close(): void {
    this.matDialog.closeAll();
  }

}