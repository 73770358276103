import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../shared/interfaces/i-filter-column';
import { IShowAreaOnMap } from '../../interfaces/i-show-area-on-map';
import { ShowAreaOnMapService } from '../api/show-area-on-map.service';

@Injectable({
  providedIn: 'root'
})
export class BlShowAreaOnMapDataService extends BlDataLogicService implements ITableStorage<IShowAreaOnMap> {

  constructor(
    private apiService: ShowAreaOnMapService
  ) {
    super();
  }

  coordinatesForMainMap = null;

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  storage: BehaviorSubject<IShowAreaOnMap[]> = new BehaviorSubject<IShowAreaOnMap[]>([]);

  originalData: IShowAreaOnMap[];

  getOriginalData(): IShowAreaOnMap[] {
    return this.originalData;
  }

  setOriginalData(items: IShowAreaOnMap[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IShowAreaOnMap[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IShowAreaOnMap[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IShowAreaOnMap[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IShowAreaOnMap[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

}