import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';
import { IGeoLocationBackward, IGeoLocationBackwardRequest } from '../../interfaces/i-geo-location-backward';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationBackwardService extends ApiService<IGeoLocationBackward>{

  constructor(http: HttpClient) {
    super(apiPaths.routing.geoLocationBackward, http);
  }

  search(objectForSend: IGeoLocationBackwardRequest): Observable<IGeoLocationBackward[]> {

    return this.http.post<any>(`${this.baseUrl + apiPaths.routing.geoLocationBackward}`, objectForSend).pipe(
      map((items: any) => {
        let data = items.hasOwnProperty('data') ? items['data'] : items;
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

}
