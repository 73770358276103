import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageFunctions } from './shared/functions/local-storage-functions';
import { LocalStorageKeys } from './shared/enums/local-storage-keys';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CheckEvents } from './core/auto-logout/helpers/check-events';
import { AutoLogoutService } from './core/auto-logout/services/auto-logout.service';
import { CompanySettingsService } from './core/services/company-settings.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(
    private translate: TranslateService,
    private matDialog: MatDialog,
    private router: Router,
    private autoLogoutService: AutoLogoutService,
    private companySettings: CompanySettingsService,
    private authService: AuthService
  ) {
    this.setLanguage();
    // if(this.companySettings.getCurrentValue() == null && this.authService.isLoggedIn()) {
    //   this.companySettings.getHotelSettings();
    // }
  }

  public isVisibleLogout = false;

  ngOnInit(): void {
    this.autoLogoutService._startStopwatch.subscribe({
      next: (data) => {
        this.isVisibleLogout = data;
      }
    });
  }

  @HostListener('window:click', ['$event'])
  @HostListener('window:mouseover', ['$event'])
  @HostListener('window:scroll')
  @HostListener('window:popstate', ['$event'])
  onEvent($event = null) {
    if(localStorage.getItem("auth") == null && this.router.url.indexOf("forget-password") == -1 && this.router.url.indexOf("new-password-activation") == -1 && this.router.url.indexOf("register") == -1 && this.router.url.indexOf("register-activation") == -1) {
      this.matDialog.closeAll();
      if(this.router.url.indexOf('/login')==-1){
        this.router.navigateByUrl('/');
      }
    } else {
      CheckEvents.updateLastEventTime();
    }
  }

  private setLanguage(){
    this.translate.addLangs(['en', 'sr', 'sr-Cyrl']);
    const browserLang = this.translate.getBrowserLang();
    
    let language = browserLang.match(/en|sr|sr-Cyrl/) ? browserLang : 'en';

    this.translate.use(language);

    const lang = LocalStorageFunctions.get(LocalStorageKeys.lang);

    if(lang != null) {
      this.translate.use(lang)
    } else {
      this.translate.use('en');
    }
  }
}
