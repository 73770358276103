import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { QapService } from '../api/qap.service';
import { RunQapService } from '../api/run-qap.service';
import { QapAlgorithmsService } from '../api/qap-algorithms.service';
import { DownloadQapCsvService } from '../api/download-qap-csv.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../../../config/global';
import { apiPaths } from '../../../../config/api';
import { QapInstancesService } from '../api/qap-instances.service';
import { TestQapService } from '../api/test-qap.service';

@Injectable({
  providedIn: 'root'
})
export class BlQapRequestsService extends BlRequestService {

  constructor(
    private alertServices: AlertMessageDataService,
    private apiService: QapService,
    private changeArrayNumbersService: RunQapService,
    private algorithmService: QapAlgorithmsService,
    private instancesService: QapInstancesService,
    private testService: TestQapService,
    private downloadCsvService: DownloadQapCsvService,
    private http: HttpClient
  ) {
    super(alertServices);
  }

  saveData(dataForSend: any) {
    // update but with post http method
    return this.apiService.create(dataForSend);
  }

  insertData(dataForSend: any) {
    return this.apiService.create(dataForSend);
  }

  getAllByType(type: string) {
    return this.apiService.getWithParams({ problemType: type });
  }

  getQAP(id: number) {
    return this.apiService.get(id);
  }

  getQAPs() {
    return this.apiService.getAll();//ovu metodu koristim za test @obrisati
  }

  runProblem(dataForSend: any) {
    return this.changeArrayNumbersService.create(dataForSend);
  }

  getAlgorithms() {
    return this.algorithmService.getAll();
  }

  getInstances() {
    return this.instancesService.getAll();
  }

  testProblem(dataForSend: any) {
    return this.testService.create(dataForSend);
  }

  getTestProblem() {
    return this.testService.getAll();
  } //@obrisati
}
