import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormArray } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { IDeterministicMrpDropDownData, IDeterministicMrpForm } from '../../../../interfaces/i-deterministic-mrp';
import { BlDeterministicMrpFormService } from '../../../../services/form/bl-deterministic-mrp-form.service';
import { BlDeterministicMrpDataService } from '../../../../services/shared/bl-deterministic-mrp-data.service';
import { PLANNING_PERIODS } from '../../../../../../config/settings/mrp/planningPeriods';
import { LayoutSharedService } from '../../../../../../layout/layout-shared.service';

@Component({
  selector: 'app-form-dashboard-deterministic-mrp',
  templateUrl: './form-dashboard-deterministic-mrp.component.html',
  styleUrls: ['./form-dashboard-deterministic-mrp.component.scss']
})
export class FormDashboardDeterministicMrpComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: BlDeterministicMrpFormService,
    private matDialog: MatDialog,
    private ref : MatDialogRef<FormDashboardDeterministicMrpComponent>,
    private alertService: AlertMessageDataService,
    private dataService: BlDeterministicMrpDataService,
    private sharedService: LayoutSharedService
  ) { }

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IDeterministicMrpForm> = this.formService.getFormReference();

  public dropdownData: IDeterministicMrpDropDownData = {
    LotSizes: null,
    MaterialTypes: null,
    SourceCodes: null,
    PlanningPeriods: null
  }

  public isBackgroundImage: boolean;

  ngOnInit(): void {
    this.dropdownData.PlanningPeriods = PLANNING_PERIODS;
    this.trackIsBackgroundImage();
  }

  onSelectOption(event: any) {
    if (event.value == "Day") {
      this.formService.setPeriodValidators(1, 365);
    } else if (event.value == "Week") {
      this.formService.setPeriodValidators(1, 52);
    } else if (event.value == "Month") {
      this.formService.setPeriodValidators(1, 12);
    } else if (event.value == "Quater") {
      this.formService.setPeriodValidators(1, 4);
    }
  }

  trackIsBackgroundImage() {
    this.subscription.add(
      this.sharedService.backgroundImageState.subscribe({
        next: (data: boolean) => (this.isBackgroundImage = data)
      })
    );
  }

  changeBackgroundImage() {
    this.sharedService.backgroundImageState.next(false);
  }

  save() {
    this.dataService.requestedResults = false;
    let formValue: any = this.formService.form.value;

    this.subscription.add(
      this.dataService.storageDataFromFormDialog.next(formValue)
    );

    this.subscription.add(
      this.dataService.storageDataNewForm.next(formValue)
    );

    this.subscription.add(
      this.dataService.storageIsTabsView.next(true)
    );

    this.subscription.add(
      this.dataService.storageDataisFromViewDialog.next(false)
    );

    this.subscription.add(
      this.dataService.storageDataIsShowOtherTabs.next(false)
    );

    this.alertService.setMessage("success", "Successfully saved.");
    this.changeBackgroundImage();

    this.dataService.currentTitle = this.form.get("title").value

    this.close(this.form.get("title").value);

  }

  checkIfFormIsValid(){
    let title = this.formService.form.get("title").valid;
    let noProductPartItems = this.formService.form.get("noProductPartItems").valid;
    let timeUnitPlanningPeriod = this.formService.form.get("timeUnitPlanningPeriod").valid;
    let noPlanningPerod = this.formService.form.get("noPlanningPerod").valid;
    let noPeriodsYear = this.formService.form.get("noPeriodsYear").valid;
    let maxNoOfDirectComponent = this.formService.form.get("maxNoOfDirectComponent").valid;

    return title && noProductPartItems && timeUnitPlanningPeriod && noPlanningPerod && noPeriodsYear && maxNoOfDirectComponent;
  }

  close(state?:boolean) {
    this.ref.close(state);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.minValues = 1;
    this.formService.maxValues = 1;
    this.formService.reset();

  }
}
