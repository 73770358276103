@if (options && (setup.isAllowedOptionsEmptyArray? true: (isOptionsCame))) {
  <mat-form-field appearance="outline" [ngClass]="(formControlToFill.invalid)? 'requiredField': false">
    <mat-label>{{ labelText | translate}}</mat-label>
    <mat-select multiple (openedChange)="openedChange($event)" class="virtual-scroll" [(ngModel)]="selected" [formControl]="formControlToFill"
      disableOptionCentering>
      <mat-select-search [formControl]="filterControl"></mat-select-search>
      <cdk-virtual-scroll-viewport class="example-viewport-select" minBufferPx="200" maxBufferPx="400"
        [itemSize]="36" [style.height.px]="options.length > 5 ? 6 * 36 : (options.length + 1) * 36">
        @if (options.length > 0) {
          <mat-option [value]="selectAllValue" #allSelect (click)="selectAll(allSelect.selected)">
            {{"SelectAll" | translate}}
          </mat-option>
        }
        <mat-option *cdkVirtualFor="let option of options" [value]="option.id" [disabled]="setup.isDisabledAllOptions" (onSelectionChange)="onSelection($event)" >
          @if (!option.isNotFound) {
            <span>
              {{option.title}}
            </span>
          } @else {
            <span >
              {{option.title | translate}}
              <a (mouseover)="showDeletedOptionInfo(option.id)" class="deleted-link-cursor" [ngbPopover]="popContent" popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom" triggers="mouseenter:mouseleave" container="body">
                #{{ option.id }}
              </a>
              <ng-template #popContent>
                @if (deletedOptionInfo && deletedOptionStatus) {
                  <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{ deletedOptionStatus | translate }}</p>
                  <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
                }
              </ng-template>
            </span>
          }
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-select>
    @if (formControlToFill.hasError('required')) {
      <mat-error class="customError">
        {{'ThisFieldIsRequired' | translate}}
      </mat-error>
    }
  </mat-form-field>
} @else {
  <app-input-with-spinner inputLabel="{{labelText}}"></app-input-with-spinner>
}
