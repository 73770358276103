<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 formHeading">
      <h3 class="modalHeading">
        {{"GraphNodes" | translate}}
      </h3>
    </div>
  </div>

  <div class="row reducedForm">
    <div class="col-sm-4">
      <mat-form-field color="accent" appearance="outline">
        <mat-label>{{"Problem Types" | translate}}</mat-label>
        <mat-select (selectionChange)="changeProblemType($event)">
          @for (problem of problemTypes; track problem) {
            <mat-option [value]="problem.type">{{problem.title | translate}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  @if (isProblemSelected) {
    <div class="row">
      <div class="col-sm-4 searchBox noLeftPadding">
        <app-search class="reducedForm" [dataService]="dataService"></app-search>
      </div>
      <div class="col-sm-8 products iconBox">
        <app-show-hide-columns class="reducedButton" [tableService]="tableService"></app-show-hide-columns>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 table-box">
        <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService">
        </app-table>
      </div>
    </div>
  }

</div>
