import { Component, OnInit } from '@angular/core';
import { BlNewPasswordActivationFormService } from '../../services/forms/bl-new-password-activation-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { INewPasswordActivationForm } from '../../interfaces/i-new-password-activation';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { FormGroupTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { SpinnerFunctions } from '../../../shared/functions/spinner-functions';

@Component({
  selector: 'app-new-password-activation',
  templateUrl: './new-password-activation.component.html',
  styleUrls: ['./new-password-activation.component.scss']
})
export class NewPasswordActivationComponent implements OnInit {

  constructor(
    public formService: BlNewPasswordActivationFormService,
    public activatedRoute: ActivatedRoute,
    private location: Location,
    private alertService: AlertMessageDataService,
    private router: Router
  ) {}

  public form: FormGroupTypeSafe<INewPasswordActivationForm>;

  ngOnInit() {
    this.form = this.formService.getFormReference();

    this.activatedRoute.queryParams.subscribe({
      next: (params) => {
        const q = params['q'];
        this.formService.q = q;
        if (typeof (q) === 'undefined') {
          this.alertService.setMessage("danger", "Invalid url");
          this.form.disable();
        }
      }
    });
  }

  setConfirmPassword(): void {
    this.formService.passwordValidation();
  }

  passStrengthChanged(strength): void {
    this.formService.passStrengthChanged(strength);
  }

  changeCurrentPass(): void {
    this.formService.changeCurrentPass();
  }

  save(): void {
    SpinnerFunctions.showSpinner();

    this.formService.submitInsert().subscribe({
      next: success => {
        SpinnerFunctions.hideSpinner();
        this.router.navigateByUrl('/');
      },
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    })
  }

}
