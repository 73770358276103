<div id="pms-global-spinner">
    <div class="spinner-container">
        <h3>{{"Loading" | translate}}</h3>
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</div>
