<div class="row dashboard-content source-destination">
  <div class="col-md-12 section-content">
    <app-header-backpack-problem></app-header-backpack-problem>
    @if (dataService.showDashboard) {
      <div class="row" >
        <div class="col-md-12">
          <mat-tab-group dynamicHeight="true" class="custom-tab-group"
            [selectedIndex]="dataService.selectedIndex">
            <mat-tab label="{{'3DPacking' | translate}}">
              <app-tables-backpack-problem>
              </app-tables-backpack-problem>
            </mat-tab>
            @if (dataService.show3dTable) {
              <mat-tab label="{{'Results' | translate}}" >
                <app-three-d-table-backpack-problem></app-three-d-table-backpack-problem>
              </mat-tab>
            }
          </mat-tab-group>
        </div>
      </div>
    }
  </div>
</div>
