import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BlPfspFormService } from '../../services/form/bl-pfsp-form.service';
import { BlPfspDataService } from '../../services/shared/bl-pfsp-data.service';

@Component({
  selector: 'app-dashboard-pfsp',
  templateUrl: './dashboard-pfsp.component.html',
  styleUrls: ['./dashboard-pfsp.component.scss']
})
export class DashboardPfspComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private dataService: BlPfspDataService,
    public formServices: BlPfspFormService
  ) { }

  ngAfterViewInit(): void {
    this.dataService.storageIsTabsView.subscribe({
      next: data => {
        this.formServices.isShowMainTabs = data;
      }
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.dataService.storageIsTabsView.next(false);
    this.dataService.storageDataIsShowOtherTabs.next(false);
    this.dataService.storageIsRunResults.next(false);

    this.dataService.storageDataResults.next(null);
    this.dataService.originalObject.next(null);
  }
  
}
