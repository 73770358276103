import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { apiPaths } from '../../../../../../config/api';
import { ConfirmDialogComponent } from '../../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { MarkdownService } from '../../../../../../shared/services/markdown.service';
import { FileType } from '../../../../../../shared/enums/file-type';
import { SpinnerFunctions } from '../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../shared/services/alert-message-data.service';
import { BlReportsService } from '../../../../../../shared/services/bl-reports.service';
import { FileDownloadService } from '../../../../../../shared/services/file-download.service';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { routingProblems } from '../../../../../routing-common/constants/routing-problems';
import { DownloadMapService } from '../../../../services/api/download-map.service';
import { BlLeftSidebarDistribution1ToNFormService } from '../../../../services/forms/bl-left-sidebar-distribution-1-to-n-form.service';
import { BlDistribution1ToNRequestsService } from '../../../../services/requests/bl-distribution-1-to-n-requests.service';
import { BlDistribution1ToNDataService } from '../../../../services/shared/bl-distribution-1-to-n-data.service';
import { AdminDashboardDistributionComponent } from '../admin-dashboard-distribution/admin-dashboard-distribution.component';
import { CalculateAlgorithmDashboardDistributionComponent } from '../calculate-algorithm-dashboard-distribution/calculate-algorithm-dashboard-distribution.component';
import { NewDashboardDistributionComponent } from '../new-dashboard-distribution/new-dashboard-distribution.component';
import { OverviewDashboardDistributionComponent } from '../overview-dashboard-distribution/overview-dashboard-distribution.component';
import { ReportsDashboardDistributionComponent } from '../reports-dashboard-distribution/reports-dashboard-distribution.component';
import { RouteSettingsDashboardDistributionComponent } from '../route-settings-dashboard-distribution/route-settings-dashboard-distribution.component';
import { SaveAsNewDashboardDistributionComponent } from '../save-as-new-dashboard-distribution/save-as-new-dashboard-distribution.component';
import { ViewDialogDashboardDistributionComponent } from './../view-dialog-dashboard-distribution/view-dialog-dashboard-distribution.component';

@Component({
  selector: 'app-header-dashboard-distribution',
  templateUrl: './header-dashboard-distribution.component.html',
  styleUrls: ['./header-dashboard-distribution.component.scss']
})
export class HeaderDashboardDistributionComponent implements OnInit, OnDestroy {

  constructor(
    public matDialog: MatDialog,
    public dataService: BlDistribution1ToNDataService,
    public requestsService: BlDistribution1ToNRequestsService,
    public leftSidebarFormService: BlLeftSidebarDistribution1ToNFormService,
    private alertService: AlertMessageDataService,
    private translateService: TranslateService,
    public permissionsService: PermissionsService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private downloadMapService: DownloadMapService,
    private sanitizer: DomSanitizer,
    private reportService: BlReportsService,
    private markdownService: MarkdownService,
    private fileDownloadService: FileDownloadService
  ) {}

  private subscription: Subscription = new Subscription();
  problems = routingProblems;
  currentProblem = "";
  canRun: boolean = false;

  public page: string;

  private confirmDialog = {
    component: ConfirmDialogComponent,
    specification: {
      width: "370px",
      height: "auto",
      minHeight: '150px',
      panelClass: "custom-dialog",
      data: { message: "Error" }
    }
  };

  public safeData: any;
  public safeData1: any;
  public unsafeHTML: string;
  public unsafeHTML1: string;

  ngOnInit(): void {
    this.trackCanRun();
    this.checkUrl();
  }

  trackCanRun(): void {
    this.subscription.add(this.dataService.canRunBS.subscribe({
      next: data => {
        this.canRun = data;
      }
    }));
  }

  checkUrl(){
      let pageUrl = this.router.url;
      let pageArr = ['ptp', 'sssp', 'tsp', 'cvrp']

      pageArr.forEach(item => {
        if(pageUrl.indexOf(item) !== -1){
          this.page = item;
        }
      })

      switch (this.page) {
        case 'ptp':
          this.unsafeHTML = "assets/markdown/routing/point-to-point/help.html";
          this.unsafeHTML1 = "assets/markdown/routing/point-to-point/help1.html";
          break;
        case 'sssp':
          this.unsafeHTML = "assets/markdown/routing/single-source-sp/help.html";
          this.unsafeHTML1 = "assets/markdown/routing/single-source-sp/help1.html";
          break;
        case 'tsp':
          this.unsafeHTML = "assets/markdown/routing/tsp/help.html";
          this.unsafeHTML1 = "assets/markdown/routing/tsp/help1.html";
          break;
        case 'cvrp':
          this.unsafeHTML = "assets/markdown/routing/capacitated-vehicle-routing-problem/help.html";
          this.unsafeHTML1 = "assets/markdown/routing/capacitated-vehicle-routing-problem/help1.html";
          break;
      }

      this.safeData = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML);
      this.safeData1 = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeHTML1);

  }

  openMarkdown() {
    this.markdownService.openMarkdown(this.safeData1);
  }

  save(): void {
    if(this.checkIfGraph()) {
      if(this.checkIfGraphIsValid()) {
        this.createOrUpdateDistribution();
      }
      else {
        this.setErrorAlertMessage();
      }
    }
    else {
      this.leftSidebarFormService.setValidatorsForName();

      if (this.leftSidebarFormService.form.invalid) {
        this.alertService.setMessage("danger", this.translateService.instant("TitleIsRequired"));
      }
      else if (!this.leftSidebarFormService.checkIfDestinationsAreSelected() || !this.leftSidebarFormService.checkIfSourceIsSelected() || !this.leftSidebarFormService.checkIfTrucksNumberCorrect()) {
        this.setErrorAlertMessage();
      }
      else {
        this.createOrUpdateDistribution();
      }
    }

  }

  createOrUpdateDistribution(): void {
    SpinnerFunctions.showSpinner();
    if (this.dataService.isEdit) {
      this.updateDistribution();
    } else {
      this.createNewDistribution();
    }
  }

  saveAsNew(): void {
    if (this.isValidData()) {

      this.matDialog.open(SaveAsNewDashboardDistributionComponent, {
        width: '90%',
        maxWidth: '400px',
        height: "auto",
        minHeight: "100px",
        panelClass: "custom-dialog",
        data: {
          title: this.leftSidebarFormService.form.getSafe(x => x.name)
        }
      }).afterClosed().subscribe({
        next: val => {

          if (val) {
            if (this.isValidData()) {
              SpinnerFunctions.showSpinner();
              this.createNewDistribution(val);
            }
            else {
              this.setErrorAlertMessage();
            }
          }
        }
      });
    }
    else {
        this.setErrorAlertMessage();
    }
  }

  changeToEdit(data): void {
    this.dataService.isCustom = true;
    this.dataService.isEdit = true;
    this.leftSidebarFormService.objToPatch = data;
    this.leftSidebarFormService.originalObj = cloneDeep(data);
    this.leftSidebarFormService.objToPatch["id"] = data.id;
    this.dataService.showTabs = true;
    this.leftSidebarFormService.fillForm();
    this.dataService.canRunBS.next(true);
    this.dataService.currentIdForRun = data.id;
  }

  viewDistribution1ToN(): void {
    const dialogRef = this.matDialog.open(ViewDialogDashboardDistributionComponent, {
      width: '90%',
      maxWidth: '500px',
      height: "auto",
      minHeight: "180px",
      panelClass: ["custom-dialog", "no-actions-dialog"],
    });
  }

  createNewDistribution(newName: string = null): void {
    this.subscription.add(
      this.leftSidebarFormService.submitInsert(newName).subscribe({
        next: success => {
          if(success.poruka != null && success.poruka != "") {
            SpinnerFunctions.hideSpinner();
            this.alertService.setMessage("danger", success.poruka);
          }
          else {
            this.checkIfMapIsDownloaded(success, "insert");
            SpinnerFunctions.hideSpinner();
          }
        },
        error: error => SpinnerFunctions.hideSpinner()
      })
    )
  }

  updateDistribution(): void {
    this.subscription.add(
      this.leftSidebarFormService.submitUpdate().subscribe({
        next: success => {
          if(success.poruka != null && success.poruka != "") {
            SpinnerFunctions.hideSpinner();
            this.alertService.setMessage("danger", success.poruka);
          }
          else {
            this.checkIfMapIsDownloaded(success, "update");
            SpinnerFunctions.hideSpinner()
          }
        },
        error: error => SpinnerFunctions.hideSpinner()
      })
    );
  }

  checkIfMapIsDownloaded(data, insertOrUpdate: string) {
    if(!this.isNumeric(data.userMessageType)) {
      this.alertService.setMessage("danger", data.userMessageType);
      return false;
    }
    if(data.userMessageType == "1") {
      this.confirmDialog.specification.data = {
        message: this.translateService.instant("DoYouWantToDownloadMapFirstPart") + data.contryName + this.translateService.instant("DoYouWantToDownloadMapSecondPart")
      };
      this.matDialog.open(this.confirmDialog.component, this.confirmDialog.specification).afterClosed().subscribe({
        next: value => {
          if (value) {
            this.downloadMapService.downloadMap({
              countryName: data.contryName
            }).subscribe({next: data => {}, error: error => {}});
            this.alertService.setMessage("success", this.translateService.instant("TheMapIsDownloadingPleaseWait"));

          }
        }
      });
      return false;
    }
    else if(data.userMessageType == "-1") {
      this.alertService.setMessage("danger", this.translateService.instant("TheEnteredCoordinatesDoNotBelongToTheSameCountry"));
      return false;
    }
    else if(data.userMessageType == "-2") {
      this.alertService.setMessage("danger", this.translateService.instant("TheCountryMapIsCurrentlyUnavailable"));
      return false;
    }
    else {
      const msg = insertOrUpdate == "insert" ? "SuccessInsert" : "SuccessUpdate";
      this.alertService.setMessage("success", this.translateService.instant(msg));
      this.changeToEdit(data);
    }
  }

  openAdminModal(): void {
    const dialogRef = this.matDialog.open(AdminDashboardDistributionComponent, {
      width: '80%',
      maxWidth: '400px',
      height: "auto",
      minHeight: "120px",
      panelClass: "custom-dialog"
    });
  }

  openSettingsModal(): void {
    const dialogRef = this.matDialog.open(RouteSettingsDashboardDistributionComponent, {
      width: '90%',
      maxWidth: '750px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    });
  }

  openResultsModal(): void {
    const dialogRef = this.matDialog.open(ReportsDashboardDistributionComponent, {
      width: '90%',
      maxWidth: '750px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    });
  }

  openModalForNew(): void {
    const dialogRef = this.matDialog.open(NewDashboardDistributionComponent, {
      width: '90%',
      maxWidth: '600px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    });
  }

  openModalBeforeCalculate(): void {
    if (this.isValidData()) {
      const dialogRef = this.matDialog.open(CalculateAlgorithmDashboardDistributionComponent, {
        width: '80%',
        maxWidth: '400px',
        height: "auto",
        minHeight: "100px",
        panelClass: "custom-dialog"
      });
    }
    else {
      this.setErrorAlertMessage();
    }
  }

  isValidData(): boolean {
    if(this.checkIfGraph()) {
      return this.checkIfGraphIsValid();
    }

    return this.leftSidebarFormService.checkIfDestinationsAreSelected() && this.leftSidebarFormService.checkIfSourceIsSelected() && this.leftSidebarFormService.checkIfTrucksNumberCorrect() && this.leftSidebarFormService.checkDestinationsQuantity();
  }

  setErrorAlertMessage(): void {
    if(this.checkIfGraph()) {
      this.setErrorMessageForGraph();
    }
    else {
      const errStr = this.dataService.problemType.shortcut != "tsp" ? "ChooseSourceAndDestination" : "ChooseSourceAnd3Destinations";

      if(!this.leftSidebarFormService.checkIfDestinationsAreSelected() || !this.leftSidebarFormService.checkIfSourceIsSelected()) {
        this.alertService.setMessage("danger", this.translateService.instant(errStr));
      }

      if(!this.leftSidebarFormService.checkIfTrucksNumberCorrect()) {
        this.alertService.setMessage("danger", this.translateService.instant("TotalNumberOfTrucksIsInvalid"));
      }

      if(!this.leftSidebarFormService.checkDestinationsQuantity()) {
        this.alertService.setMessage("danger", this.translateService.instant("QuantityMustBeAnInteger"));
      }
    }
  }

  redirectToAnotherProblem(event): void {
    const problem = event.value;
    this.router.navigateByUrl('/routing/' + problem);
  }

  openOverviewModal(): void {
    const dialogRef = this.matDialog.open(OverviewDashboardDistributionComponent, {
      width: '90%',
      maxWidth: '550px',
      height: "auto",
      minHeight: "100px",
      panelClass: "custom-dialog"
    });
  }

  isRunDisabled(): boolean {
    if(this.dataService.typeOfMap == 'graph')
      return !this.checkIfGraphIsValid() || !this.dataService.isEdit;

    let x = this.leftSidebarFormService.originalObj;
    let y = {
      source: this.dataService.source,
      destinations: this.dataService.allDestinations,
      settings: this.dataService.settingsObj,
      trucks: this.dataService.trucks,
      destinationsQuantity: this.dataService.destinationsQuantity,
      typeOfMap: this.dataService.typeOfMap
    }

    if(typeof(x) == "undefined" || typeof(y) == "undefined")
      return true;

    let x1 = {
      destinations: x.destinations,
      destinationsQuantity: x.destinationsQuantity,
      trucks: x.trucks,
      source: x.source,
      typeOfMap: x.typeOfMap
    }
    let y1 = {
      destinations: y.destinations,
      destinationsQuantity: y.destinationsQuantity,
      trucks: y.trucks,
      source: y.source,
      typeOfMap: y.typeOfMap
    }

    return JSON.stringify(x1) !== JSON.stringify(y1) || !this.canRun;
  }

  export() {
    this.fileDownloadService.export(this.dataService.currentIdForRun, apiPaths.routing.downloadRoutingCSV, this.dataService.problemType.title + "_", FileType.csv)
  }

  checkIfGraphIsValid(): boolean {
    return this.leftSidebarFormService.checkIfControlsForGraphAreValid();
  }

  checkIfGraph(): boolean {
    return this.dataService.typeOfMap === "graph";
  }

  setErrorMessageForGraph(): void {
    let errStr = "";
    errStr = "GraphInvalidMessage";
    if(this.dataService.problemType.shortcut == 'ptp')
      errStr = "GraphInvalidMessagePtp";

    this.alertService.setMessage("danger", this.translateService.instant(errStr));
  }

  isNumeric(str): boolean {
    return !isNaN(str) && !isNaN(parseFloat(str))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
