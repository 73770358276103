<!-- Header Dialog-->
<div mat-dialog-title>
    <div class="row">
      <div class="col-sm-12 closeIconBox">
        <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
      </div>
    </div>
  </div>
  <!-- End Header Dialog-->
  <!-- Form Dialog-->
  <form class="reducedForm" [formGroup]="formService.formConfig" (ngSubmit)="save()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12 formHeading">
          <h3 class="modalHeading">
            {{"Add" | translate}}&nbsp; {{"3DPacking" | translate}}
          </h3>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 input-methods">
          <label class="sub-title">{{"Input Methods" | translate}}</label>
          <mat-radio-group [formControlName]="formConfig.keys.inputMethod">
            <mat-radio-button [value]="data.id" *ngFor="let data of dropdownData.Methods">{{data.title | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row" [ngClass]="!formService.isImportMethod ? 'showDiv' : 'hideDiv'">
          <!-- Title Control -->
          <div class="col-sm-12 row-col-title">
            <mat-form-field color="accent" appearance="outline" [ngClass]="formService.formConfig.get('title').errors ? 'requiredField' : ''">
              <mat-label>{{"Problem Title" | translate}}</mat-label>
              <input [formControlName]="formConfig.keys.title" matInput autocomplete="off" />
              <mat-error class="customError">
                <ng-container *ngIf="formService.formConfig.get('title').hasError('required')">
                  {{"ThisFieldIsRequired" | translate}}
                </ng-container>
                <ng-container *ngIf="formService.formConfig.get('title').hasError('noWhiteSpace')">
                  {{"NoWhiteSpace" | translate}}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- End Title Control -->
      </div>
      <div class="row" [ngClass]="formService.isImportMethod ? 'showDiv' : 'hideDiv'">
          <div class="col-md-12 inputfile-wrapper">
            <app-common-file-upload (onFileChanged)="onFileChanged($event)" [allowedFileTypes]="allowedFileTypes"></app-common-file-upload>
          </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button color="accent" type="submit" disableRipple [disabled]="(!this.formConfig.valid) && formService.isImportMethod == false">{{'Apply' | translate}}</button>
      <button mat-flat-button type="button" color="warn" disableRipple (click)="close()">{{'Close' | translate}}</button>
    </div>
  </form>
  <!-- End Form Dialog-->
