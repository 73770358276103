<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 custom-dialog-title">
            <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 formHeading">
            <h3 class="modalHeading">
                {{"MRP Specification" | translate}}
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 table-action-box">
          <app-search class="search-box"
            [dataService]="dataService">
          </app-search>
          <app-show-hide-columns class="action-box"
            [tableService]="tableService">
          </app-show-hide-columns>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 table-box mrp-table">
            <app-table [apiService]="apiService" [tableService]="tableService" [dataService]="dataService">
            </app-table>
        </div>
    </div>
</div>
