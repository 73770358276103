import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'columnClass'
})
export class ColumnClassPipe implements PipeTransform {
  
  transform(column: ITableColumn): string {
    return column.hasOwnProperty('class')? column.class : '';
  }

}
