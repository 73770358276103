import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { ValidationFunctions } from '../../../../../../../../shared/functions/validation-functions';
import { IPosition } from '../../../../../../../../shared/interfaces/i-position';
import { GeoLocationForwardService } from '../../../../../../services/api/geo-location-forward.service';
import { BlGeoLocationForwardFormService } from '../../../../../../services/forms/bl-geo-location-forward-form.service';
import { BlGeoLocationForwardRequestsService } from '../../../../../../services/requests/bl-geo-location-forward-requests.service';
import { BlGeoLocationDataService } from '../../../../../../services/shared/bl-geo-location-data.service';
import { BlGeoLocationForwardDataService } from '../../../../../../services/shared/bl-geo-location-forward-data.service';
import { BlGeoLocationForwardTableService } from '../../../../../../services/table/bl-geo-location-forward-table.service';
import { GeoLocationTableComponent } from '../geo-location-table/geo-location-table.component';

@Component({
  selector: 'app-forward-geo-location',
  templateUrl: './forward-geo-location.component.html',
  styleUrls: ['./forward-geo-location.component.scss']
})
export class ForwardGeoLocationComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlGeoLocationForwardFormService,
    public requestsService: BlGeoLocationForwardRequestsService,
    public dataService: BlGeoLocationForwardDataService,
    public tableService: BlGeoLocationForwardTableService,
    public apiService: GeoLocationForwardService,
    public mainDataService: BlGeoLocationDataService
  ) {}

  allItems: any = [];
  selectedItem: any = {};
  columnsForItem: [] = [];

  markers: IPosition[] = [{
    latitude: 0,
    longitude: 0
  }];
  zoomIn: IPosition;
  renderMap: boolean = false;

  showAllItemsTable: boolean = false;

  subscription: Subscription = new Subscription();

  @ViewChild(GeoLocationTableComponent) geoLocationTableComponent: GeoLocationTableComponent;

  ngOnInit(): void {
    ValidationFunctions.runValidation(this.formService.form);
    this.tableService.addColumnWithDialog(
      cloneDeep({
        index: 'actions',
        title: 'Actions',
        options: [
          {
            title: 'Show',
            class: 'mat-flat-button ',
            method: (row) => {
              this.showInformation(row);
            }
          }
        ]
      })
    );
  }

  showInformation(row): void {
    const selected = this.allItems.find(x => x.placeId == row.placeId);
    this.setMapData(selected.lat, selected.longitude);
    this.geoLocationTableComponent.generateTable(selected);
  }

  setMapData(lat, long) {
    this.markers = [{
      longitude: long,
      latitude: lat
    }];
    this.zoomIn = {
      longitude: long,
      latitude: lat
    };

    this.renderMap = true;
  }

  search(): void {
    SpinnerFunctions.showSpinner();
    this.showAllItemsTable = true;

    const objectForSend = this.formService.prepareDataToSend();
    this.subscription.add(this.requestsService.search(objectForSend).subscribe({
      next: data => {
        this.allItems = data;
        this.dataService.setStorage(data);

        SpinnerFunctions.hideSpinner();
      }, 
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}