import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../../shared/functions/spinner-functions';
import { FormGroupTypeSafe } from '../../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../../shared/services/alert-message-data.service';
import { IAdminConfigRoutingForm, IMapDownload } from '../../../../../../interfaces/i-admin-config-routing';
import { AdminConfigRoutingService } from '../../../../../../services/api/admin-config-routing.service';
import { BlAdminConfigRoutingFormService } from '../../../../../../services/forms/bl-admin-config-routing-form.service';
import { BlAdminConfigRoutingRequestsService } from '../../../../../../services/requests/bl-admin-config-routing-requests.service';
import { BlAdminConfigRoutingDataService } from '../../../../../../services/shared/bl-admin-config-routing-data.service';

@Component({
  selector: 'app-routing-admin-config',
  templateUrl: './routing-admin-config.component.html',
  styleUrls: ['./routing-admin-config.component.scss']
})

export class RoutingAdminConfigComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: AdminConfigRoutingService,
    public dataService: BlAdminConfigRoutingDataService,
    public requestsService: BlAdminConfigRoutingRequestsService,
    public formService: BlAdminConfigRoutingFormService,
    private alertService: AlertMessageDataService
  ) {}

  freeMaps: IMapDownload[] = [];
  occupiedMaps: IMapDownload[] = [];
  form: FormGroupTypeSafe<IAdminConfigRoutingForm> = this.formService.getFormReference();
  showControls = false;

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.getAllMaps();
  }

  getAllMaps(): void {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestsService.getAll().subscribe({
        next: data => {
          this.freeMaps = this.formService.maps = data.free;
          this.occupiedMaps = data.occupied;
          this.formService.fillForm(data.free);
          this.showControls = true;
          SpinnerFunctions.hideSpinner();
        },
        error: err => {
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }

  save(): void {
    this.subscription.add(
      this.formService.submitUpdate().subscribe({
        next: data => {
          this.alertService.setMessage("success", "Successfully downloaded!");
          this.getAllMaps();
        },
        error: err => {
          this.alertService.setMessage("danger", "Download error!");
        }
      })
    )
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

}
