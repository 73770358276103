<div class="row">
  <div class="col-md-12 section-content">
    <app-header-dashboard-pfsp>
    </app-header-dashboard-pfsp>
    @if (formServices.isShowMainTabs) {
      <app-tabs-dashboard-pfsp>
      </app-tabs-dashboard-pfsp>
    }
  </div>
</div>
