import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeterministicMrpRoutingModule } from './deterministic-mrp-routing.module';
import { DashboardDeterministicMrpComponent } from './components/dashboard-deterministic-mrp/dashboard-deterministic-mrp.component';
import { FormDashboardDeterministicMrpComponent } from './components/dashboard-deterministic-mrp/components/form-dashboard-deterministic-mrp/form-dashboard-deterministic-mrp.component';
import { HeaderDashboardDeterministicMrpComponent } from './components/dashboard-deterministic-mrp/components/header-dashboard-deterministic-mrp/header-dashboard-deterministic-mrp.component';
import { TabsDashboardDeterministicMrpComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/tabs-dashboard-deterministic-mrp.component';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { TabDashboardItemMasterComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-item-master/tab-dashboard-item-master.component';
import { ViewDialogDashboardDeterministicMrpComponen } from './components/dashboard-deterministic-mrp/components/view-dialog-dashboard-deterministic-mrp/view-dialog-dashboard-deterministic-mrp.component';
import { TabDashboardBomComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-bom/tab-dashboard-bom.component';
import { TabDashboardBomLeftBlockComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-bom/components/tab-dashboard-bom-left-block/tab-dashboard-bom-left-block.component';
import { TabDashboardBomRightBlockComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-bom/components/tab-dashboard-bom-right-block/tab-dashboard-bom-right-block.component';
import { TabDashboardMpsComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-mps/tab-dashboard-mps.component';
import { TabDashboardInventoryComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-inventory/tab-dashboard-inventory.component';
import { TabDashboardCapacityComponent } from './components/dashboard-deterministic-mrp/components/tabs-dashboard-deterministic-mrp/components/tab-dashboard-capacity/tab-dashboard-capacity.component';
import { DashboardMrpReportComponent } from './components/dashboard-mrp-report/dashboard-mrp-report.component';
import { TabsDashboardMrpReportComponent } from './components/dashboard-mrp-report/components/tabs-dashboard-mrp-report/tabs-dashboard-mrp-report.component';
import { TabDashboardMrpReportComponent } from './components/dashboard-mrp-report/components/tabs-dashboard-mrp-report/components/tab-dashboard-mrp-report/tab-dashboard-mrp-report.component';
import { TabDashboardActionListComponent } from './components/dashboard-mrp-report/components/tabs-dashboard-mrp-report/components/tab-dashboard-action-list/tab-dashboard-action-list.component';
import { TabDashboardIndentedBomComponent } from './components/dashboard-mrp-report/components/tabs-dashboard-mrp-report/components/tab-dashboard-indented-bom/tab-dashboard-indented-bom.component';
import { TabDashboardCostAnalysisComponent } from './components/dashboard-mrp-report/components/tabs-dashboard-mrp-report/components/tab-dashboard-cost-analysis/tab-dashboard-cost-analysis.component';
import { HeaderDashboardMrpReportComponent } from './components/dashboard-mrp-report/header-dashboard-mrp-report/header-dashboard-mrp-report.component';
import { SaveAsNewDialogComponent } from './components/dashboard-deterministic-mrp/components/save-as-new-dialog/save-as-new-dialog.component';
import { UpgradeYourBrowserComponent } from './components/dashboard-mrp-report/upgrade-your-browser/upgrade-your-browser.component';


@NgModule({
    declarations: [DashboardDeterministicMrpComponent, FormDashboardDeterministicMrpComponent, ViewDialogDashboardDeterministicMrpComponen, HeaderDashboardDeterministicMrpComponent, TabsDashboardDeterministicMrpComponent, TabDashboardItemMasterComponent, TabDashboardBomComponent, TabDashboardBomLeftBlockComponent, TabDashboardBomRightBlockComponent, TabDashboardMpsComponent, TabDashboardInventoryComponent, TabDashboardCapacityComponent, DashboardMrpReportComponent, TabsDashboardMrpReportComponent, TabDashboardMrpReportComponent, TabDashboardActionListComponent, TabDashboardIndentedBomComponent, TabDashboardCostAnalysisComponent, HeaderDashboardMrpReportComponent, SaveAsNewDialogComponent, UpgradeYourBrowserComponent],
    imports: [
        CommonModule,
        DeterministicMrpRoutingModule,
        SharedModule,
        CoreModule
    ]
})
export class DeterministicMrpModule { }
