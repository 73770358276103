<div class="row">
    <div class="col-sm-9 header-title-box">
        <h3 class="page-header-title"> <span>{{"AdminConfig" | translate}}</span></h3>
    </div>
    <div class="col-sm-3 header-button-box">
      <!-- <button type="button" mat-stroked-button color="accent" (click)="openMarkdown()">
          <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
      </button> -->
    </div>
</div>
