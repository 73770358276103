import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumn } from '../../interfaces/i-table-column';

@Pipe({
  name: 'formControlType'
})
export class FormControlTypePipe implements PipeTransform {

  transform(column: ITableColumn, row: any): any {
    return this.fieldType(row[column.index]);
  }

  private fieldType(value) {
    if(!isNaN(value)){
      return "number";
    }
    return "text";
  }

}
