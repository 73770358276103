<form [formGroup]="form" class="reducedForm" style="height: 100%;">

    <div class="content-wrapper">
        <div class="upper">
            <!-- Heading Input Data Tab -->
            <div class="row tab-header" *ngIf="!dataService.originalObject.getValue() || !dataService.originalObject.getValue().id">
                <div class="col-md-8">

                </div>
                <div class="col-md-4 tab-actions">
                    <ng-container *ngIf="formService.form.get('rows')['controls']?.length>0">
                        <button class="btn-absolute" type="button" mat-flat-button color="accent" (click)="saveData()"
                            [disabled]="!this.form.get('rows').valid">{{"Insert" |
                            translate}}
                        </button>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="!form.get('rows').valid">
                <div class="row">

                    <div class="col-md-12 alert alert-warning" role="alert">
                        {{"The value entered in the table field is higher than" |
                        translate}}&nbsp;<b>{{"P" | translate}}:&nbsp;{{formService.configP}}</b>.<br/>
                        {{"The value entered in the table field must not be less than" |
                        translate}}<b>&nbsp;1</b>.
                    </div>
                </div>
            </ng-container>

            <!-- End Heading Input Data Tab -->
            <!-- Table Input Data Tab -->
            <ng-container *ngIf="dataService.originalObject.getValue() && dataService.originalObject.getValue().id">
                <div class="row">
                    <div class="col-sm-6 col-md-3 col-lg-2 input-mobile">
                        <mat-form-field appearance="outline" [ngClass]="formService.form.get('objective').errors ? 'requiredField' : ''">
                            <mat-label>{{ "Objective" | translate}}</mat-label>
                            <mat-select [formControlName]="formService.form.keys.objective" disableOptionCentering
                                >
                                <mat-option *ngFor="let option of dropdownData.Objectives" [value]="option.id">
                                    {{option.title | translate}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="customError">
                                <ng-container *ngIf="formService.form.get('objective').hasError('required')">
                                    {{"ThisFieldIsRequired" | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 input-mobile">
                        <mat-form-field appearance="outline" [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''">
                            <mat-label>{{ "Algorithm" | translate}}</mat-label>
                            <mat-select (selectionChange)="changeAlgorithm($event.value)" [formControlName]="formService.form.keys.algorithm" disableOptionCentering>
                                <mat-option  *ngFor="let option of dropdownData.Algorithms" [value]="option.id">
                                    {{option.title | translate}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="customError">
                                <ng-container *ngIf="formService.form.get('algorithm').hasError('required')">
                                    {{"ThisFieldIsRequired" | translate}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6 col-md-3 col-lg-2 input-mobile">

                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-5 btn-run">

                    </div>
                </div>
            </ng-container>

            <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-2 input-mobile"></div>
                <div class="col-sm-6 col-md-3 col-lg-3 input-mobile">
                    <ng-container *ngIf="form.get('algorithmParams')['controls']" formArrayName="algorithmParams">
                        <ng-container *ngFor="let item of formService.parametri; let i=index">
                            <div class="row">
                            <div class="col-md-12">
                                <mat-form-field appearance="outline">
                                <mat-label>{{item | translate}}</mat-label>
                                <input [formControl]="form.get('algorithmParams')['controls'][i].get('value')" matInput autocomplete="off" type="number">
                                </mat-form-field>
                            </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-2 input-mobile"></div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <cdk-virtual-scroll-viewport style="width: 100%; height: 330px; overflow-y: auto;" minBufferPx="200"
                        maxBufferPx="400" [itemSize]="50">
                        <div>
                            <ng-container *ngIf="form.get('rows')['controls']?.length > 0; else noAllTableData;">
                                <table class="table table-sm table-striped tab-input-data">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th *ngFor="let header of tableService.headersTable1">{{header.index}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *cdkVirtualFor="let item of form.get('rows')['controls']; let i=index">
                                            <tr>
                                                <td>{{i+1}}</td>
                                                <td *ngFor="let cols of numbers; let j=index;">
                                                    <mat-form-field appearance="outline">
                                                        <input
                                                            [formControl]="form.get('rows')['controls'][i].get('c'+j)"
                                                            matInput autocomplete="off" />
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-template #noAllTableData>
                                <div class="alert alert-warning" role="alert">
                                    {{"NoRecordsFound" | translate}}
                                </div>
                            </ng-template>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
            <!-- End Table Input Data Tab -->
        </div>
        <div class="lower">
            <!-- Second Block -->
            <ng-container *ngIf="formService.isShowOtherTabs && dataService.dataForSecondBlock">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-striped input-data-info">
                            <tbody>
                                <tr>
                                    <td><b>{{"Min number of cells" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.minNumberCells}}</td>
                                </tr>
                                <tr>
                                    <td><b>{{"Max number of cells" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.maxNumberCells}}</td>
                                </tr>
                                <tr>
                                    <td><b>{{"Group of machines" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.groupOfMachines}}</td>
                                </tr>
                                <tr>
                                    <td><b>{{"Group of parts" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.groupOfParts}}</td>
                                </tr>
                                <tr>
                                    <td><b>{{"Degrees of machines" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.degreesOfMachines}}</td>
                                </tr>
                                <tr>
                                    <td><b>{{"Degrees of parts" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.degreesOfParts}}</td>
                                </tr>
                                <tr>
                                    <td><b>{{"Resid. of parts" | translate}}</b></td>
                                    <td>{{dataService.dataForSecondBlock?.rowsTab1.table2?.residOfParts}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <ng-container *ngFor="let table of dataService.dataForSecondBlock?.rowsTab1.table2?.statistics">
                            <table class="table table-sm table-striped input-data-info">
                                <tbody>
                                    <tr *ngFor="let data of table.stavke">
                                        <td>{{data.name | translate}}</td>
                                        <td>{{data.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <!-- End Second Block -->
        </div>
    </div>
</form>
