<div class="row">
  <div class="col-md-12 tab-content">
    <mat-tab-group dynamicHeight="true" [selectedIndex]="selectedTab" class="custom-tab-group"
      (selectedTabChange)="onTabChange($event)">
      <mat-tab label="{{'Input Data' | translate}}" [disabled]="!(dataService.originalObject.getValue())">
        <app-tab-dashboard-input-data></app-tab-dashboard-input-data>
      </mat-tab>
      @if (formService.isShowOtherTabs) {
        <mat-tab label="{{'Reductions' | translate}}">
          <app-tab-dashboard-reductions></app-tab-dashboard-reductions>
        </mat-tab>
        <mat-tab label="{{'Dissimil M' | translate}}">
          <app-tab-dashboard-dissimil-m></app-tab-dashboard-dissimil-m>
        </mat-tab>
        <mat-tab label="{{'Dissimil P' | translate}}">
          <app-tab-dashboard-dissimil-p></app-tab-dashboard-dissimil-p>
        </mat-tab>
        <mat-tab label="{{'Similarity M' | translate}}">
          <app-tab-dashboard-similarity-m></app-tab-dashboard-similarity-m>
        </mat-tab>
        <mat-tab label="{{'Similarity P' | translate}}">
          <app-tab-dashboard-similarity-p></app-tab-dashboard-similarity-p>
        </mat-tab>
        <mat-tab label="{{'Results' | translate}}" [disabled]="isBruscoRun == false">
          <app-tab-dashboard-results></app-tab-dashboard-results>
        </mat-tab>
      }
      <mat-tab label="{{'Test Results' | translate}}" [disabled]="isResultsRun == false">
        <app-tab-dashboard-test-results></app-tab-dashboard-test-results>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
