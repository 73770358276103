import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { HeaderComponent } from './header/header.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { SharedModule } from '../shared/shared.module';
import { LanguageModalComponent } from './language-modal/language-modal.component';
import { UserNavigationComponent } from './header/components/user-navigation/user-navigation.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { HeaderDashboardNotificationComponent } from './notification-modal/components/header-dashboard-notification/header-dashboard-notification.component';
import { TableDashboardNotificationComponent } from './notification-modal/components/table-dashboard-notification/table-dashboard-notification.component';


@NgModule({
    declarations: [AppLayoutComponent, LeftSidebarComponent, HeaderComponent, LanguageModalComponent, UserNavigationComponent, NotificationModalComponent, HeaderDashboardNotificationComponent, TableDashboardNotificationComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class LayoutModule { }
