<div id="login-wrapper">

    <div class="login-form">
      <div class="row">
        <div class="col-md-12">
          <h3>{{"COMPUTER INTEGRATED MANUFACTURING" | translate}}</h3>
        </div>
      </div>

        <div class="row">
          <div class="col-sm-12">
           <p class="textInfo">
            {{"RegisterActivationDesc" | translate}}
           </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
           <button mat-flat-button color="accent" [disabled]="(isUrlValid == false)? true: false" (click)="activeUser()">{{'Active Account' | translate}}</button>
          </div>
        </div>
    </div>

  </div>
