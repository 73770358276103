<div class="row">
    <div class="col-sm-12 header-button-box">
        <div>
            <button (click)="printReport()" mat-stroked-button color="accent">
                <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
            </button>
        </div>
    </div>
</div>
<div id="report" class="row">
    <div class="col-md-12 tab-wrapper">
        <ng-container *ngIf="dataService.reportTables.length > 0; else noAllTableData;">
            <ng-container *ngFor="let data of dataService.reportTables; let i = index;">
                <div class="row">
                    <div class="col-sm-12 ">
                        <p class="table-title" [innerHTML]="data.nazivTabele | safeHtml"></p>
                        <p class="table-title" [innerHTML]="data.opis | safeHtml"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 table-wrapper">
                        <table class="table table-sm">
                            <thead class="thead-dark">
                                <tr>
                                    <th>&nbsp;</th>
                                    <th *ngFor="let header of data.table.tableHeader">{{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tableRow of data.table.tableBody">
                                    <ng-container *ngFor="let columns of tableRow.stavke">
                                            <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                                                <span [ngStyle]="{'textAlign' : regex.test(columns.value) ? 'right' : 'left'}">
                                                    {{columns.value}}
                                                </span>
                                            </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #noAllTableData>
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-warning" role="alert">
                        {{"NoRecordsFound" | translate}}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<!-- End MRP Report Table Tab -->