import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BlQapDataService } from '../../services/shared/bl-qap-data.service';
import { BlQapFormService } from '../../services/forms/bl-qap-form.service';

@Component({
  selector: 'app-dashboard-qap',
  templateUrl: './dashboard-qap.component.html',
  styleUrls: ['./dashboard-qap.component.scss']
})
export class DashboardQapComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private dataService: BlQapDataService,
    public formServices: BlQapFormService
  ) { }

  ngAfterViewInit(): void {
    this.dataService.storageIsTabsView.subscribe({
      next: (data) => {
        this.formServices.isShowMainTabs = data;
      }
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // this.dataService.storageDataIsRightBlockView.next(false);
    this.dataService.storageIsTabsView.next(false);
    this.dataService.storageDataIsShowOtherTabs.next(false);
    this.dataService.storageIsRunResults.next(false);
    // this.formServices.reset();
    this.formServices.reducedFlowsArray = [];
    this.formServices.reducedDistancesArray = [];

    this.dataService.originalObject.next(null);
  }

}
