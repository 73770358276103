<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
    </div>
  </div>
</div>

<form class="custom-form" [formGroup]="formService.form">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 formHeading">
        <h3 class="modalHeading">
          {{"Square Assignment - Flows" | translate}}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 custom-form reduced-form">
        <cdk-virtual-scroll-viewport
          [ngStyle]="{'height' : formService.sizes < 15 ? (formService.sizes + 2)*30+'px' : '480px'}"
          style="width: 100%; overflow-y: auto;"
          minBufferPx="200"
          maxBufferPx="600"
          [itemSize]="30">
          <div>
            @if (formService.form.get('flows')['controls']?.length > 0) {
              <table class="table table-sm table-striped tab-input-data table-pfsp hide-subscript">
                <thead class="thead-dark">
                  <tr>
                    <th>&nbsp;</th>
                    @for (header of tableService.headersTable1; track header) {
                      <th>{{header.index}}</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  <ng-container *cdkVirtualFor="let item of formService.form.get('flows')['controls']; let i=index">
                    <tr>
                      <td>{{i+1}}</td>
                      @for (cols of numbers; track cols; let j = $index) {
                        <td>
                          <mat-form-field appearance="outline">
                            <input [formControl]="formService.form.get('flows')['controls'][i].get('c'+j)"
                              matInput autocomplete="off" />
                            </mat-form-field>
                          </td>
                        }
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              } @else {
                <div class="alert alert-warning" role="alert">
                  {{"NoRecordsFound" | translate}}
                </div>
              }
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="primary" [disabled]="!form.get('flows').valid" (click)="save()">{{"Save" | translate}}</button>
        <button mat-flat-button color="warn" (click)="close(false)">{{"Close" | translate}}</button>
    </div>
  </form>
