import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../../core/guards/role.guard';
import { DashboardGraphNodesComponent } from './components/dashboard-graph-nodes/dashboard-graph-nodes.component';

const routes: Routes = [
  {
    path: "dashboard-graph-nodes",
    component: DashboardGraphNodesComponent,
    canActivate: [RoleGuard],
    data: {section: "routing", child: "dashboard-graph-nodes" }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GraphNodesRoutingModule { }
