import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../../../../shared/services/alert-message-data.service';
import { BlPfspFormService } from '../../../../../../../../services/form/bl-pfsp-form.service';
import { BlPfspRequestsService } from '../../../../../../../../services/requests/bl-pfsp-requests.service';
import { BlPfspDataService } from '../../../../../../../../services/shared/bl-pfsp-data.service';
import { BlPfspResultTableService } from '../../../../../../../../services/table/bl-pfsp-result-table.service';
import { PfspHelperFunctionsService } from '../../../../../../../../services/helper/pfsp-helper-functions.service';

@Component({
  selector: 'app-tab-dashboard-input-data-right-side',
  templateUrl: './tab-dashboard-input-data-right-side.component.html',
  styleUrls: ['./tab-dashboard-input-data-right-side.component.scss']
})
export class TabDashboardInputDataRightSideComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlPfspFormService,
    private dataService: BlPfspDataService,
    public tableService: BlPfspResultTableService,
    private requestsService: BlPfspRequestsService,
    private alertService: AlertMessageDataService,
    private cdr: ChangeDetectorRef,
    private pfspHelperFunctionsService: PfspHelperFunctionsService
  ) { }

  private subscription: Subscription = new Subscription();
  public dataFromStorage: any;
  public dataFromStorageForSend: any;
  public dataForSend: any;

  private clickedButton: boolean = false;

  ngOnInit(): void {
    this.subscription.add(
      this.dataService.storageDataResults.subscribe({
        next: data => {
          if(data) {
            this.dataFromStorage = data;
            this.formService.reducedNumbersArray = this.dataFromStorage.rightBlock.arrayNumbers;
            this.formService.numberRows = this.dataFromStorage.m;
            this.formService.numberCols = this.dataFromStorage.p;
            this.tableService.createDynamicReducedHeaderColumnsTable(this.formService.reducedNumbersArray);
            this.dataFromStorageForSend = this.dataService.originalObject.getValue();
          }
        }
      })
    );

    this.cdr.detectChanges();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formService.reducedNumbersArray, event.previousIndex, event.currentIndex);

    this.clickedButton = true;
    this.dataForSend = [];
    if(this.dataFromStorage) {
      this.prepareDataToSend(this.dataFromStorage.id);

      if (this.clickedButton) {
        SpinnerFunctions.showSpinner();
        this.formService.isRunProblemForTitle = false;
        this.formService.problemTitle = "";
        this.subscription.add(
          this.requestsService.runProblem(this.dataForSend).subscribe({
            next: success => {
              this.alertService.setMessage("success", "Successfully.");
              this.dataService.storageDataResults.next(success);
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
              this.formService.isRunProblemForTitle = true;
              this.formService.problemTitle = success[0] ? success[0].title : success["title"];
            },
            error: error => {
              this.alertService.setMessage("danger", "Error.");
              SpinnerFunctions.hideSpinner();
              this.clickedButton = false;
            }
          })
        );
      }
    }
  }

  prepareDataToSend(isSaved: boolean): void {
    this.dataForSend = [];
    let form: any = this.formService.form.getRawValue();
    let rows: any = this.pfspHelperFunctionsService.mapToSingleArray(form.rows, form);

    let dataToSend = {
      id: this.dataFromStorageForSend.id,
      m: this.dataFromStorageForSend.m,
      n: this.dataFromStorageForSend.n,
      title: this.dataFromStorage.title,
      problemType: this.dataFromStorageForSend.problemType,
      leftBlock: {
        targetFunction: this.dataFromStorageForSend.leftBlock.targetFunction,
        algorithm: "refresh",
        table: rows
      },
      rightBlock: {
        arrayNumbers: this.formService.reducedNumbersArray
      }
    }
    if(!isSaved) {
      dataToSend.leftBlock.targetFunction = this.formService.form.getSafe(x => x.targetFunction).value;
    }

    this.dataForSend.push(dataToSend);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols = 0;
    this.dataForSend = [];
    this.formService.reducedNumbersArray = [];
  }

}
