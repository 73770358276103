import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from '../../../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { IDeterministicMrpDropDownData, IDeterministicMrpForm } from '../../../../../../interfaces/i-deterministic-mrp';
import { BlDeterministicMrpFormService } from '../../../../../../services/form/bl-deterministic-mrp-form.service';
import { BlDeterministicMrpDataService } from '../../../../../../services/shared/bl-deterministic-mrp-data.service';
import { BlDeterministicMrpTableService } from '../../../../../../services/table/bl-deterministic-mrp-table.service';
import { SOURCE_CODES } from '../../../../../../../../config/settings/mrp/sourceCodes';
import { MATERIAL_TYPES } from '../../../../../../../../config/settings/mrp/materialTypes';
import { LOT_SIZES } from '../../../../../../../../config/settings/mrp/lotSizes';
import { UntypedFormArray } from '@angular/forms';
import { PermissionsService } from '../../../../../../../../shared/services/permissions.service';
import { BlBomFormService } from '../../../../../../services/form/bl-bom-form.service';
import { BlMpsFormService } from '../../../../../../services/form/bl-mps-form.service';
import { BlInventoryFormService } from '../../../../../../services/form/bl-inventory-form.service';
import { BlCapacityFormService } from '../../../../../../services/form/bl-capacity-form.service';

@Component({
  selector: 'app-tab-dashboard-item-master',
  templateUrl: './tab-dashboard-item-master.component.html',
  styleUrls: ['./tab-dashboard-item-master.component.scss']
})
export class TabDashboardItemMasterComponent implements OnInit, OnDestroy {

  constructor(
    public formService: BlDeterministicMrpFormService,
    public dataService: BlDeterministicMrpDataService,
    public tableService: BlDeterministicMrpTableService,
    private alertService: AlertMessageDataService,
    public permissionsService: PermissionsService,
    public formServiceTab2: BlBomFormService,
    public formServiceTab3: BlMpsFormService,
    public formServiceTab4: BlInventoryFormService,
    public formServiceTab5: BlCapacityFormService
  ) { }

  public clickedButton: boolean = false;
  public isViewLoad: boolean = false;

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IDeterministicMrpForm> = this.formService.form;

  public dropdownData: IDeterministicMrpDropDownData = {
    LotSizes: null,
    MaterialTypes: null,
    SourceCodes: null,
    PlanningPeriods: null
  }


  public once: boolean = false;

  ngOnInit(): void {
    this.dropdownData.SourceCodes = SOURCE_CODES;
    this.dropdownData.MaterialTypes = MATERIAL_TYPES;
    this.dropdownData.LotSizes = LOT_SIZES;

    this.subscription.add(
      this.dataService.storageDataisFromViewDialog.subscribe({
        next: (data) => {
          if (data == false) {
            this.isViewLoad = true;
            this.dataService.storageDataFromFormDialog.subscribe({
              next: (dataForm) => {
                if (dataForm) {
                  if (this.isViewLoad) {
                    this.isViewLoad = false;
                    this.formService.numberRows = dataForm.noProductPartItems;
                    if (this.formService.numberRows && this.formService.numberRows > 0) {
                      this.formService.createTableWithDynamicRows(this.formService.numberRows);
                    }
                  }
                }
              }
            })
          } else {
            this.isViewLoad = true;
            this.dataService.storageDataViewDialog.subscribe({
              next: (dataView) => {
                if (dataView) {
                  if (this.isViewLoad) {
                    this.isViewLoad = false;
                    this.formService.setRows(dataView.rows);
                  }
                }
              }
            })
          }
        }
      })
    );

  }

  checkingSameValue(event: any, index: any) {

    let controlValue: any = event.target.value;
    let controls: any = (this.form.getSafe(x => x.rows) as UntypedFormArray).controls;

    for(let i = 0; i < controls.length; i++) {
      let itemIdValue: any = (this.form.getSafe(x => x.rows) as UntypedFormArray).controls[i].get("itemId").value;
      if(itemIdValue == controlValue) {
        if(i != index){
          (this.form.getSafe(x => x.rows) as UntypedFormArray).controls[index].get("itemId").setValue("");
          this.alertService.setMessage("warning", "Values in Item ID column are same. Please change it.");
        }

      }
    }
  }

  saveData() {
    this.clickedButton = true;
    this.dataService.setRun(true);
    this.subscription.add(this.dataService.storageDataisFromViewDialog.subscribe({
      next: (data) => {
        if (data == false) {
          this.dataService.storageDataFromFormDialog.subscribe({
            next: (dataForm) => {
              if (dataForm) {
                if (this.clickedButton) {
                  this.resetRun();
                  this.dataService.storageDataNewForm.subscribe({
                    next: (formValue) => {
                      let formData: any = this.form.value;

                      if(formValue) {
                        this.formService.rowData = [
                          {
                            title: formValue.title,
                            noProductPartItems: formValue.noProductPartItems,
                            timeUnitPlanningPeriod: formValue.timeUnitPlanningPeriod,
                            noPlanningPerod: formValue.noPlanningPerod,
                            noPeriodsYear: formValue.noPeriodsYear,
                            maxNoOfDirectComponent: formValue.maxNoOfDirectComponent,
                            rows: formData.rows
                          }
                        ];
                      }
                  }
                  })
                }
              }
            }
          });

          if(this.clickedButton){
            this.subscription.add(
              this.dataService.storageDataIsShowOtherTabs.next(true)
            );
          }

          this.subscription.add(
            this.dataService.storageDataFromFormDialog.next(this.formService.rowData)
          );

          this.subscription.add(
            this.dataService.storageIsTreeView.next(false)
          );

          this.subscription.add(
            this.dataService.storageNewDataTab2.next(null)
          );

          if(this.clickedButton)
          this.alertService.setMessage("success", "Successfully.");
          this.clickedButton = false;

        } else {
          this.once = true;
          this.dataService.storageDataViewDialog.subscribe({
            next: (dataView) => {
              if (dataView) {
                  this.resetRun();
                  this.clickedButton = false;
                  this.dataService.storageDataViewForm.subscribe({
                    next: (viewValue) => {
                      if (viewValue) {
                        let formValue: any = this.form.value;

                        this.formService.rowData = [
                          {
                            id: viewValue.id,
                            title: viewValue.title,
                            noProductPartItems: viewValue.noProductPartItems,
                            timeUnitPlanningPeriod: viewValue.timeUnitPlanningPeriod,
                            noPlanningPerod: viewValue.noPlanningPerod,
                            noPeriodsYear: viewValue.noPeriodsYear,
                            maxNoOfDirectComponent: viewValue.maxNoOfDirectComponent,
                            rows: formValue.rows,
                            rowsTab2: viewValue.rowsTab2,
                            rowsTab3: viewValue.rowsTab3,
                            rowsTab4: viewValue.rowsTab4,
                            rowsTab5: viewValue.rowsTab5
                          }
                        ];
                        if(this.once)
                        this.bulk();
                      }
                    }
                  })
                }
             }
          });

        }
       this.trackItemIdChanges();
      }
    }));
  }

  trackItemIdChanges(){
      (this.formService.form.get("rows") as UntypedFormArray).valueChanges.subscribe({
        next: (data)=>{
          if(data){
            if(this.formServiceTab2.form.value.rowsTab2.length==data.length)
              this.updateOtherTabs("rowsTab2", data);
              if(this.formServiceTab3.form.value.rowsTab3.length==data.length)
              this.updateOtherTabs("rowsTab3", data);
              if(this.formServiceTab4.form.value.rowsTab4.length==data.length)
              this.updateOtherTabs("rowsTab4", data);
              if(this.formServiceTab5.form.value.rowsTab5.length==data.length)
              this.updateOtherTabs("rowsTab5", data);
          }
        }
      })
      this.dataService.insert(true);
  }
  updateOtherTabs(ctrlName : string, data){
      (this["formServiceTab"+ctrlName.charAt(ctrlName.length-1)].form.get(ctrlName) as UntypedFormArray).controls.forEach((ctrl,index)=>{
        if(this.dataService.inserted){
        ctrl.get("itemId").setValue(data[index].itemId);
        }
      })
  }

  bulk(){
      this.once = false;
      this.subscription.add(
        this.dataService.storageDataIsShowOtherTabs.next(true)
      );
      this.subscription.add(
        this.dataService.storageDataViewDialog.next(this.formService.rowData)
      );

      this.subscription.add(
        this.dataService.storageIsTreeView.next(false)
      );

      this.subscription.add(
        this.dataService.storageViewDataTab2.next(null)
      );

      this.alertService.setMessage("success", "Successfully.");

  }

  resetRun(){
    setTimeout(() => {
      this.dataService.setRun(false);
    }, 1000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
  }

}
