<div mat-dialog-title>
  <div class="row">
    <div class="col-sm-12 custom-dialog-title">
      <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 formHeading">
      <h3 class="modalHeading">
        {{dataService.problemType.title | translate}}
      </h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 custom-form type-form">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>{{"Instance Type" | translate}}</mat-label>
        <mat-select (selectionChange)="changeProblemType($event)" panelClass="custom-mat-select-panel-class">
          @for (problem of problemTypes; track problem) {
            <mat-option [value]="problem.type">{{problem.title | translate}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (isProblemSelected) {
        <mat-form-field color="primary" appearance="outline">
          <mat-label>{{"TypeOfMap" | translate}}</mat-label>
          <mat-select [value]="'all'" (selectionChange)="changeMapType($event)"
            panelClass="custom-mat-select-panel-class">
            @for (type of mapTypes; track type) {
              <mat-option [value]="type.type">{{type.title | translate}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
  </div>

  @if (isProblemSelected) {
    <div class="row">
      <div class="col-sm-12 table-action-box">
        <app-search class="search-box"
          [dataService]="dataService">
        </app-search>
        <app-show-hide-columns class="action-box"
          [tableService]="tableService">
        </app-show-hide-columns>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 table-box distribution-table">
        <app-table
          [apiService]="apiService"
          [tableService]="tableService"
          [dataService]="dataService">
        </app-table>
      </div>
    </div>
  }

</div>
