<div class="row">
    <div class="col-md-12">
        <app-header-dashboard-distribution></app-header-dashboard-distribution>
    </div>
</div>

<div class="row" *ngIf="dataService.showTabs">
    <div class="col-md-12">
        <app-tabs-dashboard-distribution></app-tabs-dashboard-distribution>
    </div>
</div>
