import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupTypeSafe } from '../../../../../../shared/helpers/reactive-forms-helper';
import { IBackpackProblemSaveAsForm } from '../../../../interfaces/i-backpack-problem';
import { BlBackpackProblemSaveAsFormService } from '../../../../services/forms/bl-backpack-problem-save-as-form.service';
import { BlBackpackProblemDataService } from '../../../../services/shared/bl-backpack-problem-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-save-as-dialog-backpack-problem',
  templateUrl: './save-as-dialog-backpack-problem.component.html',
  styleUrls: ['./save-as-dialog-backpack-problem.component.scss']
})
export class SaveAsDialogBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    public dataService: BlBackpackProblemDataService,
    public dialogRef: MatDialogRef<SaveAsDialogBackpackProblemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: BlBackpackProblemSaveAsFormService,
    private translateService: TranslateService
  ) { }

  public form: FormGroupTypeSafe<IBackpackProblemSaveAsForm> = this.formService.getFormReference();
  public html: string = `${this.translateService.instant("New3DPackingDerivedFrom")} ${this.dataService.originalObject.getValue() ? (this.dataService.originalObject.getValue()[0] ? this.dataService.originalObject.getValue()[0].title : this.dataService.originalObject.getValue().title) : this.data.title}`

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  complete() {
    this.dialogRef.close(this.form.getSafe(x => x.title).value);
  }

  ngOnDestroy() {
    this.formService.reset();
  }

}
