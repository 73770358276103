<form class="reducedForm" [formGroup]="form">

    <div class="row">
        <div class="col-sm-12 adjust-lines">
            <mat-form-field color="primary" appearance='outline'>
                <mat-label>{{'Linewidth' | translate}}</mat-label>
                <input matInput type="number" [formControlName]="formService.form.keys.width" />
            </mat-form-field>
        </div>
    </div>

    <div class="row" *ngIf="dataService.allCoordinatesForMainMap && dataService.allCoordinatesForMainMap.routes">
        <div class="col-md-12 show-routes">
            <p>{{"ShowHideRoutes" | translate}}</p>
        </div>
        <div class="col-md-12" *ngFor="let route of dataService.allCoordinatesForMainMap.routes">
            <mat-checkbox (change)="changeRoutes($event, route.color, route.id)" [checked]="true" >
                <input [disabled]="true" class="polylineColorInput" matInput type='color' [value]="route.color" />
            </mat-checkbox>
        </div>
    </div>

</form>
