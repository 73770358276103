import { IFilterColumn, IFilterComparer } from "../interfaces/i-filter-column";
import { DateFunctions } from "./date-functions";
import { ArrayFunctions } from "./array-functions";
import { TableFunctions } from "./table-functions";
import { cloneDeep } from "lodash";
import { FilterOperators } from '../consts/filter-operators';
import { DATE_COLUMN_KEYS } from '../consts/date-column-keys';

export class FilterFunctions {

    static filterArray(filters : IFilterColumn[], array){
        return FilterFunctions.filter(filters, array);
    }
    
    private static filter(filters: IFilterColumn[], array: any[]){
        if(filters.length > 0){
          let i =0;
          array = array.filter(row => {  
            let showRow = true;
            filters.forEach((x : IFilterColumn,i) => {
              if(i==0 && x.hasOwnProperty('operator') && x.operator==FilterOperators.or)
                showRow = false;
              
              if(x.hasOwnProperty('column')) {
                if(
                    x.value!=="0" && 
                    x.value!=="" && 
                    x.value!==null && 
                    (
                      (!x.hasOwnProperty('default')) 
                      || (x.hasOwnProperty('default') && 
                      x.value!=x.default)
                    )
                  ){
                  let columnValue = null;
                  if(x.column.indexOf('.')!=-1){
                    columnValue = TableFunctions.getPropertyValue(row,x.column);
                  } else if(x.column =="dueDateTo") {
                    columnValue = row["dueDate"];
                  } else {
                    columnValue = row[x.column];
                  }
                  
                  let dateColumn = DATE_COLUMN_KEYS.find( z => z == x.column);

                  if(dateColumn) {
                    columnValue = DateFunctions.formatDate(DateFunctions.getMomentDateNoTime(columnValue));
                    let date = "";
                    if(x.value.hasOwnProperty('_id') && x.value ) {
                      date = DateFunctions.formatDate(DateFunctions.getMomentDateNoTime(x.value['_d']));
                    } else {
                      date = DateFunctions.formatDate(DateFunctions.getMomentDateNoTime(x.value));
                    }

                    const cloneVal = cloneDeep(x);
                    cloneVal.value = DateFunctions.getMomentDateNoTime(date).getTime()
                    if(columnValue ==null) {
                      showRow = false;
                    } else {
                      showRow = FilterFunctions.checkColumn(showRow, DateFunctions.getMomentDateNoTime(columnValue).getTime(), cloneVal);
                    }
                  }
                  else if(typeof(x.value) == 'string' && x.value.trim()!=""){
                    showRow = FilterFunctions.checkColumn(showRow, columnValue, x);
                  } 
                  else {
                    showRow = FilterFunctions.checkColumn(showRow, columnValue, x);
                  }
                  
                } else if((x.hasOwnProperty('default') && x.value==x.default)){
                  if(x.hasOwnProperty('operator')){
                    if(x.operator==FilterOperators.or){
                      showRow = showRow || true;
                    } else {
                      showRow = showRow && true;
                    }
                } else {
                  showRow = showRow && true;
                }
              }
            }
            })
            return showRow;
        });
        // TODO: set like props in data or try to save as prop of this class
    
          // this.totalCount = this.dataSource.data.length;
          // this.paginator.pageIndex = 0;
          // this.goToPage(1);
        }
        else {
          // this.dataSource.data = dataToFilter;
          // this.totalCount = this.dataSource.data.length;
          // this.goToPage(1);
        }
        return array;
    }
    
    private static checkColumn(showRow, columnValue, x: IFilterColumn){
        if(x.hasOwnProperty('operator')){
            if(x.operator==FilterOperators.or){
            
            showRow = showRow || this.compareValues(columnValue,x.value, x.comparer);
            }
            else 
            showRow = showRow && this.compareValues(columnValue,x.value, x.comparer)
        } else{
            showRow = showRow && this.compareValues(columnValue,x.value, x.comparer)
        }
        return showRow;
    }

    private static compareValues(x, y, comparer : IFilterComparer){
        if(Array.isArray(y)){
            return ArrayFunctions.inArray(x, y);
        } else {
            switch(comparer){
            case IFilterComparer.less:
                return x < y;
            case IFilterComparer.lessOrEqual:
                return x <= y;
            case IFilterComparer.more:
                return x > y;
            case IFilterComparer.moreOrEqual:
                return x >= y;
            default: 
                return x == y;
            }
        }
    }
}