<mat-form-field class="col-md-offset-3" class="filterLeftSideBar" appearance="outline">
    <mat-label>{{labelTitle | translate}}</mat-label>
    <mat-chip-list #chipListStatus>
        <mat-chip *ngFor="let chip of selectedChips" selectable="true" removable="true" 
          (removed)="removeChip(chip)">
            {{chip.title}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input #chipInput [matAutocomplete]="auto" [matChipInputFor]="chipListStatus"
            [matChipInputSeparatorKeyCodes]="keyCodes" matChipInputAddOnBlur="false" 
            (keyup)="filterChip($event.target.value)" >
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectChip($event, chipInput)">
        <mat-option *ngFor="let option of filteredChips" [value]="option">
            {{ option.title }}
        </mat-option>
    </mat-autocomplete>


</mat-form-field>