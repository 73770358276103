import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlDataLogicService } from '../../../../shared/bussiness-logic/base/bl-data-logic.service';
import { ITableStorage } from '../../../../shared/bussiness-logic/interfaces/i-storage';
import { IFilterColumn } from '../../../../shared/interfaces/i-filter-column';
import { IBackpackProblem } from '../../interfaces/i-backpack-problem';
import { BackpackProblemService } from '../api/backpack-problem.service';

@Injectable({
  providedIn: 'root'
})
export class BlBackpackProblemDataService extends BlDataLogicService implements ITableStorage<IBackpackProblem>{

  constructor(
    private apiService: BackpackProblemService
  ) {
    super();
  }

  public originalObject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  idForPatch : number = -1;
  // clickedRun : boolean = false;
  // clickedView : boolean = false;
  // patchFlag : boolean = false;
  // inserted : boolean = false;

  _paginationGoToId: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  storage: BehaviorSubject<IBackpackProblem[]> = new BehaviorSubject<IBackpackProblem[]>([]);

  originalData: IBackpackProblem[];

  searchValue: BehaviorSubject<string> = new BehaviorSubject<string>("");

  filtersStorage: BehaviorSubject<IFilterColumn[]>;

  //ovo su storidzi za BackpackProblem //
  // public storageIsSavesButtonView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //storage kojim se setuje dal se vide save dugmici ili ne
  //public storageDataIsRightBlockView: BehaviorSubject<any> = new BehaviorSubject<any>(false); // storage kojim se setuje dal se vidi desni blok strane
  // public storageDataProblemLoad: BehaviorSubject<any> = new BehaviorSubject<any>(false); // storage kojim se setuje dal se vidi dashboar nakon uspejsnog applye na new ili view problema
  // public storageDataFromFormDialog: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage koji sluzi da setuje kolonu sa vrednostima i prvog taba (new  button)
  // public storageDataViewDialog: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storage koji se setuje vrednostima iz dijaloga za prikaz (open  button)
  // public storageDataisFromViewDialog: BehaviorSubject<any> = new BehaviorSubject<any>(false); // storage kojim se pamti stanje true/false da bi znali dal da uzimamo podatke iz ovog ili storage za form dialog
  // public storageDataNewForm: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storidz samo za podatke NOVE
  // public storageDataViewForm: BehaviorSubject<any> = new BehaviorSubject<any>(null); // storidz samo za podatke iz view dialoga -- POSTOJECE
  // public storageIsExportShow: BehaviorSubject<any> = new BehaviorSubject<any>(false);  // ovim setujemo dal vidimo dal je export dugme vidljivo tj dal je problem snimljen
  //public storageSelectedFirstTab: BehaviorSubject<any> = new BehaviorSubject<any>(0);  // ovaj storage je default 0 i ne setuje se nidje vec na prikaz ostalih tabova se poziva kako bi se postavio inicijalno prvi tab setovan nakon otvaranja drugog problema
  public storageDataResults: BehaviorSubject<any> = new BehaviorSubject<any>(null);  // ovaj storage za setovanje DESNOG BLOKA NAKON KLIKA NA RUN
  // public storageTypeProblem: BehaviorSubject<any> = new BehaviorSubject<any>("");  // pvaj storage je da se zna dal je izabrana literatua ili custom problem iz open dijaloga
  // END ovo su storidzi za BackpackProblem //

  showDashboard: boolean = false;
  threeDTableRecords;
  show3dTable: boolean = false;
  selectedIndex: number = 0;

  public containerNumberOfColumns: number = 5;
  public itemNumberOfColumns: number = 5;


  getOriginalData(): IBackpackProblem[] {
    return this.originalData;
  }

  setOriginalData(items: IBackpackProblem[]): void {
    this.originalData = items;
  }

  getStorage(): Observable<IBackpackProblem[]> {
    return this.storage.asObservable();
  }

  setStorage(items: IBackpackProblem[]): void {
    this.originalData = items;
    this.storage.next(items);
  }

  getCurrentValue(): IBackpackProblem[] {
    return this.storage.getValue();
  }

  filterStorageBy(filters: IFilterColumn[], customDataToFilter?: IBackpackProblem[], setCurrentStorageValueAsOriginalData?: boolean): void {
    if ((!this.originalData) || setCurrentStorageValueAsOriginalData) {
      this.originalData = this.getCurrentValue();
    }
    let dataToFilter = cloneDeep(this.originalData);
    super.setStorageWithFilteredDataFromApiOrCache(filters, dataToFilter, this.storage, this.apiService);
  }

  // setRun(state:boolean){
  //   this.clickedRun = state;
  // }

  // setView(state:boolean){
  //   this.clickedView = state;
  // }

  setIdForPatch(id:number){
    this.idForPatch = id;
  }

  // insert(state : boolean){
  //   this.inserted = state;
  // }

}