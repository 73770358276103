import { AbstractControl, ValidationErrors } from "@angular/forms";

// export function whitespaceValidator(control: AbstractControl){
//     const isWhitespace = (control.value || '').trim().length === 0 || (control.value as string).startsWith(' ') || (control.value as string).endsWith(' ');
//     const isValid = !isWhitespace;
//     return isValid ? null : { 'whitespace': true };
//   }

export function whitespaceValidator(control: AbstractControl) : ValidationErrors | null {
  return control.value ? 
  (control.value.toString() || '').trim().length === 0 || 
  (control.value.toString()).startsWith(' ') || 
  (control.value.toString()).endsWith(' ') ? {'noWhiteSpace': true} : null : null;
  
}