import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlBackpackProblemFormService } from '../../services/forms/bl-backpack-problem-form.service';
import { BlBackpackProblemDataService } from '../../services/shared/bl-backpack-problem-data.service';

@Component({
  selector: 'app-dashboard-backpack-problem',
  templateUrl: './dashboard-backpack-problem.component.html',
  styleUrls: ['./dashboard-backpack-problem.component.scss']
})
export class DashboardBackpackProblemComponent implements OnInit, OnDestroy {

  constructor(
    public dataService: BlBackpackProblemDataService,
    public formServices: BlBackpackProblemFormService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.formServices.reset();
    this.formServices.reducedItemsArray = [];
    this.formServices.reducedContainersArray = [];
    this.dataService.showDashboard = false;
    this.dataService.threeDTableRecords = [];
    this.dataService.show3dTable = false;
    
    this.dataService.originalObject.next(null);
    this.dataService.storageDataResults.next(null);
  }

}
