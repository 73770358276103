import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DynamicAlgorithmFormControlsService } from '../../../../../../../../../../core/services/dynamic-algorithm-form-controls.service';
import { FormGroupTypeSafe } from '../../../../../../../../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../../../../../../../../shared/services/alert-message-data.service';
import { IPfspDropdownData, IPfspForm } from '../../../../../../../../interfaces/i-pfsp';
import { BlPfspFormService } from '../../../../../../../../services/form/bl-pfsp-form.service';
import { BlPfspRequestsService } from '../../../../../../../../services/requests/bl-pfsp-requests.service';
import { BlPfspDataService } from '../../../../../../../../services/shared/bl-pfsp-data.service';
import { BlPfspResultTableService } from '../../../../../../../../services/table/bl-pfsp-result-table.service';

@Component({
  selector: 'app-tab-dashboard-input-data-left-side',
  templateUrl: './tab-dashboard-input-data-left-side.component.html',
  styleUrls: ['./tab-dashboard-input-data-left-side.component.scss']
})
export class TabDashboardInputDataLeftSideComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dataService: BlPfspDataService,
    public formService: BlPfspFormService,
    public tableService: BlPfspResultTableService,
    private alertService: AlertMessageDataService,
    public translateService: TranslateService,
    private requestService: BlPfspRequestsService,
    private cdr: ChangeDetectorRef,
    private dynamicAlgorithmFormControlsService: DynamicAlgorithmFormControlsService
  ) { }

  public clickedButton: boolean = false;
  public isViewLoad: boolean = false;
  private dataForSend: any = [];

  private subscription: Subscription = new Subscription();

  public form: FormGroupTypeSafe<IPfspForm> = this.formService.form;
  public numbers: any = [];

  public dropdownData: IPfspDropdownData = {
    ProblemTypes: null,
    Algorithms: null,
    TargetFunctions: null,
    Methods: null,
    Pfsps: null
  }

  ngAfterViewInit(): void {
    this.dataService.storageDataIsShowOtherTabs.subscribe({
      next: data => {
        this.formService.isShowOtherTabs = data;
      }
    });

    this.getObjectives();
    this.getAlgorithms();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

    this.subscription.add(
      this.dataService.originalObject.subscribe({
        next: data => {
          if(data) {
            this.formService.numberRows = data.m;
            this.formService.numberCols = data.n;
            if(data.id) {
              this.isViewLoad = true;
              if (this.isViewLoad) {
                this.isViewLoad = false;

                if (this.formService.numberCols && this.formService.numberCols > 0) {
                  this.tableService.createDynamicHeaderColumnsTable(this.formService.numberCols)
                  this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                  this.formService.setRows(data);
                }
              }
            } else {
              this.isViewLoad = true;
              if (this.isViewLoad) {
                this.isViewLoad = false;

                this.numbers = Array(this.formService.numberCols).fill(0).map((x, i) => i);
                this.tableService.createDynamicHeaderColumnsTable(this.formService.numberCols);

                if (this.formService.numberRows && this.formService.numberRows > 0) {
                  this.formService.createTableWithDynamicRows(this.formService.numberCols, this.formService.numberRows);
                }
              }
            }
          }
        }
      })
    );
  }

  getAlgorithms() {
    this.subscription.add(
      this.requestService.getAlgorithms().subscribe({
        next: data => {
          if(data) {
            this.dropdownData.Algorithms = data;
            this.formService.algorithmData = data;
            this.formService.form.getSafe(x => x.algorithm).setValue(data[0].id);
          }
        }
      })
    );
  }

  getObjectives() {
    this.subscription.add(
      this.requestService.getObjectives().subscribe({
        next: data => {
          if(data) {
            this.dropdownData.TargetFunctions = data;
            this.formService.form.getSafe(x => x.targetFunction).setValue(1);
          }
        }
      })
    );
  }

  changeAlgorithm(value): void {
    const choosenAlgorithm = cloneDeep(this.dropdownData.Algorithms).find(x => x.id === value);
    this.dynamicAlgorithmFormControlsService.createAlgorithmParamsFormControls(choosenAlgorithm.parametri, this.form);
    this.formService.parametri = Object.keys(choosenAlgorithm.parametri);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.numberRows = 0;
    this.formService.numberCols = 0;
    this.clickedButton = false;
    this.numbers = [];
  }

}
