import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../shared/bussiness-logic/base/bl-request.service';
import { RegisterService } from '../api/register.service';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { RegisterActivationService } from '../api/register-activation.service';

@Injectable({
  providedIn: 'root'
})
export class BlRegisterRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: RegisterService,
    private registerActivation: RegisterActivationService
  ) {
    super(alertService);
  }

  registerUser(dataToSend: any) {
    return this.apiService.create(dataToSend);
  }

  activationUser(dataToSend: any) {
    return this.registerActivation.create(dataToSend);
  }
}
