<div class="row">
    <div class="col-md-12 section-content" [ngClass]="isBackgroundImage ? 'bg-image' : null">
        <app-header-dashboard-deterministic-mrp>
        </app-header-dashboard-deterministic-mrp>
        <ng-container *ngIf="formService.isShowMainTabs">
            <app-tabs-dashboard-deterministic-mrp>
            </app-tabs-dashboard-deterministic-mrp>
        </ng-container>
    </div>
</div>
