<style>
    @media print {
            html,
            body {
                height: 99%;
            }

            #reportButton,
            #title {
                display: none;
            }
        }
    </style>

<form class="reducedForm" [formGroup]="formService.form">
        <div class="row">
            <div class="col-sm-12">
                <h3 class="page-header-title">
                    {{"PFSP" | translate}} - {{"Test" | translate}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 col-lg-2 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('targetFunction').errors ? 'requiredField' : ''">
                    <mat-label>{{ "Objective" | translate}}</mat-label>
                    <mat-select [formControlName]="formService.form.keys.targetFunction" disableOptionCentering
                        multiple>
                        <mat-option *ngFor="let option of dropdownData.TargetFunctions" [value]="option.id">
                            {{option.title | translate}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('targetFunction').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('algorithm').errors ? 'requiredField' : ''">
                    <mat-label>{{ "Algorithm" | translate}}</mat-label>
                    <mat-select [formControlName]="formService.form.keys.algorithm" disableOptionCentering multiple>
                        <mat-option (onSelectionChange)="changeAlgorithmOptions($event.source)" *ngFor="let option of dropdownData.Algorithms" [value]="option.id">
                            {{option.title | translate}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('algorithm').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-2 input-mobile">
                <mat-form-field appearance="outline" [ngClass]="formService.form.get('problem').errors ? 'requiredField' : ''">
                    <mat-label>{{ "Instance" | translate}}</mat-label>
                    <mat-select [formControlName]="formService.form.keys.problem" disableOptionCentering multiple>
                        <mat-option *ngFor="let option of dropdownData.Pfsps" [value]="option.id">
                            {{option.title}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="customError">
                        <ng-container *ngIf="formService.form.get('problem').hasError('required')">
                            {{"ThisFieldIsRequired" | translate}}
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-5 btn-run">
                <button mat-flat-button color="accent" type="submit" disableRipple [disabled]="!this.formService.form.valid"
                    (click)="runTest()">{{'Run'
                    |
                    translate}}</button>
            </div>
        </div>

        <ng-container *ngIf="formService.form.get('algorithmParams')['controls']" formArrayName="algorithmParams">
            <div class="row" *ngFor="let item of parametri; let i=index">
                <div class="col-sm-6 col-md-3 col-lg-2 input-mobile"></div>
                <div class="col-sm-6 col-md-3 col-lg-3 input-mobile">
                    <ng-container *ngIf="(item.parametri | json) != ({} | json)">{{item.title}}</ng-container>
                    <ng-container [formArrayName]="i">
                        <ng-container *ngFor="let item2 of item.parametri | keyvalue; let j=index">
                            <div class="row">
                            <div class="col-md-12">
                                <mat-form-field appearance="outline">
                                <mat-label>{{item2.key | translate}}</mat-label>
                                <input [formControl]="formService.form.get('algorithmParams')['controls'][i].get('values')['controls'][j].get('value')" matInput
                                    autocomplete="off" type="number">
                                </mat-form-field>
                            </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <hr *ngIf="(item.parametri | json) != ({} | json)"/>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-2 input-mobile"></div>
                <div class="col-sm-6 col-md-3 col-lg-5 btn-run"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="formService.isShowReportTable">
            <div class="row mt-15">
                <div class="col-md-12 header-button-box">
                    <button (click)="printReport()" id="reportButton" mat-stroked-button color="accent">
                        <mat-icon>print</mat-icon>&nbsp;<span>{{"Print" | translate}}</span>
                    </button>
                </div>
            </div>
            <div class="row mt-15" id="report">
                <div class="col-md-12 dynamic-column">
                    <ng-container *ngIf="dataForTable; else noAllTableData;">
                        <ng-container *ngFor="let data of dataForTable; let i = index;">
                            <div class="row">
                                <div class="col-sm-12">
                                    <span class="table-title" [innerHTML]="data.nazivTabele | safeHtml"></span>
                                    <span class="table-title" [innerHTML]="data.opis | safeHtml"></span>
                                </div>
                            </div>
                            <table class="table table-sm table-dynamic">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th *ngFor="let header of data.table.tableHeader">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let tableRow of data.table.tableBody; let j=index;">
                                        <td>{{j+1}}</td>
                                        <ng-container *ngFor="let columns of tableRow.stavke">
                                            <td [ngStyle]="(columns.style != '') ? {'background': columns.style} : {'background': '#fff'}">
                                                {{columns.value}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                    </ng-container>
                    <ng-template #noAllTableData>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="alert alert-warning" role="alert">
                                    {{"NoRecordsFound" | translate}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-container>
</form>
