import { LocalStorageKeys } from '../enums/local-storage-keys';
import { LocalStorageItem } from '../interfaces/local-storage-item';

export class LocalStorageFunctions {

    static get(key : LocalStorageKeys) : any {
        let value = localStorage.getItem(key);
        if(LocalStorageFunctions.IsJsonString(value)){
            value = JSON.parse(value);
        }
        return value;
    }

    static set(item : LocalStorageItem){
        localStorage.setItem(item.key, item.value);
    }

    static IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static setCompanyDate(hotelDate: string){
        LocalStorageFunctions.set({
            key: LocalStorageKeys.company_date,
            value: hotelDate
          });
    }

    static deleteAll(){
        localStorage.removeItem(LocalStorageKeys.company_settings);
        localStorage.removeItem(LocalStorageKeys.country);
        // localStorage.removeItem(LocalStorageKeys.lang);
        localStorage.removeItem(LocalStorageKeys.last_event_time);
        localStorage.removeItem(LocalStorageKeys.company_date);
    }
}