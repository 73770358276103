
            <table class="table table-sm table-striped">
                <tbody>
                    <tr>
                        <td><b>{{"Number Of Cells" | translate}}</b>:</td>
                        <td class="results-info">{{dataFromStorage?.rowsTab7.numberOfCells}}</td>
                    </tr>
                    <tr>
                        <td><b>{{"Exceptions" | translate}}</b>:</td>
                        <td class="results-info">{{dataFromStorage?.rowsTab7.exceptions}}</td>
                    </tr>
                    <tr>
                        <td><b>{{"Voids" | translate}}</b>:</td>
                        <td class="results-info">{{dataFromStorage?.rowsTab7.voids}}</td>
                    </tr>
                    <tr>
                        <td><b>{{"Machines" | translate}}</b>:</td>
                        <td class="results-info">{{dataFromStorage?.rowsTab7.machines}}</td>
                    </tr>
                    <tr>
                        <td><b>{{"Parts" | translate}}</b>:</td>
                        <td class="results-info">{{dataFromStorage?.rowsTab7.parts}}</td>
                    </tr>
                </tbody>
            </table>
