import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '../../../../config/api';
import { ApiService } from '../../../../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadPfspCSVService extends ApiService<any>{

  constructor(http: HttpClient) {
    super(apiPaths.order.pfspReportCSV, http);
  }
}
