import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PfspModule } from './pfsp/pfsp.module';

const routes: Routes = [
  {
    path: "pfsp",
    loadChildren: () => PfspModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule { }
