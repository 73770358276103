import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../../config/api';

@Injectable({
  providedIn: 'root'
})
export class SignalRNotificationService extends ApiService<any>{

  constructor(http: HttpClient) {
    super(apiPaths.notificationHub, http);
  }

  public notification = new BehaviorSubject<any>(null);

}
