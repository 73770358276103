import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerFunctions } from '../../../../../../../shared/functions/spinner-functions';
import { AlertMessageDataService } from '../../../../../../../shared/services/alert-message-data.service';
import { IMapDownload } from '../../../../../../interfaces/i-admin-config-routing';
import { ShowAreaOnMapService } from '../../../../../../services/api/show-area-on-map.service';
import { BlShowAreaOnMapFormService } from '../../../../../../services/forms/bl-show-area-on-map-form.service';
import { BlShowAreaOnMapRequestsService } from '../../../../../../services/requests/bl-show-area-on-map-requests.service';
import { BlShowAreaOnMapDataService } from '../../../../../../services/shared/bl-show-area-on-map-data.service';

@Component({
  selector: 'app-show-area-on-map',
  templateUrl: './show-area-on-map.component.html',
  styleUrls: ['./show-area-on-map.component.scss']
})
export class ShowAreaOnMapComponent implements OnInit, OnDestroy {

  constructor(
    public apiService: ShowAreaOnMapService,
    public dataService: BlShowAreaOnMapDataService,
    public requestsService: BlShowAreaOnMapRequestsService,
    public formService: BlShowAreaOnMapFormService,
    private alertService: AlertMessageDataService
  ) {}

  freeMaps: IMapDownload[] = [];
  occupiedMaps: IMapDownload[] = [];
  isMapVisible = false;

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.getAllMaps();
  }

  getAllMaps(): void {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.requestsService.getAllMaps().subscribe({
        next: data => {
          this.freeMaps = data.free;
          this.occupiedMaps = data.occupied;
          SpinnerFunctions.hideSpinner();
        },
        error: err => {
          SpinnerFunctions.hideSpinner();
        }
      })
    )
  }

  showMap(): void {
    SpinnerFunctions.showSpinner();
    this.subscription.add(
      this.formService.getMap().subscribe({
        next: data => {
          SpinnerFunctions.hideSpinner();
          this.dataService.coordinatesForMainMap = data;
          this.isMapVisible = true;
        },
        error: err => {
          SpinnerFunctions.hideSpinner();
          this.alertService.setMessage("danger", "Download error!");
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formService.reset()
  }

}
