<form class="custom-form" [formGroup]="form">

  <div class="row">
    <div class="col-sm-12 adjust-lines">
      <mat-form-field color="primary" appearance='outline'>
        <mat-label>{{'Linewidth' | translate}}</mat-label>
        <input matInput type="number" [formControlName]="formService.form.keys.width" />
      </mat-form-field>
    </div>
  </div>

  @if (dataService.allCoordinatesForMainMap && dataService.allCoordinatesForMainMap.routes) {
    <div class="row">
      <div class="col-md-12 show-routes">
        <p>{{"ShowHideRoutes" | translate}}</p>
      </div>
      @for (route of dataService.allCoordinatesForMainMap.routes; track route) {
        <div class="col-md-12">
          <mat-checkbox color="primary"
            (change)="changeRoutes($event, route.color, route.id)" [checked]="true" >
            <input [disabled]="true" class="polylineColorInput" matInput type='color' [value]="route.color" />
          </mat-checkbox>
        </div>
      }
    </div>
  }

</form>
