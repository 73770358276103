<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close()">close</mat-icon>
        </div>
    </div>
</div>

<form [formGroup]="form" class="reducedForm" (ngSubmit)="save()">
    <div mat-dialog-content>

        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{ "Algorithm" | translate }}
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>{{"ChooseAlgorithm" | translate}}</mat-label>
                    <mat-select [formControlName]="form.keys.algorithm">
                        <mat-option *ngFor="let algorithm of dataService.problemAlgorithms" [value]="algorithm.code">{{algorithm.title}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="dataService.typeOfMap != 'graph'">
            <div class="col-sm-12">
                <mat-radio-group formControlName="routeType" class="flexCenter">
                    <mat-radio-button value="f" class="mr-25">{{"FastestRoute" | translate}}
                    </mat-radio-button>
                    <mat-radio-button value="s">{{"ShortestRoute" | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <div class="row full-width">
            <app-button-with-spinner class="full-width"
            [isClickedSave]="isClickedSave"
            buttonText = "Run"
            [form]="form">
            </app-button-with-spinner>
        </div>
    </div>
</form>
