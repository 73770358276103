<ng-container *ngIf="options && (setup.isAllowedOptionsEmptyArray? true: (isOptionsCame)); else spinner">
    <mat-form-field appearance="outline" [ngClass]="(formControlToFill.invalid)? 'requiredField': false">
        <mat-label>{{ labelText | translate}}</mat-label>
        <mat-select multiple (openedChange)="openedChange($event)" class="virtual-scroll" [(ngModel)]="selected" [formControl]="formControlToFill"
            disableOptionCentering>
            
            <mat-select-search [formControl]="filterControl"></mat-select-search>

            <cdk-virtual-scroll-viewport class="example-viewport-select" minBufferPx="200" maxBufferPx="400"
                [itemSize]="36" [style.height.px]="options.length > 5 ? 6 * 36 : (options.length + 1) * 36">
                <ng-container *ngIf="options.length > 0">
                    <mat-option [value]="selectAllValue" #allSelect (click)="selectAll(allSelect.selected)">
                        {{"SelectAll" | translate}}
                    </mat-option>
                </ng-container>

                <mat-option *cdkVirtualFor="let option of options" [value]="option.id" [disabled]="setup.isDisabledAllOptions" (onSelectionChange)="onSelection($event)" >
                    <span *ngIf="!option.isNotFound else deletedOption">
                        {{option.title}}
                    </span>
                    <ng-template #deletedOption>
                        <span >
                            {{option.title | translate}} 

                            <a (mouseover)="showDeletedOptionInfo(option.id)" class="deleted-link-cursor" [ngbPopover]="popContent" popoverTitle="{{'Info' | translate}}" placement="bottom" popoverClass="vscrollPopoverBottom" triggers="mouseenter:mouseleave" container="body">
                                #{{ option.id }}
                            </a>

                            <ng-template #popContent>
                                <ng-container *ngIf="deletedOptionInfo && deletedOptionStatus">
                                    <p class="deleted-option-status-info"><b>{{'Status' | translate}}:</b> {{ deletedOptionStatus | translate }}</p>
                                    <div class="deleted-option-list-wrapper" [innerHTML]="deletedOptionInfo | safeHtml"></div>
                                </ng-container>
                            </ng-template>
                        </span>
                    </ng-template>
                </mat-option>

            </cdk-virtual-scroll-viewport>
        </mat-select>
        <mat-error *ngIf="formControlToFill.hasError('required')" class="customError">
            {{'ThisFieldIsRequired' | translate}}
        </mat-error>
    </mat-form-field>
</ng-container>
<ng-template #spinner>
    <app-input-with-spinner inputLabel="{{labelText}}"></app-input-with-spinner>
</ng-template>