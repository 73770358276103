<div [ngClass]="!(dataService.originalObject.getValue() || mainTitle || dataService.storageIsRunResults.getValue()) ? 'fixed-page-header' : 'relative-page-header'">
    <div class="row">
        <!-- Header PFSP -->
        <div class="col-md-12">
            <h3 class="page-header-title"> <span>{{"Scheduling" | translate}}</span> / {{"PFSP" | translate}}</h3>
        </div>
        <!-- End Header PFSP -->
    </div>

    <div class="row">
        <!-- Buttons for Add,  View -->
        <div class="col-md-12">
            <div class="header-pfsp">
                <div class="header-button-box" [ngClass]="formService.isShowSavesButtons ? 'all-buttons' : null">
                    <button type="button" mat-stroked-button color="accent" (click)="newPFSP()" [disabled]="!permissionsService.havePermission('pfsp', 'ability-to-create-new-problem')">
                        <mat-icon>create</mat-icon>{{"New" | translate}}
                    </button>
                    <button type="button" mat-stroked-button color="accent" (click)="viewPFSP()" [disabled]="!permissionsService.havePermission('pfsp', 'ability-to-open-all-problems')">
                        <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
                    </button>
                    <button type="button" mat-stroked-button color="accent" (click)="testPFSP()" [disabled]="!permissionsService.havePermission('pfsp', 'ability-to-test-problem')">
                        <mat-icon>sticky_note_2</mat-icon>{{"Test" | translate}}
                    </button>
                    <ng-container *ngIf="formService.isShowSavesButtons == true">
                        <ng-container *ngIf="isLiteratureProblem != true">
                            <button type="button" mat-stroked-button color="accent" (click)="saveDatas()" [disabled]="!permissionsService.havePermission('pfsp', 'ability-to-save-problem') || !this.formService.form.valid">
                                <mat-icon>album</mat-icon>{{"Save" | translate}}
                            </button>
                        </ng-container>
                        <button type="button" mat-stroked-button color="accent" *ngIf="dataService.idForPatch && dataService.idForPatch != -1"
                            (click)="saveAsNew()" [disabled]="(!permissionsService.havePermission('pfsp', 'ability-to-save-as') || !this.formService.form.valid)">
                            <mat-icon>album</mat-icon>{{"Save As" | translate}}
                        </button>
                        <button type="button" mat-stroked-button color="accent" (click)="loadProblem()" [disabled]="(!permissionsService.havePermission('pfsp', 'ability-to-load-problem') || !this.formService.form.valid)">
                            <mat-icon>system_update_alt</mat-icon>{{"Load" | translate}}
                        </button>
                        <button type="button" mat-stroked-button color="accent" (click)="sortProblem()" [disabled]="(!permissionsService.havePermission('pfsp', 'ability-to-sort-problem') || !this.formService.form.valid)">
                            <mat-icon>filter_list</mat-icon>{{"Sort" | translate}}
                        </button>
                        <button type="button" mat-stroked-button color="accent" (click)="runProblem()" [disabled]="(!permissionsService.havePermission('pfsp', 'ability-to-run-problem') || !this.formService.form.valid)">
                            <mat-icon>school</mat-icon>{{"Run" | translate}}
                        </button>
                        <ng-container *ngIf="formService.isRunProblemForTitle == true; else noRunReport">
                            <span class="problem-title" [innerHTML]="formService.problemTitle | safeHtml">
                            </span>
                        </ng-container>
                        <ng-template #noRunReport>
                            <span class="problem-title" *ngIf="dataService.originalObject.getValue() || mainTitle"
                                [innerHTML]="dataService.originalObject.getValue() ?
                      (dataService.originalObject.getValue()[0] ?
                      dataService.originalObject.getValue()[0].title :
                      dataService.originalObject.getValue().title) : mainTitle">
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
                <div class="help-block">
                    <ng-container *ngIf="formService.isExportShow==true">
                        <button type="button" mat-stroked-button color="accent" (click)="export()">
                            <mat-icon>save_alt</mat-icon>{{"Export CSV" | translate}}
                        </button>
                    </ng-container>
                    <button type="button" mat-stroked-button color="accent" (click)="openMarkdown()">
                        <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
                    </button>
                </div>
            </div>
        </div>
        <!-- End Buttons for Add,  View -->
    </div>

    <div class="row">
        <div class="col-md-12 iframe-wrapper" *ngIf="!(dataService.originalObject.getValue() || mainTitle || dataService.storageIsRunResults.getValue())">
            <div class="iframe-holder">
                <iframe src="assets/markdown/scheduling/pfsp/help.html" width="100%" height="100%">
                </iframe>
            </div>
        </div>
    </div>
</div>
