<!-- Header Dialog-->
<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-12 closeIconBox">
            <mat-icon class="closeIconBold" (click)="close(false)">close</mat-icon>
        </div>
    </div>
</div>
<!-- End Header Dialog-->
<!-- Form Dialog-->
<form class="reducedForm" [formGroup]="formService.form" *ngIf="showForm">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12 formHeading">
                <h3 class="modalHeading">
                    {{"Containers" | translate}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-10">
                <button class="add-fab-btn bg-green" type="button" mat-mini-fab color="accent"
                (click)="formService.addContainer()">
                  <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <cdk-virtual-scroll-viewport
                    [ngStyle]="{'height' : (formService.form.get('containers')['controls']?.length + 1)*30+'px'}"
                    style="width: 100%; overflow-y: auto;"
                    minBufferPx="200"
                    maxBufferPx="600"
                    [itemSize]="30">
                    <div>
                        <ng-container *ngIf="formService.form.get('containers')['controls']?.length > 0; else noAllTableData;">
                            <table class="table table-sm table-striped tab-input-data table-pfsp table-packing">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th *ngFor="let header of tableService.headersTable2">{{header.title}}</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of formService.form.get('containers')['controls']; let i=index">
                                        <tr>
                                            <td>{{i+1}}</td>
                                            <td *ngFor="let cols of numbers; let j=index;">
                                                <mat-form-field appearance="outline">
                                                    <input [formControl]="formService.form.get('containers')['controls'][i].get('c'+j)"
                                                        matInput autocomplete="off" />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <button class="remove-fab-btn" *ngIf="i > 0" mat-mini-fab color="warn"
                                                    (click)="formService.removeContainer(i)" type="button">
                                                    <mat-icon>remove_circle</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </ng-container>
                        <ng-template #noAllTableData>
                            <div class="alert alert-warning" role="alert">
                                {{"NoRecordsFound" | translate}}
                            </div>
                        </ng-template>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="row" style="width: 100%;">
            <div class="col-sm-12 dialog-action-box">
                <button mat-flat-button color="accent" disableRipple [disabled]="!form.get('containers').valid" (click)="save()">{{"Save" | translate}}</button>
                <button mat-flat-button color="warn" disableRipple (click)="close()">{{"Close" | translate}}</button>
            </div>
        </div>
    </div>
</form>
<!-- End Form Dialog-->
