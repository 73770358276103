import { Injectable } from '@angular/core';
import { INotification } from '../../interfaces/i-notification';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../shared/services/api.service';
import { apiPaths } from '../../../../config/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService<INotification> {

  constructor(http: HttpClient) {
    super(apiPaths.notification, http);
  }
}
