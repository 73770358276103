import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoPositioningService } from '../../../../../../../../shared/components/osm-view/geo-positioning.service';
import { LatLong } from '../../../../../../../../shared/consts/lat-long';
import { SpinnerFunctions } from '../../../../../../../../shared/functions/spinner-functions';
import { ValidationFunctions } from '../../../../../../../../shared/functions/validation-functions';
import { IPosition } from '../../../../../../../../shared/interfaces/i-position';
import { AlertMessageDataService } from '../../../../../../../../shared/services/alert-message-data.service';
import { BlGeoLocationBackwardFormService } from '../../../../../../services/forms/bl-geo-location-backward-form.service';
import { BlGeoLocationBackwardRequestsService } from '../../../../../../services/requests/bl-geo-location-backward-requests.service';
import { BlGeoLocationDataService } from '../../../../../../services/shared/bl-geo-location-data.service';
import { GeoLocationTableComponent } from '../geo-location-table/geo-location-table.component';


@Component({
  selector: 'app-backward-geo-location',
  templateUrl: './backward-geo-location.component.html',
  styleUrls: ['./backward-geo-location.component.scss']
})
export class BackwardGeoLocationComponent implements OnInit {

  constructor(
    public formService: BlGeoLocationBackwardFormService,
    private alertService: AlertMessageDataService,
    public geoPositioningService: GeoPositioningService,
    public dataService: BlGeoLocationDataService,
    private requestsService: BlGeoLocationBackwardRequestsService
  ) {}

  @ViewChild(GeoLocationTableComponent) geoLocationTableComponent: GeoLocationTableComponent;

  selectedItem: any = {};
  columnsForItem: [] = [];

  markers: IPosition[] = [{
    latitude: 0,
    longitude: 0
  }];

  zoomIn: IPosition;
  showMap: boolean = false;

  ngOnInit(): void {
    ValidationFunctions.runValidation(this.formService.form);

    this.markers[0] = {
      longitude: LatLong.longitude,
      latitude: LatLong.latitude
    }
    this.zoomIn = {
      longitude: LatLong.longitude,
      latitude: LatLong.latitude
    }
    this.getUsersCurrentLocation();
  }

  getUsersCurrentLocation(zoomOnly: boolean = false): void {
    SpinnerFunctions.showSpinner();

    this.geoPositioningService.getLocation().subscribe({
      next: data => {
      SpinnerFunctions.hideSpinner();

      const latitude = parseFloat(data.coords.latitude);
      const longitude = parseFloat(data.coords.longitude);

      if (!zoomOnly) {
        this.formService.setLatitude(latitude);
        this.formService.setLongitude(longitude);
        this.markers[0] = {
          longitude: longitude,
          latitude: latitude
        }
      }

      this.zoomIn = {
        longitude: longitude,
        latitude: latitude
      }
    }, 
    error: error => {
      SpinnerFunctions.hideSpinner();
      this.alertService.setMessage('danger', error);

      this.formService.setLatitude(LatLong.latitude);
      this.formService.setLongitude(LatLong.longitude);
      this.markers[0] = {
        longitude: LatLong.longitude,
        latitude: LatLong.latitude
      }
      }
    })
  }

  showMyLocation(): void {
    this.getUsersCurrentLocation();
  }

  search(): void {
    SpinnerFunctions.showSpinner();
    const objectForSearch = this.formService.prepareDataToSend();
    this.requestsService.search(objectForSearch).subscribe({
      next: data => {
        this.geoLocationTableComponent.generateTable(data);
        SpinnerFunctions.hideSpinner();
      }, 
      error: err => {
        SpinnerFunctions.hideSpinner();
      }
    });
  }

}
