import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { IPfspTestForm } from '../../interfaces/i-pfsp';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ValidationFunctions } from '../../../../shared/functions/validation-functions';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BlPfspTestFormService implements IFormService<IPfspTestForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
  ) { }

  public isShowReportTable: boolean = false;

  form: FormGroupTypeSafe<IPfspTestForm> = this.init();

  init(): FormGroupTypeSafe<IPfspTestForm> {
    let group: FormGroupTypeSafe<IPfspTestForm> = this.formBuilder.group<IPfspTestForm>({
      targetFunction: this.formBuilder.control("", [Validators.required]),
      algorithm: this.formBuilder.control("", [Validators.required]),
      problem: this.formBuilder.control("", [Validators.required]),
      algorithmParams: this.formBuilder.array([])
    });
    ValidationFunctions.runValidation(group);
    return group;
  }

  getFormReference(): FormGroupTypeSafe<IPfspTestForm> {
    return this.form;
  }

  reset(): void {
    this.form = this.init();
  }

}