import { Pipe, PipeTransform } from '@angular/core';
import { ColumnOption } from '../../interfaces/column-option';

@Pipe({
  name: 'optionClass'
})
export class OptionClassPipe implements PipeTransform {

  transform(option: ColumnOption): string {
    return option.hasOwnProperty('class')? option.class : 'mat-flat-button';
  }

}
