import { Injectable } from '@angular/core';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { ChangePasswordService } from '../api/change-password.service';

@Injectable({
  providedIn: 'root'
})
export class BlChangePasswordRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: ChangePasswordService
  ) {
    super(alertService);
  }

}