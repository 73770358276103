import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BlGeoLocationDataService {

  constructor() {}

  triggerInvalidateSizeOnMap: boolean = false;

}