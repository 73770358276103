<div class="row">
    <div class="col-md-12">
        <app-header-geo-location></app-header-geo-location>
    </div>
</div>
<div class="row geo-location-tab-content">
    <div class="col-md-12">
        <app-tabs-geo-location></app-tabs-geo-location>
    </div>
</div>
