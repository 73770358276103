import { Injectable } from '@angular/core';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';
import { IDistribution1ToNAlghoritm, IDistribution1ToNAlghoritmForm } from '../../interfaces/i-distribution-1-to-n-alghoritm';
import { Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNAlghoritmFormService implements IFormService<IDistribution1ToNAlghoritmForm>{

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private dataService: BlDistribution1ToNDataService
  ) {}

  form: FormGroupTypeSafe<IDistribution1ToNAlghoritmForm> = this.init();

  init(): FormGroupTypeSafe<IDistribution1ToNAlghoritmForm> {
    let group: FormGroupTypeSafe<IDistribution1ToNAlghoritmForm> = this.formBuilder.group<IDistribution1ToNAlghoritmForm>({
      algorithm: this.formBuilder.control("", Validators.required),
      routeType: this.formBuilder.control("", Validators.required),
    });
    return group;
  }

  fillForm(): void {
    this.form.getSafe(x => x.algorithm).setValue(this.dataService.algorithm);
  }

  createAlgorithmObj(): IDistribution1ToNAlghoritm {
    let formValue: IDistribution1ToNAlghoritmForm = this.form.getRawValue();

    let dataToSend: IDistribution1ToNAlghoritm = {
      algorithm: formValue.algorithm,
      routeType: formValue.routeType
    };

    return dataToSend;
  }

  getFormReference(): FormGroupTypeSafe<IDistribution1ToNAlghoritmForm> {
    return this.form;
  }

  reset(): void {
    this.form.reset();
  }

}
