<form class="custom-form" [formGroup]="formService.form">
    <div class="row">

        <div class="col-sm-12 col-md-6">
            <div class="row">
                <div class="col-sm-12 location-box">
                    <div class="inputs">
                        <mat-form-field appearance="outline">
                                <mat-label>{{"Latitude" | translate}}</mat-label>
                                <input type="number" [formControlName]="formService.form.keys.latitude" matInput
                                    autocomplete="off" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                                <mat-label>{{"Longitude" | translate}}</mat-label>
                                <input type="number" [formControlName]="formService.form.keys.longitude" matInput
                                    autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <div class="buttons">
                        <button class="custom-flat-btn show-location"
                            [disabled]="geoPositioningService.turnOnLocationMessage != ''" type="button"
                            mat-flat-button color="primary"
                            (click)="showMyLocation()" [disabled]="formService.form.invalid">
                            {{"GetMyLocation" | translate}}
                        </button>
                        <button class="custom-flat-btn" (click)="search()" mat-flat-button color="primary"
                            [disabled]="formService.form.invalid">
                            {{"Search" | translate}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 map-box">
                    <app-leaflet-map [form]="formService.form" [zoomIn]="zoomIn" [markers]="markers"
                            [invalidateSize]="dataService.triggerInvalidateSizeOnMap">
                    </app-leaflet-map>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 backward-info">
            <app-geo-location-table
                [selectedItem]="selectedItem"
                [columnsForItem]="columnsForItem">
            </app-geo-location-table>
        </div>
    </div>
</form>
