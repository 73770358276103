import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DmrpHelperFunctionsService {

  constructor() { }

  //primjer pravljenja niza stringova mapiranjem i zatim konverzija iz niza stringova u string odvojen zarezom
  mapToSingleArrayFor2Tab(arr: any[], form: any) {
    let namesOfDynamicControls = [];
    Object.keys(arr[0]).forEach(el => {
      if (el.startsWith("c")) {
        namesOfDynamicControls.push(el);
      }
    })
    arr.forEach(el => {
      el.stavke = "";
      el.tempArr = [];
      namesOfDynamicControls.forEach(name => {
        el.tempArr.push(el[name]);
        delete el[name];
      })
      el.stavke = el.tempArr.join(',');
      el.stavke = el.stavke.split(',')
  .filter(function(item){
    return item !== '';
  }).join(",");
      delete el["tempArr"];
    })
    return arr;
  }

  //primjer pravljenja niza stringova mapiranjem
  mapToSingleArray(arr: any[], form: any) {
    let namesOfDynamicControls = [];
    Object.keys(arr[0]).forEach(el => {
      if (el.startsWith("c")) {
        namesOfDynamicControls.push(el);
      }
    })
    arr.forEach(el => {
      el.stavke = "";
      el.tempArr = [];
      namesOfDynamicControls.forEach(name => {
        el.tempArr.push(el[name]);
        delete el[name];
      })

      el.stavke = el.tempArr.join(',');

  delete el["tempArr"];
    })
    return arr;
  }

}
