import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MrpRoutingModule } from './mrp-routing.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MrpRoutingModule,
    SharedModule
  ]
})
export class MrpModule { }
