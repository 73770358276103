export const routingProblems = [
    {
        id: 1,
        value: "ptp/dashboard-distribution",
        title: "Point To Point",
        shortcut: "ptp"
    },
    {
        id: 2,
        value: "sssp/dashboard-distribution",
        title: "Single Source SP",
        shortcut: "sssp"
    },
    {
        id: 3,
        value: "tsp/dashboard-distribution",
        title: "TSP",
        shortcut: "tsp"
    },
    {
        id: 4,
        value: "cvrp/dashboard-distribution",
        title: "Multi destinations centers",
        shortcut: "cvrp"
    }
];
