import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AutoLogoutService {
  public startStopwatch = new BehaviorSubject<boolean>(false);
  public _startStopwatch = this.startStopwatch.asObservable();
  constructor() { }
}
