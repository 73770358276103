<form (ngSubmit)="showMap()" [formGroup]="formService.form" class="reducedForm tableForm mt-20">
    <div class="row">

        <div class="col-md-2">
            <div class="row">
                <div class="col-sm-6 col-md-12">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>{{"Maps" | translate}}</mat-label>
                        <mat-select [formControlName]="formService.form.keys.maps">
                            <mat-option *ngFor="let option of freeMaps" [value]="option.id">
                                {{option.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-6 col-md-12">
                    <button id="showMapBtn" mat-flat-button color="accent" type="submit"
                        [disabled]="formService.form.invalid">{{'ShowMap' | translate}}</button>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isMapVisible">
            <div class="col-md-10 show-area-on-map">
                <app-leaflet-map [markers]="dataService.coordinatesForMainMap.markers"
                    [routes]="dataService.coordinatesForMainMap.routes" [fitBounds]="true" [clickEvent]="false"
                    [getUsersLocationOnInit]="false">
                </app-leaflet-map>
            </div>
        </ng-container>


    </div>
</form>