import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { FormBuilderTypeSafe } from '../../../shared/helpers/reactive-forms-helper';
import { AlertMessageDataService } from '../../../shared/services/alert-message-data.service';
import { ICredentials } from '../../interfaces/i-credentials';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private authService: AuthService,
    private alertService: AlertMessageDataService,
    private router: Router
  ) { }

  public form = this.formBuilder.group<ICredentials>({
    username: this.formBuilder.control("", [Validators.required]),
    password: this.formBuilder.control("", [Validators.required])
  });

  ngOnInit() {
    
  }

  submit() {
    let formValue = this.form.value as ICredentials;

    this.authService.login(formValue).subscribe({
      next: (data) => {
        this.alertService.setMessage("success", "You are successfully logged in.");
        this.router.navigateByUrl("/home");
      }
    })
  }

  //metoda da ne iskace greska u konzoli i da klikom na Enter uradi validaciju logovanja
  textareaEnterPressed($event: KeyboardEvent) {
    $event.preventDefault()
    $event.stopPropagation()

    let formValue = this.form.value as ICredentials;

    this.authService.login(formValue).subscribe({
      next: (data) => {
        this.alertService.setMessage("success", "You are successfully logged in.");
        this.router.navigateByUrl("/home");
      }
    })
  }

}
