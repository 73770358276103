<form [formGroup]="formService.form" class="reducedForm tab-mps">

    <!-- Table MPS Tab -->
    <div class="row">
        <div class="col-md-12">
            <cdk-virtual-scroll-viewport style="width: 100%; height: 450px; overflow-y: auto;" minBufferPx="200"
                maxBufferPx="400" [itemSize]="50">
                <div class="col-md-12">
                    <ng-container *ngIf="formService.form.get('rowsTab3')['controls']?.length > 0; else noAllTableData;">
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th>{{"Item ID" | translate}}</th>
                                    <th>{{"Overdue Requirement" | translate}}</th>
                                    <th *ngFor="let header of tableService.headersTable">{{header.title | translate}} &nbsp;{{header.index}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *cdkVirtualFor="let item of formService.form.get('rowsTab3')['controls']; let i=index">
                                    <tr>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{"Item ID" | translate}}</mat-label>
                                                <input [formControl]="formService.form.get('rowsTab3')['controls'][i].get('itemId')"
                                                    matInput autocomplete="off" />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{"Overdue Requirement" | translate}}</mat-label>
                                                <input [formControl]="formService.form.get('rowsTab3')['controls'][i].get('requirement')"
                                                    matInput autocomplete="off" type="number" min=0 />
                                            </mat-form-field>
                                        </td>
                                        <td *ngFor="let cols of numbers; let j=index;">
                                            <mat-form-field appearance="outline">
                                                <input [formControl]="formService.form.get('rowsTab3')['controls'][i].get('c'+j)" matInput autocomplete="off" />
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-template #noAllTableData>
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th *ngFor="let header of tableService.headersTable">{{header.title | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td [attr.colspan]="formService.colSpan" class="alert alert-warning">
                                        {{'NoRecordsFound' | translate}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <!-- End Table MPS Tab -->
</form>
