<ng-container *ngIf="minDate; else default">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>{{ labelText | translate}}</mat-label>
        <span id="display-picker-value" (click)="picker.open()">{{ formControlToFill.value | date: (type == 'M'? 'MM' : 'yyyy') }}</span>
        <input autocomplete="off" matInput (click)="picker.open()" [formControl]="formControlToFill"
                [matDatepicker]="picker" id="picker-value" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker
        [startView]="type == 'M'? 'year' : 'multi-year'"
        (yearSelected)="chosenHandler($event)"
        (monthSelected)="chosenHandler($event)">
        </mat-datepicker>
    </mat-form-field>
</ng-container>
<ng-template #default>
    <mat-form-field appearance="outline" color="accent">
        <mat-label>{{ labelText | translate}}</mat-label>
        <span id="display-picker-value" (click)="picker.open()">{{ formControlToFill.value | date: (type == 'M'? 'MM' : 'yyyy') }}</span>
        <input autocomplete="off" matInput (click)="picker.open()" [formControl]="formControlToFill"
                [matDatepicker]="picker" id="picker-value">
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker
        [startView]="type == 'M'? 'year' : 'multi-year'"
        (yearSelected)="chosenHandler($event)"
        (monthSelected)="chosenHandler($event)">
        </mat-datepicker>
    </mat-form-field>
</ng-template>
