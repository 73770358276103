import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { IGeoLocationBackwardRequest } from '../../interfaces/i-geo-location-backward';
import { GeoLocationBackwardService } from '../api/geo-location-backward.service';

@Injectable({
  providedIn: 'root'
})
export class BlGeoLocationBackwardRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: GeoLocationBackwardService
  ) {
    super(alertService);
  }

  search(objectForSend: IGeoLocationBackwardRequest): Observable<any> {
    return this.apiService.search(objectForSend);
  }

}