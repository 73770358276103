import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiPaths } from '../../../../config/api';
import { config } from '../../../../config/global';
import { BlRequestService } from '../../../../shared/bussiness-logic/base/bl-request.service';
import { AlertMessageDataService } from '../../../../shared/services/alert-message-data.service';
import { ProblemAlgorithmsService } from '../../../routing-common/business-logic/api/problem-algorithms.service';
import { IProblemAlgorithm } from '../../../routing-common/interfaces/i-problem-algorithm';
import { IDistribution1ToN, IDistribution1ToNRun } from '../../interfaces/i-distribution-1-to-n';
import { Distribution1ToNService } from '../api/distribution-1-to-n.service';
import { BlDistribution1ToNDataService } from '../shared/bl-distribution-1-to-n-data.service';

@Injectable({
  providedIn: 'root'
})
export class BlDistribution1ToNRequestsService extends BlRequestService {

  constructor(
    private alertService: AlertMessageDataService,
    private apiService: Distribution1ToNService,
    private dataService: BlDistribution1ToNDataService,
    private problemAlgorithmsService: ProblemAlgorithmsService,
    private http: HttpClient
  ) {
    super(alertService);
  }

  getRoutes(): Observable<any> {
    return this.apiService.getAll();
  }

  calculate(obj: IDistribution1ToNRun): Observable<any> {
    this.dataService.mainMapFitBounds = true;
    return this.apiService.calculate(obj);
  }

  updateWithoutParams(obj: any){
    return this.apiService.updateWithoutParams(obj);
  }

  insertWithoutParams(obj: any){
    return this.apiService.insertWithoutParams(obj);
  }

  admin(): Observable<any> {
    return this.apiService.admin();
  }

  getAlgorithmProblems(): Observable<IProblemAlgorithm[]> {
    return this.problemAlgorithmsService.getAlgorithmsByProblemType(this.dataService.problemType.shortcut);
  }

}