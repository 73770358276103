import { IGroupProperty } from "../../distribution-1-to-n/interfaces/i-group-property";


export const GROUP_PROPERTIES: IGroupProperty[] = [
    {
        id: 1,
        problems: ["ptp", "sssp", "tsp"]
    },
    {
        id: 2,
        problems: ["cvrp"]
    }
]