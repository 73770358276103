import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { BlTableLogicService } from '../../../../shared/bussiness-logic/base/bl-table-logic.service';
import { TableFunctions } from '../../../../shared/functions/table-functions';
import { ISetOfColumns } from '../../../../shared/interfaces/i-set-of-columns';
import { ITableColumn } from '../../../../shared/interfaces/i-table-column';
import { IGraphNode } from '../../interfaces/i-graph-node';

@Injectable({
  providedIn: 'root'
})
export class BlGraphNodesTableService extends BlTableLogicService<IGraphNode>{

  constructor(
    protected translateService: TranslateService,
    protected tableFunctions: TableFunctions,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(translateService, tableFunctions, matDialog, location);
  }

  public apis = [];
  public idColumn = "";
  
  protected columns: ITableColumn[] = [
    {
      index: "id",
      title: "#",
      hide: true
    },
    {
      index: "name",
      title: "Name",
      hide: false
    }
  ];
  
  protected columnsStorage: BehaviorSubject<ISetOfColumns> = new BehaviorSubject<ISetOfColumns>({
    columns: this.columns,
    displayedColumns: this.displayedColumns
  });

  protected displayedColumns: string[] =  this.columns.map(x => x.index);
  
}