import { TableFunctions } from "./table-functions";
import { Injectable } from "@angular/core";
import { DateFunctions } from "./date-functions";
import { AuthService } from "../../core/services/auth.service";

@Injectable({
    providedIn: 'root'
  })
export class PrintFunctions {

    constructor(
        private tableFunctions: TableFunctions,
        private authService: AuthService
    ) {

    }

    private date = DateFunctions.getTodayHotelDateNoTime();

    public generateHeader(mywindow, title){
        let hotelName = this.getHotelName();

        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('</head><body style="position: relative">');
        mywindow.document.write('<h3 style="font-size: 24px; color: #808080">' + hotelName  + '</h3>');
        mywindow.document.write('<h4 style="font-size: 20px; color: #808080">' + title  + '</h4>');
    }

    private getHotelName(){
        return this.authService.isLoggedIn()? this.authService.getAllInfoFromToken().given_name : '';
    }
    public generateTable(mywindow, columnsToPrint, data){
        mywindow.document.write('<table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">');
        const ths = columnsToPrint.map(x => x.title);
        mywindow.document.write('<thead><tr>');
        for (let i = 0; i < ths.length; i++) {
        mywindow.document.write('<th><p style="font-size: 14px; color: #808080; font-weight: bold; text-transform: capitalize; padding: 5px; vertical-align: middle">' + ths[i] + '</p></th>');
        }
        mywindow.document.write('</tr></thead>');
        mywindow.document.write('<tbody>');
        data.forEach(element => {
            for(var value in element){
                if(element.hasOwnProperty(value)){
                if(typeof(element[value])==='string'){
                    if((element[value] as string).endsWith("T00:00:00")){
                    element[value] = DateFunctions.formatDate(element[value]);
                    }
                }
                }
            }
            });
        for (let i = 0; i <data.length; i++) {
        mywindow.document.write('<tr style="min-height: 50px">');

        const row =data[i];
        const keys = columnsToPrint.map(x => x.index);
        keys.forEach( key => {
            let val = this.tableFunctions.getProperty(row, key) ? this.tableFunctions.getProperty(row, key) : '';
            
            mywindow.document.write('<td style="text-align: center; font-size: 14px; color: #808080; padding: 6px; vertical-align: middle; border-top: 1px solid #808080">'+val+'</td>');
        });
        mywindow.document.write('</tr>');
        }
        mywindow.document.write('</tbody>');
        mywindow.document.write('</table>');
    }

    public generateFooter(mywindow){
        mywindow.document.write('<p style="font-size: 12px; display: flex; justify-content: flex-end; align-items: center;">' + this.date.toDateString() + '/' + this.date.toLocaleTimeString() + '</p>');
        mywindow.document.write('</body></html>');
    }

    public runPrint(mywindow){
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
    }
}