<div [ngClass]="!(dataService.showTabs) ? 'fixed-page-header' : 'relative-page-header'">

  <div class="row">
    <div class="col-md-12">
      <h3 class="page-header-title"> <span>{{"Routing" | translate}}</span> / {{dataService.problemType.title | translate}}</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="header-button-box distribution-box"
        [ngClass]="(dataService.showTabs && dataService.problemType.shortcut == 'cvrp') ? 'all-buttons-and-overview' : (dataService.showTabs ? 'all-buttons' : null)">
        <div class="cta-buttons">

          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="openModalForNew()"
            [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-add-new-problem')">
            <mat-icon>create</mat-icon>{{"New" | translate}}
          </button>

          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="viewDistribution1ToN()">
            <mat-icon>import_contacts</mat-icon>{{"Open" | translate}}
          </button>

          @if (dataService.showTabs) {
            @if (dataService.isCustom) {
              <button type="button" mat-stroked-button color="primary"
                class="custom-stroked-btn"
                (click)="save()"
                [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-save-problem')">
                <mat-icon>create</mat-icon>
                {{"Save" | translate}}
              </button>
            }
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="saveAsNew()"
              [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-save-as')">
              <mat-icon>create</mat-icon>
              {{"Save As" | translate}}
            </button>
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="openModalBeforeCalculate()"
              [disabled]="isRunDisabled()">
              <mat-icon>school</mat-icon>{{"Run" | translate}}
            </button>
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="openSettingsModal()">
              <mat-icon>settings</mat-icon>
              {{"RouteSettings" | translate}}
            </button>
            @if (dataService.problemType.shortcut == 'cvrp') {
              <button type="button" mat-stroked-button color="primary"
                class="custom-stroked-btn"
                (click)="openOverviewModal()" [disabled]="isRunDisabled()">
                <mat-icon>settings</mat-icon>
                {{"Overview" | translate}}
              </button>
            }
          }

          <div class="custom-form"
            [ngClass]="!(dataService.showTabs) ? 'header-form' : 'page-form'">
            <mat-form-field color="primary" appearance="outline">
              <mat-label>{{"Problems" | translate}}</mat-label>
              <mat-select [value]="dataService.problemType.value"
                panelClass="custom-mat-select-panel-class"
                (selectionChange)="redirectToAnotherProblem($event)">
                @for (problem of problems; track problem['id']) {
                  <mat-option [value]="problem.value">
                  {{problem.title | translate}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div class="admin-button">
          @if (dataService.isEdit) {
            <button type="button" mat-stroked-button color="primary"
              class="custom-stroked-btn"
              (click)="export()">
              <mat-icon>save_alt</mat-icon>{{"Export CSV" | translate}}
            </button>
          }
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="openAdminModal()"
            [disabled]="!permissionsService.havePermission('single-source-sp', 'ability-to-open-admin')">
            <mat-icon>admin_panel_settings</mat-icon>
            {{"Admin" | translate}}
          </button>
          <button type="button" mat-stroked-button color="primary"
            class="custom-stroked-btn"
            (click)="openMarkdown()">
            <mat-icon>help_outline</mat-icon>{{"Help" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  @if (dataService.countryName) {
    <div class="row">
      <div class="col-md-12 country-name">
        {{ dataService.countryName }}
      </div>
    </div>
  }

  <div class="row">
    @if (!(dataService.showTabs)) {
      <div class="col-md-12 iframe-wrapper">
        <div class="iframe-holder">
          <iframe
            [src]="safeData"
            width="100%" height="100%"  frameborder="5">
          </iframe>
        </div>
      </div>
    }
  </div>

</div>
