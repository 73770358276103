import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IFormService } from '../../../../shared/bussiness-logic/interfaces/i-form-service';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from '../../../../shared/helpers/reactive-forms-helper';
import { IGeoLocationBackward, IGeoLocationBackwardForm, IGeoLocationBackwardRequest } from '../../interfaces/i-geo-location-backward';
import { GeoLocationBackwardService } from '../api/geo-location-backward.service';
import { BlGeoLocationBackwardRequestsService } from '../requests/bl-geo-location-backward-requests.service';

@Injectable({
  providedIn: 'root'
})
export class BlGeoLocationBackwardFormService implements IFormService<IGeoLocationBackwardForm> {

  constructor(
    private formBuilder: FormBuilderTypeSafe,
    private matDialog: MatDialog,
    private requestsService: BlGeoLocationBackwardRequestsService,
    private apiService: GeoLocationBackwardService
  ) {}

  form: FormGroupTypeSafe<IGeoLocationBackwardForm> = this.init();
  originalObj: IGeoLocationBackward;

  init(): FormGroupTypeSafe<IGeoLocationBackwardForm> {
    return this.formBuilder.group<IGeoLocationBackwardForm>({
      longitude: this.formBuilder.control("", Validators.required),
      latitude: this.formBuilder.control("", Validators.required)
    });
  }

  setLatitude(latitude: number): void {
    this.form.getSafe(x => x.latitude).setValue(latitude);
  }

  setLongitude(longitude: number): void {
    this.form.getSafe(x => x.longitude).setValue(longitude);
  }

  fillForm(): void {}

  prepareDataToSend(): IGeoLocationBackwardRequest {
    let formValue: IGeoLocationBackwardForm = this.form.getRawValue();

    let dataToSend: IGeoLocationBackwardRequest = {
      longitude: formValue.longitude,
      lat: formValue.latitude
    };

    return dataToSend;
  }

  getFormReference(): FormGroupTypeSafe<IGeoLocationBackwardForm> {
    return this.form;
  }

  close(): void {
    this.matDialog.closeAll();
  }

  reset(): void {
    this.form = this.init();
  }

}