import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICredentialsForgetPassword } from '../../interfaces/i-credentials';
import { ApiService } from '../../../shared/services/api.service';
import { apiPaths } from '../../../config/api';

@Injectable({
  providedIn: 'root'
})
export class ForgerPasswordService extends ApiService<ICredentialsForgetPassword>{

  constructor(http: HttpClient) {
    super(apiPaths.auth.forgetPassword, http);
  }

}