export const PROBLEM_TYPES_FILTER: any[] = [
    {
        id: 1,
        title: "Instance from the literature",
        type: "l"
    },
    {
        id: 2,
        title: "Custom Instance",
        type: "c"
    }
]