import { Pipe, PipeTransform } from '@angular/core';
import { ORGNode, ORGTree } from '../components/org-chart/utility/orgnode';

@Pipe({
  name: 'orgChartDrawChildrenLinks'
})
export class OrgChartDrawChildrenLinksPipe implements PipeTransform {

  transform(node: ORGNode, tree: ORGTree, async: boolean = true): any {
    return node._drawChildrenLinks(tree);
  }
}
